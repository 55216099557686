import React, { Component } from 'react';

import { Helmet } from "react-helmet";

import PropertiesDialog from '../dialogs/PropertiesDialog';

import AlertEditDialog from '../dialogs/AlertEditDialog';

import { FileEarmarkText } from 'react-bootstrap-icons';
import { Trash } from 'react-bootstrap-icons';

import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from 'react-bootstrap-dialog'

import {OverlayTrigger,Tooltip} from "react-bootstrap";

import { getPlanInfo, convertPlanInfoUnlimitedToNumbers } from '../utilities/AppUtility';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
});

class Alerts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            alerts :[],
            uiLoading: true
        }
       
        this.propertiesDialogRef = React.createRef();
        this.alertDialogRef = React.createRef();

    }
    

    componentWillMount = () => {

     this.loadAlerts();

    };

    alertInfoMessageCheckboxChange  = (event) => {

        let checkBoxValue = false;

        let checkbox = document.getElementById('alertInfoMessageCheckbox');
        if (checkbox.checked != true)
        {
            checkBoxValue = true;
        }else
        {
            checkBoxValue = false;
        }
       
        localStorage.setItem('ShowAlertInfoMessage',checkBoxValue.toString());

    };

    loadAlerts()
    {
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

        axios
        .get('/api/alerts')
        .then((response) => {

            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
            {
                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            }

            console.log("Before refresh token:")
            console.log("---------------------")
            console.log(refreshToken);
            console.log("")
            console.log("Return Refresh Token:")
            console.log("---------------------")
            console.log(response.headers["refreshtoken"]);

           // console.log(JSON.stringify(response.data));

            this.setState({
                alerts: response.data,
                uiLoading: false
            });
        })
        .catch((error) => {
            //alert(error);
            console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
                }  else
                {
                    this.setState({
                        uiLoading: false
                    });
                }
        });
    };

    showAlertInfoMessage()
    {
        document.querySelector("#alertInfoButton").click();
    }

    deleteAlertFromDB(id)
    {
        const alert = {
            id: id
          };
      
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

        axios
            .post('/api/deleteAlert', alert)
            .then((response) => {

                if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

                console.log("Completed deleting alert");
            
                this.loadAlerts();
            })
            .catch((error) => {
                
            console.log(error);

            if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
                }
                
            });
    };  

    openAlertEditDialog(alert,event) {

        event.preventDefault();

        //console.log(this.props.user);

        console.log(this.props.user.planName);
        let planInfo = getPlanInfo(this.props.user.planName);
        planInfo = convertPlanInfoUnlimitedToNumbers(planInfo);

        let message= "You have exceeded the number of advanced searches you can have for this plan. Please delete some of your existing advanced searches across your profiles or go to MY ACCOUNT and upgrade your plan.";
        let advancedSeaches = planInfo.advancedSeaches;

        if (this.props.user.planType === 'trial')
        {
            advancedSeaches = 1;
            message= "You have exceeded the number of advanced searches you can have for the trial version, you must UPGRADE to a subscription plan to get all features.";
        }

        if (this.state.alerts.length >= advancedSeaches && alert == null)
        {
            this.dialog.show({
                title: 'Plan Limit Exceeded',
                body: message,
                actions: [
                  Dialog.OKAction(() => {
                    //console.log('OK was clicked!')
                    
                  })
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                  dialog.hide()
                  
                }
              });

        } else
        {
            var dialog = this.alertDialogRef.current;
            dialog.PopulateDialog(alert,this.props.profileId,this.props.profile,this.props.user,this);
        }
    }

    deleteAlert(id,event) {

        event.preventDefault();

        //this.dialog.showAlert('Hello Dialog!');

        this.dialog.show({
            title: 'Delete Confirmation',
            body: 'Do you want to delete this advanced search? All associated properties will be deleted.',
            actions: [
              Dialog.CancelAction(),
              Dialog.OKAction(() => {
                console.log('OK was clicked!')
                this.deleteAlertFromDB(id);
              })
            ],
            bsSize: 'small',
            onHide: (dialog) => {
              dialog.hide()
              
            }
          })
    }
    
    formatTimeStampToDateString(timestamp)
    {
       // console.log(timestamp);

        const fireBaseTime = new Date(
            timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
          );

        //console.log(fireBaseTime.toLocaleDateString());  

        return fireBaseTime.toLocaleDateString();
    }

    render() {

        const { classes } = this.props;

        if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
            return (

                <div>
                    <div classX="my-property-heading">
                        <h1 class="dashboard-heading" style={{paddingBottom:'2px',marginBottom:'2px'}}>
                            Advanced Search

                           <span style={{paddingLeft:'10px',fontSize:'18px',fontWeight:'plain',color:'#949494'}}>
                            <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 150, hide: 100 }} 
                                            trigger={["hover","click"]}
                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltipWhite">
                                            
                                                <div className='helpText'>
                                                    {this.state.alerts?.filter(alert => alert.profile_id == this.props.profileId).length} advanced searches
                                                </div>
                                            
                                            </Tooltip>}
                                            >
                                                <span> 
                                ({this.state.alerts?.filter(alert => alert.profile_id == this.props.profileId).length})
                                </span>

                                </OverlayTrigger>
                            </span>

                        </h1>      
                        <div class="page-short-description view-mode-padding">
                        {/*
                        Advanced searches allow you to specify the criteria for the properties you want to search for within the selected profile, including location, price, number of bedrooms, and number of bathrooms, among other things. The application will search for properties that match your criteria on a daily basis and will notify you via email when they are found.
                       */}
                       Advanced searches enable you to set specific criteria for the properties you're seeking within a selected profile. You can define the required number of green indicators a property must have, where more green indicators mean higher profitability.

                        </div>             
                    </div>

                    <section class="my-alerts-content" style={{paddingTop:'10px'}}>
        
                        <div class="row">
                            <div class="col-md-12" style={{paddingLeft:'0px',marginLeft:'0px'}}>
                    
                            <button type="button" class="btn btn-primary add-btn" onClick={(e) => this.openAlertEditDialog(null, e)} style={{marginBottom:"10px",marginLeft:"16px"}}>
                                    ADD
                            </button>
                                
                    
    {/* ======================================== Trial table Begins ==================================== */}
                                <div class="top-row-list-properties">
                                            {/* this.state.alerts.map((alert) => ( */}
                                            {this.state.alerts?.filter(alert => alert.profile_id == this.props.profileId).map((alert,i) => (	
        
                                                        <div class="row properties-row">
                                                            <div class="col-md-12">
                                                            <div class="custom-control custom-checkbox">
                                                                {/*   <input type="checkbox" class="custom-control-input" id="customCheck2" checked /> */}
                                                                    <label class="row-container custom-label" for="customCheck2">
                                                                        <div class="row"  style={{borderBottom:"none"}}>
                                                            
                                                                            <div class="col-lg-12" style={{paddingTop:"5px",marginTop:"5px"}}>
                                                                                <div class="row">
                                                                                    <div class="col-md-4">
                                                                                        <p class="rate-heading table-header-text-center">Search Name</p>
                                                                                        <p class="green-text"><h2 class="property-heading" style={{fontSize:"20px",fontWeight:"400"}}><a href="#!" style={{textDecoration:"underline"}}  onClick={(e) => this.openAlertEditDialog(alert, e)}>{alert.alert_name}</a></h2></p>
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        <p class="rate-heading text-center">Start Date</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>{this.formatTimeStampToDateString(alert.start_date)}</p>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-md-2">
                                                                                        <p class="rate-heading text-center">End Date</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>{this.formatTimeStampToDateString(alert.end_date)}</p>
                                                                                    </div>

                                                                                    <div class="col-md-2">
                                                                                        <p class="rate-heading text-center">Investment Type</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>{alert.investment_type}</p>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-md-1">
                                                                                        <p class="rate-heading text-center">Matches</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>({this.props.properties?.filter(property => property.alertId == alert.id).length})</p>
                                                                                    </div>
                                                                                    
                                                                            {/*
                                                                                    <div class="col-md-1">
                                                                                        <p class="rate-heading text-center">Status</p>
                                                                                        <p class="rate-value text-center" style={{fontSize:"18px"}}>
                                                                                            <BootstrapSwitchButton checked={true} onstyle="success" offstyle="danger" size="xs"/>
                                                                                        </p>
                                                                                    </div>
                                                                            */}    
                                                                                
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row" style={{borderTop:"none"}}>
                                                                        <div class="col-md-12">
                                                                        {/*
                                                                        <button classX="btn  btn-warning  view-btn" style={{all:"unset",cursor:"pointer",color:"#FFFFFF"}} onClick={(e) => this.openDashboard(alert, e)}>found ({this.props.properties?.filter(property => property.alertId == alert.id).length})</button>
                                                                        */}
                                                                        &nbsp;
                                                                        <h3 class="total-value">
                                                                                <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openAlertEditDialog(alert, e)}><FileEarmarkText className="ml-2" style={{height:"24px"}}/> </a>
                                                                                &nbsp;
                                                                                <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.deleteAlert(alert.id, e)}><Trash className="ml-2"  style={{height:"24px"}}/></a>
                                                                                </h3>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>                                                         
                                                        </div>


                                                    </div>
                                                

                                            ))}    

                                        <Dialog ref={(component) => { this.dialog = component }}/>     
                                </div>
    
                                                                                                
                        {/* -------------------  BEGIN  HELP MODAL ------------------- */}

                        <button type="button" id="alertInfoButton" class="infoHelpbutton" data-toggle="modal" data-target="#alertInfoModal">
                            <div>
                              
                            </div>
                        </button>

                        <div class="modal fade help-ifo-modal" id="alertInfoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <div style={{fontWeight:'bold'}}>Alert - Information</div>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" style={{fontSize:'16px'}}>
                                
                                <p>
                                Please note that advanced searches run in the background. 
                                </p>
                                <p>
                                    The application will search for properties matching your criteria on a daily basis and notify you via email when they are found.
                                </p>

                                <p>
                                When you log in to the application, newly found properties will be labeled as <b>NEW</b>.
                                </p>

                                <p>
                                <b>
                                <label>
                                    <input id="alertInfoMessageCheckbox" type="checkbox" style={{width:'20px',height:'20px',accentColor:"blue", }} onChange={this.alertInfoMessageCheckboxChange}/>
                                    <span style={{paddingLeft:'6px'}}>Don't show this message again</span>
                                </label>
                                </b>
                                </p>

                            </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* -------------------  END  HELP MODAL ------------------- */}


    {/* ========================================= Trial Table Ends ======================================*/}

                                <PropertiesDialog ref={this.propertiesDialogRef}></PropertiesDialog>
                                <AlertEditDialog ref={this.alertDialogRef}  homeRef={this.props.parentRef}></AlertEditDialog>
                            </div>
                        </div>
                    </section>

                                                                      
                    <Helmet>
                        <script src="js/grid-functions.js" type="text/javascript" />
                         <script src="vendor/jquery/jquery.min.js"></script> 
                        <script src="js/sb-admin-2.min.js"></script>
                        <script src="vendor/bootstrap/bootstrap.bundle.min.js"></script>
                    </Helmet>
                   

                </div>


            );
        }
    }
}

export default (withStyles(styles)(Alerts));

import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import './App.css';

import login from './pages/Login';
import reset from './pages/Reset';
import home from './pages/Home';
import main from './pages/Main';
import signup from './pages/Signup';     //This is if I want sign up without payment
import contactus from './pages/Contactus';

import pricing from './pages/Pricing';
import signupLegalDisclaimer from './pages/Signup_LegalDisclaimer';
import signupPaymentInfo from './pages/Signup_PaymentInfo';
import signupEmailConfirmation from './pages/Signup_EmailConfirmation';
import viewer from './pages/Viewer';

import termsOfService from './pages/TermsOfService';

import calculators from './pages/Calculators';

const TRACKING_ID = "G-D9WHQS48PD"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

//import signup from './pages/signupWithPayment';  //This signup requires payment
//import reset from './pages/reset';
//import activate from './pages/activate';

const reload = () => window.location.reload();

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <div>
        <Switch>
            <Route exact path="/login" component={login}/>
            <Route exact path="/reset" component={reset}/>
            <Route exact path="/signup" component={signup}/>
            <Route exact path="/app" component={home}/>
            
            <Route exact path="/pricing" component={pricing}/>
            <Route exact path="/signup/email-confirmation" component={signupEmailConfirmation}/>
            <Route exact path="/signup/terms-and-conditions" component={signupLegalDisclaimer}/>
            <Route exact path="/signup/billing-information" component={signupPaymentInfo}/>

            <Route exact path="/terms-of-service" component={termsOfService}/>

            <Route exact path="/logout/force-user-logout-after-404" component={viewer}/>
            <Route exact path="/" component={main}/>
            <Route exact path="/index.html" component={main}/>

            <Route exact path="/contactus" component={contactus}/>

            <Route exact path="/calculators" component={calculators}/>

        </Switch>
      </div>
    </Router>
  );
}
export default App;


/*
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import login from './pages/Login';
import home from './pages/Home';
import signup from './pages/Signup';     //This is if I want sign up without payment
//import signup from './pages/signupWithPayment';  //This signup requires payment
//import reset from './pages/reset';
//import activate from './pages/activate';
const reload = () => window.location.reload();
function App() {
  return (
    <Router>
      <div>
        <Switch>
            <Route exact path="/login" component={login}/>
            <Route exact path="/signup" component={signup}/>
            <Route exact path="/" component={home}/>      
        </Switch>
      </div>
    </Router>
  );
}
export default App;
*/


/*
import './App.css';
import Home from './pages/Home';
function App() {
  return (
      <Home />
  );
}
export default App;
*/
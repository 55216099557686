import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger ,Tooltip } from "react-bootstrap";

import IndicatorInfoDialog from '../dialogs/IndicatorInfoDialog';

//import BLO from '../utilities/BLO';
import * as BLO from '../utilities/BLO';


class PropertiesEditDialog extends Component { 
    state = {
        isOpen: false, 
        property: {}
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.state = { 
            property : {},
            indicator1: {},
            indicator2: {},
            indicator3: {},
            indicator4: {},
            indicator5: {},
            indicator6: {},
            indicator7: {},
            indicator8: {},
            indicator9: {},
            profile: null,
            profileId:0
          };


        this.indicatorInfoDialogRef = React.createRef();

     }

     openIndicatorInfoDialog(indicator,event) {

        event.preventDefault();

        var dialog = this.indicatorInfoDialogRef.current;
        dialog.PopulateDialog(indicator);
    }

     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    PopulateDialog(property,settings,profileId,profile)
    {
        //console.log("property:");
        //console.log(JSON.stringify(property));

        /*
           Note: You still need to pay closing costs with cash offers:
           https://www.realtor.com/advice/buy/best-of-advice-if-i-pay-cash-for-a-house-what-else-will-i-owe/
           https://www.financialsamurai.com/closing-costs-when-paying-cash-for-a-property/
        */

        this.setState({          
            profileId:profileId,
            profile:profile
        });

        
        if (isNaN(property.term))
        {            
          property.term = property.term.replace("-"," ").replace("year", "Years").replace("Years", "").trim();
        }

        if (property.propertyCashFlowMaps == null || Object.keys(property.propertyCashFlowMaps).length == 0)
        {
            const projectedYearOfSale = parseInt(property.projectedYearOfSale.toLowerCase().replace("year","").trim());

            property.propertyCashFlowMaps = BLO.calculateFutureCashFlows(property,settings.benchmarkSettings);

            console.log(property.propertyCashFlowMaps.size);
        }

        if (property.trendingPricePerSqft == null || property.trendingPricePerSqft ==0)
        { 
          if (property.similarSoldProperties != null)
          {
            const pricePerSqft = this.CalculatePropertyPricePerSqftAverage(property.similarSoldProperties);
            property.trendingPricePerSqft = pricePerSqft;
          }
        }
        
        if (property.investmentType == "Rental Property")
        {
            this.PopulateRentalIndicators(property,settings);          
        
        } else if (property.investmentType == "Flipping Property")
        {
            this.PopulateFlippingIndicators(property,settings);          
        
        } else if (property.investmentType == "Primary Home")
        {
            this.PopulatePrimaryHomeIndicators(property,settings);          
        }

        
    }

  PopulateRentalIndicators(property,account_settings)
  {
    //const settings = Settings.GetProfileBenchmarkingSettings();
    const settings =JSON.parse(JSON.stringify(account_settings.benchmarkSettings)); // clone it since need budget based on the profile value and trending price per sqft
    const profile = this.props?.profile;
	
    //Needs to come from profile
    settings.budgetAccepted=profile?.budget;   
	settings.budgetRejected=profile?.budget;   
	settings.budgetAcceptedCompareId=5;
	settings.budgetRejectedCompareId=1;

    //Needs to come from property
    settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
	settings.pricePerSizeRejected = property.trendingPricePerSqft;
	settings.pricePerSizeAcceptedCompareId =  5;
	settings.pricePerSizeRejectedCompareId =  1;

    //console.log("SETTINGS:");
    //console.log(JSON.stringify(settings));

    const grmIndicator = BLO.getGRMIndicator(property,settings);
    const capIndicator = BLO.getCapRateIndicator(property,settings);
    const cocIndicator = BLO.getCashOnCashIndicator(property,settings);

    const piIndicator = BLO.getProfitabilityIndexIndicator(property,settings);
    const irrIndicator = BLO.getInternalRateOfReturnIndicator(property,settings);
    const dcrIndicator = BLO.getDebtCoverageRatioRentalIndicator(property,settings);

    const berIndicator = BLO.getBreakEvenRatioIndicator(property,settings);
    const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
    const ncfIndicator = BLO.getNetCashFlowIndicator(property,settings);

    //console.log(JSON.stringify(dcrIndicator));

    const indicators = [];
    indicators.push(grmIndicator);
    indicators.push(capIndicator);
    indicators.push(cocIndicator);

    indicators.push(piIndicator);
    indicators.push(irrIndicator);
    indicators.push(dcrIndicator);

    indicators.push(berIndicator);
    indicators.push(ltvIndicator);
    indicators.push(ncfIndicator);

    console.log(JSON.stringify(indicators));

    this.setState({
        property: property,
        indicator1: grmIndicator,
        indicator2: capIndicator,
        indicator3: cocIndicator,
        indicator4: piIndicator,
        indicator5: irrIndicator,
        indicator6: dcrIndicator,
        indicator7: berIndicator,
        indicator8: ltvIndicator,
        indicator9: ncfIndicator
      }, () => {
      
        this.openModal();

      });

  }

  PopulateFlippingIndicators(property,account_settings)
  {
    //const settings = Settings.GetProfileBenchmarkingSettings();
    const settings =JSON.parse(JSON.stringify(account_settings.benchmarkSettings)); // clone it since need budget based on the profile value and trending price per sqft
    const profile = this.props?.profile;
	
    //Needs to come from profile
    settings.budgetAccepted=profile?.budget;   
	settings.budgetRejected=profile?.budget;   
	settings.budgetAcceptedCompareId=5;
	settings.budgetRejectedCompareId=1;

    //Needs to come from property
    settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
	settings.pricePerSizeRejected = property.trendingPricePerSqft;
	settings.pricePerSizeAcceptedCompareId =  5;
	settings.pricePerSizeRejectedCompareId =  1;

    const seventyPercentIndicator = BLO.get70PercentRuleIndicator(property,settings);
    const keyRiskIndicator = BLO.getKeyRiskIndicator(property,settings);    
    const dcrIndicator = BLO.getDebtCoverageRatioFlipIndicator(property,settings);

    const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
    const roiIndicator = BLO.getROIIndicator(property,settings);
    const netProfitIndicator = BLO.getNetProfitIndicator(property,settings);

    const marketValueIndicator = BLO.getMarketValuePercentIndicator(property,settings);
    const pricePerSqFtIndicator = BLO.getPricePerSizeIndicator(property,settings);
    const budgetIndicator = BLO.getBudgetIndicator(property,settings);

    //console.log(JSON.stringify(roiIndicator));


    this.setState({
        property: property,
        indicator1: seventyPercentIndicator,
        indicator2: keyRiskIndicator,
        indicator3: dcrIndicator,
        indicator4: ltvIndicator,
        indicator5: roiIndicator,
        indicator6: netProfitIndicator,
        indicator7: marketValueIndicator,
        indicator8: pricePerSqFtIndicator,
        indicator9: budgetIndicator
      }, () => {
      
        this.openModal();

      });

  }


  PopulatePrimaryHomeIndicators(property,account_settings)
  {
    //const settings = Settings.GetProfileBenchmarkingSettings();
    const settings =JSON.parse(JSON.stringify(account_settings.benchmarkSettings)); // clone it since need budget based on the profile value and trending price per sqft
    const profile = this.props?.profile;
	
    //Needs to come from profile
    settings.budgetAccepted=profile?.budget;   
	settings.budgetRejected=profile?.budget;   
	settings.budgetAcceptedCompareId=5;
	settings.budgetRejectedCompareId=1;

    //Needs to come from property
    settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
	settings.pricePerSizeRejected = property.trendingPricePerSqft;
	settings.pricePerSizeAcceptedCompareId =  5;
	settings.pricePerSizeRejectedCompareId =  1;

    const backEndRatioIndicator = BLO.getBackEndRatioIndicator(property,settings);
    const frontEndRatioIndicator = BLO.getFrontEndRatioIndicator(property,settings);    
    const dcrIndicator = BLO.getDebtCoverageRatioHomeIndicator(property,settings);

    const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
    const costOfDebtIndicator = BLO.getCostOfDebtIndicator(property,settings);
    const marketValueIndicator = BLO.getMarketValuePercentIndicator(property,settings);
        
    const pricePerSqFtIndicator = BLO.getPricePerSizeIndicator(property,settings);
    const priceToRentIndicator = BLO.getPriceToRentRatioIndicator(property,settings);
    const budgetIndicator = BLO.getBudgetHomeIndicator(property,settings);

    //const creditScoreIndicator = BLO.getCreditScoreIndicator(property,settings);

    //console.log(JSON.stringify(priceToRentIndicator));

    this.setState({
        property: property,
        indicator1: backEndRatioIndicator,
        indicator2: frontEndRatioIndicator,
        indicator3: dcrIndicator,
        indicator4: ltvIndicator,
        indicator5: costOfDebtIndicator,
        indicator6: marketValueIndicator,
        indicator7: pricePerSqFtIndicator,
        indicator8: priceToRentIndicator,
        indicator9: budgetIndicator
      }, () => {
      
        this.openModal();

      });

  }


  CalculatePropertyPricePerSqftAverage(similarProperties)
  {
    
    let pricePerSqftAverage = 0;
 
    try
    {

      let total = 0;
      for (let property of similarProperties)
      {
        let pricePerSqft = this.ConvertToNumber(property.price) / this.ConvertToNumber(property.sqFt);

        pricePerSqft = parseInt(pricePerSqft);

        total = total + pricePerSqft;
      }
   
      pricePerSqftAverage = total/similarProperties.length;

      pricePerSqftAverage = parseInt(pricePerSqftAverage);


    }catch(error)
    {
       console.log(error);
    }

    return pricePerSqftAverage;
  }

  ConvertToNumber(value)
  {
      value = value.toString();
      let valueNumber = 0;

      try
      {
          let valueString = value.toString();
          valueString = value.toString().replace(",","").replace("$","").replace("+","").replace("-","").replace("/mo","");

          if (valueString.endsWith("+"))
          {
              valueString = valueString.slice(0, -1);
          }

          valueNumber = parseInt(valueString);
      } catch(error)
      {
          console.log(error);
          valueNumber = value;
      }

      return valueNumber;
  }

  FetchRecordFromDatabase(id)
  {
    var record = {
   };


   return record;
  }
   
  CreateEmptyRecord()
  {
    var record = {
   };

   return record;
  }

  
  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }

  FormatCurrency(value)
  {

	let dollarUS = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	  });
  
  
	  let formattedValue = dollarUS.format(value);
  
	  if (formattedValue.endsWith(".00"))
	  {
		formattedValue = formattedValue.substring(0, formattedValue.length-3);
	  }
	  //alert(formattedValue);
  
	  return formattedValue;

  }


    render() {

        return (
            <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} dialogClassName="detail-modal-width"> 

            <Modal.Header closeButton>
              <Modal.Title>Property Analysis</Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal">
            
               <div class="modal-body" style={{padding:"0px",margin:"0px"}}>
                    <div classx="container" style={{padding:"0px",margin:"0px"}}>

                        <div class="col-md-12" class="round-border-dash-mobile">

                            <div class="row" style={{paddingBottom:"6px"}}>
                                <div class="col-md-12">
                                    <table cellPadding="4">
                                        <tr>
                                            <td colSpan="3">
                                                <div style={{textAlign:"left",color:"#FFFFFF",paddingLeft:"2px"}}>
                                        
                                                    <h3>{this.state.property?.nickName}</h3>
                                                    {this.state.property?.address}, {this.state.property?.city} {this.state.property?.state} {this.state.property?.zipcode}<br></br>
                                                </div>
                                            </td>
                                        </tr>    
                                        <tr>
                                            <td>
                                            {((this.state.indicator1?.property?.financingType !== "Cash") || (this.state.indicator1?.property?.financingType === "Cash" && this.state.indicator1?.indicatorAcronym !== "DCR" && this.state.indicator1?.indicatorAcronym !== "BER" && this.state.indicator1?.indicatorAcronym !== "LVR") && this.state.indicator1?.indicatorAcronym !== "DCR" && this.state.indicator1?.indicatorAcronym !== "COD" && this.state.indicator1?.indicatorAcronym !== "FE") &&     

                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator1, e)}>
                                                <div class={this.state.indicator1?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator1?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator1?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator1?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator1?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator1?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator1?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator1?.indicatorValue}
                                                            </span>
                                                        }
                                                        
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                             }		

                                             {this.state.indicator1?.property?.financingType === "Cash" && (this.state.indicator1?.indicatorAcronym === "DCR" || this.state.indicator1?.indicatorAcronym === "LVR" || this.state.indicator1?.indicatorAcronym === "BER" || this.state.indicator1?.indicatorAcronym === "COD" || this.state.indicator1?.indicatorAcronym === "FE") &&   
 
                                                 <OverlayTrigger
                                                 placement="top-start"
                                                 delay={{ show: 150, hide: 100 }} 
                                                 trigger={["hover","click"]}
                                                 overlay={<Tooltip  className="messagetooltip">
 
                                                 <div className='helpText'>This indicator is not applicable <br></br>
                                                                           when you are paying for the<br></br> 
                                                                           property entirely with cash.</div>
                                                 </Tooltip>}
                                                 >
                                                     <div class='disabled-indicator-tile'>
                                                         <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                             {this.state.indicator1?.indicatorName}
                                                         </diV>
                                                         <diV class="indicator-value" style={{color:'#505050'}}>
                                                         <b>N/A</b>
                                                         </diV>
                                                     </div>
                                                 </OverlayTrigger>
                                             }
                                            </td>
                                            <td>
                                            {((this.state.indicator2?.property?.financingType !== "Cash") || (this.state.indicator2?.property?.financingType === "Cash" && this.state.indicator2?.indicatorAcronym !== "DCR" && this.state.indicator2?.indicatorAcronym !== "BER" && this.state.indicator2?.indicatorAcronym !== "LVR") && this.state.indicator2?.indicatorAcronym !== "DCR" && this.state.indicator2?.indicatorAcronym !== "COD" && this.state.indicator2?.indicatorAcronym !== "FE") &&     
                                           
                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator2, e)}>
                                                <div class={this.state.indicator2?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator2?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator2?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator2?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator2?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator2?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator2?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator2?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                            }		

                                            {this.state.indicator2?.property?.financingType === "Cash" && (this.state.indicator2?.indicatorAcronym === "DCR" || this.state.indicator2?.indicatorAcronym === "LVR" || this.state.indicator2?.indicatorAcronym === "BER" || this.state.indicator2?.indicatorAcronym === "COD" || this.state.indicator2?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator2?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            </td>
                                            <td>
                                            {((this.state.indicator3?.property?.financingType !== "Cash") || (this.state.indicator3?.property?.financingType === "Cash" && this.state.indicator3?.indicatorAcronym !== "DCR" && this.state.indicator3?.indicatorAcronym !== "BER" && this.state.indicator3?.indicatorAcronym !== "LVR") && this.state.indicator3?.indicatorAcronym !== "DCR" && this.state.indicator3?.indicatorAcronym !== "COD" && this.state.indicator3?.indicatorAcronym !== "FE") &&     
                                           

                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator3, e)}>
                                                <div class={this.state.indicator3?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator3?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator3?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator3?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator3?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator3?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator3?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator3?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                            }		

                                            {this.state.indicator3?.property?.financingType === "Cash" && (this.state.indicator3?.indicatorAcronym === "DCR" || this.state.indicator3?.indicatorAcronym === "LVR" || this.state.indicator3?.indicatorAcronym === "BER" || this.state.indicator3?.indicatorAcronym === "COD" || this.state.indicator3?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator3?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                           
                                            {((this.state.indicator4?.property?.financingType !== "Cash") || (this.state.indicator4?.property?.financingType === "Cash" && this.state.indicator4?.indicatorAcronym !== "DCR" && this.state.indicator4?.indicatorAcronym !== "BER" && this.state.indicator4?.indicatorAcronym !== "LVR") && this.state.indicator4?.indicatorAcronym !== "DCR" && this.state.indicator4?.indicatorAcronym !== "COD" && this.state.indicator4?.indicatorAcronym !== "FE") &&     
                                           

                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator4, e)}>
                                                <div class={this.state.indicator4?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator4?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator4?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator4?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator4?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator4?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator4?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator4?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                            }		

                                            {this.state.indicator4?.property?.financingType === "Cash" && (this.state.indicator4?.indicatorAcronym === "DCR" || this.state.indicator4?.indicatorAcronym === "LVR" || this.state.indicator4?.indicatorAcronym === "BER" || this.state.indicator4?.indicatorAcronym === "COD" || this.state.indicator4?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator4?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            </td>
                                            <td>
                                            {((this.state.indicator5?.property?.financingType !== "Cash") || (this.state.indicator5?.property?.financingType === "Cash" && this.state.indicator5?.indicatorAcronym !== "DCR" && this.state.indicator5?.indicatorAcronym !== "BER" && this.state.indicator5?.indicatorAcronym !== "LVR") && this.state.indicator5?.indicatorAcronym !== "DCR" && this.state.indicator5?.indicatorAcronym !== "COD" && this.state.indicator5?.indicatorAcronym !== "FE") &&     
                                           

                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator5, e)}>
                                                <div class={this.state.indicator5?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator5?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator5?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator5?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator5?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator5?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator5?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator5?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                            }		

                                            {this.state.indicator5?.property?.financingType === "Cash" && (this.state.indicator5?.indicatorAcronym === "DCR" || this.state.indicator5?.indicatorAcronym === "LVR" || this.state.indicator5?.indicatorAcronym === "BER" || this.state.indicator5?.indicatorAcronym === "COD" || this.state.indicator5?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator5?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }

                                            </td>
                                            <td>
                                        
                                            {((this.state.indicator6?.property?.financingType !== "Cash") || (this.state.indicator6?.property?.financingType === "Cash" && this.state.indicator6?.indicatorAcronym !== "DCR" && this.state.indicator6?.indicatorAcronym !== "BER" && this.state.indicator6?.indicatorAcronym !== "LVR") && this.state.indicator6?.indicatorAcronym !== "DCR" && this.state.indicator6?.indicatorAcronym !== "COD" && this.state.indicator6?.indicatorAcronym !== "FE") &&     
                                            
                                               <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator6, e)}>
                                               <div class={this.state.indicator6?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator6?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator6?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator6?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator6?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator6?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator6?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator6?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>

                                            }
                                            
                                            {this.state.indicator6?.property?.financingType === "Cash" && (this.state.indicator6?.indicatorAcronym === "DCR" || this.state.indicator6?.indicatorAcronym === "LVR" || this.state.indicator6?.indicatorAcronym === "BER" || this.state.indicator6?.indicatorAcronym === "COD"  || this.state.indicator6?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator6?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            </td>
                                        </tr>


                                        <tr>
                                            <td>
                                            {((this.state.indicator7?.property?.financingType !== "Cash") || (this.state.indicator7?.property?.financingType === "Cash" && this.state.indicator7?.indicatorAcronym !== "DCR" && this.state.indicator7?.indicatorAcronym !== "BER" && this.state.indicator7?.indicatorAcronym !== "LVR") && this.state.indicator7?.indicatorAcronym !== "DCR" && this.state.indicator7?.indicatorAcronym !== "COD" && this.state.indicator7?.indicatorAcronym !== "FE") &&     
                                           

                                               <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator7, e)}>
                                               <div class={this.state.indicator7?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator7?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator7?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator7?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator7?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator7?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator7?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator7?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                            }		

                                            {this.state.indicator7?.property?.financingType === "Cash" && (this.state.indicator7?.indicatorAcronym === "DCR" || this.state.indicator7?.indicatorAcronym === "LVR" || this.state.indicator7?.indicatorAcronym === "BER" || this.state.indicator7?.indicatorAcronym === "COD" || this.state.indicator7?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator7?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            </td>
                                            <td>
                                        
                                            {((this.state.indicator8?.property?.financingType !== "Cash") || (this.state.indicator8?.property?.financingType === "Cash" && this.state.indicator8?.indicatorAcronym !== "DCR" && this.state.indicator8?.indicatorAcronym !== "BER" && this.state.indicator8?.indicatorAcronym !== "LVR") && this.state.indicator8?.indicatorAcronym !== "DCR" && this.state.indicator8?.indicatorAcronym !== "COD" && this.state.indicator8?.indicatorAcronym !== "FE") &&     
                                           

                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator8, e)}>
                                                <div class={this.state.indicator8?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator8?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        
                                                        {this.state.indicator8?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator8?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator8?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator8?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator8?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator8?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                            }		

                                            {this.state.indicator8?.property?.financingType === "Cash" && (this.state.indicator8?.indicatorAcronym === "DCR" || this.state.indicator8?.indicatorAcronym === "LVR" || this.state.indicator8?.indicatorAcronym === "BER" || this.state.indicator8?.indicatorAcronym === "COD" || this.state.indicator8?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator8?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            </td>
                                            <td>
                                            {((this.state.indicator9?.property?.financingType !== "Cash") || (this.state.indicator9?.property?.financingType === "Cash" && this.state.indicator9?.indicatorAcronym !== "DCR" && this.state.indicator9?.indicatorAcronym !== "BER" && this.state.indicator9?.indicatorAcronym !== "LVR") && this.state.indicator9?.indicatorAcronym !== "DCR" && this.state.indicator9?.indicatorAcronym !== "COD" && this.state.indicator9?.indicatorAcronym !== "FE") &&     
                                           

                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(this.state.indicator9, e)}>
                                                <div class={this.state.indicator9?.meetsBenchmark ? 'green-indicator-tile' : 'red-indicator-tile'}>
                                                    <diV class="indicator-name">
                                                        {this.state.indicator9?.indicatorName}
                                                    </diV>
                                                    <diV class="indicator-value">
                                                        {this.state.indicator9?.valueType == "PERCENT" &&
                                                            <span>
                                                            {this.FormatPercent(this.state.indicator9?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator9?.valueType == "DOLLAR_AMOUNT" &&
                                                            <span>
                                                            {this.FormatCurrency(this.state.indicator9?.indicatorValue)}
                                                            </span>
                                                        }
                                                        {this.state.indicator9?.valueType == "DECIMAL" &&
                                                            <span>
                                                            {this.state.indicator9?.indicatorValue}
                                                            </span>
                                                        }
                                                    </diV>
                                                    
                                                </div>
                                                </a>
                                            }		

                                            {this.state.indicator9?.property?.financingType === "Cash" && (this.state.indicator9?.indicatorAcronym === "DCR" || this.state.indicator9?.indicatorAcronym === "LVR" || this.state.indicator9?.indicatorAcronym === "BER" || this.state.indicator9?.indicatorAcronym === "COD" || this.state.indicator9?.indicatorAcronym === "FE") &&   

                                                <OverlayTrigger
                                                placement="top-start"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltip">

                                                <div className='helpText'>This indicator is not applicable <br></br>
                                                                          when you are paying for the<br></br> 
                                                                          property entirely with cash.</div>
                                                </Tooltip>}
                                                >
                                                    <div class='disabled-indicator-tile'>
                                                        <diV class="indicator-name" style={{color:'#8a8a8a'}}>
                                                            {this.state.indicator9?.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value" style={{color:'#505050'}}>
                                                        <b>N/A</b>
                                                        </diV>
                                                    </div>
                                                </OverlayTrigger>
                                            }
                                            </td>
                                         </tr>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                                   
                      </div>

                      <IndicatorInfoDialog ref={this.indicatorInfoDialogRef}></IndicatorInfoDialog>

                </div>
                                                    


            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>
          </Modal>

        );
    }
}

export default (PropertiesEditDialog);

import React, { Component } from 'react';
import { Modal, Button, Dropdown, DropdownButton, Glyphicon,OverlayTrigger,Tooltip,Accordion,Card } from "react-bootstrap";


import { PatchMinus, PatchPlus } from 'react-bootstrap-icons';

class PropertiesEditDialog extends Component { 
    state = {
        isOpen: false 
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.state = { properties :
          [{id: 123,name : 'Baltimore South Street Property',type: 'rental',street: '555 South Street',city: 'Baltimore',state : 'MD', zipcode: '22222', price:'530500', image:'house1.png', indicators : 
              
                  [
                      {code: 'GRM',indicator:'Gross-Rent-Multiplier', description:'Gross Rent Multiplier',passed : 'true',value:'26.5', valueType:'percent'},
                      {code: 'CAP',indicator:'Capitalization-Rate', description:'Capitalization Rate', passed : 'true',value:'75', valueType:'percent'},
                      {code: 'PI',indicator:'Profitability-Index', description:'Profitability Index',passed : 'false',value:'8.45', valueType:'percent'},
                      {code: 'IRR',indicator:'Internal-Rate-Return', description:'Internal Rate of Return', passed : 'true',value:'40', valueType:'percent'},
                      {code: 'DCR',indicator:'Debt-Coverage-Ratio', description:'Debt Coverage', passed : 'true',value:'11.5', valueType:'percent'},
                      {code: 'BER',indicator:'Break-Even-Ratio', description:'Break-Even Ratio', passed : 'false',value:'15.7', valueType:'percent'},
                      {code: 'NET',indicator:'Net-Cash-Flow', description:'Net Cash Flow', passed : 'true',value:'7.53', valueType:'percent'},
                      {code: 'COC',indicator:'Cash-on-Cash-Return', description:'Cash on Cash Return', passed : 'true',value:'13.64', valueType:'percent'},
                      {code: '70P',indicator:'70-Percent-Rule', description:'70-Percent-Rule', passed : 'true',value:'70.64', valueType:'percent'}

                     
                  ]    
              },
              {id: 123,name : 'New York East St Property',type: 'rental',street: '303 E 57th St APT 32B',city: 'New York',state : 'NY', zipcode: '10022', price: '379000',image:'house5.png', indicators : 
              
                  [
                      {code: 'GRM',indicator:'Gross-Rent-Multiplier', description:'Gross Rent Multiplier',passed : 'false',value:'16.5', valueType:'percent'},
                      {code: 'CAP',indicator:'Capitalization-Rate', description:'Capitalization Rate', passed : 'true',value:'75', valueType:'percent'},
                      {code: 'PI',indicator:'Profitability-Index', description:'Profitability Index',passed : 'false',value:'8.45', valueType:'percent'},
                      {code: 'IRR',indicator:'Internal-Rate-Return', description:'Internal Rate of Return', passed : 'true',value:'40', valueType:'percent'},
                      {code: 'DCR',indicator:'Debt-Coverage-Ratio', description:'Debt Coverage', passed : 'false',value:'11.5', valueType:'percent'},
                      {code: 'BER',indicator:'Break-Even-Ratio', description:'Break-Even Ratio', passed : 'false',value:'15.7', valueType:'percent'},
                      {code: 'NET',indicator:'Net-Cash-Flow', description:'Net Cash Flow', passed : 'true',value:'7.53', valueType:'percent'},
                      {code: 'COC',indicator:'Cash-on-Cash-Return', description:'Cash on Cash Return', passed : 'true',value:'13.64', valueType:'percent'},
                      {code: '70P',indicator:'70-Percent-Rule', description:'70-Percent-Rule', passed : 'true',value:'70.64', valueType:'percent'}
                  ]    
              },
              {id: 123,name : 'Miami Rental',type: 'rental',street: '2198 Secoffee St',city: 'Miami',state : 'FL', zipcode: '33133', price: '988550',image:'house3.png', indicators : 
              
                  [
                      {code: 'GRM',indicator:'Gross-Rent-Multiplier', description:'Gross Rent Multiplier',passed : 'true',value:'26.5', valueType:'percent'},
                      {code: 'CAP',indicator:'Capitalization-Rate', description:'Capitalization Rate', passed : 'true',value:'75', valueType:'percent'},
                      {code: 'PI',indicator:'Profitability-Index', description:'Profitability Index',passed : 'false',value:'8.45', valueType:'percent'},
                      {code: 'IRR',indicator:'Internal-Rate-Return', description:'Internal Rate of Return', passed : 'true',value:'40', valueType:'percent'},
                      {code: 'DCR',indicator:'Debt-Coverage-Ratio', description:'Debt Coverage', passed : 'true',value:'11.5', valueType:'percent'},
                      {code: 'BER',indicator:'Break-Even-Ratio', description:'Break-Even Ratio', passed : 'false',value:'15.7', valueType:'percent'},
                      {code: 'NET',indicator:'Net-Cash-Flow', description:'Net Cash Flow', passed : 'true',value:'7.53', valueType:'percent'},
                      {code: 'COC',indicator:'Cash-on-Cash-Return', description:'Cash on Cash Return', passed : 'true',value:'13.64', valueType:'percent'},
                      {code: '70P',indicator:'70-Percent-Rule', description:'70-Percent-Rule', passed : 'true',value:'70.64', valueType:'percent'}
                  ]    
              },
              {id: 123,name : 'Atlanta House',type: 'rental',street: '3550 Hamlin Sq SW',city: 'Atlanta',state : 'GA', zipcode: '30331', price:'425000', image:'house4.png',indicators : 
              
                  [
                      {code: 'GRM',indicator:'Gross-Rent-Multiplier', description:'Gross Rent Multiplier',passed : 'true',value:'26.5', valueType:'percent'},
                      {code: 'CAP',indicator:'Capitalization-Rate', description:'Capitalization Rate', passed : 'true',value:'75', valueType:'percent'},
                      {code: 'PI',indicator:'Profitability-Index', description:'Profitability Index',passed : 'false',value:'8.45', valueType:'percent'},
                      {code: 'IRR',indicator:'Internal-Rate-Return', description:'Internal Rate of Return', passed : 'true',value:'40', valueType:'percent'},
                      {code: 'DCR',indicator:'Debt-Coverage-Ratio', description:'Debt Coverage', passed : 'true',value:'11.5', valueType:'percent'},
                      {code: 'BER',indicator:'Break-Even-Ratio', description:'Break-Even Ratio', passed : 'false',value:'15.7', valueType:'percent'},
                      {code: 'NET',indicator:'Net-Cash-Flow', description:'Net Cash Flow', passed : 'true',value:'7.53', valueType:'percent'},
                      {code: 'COC',indicator:'Cash-on-Cash-Return', description:'Cash on Cash Return', passed : 'true',value:'13.64', valueType:'percent'},
                      {code: '70P',indicator:'70-Percent-Rule', description:'70-Percent-Rule', passed : 'true',value:'70.64', valueType:'percent'}
                  ]    
              },
              {id: 123,name : 'Washington Investment Property',type: 'rental',street: '3729 Corey Pl NW',city: 'Washington',state : 'DC', zipcode: '20016', price:'1995000', image:'house2.png',indicators : 
              
                  [
                      {code: 'GRM',indicator:'Gross-Rent-Multiplier', description:'Gross Rent Multiplier',passed : 'true',value:'26.5', valueType:'percent'},
                      {code: 'CAP',indicator:'Capitalization-Rate', description:'Capitalization Rate', passed : 'true',value:'75', valueType:'percent'},
                      {code: 'PI',indicator:'Profitability-Index', description:'Profitability Index',passed : 'false',value:'8.45', valueType:'percent'},
                      {code: 'IRR',indicator:'Internal-Rate-Return', description:'Internal Rate of Return', passed : 'true',value:'40', valueType:'percent'},
                      {code: 'DCR',indicator:'Debt-Coverage-Ratio', description:'Debt Coverage', passed : 'true',value:'11.5', valueType:'percent'},
                      {code: 'BER',indicator:'Break-Even-Ratio', description:'Break-Even Ratio', passed : 'false',value:'15.7', valueType:'percent'},
                      {code: 'NET',indicator:'Net-Cash-Flow', description:'Net Cash Flow', passed : 'true',value:'7.53', valueType:'percent'},
                      {code: 'COC',indicator:'Cash-on-Cash-Return', description:'Cash on Cash Return', passed : 'true',value:'13.64', valueType:'percent'},
                      {code: '70P',indicator:'70-Percent-Rule', description:'70-Percent-Rule', passed : 'true',value:'70.64', valueType:'percent'}
                  ]    
              },
              {id: 123,name : 'Washington Cathedral Ave Property',type: 'rental',street: '5134 Cathedral Ave NW',city: 'Washington',state : 'DC', zipcode: '20016', price:'2995000', image:'house6.png', indicators : 
              
                  [
                      {code: 'GRM',indicator:'Gross-Rent-Multiplier', description:'Gross Rent Multiplier',passed : 'true',value:'26.5', valueType:'percent'},
                      {code: 'CAP',indicator:'Capitalization-Rate', description:'Capitalization Rate', passed : 'true',value:'75', valueType:'percent'},
                      {code: 'PI',indicator:'Profitability-Index', description:'Profitability Index',passed : 'false',value:'8.45', valueType:'percent'},
                      {code: 'IRR',indicator:'Internal-Rate-Return', description:'Internal Rate of Return', passed : 'true',value:'40', valueType:'percent'},
                      {code: 'DCR',indicator:'Debt-Coverage-Ratio', description:'Debt Coverage', passed : 'true',value:'11.5', valueType:'percent'},
                      {code: 'BER',indicator:'Break-Even-Ratio', description:'Break-Even Ratio', passed : 'false',value:'15.7', valueType:'percent'},
                      {code: 'NET',indicator:'Net-Cash-Flow', description:'Net Cash Flow', passed : 'true',value:'7.53', valueType:'percent'},
                      {code: 'COC',indicator:'Cash-on-Cash-Return', description:'Cash on Cash Return', passed : 'true',value:'1250', valueType:'currency'},
                      {code: '70P',indicator:'70-Percent-Rule', description:'70-Percent-Rule', passed : 'true',value:'70.64', valueType:'percent'}
                  ]    
              }
           
          ]};

          this.RenderIndicatorValue = this.RenderIndicatorValue.bind(this);
     }

    RenderIndicatorValue(value,valueType)
    {
        var formattedValue = '';

        if (valueType === 'currency')
        {
            /*
            formattedValue =  value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              });
              */

              var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
                });

                formattedValue = formatter.format(value);

        } else if (valueType === 'percent')
        {
            formattedValue = value+'%';
        }

        return formattedValue;
    }

    expandCollapseProperty(event,index,type) {

      event.preventDefault();

      //alert(index);
      //alert(type);

      var ele = document.querySelector('#propertyVisibility'+type+index);
      ele.style.display= "none";

      var propertyDiv = document.querySelector('#propertyContainer'+index); 

      var otherType=null;

      if (type === "Plus")
      {
        otherType = "Minus";
        propertyDiv.style.display= "block";
      }else
      {
        otherType = "Plus";
        propertyDiv.style.display= "none";
      }

      var otherDiv = document.querySelector('#propertyVisibility'+otherType+index);
      otherDiv.style.display= "block";
      
   }

     componentDidUpdate() {
        
    }

    componentDidMount() {
   
    }

    componentWillUnmount() {
    }

    PopulateDialog() 
    {
        this.openModal();
    }

  FetchRecordFromDatabase(id)
  {
    var record = {
   };


   return record;
  }
   
  CreateEmptyRecord()
  {
    var record = {
   };

   return record;
  }


    render() {

        return (
            <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} dialogClassName="property-modal-width"> 

            <Modal.Header closeButton>
              <Modal.Title>Alert Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal">
            
               <div class="modal-body">
                                <div classx="container">

                                    <div class="card2">
                                 
                                        <div class="card-body alert-edit-modal">

                                            <div class="tab-content">
                                              
                                                {/*  --------------------------------------------------------    */}
                                                <div class="main-toggle" style={{paddingLeft:'15px',paddingBottom:'5px'}}>
                                                  <button type="button" class="btn btn-success compare-btn" data-toggle="modal" data-target="#compare-modal-new">
                                                            Compare
                                                  </button> 
                                                  <br/><br/><br/>
                                                </div>
                    </div>
                                                <div class="top-row-list-properties">
                                              
                                                {this.state.properties.map((property, i) => (

                                                  <span>

                                                    <div id={`propertyVisibilityPlus${i}`} style={{display:"none"}}><a href="#!" style={{textDecoration:"underline"}}  onClick={(e) => this.expandCollapseProperty(e,i,"Plus")}><PatchPlus className="ml-2" style={{height:"24px",fontSize:"25px",color:"white",paddingBottom:"4px"}}/></a> {property.name}</div> 
                                                    <div id={`propertyVisibilityMinus${i}`}><a href="#!" style={{textDecoration:"underline"}}  onClick={(e) => this.expandCollapseProperty(e,i,"Minus")}><PatchMinus className="ml-2" style={{height:"24px",fontSize:"25px",color:"white",paddingBottom:"4px"}}/></a> </div> 
                                                      
                                                  <div id={`propertyContainer${i}`} class="row properties-row">
                                                      <div class="col-md-12">
                                                         <div class="custom-control custom-checkbox">
                                                              <input type="checkbox" class="custom-control-input" id={`propertyCheck${i}`} /> 
                                                              <label class="custom-control-label custom-label" for={`propertyCheck${i}`}>
                                                                  <div class="row">
                                                                      <div class="col-lg-5 properties-top-col">
                                                                          <div class="row">
                                                                              <div class="col-md-5 text-center">
                                                                                  <img src={`../images/properties/${property.image}`}  class="property-img" alt="" />
                                                                                  <h3 class="grid-price">{this.RenderIndicatorValue(property.price,"currency")}</h3>
                                                                              </div>
                                                                              <div class="col-md-7">
                                                                                  <h2 class="property-heading">{property.name}</h2>
                                                                                  <p class="house-address">{property.street} {property.city}, {property.state}, {property.zipcode}</p>
                                                                                  <hr />
                                                                                  <div class="indicators"><i class="fas fa-check-square greenish-tick"></i><i
                                                                                      class="fas fa-check-square greenish-tick"></i><i
                                                                                          class="fas fa-check-square greenish-tick"></i><i
                                                                                              class="fas fa-check-square greenish-tick"></i><i
                                                                                                  class="fas fa-check-square greenish-tick"></i><i
                                                                                                      class="fas fa-check-square greenish-tick"></i><i
                                                                                                          class="fas fa-check-square red-tick"></i><i class="fas fa-check-square red-tick"></i><i class="fas fa-check-square red-tick" style={{width:"5px",height:"5px"}}></i>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                      <div class="col-lg-7 value-top-col">
                                                                          <div class="row">

                                                                              {property.indicators.map((indicator, index) => (  
                                                                                
                                                                                  <div className={`col-md-3 property-indicator-width`}  style={{display:`${index > 4  && "none"}`}}>{index > 4  && "dont-display"}
                                                                                      {index <= 4  &&
                                                                                          <span>
                                                                                              <p class="rate-heading text-center">{indicator.description}</p>
                                                                                              <p className={`rate-value text-center ${indicator.passed === "true"  && "green-value"} ${indicator.passed === "false"  && "red-value"}`}>
                                                                                              
                                                                                                  {this.RenderIndicatorValue(indicator.value,indicator.valueType)}</p>
                                                                                          </span>
                                                                                      }
                                                                                  </div>

                                                                              ))}
                                                                          </div>

                                                                          <div class="row">

                                                                          {property.indicators.map((indicator, index) => (  
                                                                                
                                                                                <div className={`col-md-3 property-indicator-width`}  style={{display:`${index <= 4  && "none"}`}}>{index <= 4  && "dont-display"}
                                                                                    {index > 4  &&
                                                                                        <span>
                                                                                            <p class="rate-heading text-center">{indicator.description}</p>
                                                                                            <p className={`rate-value text-center ${indicator.passed === "true"  && "green-value"} ${indicator.passed === "false"  && "red-value"}`}>
                                                                                            
                                                                                                {this.RenderIndicatorValue(indicator.value,indicator.valueType)}</p>
                                                                                        </span>
                                                                                    }
                                                                                </div>

                                                                            ))}

                                                                          </div>
                                                                          
                                                                      </div>
                                                                  </div>
                                                                  <div class="row">
                                                                      <div class="col-md-12">
                                                                          <h3 class="total-value">{this.RenderIndicatorValue(property.price,"currency")}</h3>
                                                                          <div>
                                                                            <button class="btn  btn-warning  view-btn">View Details</button>
                                                                            <span>&nbsp;</span>
                                                                            <button class="btn  btn-success  view-btn">Add To My Properties</button>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </label>
                                                          </div>


                                                  </div>
                                                  </div>
                                                  </span>
                                                  ))} 

                                                {/*  --------------------------------------------------------    */}

                                                </div>

                                            </div>
                                        </div>
                                   
                                        <div class="modal-footer">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-5">&nbsp;</div>
                                                    <div class="col-md-2">
                                                              &nbsp;
                                                              {/*  Footer content*/} 
                                                      </div>
                                                    <div class="col-md-5">&nbsp;</div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>


                                </div>
                                                    


            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>
          </Modal>

        );
    }
}

export default (PropertiesEditDialog);

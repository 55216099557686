import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../pages/img/icons/stop2-sm.png';
import goicon from '../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../pages/img/indicators/traffic-lights-red.png';

import GrossRentMultiplier from './indicators/GrossRentMultiplier';
import BackEndRatio from './indicators/BackEndRatio';
import FrontEndRatio from './indicators/FrontEndRatio';
import DebtCoverageRatioHome from './indicators/DebtCoverageRatioHome';
import DebtCoverageRatioFlipping from './indicators/DebtCoverageRatioFlipping';
import DebtCoverageRatioRental from './indicators/DebtCoverageRatioRental';

import CostOfDebt from './indicators/CostOfDebt';
import PriceToRentRatio from './indicators/PriceToRentRatio';
import LoanToValueRatio from './indicators/LoanToValueRatio';
import PricePerSize from './indicators/PricePerSize';
import MarketValue from './indicators/MarketValue';
import CreditScore from './indicators/CreditScore';
import RuleOf70 from './indicators/RuleOf70';
import KeyRiskIndicator from './indicators/KeyRiskIndicator';
import NetProfit from './indicators/NetProfit';
import ROI from './indicators/ROI';
import Budget from './indicators/Budget';
import BudgetHome from './indicators/BudgetHome';
import IRR from './indicators/IRR';
import ProfitabilityIndex from './indicators/ProfitabilityIndex';
import CapRate from './indicators/CapRate';
import CashOnCash from './indicators/CashOnCash';
import BreakEvenRatio from './indicators/BreakEvenRatio';
import NetCashFlow from './indicators/NetCashFlow';


class IndicatorInfoDialog extends Component { 
    state = {
        isOpen: false,
        indicator: {} 
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    PopulateDialog(indicator) 
    {
        //alert("IndicatorInfoDialog");
        //alert(JSON.stringify(indicator));

        this.setState({
            indicator: indicator
          });
        

        this.openModal();
    }

  FetchRecordFromDatabase(id)
  {
    var record = {
   };


   return record;
  }
   
  CreateEmptyRecord()
  {
    var record = {
   };

   return record;
  }

    render() {

        return (
            <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} dialogClassName="info-modal-width"> 

            <Modal.Header closeButton>
              <Modal.Title><a href="#" onClick={this.closeModal}>&#60; Back</a></Modal.Title>
            </Modal.Header>
            <Modal.Body class="indicator-info-modal" style={{paddingLeft:'10px',paddingRight:'10px'}}>
            
                             
               { this.state.indicator.indicatorAcronym ==="GRM" &&    <GrossRentMultiplier indicator={this.state.indicator}/>}
			         { this.state.indicator.indicatorAcronym ==="BE" &&     <BackEndRatio indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="FE" &&     <FrontEndRatio indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="DCR" &&  this.state.indicator.property.investmentType ==="Primary Home" &&  <DebtCoverageRatioHome indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="DCR" &&  this.state.indicator.property.investmentType ==="Flipping Property" &&  <DebtCoverageRatioFlipping indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="DCR" &&  this.state.indicator.property.investmentType ==="Rental Property" &&  <DebtCoverageRatioRental indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="COD" &&     <CostOfDebt indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="PTR" &&     <PriceToRentRatio indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="LVR" &&     <LoanToValueRatio indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="PPQ" &&     <PricePerSize indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="MVP" &&     <MarketValue indicator={this.state.indicator}/>}  
               { this.state.indicator.indicatorAcronym ==="CS" &&     <CreditScore indicator={this.state.indicator}/>}  
               { this.state.indicator.indicatorAcronym ==="R70" &&     <RuleOf70 indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="KRI" &&     <KeyRiskIndicator indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="NP" &&     <NetProfit indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="ROI" &&     <ROI indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="BUDGET" && this.state.indicator.property.investmentType ==="Flipping Property" &&  <Budget indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="BUDGET" && this.state.indicator.property.investmentType ==="Primary Home" && <BudgetHome indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="IRR" &&   <IRR indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="PI" &&   <ProfitabilityIndex indicator={this.state.indicator}/>}                  
               { this.state.indicator.indicatorAcronym ==="CAP" &&   <CapRate indicator={this.state.indicator}/>} 
               { this.state.indicator.indicatorAcronym ==="COC" &&   <CashOnCash indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="BER" &&   <BreakEvenRatio indicator={this.state.indicator}/>}
               { this.state.indicator.indicatorAcronym ==="NCF" &&   <NetCashFlow indicator={this.state.indicator}/>}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>
          </Modal>

        );
    }
}

export default (IndicatorInfoDialog);

import React from "react";

import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import "../pages/css/checkout.css";

import poweredByStripe from '../pages/img/help/powered-by-stripe.png';

import {createTheme,ThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import axios from 'axios';
import { authMiddleWare } from '../utilities/Auth';

class CheckoutForm extends React.Component {

  constructor(props) {
		super(props);

    this.state = {
			name: '',
			email: '',
			phoneNumber: '',
			username: '',
			country: '',
			username: '',
      blogUrl: '',
			type: '',
      hasSubscription: false,
			stripeCustomerId:'',
			stripeSubscriptionId:'',
      stripeSubscriptionStatus:'',
      cardIsValid: false,
      disableButton: 'true'
		};
	}
  
  componentWillMount = () => {

    let hasSubscription = false;

    if (this.props.user.stripeSubscriptionId != null && this.props.user.stripeSubscriptionId !='')
    {
        hasSubscription = true;
    }

    this.setState({
      name: this.props.user.name,
      email: this.props.user.email,
      hasSubscription: hasSubscription,
      stripeCustomerId: this.props.user.stripeCustomerId,
      stripeSubscriptionId: this.props.user.stripeSubscriptionId,
      stripeSubscriptionStatus: this.props.user.stripeSubscriptionStatus,
      uiLoading: false
    });
  

	};

  componentWillReceiveProps(nextProps) {
        
    //alert(JSON.stringify(this.props));

    //alert(this.props?.paymentMethod?.billing_details?.name);

    //alert(this.props?.paymentMethod);

    this.setState({
      name: this.props?.paymentMethod?.billing_details?.name,
    });

  }

  handleCardChange = (event) => {
		
    if (event.complete) {
    
        if (!event.error) {
        
          this.setState({
            cardIsValid: true
          }, () => {
            this.checkButtonShouldBeEnabled();            
          });

        } else
        {
          this.setState({
            cardIsValid: false
          }, () => {
           this.checkButtonShouldBeEnabled();            
          });
        }
    } else
    {
      this.setState({
        cardIsValid: false
      }, () => {
        this.checkButtonShouldBeEnabled();            
      });
    }
  
	};

  checkButtonShouldBeEnabled = () =>
  {
     
      if (this.state.name.trim() != '' && 
          this.state.email.trim() != '' && 
          this.state.cardIsValid)
      {
     
        this.setState({
          disableButton: ''
        });
      }else
      {
        
        this.setState({
          disableButton: 'true'
        });
      }

  }

  handleChange = (event) => {

    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.checkButtonShouldBeEnabled();            
    });

	};

  handleSubmit = async event => {

    event.preventDefault();

    const targetAction = this.props.targetAction;

    const stripeCustomerId = this.state.stripeCustomerId;

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {

      return;

    }

    const card = elements.getElement(CardElement);

    if (this.state.name.trim() != '' && 
        this.state.email.trim() != '' && 
       this.state.cardIsValid)
    {

      this.setState({
        disableButton: 'true'
      });

      const name = this.state.name;
      const email = this.state.email;
      const phoneNumber = this.state.phoneNumber;
      const stripeSubscriptionStatus = this.state.stripeSubscriptionStatus;
      authMiddleWare(this?.props?.history);

      stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: this.state.name,
        },
      })
      .then(function(result) {
      
              const paymentMethodId = result.paymentMethod.id;

    //          alert(paymentMethodId);

              //If it just unpaid then update user default payment method
              if (stripeSubscriptionStatus ==='unpaid' || targetAction==='EditPaymentMethod')
              {

                //Call Backend to update payment method
                authMiddleWare(this?.props?.history);
                const authToken = localStorage.getItem('AuthToken');
                const refreshToken = localStorage.getItem('RefreshToken');
                axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                axios
                  .post('/api/updatePaymentMethod', {
                    customerId: stripeCustomerId,
                    paymentMethodId: paymentMethodId
                  })
                  .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }
    

                    window.location.reload();
                   
                  })
                  .catch((error) => {
                    alert("Error occurred. Try again later.");
                    window.location.reload();

                    console.log('Error in updating customer record:'+error);
            
                  });

              } else // Create new subscription
              {

                  //Call Backend to subscribe user
                  authMiddleWare(this?.props?.history);
                  const authToken = localStorage.getItem('AuthToken');
                  const refreshToken = localStorage.getItem('RefreshToken');
                  axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                  axios
                    .post('/api/subscribe', {
                      name: name,
                      email: email,
                      phoneNumber: phoneNumber,
                      paymentMethodId: paymentMethodId
                    })
                    .then((response) => {
                
                      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                      {
                          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                      }
      
                        window.location.reload();
                      
                    })
                    .catch((error) => {
                      alert("Error with your submission. Try again later.");
                      window.location.reload();
                                              
                      console.log('Error in subscribing user:'+error);
                      
                    });

                  } // end if 




      }).catch(function(err) {
        alert("Error processing. Try again later:"+err);
        window.location.reload();
                          
        console.log(err);
      });

    } else {

      this.setState({
        disableButton: 'true'
      });

      alert("Please enter all required fields: Name, Email and Credit Card");
    }


  };

  
  render() {

    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "20px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fc0d1b",
          iconColor: "#fc0d1b",
        },
      },
    };

    const theme = createTheme({
      palette: {
        primary: {
          main: '#e37627' //your color
        }
      }
    });

    const { errors } = this.state;

    return (

      <div>
    
      <form onSubmit={this.handleSubmit}>

      <div className="sr-combo-inputsX" style={{maxWidth:"600px"}}>
        <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="text"
              id="name"
              name="name" 
              value= {this.state.name} 
              placeholder="Name on Card"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px",fontSize:'20px'}} 
              onChange={this.handleChange}
            />
          </div>

          <br></br>
          
          <div className="sr-combo-inputs-rowx" style={{paddingBottom:"10px"}}>
            <CardElement
              className="sr-input sr-card-element"
              options={options} 
              disabled={this.state.uiLoading} 
              style={{borderRadius:"4px"}} 
              onChange={this.handleCardChange}
            />
          </div>

          
        </div>


    <br></br>
        <center>
            <ThemeProvider theme={theme}>
                <Button
                        type="submit"
                        fullWidth
                        variant="contained" 
                        style={{color:'#FFFFFF',fontSize:'20px',fontWeight:'bold',maxWidth:'400px'}}
                        color="primary" 
                        disabled={this.state.disableButton}
                    >
                        {this.props.targetAction==='EditPaymentMethod'?'Update Payment Method':'Pay'}
                    </Button>
            </ThemeProvider> 
        </center>


      </form>
      <br></br>

    <img src={poweredByStripe} style={{height:"80px"}}/>
    <br></br>

    <div className="banner">
        <span style={{marginTop:"10px",marginBottom:"5px"}}>

          We don't save your payment information, your payment is handled directly my our payment processor. We use <strong>Stripe</strong> as our payment processor, which adheres to the standards set by <strong>PCI-DSS</strong> as managed by the <strong>PCI</strong> Security Standards Council. You can read about Stripe's PCI-DSS compliance <a href="https://stripe.com/guides/pci-compliance" target="_blank">here</a>. 

        </span>
    </div>

      </div>

    );

  }

}


export default function InjectedCheckoutForm(props) {

  return (

    <ElementsConsumer>

      {({ stripe, elements }) => (

        <CheckoutForm stripe={stripe} elements={elements} targetAction={props.targetAction} user={props.user} paymentMethod={props.paymentMethod}/>

      )}

    </ElementsConsumer>

  );

}

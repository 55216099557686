import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">
                                    <div style={{paddingBottom:'12px'}}>
                                    The <b>key risk indicator</b> calculates the maximum allowable percentage that the property can drop in price while you hold the property before the investment starts losing money. 
                                    
                                    <br></br><br></br>
                                    When the property is a fixer-upper, be aware that the market may be subject to fluctuations while you are repairing it. Since timing the market is not feasible, it's important that you assess your risk level in case property values drop during the time you hold the property.
                                    </div>

                                    <div style={{paddingBottom:'12px'}}>
                                    The calculation of this risk indicator depends on your ability to buy the property below market value and the number of months you plan to hold the property. The longer you hold the property, the higher the risk becomes. 
                                    
                                    </div>
                                   
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The higher the market value drop you can absorb, the better.  
                                    <br></br><br></br>
                                    Therefore you should try to buy the property below market value and also hold the property for the least number of months to minimize your risk.
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>key risk indicator</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Purchase Price
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            After Repair Value
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_AVR}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Rehab Costs
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_REPAIRS}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row">

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Holding Months
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_HOLDING_MONTHS}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                         
                                    
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                        {/* ---------------------  STEP 1 ------------------ */}
                                        <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Market Value</b>
                                           </div>

                                           <div style={{paddingLeft:'10px',paddingRight:'30px'}}>  
                                           The After Repair Value of the property minus the rehab costs represents market value of the property.
                                           </div>
                                       </div>

                                       <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'40%'}}>
                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"left",maxWidth:'250px'}}>
                                                            After Repair Value
                                                        </div>
                                            </div>
                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            -
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"left",maxWidth:'250px'}}>
                                                            Rehab Costs
                                                        </div>
                                            </div>

                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{borderTop:"2px solid  #1c7cfb",textAlign:"left",maxWidth:'250px'}}>
                                                            <b>Market Value</b>
                                                        </div>
                                            </div>

                                            
                                        <br></br>
                                       
                                        <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"right",maxWidth:'100px'}}>
                                                            {this.state.indicator.helpVariableMap.VAR_AVR}
                                                        </div>
                                            </div>
                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            +
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"right",maxWidth:'100px'}}>
                                                            {this.state.indicator.helpVariableMap.VAR_REPAIRS}
                                                        </div>
                                            </div>

                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'100px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_MARKET_VALUE}</b>
                                                        </div>
                                            </div>                                   
                                        
                                        </div>
                                   
                                        {/* ---------------------  STEP 2 ------------------ */}
                                        <br></br>
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 2:</span> <b>Calculate the Market Value Percent</b>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:'10px'}}>
                                                <table>
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Market Value Percent= </td>
                                                            <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}>
                                                                     <b> 
                                                                       Market Value - Purchase Price
                                                                    </b>
                                                                </div>
                                                                 
                                                                <b>Market Value</b>

                                                            </td>
                                                            <td> x 100</td>
                                                        </tr>

                                                    </table>

                                                    <div style={{paddingTop:'25px'}}>
                                                        <table>
                                                            <tr>
                                                                <td style={{paddingTop:'5px'}}>Market Value Percent = </td>
                                                                <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                    <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>{this.state.indicator.helpVariableMap.VAR_MARKET_VALUE} - {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}</b></div>
                                                                    
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_MARKET_VALUE}</b>

                                                                </td>
                                                                <td> x 100</td>

                                                            </tr>

                                                        </table>
                                                    </div>

                                                    <div style={{paddingTop:'25px'}}>
                                                        <table>
                                                            <tr>
                                                                <td style={{paddingTop:'5px'}}>Market Value Percent = </td>
                                                                <td style={{textAlign:"left",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_MARKET_VALUE_PERCENT}</b>
                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>

                                                   
                                                    <div style={{paddingTop:'25px'}}>
                                                        {this.state.indicator.meetsBenchmark && 
                                                            <span>
                                                                You are buying the property <b>{this.state.indicator.helpVariableMap.VAR_MARKET_VALUE_PERCENT}</b> below 
                                                                market value, <br></br>
                                                                basically you are paying less than it is worth, which is good.
                                                            </span>
                                                        }

                                                        {!this.state.indicator.meetsBenchmark && 
                                                            <span>
                                                                You are buying the property <b>{this.state.indicator.helpVariableMap.VAR_MARKET_VALUE_PERCENT}</b> above 
                                                                market value, <br></br>
                                                                basically you are paying more than it is worth.
                                                            </span>
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                   
                                        <br></br>
                                        {/* ---------------------  STEP 3 ------------------ */}
                                        <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 3:</span> <b>Calculate the maximum market value drop <br></br>
                                               <span style={{paddingLeft:'55px'}}>you can absorb each month before you</span> <br></br>
                                               <span style={{paddingLeft:'55px'}}>start losing money.</span>
                                               </b>
                                           </div>
                                       </div>
                                       
                                       <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:'10px'}}>
                                                <table>
                                                        <tr>
                                                            <td style={{paddingTop:'5px',maxWidth:'225px'}}>Maximum Monthly Market Value Drop</td>
                                                            <td styleX={{maxWidth:'15px'}}> = </td>
                                                            <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}>
                                                                     <b> 
                                                                       Market Value Percentage
                                                                    </b>
                                                                </div>
                                                                 
                                                                <b>Holding Months</b>

                                                            </td>
                                                        </tr>

                                                    </table>

                                                    <div style={{paddingTop:'25px'}}>
                                                        <table>
                                                            <tr>
                                                                <td style={{paddingTop:'5px',maxWidth:'225px'}}>Maximum Monthly Market Value Drop</td>
                                                                <td styleX={{paddingTop:'5px'}}> = </td>
                                                                <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                    <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>{this.state.indicator.helpVariableMap.VAR_MARKET_VALUE}</b></div>
                                                                    
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_HOLDING_MONTHS}</b>

                                                                </td>

                                                            </tr>

                                                        </table>
                                                    </div>

                                                    
                                                    <div style={{paddingTop:'25px'}}>
                                                        <table>
                                                            <tr>
                                                                <td style={{paddingTop:'5px',maxWidth:'225px'}}>Maximum Monthly Market Value Drop</td>
                                                                <td styleX={{paddingTop:'5px'}}> = </td>
                                                                <td style={{textAlign:"left",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                    <b>{this.state.indicator.indicatorValue}%</b>
                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                        </div>
                                      
                                  
                                        {/* ---------------------  STEP 4 ------------------ */}
                                         <br></br>               

                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 4:</span> <b>Results</b>
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                    Key Risk Indicator = 
                                                    </div>
                                                    <div class="col-md-4 indicator-info-formula-table-col1">
                                                           <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                    </div>
                                                </div>
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                              The higher the market value drop you can absorb, the better.  
                                              You can increase the maximum market value drop you can
                                              absorb by shortening your holding months or by paying 
                                              less for the same property.
                                            </div>
                                        </div>

                                   

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Key Risk Indicator benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                PURCHASE PRICE<br></br>
                                                HOLDING MONTHS
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                  {/* TIP */}
                  {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>2)</b> Hold the property for less time. Lower the <span style={{fontWeight:'600'}}>holding months</span><br></br>
                        </div>

                    </div>        
                }                                               

                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { Card, CardContent} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});


class subscriptionDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {

		};
	}

	componentWillMount = () => {
	

	};

	goToUpGrade = (event) => {
    
    //    if (this.state.user.planName !=='')
    //    {
    //        window.location.href ="/signup/terms-and-conditions";

    //    }else
    //    {
            //this.state.props.history.push('/pricing');
            window.location.href ="/pricing";
     //   }
    }

	render() {
		const { classes, ...rest } = this.props;

		return (
			<div>
					<h1 class="accountLabel">Your Subscription</h1>
                    <p class="accountLabel">Manage your subscription.</p>
				
					<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Card className={classes.root} variant="outlined">
									<CardContent>
										<Typography variant="h5" component="h2">
											<span style={{color:"#e37627",fontWeight:"500"}}>Trial Subscription</span>
										</Typography>
										<Typography className={classes.pos} style={{fontSize:'18px'}} color="textSecondary">
											
											<div style={{color:'#000000'}}>
												This is a trial subscription, upgrade to a paying subscription to get all the features of the application.
											</div>

											<div style={{paddingTop:'10px'}}>

													<Button variant="contained" style={{backgroundColor:"#e37627",height:"25px", color:"#FFFFFF",fontSize:'20px' ,paddingTop:'20px', paddingBottom:'20px', paddingLeft:'30px', paddingRight:'30px'}}  onClick={this.goToUpGrade}>UPGRADE</Button>
											</div>

										</Typography>
									</CardContent>
									<CardActions>
                      
					
									</CardActions>	
								</Card>		
							</Grid>
					</Grid>	
			</div>
		);

 }
}


export default withStyles(styles)(subscriptionDetails);

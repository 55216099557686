import React, { Component } from 'react';

import {LegalDisclaimerPageHTML} from '../static/SignUp_LegalDisclaimerPageHTML';

import axios from 'axios';

import Dialog from 'react-bootstrap-dialog'
import withStyles from '@material-ui/core/styles/withStyles';

import { authMiddleWare } from '../utilities/Auth';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#ff642d'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class main extends Component {

	state = {
        displayScreen:false,
		loading: false,
	};

	constructor(props) {
		super(props);

		this.indexPageElement = null;

	}

	componentWillMount = () => {

		try
		{			
			// 2. check user is logped in
			console.log("About to check logged in user.");

			authMiddleWare(this?.props?.history);
			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.get('/api/alreadyLoggedInCheck')
				.then((response) => {

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					//console.log(JSON.stringify(response));

					//If user is logged in
					if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
					{
						//Retrieve user
						authMiddleWare(this?.props?.history);
						const authToken = localStorage.getItem('AuthToken');
						const refreshToken = localStorage.getItem('RefreshToken');
						axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                        axios
                        .get('/api/user')
                        .then((response) => {
                            //console.log(response.data);
           
							if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
							{
								localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
								localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
							}
			
							const user = response.data;

                            this.setState({ 
                                user: response.data,
								email: response.data.email,
								displayScreen:true
                            });	

							localStorage.setItem('SessionExpired','N')

							if (user.termsAndConditionsAccepted != null && user.termsAndConditionsAccepted ==="Y")
							{
								this.props.history.push('/signup/billing-information');
							}
                            
                        })
                        .catch((error) => {
                            console.log('Error in getting user record:'+error);
        
                            this.setState({ 
                                user: null,
								displayScreen:true
                            });	
                            //If user is not logged in redirect to signup
							this.props.history.push('/signup');

                    
                          });
					} 

				})
				.catch((error) => {
					//console.log(error);
					this.setState({ 
						user: null,
						displayScreen:true
					});	

					if(error.response.status === 403) {
						
						//If user is not logged in redirect to signup
						this.props.history.push('/signup');
					}
					
				});

			
			} catch(err)
			{
				this.setState({ 
					user: null,
					displayScreen:true
				});	
				console.log("Error checking logged in user:"+err);
			
			}
	}

	componentDidMount= () => {
	
	  };

	displayAlert = (title,message) => {

		this.dialog.show({
			title: title,
			body: message,
			actions: [
			  Dialog.OKAction(() => {
				//console.log('OK was clicked!')
				
			  })
			],
			bsSize: 'small',
			onHide: (dialog) => {
			  dialog.hide()
			  
			}
		  });
	};

	goHome = () => {
		window.location.href ="/";
	};
	
	buttonClicked = (event) => {
	
		event.preventDefault();
	
		const payload = {
		};

		this.setState({ 
			loading: true,
		});

		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };  

		axios
		.post('/api/acceptTermsAndConditions', payload)
		.then((response) => {
				
			if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
			{
				localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
				localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
			}

				this.setState({ 
					loading: false,
				});
				
				this.props.history.push('/signup/billing-information');

			})
			.catch((error) => {
		
				this.displayAlert("Error","Error accepting terms, please try again");

				this.setState({
					errors: error,
					loading: false
				});
			});

	};

	render() {

		const { classes } = this.props;
		const { errors, loading } = this.state;

			return (
				<div>
					{this.state.displayScreen && 
                		<LegalDisclaimerPageHTML handleClick={this.buttonClicked} goHome={this.goHome}  showButton={true} user={this.state.user} loading={this.state.loading} classes={classes}/>
					}
					<Dialog ref={(component) => { this.dialog = component }}/> 
				</div>
				
	)};

}

export default withStyles(styles)(main);

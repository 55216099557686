import React, { Component } from 'react';

import { Helmet } from "react-helmet";

import { FileEarmarkText } from 'react-bootstrap-icons';
import { Trash } from 'react-bootstrap-icons';
import { BarChartLine } from 'react-bootstrap-icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import IndicatorInfoDialog from '../dialogs/IndicatorInfoDialog';
import PropertyEditDialog from '../dialogs/PropertyEditDialog';
import PhotoDialog from '../dialogs/PhotoDialog';

import { getPlanInfo, convertPlanInfoUnlimitedToNumbers, getElapsedHours } from '../utilities/AppUtility';

import { isIndicatorDisabled } from '../utilities/IndicatorUtility';

import Dialog from 'react-bootstrap-dialog'

//import BLO from '../utilities/BLO';
import * as BLO from '../utilities/BLO';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

const { maxTrialPropertiesToShow, maxTrialPropertiesToAllowToAdd } = require('../utilities/Constants');

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
});

class Dashboard extends Component {

    state = {
        properties:[],
        uiLoading: true         
    };

    constructor(props) {
        super(props);

            this.indicatorInfoDialogRef = React.createRef();
            this.propertyEditDialogRef  = React.createRef();
            this.photoDialogRef = React.createRef();

    }

    componentWillMount = () => {

        //console.log("PROPERTIES:");
        //console.log(JSON.stringify(this.props.properties));

        if (this.props.properties == null)
        {
            this.loadProperties();
        } else 
        {
            this.setState({
                properties: this.props.properties,
                uiLoading: false
            });   
        }

        if (this.props.settings == null || (this.props.settings != null && this.props.settings.benchmarkSettings == null))
        {
            this.loadSettings();
        }

    };

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.properties != null && (this.props.properties.length !== nextProps.properties.length || JSON.stringify(this.props.properties) !== JSON.stringify(nextProps.properties) )) 
        {
 
         this.setState({
             properties: nextProps.properties,
             uiLoading: false
         });  
 
        }
 
      }; 

    loadProperties()
    {
        this.props.parentRef.loadProperties();
    }

    loadSettings()
    {
        try
        {
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
            axios
                .get('/api/settings')
                .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }
    
                    const settings = response.data;
                    const benchmarkSettings = BLO.getIndicatorBenchmarkSettings(settings);
                    settings.benchmarkSettings = benchmarkSettings;

                    //console.log("benchmarkSettings:");
                    //console.log(JSON.stringify(benchmarkSettings));

                    //console.log("Account Settings:");
                    //console.log(JSON.stringify(settings));

                    this.props.settings = settings;

                    this.setState({
                        settings: settings
                    });
                    
                })
                .catch((error) => {
                    console.log(error);
                    if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                    }
                });
            } catch(error)
            {
              console.log(error);   
            }

    }

    GetBenchmarkSettings(settings)
    {
      if (settings == null)
      {
        return BLO.getIndicatorBenchmarkSettings(settings);
      }else
      {
        return settings.benchmarkSettings;
      }
    }
  
    RenderIndicatorValue(value,valueType)
    {
        var formattedValue = value;

        if (valueType === 'DOLLAR_AMOUNT')
        {
            /*
            formattedValue =  value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              });
              */

              var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
                });

                formattedValue = formatter.format(value);

        } else if (valueType === 'PERCENT')
        {
            formattedValue = value+'%';
        }

        return formattedValue;
    }

    getIndicators(indicators,index1,index2,index3)
    {
        const filterIndicators = [];

        filterIndicators.push(indicators[index1]);
        filterIndicators.push(indicators[index2]);
        filterIndicators.push(indicators[index3]);

        return filterIndicators;
    }

    openPhotoDialog(property,event) {

        event.preventDefault();

        var dialog = this.photoDialogRef.current;
        dialog.PopulateDialog(property);
    
    }


    openIndicatorInfoDialog(id,event,property,indicator) {

        event.preventDefault();

        const isDisabled = isIndicatorDisabled(property,indicator);

        if (isDisabled)
        {
            alert("This indicator is not applicable when you are paying for the property entirely with cash.");
        } else
        {
            //Although we have the indicator that was calculated by the back end jobs, it doesn't have the 
            //display variables needed for display since we excluded those from the back end to keep the 
            //payload small and to fix a circular reference issue in the lambda.
            const calculatedIndicator = this.calculateIndicator(property,indicator);

            //Also calculate future cashflows (Needed only for rental, PI and IRR)
            const clonedProperty = JSON.parse(JSON.stringify(property));

            const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
            const profile = this.props?.profile;
            
            //Needs to come from profile
            settings.budgetAccepted=profile?.budget;   
            settings.budgetRejected=profile?.budget;   
            settings.budgetAcceptedCompareId=5;
            settings.budgetRejectedCompareId=1;
        
            //Needs to come from property
            settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
            settings.pricePerSizeRejected = property.trendingPricePerSqft;
            settings.pricePerSizeAcceptedCompareId =  5;
            settings.pricePerSizeRejectedCompareId =  1;

            const propertyCashFlowMaps = BLO.calculateFutureCashFlows(clonedProperty,settings)

            calculatedIndicator.propertyCashFlowMaps = propertyCashFlowMaps; 
            clonedProperty.propertyCashFlowMaps = propertyCashFlowMaps; 
            calculatedIndicator.property = clonedProperty;

            //alert("Opening dialog info");
            var dialog = this.indicatorInfoDialogRef.current;
            dialog.PopulateDialog(calculatedIndicator);
           
        }

    }

    calculateIndicator(property,indicator)
    {

        switch(property.investmentType) {

            case "Primary Home":
              indicator = this.populatePrimaryHomeIndicator(property,indicator);
              break;
            case "Rental Property":
              indicator = this.populateRentalIndicator(property,indicator);
              break;
            case "Flipping Property":
              indicator = this.populateFlippingIndicator(property,indicator);
              break;
            
          }

        return indicator;
    }

    populatePrimaryHomeIndicator(property,indicator)
    {
      let calculatedIndicator = null;

      //const settings = Settings.GetProfileBenchmarkingSettings();
      const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
      const profile = this.props?.profile;
      
      //Needs to come from profile
      settings.budgetAccepted=profile?.budget;   
      settings.budgetRejected=profile?.budget;   
      settings.budgetAcceptedCompareId=5;
      settings.budgetRejectedCompareId=1;
  
      //Needs to come from property
      settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
      settings.pricePerSizeRejected = property.trendingPricePerSqft;
      settings.pricePerSizeAcceptedCompareId =  5;
      settings.pricePerSizeRejectedCompareId =  1;

      switch(indicator.indicatorAcronym) {

        case "BE":
             
            calculatedIndicator = BLO.getBackEndRatioIndicator(property,settings);
                
          break;
        
        case "FE":
            
            calculatedIndicator = BLO.getFrontEndRatioIndicator(property,settings);    
                
          break;
        
        case "DCR":
            
            calculatedIndicator = BLO.getDebtCoverageRatioHomeIndicator(property,settings);
                
          break;

        case "LVR":
              
            calculatedIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
                
            break;

        case "COD":
            
            calculatedIndicator = BLO.getCostOfDebtIndicator(property,settings);
              
            break;

        case "MVP":
            
            calculatedIndicator = BLO.getMarketValuePercentIndicator(property,settings);
              
            break;

        case "PPQ":
            
            calculatedIndicator = BLO.getPricePerSizeIndicator(property,settings);
          
            break;

        case "PTR":
            
            calculatedIndicator = BLO.getPriceToRentRatioIndicator(property,settings);
      
            break;

        case "CS":
            
            calculatedIndicator = BLO.getCreditScoreIndicator(property,settings);
      
            break;
    
        case "BUDGET":
                
                calculatedIndicator = BLO.getBudgetHomeIndicator(property,settings);
            
                break;
        
      }

      return calculatedIndicator;
    }
    
    populateFlippingIndicator(property,indicator)
    {
        let calculatedIndicator = null;

        //const settings = Settings.GetProfileBenchmarkingSettings();
        const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
        const profile = this.props?.profile;
    
        //Needs to come from profile
        settings.budgetAccepted=profile?.budget;   
        settings.budgetRejected=profile?.budget;   
        settings.budgetAcceptedCompareId=5;
        settings.budgetRejectedCompareId=1;
    
        //Needs to come from property
        settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
        settings.pricePerSizeRejected = property.trendingPricePerSqft;
        settings.pricePerSizeAcceptedCompareId =  5;
        settings.pricePerSizeRejectedCompareId =  1;

        switch(indicator.indicatorAcronym) {

            case "R70":
                    
                calculatedIndicator = BLO.get70PercentRuleIndicator(property,settings);
                      
              break;
            
            case "KRI":
                
                calculatedIndicator = BLO.getKeyRiskIndicator(property,settings);    
                      
              break;
            
            case "DCR":
                
                calculatedIndicator = BLO.getDebtCoverageRatioFlipIndicator(property,settings);
              
              break;
    
            case "LVR":
                
                calculatedIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
                      
                break;
    
            case "ROI":
                
                calculatedIndicator = BLO.getROIIndicator(property,settings);
                    
                break;
    
            case "NP":
                
                calculatedIndicator = BLO.getNetProfitIndicator(property,settings);
                    
                break;
    
            case "MVP":
                  
                calculatedIndicator = BLO.getMarketValuePercentIndicator(property,settings);
                
                break;
    
            case "PPQ":
                
                calculatedIndicator = BLO.getPricePerSizeIndicator(property,settings);
            
                break;
    
            case "BUDGET":
                
                calculatedIndicator = BLO.getBudgetIndicator(property,settings);
            
                break;
        
          }

          return calculatedIndicator;
    }
  
    populateRentalIndicator(property,indicator)
    {
        let calculatedIndicator = null;

        //const settings = Settings.GetProfileBenchmarkingSettings();
        const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
        const profile = this.props?.profile;
        
        //Needs to come from profile
        settings.budgetAccepted=profile?.budget;   
        settings.budgetRejected=profile?.budget;   
        settings.budgetAcceptedCompareId=5;
        settings.budgetRejectedCompareId=1;
    
        //Needs to come from property
        settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
        settings.pricePerSizeRejected = property.trendingPricePerSqft;
        settings.pricePerSizeAcceptedCompareId =  5;
        settings.pricePerSizeRejectedCompareId =  1;

        switch(indicator.indicatorAcronym) {

            case "GRM":
                  
                calculatedIndicator = BLO.getGRMIndicator(property,settings);
                      
              break;
            
            case "CAP":
                
                calculatedIndicator = BLO.getCapRateIndicator(property,settings);
                      
              break;
            
            case "COC":
                
                calculatedIndicator = BLO.getCashOnCashIndicator(property,settings);
              
              break;
    
            case "PI":
                  
                calculatedIndicator = BLO.getProfitabilityIndexIndicator(property,settings);
                      
                break;
    
            case "IRR":
                
                calculatedIndicator = BLO.getInternalRateOfReturnIndicator(property,settings);
                    
                break;
    
            case "DCR":
                
                calculatedIndicator = BLO.getDebtCoverageRatioRentalIndicator(property,settings);
            
                break;
    
            case "BER":
                
                calculatedIndicator = BLO.getBreakEvenRatioIndicator(property,settings);
                
                break;
    
            case "LVR":
                
                calculatedIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
            
                break;
    
            case "NCF":
                
                calculatedIndicator = BLO.getNetCashFlowIndicator(property,settings);

                break;
        
          }

          return calculatedIndicator;
    }
    
    getIndicatorValue(property,indicator)
    {
        let value = "";

        const isDisabled = isIndicatorDisabled(property,indicator);

        if (isDisabled)
        {
            value = "N/A";
        } else
        {
            value = this.RenderIndicatorValue(indicator.indicatorValue,indicator.valueType);
        } 

        return value;

    }

    getDemoProfile() 
    {
        let demoProfile = null;

        try
        {
            if (this.props.parentRef != null
                &&
                this.props.parentRef.props != null 
                &&
                this.props.parentRef.props.parentRef != null 
                &&
                this.props.parentRef.props.parentRef.state != null 
                &&
                this.props.parentRef.props.parentRef.state.profiles != null 
                &&
                this.props.parentRef.props.parentRef.state.profiles.length > 0
                )
                {
                    const profiles = this.props.parentRef.props.parentRef.state.profiles;
                    const demoProfiles = profiles.filter(profile => profile.type === 'demo');
                    demoProfile = demoProfiles[0];
                }

        }catch(error)
        {

        }

        return demoProfile;
    }
    

    openPropertyEditDialog(property,event) {

        event.preventDefault();

        let planInfo = getPlanInfo(this.props.user.planName);
        planInfo = convertPlanInfoUnlimitedToNumbers(planInfo);

        let title = "Plan Limit Exceeded";
        let message= "You have exceeded the number of properties you can have for this plan. Please delete some of your existing properties or go to MY ACCOUNT and upgrade your plan.";
        let properties = planInfo.properties;

        if (this.props.user.planType === 'trial')
        {
            //properties= convertPlanInfoUnlimitedToNumbers(getPlanInfo("lite").properties); //We will limit to the lowest plan that way when they switch we will OK. But we will gray out all properties, except the first 2
            message= "You have exceeded the number of properties you can have for the trial version, you must UPGRADE to a subscription plan to get all features.";
            properties = maxTrialPropertiesToAllowToAdd;
        }

        //Don't allow adding properties in the demo profile
        if (this.props.profile != null && this.props.profile.type != null && this.props.profile.type === 'demo') //Can't save demo property
        {
            title = "Demo Profile Limits";
            message= "You can not add properties to the Demo profile, its purpose is to showcase the application features. Switch to the Default profile to add your own properties."
            properties = 0;
        }
        
        let accountProperties = this.state.properties;

        //Don't count demo properties in the total property count
        const demoProfile = this.getDemoProfile();
        if (demoProfile != null)
        {
            accountProperties = accountProperties.filter(property => property.profileId != demoProfile.id);
        }
        
        if (accountProperties.length >= properties && property == null)

        {
            this.dialog.show({
                title: title,
                body: message,
                actions: [
                  Dialog.OKAction(() => {
                    //console.log('OK was clicked!')
                    
                  })
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                  dialog.hide()
                  
                }
              });

        } else
        {
    
        var dialog = this.propertyEditDialogRef.current;
        dialog.PopulateDialog(property,this,this.props.investmentType,this.props.profileId,this.props.settings,this.props.profile,this.props.user);
        }

    }

    filterInputChange = (event) => {
  
        let propertyValue = event.target.value;
       
        this.props.parentRef.filterInputChanged(propertyValue);

    };
    
    getPropertyClassNameMobile(property,index)
    {
 
        let className = "round-border-dash-mobile";

        try
        {
            //1. NEW label: Figure out if the property needs to the NEW label
            let propertyDate = null;
            let lastLoginDate = null;

            if (property.alertId != null && property.alertId !=="")
            {
                propertyDate = new Date(property.releasedDate._seconds * 1000);
                lastLoginDate = new Date(this.props.user.previousLoginDate);  //Created from service

            } else 
            {
                propertyDate = new Date(property.createdDate);  
                lastLoginDate = new Date(this.props.user.lastLoginDate);  //Created manually
            }

            if (propertyDate > lastLoginDate && getElapsedHours(propertyDate.toISOString()) < 24) //This is to limit to show only the properties added in the last 24 hours)
            {
                className = "col-md-12 properties-row-label round-border-dash-mobile";
            }

            //2. Blug Trial Subscription: Figure out if the trial property records needs to be blured
            
            if (this.props.user != null && this.props.user.planType === 'trial')
            {
                if (this.props != null && this.props.profile != null && this.props.profile.type != null && this.props.profile.type=='demo')
                {
                    // I don't want to blur the demo records

                } else 
                {
                    const rowIndex = index+1;

                    if (rowIndex > maxTrialPropertiesToShow)
                    {
                        className = className + " blur-component";
                    }
                }
            }

        } catch(error)
        {
            console.log(error);
        }

        return className;
    }

    getPropertyClassName(property,index)
    {
        let className = "col-md-12";

        try
        {
            //1. NEW label: Figure out if the property needs to the NEW label
            let propertyDate = null;
            let lastLoginDate = null;

            if (property.alertId != null && property.alertId !=="")
            {
                propertyDate = new Date(property.releasedDate._seconds * 1000);
                lastLoginDate = new Date(this.props.user.previousLoginDate);  //Created from service

            } else 
            {
                propertyDate = new Date(property.createdDate);  
                lastLoginDate = new Date(this.props.user.lastLoginDate);  //Created manually
            }

            if (propertyDate > lastLoginDate && getElapsedHours(propertyDate.toISOString()) < 24) //This is to limit to show only the properties added in the last 24 hours)
            {
                className = "col-md-12 properties-row-label";
            }

          
        } catch(error)
        {
            console.log(error);
        }

        return className;
    }

    getTrialBlurClassName(property,index)
    {
        let className = "";

        try
        {
            //2. Blug Trial Subscription: Figure out if the trial property records needs to be blured
            
            if (this.props.user != null && this.props.user.planType === 'trial')
            {
                if (this.props != null && this.props.profile != null && this.props.profile.type != null && this.props.profile.type=='demo')
                {
                    // I don't want to blur the demo records

                } else 
                {

                    const rowIndex = index+1;

                    if (rowIndex > maxTrialPropertiesToShow)
                    {
                        className = className + " blur-component";
                    }
                }
            }

        } catch(error)
        {
            console.log(error);
        }

        return className;
    }

    getTrialMessageClassName()
    {
        let className = "trial-property-message-hide";

        try
        {
            if (this.props.user != null && this.props.user.planType === 'trial')
            {
                if (this.props != null && this.props.profile != null && this.props.profile.type != null && this.props.profile.type=='demo')
                {
                    // I don't want to blur the demo records

                } else 
                {

                    if (this.state.properties != null)
                    {
                        const count = this.state.properties?.filter(property => property.investmentType == this.props.investmentType && property.profileId == this.props.profileId).length;

                        if (count > 0)
                        {
                            className = "trial-property-message-dasboard-show";
                        }
                        
                    }
                }
            }

        } catch(error)
        {
            console.log(error);
        }

        return className;
    }

    render() {

        const { classes } = this.props;

        if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
            return (
                <div>

                <div style={{paddingBottom:'7px'}}>
                                
                        <div style={{width:'100%'}}>

                            <div class="row">
                                <div class="col-md-4">
                                    <button type="button" class="btn btn-primary add-btn"  onClick={(e) => this.openPropertyEditDialog(null, e)} style={{marginBottom:"0px",marginLeft:"0px",marginTop:'10px'}}>
                                        ADD
                                    </button>
                                </div>

                                {/* FILTER*/}
                                <div class="col-md-4 filter-input-icons" style={{paddingTop:'18px'}}>
                                    <i class="fa fa-search filter-icon"></i>
                                    <input type="text" class="form-select filter-input-component" style={{height:'30px',paddingLeft:'35px'}} id="filter" placeholder="Enter street, city, price" name="filter" defaultValue={this.props.filter}  onChange={this.filterInputChange}/>
                                
                                </div>
                            </div>

                        </div>
                </div>

                                                            
                    {/* -------------------------------------------- D E S K T O P -------------------------------------------------*/}
                    <div class="desktop-only-block">

                                {/* Message to show when the trial max has reached*/}
                                <div class={this.getTrialMessageClassName()}>
                                    <span class="trial-property-message-dasboard-show-bk">To unlock <b><a href="/pricing">UPGRADE</a></b> to a subscription plan</span>
                                </div>
                                
                                <table cellPadding="4">
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                    {/* this.state.properties.map((property, i) => ( */}

                                    {this.state.properties?.filter(property => property.investmentType == this.props.investmentType && property.profileId == this.props.profileId).map((property,i) => (	
                                        
                                        
                                    <tr class={this.getTrialBlurClassName(property,i)}>
                                        <td>
                                            <div class={this.getPropertyClassName(property,i)}  style={{paddingRight:'0px',marginRight:'0px',paddingLeft:'0px',marginLeft:'0px'}}>
                                                
                                                {/*
                                                <div class="property-info-dash" style={{backgroundImage:`url(${property.propertyImage})`,backgroundSize: 'cover'}}>
                                                */}

                                                <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openPhotoDialog(property, e)}>
                                                <div class="property-info-dash">

                                                <span class="block-greater-1500 property-info-address-dash2">
                                                    {property.address} <br></br> 
                                                    {property.city},&nbsp;{property.state}, {property.zipcode}
                                                    
                                                </span>

                                                <span class="block-less-1500 property-info-address-dash2">
                                                    {property.address} <br></br> 
                                                    {property.city},&nbsp;{property.state}
                                                    {((property.address.length < 13 || property.city.split(" ").length==1) && property.address.length < 30) &&
                                                     <span>, {property.zipcode}</span>
                                                    }
                                                </span>


                                               {/*
                                                <br></br>   
                                                    <h3 class="total-value">
                                                        <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openPropertyEditDialog(property, e)}><FileEarmarkText className="ml-2" style={{height:"24px"}}/> </a>
                                                        &nbsp;
                                                        <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.deleteProperty(property.id, e)}><Trash className="ml-2"  style={{height:"24px"}}/></a>
                                                    </h3>
                                                <span style={{color:"#000000"}}>xxxxxxxxxxxxxxxxxxx</span>
                                                */}
                                                
                                                </div>
                                                </a>
{/*
                                                <div style={{height: '86px', width: '93px',backgroundColor:'red'}}></div>
*/}
                                            </div>

                                        </td>

                                        {property.indicators.map((indicator, index) => (  
                                            <td>
                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(1, e, property,indicator)}>
                                                    <div className={`${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == true  && "green-indicator-tile-dash"} ${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == false  && "red-indicator-tile-dash"} ${isIndicatorDisabled(property,indicator)==true  && "disabled-indicator-tile-dash"}`}>
                                                        <diV class="indicator-name-dash">
                                                            {indicator.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value-dash">
                                                            {this.getIndicatorValue(property,indicator)}
                                                        </diV>
                                                        
                                                    </div>
                                                </a>
                                            </td>

                                        ))}
                            
                                    </tr>
                                ))} 

                                </table>
                        </div>

                    {/* --------------------------------------------- M O B I L E -------------------------------------------------*/}
                    <div class="mobile-only-block">
                        <div classX="container" style={{paddingTop:'13px'}}>

                        {/* Message to show when the trial max has reached*/}
                        <div class={this.getTrialMessageClassName()}>
                            <span class="trial-property-message-dasboard-show-bk">To unlock <b><a href="/pricing">UPGRADE</a></b> to a subscription plan</span>
                        </div>

                        {this.state.properties?.filter(property => property.investmentType == this.props.investmentType && property.profileId == this.props.profileId).map((property,i) => (	                    
                            
                         <div>   

                            <div class={this.getPropertyClassNameMobile(property,i)}>  
                            <div>
                                <div class="row">
                                    <div class="col-md-12 propertyInfo">
                                        
                                        <h3 style={{maxWidth:"300px"}}>{property.nickName}</h3>
                                        {property.address}, {property.state}, {property.zipcode}
                                    </div>
                                </div>


                                <div class="row" style={{paddingBottom:"6px"}}>
                                    <div class="col-md-12">
                                        <table cellPadding="2">
                                            <tr>

                                            {this.getIndicators(property.indicators,0,1,2).map((indicator, index) => (  
                                                    <td>
                                                    <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(1, e, property,indicator)}>
                                                    <div className={`${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == true  && "green-indicator-tile-dash-mobile"} ${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == false  && "red-indicator-tile-dash-mobile"} ${isIndicatorDisabled(property,indicator)==true  && "disabled-indicator-tile-dash-mobile"}`}> 
                                                        <diV class="indicator-name-dash-mobile">
                                                            {indicator.indicatorName}
                                                        </diV>
                                                        <diV class="indicator-value-dash-mobile">
                                                            {this.getIndicatorValue(property,indicator)}
                                                        </diV>
                                                        
                                                    </div>
                                                    </a>
                                                    </td>
                                            ))}

                                            
                                            </tr>

                                            <tr>
                                                {this.getIndicators(property.indicators,3,4,5).map((indicator, index) => (  
                                                        <td>
                                                        <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(1, e, property,indicator)}>
                                                        <div className={`${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == true  && "green-indicator-tile-dash-mobile"} ${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == false  && "red-indicator-tile-dash-mobile"} ${isIndicatorDisabled(property,indicator)==true  && "disabled-indicator-tile-dash-mobile"}`}>
                                                            <diV class="indicator-name-dash-mobile">
                                                                {indicator.indicatorName}
                                                            </diV>
                                                            <diV class="indicator-value-dash-mobile">
                                                            {this.getIndicatorValue(property,indicator)}
                                                            </diV>
                                                            
                                                        </div>
                                                        </a>
                                                        </td>
                                                ))}
                                            </tr>


                                            <tr>
                                                    {this.getIndicators(property.indicators,6,7,8).map((indicator, index) => (  
                                                            <td>
                                                            <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(1, e, property,indicator)}>
                                                            <div className={`${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == true  && "green-indicator-tile-dash-mobile"} ${isIndicatorDisabled(property,indicator)==false && indicator.meetsBenchmark == false  && "red-indicator-tile-dash-mobile"} ${isIndicatorDisabled(property,indicator)==true  && "disabled-indicator-tile-dash-mobile"}`}>
                                                                <diV class="indicator-name-dash-mobile">
                                                                    {indicator.indicatorName}
                                                                </diV>
                                                                <diV class="indicator-value-dash-mobile">
                                                                {this.getIndicatorValue(property,indicator)}
                                                                </diV>
                                                                
                                                            </div>
                                                            </a>
                                                            </td>
                                                    ))}
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            
                            </div>
                        </div>
                        
                        ))}

                        </div>

                    </div> {/* End Mobile */}
                    &nbsp;
                     {/*    No Properties   */}
                     {this.props?.propertyCounts?.selectedInvestmentPropertyCount === 0 && 

                    <div class="no-record-found-dashboard">
                        No properties found.<br></br>
                        You can add new properties for the selected profile by clicking the <b>ADD</b> button or by creating an <b>ADVANCED SEARCH</b> from the left navigation menu.

                    </div>    
                    }

                    <Dialog ref={(component) => { this.dialog = component }}/> 

                    <IndicatorInfoDialog ref={this.indicatorInfoDialogRef}></IndicatorInfoDialog>
                    
                    <PropertyEditDialog ref={this.propertyEditDialogRef}></PropertyEditDialog>   

                    <PhotoDialog ref={this.photoDialogRef}></PhotoDialog>

                    {/*
                    <Helmet>
                        <script src="vendor/jquery/jquery.min.js" type="text/javascript" />
                        <script src="js/sb-admin-2.min.js" type="text/javascript" />
                        <script src="vendor/bootstrap/bootstrap.bundle.min.js" type="text/javascript" />
                    </Helmet>
                    */}

                    <Helmet>
                        <script src="js/grid-functions.js" type="text/javascript" />
                        <script src="vendor/jquery/jquery.min.js"></script>
                        <script src="js/sb-admin-2.min.js"></script>
                        <script src="vendor/bootstrap/bootstrap.bundle.min.js"></script>
                    </Helmet>

                </div>
            );
        }
    }
}

export default (withStyles(styles)(Dashboard));
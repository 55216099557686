var StringBuffer = require("stringbuffer");// npm install stringbuffer

var IndicatorVO = require("./IndicatorVO.js");

var AppUtility = require("./AppUtility.js");

export const COMPARISON_SYMBOL_MAP  = new Map();

let compareOperatorVO  = {};
compareOperatorVO.id = 1;
compareOperatorVO.nickName = "Greater Than";
compareOperatorVO.symbol = ">";
COMPARISON_SYMBOL_MAP.set(parseInt(compareOperatorVO.id), compareOperatorVO);

compareOperatorVO  = {};
compareOperatorVO.id = 2;
compareOperatorVO.nickName = "Greater Than Or Equal To";
compareOperatorVO.symbol = ">=";
COMPARISON_SYMBOL_MAP.set(parseInt(compareOperatorVO.id), compareOperatorVO);

compareOperatorVO  = {};
compareOperatorVO.id = 3;
compareOperatorVO.nickName = "Equal To";
compareOperatorVO.symbol = "=";
COMPARISON_SYMBOL_MAP.set(parseInt(compareOperatorVO.id), compareOperatorVO);

compareOperatorVO  = {};
compareOperatorVO.id = 4;
compareOperatorVO.nickName = "Less Than";
compareOperatorVO.symbol = "<";
COMPARISON_SYMBOL_MAP.set(parseInt(compareOperatorVO.id), compareOperatorVO);

compareOperatorVO  = {};
compareOperatorVO.id = 5;
compareOperatorVO.nickName = "Less Than Or Equal To";
compareOperatorVO.symbol = "<=";
COMPARISON_SYMBOL_MAP.set(parseInt(compareOperatorVO.id), compareOperatorVO);

compareOperatorVO  = {};
compareOperatorVO.id = 6;
compareOperatorVO.nickName = "Not Equal To";
compareOperatorVO.symbol = "!=";
COMPARISON_SYMBOL_MAP.set(parseInt(compareOperatorVO.id), compareOperatorVO);



export const getNetProfitFromFutureSale = (yearOfSale,loanAmount,annualInterestRate, loanTermYears, purchasePrice,annualHomeAppreciationPercent,financingType,settings) => {
	
	let netProfitsFromFutureSale = 0;

	// Based on https://www.creditfinanceplus.com/calculators/home-value-appreciation-profits-future-sale.php

	
	//STEP 1: Calculate home appreciation.  We are using the home appreciation from Settings.annualHomeAppreciationPercent
	// Formula :F = P * (1 + i)ⁿ  https://newsilver.com/the-lender/how-to-calculate-home-appreciation/

	let resaleValue_HomeAppreciation = purchasePrice * Math.pow(1 + (annualHomeAppreciationPercent/100),yearOfSale);
	resaleValue_HomeAppreciation = parseInt(resaleValue_HomeAppreciation);
	
	//STEP 2: Calculate mortgage balance left to pay
	let mortgageBalance = 0
	if (financingType == "Loan")
	{
		mortgageBalance = getMortgageBalanceOnSpecificYear(loanTermYears, yearOfSale, loanAmount, annualInterestRate);
		mortgageBalance = parseInt(mortgageBalance);
	}

	// STEP 3: Calculate home equity
	let homeEquity = resaleValue_HomeAppreciation - mortgageBalance;

	// STEP 4: Calculate selling Costs.  We are using the selling cost percent from Settings.sellingCostPercent (the same one used for flipping)
	let sellingCosts = resaleValue_HomeAppreciation * (settings.sellingCostPercent/100);
	sellingCosts = parseInt(sellingCosts);

	// STEP 5: Calculate net profits from future sale
	netProfitsFromFutureSale = homeEquity - sellingCosts;

	/*
	console.log("purchasePrice:"+purchasePrice);
	console.log("annualHomeAppreciationPercent:"+annualHomeAppreciationPercent);
	console.log("yearOfSale:"+yearOfSale);
	console.log("resaleValue_HomeAppreciation:"+resaleValue_HomeAppreciation);
	console.log("mortgageBalance:"+mortgageBalance);
	console.log("homeEquity:"+homeEquity);
	console.log("sellingCosts:"+sellingCosts);
	console.log("netProfitsFromFutureSale:"+netProfitsFromFutureSale);
	*/

	const result = {};
	result.netProfitsFromFutureSale = netProfitsFromFutureSale;
	result.helpVariableMap = new Map();

	result.helpVariableMap.set("RESALE_VALUE", AppUtility.getAmountStringFromDouble(resaleValue_HomeAppreciation));
	result.helpVariableMap.set("MORTGAGE_BALANCE", AppUtility.getAmountStringFromDouble(mortgageBalance));
	result.helpVariableMap.set("HOME_EQUITY", AppUtility.getAmountStringFromDouble(homeEquity));
	result.helpVariableMap.set("SELLING_COSTS", AppUtility.getAmountStringFromDouble(sellingCosts));
	result.helpVariableMap.set("SELLING_COSTS_PERCENT", settings.sellingCostPercent+"%");
	result.helpVariableMap.set("LOAN_AMOUNT", AppUtility.getAmountStringFromDouble(loanAmount));
	result.helpVariableMap.set("HOME_APPRECIATION_PERCENT", annualHomeAppreciationPercent+"%");
	result.helpVariableMap.set("YEAR_OF_SALE", yearOfSale);
	result.helpVariableMap.set("CASH_FLOW", AppUtility.getAmountStringFromDouble(netProfitsFromFutureSale));
	
	return result;
}

export const getMortgageBalanceOnSpecificYear = (years,yearNumber,loanAmount,annualInterestRate) => {

	let mortgageBalance = 0;

	const amortizationSchedule = getAmortizationSchedule(years,loanAmount,annualInterestRate);

	let lastYearAmortization = null;
	for (let i = 0; i < amortizationSchedule.yearMortgageSchedulePayments.length; i++) {
	   
	  const yearAmortization = amortizationSchedule.yearMortgageSchedulePayments[i];
	  if (yearAmortization.year == yearNumber)  
	  {
		lastYearAmortization = yearAmortization;
		break;
	  }
	} //end loop

	if (lastYearAmortization != null)
	{
		const lastPayment = lastYearAmortization.payments[lastYearAmortization.payments.length -1];

		mortgageBalance = parseInt(lastPayment.balance);

	}

	return mortgageBalance;
}

export const getMortgageBalanceOnSpecificMonth = (years,monthNumber,loanAmount,annualInterestRate) => {

	let balance = {
		mortgageBalance: 0,
		payments:[]
	}

	let monthCount = 0;
	let exist = false;

	const amortizationSchedule = getAmortizationSchedule(years,loanAmount,annualInterestRate);

	for (let i = 0; i < amortizationSchedule.yearMortgageSchedulePayments.length; i++) {
	   
	  const yearAmortization = amortizationSchedule.yearMortgageSchedulePayments[i];
	 
	  for (let payment of yearAmortization.payments)
	  {
			monthCount = monthCount +1;

			balance.payments.push(payment);

			if (monthCount >= monthNumber)
			{
				balance.mortgageBalance = payment.balance;
				exist = true;
				break;
			}
	  }
	  
	  if (exist)
	  {
		break;
	  }

	} //end loop


	return balance;
}


export const getAmortizationSchedule = (yearNumber,loanAmount,annualInterestRate) => {


	let mortgageScheduleVO  = {};

	//alert("annualInterestRate:"+annualInterestRate);

	annualInterestRate = annualInterestRate / 100;

	let monthlyInterestRate = annualInterestRate / 12;
	let numberOfMonths = yearNumber * 12;
	let monthlyPayment = loanAmount * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -numberOfMonths)));

	
	//console.log("annualInterestRate:"+annualInterestRate);
	//console.log("numberOfMonths:"+numberOfMonths);
	//console.log("loanAmount:"+loanAmount);
	//console.log("monthlyInterestRate:"+monthlyInterestRate);
	//console.log("monthlyPayment:"+monthlyPayment);

	//alert("monthlyPayment:"+monthlyPayment);

	monthlyPayment = (monthlyPayment * 100) / 100.0;
	let totalPayment = monthlyPayment * numberOfMonths;
	totalPayment = (totalPayment * 100) / 100.0;

	let balance = loanAmount;
	let interest = 0;
	let principal = 0;

	mortgageScheduleVO.monthlyPayment = monthlyPayment;
	mortgageScheduleVO.totalPayment = totalPayment;

			let paymentList  = [];
			let yearPaymentList  = [];

	let yearMortgageSchedulePaymentsVO = null;
	let yearMortgageSchedulePayments = null;

	let yearCounter = 1;
	let yearMonthCounter = 0;

	for (let i = 0; i < yearNumber * 12; i++) {

		yearMonthCounter = yearMonthCounter + 1;
		if (yearMonthCounter > 12) {

			yearMortgageSchedulePaymentsVO  = {};
			yearMortgageSchedulePaymentsVO.year = yearCounter;
			let payments  = [];
			payments  = paymentList;
			yearMortgageSchedulePaymentsVO.payments = payments;

			yearPaymentList.push(yearMortgageSchedulePaymentsVO);

							paymentList  = [];
			yearMonthCounter = 1;
			yearCounter = yearCounter + 1;

		}

		//alert("principal:"+principal);

		interest = (monthlyInterestRate * balance * 100) / 100.0;
		principal = ((monthlyPayment - interest) * 100) / 100.0;
		balance = ((balance - principal) * 100) / 100.0;

		//console.log("yearMonthCounter:"+yearMonthCounter);
		//console.log("monthlyInterestRate:"+monthlyInterestRate);
		//console.log("monthlyPayment:"+monthlyPayment);
		//console.log("interest:"+interest);
		//console.log("principal:"+principal);
		//console.log("balance:"+balance);
		//console.log("----------------------");

		let mortgageSchedulePaymentVO  = {};

		mortgageSchedulePaymentVO.balance = balance;
		mortgageSchedulePaymentVO.interest = interest;
		mortgageSchedulePaymentVO.principal = principal;
		mortgageSchedulePaymentVO.month = yearMonthCounter;

		paymentList.push(mortgageSchedulePaymentVO);
	}

	// add last year
	yearMortgageSchedulePaymentsVO  = {};
	yearMortgageSchedulePaymentsVO.year = yearCounter;
	let payments  = [];
	payments  = paymentList;
	yearMortgageSchedulePaymentsVO.payments = payments;

	yearPaymentList.push(yearMortgageSchedulePaymentsVO);

	paymentList  = [];
	yearMonthCounter = 1;
	yearCounter = yearCounter + 1;

	// convert list to array

	yearMortgageSchedulePayments  = [];
	yearMortgageSchedulePayments  = yearPaymentList;

	mortgageScheduleVO.yearMortgageSchedulePayments = yearMortgageSchedulePayments;

	return mortgageScheduleVO;

};

export const getEstimatedFutureCashFlows = (paramProperty,projectedYearOfSale,projectedSaleProceeds,benchmarkingSetting) => {

	const inProperty = JSON.parse(JSON.stringify(paramProperty)); // Make a clone of property - Deep copy

	let estimatedFutureCashFlows  = new Map();

	let estimatedFutureFinancialInfo = null;

	let expenseGrowthRate = inProperty.expenseRateOfGrowth;
	let rentGrowthRate = inProperty.rentRateOfGrowth;
	let vacancyRate = inProperty.vacancyRate;

	// IndicatorVO capRateIndicator = null;
	let cashFlowIndicator = null;

	for (let x = 0; x < projectedYearOfSale; x = x + 1) {

		estimatedFutureFinancialInfo  = new Map();

		cashFlowIndicator = getNetCashFlowIndicator(inProperty,benchmarkingSetting,COMPARISON_SYMBOL_MAP);

		if (cashFlowIndicator != null && cashFlowIndicator.helpVariableMap != null) {

			//console.log(x+":");
			//console.log(JSON.stringify(cashFlowIndicator));

			// VAR_MONTHLY_RENT
			// VAR_GROSS_ANNUAL_INCOME
			// VAR_ANNUAL_EXPENSES
			// VAR_MONTHLY_MORTGAGE_PAYMENT
			// VAR_ANNUAL_MORTGAGE_PAYMENT
			// VAR_ANNUAL_CASH_FLOW
			// VAR_VACANCY_RATE
			// VAR_VACANCY_CREDIT_LOSS
			// VAR_GROSS_OPERATING_INCOME
			// VAR_NET_OPERATING_INCOME

	
			//setAll(cashFlowIndicator.helpVariableMap,estimatedFutureFinancialInfo);

			//Add all helpVariableMap entries to estimatedFutureFinancialInfo
			for (let [key, value] of Object.entries(cashFlowIndicator.helpVariableMap)) {
				//console.log(key, value);
				estimatedFutureFinancialInfo.set(key,value);
			}

			estimatedFutureFinancialInfo.set("VAR_ADMIN_EXPENSE",AppUtility.getAmountStringFromDouble(inProperty.administrativeExpense));
			estimatedFutureFinancialInfo.set("VAR_TAX_EXPENSE",AppUtility.getAmountStringFromDouble(inProperty.propertyTaxes));
			estimatedFutureFinancialInfo.set("VAR_INSURANCE_EXPENSE",AppUtility.getAmountStringFromDouble(inProperty.insuranceExpense));
			estimatedFutureFinancialInfo.set("VAR_MAINTENANCE_EXPENSE",AppUtility.getAmountStringFromDouble(inProperty.maintenanceExpense));
			estimatedFutureFinancialInfo.set("VAR_GENERAL_EXPENSE",AppUtility.getAmountStringFromDouble(inProperty.generalExpense));

			let buffer = new StringBuffer();
			buffer.append(rentGrowthRate);
			buffer.append("%");

			estimatedFutureFinancialInfo.set("VAR_RENT_GROWTH_RATE", buffer.toString());

			buffer = AppUtility.clearBuffer(buffer);
			buffer.append(expenseGrowthRate);
			buffer.append("%");
			estimatedFutureFinancialInfo.set("VAR_EXPENSE_GROWTH_RATE", buffer.toString());

			buffer = AppUtility.clearBuffer(buffer);
			buffer.append(vacancyRate);
			buffer.append("%");
			estimatedFutureFinancialInfo.set("VAR_VACANCY_RATE_PERCENT", buffer.toString());

			let totalCashFlow = 0;

			totalCashFlow = cashFlowIndicator.indicatorValue;

			//if (AppUtility.getSaleProceedsYear(inProperty) == x + 1) {

			estimatedFutureFinancialInfo.set("VAR_SALEPROCEEDS",AppUtility.getAmountStringFromDouble(0));
			estimatedFutureFinancialInfo.set("VAR_SALEPROCEEDS_AS_NUMBER",0);

			//Add sales proceeds to the last year
			// NOTE: I WILL NOT ADD THE SALES PROCEEDS TO THE LAST YEAR BECAUSE IT MESSES UP THE INDICATORS THAT ALREADY DO IT (lIKE IRR)
			/*
			if (projectedYearOfSale == x + 1)
			{
				totalCashFlow = parseInt(totalCashFlow) + parseInt(projectedSaleProceeds);

				estimatedFutureFinancialInfo.set("VAR_SALEPROCEEDS",AppUtility.getAmountStringFromDouble(projectedSaleProceeds));
				estimatedFutureFinancialInfo.set("VAR_SALEPROCEEDS_AS_NUMBER",projectedSaleProceeds);

			}
			*/

			totalCashFlow = parseInt(totalCashFlow);
			
			estimatedFutureFinancialInfo.set("VAR_ANNUAL_CASH_FLOW",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalCashFlow));
			estimatedFutureFinancialInfo.set("VAR_ANNUAL_CASH_FLOW_AS_NUMBER",parseInt(totalCashFlow));

			estimatedFutureFinancialInfo.set("VAR_ANNUAL_CASH_FLOW_DOUBLE",parseFloat(totalCashFlow));
		}

		estimatedFutureCashFlows.set(x + 1,estimatedFutureFinancialInfo);

		// Now prepare for the next year
		let projectedSaleProceedsYear = AppUtility.getSaleProceedsYear(inProperty);

		inProperty.rentalMaintenance = AppUtility.incrementByPercent(inProperty.rentalMaintenance, expenseGrowthRate);

		inProperty.rentalUtilities = AppUtility.incrementByPercent(inProperty.rentalUtilities, expenseGrowthRate); 
		
		inProperty.rentalPropertyManagement = AppUtility.incrementByPercent(inProperty.rentalPropertyManagement, expenseGrowthRate);
		
		inProperty.rentalBookkeeping = AppUtility.incrementByPercent(inProperty.rentalBookkeeping, expenseGrowthRate);
		
		inProperty.rentalTaxFiling = AppUtility.incrementByPercent(inProperty.rentalTaxFiling, expenseGrowthRate);
		
		inProperty.rentalOtherExpenses = AppUtility.incrementByPercent(inProperty.rentalOtherExpenses, expenseGrowthRate);


		// increase rent by rent growth rate
		let monthlyRent = 0;
		monthlyRent = AppUtility.incrementByPercent(inProperty.monthlyRent, rentGrowthRate);

		inProperty.monthlyRent = monthlyRent;

	} //end loop

	return estimatedFutureCashFlows;

};

export const getNetCashFlowIndicator = (property,benchmarkingSetting) => {

	// NCF= ((((NOI-Debt Service)-Capital Additions) + loan
	// proceeds)+interest
	// earned) - Tax Liability

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let monthlyRent = 0;
	let grossScheduledIncome = 0;
	let vacancyRate = 0;
	let creditLoss = 0;
	let grossOperatingIncome = 0;
	let totalExpenses = 0;
	let netOperatingIncome = 0;
	let netCashFlow = 0;
	let monthylMortgagePayment = 0;
	let annualDebtService = 0;
	let passFlag = false;

	let accepted = false;
	let rejected = false;

	let acceptCompareText = "";
	let rejectCompareText = "";

	if (property != null) {
		// 1. Monthly Rent
		monthlyRent = property.monthlyRent;
		vacancyRate = property.vacancyRate;
		if (vacancyRate != 0) {
			vacancyRate = vacancyRate / 100;
		}

		// 2. Gross Schedule Income
		grossScheduledIncome = getMonthlyScheduledIncome(monthlyRent);

		// 3. Credit Loss
		creditLoss = getCreditLoss(grossScheduledIncome, vacancyRate);

		// 4. Gross Operating Income
		grossOperatingIncome = getGrossOperatingIncome(grossScheduledIncome, creditLoss);

		// 5. Total Expenses

		totalExpenses = getTotalExpenses(property);

		totalExpenses = totalExpenses * 12; //Annualized it

		// 6. Net 0perating Income
		netOperatingIncome = getNetOperatingIncome(grossOperatingIncome, totalExpenses);

		// 7. Monthly Mortgage Payment
		monthylMortgagePayment = parseInt(property.monthlyPayment);

		// console.log("==============================================================");
		// console.log("grossScheduledIncome:"+grossScheduledIncome);
		// console.log("creditLoss:"+creditLoss);
		// console.log("grossOperatingIncome:"+grossOperatingIncome);
		// console.log("totalExpenses:"+totalExpenses);
		// console.log("netOperatingIncome:"+netOperatingIncome);
		// console.log("monthylMortgagePayment:"+monthylMortgagePayment);

		if (property.financingType == "Loan")
		{
			// 8. Annual Debt Service (ADS)
			annualDebtService = monthylMortgagePayment * 12;
		}

		// 9. netCashFlow (Unable to deduct investor's tax liability since
		// don't know
		// investor's tax bracket)
		netCashFlow = netOperatingIncome - annualDebtService;


		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.ncfAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.ncfAccepted, netCashFlow,symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.ncfRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.ncfRejected, netCashFlow,symbol);
			rejectCompareText = compareOperatorVO.nickName;
			passFlag = accepted && !rejected;
		}

	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.NET_CASH_FLOW_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.NET_CASH_FLOW_INDICATOR_ACRONYM;
	indicator.indicatorValue =  netCashFlow;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DOLLAR_AMOUNT;
	indicator.helpVariableMap = new Map();


	populateNetCashFlowIndicatorValueMap(indicator, monthlyRent, grossScheduledIncome, totalExpenses, netCashFlow,property.monthlyPayment, annualDebtService, vacancyRate, creditLoss, grossOperatingIncome,netOperatingIncome, acceptCompareText, benchmarkingSetting.ncfAccepted, rejectCompareText, benchmarkingSetting.ncfRejected, IndicatorVO.DOLLAR_AMOUNT);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const getNetOperatingIncome = (grossOperatingIncome,totalExpenses) => {

	let netOperatingIncome = grossOperatingIncome - totalExpenses;

	return netOperatingIncome;

};

export const getGrossOperatingIncome = (grossScheduledIncome,creditLoss) => {

	let grossOperatingIncome = grossScheduledIncome - creditLoss;

	return grossOperatingIncome;

};

export const getMonthlyScheduledIncome = (monthlyRent) => {

	let grossScheduledIncome = monthlyRent * 12;

	return grossScheduledIncome;

};

export const getCreditLoss = (grossScheduledIncome,vacancyRate) => {

	let creditLoss = vacancyRate * grossScheduledIncome;

	return creditLoss;

};

export const getTotalExpenses = (property) => {

	let total = 0;

	//OLD
	//total = total + property.expenseRateOfGrowth + property.administrativeExpense + property.generalExpense + property.insuranceExpense + property.maintenanceExpense;

	// console.log("\tproperty.rentalMaintenance:"+property.rentalMaintenance);
	// console.log("\tproperty.rentalUtilities:"+property.rentalUtilities);
	// console.log("\tproperty.rentalPropertyManagement:"+property.rentalPropertyManagement);
	// console.log("\tproperty.rentalBookkeeping:"+property.rentalBookkeeping);
	// console.log("\tproperty.rentalOtherExpenses:"+property.rentalOtherExpenses);
	// console.log("\tproperty.rentalTaxFiling:"+property.rentalTaxFiling);

	//total = total + property.rentalMaintenance + property.rentalUtilities + property.rentalPropertyManagement + property.rentalBookkeeping + property.rentalOtherExpenses + property.rentalTaxFiling;

	total = total + parseFloat(property.rentalMaintenance) + parseFloat(property.rentalUtilities) + parseFloat(property.rentalPropertyManagement) + parseFloat(property.rentalBookkeeping) + parseFloat(property.rentalOtherExpenses) + parseFloat(property.rentalTaxFiling);

	//console.log("\tTOTAL:"+total);

	return total;

};

export const populateNetCashFlowIndicatorValueMap = (indicator,monthlyRent,grossScheduledIncome,annualExpenses,netAnnualCashFlow,monthlyMortgage,annualMortgage,vacancyRate,creditLoss,grossOperatingIncome,netOperatingIncome,acceptCompareText, acceptValue, rejectCompareText, rejectValue,valueType) => {
 
	indicator.helpVariableMap.set("VAR_MONTHLY_RENT", AppUtility.getAmountStringFromDouble(parseInt(monthlyRent)));
	indicator.helpVariableMap.set("VAR_GROSS_ANNUAL_INCOME",AppUtility.getAmountStringFromDouble(parseInt(grossScheduledIncome)));
	indicator.helpVariableMap.set("VAR_ANNUAL_EXPENSES", AppUtility.getAmountStringFromDouble(parseInt(annualExpenses)));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(parseInt(monthlyMortgage)));
	indicator.helpVariableMap.set("VAR_ANNUAL_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(parseInt(annualMortgage)));
	indicator.helpVariableMap.set("VAR_ANNUAL_CASH_FLOW",AppUtility.getAmountStringFromDoubleNoFractionalPart(parseInt(netAnnualCashFlow)));
	indicator.helpVariableMap.set("VAR_VACANCY_RATE", (AppUtility.roundToTheNearest(vacancyRate * 100, 100)).toString()); 
	indicator.helpVariableMap.set("VAR_VACANCY_CREDIT_LOSS", AppUtility.getAmountStringFromDouble(parseInt(creditLoss)));
	indicator.helpVariableMap.set("VAR_GROSS_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(parseInt(grossOperatingIncome)));
	indicator.helpVariableMap.set("VAR_NET_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(parseInt(netOperatingIncome)));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		//indicator.helpVariableMap.set("VAR_RESULT_TEXT","The net cash flow return for this property is green because it is above zero.  You should also deduct your tax liability from this amount to ensure you have an exact representation of your cash flow.  The tax liability is not deducted from the above amount because it varies depending on the investor's tax bracket.");
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>net cash flow return</b> for this property is green because it is above zero. \n\n <b>Note</b>: This is the net cash flow for the first year of operation, subsequent years will be impacted by the <i>expense rate of growth</i> and <i>rent rate of growth</i>, which are not used in this calculation.");
		
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>net cash flow return</b> for this property is red because it is below zero. \n\n <b>Note</b>: This is the net cash flow for the first year of operation, subsequent years will be impacted by the <i>expense rate of growth</i> and <i>rent rate of growth</i>, which are not used in this calculation.");
	}

	//no return 

};

export const getGRMIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	// Formula: Gross Rent Multiplier = Market Value / Gross Scheduled
	// Income
	// (Annual)

	let indicator = null;

	let price = 0;
	let monthlyRent = 0;
	let grossScheduledIncome = 0;
	let grossRentMultiplier = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {
		// 1. Price
		price = property.price;

		if (property != null) {
			monthlyRent = property.monthlyRent;
		}

		// 2. Gross Schedule Income
		grossScheduledIncome = getMonthlyScheduledIncome(monthlyRent);

		// 3. Gross Rent Multiplier
		if (grossScheduledIncome > 0) {
			grossRentMultiplier = price / grossScheduledIncome;
		} else {
			grossRentMultiplier = 9999;
		}

		grossRentMultiplier = AppUtility.roundToTheNearest(grossRentMultiplier, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.grmAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			acceptCompareText = compareOperatorVO.nickName;
			accepted = compareBenchmarkedValue(benchmarkingSetting.grmAccepted, grossRentMultiplier, symbol);

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.grmRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejectCompareText = compareOperatorVO.nickName;
			rejected = compareBenchmarkedValue(benchmarkingSetting.grmRejected, grossRentMultiplier, symbol);
			passFlag = accepted && !rejected;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.GROSS_RENT_MULTIPLIER_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.GROSS_RENT_MULTIPLIER_INDICATOR_ACRONYM;
	indicator.indicatorValue =  grossRentMultiplier;
	indicator.property =  property;
	indicator.valueType = IndicatorVO.DECIMAL;
	indicator.helpVariableMap = new Map();


	populateGRMIndicatorValueMap(indicator, price, monthlyRent, grossScheduledIncome, grossRentMultiplier,acceptCompareText, benchmarkingSetting.grmAccepted, rejectCompareText,benchmarkingSetting.grmRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap);

	return indicator;

};

export const populateGRMIndicatorValueMap = (indicator,price,monthlyRent,grossScheduledIncome,grossRentMultiplier,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {

	let buffer = new StringBuffer();

	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE", AppUtility.getAmountStringFromDouble(price));
	indicator.helpVariableMap.set("VAR_MONTHLY_RENT", AppUtility.getAmountStringFromDouble(monthlyRent));
	indicator.helpVariableMap.set("VAR_ANNUAL_INCOME",AppUtility.getAmountStringFromDouble(grossScheduledIncome));

	if (grossRentMultiplier != 9999.0) {
		indicator.helpVariableMap.set("VAR_GROSS_RENT_MULTIPLIER",AppUtility.getIndicatorValue(valueType, grossRentMultiplier));
	} else {
		indicator.helpVariableMap.set("VAR_GROSS_RENT_MULTIPLIER", "N/A");
		indicator.indicatorValue = 0;
	}

	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		//indicator.helpVariableMap.set("VAR_RESULT_TEXT","The Gross Rent Multiplier for this property is green, which means that the Market Value of this property is good based on the current benchmarking setting.");
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>gross rent multiplier</b> (GRM) for this property is green, which means the annual gross rental income it produces is good for its purchase price. Based on this GRM, it would take <b>"+grossRentMultiplier+"</b> years of collecting rent to equal the purchase price.\n\nNote that GRM is not equivalent to the length of time it takes for the investment to pay off because it does not take net operating income into account. \n\nHowever, it can be a useful initial tool to provide a quick estimate of a rental property's value.");

	} else {
		//indicator.helpVariableMap.set("VAR_RESULT_TEXT","The Gross Rent Multiplier for this property is red, which means that the Market Value of this property is poor based on the current benchmarking setting.");
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>gross rent multiplier</b> (GRM) for this property is red, which means the annual gross rental income it produces is low relative to its purchase price. Based on this GRM, it would take <b>"+grossRentMultiplier+"</b> years of collecting rent to equal the purchase price.\n\nNote that GRM is not equivalent to the length of time it takes for the investment to pay off because it does not take net operating income into account. \n\nHowever, it can be a useful initial tool to provide a quick estimate of a rental property's value.");
	}

	//no return 

};

export const getCapRateIndicator = (property,benchmarkingSetting) => {

	// Formula: Cap Rate = Net Operating Income / Value

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let price = 0;
	let monthlyRent = 0;
	let grossScheduledIncome = 0;
	let vacancyRate = 0;
	let creditLoss = 0;
	let grossOperatingIncome = 0;
	let totalExpenses = 0;
	let netOperatingIncome = 0;
	let capitalizationRatePercent = 0;
	let capitalizationRate = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {
		// 1. Price
		price = property.price;

		if (property != null) {
			monthlyRent = property.monthlyRent;
			vacancyRate = property.vacancyRate / 100;
		}

		// 2. Gross Schedule Income
		grossScheduledIncome = getMonthlyScheduledIncome(monthlyRent);

		// 3. Credit Loss
		creditLoss = getCreditLoss(grossScheduledIncome, vacancyRate);

		// 4. Gross Operating Income
		grossOperatingIncome = getGrossOperatingIncome(grossScheduledIncome, creditLoss);

		// 5. Total Expenses

		totalExpenses = getTotalExpenses(property);

		totalExpenses = totalExpenses * 12; //We want annual expenses

		// 6. Net Operating Income
		netOperatingIncome = getNetOperatingIncome(grossOperatingIncome, totalExpenses);

		// 7. Cap Rate
		if (price > 0) {
			capitalizationRatePercent = netOperatingIncome / price;

		} else {
			capitalizationRatePercent = 0;
		}

		capitalizationRate = capitalizationRatePercent * 100; // because it
																// is a
																// percentage
		capitalizationRate = AppUtility.roundToTheNearest(capitalizationRate, 100);
		capitalizationRatePercent = AppUtility.roundToTheNearest(capitalizationRatePercent, 10000);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.capAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.capAccepted, capitalizationRate, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.capRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.capRejected, capitalizationRate, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.CAPITALIZATION_RATE_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.CAPITALIZATION_RATE_INDICATOR_ACRONYM;
	//indicator.indicatorValue =  capitalizationRatePercent;
	indicator.indicatorValue =  capitalizationRate;
	indicator.property =  property;
	indicator.valueType = IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateCapRateIndicatorValueMap(indicator, price, monthlyRent, grossScheduledIncome, vacancyRate * 100,totalExpenses, creditLoss, grossOperatingIncome, netOperatingIncome, capitalizationRatePercent,acceptCompareText, benchmarkingSetting.capAccepted, rejectCompareText,benchmarkingSetting.capRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	
	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateCapRateIndicatorValueMap = (indicator,price,monthlyRent,grossScheduledIncome,vacancyRate,annualExpenses,vacancyCreditLoss,grossOperatingIncome,netOperatingIncome,capRate,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE", AppUtility.getAmountStringFromDouble(price));
	indicator.helpVariableMap.set("VAR_MONTHLY_RENT", AppUtility.getAmountStringFromDouble(monthlyRent));
	indicator.helpVariableMap.set("VAR_GROSS_ANNUAL_INCOME", AppUtility.getAmountStringFromDouble(monthlyRent*12));
	indicator.helpVariableMap.set("VAR_ANNUAL_INCOME",AppUtility.getAmountStringFromDouble(grossScheduledIncome));
	indicator.helpVariableMap.set("VAR_VACANCY_RATE", (AppUtility.roundToTheNearest(vacancyRate, 100)).toString()); 
	indicator.helpVariableMap.set("VAR_ANNUAL_EXPENSES", AppUtility.getAmountStringFromDouble(annualExpenses));
	indicator.helpVariableMap.set("VAR_VACANCY_CREDIT_LOSS",AppUtility.getAmountStringFromDouble(vacancyCreditLoss));
	indicator.helpVariableMap.set("VAR_GROSS_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(grossOperatingIncome));
	indicator.helpVariableMap.set("VAR_NET_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(netOperatingIncome));
	indicator.helpVariableMap.set("VAR_CAPITALIZATION_RATE", AppUtility.getIndicatorValue(valueType, capRate));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>capitalization rate</b> for this property is green, which means the earning ability of this rental property is good.");
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>capitalization rate</b> for this property is red, which means the earning ability of this rental property is poor.");
	}

	//no return 


};

export const getCashOnCashIndicator = (property,benchmarkingSetting) => {


	// Formula: Cash-On-Cash= Annual Cash Flow / Cash Invested

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let initialInvestment = 0;
	let totalClosingCosts = 0;
	let monthlyRent = 0;
	let netAnnualCashFlow = 0;
	let cashOnCash = 0;
	let cashOnCashPercent = 0;
	let vacancyRate = 0;
	let creditLoss = 0;
	let grossOperatingIncome = 0;
	let grossScheduledIncome = 0;
	let annualMortgagePayments = 0;
	let totalExpenses = 0;
	let netOperatingIncome = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null && property != null && property != null) {
		// 1. Total Closing Cost
		totalClosingCosts = property.closingCosts;
		
		//initialInvestment = parseInt(totalClosingCosts) + parseInt(property.downPayment);

		if (property.financingType ==="Loan")
		{
			initialInvestment = parseInt(totalClosingCosts) + parseInt(property.downPayment);

		} else if (property.financingType ==="Cash")
		{
			initialInvestment = parseInt(totalClosingCosts) + parseInt(property.price);
		} 

		// it was not financed, it was purchased with cash
		/*
		if (initialInvestment == 0 && property.interestRate == 0 && property.downPayment == 0) 
		{
			initialInvestment = property.price;

		}
		*/

		if (property != null) {
			monthlyRent = property.monthlyRent;
			vacancyRate = property.vacancyRate; 
			if (vacancyRate != 0) 
			{ 
				vacancyRate = vacancyRate / 100; 
			} 
		} 

		// 2. Gross Schedule Income
		grossScheduledIncome = getMonthlyScheduledIncome(monthlyRent);

		// 3. Credit Loss
		creditLoss = getCreditLoss(grossScheduledIncome, vacancyRate); // *

		// 4. Gross Operating Income
		grossOperatingIncome = getGrossOperatingIncome(grossScheduledIncome, creditLoss); // *

		// 5. Total Expenses
		totalExpenses = getTotalExpenses(property);

		totalExpenses = totalExpenses * 12; //Need to annualize it

		// 6. Net Operating Income
		netOperatingIncome = getNetOperatingIncome(grossOperatingIncome, totalExpenses);

		if (property.financingType ==="Loan")
		{
			// 7. Annual Mortgage Payment
			annualMortgagePayments = parseInt(property.monthlyPayment) * 12;
		}

		// 8. Net Annual Cash Flow
		netAnnualCashFlow = parseInt(netOperatingIncome) - parseInt(annualMortgagePayments);

		// 9. Cash on Cash
		if (initialInvestment > 0) {
			cashOnCashPercent = netAnnualCashFlow / initialInvestment;
		} else {
			cashOnCashPercent = 0;
		}

		cashOnCash = cashOnCashPercent * 100; // because it is a percentage

		cashOnCash = AppUtility.roundToTheNearest(cashOnCash, 100);
		cashOnCashPercent = AppUtility.roundToTheNearest(cashOnCashPercent, 10000);

	}

	if (comparisonSymbolMap != null) {
		let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.cocAcceptedCompareId));
		let symbol = compareOperatorVO.symbol;
		accepted = compareBenchmarkedValue(benchmarkingSetting.cocAccepted, cashOnCash, symbol);
		acceptCompareText = compareOperatorVO.nickName;

		compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.cocRejectedCompareId));
		symbol = compareOperatorVO.symbol;
		rejected = compareBenchmarkedValue(benchmarkingSetting.cocRejected, cashOnCash, symbol);
		rejectCompareText = compareOperatorVO.nickName;
		passFlag = accepted && !rejected;
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.CASH_ON_CASH_RATE_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.CASH_ON_CASH_RATE_INDICATOR_ACRONYM;
	//indicator.indicatorValue =  cashOnCashPercent;
	indicator.indicatorValue =  cashOnCash;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateCashOncashIndicatorValueMap(property, indicator, initialInvestment, totalClosingCosts, monthlyRent,grossScheduledIncome, totalExpenses, netAnnualCashFlow,property == null ? 0 : property.monthlyPayment, annualMortgagePayments, vacancyRate, creditLoss,grossOperatingIncome, netOperatingIncome, cashOnCashPercent, acceptCompareText,benchmarkingSetting.cocAccepted, rejectCompareText,benchmarkingSetting.cocRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateCashOncashIndicatorValueMap = (property, indicator,initialInvestment,totalClosingCosts,monthlyRent,grossScheduledIncome,annualExpenses,netAnnualCashFlow,monthlyMortgage,annualMortgage,vacancyRate,creditLoss,grossOperatingIncome,netOperatingIncome,cashOnCash,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_INITIAL_INVESTMENT",AppUtility.getAmountStringFromDouble(initialInvestment));
	indicator.helpVariableMap.set("VAR_MONTHLY_RENT", AppUtility.getAmountStringFromDouble(monthlyRent));
	indicator.helpVariableMap.set("VAR_GROSS_ANNUAL_INCOME",AppUtility.getAmountStringFromDouble(grossScheduledIncome));
	indicator.helpVariableMap.set("VAR_ANNUAL_EXPENSES", AppUtility.getAmountStringFromDouble(annualExpenses));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(monthlyMortgage));
	indicator.helpVariableMap.set("VAR_ANNUAL_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(annualMortgage));
	indicator.helpVariableMap.set("VAR_ANNUAL_CASH_FLOW",AppUtility.getAmountStringFromDouble(netAnnualCashFlow));
	indicator.helpVariableMap.set("VAR_VACANCY_RATE", (AppUtility.roundToTheNearest(vacancyRate * 100, 100)).toString()); 
	indicator.helpVariableMap.set("VAR_VACANCY_CREDIT_LOSS", AppUtility.getAmountStringFromDouble(creditLoss));
	indicator.helpVariableMap.set("VAR_GROSS_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(grossOperatingIncome));
	indicator.helpVariableMap.set("VAR_NET_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(netOperatingIncome));
	indicator.helpVariableMap.set("VAR_CASH_ON_CASH_RETURN",AppUtility.getIndicatorValue(valueType, cashOnCash));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>cash on cash return</b> for this property is green, which means the return on the cash invested for this property is good.");
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>cash on cash return</b> for this property is red, which means the return on the cash invested for this property is poor.");
	}


	if (property.financingType =="Cash") {
		buffer = new StringBuffer();
		buffer.append(indicator.helpVariableMap.get("VAR_RESULT_TEXT"));
		buffer.append("\n\n<b>Note</b>: No mortgage has been specified for this property, therefore the initial investment is the entire purchase price; which means the property is not being financed.\n\nTo get better results add a mortgage to finance part of the purchase price.");
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", buffer.toString());

	}

	//no return 

};

export const getInternalRateOfReturnIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let irr = 99.99;
	let irrPercent = 0;
	let initialCashInvestment = 0;
	let totalClosingCosts = 0;
	let discountedCashFlow = 0;
	let year1PV = 0;
	let year2PV = 0;
	let year3PV = 0;
	let year4PV = 0;
	let year5PV = 0;
	let year6PV = 0;
	let year7PV = 0;
	let year8PV = 0;
	let year9PV = 0;
	let year10PV = 0;
	
	//const test = getPresentValue(20222,0,39.4,3)
	//const test = getPresentValue(20222,0,99.96,3)
	//alert(test);

	const yearPVs  = new Map();
	let acceptCompareText = "";
	let rejectCompareText = "";

	let year1SalesProceeds = 0;
	let year2SalesProceeds = 0;
	let year3SalesProceeds = 0;
	let year4SalesProceeds = 0;
	let year5SalesProceeds = 0;
	let year6SalesProceeds = 0;
	let year7SalesProceeds = 0;
	let year8SalesProceeds = 0;
	let year9SalesProceeds = 0;
	let year10SalesProceeds = 0;

	let yearNumber = 0;

	const yearSaleProceeds  = new Map();

	if (property != null && property != null) {
		totalClosingCosts = property.closingCosts;
		
		yearNumber = parseInt(property.projectedYearOfSale.toLowerCase().replace("year","").trim());

		// it means it is was not financed, it was purchased with cash
		//if (initialCashInvestment == 0 && property.interestRate == 0 && property.downPayment == 0) 
		//{
		//	initialCashInvestment = property.price;
		//}

		if (property.financingType ==="Loan")
		{
			initialCashInvestment = parseInt(totalClosingCosts) + parseInt(property.downPayment);

		} else if (property.financingType ==="Cash")
		{
			initialCashInvestment = parseInt(totalClosingCosts) + parseInt(property.price);
		} 

		
		/* Dynamic */
		let currentYear = 0;

		for (let e = 0; e < yearNumber; e++) {

			currentYear = e + 1;
			
			yearSaleProceeds.set(currentYear,0);
		}

		property["year"+yearNumber+"SalesProceeds"] = property.projectedSaleProceeds;
		yearSaleProceeds.set(yearNumber,property.projectedSaleProceeds);

		//const yearSaleProceed = parseInt(property["year"+yearNumber+"CashFlow"]) - parseInt(property.projectedSaleProceeds);
		//yearSaleProceeds.set(yearNumber,yearSaleProceed); 


		let discountedCashFlowPrevious = 0;
		let irrPrevious = 0;

		// while (discountedCashFlow <= initialCashInvestment || irr <= 0)
		while (discountedCashFlow <= initialCashInvestment) {
			if (irr <= -1000) {
				break;
			}

			discountedCashFlowPrevious = discountedCashFlow;

			discountedCashFlow = 0;
			/* dynamic */
			
			for (let i = 0; i < yearNumber; i++) {

				currentYear = i + 1;	
				//console.log("The number is " + currentYear );

				const yearPV = getPresentValue(parseInt(property["year"+currentYear+"CashFlow"]) ,parseInt(yearSaleProceeds.get(currentYear)), irr, currentYear);

				discountedCashFlow = discountedCashFlow + yearPV;

				yearPVs.set(currentYear,yearPV); 

			} //end loop

			
			irr = irr - 0.01;
		}

		if (irr == 99) {
			irr = 0.0;
		} else {
			irr = irr + 0.01;
		}

		if (discountedCashFlow < initialCashInvestment) {
			irr = irrPrevious;
		}

		irrPercent = irr / 100;

		//irr = AppUtility.roundToTheNearest(irr, 100);
		irr = Math.round(irr);
		irrPercent = AppUtility.roundToTheNearest(irrPercent, 10000);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.rrrAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.rrrAccepted, irr, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.rrrRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.rrrRejected, irr, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}

	} else {
		irr = 0;
	}

	if (comparisonSymbolMap != null) {
		let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.rrrAcceptedCompareId));
		let symbol = compareOperatorVO.symbol;
		accepted = compareBenchmarkedValue(benchmarkingSetting.rrrAccepted, irr, symbol);
		acceptCompareText = compareOperatorVO.nickName;

		compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.rrrRejectedCompareId));
		symbol = compareOperatorVO.symbol;
		rejected = compareBenchmarkedValue(benchmarkingSetting.rrrRejected, irr, symbol);
		passFlag = accepted && !rejected;
		rejectCompareText = compareOperatorVO.nickName;
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.IRR_INDICATOR_NAME;
	indicator.indicatorAcronym =  IndicatorVO.IRR_INDICATOR_ACRONYM;
	//indicator.indicatorValue = irrPercent;
	indicator.indicatorValue = irr;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();

	populateInternalRateOfReturnIndicatorValueMap(indicator, initialCashInvestment, totalClosingCosts, irrPercent,yearNumber,property,yearPVs,yearSaleProceeds,property.year1CashFlow, year1SalesProceeds, year1PV,property.year1CashFlow + year1SalesProceeds,property.year2CashFlow, year2SalesProceeds, year2PV,property.year2CashFlow + year2SalesProceeds,property.year3CashFlow, year3SalesProceeds, year3PV,property.year3CashFlow + year3SalesProceeds,property.year4CashFlow, year4SalesProceeds, year4PV,property.year4CashFlow + year4SalesProceeds,property.year5CashFlow, year5SalesProceeds, year5PV,property.year5CashFlow + year5SalesProceeds,property.year6CashFlow, year6SalesProceeds, year6PV,property.year6CashFlow + year7SalesProceeds,property.year7CashFlow, year7SalesProceeds, year7PV,property.year7CashFlow + year7SalesProceeds,property.year8CashFlow, year8SalesProceeds, year8PV,property.year8CashFlow + year8SalesProceeds,property.year9CashFlow, year9SalesProceeds, year9PV,property.year9CashFlow + year9SalesProceeds,property.year10CashFlow, year10SalesProceeds, year10PV,property.year10CashFlow + year10SalesProceeds, discountedCashFlow, acceptCompareText,benchmarkingSetting.rrrAccepted, rejectCompareText,benchmarkingSetting.rrrRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateInternalRateOfReturnIndicatorValueMap = (indicator,initialInvestment,totalClosingCosts,internalRateOfReturn,yearNumber,property, yearPVs,yearSaleProceeds,year1CashFlow,year1SaleProceed,year1NPV,year1TotalCashFlow,year2CashFlow,year2SaleProceed,year2NPV,year2TotalCashFlow,year3CashFlow,year3SaleProceed,year3NPV,year3TotalCashFlow,year4CashFlow,year4SaleProceed,year4NPV,year4TotalCashFlow,year5CashFlow,year5SaleProceed,year5NPV,year5TotalCashFlow,year6CashFlow,year6SaleProceed,year6NPV,year6TotalCashFlow,year7CashFlow,year7SaleProceed,year7NPV,year7TotalCashFlow,year8CashFlow,year8SaleProceed,year8NPV,year8TotalCashFlow,year9CashFlow,year9SaleProceed,year9NPV,year9TotalCashFlow,year10CashFlow,year10SaleProceed,year10NPV,year10TotalCashFlow,totalNPV,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {
	
	//let map = getNetPresentValueMap2(year1CashFlow, year1SaleProceed, year1NPV, year1TotalCashFlow, year2CashFlow, year2SaleProceed, year2NPV, year2TotalCashFlow, year3CashFlow, year3SaleProceed, year3NPV, year3TotalCashFlow, year4CashFlow, year4SaleProceed, year4NPV, year4TotalCashFlow, year5CashFlow, year5SaleProceed, year5NPV, year5TotalCashFlow, year6CashFlow, year6SaleProceed, year6NPV, year6TotalCashFlow, year7CashFlow, year7SaleProceed, year7NPV, year7TotalCashFlow, year8CashFlow, year8SaleProceed, year8NPV, year8TotalCashFlow, year9CashFlow, year9SaleProceed, year9NPV, year9TotalCashFlow, year10CashFlow, year10SaleProceed, year10NPV, year10TotalCashFlow);

	//alert(yearSaleProceeds);

	let map = getNetPresentValueMap(yearNumber,property, yearPVs,yearSaleProceeds);
	setAll(map,indicator.helpVariableMap);

	if (internalRateOfReturn == 99.99 || internalRateOfReturn == IndicatorVO.UNDEFINED_IRR_VALUE) // same as -0.099999
	{
		indicator.helpVariableMap.set("VAR_INTERNAL_RATE_OF_RETURN", "Undefined");
		indicator.helpVariableMap.set("VAR_INTERNAL_RATE_OF_RETURN_PLAIN", "Undefined");
	} else {
		indicator.helpVariableMap.set("VAR_INTERNAL_RATE_OF_RETURN",AppUtility.getIndicatorValue(valueType, internalRateOfReturn));
		indicator.helpVariableMap.set("VAR_INTERNAL_RATE_OF_RETURN_PLAIN",AppUtility.getIndicatorValue(IndicatorVO.DECIMAL, internalRateOfReturn));
	}

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());
	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	indicator.helpVariableMap.set("VAR_SALE_PROCEEDS",AppUtility.getAmountStringFromDouble(property.projectedSaleProceeds));

	indicator.helpVariableMap.set("VAR_INITAL_INVESTMENT",AppUtility.getAmountStringFromDouble(initialInvestment));
	indicator.helpVariableMap.set("TOTAL_NPV", AppUtility.getAmountStringFromDouble(totalNPV));

	buffer = new StringBuffer();

	if (indicator.meetsBenchmark) {

		buffer.append("The <b>internal rate of return</b> (IRR) for this property meets your required internal rate of return of <b>");
		buffer.append(acceptValue);
		buffer.append("%</b>. \n\nThis means that the profitability of this rental property is good based on its predicted future cash flows.\n\nNote: It is wise to compare the internal rate of return of several potential properties to see which one has the highest IRR.");

		indicator.helpVariableMap.set("VAR_RESULT_TEXT", buffer.toString());

	} else {
		if (internalRateOfReturn == 99.99 || internalRateOfReturn == IndicatorVO.UNDEFINED_IRR_VALUE) // same
																										// as
		// -0.099999
		{
			buffer.append("The <b>internal rate of return</b> (IRR) for this property is undefined. This means that the Internal Rate of Return is so negative it is impossible to accurately calculate. ");
		} else {
			buffer.append("The <b>internal rate of return</b> (IRR) for this property doesn't meet your required internal rate of return of  <b>");
			buffer.append(acceptValue);
			buffer.append("%</b>. \n\nThis means that the profitability of this rental property is bad based on its predicted future cash flows.");
		}
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", buffer.toString());
	}


	//no return 

};

export const getNetPresentValueMap2 = (year1CashFlow,year1SaleProceed,year1NPV,year1TotalCashFlow,year2CashFlow,year2SaleProceed,year2NPV,year2TotalCashFlow,year3CashFlow,year3SaleProceed,year3NPV,year3TotalCashFlow,year4CashFlow,year4SaleProceed,year4NPV,year4TotalCashFlow,year5CashFlow,year5SaleProceed,year5NPV,year5TotalCashFlow,year6CashFlow,year6SaleProceed,year6NPV,year6TotalCashFlow,year7CashFlow,year7SaleProceed,year7NPV,year7TotalCashFlow,year8CashFlow,year8SaleProceed,year8NPV,year8TotalCashFlow,year9CashFlow,year9SaleProceed,year9NPV,year9TotalCashFlow,year10CashFlow,year10SaleProceed,year10NPV,year10TotalCashFlow) => {
		let map  = new Map();
	
		map.set("VAR_YEAR1_CASH_FLOW", AppUtility.getAmountStringFromDouble(year1CashFlow));
		map.set("VAR_YEAR1_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year1SaleProceed));
		map.set("VAR_YEAR1_NPV", AppUtility.getAmountStringFromDouble(year1NPV));
		map.set("VAR_YEAR1_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year1TotalCashFlow));
	
		map.set("VAR_YEAR2_CASH_FLOW", AppUtility.getAmountStringFromDouble(year2CashFlow));
		map.set("VAR_YEAR2_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year2SaleProceed));
		map.set("VAR_YEAR2_NPV", AppUtility.getAmountStringFromDouble(year2NPV));
		map.set("VAR_YEAR2_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year2TotalCashFlow));
	
		map.set("VAR_YEAR3_CASH_FLOW", AppUtility.getAmountStringFromDouble(year3CashFlow));
		map.set("VAR_YEAR3_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year3SaleProceed));
		map.set("VAR_YEAR3_NPV", AppUtility.getAmountStringFromDouble(year3NPV));
		map.set("VAR_YEAR3_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year3TotalCashFlow));
	
		map.set("VAR_YEAR4_CASH_FLOW", AppUtility.getAmountStringFromDouble(year4CashFlow));
		map.set("VAR_YEAR4_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year4SaleProceed));
		map.set("VAR_YEAR4_NPV", AppUtility.getAmountStringFromDouble(year4NPV));
		map.set("VAR_YEAR4_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year4TotalCashFlow));
	
		map.set("VAR_YEAR5_CASH_FLOW", AppUtility.getAmountStringFromDouble(year5CashFlow));
		map.set("VAR_YEAR5_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year5SaleProceed));
		map.set("VAR_YEAR5_NPV", AppUtility.getAmountStringFromDouble(year5NPV));
		map.set("VAR_YEAR5_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year5TotalCashFlow));
	
		map.set("VAR_YEAR6_CASH_FLOW", AppUtility.getAmountStringFromDouble(year6CashFlow));
		map.set("VAR_YEAR6_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year6SaleProceed));
		map.set("VAR_YEAR6_NPV", AppUtility.getAmountStringFromDouble(year6NPV));
		map.set("VAR_YEAR6_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year6TotalCashFlow));
	
		map.set("VAR_YEAR7_CASH_FLOW", AppUtility.getAmountStringFromDouble(year7CashFlow));
		map.set("VAR_YEAR7_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year7SaleProceed));
		map.set("VAR_YEAR7_NPV", AppUtility.getAmountStringFromDouble(year7NPV));
		map.set("VAR_YEAR7_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year7TotalCashFlow));
	
		map.set("VAR_YEAR8_CASH_FLOW", AppUtility.getAmountStringFromDouble(year8CashFlow));
		map.set("VAR_YEAR8_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year8SaleProceed));
		map.set("VAR_YEAR8_NPV", AppUtility.getAmountStringFromDouble(year8NPV));
		map.set("VAR_YEAR8_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year8TotalCashFlow));
	
		map.set("VAR_YEAR9_CASH_FLOW", AppUtility.getAmountStringFromDouble(year9CashFlow));
		map.set("VAR_YEAR9_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year9SaleProceed));
		map.set("VAR_YEAR9_NPV", AppUtility.getAmountStringFromDouble(year9NPV));
		map.set("VAR_YEAR9_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year9TotalCashFlow));
	
		map.set("VAR_YEAR10_CASH_FLOW", AppUtility.getAmountStringFromDouble(year10CashFlow));
		map.set("VAR_YEAR10_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year10SaleProceed));
		map.set("VAR_YEAR10_NPV", AppUtility.getAmountStringFromDouble(year10NPV));
		map.set("VAR_YEAR10_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year10TotalCashFlow));
	
		return map;
	
	};

	
export const getNetPresentValueMap = (yearNumber,property, yearPVMap,yearSaleProceedMap) => {

	//alert("yearSaleProceeds:"+yearSaleProceeds);

	let map  = new Map();

	/* dynamic */
	let currentYear = 0;

	let yearCashFlow = 0;
	let yearSaleProceeds = 0;
	let yearNPV = 0;
	let yearTotalCashFlow = 0;

	const cashFlowArray = [];

	for (let e = 0; e < yearNumber; e++) {

		currentYear = e + 1;
		
		yearCashFlow = property["year"+currentYear+"CashFlow"];
		yearSaleProceeds = yearSaleProceedMap.get(currentYear);
		yearNPV = yearPVMap.get(currentYear);;
		yearTotalCashFlow = parseInt(yearCashFlow) + parseInt(yearSaleProceeds);
		
		const cashFlowObj = {
			year : currentYear,
			cashFlow: AppUtility.getAmountStringFromDouble(yearCashFlow),
			saleProceeds: AppUtility.getAmountStringFromDouble(yearSaleProceeds),
			npv: AppUtility.getAmountStringFromDoubleNoFractionalPart(yearNPV),
			totalCashFlow: AppUtility.getAmountStringFromDouble(yearTotalCashFlow)
		}

		cashFlowArray.push(cashFlowObj);	
		
	//	map.set("VAR_YEAR"+currentYear+"_CASH_FLOW", AppUtility.getAmountStringFromDouble(yearCashFlow));
	//	map.set("VAR_YEAR"+currentYear+"_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(yearSaleProceed));
	//	map.set("VAR_YEAR"+currentYear+"_NPV", AppUtility.getAmountStringFromDouble(yearNPV));
	//	map.set("VAR_YEAR"+currentYear+"_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(yearTotalCashFlow));

	}
	map.set("VAR_CASHFLOWS",cashFlowArray);


	/* static 
	map.set("VAR_YEAR1_CASH_FLOW", AppUtility.getAmountStringFromDouble(year1CashFlow));
	map.set("VAR_YEAR1_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year1SaleProceed));
	map.set("VAR_YEAR1_NPV", AppUtility.getAmountStringFromDouble(year1NPV));
	map.set("VAR_YEAR1_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year1TotalCashFlow));

	map.set("VAR_YEAR2_CASH_FLOW", AppUtility.getAmountStringFromDouble(year2CashFlow));
	map.set("VAR_YEAR2_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year2SaleProceed));
	map.set("VAR_YEAR2_NPV", AppUtility.getAmountStringFromDouble(year2NPV));
	map.set("VAR_YEAR2_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year2TotalCashFlow));

	map.set("VAR_YEAR3_CASH_FLOW", AppUtility.getAmountStringFromDouble(year3CashFlow));
	map.set("VAR_YEAR3_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year3SaleProceed));
	map.set("VAR_YEAR3_NPV", AppUtility.getAmountStringFromDouble(year3NPV));
	map.set("VAR_YEAR3_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year3TotalCashFlow));

	map.set("VAR_YEAR4_CASH_FLOW", AppUtility.getAmountStringFromDouble(year4CashFlow));
	map.set("VAR_YEAR4_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year4SaleProceed));
	map.set("VAR_YEAR4_NPV", AppUtility.getAmountStringFromDouble(year4NPV));
	map.set("VAR_YEAR4_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year4TotalCashFlow));

	map.set("VAR_YEAR5_CASH_FLOW", AppUtility.getAmountStringFromDouble(year5CashFlow));
	map.set("VAR_YEAR5_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year5SaleProceed));
	map.set("VAR_YEAR5_NPV", AppUtility.getAmountStringFromDouble(year5NPV));
	map.set("VAR_YEAR5_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year5TotalCashFlow));

	map.set("VAR_YEAR6_CASH_FLOW", AppUtility.getAmountStringFromDouble(year6CashFlow));
	map.set("VAR_YEAR6_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year6SaleProceed));
	map.set("VAR_YEAR6_NPV", AppUtility.getAmountStringFromDouble(year6NPV));
	map.set("VAR_YEAR6_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year6TotalCashFlow));

	map.set("VAR_YEAR7_CASH_FLOW", AppUtility.getAmountStringFromDouble(year7CashFlow));
	map.set("VAR_YEAR7_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year7SaleProceed));
	map.set("VAR_YEAR7_NPV", AppUtility.getAmountStringFromDouble(year7NPV));
	map.set("VAR_YEAR7_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year7TotalCashFlow));

	map.set("VAR_YEAR8_CASH_FLOW", AppUtility.getAmountStringFromDouble(year8CashFlow));
	map.set("VAR_YEAR8_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year8SaleProceed));
	map.set("VAR_YEAR8_NPV", AppUtility.getAmountStringFromDouble(year8NPV));
	map.set("VAR_YEAR8_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year8TotalCashFlow));

	map.set("VAR_YEAR9_CASH_FLOW", AppUtility.getAmountStringFromDouble(year9CashFlow));
	map.set("VAR_YEAR9_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year9SaleProceed));
	map.set("VAR_YEAR9_NPV", AppUtility.getAmountStringFromDouble(year9NPV));
	map.set("VAR_YEAR9_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year9TotalCashFlow));

	map.set("VAR_YEAR10_CASH_FLOW", AppUtility.getAmountStringFromDouble(year10CashFlow));
	map.set("VAR_YEAR10_SALE_PROCEEDS", AppUtility.getAmountStringFromDouble(year10SaleProceed));
	map.set("VAR_YEAR10_NPV", AppUtility.getAmountStringFromDouble(year10NPV));
	map.set("VAR_YEAR10_TOTAL_CASH_FLOW", AppUtility.getAmountStringFromDouble(year10TotalCashFlow));
	*/

	return map;

};

export const getProfitabilityIndexIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;
	
	let indicator = null;

	let passFlag = false;
	let initialCashInvestment = 0;
	let discountedCashFlow = 0;
	let year1PV = 0;
	let year2PV = 0;
	let year3PV = 0;
	let year4PV = 0;
	let year5PV = 0;
	let year6PV = 0;
	let year7PV = 0;
	let year8PV = 0;
	let year9PV = 0;
	let year10PV = 0;
	let acceptCompareText = "Greater Than Or Equal To 1.0";
	let rejectCompareText = "Less Than 1.0";
	let requiredRateOfReturn = 0;
	let pi = 0;
	let totalClosingCosts = 0;

	if (benchmarkingSetting != null) {
		requiredRateOfReturn = benchmarkingSetting.rrrAccepted;
	}

	const yearPVs  = new Map();

	let discountedCashFlowPrevious = 0;
	let year1SalesProceeds = 0;
	let year2SalesProceeds = 0;
	let year3SalesProceeds = 0;
	let year4SalesProceeds = 0;
	let year5SalesProceeds = 0;
	let year6SalesProceeds = 0;
	let year7SalesProceeds = 0;
	let year8SalesProceeds = 0;
	let year9SalesProceeds = 0;
	let year10SalesProceeds = 0;

	let yearNumber = 0;

	const yearSaleProceeds  = new Map();

	if (property != null && property != null && property != null) {

		totalClosingCosts = property.closingCosts;

		//initialCashInvestment = parseInt(totalClosingCosts) + parseInt(property.downPayment);

		if (property.financingType ==="Loan")
		{
			initialCashInvestment = parseInt(totalClosingCosts) + parseInt(property.downPayment);

		} else if (property.financingType ==="Cash")
		{
			initialCashInvestment = parseInt(totalClosingCosts) + parseInt(property.price);
		} 

		yearNumber = parseInt(property.projectedYearOfSale.toLowerCase().replace("year","").trim());

		
		/* Dynamic */
		let currentYear = 0;

		for (let e = 0; e < yearNumber; e++) {

			currentYear = e + 1;
			
			yearSaleProceeds.set(currentYear,0);
		}

		//Set saleproceeds for the last year
		property["year"+yearNumber+"SalesProceeds"] = property.projectedSaleProceeds;
		yearSaleProceeds.set(yearNumber,property.projectedSaleProceeds);

	
		discountedCashFlowPrevious = discountedCashFlow;

		discountedCashFlow = 0;
		/* dynamic */
		
		for (let i = 0; i < yearNumber; i++) {

			currentYear = i + 1;	
			//console.log("The number is " + currentYear );

			const yearPV = getPresentValue(parseInt(property["year"+currentYear+"CashFlow"]) ,parseInt(yearSaleProceeds.get(currentYear)), requiredRateOfReturn, currentYear);

			discountedCashFlow = discountedCashFlow + yearPV;

			yearPVs.set(currentYear,yearPV); 

		} //end loop


		if (initialCashInvestment > 0) {
			pi = discountedCashFlow / initialCashInvestment;
		}

		pi = AppUtility.roundToTheNearest(pi, 100);

	} else {
		pi = 0;

	}

	if (pi >= 1) {
		passFlag = true;
	} else {
		passFlag = false;
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.PI_INDICATOR_NAME;
	indicator.indicatorAcronym =  IndicatorVO.PI_INDICATOR_ACRONYM;
	indicator.indicatorValue =  pi;
	indicator.property = property;
	indicator.valueType =  IndicatorVO.DECIMAL;
	indicator.helpVariableMap = new Map();


	populateProfitabilityIndexIndicatorValueMap(indicator, initialCashInvestment, totalClosingCosts, pi, yearNumber,property,yearPVs,yearSaleProceeds,property.year1CashFlow, year1SalesProceeds, year1PV, property.year1CashFlow + year1SalesProceeds,

	property.year2CashFlow, year2SalesProceeds, year2PV, property.year2CashFlow + year2SalesProceeds,

	property.year3CashFlow, year3SalesProceeds, year3PV, property.year3CashFlow + year3SalesProceeds,

	property.year4CashFlow, year4SalesProceeds, year4PV, property.year4CashFlow + year4SalesProceeds,

	property.year5CashFlow, year5SalesProceeds, year5PV, property.year5CashFlow + year5SalesProceeds,

	property.year6CashFlow, year6SalesProceeds, year6PV, property.year6CashFlow + year6SalesProceeds,

	property.year7CashFlow, year7SalesProceeds, year7PV, property.year7CashFlow + year7SalesProceeds,

	property.year8CashFlow, year8SalesProceeds, year8PV, property.year8CashFlow + year8SalesProceeds,

	property.year9CashFlow, year9SalesProceeds, year9PV, property.year9CashFlow + year9SalesProceeds,

	property.year10CashFlow, year10SalesProceeds, year10PV, property.year10CashFlow + year10SalesProceeds, discountedCashFlow, acceptCompareText, benchmarkingSetting.rrrAccepted, rejectCompareText, benchmarkingSetting.rrrRejected, IndicatorVO.DECIMAL, requiredRateOfReturn);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	// } // End if statement
	return indicator;

};

export const populateProfitabilityIndexIndicatorValueMap = (indicator,initialInvestment,totalClosingCosts,pi,yearNumber,property,yearPVs,yearSaleProceeds,year1CashFlow,year1SaleProceed,year1NPV,year1TotalCashFlow,year2CashFlow,year2SaleProceed,year2NPV,year2TotalCashFlow,year3CashFlow,year3SaleProceed,year3NPV,year3TotalCashFlow,year4CashFlow,year4SaleProceed,year4NPV,year4TotalCashFlow,year5CashFlow,year5SaleProceed,year5NPV,year5TotalCashFlow,year6CashFlow,year6SaleProceed,year6NPV,year6TotalCashFlow,year7CashFlow,year7SaleProceed,year7NPV,year7TotalCashFlow,year8CashFlow,year8SaleProceed,year8NPV,year8TotalCashFlow,year9CashFlow,year9SaleProceed,year9NPV,year9TotalCashFlow,year10CashFlow,year10SaleProceed,year10NPV,year10TotalCashFlow,totalNPV,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType,rrr) => {

	
	//let map = getNetPresentValueMap2(year1CashFlow, year1SaleProceed, year1NPV, year1TotalCashFlow, year2CashFlow, year2SaleProceed, year2NPV, year2TotalCashFlow, year3CashFlow, year3SaleProceed, year3NPV, year3TotalCashFlow, year4CashFlow, year4SaleProceed, year4NPV, year4TotalCashFlow, year5CashFlow, year5SaleProceed, year5NPV, year5TotalCashFlow, year6CashFlow, year6SaleProceed, year6NPV, year6TotalCashFlow, year7CashFlow, year7SaleProceed, year7NPV, year7TotalCashFlow, year8CashFlow, year8SaleProceed, year8NPV, year8TotalCashFlow, year9CashFlow, year9SaleProceed, year9NPV, year9TotalCashFlow, year10CashFlow, year10SaleProceed, year10NPV, year10TotalCashFlow);

	let map = getNetPresentValueMap(yearNumber,property, yearPVs,yearSaleProceeds);

	setAll(map,indicator.helpVariableMap); //HERE

	indicator.helpVariableMap.set("VAR_REQUIRED_RATE_OF_RETURN",AppUtility.getIndicatorValue(IndicatorVO.PERCENT, rrr / 100));
	indicator.helpVariableMap.set("VAR_REQUIRED_RATE_PLAIN",AppUtility.getIndicatorValue(IndicatorVO.DECIMAL, rrr));
	indicator.helpVariableMap.set("VAR_PROFITABILITY_INDEX", AppUtility.getIndicatorValue(valueType, pi));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", acceptCompareText);
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", rejectCompareText);

	indicator.helpVariableMap.set("VAR_SALE_PROCEEDS",AppUtility.getAmountStringFromDouble(property.projectedSaleProceeds));

	indicator.helpVariableMap.set("VAR_INITAL_INVESTMENT",AppUtility.getAmountStringFromDouble(initialInvestment));
	indicator.helpVariableMap.set("TOTAL_NPV", AppUtility.getAmountStringFromDouble(totalNPV));

	let buffer = new StringBuffer();
	if (pi > 1) {
		buffer.append("The <b>profitability index</b> for this property is greater than <b>1.0</b>, which means you have exceeded your required rate of return of <b>");
		buffer.append(AppUtility.getIndicatorValue(IndicatorVO.DECIMAL, rrr));
		buffer.append("%</b>.\n\nTherefore, the cost-benefit for this property is good.");

		buffer.append("\n\nNote: You specify your required rate of return in the settings. It is currently set at <b>"+ AppUtility.getIndicatorValue(IndicatorVO.DECIMAL, rrr)+"%</b>.");

		indicator.helpVariableMap.set("VAR_RESULT_TEXT", buffer.toString());

	} else if (pi < 1) {
		buffer.append("The <b>profitability index</b> for this property is below <b>1.0</b> which, means you failed to reach your required rate of return of <b>");
		buffer.append(AppUtility.getIndicatorValue(IndicatorVO.DECIMAL, rrr));
		buffer.append("%</b>.\n\nTherefore, the cost-benefit for this property is good.");

		buffer.append("\n\nNote: You specify your required rate of return in the settings. It is currently set at <b>"+ AppUtility.getIndicatorValue(IndicatorVO.DECIMAL, rrr)+"%</b>.");


		indicator.helpVariableMap.set("VAR_RESULT_TEXT", buffer.toString());

	} else if (pi == 1) {
		buffer.append("The <b>profitability index</b> for this property is <b>1.0</b>, which means you achieved exactly your required rate of return of <b>");
		buffer.append(AppUtility.getIndicatorValue(IndicatorVO.DECIMAL, rrr));
		buffer.append("%</b>.");
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", buffer.toString());
	}

	if (property.financingType =="Cash") {
		buffer = new StringBuffer();
		buffer.append(indicator.helpVariableMap.get("VAR_RESULT_TEXT"));
		buffer.append("\n\n<b>Note</b>: No mortgage has been specified for this property, therefore the initial investment is the entire purchase price; which means the property is not being financed. To get better results add a mortgage to finance part of the purchase price.");
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", buffer.toString());

	}

	//no return 

};

export const getDebtCoverageRatioRentalIndicator = (property,benchmarkingSetting) => {

	// Debt Coverage Ratio = Annual Net Operating Income (NOI) / Annual Debt
	// Service

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let monthlyRent = 0;
	let grossScheduledIncome = 0;
	let vacancyRate = 0;
	let creditLoss = 0;
	let grossOperatingIncome = 0;
	let totalExpenses = 0;
	let netOperatingIncome = 0;
	let debtCoverageRatio = 0;
	let monthylMortgagePayment = 0;
	let annualDebtService = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		// 1. Monthly Rent
		if (property != null) {
			monthlyRent = property.monthlyRent;
			vacancyRate = parseInt(property.vacancyRate) / 100;
		}

		// 2. Gross Schedule Income
		grossScheduledIncome = getMonthlyScheduledIncome(monthlyRent);

		// 3. Credit Loss
		creditLoss = getCreditLoss(grossScheduledIncome, vacancyRate);

		// 4. Gross Operating Income
		grossOperatingIncome = getGrossOperatingIncome(grossScheduledIncome, creditLoss);

		// 5. Total Expenses

		totalExpenses = getTotalExpenses(property);

		// 6. Net Operating Income
		netOperatingIncome = getNetOperatingIncome(grossOperatingIncome, totalExpenses);

		// 7. Monthly Mortgage Payment
		if (property != null) {
			monthylMortgagePayment = property.monthlyPayment;
		}

		// 8. Annual Debt Service (ADS)
		annualDebtService = parseInt(monthylMortgagePayment) * 12;

		// 9. debtCoverageRatio
		if (annualDebtService > 0) {
			debtCoverageRatio = parseInt(netOperatingIncome) / parseInt(annualDebtService);
		} else {
			debtCoverageRatio = 0;
		}

		debtCoverageRatio = AppUtility.roundToTheNearest(debtCoverageRatio, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.dcrAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.dcrAccepted, debtCoverageRatio, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.dcrRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.dcrRejected, debtCoverageRatio, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.DEBT_CONVERAGE_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.DEBT_CONVERAGE_RATIO_INDICATOR_ACRONYM;
	indicator.indicatorValue =  debtCoverageRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DECIMAL;
	indicator.helpVariableMap = new Map();


	populateDebtCoverageRatioIndicatorValueMap(indicator, monthlyRent, monthylMortgagePayment, grossScheduledIncome, totalExpenses, property == null ? 0 : property.monthlyPayment, annualDebtService, vacancyRate * 100, creditLoss, grossOperatingIncome, netOperatingIncome, debtCoverageRatio, acceptCompareText, benchmarkingSetting.dcrAccepted, rejectCompareText, benchmarkingSetting.dcrRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateDebtCoverageRatioIndicatorValueMap = (indicator,monthlyRent,monthylMortgagePayment,grossScheduledIncome,annualExpenses,monthlyMortgage,annualMortgage,vacancyRate,creditLoss,grossOperatingIncome,netOperatingIncome,debtCoverageRatio,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_MONTHLY_RENT", AppUtility.getAmountStringFromDouble(monthlyRent));
	indicator.helpVariableMap.set("VAR_GROSS_ANNUAL_INCOME",AppUtility.getAmountStringFromDouble(grossScheduledIncome));
	indicator.helpVariableMap.set("VAR_ANNUAL_EXPENSES", AppUtility.getAmountStringFromDouble(annualExpenses));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(monthlyMortgage));
	indicator.helpVariableMap.set("VAR_ANNUAL_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(annualMortgage));
	indicator.helpVariableMap.set("VAR_VACANCY_RATE", (AppUtility.roundToTheNearest(vacancyRate, 100)).toString()); 
	indicator.helpVariableMap.set("VAR_VACANCY_CREDIT_LOSS", AppUtility.getAmountStringFromDouble(creditLoss));
	indicator.helpVariableMap.set("VAR_GROSS_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(grossOperatingIncome));
	indicator.helpVariableMap.set("VAR_NET_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(netOperatingIncome));
	indicator.helpVariableMap.set("VAR_DEBT_COVERAGE_RATIO",AppUtility.getIndicatorValue(valueType, debtCoverageRatio));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is green, which means you have sufficient funds to cover the annual mortgage payments and have some money left over.");
	} else {
		if (debtCoverageRatio == 1) {
			indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is red, wich means you just have enough money to cover the annual mortgage payments.");			
		} else {
			indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is red, which means you don't have enough money to cover the annual mortgage payments.");
		}
	}

	if (monthylMortgagePayment == 0) {
		indicator.helpVariableMap.set("VAR_DEBT_COVERAGE_RATIO", "N/A");
		indicator.indicatorValue = 0;
		indicator.undeterminedFlag = true;
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> is not applicable for this property since there is no mortgage for the property, which means there is no debt associated with the property.");
	}

	//no return 

};

export const getDebtCoverageRatioHomeIndicator = (property,benchmarkingSetting) => {

	// Debt Coverage Ratio = Annual Net Operating Income (NOI) / Annual Debt
	// Service

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let grossIncome = 0;
	let dcr = 0;
	let noi = 0;
	let monthlyMortgage = 0;
	let monthlyDebt = 0;
	let monthlyBills = 0;
	let totalInvestment = 0;
	let purchasePrice = 0;
	let debtService = 0;

	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		grossIncome = property.monthlyGrossIncome;
		monthlyMortgage = property.monthlyPayment;

		monthlyBills = property.monthlyBills;

		if (property.billfrequency == "Annually") {
			monthlyBills = parseInt(monthlyBills) / 12;

		} else if (property.billfrequency == "Quarterly")

		{
			monthlyBills = parseInt(monthlyBills) / 4;

		}

		noi = parseInt(grossIncome) - parseInt(monthlyBills);

		dcr = noi / parseInt(monthlyMortgage);

		dcr = AppUtility.roundToTheNearest(dcr, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.dcrAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.dcrAccepted, dcr, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.dcrRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.dcrRejected, dcr, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.DEBT_CONVERAGE_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.DEBT_CONVERAGE_RATIO_INDICATOR_ACRONYM;
	indicator.indicatorValue =  dcr;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DECIMAL;
	indicator.helpVariableMap = new Map();


	populateDebtCoverageRatioHomeIndicatorValueMap(indicator, property, grossIncome, dcr, noi, monthlyMortgage, monthlyBills, debtService, acceptCompareText, benchmarkingSetting.dcrAccepted, rejectCompareText, benchmarkingSetting.dcrRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateDebtCoverageRatioHomeIndicatorValueMap = (indicator,property,grossIncome,dcr,noi,monthlyMortgage,monthlyBills,debtService,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_DEBT_COVERAGE_RATIO", AppUtility.getIndicatorValue(valueType, dcr));

	indicator.helpVariableMap.set("VAR_GROSS_INCOME", AppUtility.getAmountStringFromDouble(grossIncome));

	indicator.helpVariableMap.set("VAR_NET_PROFITS", AppUtility.getAmountStringFromDouble(noi));

	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE",AppUtility.getAmountStringFromDouble(monthlyMortgage));

	indicator.helpVariableMap.set("VAR_MONTHLY_BILLS", AppUtility.getAmountStringFromDouble(monthlyBills));

	indicator.helpVariableMap.set("VAR_DEBT_SERVICE", AppUtility.getAmountStringFromDouble(debtService));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is green, which means you have sufficient funds to cover the annual mortgage payments and have some money left over. ");
	} else {
		if (dcr == 1) {
			indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is red, wich means you just have enough money to cover the annual mortgage payments. ");	
		} else {
			indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is red, which means you don't have enough money to cover the annual mortgage payments. ");		
		}
	}

	if (monthlyMortgage == 0) {
		indicator.helpVariableMap.set("VAR_DEBT_COVERAGE_RATIO", "N/A");
		indicator.indicatorValue = 0;
		indicator.undeterminedFlag = true;
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> is not applicable for this property since there is no mortgage for the property, which means there is no debt associated with the property.");
	}

	//no return 

};

export const getDebtCoverageRatioFlipIndicator = (property,benchmarkingSetting) => {

	// Debt Coverage Ratio = Annual Net Operating Income (NOI) / Annual Debt

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let repairs = 0;

	let monthylMortgagePayment = 0;
	let totalMortgagePayments = 0;
	let debtCoverageRatio = 0;

	// repairs
	/*
	let electricalCosts = 0;
	let carpentryCosts = 0;
	let plumbingCosts = 0;
	let hvacCosts = 0;
	let paintingCosts = 0;
	let flooringCosts = 0;
	let roofingCosts = 0;
	let landscapingCosts = 0;
	let otherRepairCosts = 0;
	*/

	let avr = 0;
	let holdingCosts = 0;

	// holding costs
	/*
	let homeownersInsurance = 0;
	let propertyTaxes = 0;
	let monthlyElectricity = 0;
	let monthlyGas = 0;
	let monthlyWater = 0;
	let monthlyLawn = 0;
	let hoa = 0;
	let otherMonthlyExpenses = 0;
	*/
	let downPayment = 0;

	let monthlyHoldingCosts = 0;
	let sellingCosts = 0;

	// selling costs
	/*
	let realEstateCommissions = 0;
	let titleInsurance = 0;
	let recordingFees = 0;
	let closingCostsSelling = 0;
	let otherSellingCosts = 0;
	*/


	let totalInvestment = 0;
	let profits = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;
		// repairs
		/*
		electricalCosts = property.electricalCosts;
		carpentryCosts = property.carpentryCosts;
		plumbingCosts = property.plumbingCosts;
		hvacCosts = property.hvacCosts;
		paintingCosts = property.paintingCosts;
		flooringCosts = property.flooringCosts;
		roofingCosts = property.roofingCosts;
		landscapingCosts = property.landscapingCosts;
		otherRepairCosts = property.otherRepairCosts;

		repairs = electricalCosts + carpentryCosts + plumbingCosts + hvacCosts + paintingCosts + flooringCosts + roofingCosts + landscapingCosts + otherRepairCosts;
		*/

		repairs = property.rehabCosts;

		avr = property.afterRepairValue;
		
		// holding costs
		/*
		homeownersInsurance = property.homeownersInsurance;
		propertyTaxes = property.propertyTaxes;
		monthlyElectricity = property.monthlyElectricity;
		monthlyGas = property.monthlyGas;
		monthlyWater = property.monthlyWater;
		monthlyLawn = property.monthlyLawn;
		hoa = property.hoa;
		otherMonthlyExpenses = property.otherMonthlyExpenses;

		holdingCosts = homeownersInsurance + propertyTaxes + monthlyElectricity + monthlyGas + monthlyWater + monthlyLawn + hoa + otherMonthlyExpenses;
		*/

		holdingCosts = property.holdingCosts;

		// selling costs
		/*
		realEstateCommissions = property.realEstateCommissions;
		titleInsurance = property.titleInsurance;
		recordingFees = property.recordingFees;
		closingCostsSelling = property.closingCostsSelling;
		otherSellingCosts = property.otherSellingCosts;
		*/

		/*
		 * Note: It does not make sense to add mortgage paymetns to holding months if we
		 * are using mortgage payments in the drc ratio calculation
		 * 
		 * if (property.financingType == "Loan")
		 * 
		 * {
		 * 
		 let holdingCosts = holdingCosts + property.monthlyPayment;
		 * 
		 * }
		 */
		monthlyHoldingCosts = parseInt(holdingCosts);

		if (property.holdingCostsFrequency == "Annually")

		{

			monthlyHoldingCosts = parseInt(holdingCosts) / 12;

		} else if (property.holdingCostsFrequency == "Quarterly")

		{

			monthlyHoldingCosts = parseInt(holdingCosts) / 4;

		}

		holdingCosts = parseInt(monthlyHoldingCosts) * parseInt(property.holdingMonths); // multiply by holding months
														
		/*
		sellingCosts = realEstateCommissions + titleInsurance + recordingFees + closingCostsSelling + otherSellingCosts;
		*/

		sellingCosts = property.sellingCosts;

		let closingCosts = property.closingCosts;

		downPayment = property.downPayment;

		// 1. Calculate total investment
		if (property.financingType == "Cash") {
			totalInvestment = parseInt(purchasePrice) + parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);

		} else if (property.financingType == "Loan") {
			
			totalInvestment = parseInt(downPayment) + parseInt(closingCosts) + parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);

		}

		// 2. Calculate profits
		profits = property.budget - totalInvestment;

		// loan repayment
		/*
		 * if (property.financingType == "Loan") { profits = profits -
		 * loanPayment; }
		 */

		// ***************
		// 7. Monthly Mortgage Payment
		if (property != null) {
			monthylMortgagePayment = property.monthlyPayment;
		}

		// 8. Total Mortgage Payments
		totalMortgagePayments = monthylMortgagePayment * property.holdingMonths;

		// 9. debtCoverageRatio
		if (totalMortgagePayments > 0) {
			debtCoverageRatio = profits / totalMortgagePayments;
		} else {
			debtCoverageRatio = 0;
		}

		debtCoverageRatio = AppUtility.roundToTheNearest(debtCoverageRatio, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.dcrAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.dcrAccepted, debtCoverageRatio, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.dcrRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.dcrRejected, debtCoverageRatio, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.DEBT_CONVERAGE_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.DEBT_CONVERAGE_RATIO_INDICATOR_ACRONYM;
	indicator.indicatorValue =  debtCoverageRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DECIMAL;
	indicator.helpVariableMap = new Map();


	populateDebtCoverageRatioFlipIndicatorValueMap(indicator, property, downPayment, repairs, holdingCosts, sellingCosts, monthlyHoldingCosts, totalInvestment, profits, totalMortgagePayments, debtCoverageRatio, acceptCompareText, benchmarkingSetting.dcrAccepted, rejectCompareText, benchmarkingSetting.dcrRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateDebtCoverageRatioFlipIndicatorValueMap = (indicator,property,downPayment,repairs,holdingCosts,sellingCosts,monthlyHoldingCosts,totalInvestment,profits,totalMortgagePayments,debtCoverageRatio,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_DEBT_COVERAGE_RATIO", AppUtility.getNumberValue(debtCoverageRatio));
	indicator.helpVariableMap.set("VAR_LANDSCAPING_COSTS",AppUtility.getAmountStringFromDouble(property.landscapingCosts));
	indicator.helpVariableMap.set("VAR_SELLING_COSTS", AppUtility.getAmountStringFromDouble(sellingCosts));
	indicator.helpVariableMap.set("VAR_PURCHASE_CLOSING_COSTS",AppUtility.getAmountStringFromDouble(property.closingCosts));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE",AppUtility.getAmountStringFromDouble(property.monthlyPayment));
	indicator.helpVariableMap.set("VAR_MONTHLY_HOLDING_COSTS",AppUtility.getAmountStringFromDouble(monthlyHoldingCosts));
	indicator.helpVariableMap.set("VAR_TOTAL_COSTS", AppUtility.getAmountStringFromDouble(totalInvestment));
	indicator.helpVariableMap.set("VAR_NET_OPERATING_INCOME", AppUtility.getAmountStringFromDouble(profits));
	indicator.helpVariableMap.set("VAR_TOTAL_MORTGAGE_PAYMENTS",AppUtility.getAmountStringFromDouble(totalMortgagePayments));
	indicator.helpVariableMap.set("VAR_HOLDING_MONTHS",AppUtility.getNumberValue(property.holdingMonths));
	indicator.helpVariableMap.set("VAR_REPAIRS", AppUtility.getAmountStringFromDouble(repairs));
	indicator.helpVariableMap.set("VAR_HOLDING_COSTS", AppUtility.getAmountStringFromDouble(holdingCosts));
	indicator.helpVariableMap.set("VAR_BUDGET", AppUtility.getAmountStringFromDouble(property.budget));
	indicator.helpVariableMap.set("VAR_SALE_CLOSING_COSTS",AppUtility.getAmountStringFromDouble(property.closingCosts));
	indicator.helpVariableMap.set("VAR_NET_OPERATING_INCOME", AppUtility.getAmountStringFromDouble(profits));
	indicator.helpVariableMap.set("VAR_TOTAL_SELLING_COSTS",AppUtility.getAmountStringFromDouble(sellingCosts));
	indicator.helpVariableMap.set("VAR_REPAIRS", AppUtility.getAmountStringFromDouble(repairs));
	indicator.helpVariableMap.set("VAR_DOWN_PAYMENT", AppUtility.getAmountStringFromDouble(downPayment));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is green, which indicates that you have sufficient funds to cover the expenses and make the monthly mortgage payments for the <b>"+property.holdingMonths+"-month</b> period you plan to hold the property.");

	} else {
		if (debtCoverageRatio == 1) {
			indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is red, wich means you just have enough money to cover the expenses and pay for the monthly mortgage for the <b>"+property.holdingMonths+"-month</b> period you plan to hold the property.");
		
		} else {
			indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> for this property is red, which means you don't have enough money to cover the expenses and pay for the monthly mortgage for the <b>"+property.holdingMonths+"-month</b> period you plan to hold the property.");
		
		}
	}

	if (property.monthlyPayment == 0) {
		indicator.helpVariableMap.set("VAR_DEBT_COVERAGE_RATIO", "N/A");
		indicator.indicatorValue = 0;
		indicator.undeterminedFlag = true;
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>debt coverage ratio</b> is not applicable for this property since there is no mortgage for the property, which means there is no debt associated with the property.");
	}

//no return 

};

export const getCostOfDebtIndicator = (property,benchmarkingSetting) => {

	// Debt Coverage Ratio = Annual Net Operating Income (NOI) / Annual Debt
	// Service

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let loanAmount = 0;
	let interestRate = 0;
	let loanTerm = "";
	let totalInterest = 0;
	let yearNumber = 0;
	let annualInterestRate = 0;
	let costOfDebtRatio = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		//loanTerm = property.term;
		//yearNumber = AppUtility.getTermYears(property.term);
		const term = property.term.toString().replace("-year","").trim();
		loanTerm = term+" Years";
		yearNumber = term;
		loanAmount = property.price - property.downPayment;
		interestRate = property.interestRate;
		annualInterestRate = ((property.monthlyPayment * property.interestRate) / 100) * 12;

		
		//totalInterest = getAmortizationTotalInterest(yearNumber, loanAmount, annualInterestRate);
		totalInterest = getAmortizationTotalInterest(yearNumber, loanAmount, interestRate);

		costOfDebtRatio = (totalInterest * 100)/ loanAmount;

		costOfDebtRatio = AppUtility.roundToTheNearest(costOfDebtRatio, 100);
/*
		console.log("getCostOfDebtIndicator - loanTerm:"+loanTerm);
		console.log("getCostOfDebtIndicator - yearNumber:"+yearNumber);
		console.log("getCostOfDebtIndicator - loanAmount:"+loanAmount);
		console.log("getCostOfDebtIndicator - interestRate:"+interestRate);
		console.log("getCostOfDebtIndicator - annualInterestRate:"+annualInterestRate);
		console.log("getCostOfDebtIndicator - totalInterest:"+totalInterest);
		console.log("getCostOfDebtIndicator - costOfDebtRatio:"+costOfDebtRatio);
*/
		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.costOfDebtAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.costOfDebtAccepted, costOfDebtRatio,symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.costOfDebtRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.costOfDebtRejected, costOfDebtRatio,symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.COST_OF_DEBT_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.COST_OF_DEBT_INDICATOR_ACRONYM;
	indicator.indicatorValue =  costOfDebtRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateCostOfDebtIndicatorValueMap(indicator, property, loanAmount, interestRate, loanTerm, totalInterest, yearNumber, annualInterestRate, costOfDebtRatio, acceptCompareText, benchmarkingSetting.costOfDebtAccepted, rejectCompareText, benchmarkingSetting.costOfDebtRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};


export const getAmortizationTotalInterest = (yearNumber,loanAmount,annualInterestRate) => {

	let totalInterest = 0;

	const schedulePayments = getAmortizationSchedule(yearNumber,loanAmount,annualInterestRate);

	for (let i = 0; i < schedulePayments.yearMortgageSchedulePayments.length; i++) {
		
		const schedulePayment = schedulePayments.yearMortgageSchedulePayments[i];

		//console.log(JSON.stringify(schedulePayment))
		//console.log("---------HERE-----------");

		for (let e = 0; e < schedulePayment.payments.length; e++) {
	
			const payment = schedulePayment.payments[e];

			//console.log(schedulePayment.interest);
			//console.log("--------------------");

			totalInterest = totalInterest + payment.interest;
		}
	}

	
	return totalInterest;
};


const getAmortizationTotalInterestOLD = (yearNumber,loanAmount,annualInterestRate) => {

	let totalInterest = 0;

	annualInterestRate = annualInterestRate / 100;

	let monthlyInterestRate = annualInterestRate / 12;
	let numberOfMonths = yearNumber * 12;
	let monthlyPayment = loanAmount * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -numberOfMonths)));

	monthlyPayment = (monthlyPayment * 100) / 100.0;
	let totalPayment = monthlyPayment * numberOfMonths;
	totalPayment = (totalPayment * 100) / 100.0;

	let balance = loanAmount;
	let interest = 0;
	let principal = 0;

			let paymentList  = [];
			let yearPaymentList  = [];

	let yearMortgageSchedulePaymentsVO = null;
	let yearMortgageSchedulePayments = null;

	let yearCounter = 1;
	let yearMonthCounter = 0;

	for (let i = 0; i < yearNumber * 12; i++) {

		yearMonthCounter = yearMonthCounter + 1;
		if (yearMonthCounter > 12) {

			yearMortgageSchedulePaymentsVO  = {};
			yearMortgageSchedulePaymentsVO.year = yearCounter;
							let payments  = [];
			payments  = paymentList;
			yearMortgageSchedulePaymentsVO.payments = payments;

			yearPaymentList.push(yearMortgageSchedulePaymentsVO);

							paymentList  = [];
			yearMonthCounter = 1;
			yearCounter = yearCounter + 1;

		}

		interest = (monthlyInterestRate * balance * 100) / 100.0;
		principal = ((monthlyPayment - interest) * 100) / 100.0;
		balance = ((balance - principal) * 100) / 100.0;

		totalInterest = totalInterest = +interest;
	}

	return totalInterest;

};

export const populateCostOfDebtIndicatorValueMap = (indicator,property,loanAmount,interestRate,loanTerm,totalInterest,yearNumber,annualInterestRate,costOfDebtRatio,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_COST_OF_DEBT_RATIO", AppUtility.getPercentValue(costOfDebtRatio / 100));
	indicator.helpVariableMap.set("VAR_LOAN_AMOUNT", AppUtility.getAmountStringFromDouble(loanAmount));
	indicator.helpVariableMap.set("VAR_INTEREST_RATE", AppUtility.getPercentValue(interestRate));
	indicator.helpVariableMap.set("VAR_LOAN_TERM", loanTerm);
	indicator.helpVariableMap.set("VAR_TOTAL_INTEREST",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInterest));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getPercentValue(acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getPercentValue(rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>cost-of-debt</b> ratio for this property is green, which means you are paying less than <b>" + AppUtility.getPercentValue(acceptValue) + "</b> of the loan value in interest for the entire term of the mortgage.");
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>cost-of-debt</b> ratio for this property is red, which means you are paying more than <b>" + AppUtility.getPercentValue(acceptValue) + "</b> of the loan value in interest for the entire term of the mortgage.");
	}

	if (property.monthlyPayment == 0) {
		indicator.helpVariableMap.set("VAR_DEBT_COVERAGE_RATIO", "N/A");
		indicator.indicatorValue = 0;
		indicator.undeterminedFlag = true;
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>cost-of-debt</b> ratio is not applicable for this property since there is no mortgage for the property, which means there is no cost of debt associated with the property.");
	}
	//no return 

};

export const getBreakEvenRatioIndicator = (property,benchmarkingSetting) => {

	// Break-Even Ratio = (Debt Service + Operating Expenses) / Gross
	// Operating
	// Income

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let monthlyRent = 0;
	let grossScheduledIncome = 0;
	let vacancyRate = 0;
	let creditLoss = 0;
	let grossOperatingIncome = 0;
	let totalExpenses = 0;
	let breakEvenRatio = 0;
	let monthylMortgagePayment = 0;
	let annualDebtService = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let breakEvenRatioPercent = 0;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		// 1. Monthly Rent
		if (property != null) {
			monthlyRent = property.monthlyRent;
			vacancyRate = property.vacancyRate / 100;
		}

		// 2. Gross Schedule Income
		grossScheduledIncome = getMonthlyScheduledIncome(monthlyRent);

		// 3. Credit Loss
		creditLoss = getCreditLoss(grossScheduledIncome, vacancyRate);

		// 4. Gross Operating Income
		grossOperatingIncome = getGrossOperatingIncome(grossScheduledIncome, creditLoss);

		// 5. Total Expenses

		totalExpenses = getTotalExpenses(property);

		totalExpenses = totalExpenses * 12; //annualized 

		// 6. Monthly Mortgage Payment
		if (property != null) {
			monthylMortgagePayment = property.monthlyPayment;
		}

		// 7. Annual Debt Service (ADS)
		annualDebtService = monthylMortgagePayment * 12;

		// 8. breakEvenRatio
		if (grossOperatingIncome > 0) {
			breakEvenRatio = (parseInt(annualDebtService) + parseInt(totalExpenses)) / grossOperatingIncome;
		} else {
			breakEvenRatio = 0;
		}
		breakEvenRatioPercent = breakEvenRatio;

		breakEvenRatio = breakEvenRatio * 100;

		breakEvenRatio = AppUtility.roundToTheNearest(breakEvenRatio, 100);
		breakEvenRatioPercent = AppUtility.roundToTheNearest(breakEvenRatioPercent, 10000);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.berAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.berAccepted, breakEvenRatio, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.berRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.berRejected, breakEvenRatio, symbol);
			rejectCompareText = compareOperatorVO.nickName;
			passFlag = accepted && !rejected;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.BREAK_EVEN_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.BREAK_EVEN_RATIO_INDICATOR_ACRONYM;
	//indicator.indicatorValue =  breakEvenRatioPercent;
	indicator.indicatorValue =  breakEvenRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


 	populateBreakEvenRatioIndicatorValueMap(indicator, monthlyRent, grossScheduledIncome, totalExpenses, property == null ? 0 : property.monthlyPayment, annualDebtService, vacancyRate * 100, creditLoss, grossOperatingIncome, breakEvenRatioPercent, acceptCompareText, benchmarkingSetting.berAccepted, rejectCompareText, benchmarkingSetting.berRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateBreakEvenRatioIndicatorValueMap = (indicator,monthlyRent,grossScheduledIncome,annualExpenses,monthlyMortgage,annualMortgage,vacancyRate,creditLoss,grossOperatingIncome,breakEvenRatio,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_MONTHLY_RENT", AppUtility.getAmountStringFromDouble(monthlyRent));
	indicator.helpVariableMap.set("VAR_GROSS_ANNUAL_INCOME",AppUtility.getAmountStringFromDouble(grossScheduledIncome));
	indicator.helpVariableMap.set("VAR_ANNUAL_EXPENSES", AppUtility.getAmountStringFromDouble(annualExpenses));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(monthlyMortgage));
	indicator.helpVariableMap.set("VAR_ANNUAL_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDouble(annualMortgage));
	indicator.helpVariableMap.set("VAR_VACANCY_RATE", (AppUtility.roundToTheNearest(vacancyRate, 100)).toString()); 
	indicator.helpVariableMap.set("VAR_VACANCY_CREDIT_LOSS", AppUtility.getAmountStringFromDouble(creditLoss));
	indicator.helpVariableMap.set("VAR_GROSS_OPERATING_INCOME",AppUtility.getAmountStringFromDouble(grossOperatingIncome));
	indicator.helpVariableMap.set("VAR_BREAK_EVEN_RATIO",AppUtility.getIndicatorValue(valueType, breakEvenRatio));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>break-even ratio</b> for this property is green, which means your risk of defaulting on its debt should rental income decline is low.");
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>break-even ratio</b> for this property is red, which means your risk of defaulting on its debt if rental income declines is high.");
	}

	//no return 

};

export const getLoanToValueRatioIndicator = (property,benchmarkingSetting) => {

	// Loan-To_value Ratio = Loan Amount / Lesses of Property's Appraised
	// Value or
	// purchase price

	let smallerOfPurchaseAndApraisalLabel = "Purchase Price";

	let appraisedLabel = "";

	let smallerOfPurchaseAndApraisal = 0;
	
	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let downPayment = 0;
	let banksPropertyAppraisal = 0;
	let loanAmount = 0;
	let loanToValueRatio = 0;
	let hasMortgage = false;
	let passFlag = false;
	let accepted = false;
	let rejected = false;

	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		// 1. Purchase Price
		if (property != null) {
			purchasePrice = property.price;
		}

		// 2. Monthly Mortgage Payment and property Appraisal
		if (property != null) {
			//downPayment = (property.price * property.downPayment) / 100;
			downPayment = property.downPayment;

			if (property.investmentType == "Flipping Property")
        	{
				banksPropertyAppraisal = property.afterRepairValue;
				appraisedLabel = "After Repair Value (ARV)";
			}else
			{
				banksPropertyAppraisal = property.marketValue;
				appraisedLabel = "Market Value";
			}
			
			hasMortgage = true;
		}
		

		// 3. Loan Amount
		loanAmount = purchasePrice - downPayment;

		smallerOfPurchaseAndApraisal = 0;
		if (banksPropertyAppraisal < purchasePrice) {
			smallerOfPurchaseAndApraisal = banksPropertyAppraisal;
			smallerOfPurchaseAndApraisalLabel = appraisedLabel;
		} else {
			smallerOfPurchaseAndApraisal = purchasePrice;
			smallerOfPurchaseAndApraisalLabel = "Purchase Price";
		}

		if (smallerOfPurchaseAndApraisal > 0) {
			loanToValueRatio = loanAmount / smallerOfPurchaseAndApraisal;
		}

		let loanToValueRatioPercent = loanToValueRatio * 100;

		loanToValueRatio = loanToValueRatio * 100;
		loanToValueRatio = AppUtility.roundToTheNearest(loanToValueRatio, 100);

		loanToValueRatioPercent = AppUtility.roundToTheNearest(loanToValueRatioPercent, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.lvrAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.lvrAccepted, loanToValueRatioPercent,symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.lvrRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.lvrRejected, loanToValueRatioPercent,symbol);
			rejectCompareText = compareOperatorVO.nickName;
			passFlag = accepted && !rejected;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.LOAN_TO_VALUE_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.LOAN_TO_VALUE_RATIO_INDICATOR_ACRONYM;
	indicator.indicatorValue =  loanToValueRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateLoanToValueRatioIndicatorValueMap(indicator, hasMortgage, purchasePrice, downPayment, banksPropertyAppraisal, loanAmount, loanToValueRatio, acceptCompareText, benchmarkingSetting.lvrAccepted, rejectCompareText, benchmarkingSetting.lvrRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap.set("VAR_BANKS_APPRAISAL_LABEL", smallerOfPurchaseAndApraisalLabel);
	indicator.helpVariableMap.set("VAR_APPRAISED_LABEL", appraisedLabel);
	indicator.helpVariableMap.set("VAR_SMALLER_PURCHASE_BANKS_APPRAISAL", AppUtility.getAmountStringFromDouble(smallerOfPurchaseAndApraisal));

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateLoanToValueRatioIndicatorValueMap = (indicator,hasMortgage,purchasePrice,downPayment,bankAppraisal,loanAmount,loanToValueRatio,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {

	
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE", AppUtility.getAmountStringFromDouble(purchasePrice));
	indicator.helpVariableMap.set("VAR_DOWN_PAYMENT", AppUtility.getAmountStringFromDouble(downPayment));
	indicator.helpVariableMap.set("VAR_BANKS_APPRAISAL", AppUtility.getAmountStringFromDouble(bankAppraisal));
	indicator.helpVariableMap.set("VAR_LOAN_AMOUNT", AppUtility.getAmountStringFromDouble(loanAmount));
	indicator.helpVariableMap.set("VAR_LOAN_TO_VALUE_RATIO", AppUtility.getIndicatorValue(valueType, loanToValueRatio));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (bankAppraisal < purchasePrice) {
		indicator.helpVariableMap.set("VAR_LOWER_OF_APPRAISAL_OR_PURCHASE",AppUtility.getAmountStringFromDouble(bankAppraisal));
		indicator.helpVariableMap.set("VAR_NOTE","Using Bank's Appraisal Value since it is lower than the Purchase Price.");

	} else {
		indicator.helpVariableMap.set("VAR_LOWER_OF_APPRAISAL_OR_PURCHASE",AppUtility.getAmountStringFromDouble(purchasePrice));
		indicator.helpVariableMap.set("VAR_NOTE","Purchase Price values since it is lower than the Bank's Appraisal Value.");
	}

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>loan-to-value</b> ratio (LTV) for this property is green, which means the down payment you are putting on the property is good relative to its market value. \n\nThis also indicates that banks may be more willing to approve your mortgage application. ");
	
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>loan-to-value ratio</b> (LTV) for this property is red, indicating that the down payment you are putting on the property is too low relative to its market value. \n\nThis also means that there are chances that the bank may not approve your mortgage, require you to purchase mortgage insurance, or charge a higher interest rate.");
	}

	if (hasMortgage == false) {
		indicator.helpVariableMap.set("VAR_LOAN_TO_VALUE_RATIO", "N/A");
		indicator.indicatorValue = 0;
		indicator.undeterminedFlag = true;
		indicator.meetsBenchmark = false;
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>loan-to-value</b> ratio (LTV) is not applicable for this property since there is no mortgage for the property, which means there is no loan associated with the property.");
	}

	//no return 

};

export const get70PercentRuleIndicator = (property,benchmarkingSetting) => {

	// https://www.rocketmortgage.com/learn/what-is-70-rule-in-house-flipping
	// Formula: 70% Rule = (AVR x .70) - Repairs

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let avr = 0;
	let repairs = 0;
	let budget = 0;
	let gross70PercentRule = 0;
	let net70PercentRule = 0;
	let purchasePricePercent = 0;

	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null && property.afterRepairValue > 0) {
		// 1. AVR
		avr = property.afterRepairValue;

		repairs = property.rehabCosts;

		budget = property.budget;

		// 2. Gross 70% rule
		gross70PercentRule = avr * .70;

		// 3. Net 70% rule
		net70PercentRule = gross70PercentRule - repairs;

		//? = purchase price + Rehab Costs
		//----------------------------
		//	 After Repair Value

		purchasePricePercent = (parseInt(property.price) + parseInt(repairs)) / parseInt(avr);
		purchasePricePercent = purchasePricePercent * 100;
		purchasePricePercent = AppUtility.roundToTheNearest(purchasePricePercent, 100);

		console.log("AAAAA - property.price:"+property.price);
		console.log("AAAAA - repairs:"+repairs);
		console.log("AAAAA - avr:"+avr);
		console.log("AAAAA - purchasePricePercent:"+purchasePricePercent);

		if (comparisonSymbolMap != null) {

			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.rule70AcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.rule70Accepted, purchasePricePercent, symbol);
			//accepted = compareBenchmarkedValue(net70PercentRule,property.price, symbol);

			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.rule70RejectedCompareId));
			symbol = compareOperatorVO.symbol;
			//rejected = compareBenchmarkedValue(benchmarkingSetting.rule70Rejected, net70PercentRule, symbol);
			rejected = compareBenchmarkedValue(net70PercentRule,property.price, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.RULE_70_INDICATOR_NAME;
	indicator.indicatorAcronym =  IndicatorVO.RULE_70_INDICATOR_ACRONYM;
	indicator.indicatorValue = purchasePricePercent;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();

	populate70PercentRuleIndicatorValueMap(indicator, property, avr, repairs, budget, gross70PercentRule,net70PercentRule,purchasePricePercent, acceptCompareText, benchmarkingSetting.rule70Accepted, rejectCompareText,benchmarkingSetting.rule70Rejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populate70PercentRuleIndicatorValueMap = (indicator,property,avr,repairs,budget,gross70PercentRule,net70PercentRule,purchasePricePercent,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_AVR", AppUtility.getAmountStringFromDoubleNoFractionalPart(avr));
	indicator.helpVariableMap.set("VAR_REPAIRS",AppUtility.getAmountStringFromDoubleNoFractionalPart(repairs));
	indicator.helpVariableMap.set("VAR_BUDGET", AppUtility.getAmountStringFromDoubleNoFractionalPart(budget));
	indicator.helpVariableMap.set("VAR_GROSS_70_RULE",AppUtility.getAmountStringFromDoubleNoFractionalPart(gross70PercentRule));
	indicator.helpVariableMap.set("VAR_NET_70_RULE",AppUtility.getAmountStringFromDoubleNoFractionalPart(net70PercentRule));
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.price));

	indicator.helpVariableMap.set("VAR_70_RULE", AppUtility.getIndicatorValue(valueType, purchasePricePercent));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {

		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>Rule of 70</b> for this property is green, which means you are only paying <b>"+AppUtility.getIndicatorValue(valueType, purchasePricePercent)+"</b> of the after-repair value minus repairs, which is below the maximum recommended of <b>70%</b>. <br><br>This is good since you are paying <b>"+AppUtility.getAmountStringFromDoubleNoFractionalPart(property.price)+"</b> for the property, which is less than the maximum purchase price of <b>"+AppUtility.getAmountStringFromDoubleNoFractionalPart(net70PercentRule)+"</b> suggested by the Rule of 70 indicator.<br><br>This means you should be able to make a profit on this investment.");
	
	} else {
	
		//indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>Rule of 70</b> for this property is red, which means you are paying more than <b>70%</b> of the after-repair value minus repairs. \n\nYou are paying <b>"+AppUtility.getIndicatorValue(valueType, purchasePricePercent)+"</b> of the after-repair value minus repairs. In this case, you are paying <b>"+AppUtility.getAmountStringFromDoubleNoFractionalPart(property.price)+"</b> of the after-repair value minus repairs for the property, which is higher than the recommended threshold. Therefore, this may not be a profitable investment.");
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>Rule of 70</b> for this property is red, which means you are paying more than the <b>70%</b> of the after-repair value minus repairs suggested by the Rule of 70.<br><br>You are paying <b>"+AppUtility.getIndicatorValue(valueType, purchasePricePercent)+"</b> of the after-repair value minus repairs for the property, which means this might not be a profitable investment since the maximum allowed is <b>70%</b>.     <br><br>This is bad since you are paying <b>"+AppUtility.getAmountStringFromDoubleNoFractionalPart(property.price)+"</b> for the property, which is more than the maximum purchase price of <b>"+AppUtility.getAmountStringFromDoubleNoFractionalPart(net70PercentRule)+"</b> suggested by the Rule of 70 indicator.");
	
	}

//no return 

};

export const getROIIndicator = (property,benchmarkingSetting) => {

	// Formula: Profits
	// ROI = --------------------
	// (Total Costs)

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let roi = 0;

	let indicator = null;

	let purchasePrice = 0;
	let repairs = 0;

	let avr = 0;
	let holdingCosts = 0;

	let sellingCosts = 0;

	let monthlyPayment = 0;

	let totalInvestment = 0;
	let profits = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	let downPayment = 0;
	let loanPayment = 0;

	let totalMortgagePayments = 0;

	let initialInvestment = 0;

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;
	
		repairs = property.rehabCosts;

		avr = property.afterRepairValue;
		
		holdingCosts = property.holdingCosts; //I could use the break down values also, but this is the same

		monthlyPayment = property.monthlyPayment;

		totalMortgagePayments = monthlyPayment  * property.holdingMonths;

		holdingCosts = holdingCosts * property.holdingMonths; // multiply
																	// by
																	// holding
																	// months

		/*															
		sellingCosts = realEstateCommissions + titleInsurance + recordingFees + closingCostsSelling + otherSellingCosts;
		*/

		sellingCosts = property.sellingCosts;

		let closingCosts = property.closingCosts;

		downPayment = property.downPayment;

		loanPayment = property.price - property.downPayment;

		// 1. Calculate total investment
		if (property.financingType == "Cash") {
			
			initialInvestment = parseInt(purchasePrice) + parseInt(closingCosts);

			totalInvestment = initialInvestment + parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);

		} else if (property.financingType == "Loan") {
			
			initialInvestment = parseInt(downPayment) + parseInt(closingCosts);	

			totalInvestment = initialInvestment + parseInt(totalMortgagePayments) + parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);
		}

		// 2. Calculate profits
		profits = avr - totalInvestment;

		// loan repayment
		if (property.financingType == "Loan") {
			profits = profits - loanPayment;
		}

		// 3. Calcualate ROI
		// Profits
		// ROI = -------------------- x 100
		// (Total Investment)

		roi = (profits / totalInvestment) * 100;
		
		//roi = AppUtility.roundToTheNearest(roi, 100);
		roi = Math.round(roi);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.roiAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.roiAccepted, roi, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.roiRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.roiRejected, roi, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.ROI_INDICATOR_NAME;
	indicator.indicatorAcronym =  IndicatorVO.ROI_INDICATOR_ACRONYM;
	indicator.indicatorValue =  roi;
	indicator.property = property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();

	populateROIIndicatorValueMap(indicator, property, purchasePrice, repairs, avr, totalMortgagePayments, holdingCosts, sellingCosts, loanPayment, initialInvestment,totalInvestment, profits, roi, acceptCompareText, benchmarkingSetting.roiAccepted, rejectCompareText, benchmarkingSetting.roiRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateROIIndicatorValueMap = (indicator,property,purchasePrice,repairs,avr, totalMortgagePayments,holdingCosts,sellingCosts,loanPayment,initialInvestment,totalInvestment,profits,roi,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	//let totalHoldingCosts = monthlyHoldingCosts * property.holdingMonths;

	let totalHoldingCosts = holdingCosts;
	let monthlyHoldingCosts = holdingCosts/12;

	indicator.helpVariableMap.set("VAR_ROI", AppUtility.getIndicatorValue(valueType, roi / 100));
	indicator.helpVariableMap.set("VAR_PROFITS",AppUtility.getAmountStringFromDoubleNoFractionalPart(profits));
	indicator.helpVariableMap.set("VAR_INITAL_INVESTMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(initialInvestment));
	indicator.helpVariableMap.set("VAR_TOTAL_INVESTMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInvestment));
	indicator.helpVariableMap.set("VAR_SELLING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(sellingCosts));
	indicator.helpVariableMap.set("VAR_HOLDING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalHoldingCosts));
	indicator.helpVariableMap.set("VAR_MONTHLY_HOLDING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyHoldingCosts));
	indicator.helpVariableMap.set("VAR_AVR", AppUtility.getAmountStringFromDoubleNoFractionalPart(avr));
	indicator.helpVariableMap.set("VAR_REPAIRS",AppUtility.getAmountStringFromDoubleNoFractionalPart(repairs));
	indicator.helpVariableMap.set("VAR_TOTAL_INVESTMENTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInvestment));
	indicator.helpVariableMap.set("VAR_TOTAL_SELLING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(sellingCosts));
	indicator.helpVariableMap.set("VAR_LOAN_AMOUNT",AppUtility.getAmountStringFromDoubleNoFractionalPart(loanPayment));
	
	indicator.helpVariableMap.set("VAR_PURCHASE_CLOSING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.closingCosts));
	indicator.helpVariableMap.set("VAR_LOAN_REPAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.monthlyPayment));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.monthlyPayment));

	indicator.helpVariableMap.set("VAR_TOTAL_MORTGAGE_PAYMENTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalMortgagePayments));


	indicator.helpVariableMap.set("VAR_DOWN_PAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.downPayment));
	indicator.helpVariableMap.set("VAR_SALE_CLOSING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.closingCostsSelling));
	indicator.helpVariableMap.set("VAR_HOLDING_MONTHS", (property.holdingMonths).toString());

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		
		buffer = AppUtility.clearBuffer(buffer);
		buffer.append("The ROI for this property meets your required return on investment of <b>");
		buffer.append(acceptValue);
		buffer.append("%.</b>");

		indicator.helpVariableMap.set("VAR_RESULT_TEXT",buffer.toString());
	} else
	{
		buffer = AppUtility.clearBuffer(buffer);
		buffer.append("The ROI for this property doesn't meet your required return on investment of <b>");
		buffer.append(rejectValue);
		buffer.append("%.</b>");

		indicator.helpVariableMap.set("VAR_RESULT_TEXT",buffer.toString());
	}
};

export const getKeyRiskIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let marketValue = 0;
	let marketValuePercent = 0;
	let keyRiskIndicator = 0;
	let repairs = 0;
	let avr = 0;

	// repairs
	/* OLD
	let electricalCosts = 0;
	let carpentryCosts = 0;
	let plumbingCosts = 0;
	let hvacCosts = 0;
	let paintingCosts = 0;
	let flooringCosts = 0;
	let roofingCosts = 0;
	let landscapingCosts = 0;
	let otherRepairCosts = 0;
	*/

	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;
		// repairs
		/* OLD
		electricalCosts = property.electricalCosts;
		carpentryCosts = property.carpentryCosts;
		plumbingCosts = property.plumbingCosts;
		hvacCosts = property.hvacCosts;
		paintingCosts = property.paintingCosts;
		flooringCosts = property.flooringCosts;
		roofingCosts = property.roofingCosts;
		landscapingCosts = property.landscapingCosts;
		otherRepairCosts = property.otherRepairCosts;

		repairs = electricalCosts + carpentryCosts + plumbingCosts + hvacCosts + paintingCosts + flooringCosts + roofingCosts + landscapingCosts + otherRepairCosts;
		*/

		repairs = property.rehabCosts;

		avr = property.afterRepairValue;

		marketValue = avr - repairs;

		marketValuePercent = ((marketValue - purchasePrice) / marketValue) * 100;

		keyRiskIndicator = marketValuePercent / property.holdingMonths;

		keyRiskIndicator = AppUtility.roundToTheNearest(keyRiskIndicator, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.keyRiskIndicatorAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.keyRiskIndicatorAccepted, keyRiskIndicator,symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.keyRiskIndicatorRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.keyRiskIndicatorRejected, keyRiskIndicator,symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.KRI_INDICATOR_NAME;
	indicator.indicatorAcronym =  IndicatorVO.KRI_INDICATOR_ACRONYM;
	indicator.indicatorValue = keyRiskIndicator;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateKeyRiskIndicatorValueMap(indicator, property, purchasePrice, marketValue, marketValuePercent, keyRiskIndicator, repairs, avr, acceptCompareText, benchmarkingSetting.keyRiskIndicatorAccepted, rejectCompareText, benchmarkingSetting.keyRiskIndicatorRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateKeyRiskIndicatorValueMap = (indicator,property,purchasePrice,marketValue,marketValuePercent,keyRiskIndicator,repairs,avr,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_KEY_RISK_INDICATOR",AppUtility.getIndicatorValue(valueType, keyRiskIndicator / 100));
	indicator.helpVariableMap.set("VAR_MARKET_VALUE",AppUtility.getAmountStringFromDoubleNoFractionalPart(marketValue));
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(purchasePrice));
	indicator.helpVariableMap.set("VAR_REPAIRS",AppUtility.getAmountStringFromDoubleNoFractionalPart(repairs));
	indicator.helpVariableMap.set("VAR_AVR", AppUtility.getAmountStringFromDoubleNoFractionalPart(avr));
	indicator.helpVariableMap.set("VAR_HOLDING_MONTHS", (property.holdingMonths).toString());

	let marketValuePurchasePrice = new StringBuffer();
	marketValuePurchasePrice.append(AppUtility.getAmountStringFromDoubleNoFractionalPart(marketValue));
	marketValuePurchasePrice.append(" - ");
	marketValuePurchasePrice.append(AppUtility.getAmountStringFromDoubleNoFractionalPart(purchasePrice));

	indicator.helpVariableMap.set("VAR_MARKETVALUE_PURCHASEPRICE", marketValuePurchasePrice.toString());

	//marketValuePercent = AppUtility.roundToTheNearest(marketValuePercent, 100)
	
	marketValuePercent = AppUtility.roundToTheNearest(marketValuePercent, 100)
	marketValuePercent = AppUtility.getPercentValue(marketValuePercent);
	

	indicator.helpVariableMap.set("VAR_MARKET_VALUE_PERCENT",marketValuePercent);

	//indicator.helpVariableMap.set("VAR_MARKET_VALUE_PERCENT",
	//		AppUtility.getIndicatorValue(valueType, marketValuePercent / 100));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {

		let acceptText = "The <b>key risk indicator</b> for this property is green, which means that you have a low risk level in regards to a possible property price drop in the area. This suggests that you should be able to absorb a price drop of up to <b>VAR_MAX_ALLOW_DROP</b> and still make a profit on the investment.";

		acceptText = acceptText.replaceAll("VAR_MAX_ALLOW_DROP",indicator.indicatorValue+"%");
		acceptText = acceptText.replaceAll("VAR_ACCEPT", AppUtility.getIndicatorValue(valueType, acceptValue));

		indicator.helpVariableMap.set("VAR_RESULT_TEXT", acceptText);
	} else {

		let rejectText = "The <b>key risk indicator</b> for this property is red, which means you can not absorb a price drop of <b>"+AppUtility.getIndicatorValue(valueType, acceptValue)+"</b>, which means your risk of losing money in this investment is high should property prices drop <b>"+AppUtility.getIndicatorValue(valueType, acceptValue)+"</b> or more in the area. \n\nThe maximum property price drop you can absorb is <b>VAR_INDICATOR</b>.\n\nIf you want to minimize your risk exposure you need to make sure you can absorb a price drop of <b>"+AppUtility.getIndicatorValue(valueType, acceptValue)+"</b> or more while you hold the property.";

		rejectText = rejectText.replaceAll("VAR_ACCEPT",AppUtility.getIndicatorValue(valueType, keyRiskIndicator / 100));
		rejectText = rejectText.replaceAll("VAR_MAX_ALLOW_DROP",indicator.indicatorValue+"%");
		rejectText = rejectText.replaceAll("VAR_INDICATOR",indicator.indicatorValue+"%");

		indicator.helpVariableMap.set("VAR_RESULT_TEXT", rejectText);
	}

	//no return 

};

export const getBudgetIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let repairs = 0;
	let avr = 0;
	let holdingCosts = 0;
	let sellingCosts = 0;
	let totalInvestment = 0;
	let profits = 0;
	let budgetPercent = 0;
	let surplusDeficit = 0;
	let budget = 0;



	// holding costs
	/*
	let homeownersInsurance = 0;
	let propertyTaxes = 0;
	let monthlyElectricity = 0;
	let monthlyGas = 0;
	let monthlyWater = 0;
	let monthlyLawn = 0;
	let hoa = 0;
	let otherMonthlyExpenses = 0;
	*/

	let monthlyPayment = 0;

	// selling costs
	/*
	let realEstateCommissions = 0;
	let titleInsurance = 0;
	let recordingFees = 0;
	let closingCostsSelling = 0;
	let otherSellingCosts = 0;
	*/

	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	let monthlyHoldingCosts = 0;

	let downPayment = 0;
	let loanPayment = 0;

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;
		// repairs
		/*
		electricalCosts = property.electricalCosts;
		carpentryCosts = property.carpentryCosts;
		plumbingCosts = property.plumbingCosts;
		hvacCosts = property.hvacCosts;
		paintingCosts = property.paintingCosts;
		flooringCosts = property.flooringCosts;
		roofingCosts = property.roofingCosts;
		landscapingCosts = property.landscapingCosts;
		otherRepairCosts = property.otherRepairCosts;

		repairs = electricalCosts + carpentryCosts + plumbingCosts + hvacCosts + paintingCosts + flooringCosts + roofingCosts + landscapingCosts + otherRepairCosts;
		*/

		repairs = property.rehabCosts;

		avr = property.afterRepairValue;

		budget = property.budget;
		
		// holding costs
		/*
		homeownersInsurance = property.homeownersInsurance;
		propertyTaxes = property.propertyTaxes;
		monthlyElectricity = property.monthlyElectricity;
		monthlyGas = property.monthlyGas;
		monthlyWater = property.monthlyWater;
		monthlyLawn = property.monthlyLawn;
		hoa = property.hoa;
		otherMonthlyExpenses = property.otherMonthlyExpenses;

		holdingCosts = homeownersInsurance + propertyTaxes + monthlyElectricity + monthlyGas + monthlyWater + monthlyLawn + hoa + otherMonthlyExpenses;
		*/

		monthlyHoldingCosts = property.holdingCosts;

		holdingCosts = property.holdingCosts; //I could use the break down values also, but this is the same

		monthlyPayment = property.monthlyPayment;

		// selling costs
		/*
		realEstateCommissions = property.realEstateCommissions;
		titleInsurance = property.titleInsurance;
		recordingFees = property.recordingFees;
		closingCostsSelling = property.closingCostsSelling;
		otherSellingCosts = property.otherSellingCosts;
		*/

		/*
		if (property.financingType == "Loan")

		{

			holdingCosts = holdingCosts + property.monthlyPayment;

		}
		let monthlyHoldingCosts = holdingCosts;

		if (property.holdingCostsFrequency == "Annually")

		{

			monthlyHoldingCosts = holdingCosts / 12;

		} else if (property.holdingCostsFrequency == "Quarterly")

		{

			monthlyHoldingCosts = holdingCosts / 4;

		}

		*/

		monthlyPayment = monthlyPayment  * property.holdingMonths;

		holdingCosts = holdingCosts * property.holdingMonths; // multiply
																	// by
																	// holding
																	// months

		/*															
		sellingCosts = realEstateCommissions + titleInsurance + recordingFees + closingCostsSelling + otherSellingCosts;
		*/

		sellingCosts = property.sellingCosts;

		let closingCosts = property.closingCosts;

		downPayment = property.downPayment;

		loanPayment = property.price - property.downPayment;

		// 1. Calculate total investment
		if (property.financingType == "Cash") {
			totalInvestment = parseInt(purchasePrice)  + parseInt(closingCosts) + parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);

		} else if (property.financingType == "Loan") {
			
			totalInvestment = parseInt(downPayment) + parseInt(monthlyPayment) + parseInt(closingCosts) + parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);

		}

		// 2. Calculate profits
		surplusDeficit = budget - totalInvestment;

		budgetPercent = 100- ((surplusDeficit / budget) * 100);

		if (budgetPercent > 100)
		{
			passFlag = false;
		}else
		{
			passFlag = true;
		}	

		budgetPercent = AppUtility.roundToTheNearest(budgetPercent, 100);

		indicator  = {};
		indicator.meetsBenchmark = passFlag;
		indicator.indicatorName =  IndicatorVO.BUDGET_INDICATOR_NAME;
		indicator.indicatorAcronym = IndicatorVO.BUDGET_INDICATOR_ACRONYM;
		indicator.indicatorValue =  budgetPercent;
		indicator.property =  property;
		indicator.valueType =  IndicatorVO.PERCENT;
		indicator.helpVariableMap = new Map();


		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.budgetAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.budgetAccepted, budgetPercent, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.budgetRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.budgetRejected, budgetPercent, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}


		populateBudgetIndicatorValueMap(indicator, property, purchasePrice, downPayment, monthlyPayment,repairs, budget, monthlyHoldingCosts, holdingCosts, sellingCosts, totalInvestment, surplusDeficit, budgetPercent, IndicatorVO.PERCENT);

		populatePropertyValuesIntoMap(indicator, property);

		indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON
	}
	return indicator;

};

export const populateBudgetIndicatorValueMap = (indicator,property,purchasePrice,downPayment,monthlyPayment,repairs,budget,monthlyHoldingCosts,holdingCosts,sellingCosts,totalInvestment,surplusDeficit,budgetPercent,valueType) => {


	indicator.helpVariableMap.set("VAR_BUDGET_PERCENT",AppUtility.getIndicatorValue(valueType, budgetPercent / 100));
	indicator.helpVariableMap.set("VAR_TOTAL_INVESTMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInvestment));
	indicator.helpVariableMap.set("VAR_SELLING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(sellingCosts));
	indicator.helpVariableMap.set("VAR_HOLDING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(holdingCosts));
	indicator.helpVariableMap.set("VAR_MONTHLY_HOLDING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyHoldingCosts));
	indicator.helpVariableMap.set("VAR_REPAIRS",AppUtility.getAmountStringFromDoubleNoFractionalPart(repairs));
	indicator.helpVariableMap.set("VAR_TOTAL_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInvestment));
	indicator.helpVariableMap.set("VAR_TOTAL_SELLING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(sellingCosts));

	indicator.helpVariableMap.set("VAR_TOTAL_MORTGAGE_PAYMENTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyPayment));

	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.monthlyPayment));


	indicator.helpVariableMap.set("VAR_PURCHASE_CLOSING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.closingCosts));
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.price));
	indicator.helpVariableMap.set("VAR_LOAN_REPAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.monthlyPayment));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.monthlyPayment));
	indicator.helpVariableMap.set("VAR_DOWN_PAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(downPayment));
	indicator.helpVariableMap.set("VAR_OTHER_MONTHLY_EXPENSES",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.otherMonthlyExpenses));
	indicator.helpVariableMap.set("VAR_MONTHLY_LAWN",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.monthlyLawn));
	indicator.helpVariableMap.set("VAR_SALE_CLOSING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.closingCostsSelling));
	indicator.helpVariableMap.set("VAR_RECORDING_FEES",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.recordingFees));
	indicator.helpVariableMap.set("VAR_HOLDING_MONTHS", (property.holdingMonths).toString());

	indicator.helpVariableMap.set("VAR_SURPLUS_DEFICIT",AppUtility.getAmountStringFromDoubleNoFractionalPart(surplusDeficit));

	let isSuplus = 'N';

	if (surplusDeficit >0)
	{
		isSuplus = 'Y';
	}else
	{
		isSuplus = 'N';
	}
	
	indicator.helpVariableMap.set("VAR_IS_SURPLUS",isSuplus);

	indicator.helpVariableMap.set("VAR_BUDGET", AppUtility.getAmountStringFromDoubleNoFractionalPart(budget));

	let acceptCompareText = "Less Than Or Equal 100%";
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", acceptCompareText);

	let rejectCompareText = "Greater Than 100%";
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", rejectCompareText);

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","This indicator is green because you are within budget. After paying all out-of-pocket expenses needed to purchase the property, you still have <b>" + AppUtility.getAmountStringFromDoubleNoFractionalPart(surplusDeficit) + "</b> remaining.\n\nYou are using <b>"+AppUtility.getIndicatorValue(valueType, budgetPercent)+"</b> of your available budget.");

		indicator.helpVariableMap.set("VAR_SURPLUS_DEFICIT_LABEL", "Surplus");

	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","This indicator is red because you are over budget, which means your total expenses exceed your budget, you are <b>" + AppUtility.getAmountStringFromDoubleNoFractionalPart(surplusDeficit) + "</b> short.");

		indicator.helpVariableMap.set("VAR_SURPLUS_DEFICIT_LABEL", "Deficit");
	}

	//no return 

};

export const getBudgetHomeIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let totalInvestment = 0;
	let budgetPercent = 0;
	let surplusDeficit = 0;
	let budget = 0;
	let closingCosts = 0;
	let monthlyPayment = 0;

	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	let monthlyHoldingCosts = 0;

	let downPayment = 0;

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;

		budget = property.budget;

		closingCosts = property.closingCosts;

		downPayment = property.downPayment;

		// 1. Calculate total investment
		if (property.financingType == "Cash") {
			totalInvestment = parseInt(purchasePrice)  + parseInt(closingCosts);

		} else if (property.financingType == "Loan") {
			
			totalInvestment = parseInt(downPayment) + parseInt(closingCosts);

		}

		// 2. Calculate profits
		surplusDeficit = budget - totalInvestment;

		budgetPercent = 100- ((surplusDeficit / budget) * 100);

		if (budgetPercent > 100)
		{
			passFlag = false;
		}else
		{
			passFlag = true;
		}	

		budgetPercent = AppUtility.roundToTheNearest(budgetPercent, 100);

		indicator  = {};
		indicator.meetsBenchmark = passFlag;
		indicator.indicatorName =  IndicatorVO.BUDGET_INDICATOR_NAME;
		indicator.indicatorAcronym = IndicatorVO.BUDGET_INDICATOR_ACRONYM;
		indicator.indicatorValue =  budgetPercent;
		indicator.property =  property;
		indicator.valueType =  IndicatorVO.PERCENT;
		indicator.helpVariableMap = new Map();


		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.budgetAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.budgetAccepted, budgetPercent, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.budgetRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.budgetRejected, budgetPercent, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}


		populateBudgetHomeIndicatorValueMap(indicator, property, purchasePrice, downPayment, budget, closingCosts,totalInvestment, surplusDeficit, budgetPercent, IndicatorVO.PERCENT);

		populatePropertyValuesIntoMap(indicator, property);

		indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON
	}
	return indicator;

};

export const populateBudgetHomeIndicatorValueMap = (indicator,property,purchasePrice,downPayment,budget,closingCosts,totalInvestment,surplusDeficit,budgetPercent,valueType) => {


	indicator.helpVariableMap.set("VAR_BUDGET_PERCENT",AppUtility.getIndicatorValue(valueType, budgetPercent / 100));
	indicator.helpVariableMap.set("VAR_TOTAL_INVESTMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInvestment));
	indicator.helpVariableMap.set("VAR_PURCHASE_CLOSING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.closingCosts));
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.price));
	indicator.helpVariableMap.set("VAR_DOWN_PAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(downPayment));
	
	indicator.helpVariableMap.set("VAR_SURPLUS_DEFICIT",AppUtility.getAmountStringFromDoubleNoFractionalPart(surplusDeficit));

	let isSuplus = 'N';

	if (surplusDeficit >0)
	{
		isSuplus = 'Y';
	}else
	{
		isSuplus = 'N';
	}
	
	indicator.helpVariableMap.set("VAR_IS_SURPLUS",isSuplus);

	indicator.helpVariableMap.set("VAR_BUDGET", AppUtility.getAmountStringFromDoubleNoFractionalPart(budget));

	let acceptCompareText = "Less Than Or Equal 100%";
	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", acceptCompareText);

	let rejectCompareText = "Greater Than 100%";
	indicator.helpVariableMap.set("VAR_REJECT_RANGE", rejectCompareText);

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","This indicator is green because you are on budget, which means that after paying all out of pocket expenses needed to purchase the property you still have <b>" + AppUtility.getAmountStringFromDoubleNoFractionalPart(surplusDeficit) + "</b> left.\n\nYou are using <b>"+AppUtility.getIndicatorValue(valueType, budgetPercent)+"</b> of your available budget.");

		indicator.helpVariableMap.set("VAR_SURPLUS_DEFICIT_LABEL", "Surplus");

	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","This indicator is red because you are over budget, which means total out of pocket expenses needed to purchase the property exceed your budget, you are <b>" + AppUtility.getAmountStringFromDoubleNoFractionalPart(surplusDeficit) + "</b> short.");

		indicator.helpVariableMap.set("VAR_SURPLUS_DEFICIT_LABEL", "Deficit");
	}

	//no return 

};

export const getNetProfitIndicator = (property,benchmarkingSetting) => {

	// Formula: Profits
	// ROI = --------------------
	// (Total Costs)

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let repairs = 0;
	
	let avr = 0;
	let holdingCosts = 0;

	let sellingCosts = 0;

	let monthlyPayment = 0;

	let totalInvestment = 0;
	let profits = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	let downPayment = 0;
	let loanAmount = 0;
	let loanRepaymentBalance = 0;
	let mortgageBalanceSchedule = {};

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;

		repairs = property.rehabCosts;

		avr = property.afterRepairValue;
		
		holdingCosts = property.holdingCosts; //I could use the break down values also, but this is the same

		monthlyPayment = property.monthlyPayment;
	
		monthlyPayment = monthlyPayment  * property.holdingMonths;

		holdingCosts = holdingCosts * property.holdingMonths; // multiply
																	// by
																	// holding
																	// months

		
		sellingCosts = property.sellingCosts;

		let closingCosts = property.closingCosts;

		downPayment = property.downPayment;

		loanAmount = property.price - property.downPayment;

		mortgageBalanceSchedule = getMortgageBalanceOnSpecificMonth(property.term,property.holdingMonths,loanAmount,property.interestRate);

		loanRepaymentBalance = mortgageBalanceSchedule.mortgageBalance;

		//console.log("mortgageBalance:");
		//console.log(JSON.stringify(mortgageBalance));

		// 1. Calculate total investment
		if (property.financingType == "Cash") {
			totalInvestment = parseInt(purchasePrice) + parseInt(closingCosts)+ parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);

		} else if (property.financingType == "Loan") {
			
			totalInvestment = parseInt(downPayment) + parseInt(monthlyPayment) + parseInt(closingCosts) + parseInt(repairs) + parseInt(holdingCosts) + parseInt(sellingCosts);

		}
		// 2. Calculate profits
		profits = avr - totalInvestment;

		// loan repayment
		if (property.financingType == "Loan") {
			//profits = profits - loanAmount;
			profits = profits - loanRepaymentBalance;
		}

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.netProfitAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.netProfitAccepted, profits, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.netProfitRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.netProfitRejected, profits, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.NET_PROFIT_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.NET_PROFIT_INDICATOR_ACRONYM;
	indicator.indicatorValue =  parseInt(profits);
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DOLLAR_AMOUNT;
	indicator.helpVariableMap = new Map();


	populateNetProfitIndicatorValueMap(indicator, property, purchasePrice, repairs, avr, monthlyPayment, holdingCosts, sellingCosts, loanAmount,loanRepaymentBalance,mortgageBalanceSchedule,totalInvestment, profits, acceptCompareText, benchmarkingSetting.netProfitAccepted, rejectCompareText, benchmarkingSetting.netProfitRejected, IndicatorVO.DOLLAR_AMOUNT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateNetProfitIndicatorValueMap = (indicator,property,purchasePrice,repairs,avr, monthlyPayment, holdingCosts,sellingCosts,loanPayment,loanRepaymentBalance,mortgageBalanceSchedule,totalInvestment,profits,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {



	let monthlyHoldingCosts = holdingCosts/12;
	let totalHoldingCosts = holdingCosts;

	indicator.helpVariableMap.set("VAR_PROFITS",AppUtility.getAmountStringFromDoubleNoFractionalPart(parseInt(profits)));
	indicator.helpVariableMap.set("VAR_TOTAL_INVESTMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInvestment));
	indicator.helpVariableMap.set("VAR_SELLING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(parseInt(sellingCosts)));
	indicator.helpVariableMap.set("VAR_HOLDING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalHoldingCosts));
	indicator.helpVariableMap.set("VAR_MONTHLY_HOLDING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyHoldingCosts));
	indicator.helpVariableMap.set("VAR_AVR", AppUtility.getAmountStringFromDoubleNoFractionalPart(avr));
	indicator.helpVariableMap.set("VAR_REPAIRS",AppUtility.getAmountStringFromDoubleNoFractionalPart(repairs));
	indicator.helpVariableMap.set("VAR_TOTAL_INVESTMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalInvestment));
	indicator.helpVariableMap.set("VAR_TOTAL_SELLING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(sellingCosts));

	indicator.helpVariableMap.set("VAR_LOAN_AMOUNT",AppUtility.getAmountStringFromDoubleNoFractionalPart(loanPayment));

	indicator.helpVariableMap.set("VAR_PURCHASE_CLOSING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.closingCosts));
	indicator.helpVariableMap.set("VAR_LOAN_REPAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(parseInt(loanRepaymentBalance)));
	indicator.helpVariableMap.set("VAR_MONTHLY_MORTGAGE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.monthlyPayment));

	indicator.helpVariableMap.set("VAR_MORTGAG_BALANCE_SCHEDULE",mortgageBalanceSchedule);

	indicator.helpVariableMap.set("VAR_TOTAL_MORTGAGE_PAYMENTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyPayment));

	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.price));
	indicator.helpVariableMap.set("VAR_DOWN_PAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.downPayment));
	indicator.helpVariableMap.set("VAR_SALE_CLOSING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.closingCosts));
	indicator.helpVariableMap.set("VAR_HOLDING_MONTHS", (property.holdingMonths).toString());

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>net profit</b> for this property is green, which means you are making a profit.");
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>net profit</b> for this property is red, which means you are not making a profit.");
	}

	//no return 

};

export const getMarketValuePercentIndicator = (property,benchmarkingSetting) => {

	//
	// Market Value Percent = Market Value-Purchase Price
	// -------------------------------- x 100
	// Market Value

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let marketValue = 0;
	let marketValuePercent = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;
		
		if (property.investmentType == "Flipping Property")
        {
			marketValue = property.afterRepairValue;  //I will use the avr as the market value, maybe need to change it later

        } else if (property.investmentType == "Primary Home")
        {
			marketValue = property.marketValue;		    
        }

		// 1. Calculate market value percent
		if (marketValue > 0)
		{
			marketValuePercent = ((marketValue - purchasePrice) / marketValue) * 100;
		}

		marketValuePercent = AppUtility.roundToTheNearest(marketValuePercent, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.marketValuePercentAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.marketValuePercentAccepted,marketValuePercent, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.marketValuePercentRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.marketValuePercentRejected,marketValuePercent, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.MARKET_VALUE_PERCENT_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.MARKET_VALUE_PERCENT_INDICATOR_ACRONYM;
	indicator.indicatorValue =  marketValuePercent;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateMarketValuePercentIndicatorValueMap(indicator, purchasePrice, marketValue, marketValuePercent, acceptCompareText, benchmarkingSetting.marketValuePercentAccepted, rejectCompareText, benchmarkingSetting.marketValuePercentRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateMarketValuePercentIndicatorValueMap = (indicator,purchasePrice,marketValue,marketValuePercent,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_MARKET_VALUE_PERCENT",AppUtility.getIndicatorValue(valueType, marketValuePercent / 100));
	indicator.helpVariableMap.set("VAR_MARKET_VALUE",AppUtility.getAmountStringFromDoubleNoFractionalPart(marketValue));
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(purchasePrice));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	// VAR_ACCEPT
	// VAR_CRITERIA

	let acceptText = "The <b>market value percent</b> for this property is green, which means you are paying <b>"+AppUtility.getIndicatorValue(valueType, Math.abs(marketValuePercent))+"</b> below market value.";

	let rejectText = "The <b>market value percent</b> for this property is red, which means you are paying <b>"+AppUtility.getIndicatorValue(valueType, Math.abs(marketValuePercent))+"</b> above market value.";

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", acceptText);
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", rejectText);
	}

	//no return 	

};

export const getMarketValuePercentFlipIndicator = (property,benchmarkingSetting) => {

	//
	// Market Value Percent = Market Value-Purchase Price
	// -------------------------------- x 100
	// Market Value

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let purchasePrice = 0;
	let marketValue = 0;
	let marketValuePercent = 0;

	let repairs = 0;
	// repairs
	let electricalCosts = 0;
	let carpentryCosts = 0;
	let plumbingCosts = 0;
	let hvacCosts = 0;
	let paintingCosts = 0;
	let flooringCosts = 0;
	let roofingCosts = 0;
	let landscapingCosts = 0;
	let otherRepairCosts = 0;
	let avr = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;
		// repairs
		electricalCosts = property.electricalCosts;
		carpentryCosts = property.carpentryCosts;
		plumbingCosts = property.plumbingCosts;
		hvacCosts = property.hvacCosts;
		paintingCosts = property.paintingCosts;
		flooringCosts = property.flooringCosts;
		roofingCosts = property.roofingCosts;
		landscapingCosts = property.landscapingCosts;
		otherRepairCosts = property.otherRepairCosts;

		repairs = electricalCosts + carpentryCosts + plumbingCosts + hvacCosts + paintingCosts + flooringCosts + roofingCosts + landscapingCosts + otherRepairCosts;

		avr = property.afterRepairValue;

		marketValue = avr - repairs;

		// 1. Calculate market value percent
		marketValuePercent = ((marketValue - purchasePrice) / marketValue) * 100;

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.marketValuePercentAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.marketValuePercentAccepted,marketValuePercent, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.marketValuePercentRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.marketValuePercentRejected,marketValuePercent, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.MARKET_VALUE_PERCENT_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.MARKET_VALUE_PERCENT_INDICATOR_ACRONYM;
	indicator.indicatorValue =  marketValuePercent;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateMarketValuePercentFlipIndicatorValueMap(indicator, property, purchasePrice, avr, repairs, marketValue, marketValuePercent, acceptCompareText, benchmarkingSetting.marketValuePercentAccepted, rejectCompareText, benchmarkingSetting.marketValuePercentRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateMarketValuePercentFlipIndicatorValueMap = (indicator,property,purchasePrice,avr,repairs,marketValue,marketValuePercent,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_MARKET_VALUE_PERCENT",AppUtility.getIndicatorValue(valueType, marketValuePercent / 100));
	indicator.helpVariableMap.set("VAR_MARKET_VALUE",AppUtility.getAmountStringFromDoubleNoFractionalPart(marketValue));
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(purchasePrice));
	indicator.helpVariableMap.set("VAR_REPAIRS",AppUtility.getAmountStringFromDoubleNoFractionalPart(repairs));
	indicator.helpVariableMap.set("VAR_AVR", AppUtility.getAmountStringFromDoubleNoFractionalPart(avr));

	indicator.helpVariableMap.set("VAR_ELECTRICAL_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.electricalCosts));
	indicator.helpVariableMap.set("VAR_CARPENTRY_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.carpentryCosts));
	indicator.helpVariableMap.set("VAR_PLUMBING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.plumbingCosts));
	indicator.helpVariableMap.set("VAR_HVAC_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.hvacCosts));
	indicator.helpVariableMap.set("VAR_PAINTING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.paintingCosts));
	indicator.helpVariableMap.set("VAR_FLOORING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.flooringCosts));
	indicator.helpVariableMap.set("VAR_ROOFING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.roofingCosts));
	indicator.helpVariableMap.set("VAR_LANDINGSCAPING_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.landscapingCosts));
	indicator.helpVariableMap.set("VAR_OTHER_COSTS",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.otherRepairCosts));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	// VAR_ACCEPT
	// VAR_CRITERIA

	let acceptText = "The <b>market value percent</b> for this property is green, which means you are buying the property <b>VAR_MARKET_VALUE_PERCENT</b> below market value. This might indicate you are getting a good deal on the property, however the physical condition of the property should be taken into consideration.";
	acceptText = acceptText.replace("VAR_MARKET_VALUE_PERCENT",AppUtility.getIndicatorValue(valueType, marketValuePercent / 100));

	let rejectText = "The <b>market value percent</b> for this property is red, you are buying the property <b>VAR_MARKET_VALUE_PERCENT</b> below market value, however your current market value Percent setting indicates only to accept properties that are <b>VAR_ACCEPT</b> below market value or lower.";
	rejectText = rejectText.replace("VAR_MARKET_VALUE_PERCENT",AppUtility.getIndicatorValue(valueType, marketValuePercent / 100));
	rejectText = rejectText.replace("VAR_ACCEPT", AppUtility.getIndicatorValue(valueType, acceptValue));

	if (!indicator.meetsBenchmark && marketValuePercent < 0) {
		rejectText = "The <b>market value percent</b> for this property is red, which means you are buying the property <b>VAR_MARKET_VALUE_PERCENT</b> above market value. This might indicate you are paying too much for the property.  However keep in mind that a property could be priced above market value if it has upgrades and other additional enhancements.";
		rejectText = rejectText.replace("VAR_MARKET_VALUE_PERCENT",AppUtility.getIndicatorValue(valueType, marketValuePercent / 100));

	}

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", acceptText);

		indicator.helpVariableMap
				.set("VAR_ADDITIONAL_RESULT_TEXT", "You are buying the property <b>" + AppUtility.getIndicatorValue(valueType, marketValuePercent / 100) + "</b> below market value, basically you are paying less than it is worth, which is good.");

	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", rejectText);

		indicator.helpVariableMap.set("VAR_ADDITIONAL_RESULT_TEXT", "You are buying the property <b>" + AppUtility.getIndicatorValue(valueType, marketValuePercent / 100) + "</b> above market value, basically you are paying more than it is worth, which is not good.");

	}

	//no return 

};

export const getBackEndRatioIndicator = (property,benchmarkingSetting) => {


	// Total Monthly Debt Expense
	// Back-End Ratio = ---------------------------- x 100
	// Gross Monthly Income

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let backEndRatio = 0;
	let grossMonthlyIncome = 0;
	let totalMonthlyReoccuringDebtExpense = 0;
	let mortgagePrincipal = 0;
	let childSupport = 0;
	let taxes = 0;
	let insurance = 0;
	let carLoans = 0;
	let studentLoans = 0;
	let creditCardMinimumPayments = 0;
	let otherLoanPayments = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		/*
		mortgagePrincipal = property.monthlyPayment;
		taxes = property.monthlyTaxPaymentHome;
		insurance = property.homeownersInsurancePaymentHome;
		carLoans = property.carloanDebt;
		studentLoans = property.studentloanDebt;
		otherLoanPayments = property.otherloansDebt;
		creditCardMinimumPayments = property.creditcardDebt;
		childSupport = property.childsupportDebt;
		*/
		grossMonthlyIncome = property.monthlyGrossIncome;

		// 1. Calculate Total Monthly Re-Occurring Debt Expense
		//totalMonthlyReoccuringDebtExpense = mortgagePrincipal + taxes + insurance + carLoans + studentLoans + otherLoanPayments + childSupport + creditCardMinimumPayments;

		totalMonthlyReoccuringDebtExpense = parseInt(property.monthlyPayment) + parseInt(property.monthlyBills);

		// 2. Calculate Back-End Ratio
		backEndRatio = (totalMonthlyReoccuringDebtExpense / grossMonthlyIncome) * 100;

		backEndRatio = AppUtility.roundToTheNearest(backEndRatio, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.backEndRatioAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.backEndRatioAccepted, backEndRatio, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.backEndRatioRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.backEndRatioRejected, backEndRatio, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.BACK_END_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.BACK_END_RATIO_INDICATOR_ACRONYM;
	indicator.indicatorValue =  backEndRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateBackEndRatioIndicatorValueMap(indicator, backEndRatio, grossMonthlyIncome, totalMonthlyReoccuringDebtExpense, property.monthlyPayment, taxes, insurance, property.monthlyBills, acceptCompareText, benchmarkingSetting.backEndRatioAccepted, rejectCompareText, benchmarkingSetting.backEndRatioRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateBackEndRatioIndicatorValueMap = (indicator,backEndRatio,grossMonthlyIncome,totalMonthlyReoccuringDebtExpense,monthlyPayment,taxes,insurance,monthlyBills,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {

	indicator.helpVariableMap.set("VAR_BACK_END_RATIO",AppUtility.getIndicatorValue(valueType, backEndRatio / 100));
	indicator.helpVariableMap.set("VAR_GROSS_INCOME",AppUtility.getAmountStringFromDoubleNoFractionalPart(grossMonthlyIncome));
	indicator.helpVariableMap.set("VAR_TOTAL_MONTHLY_EXPENSES",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalMonthlyReoccuringDebtExpense));
	indicator.helpVariableMap.set("VAR_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyPayment));
	indicator.helpVariableMap.set("VAR_PROPERTY_TAXES",AppUtility.getAmountStringFromDoubleNoFractionalPart(taxes));
	indicator.helpVariableMap.set("VAR_PROPERTY_INSURANCE",AppUtility.getAmountStringFromDoubleNoFractionalPart(insurance));
	indicator.helpVariableMap.set("VAR_MONTHLY_BILLS",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyBills));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>back-end ratio</b> for this property is green, which means your monthly gross income is good in relation to your total monthly expenses (including your future mortgage).");
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>back-end ratio</b> for this property is red, which means your total monthly expenses are too high for your monthly gross income.");
	}

	//no return 

};

export const getFrontEndRatioIndicator = (property,benchmarkingSetting) => {


	// Monthly Housing Expenses
	// Front-End Ratio = -------------------------- x 100
	// Gross Monthly Income

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let frontEndRatio = 0;
	let grossMonthlyIncome = 0;
	let totalMonthlyReoccuringDebtExpense = 0;
	let mortgagePrincipal = 0;
	let taxes = 0;
	let insurance = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		mortgagePrincipal = property.monthlyPayment;

		//taxes = property.monthlyTaxPaymentHome;
		//insurance = property.homeownersInsurancePaymentHome;
		grossMonthlyIncome = property.monthlyGrossIncome;

		// 1. Calculate Total Monthly Re-Occurring Debt Expense
		//totalMonthlyReoccuringDebtExpense = mortgagePrincipal + taxes + insurance;

		totalMonthlyReoccuringDebtExpense = mortgagePrincipal;

		// 2. Calculate Front-End Ratio
		frontEndRatio = (parseInt(totalMonthlyReoccuringDebtExpense) / parseInt(grossMonthlyIncome)) * 100;

		frontEndRatio = AppUtility.roundToTheNearest(frontEndRatio, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.frontEndRatioAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.frontEndRatioAccepted, frontEndRatio,symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.frontEndRatioRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.frontEndRatioRejected, frontEndRatio,symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.FRONT_END_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.FRONT_END_RATIO_INDICATOR_ACRONYM;
	indicator.indicatorValue =  frontEndRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.PERCENT;
	indicator.helpVariableMap = new Map();


	populateFrontEndRatioIndicatorValueMap(indicator, frontEndRatio, grossMonthlyIncome, totalMonthlyReoccuringDebtExpense, mortgagePrincipal, taxes, insurance, acceptCompareText, benchmarkingSetting.frontEndRatioAccepted, rejectCompareText, benchmarkingSetting.frontEndRatioRejected, IndicatorVO.PERCENT);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateFrontEndRatioIndicatorValueMap = (indicator,frontEndRatio,grossMonthlyIncome,totalMonthlyReoccuringDebtExpense,mortgagePrincipal,taxes,insurance,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_FRONT_END_RATIO",AppUtility.getIndicatorValue(valueType, frontEndRatio / 100));
	indicator.helpVariableMap.set("VAR_GROSS_INCOME",AppUtility.getAmountStringFromDoubleNoFractionalPart(grossMonthlyIncome));
	indicator.helpVariableMap.set("VAR_MONTHLY_REOCCURING_DEBT",AppUtility.getAmountStringFromDoubleNoFractionalPart(totalMonthlyReoccuringDebtExpense));
	indicator.helpVariableMap.set("VAR_MORTGAGE_PAYMENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(mortgagePrincipal));
	indicator.helpVariableMap.set("VAR_MORTGAGE_PRINCIPAL",AppUtility.getAmountStringFromDoubleNoFractionalPart(mortgagePrincipal));
	indicator.helpVariableMap.set("VAR_TAXES", AppUtility.getAmountStringFromDoubleNoFractionalPart(taxes));
	indicator.helpVariableMap.set("VAR_INSURANCE",AppUtility.getAmountStringFromDoubleNoFractionalPart(insurance));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>front-end ratio</b> for this property is green, which means you are likely to afford this property.");
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT","The <b>front-end ratio</b> for this property is red, which means you might not be able to afford this property.");
	}

	//no return 

};

export const getPriceToRentRatioIndicator = (property,benchmarkingSetting) => {


	// Purchase Price
	// Price-to-Rent Ratio = -----------------
	// Annual Rent

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let priceToRentRatio = 0;
	let purchasePrice = 0;
	let monthlyRent = 0;
	let annualRent = 0;
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		purchasePrice = property.price;

		monthlyRent = property.trendingMonthlyRent;

		if (monthlyRent ==0)
		{
			monthlyRent = property.monthlyRent;
		}
		
		// 1. Calculate Annual Rent
		annualRent = monthlyRent * 12;

		// 2. Calculate Price-to-Rent Ratio
		priceToRentRatio = purchasePrice / annualRent;

		priceToRentRatio = AppUtility.roundToTheNearest(priceToRentRatio, 100);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.priceToRentRatioAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.priceToRentRatioAccepted, priceToRentRatio,symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.priceToRentRatioRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.priceToRentRatioRejected, priceToRentRatio,symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.PRICE_TO_RENT_RATIO_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.PRICE_TO_RENT_RATIO_INDICATOR_ACRONYM;
	indicator.indicatorValue =  priceToRentRatio;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DECIMAL;
	indicator.helpVariableMap = new Map();


	populatePriceToRentRatioIndicatorValueMap(indicator, property, priceToRentRatio, purchasePrice, monthlyRent, annualRent, acceptCompareText, benchmarkingSetting.priceToRentRatioAccepted, rejectCompareText, benchmarkingSetting.priceToRentRatioRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populatePriceToRentRatioIndicatorValueMap = (indicator,property,priceToRentRatio,purchasePrice,monthlyRent,annualRent,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_PRICE_TO_RENT_RATIO",AppUtility.getIndicatorValue(valueType, priceToRentRatio));
	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(purchasePrice));
	indicator.helpVariableMap.set("VAR_MONTHLY_RENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(monthlyRent));
	indicator.helpVariableMap.set("VAR_ANNUAL_RENT",AppUtility.getAmountStringFromDoubleNoFractionalPart(annualRent));

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	let acceptText = "";
	let rejectText = "";

	if (property.investmentType != null) {
		if (property.investmentType == "Primary Home") {
			acceptText = "The <b>price-to-rent ratio</b> for this property is green, which means it makes sense financially to buy a property in this area at the present time instead of renting.";
			rejectText = "The <b>price-to-rent ratio</b> for this property is red, which means you might be better off financially by renting instead of buying a property in this area at the present time.";

		} else if (property.investmentType == "Flipping Property" || property.investmentType == "Rental Property") {
			acceptText = "The <b>price-to-rent ratio</b> for this property is green, which means this property might offer a good investment opportunity since current rent levels indicate property price will rise or stay at current levels.";
			rejectText = "The <b>price-to-rent ratio</b> for this property is red, which means there is a great probability property price might decline in this area. ";

			if (property.investmentType == "Flipping Property") {
				rejectText = rejectText + "This property can still be flipped for a profit, you just might want to hold the property for less months to minimize risk since you don't know how soonn property level could decline.";
			}
		}

	}

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", acceptText);
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", rejectText);
	}

	//no return 

};

export const getPricePerSizeIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let pricePerSize = 0;
	let purchasePrice = 0;
	let size = 0;
	let unitOfMeasure = "";
	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		size = property.livingAreaSize;
		purchasePrice = property.price;
		unitOfMeasure = property.livingAreaSizeUnit;

		pricePerSize = purchasePrice / size;

		pricePerSize = parseInt(pricePerSize);

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.pricePerSizeAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			//accepted = compareBenchmarkedValue(benchmarkingSetting.pricePerSizeAccepted, pricePerSize, symbol);
			accepted = compareBenchmarkedValue(property.trendingPricePerSqft, pricePerSize, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.pricePerSizeRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			//rejected = compareBenchmarkedValue(benchmarkingSetting.pricePerSizeRejected, pricePerSize, symbol);
			rejected = compareBenchmarkedValue(property.trendingPricePerSqft, pricePerSize, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;

			console.log(property.address);
			console.log(property.trendingPricePerSqft);
			console.log(pricePerSize);
			console.log(symbol);
			console.log(passFlag);
			console.log("---------------------------");
		}
	}


	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.PRICE_PER_SIZE_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.PRICE_PER_SIZE_INDICATOR_ACRONYM;
	indicator.indicatorValue =  pricePerSize;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DOLLAR_AMOUNT;
	indicator.helpVariableMap = new Map();


	populatePricePerSizeIndicatorValueMap(indicator, property, pricePerSize, purchasePrice, size, unitOfMeasure, acceptCompareText, benchmarkingSetting.pricePerSizeAccepted, rejectCompareText, benchmarkingSetting.pricePerSizeRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populatePricePerSizeIndicatorValueMap = (indicator,property,pricePerSize,purchasePrice,size,unitOfMeasure,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_PRICE_PER_SIZE", AppUtility.getAmountStringFromDouble(pricePerSize));

	indicator.helpVariableMap.set("VAR_PURCHASE_PRICE",AppUtility.getAmountStringFromDoubleNoFractionalPart(purchasePrice));

	indicator.helpVariableMap.set("VAR_PROPERTY_SIZE",AppUtility.getNumberFromString(size) + " sqft");

	indicator.helpVariableMap.set("VAR_PROPERTY_UNIT_OF_MEASURE", unitOfMeasure);

	indicator.helpVariableMap.set("VAR_AVERAGE_PRICE_PER_SIZE",AppUtility.getAmountStringFromDoubleNoFractionalPart(property.trendingPricePerSqft));

	let bufferTitle = new StringBuffer();
	bufferTitle.append("Price Per ");
	bufferTitle.append(unitOfMeasure);
	bufferTitle.append("  Overview (+)");
	indicator.helpVariableMap.set("VAR_OVERVIEW_TITLE", bufferTitle.toString());

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	//buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	console.log(JSON.stringify(property));
	buffer.append(AppUtility.getIndicatorValue(valueType, property.trendingPricePerSqft));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	//buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));
	buffer.append(AppUtility.getIndicatorValue(valueType, property.trendingPricePerSqft));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	let acceptText = "";
	let rejectText = "";

	acceptText = "The <b>price per sqft</b> for this property is green, which means the <b>price per sqft</b> you are paying is below the average for the area.";
	rejectText = "The <b>price per sqft</b> for this property is red, which means the <b>price per sqft</b> you are paying is higher than the average for the area.";

	acceptText = acceptText.replace("VAR_UNIT_MEASURE", unitOfMeasure);

	rejectText = rejectText.replace("VAR_UNIT_MEASURE", unitOfMeasure);

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", acceptText);
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", rejectText);
	}

	//no return 

};

export const getCreditScoreIndicator = (property,benchmarkingSetting) => {

	const comparisonSymbolMap = COMPARISON_SYMBOL_MAP;

	let indicator = null;

	let creditScore = 0;

	let passFlag = false;
	let accepted = false;
	let rejected = false;
	let acceptCompareText = "";
	let rejectCompareText = "";

	if (benchmarkingSetting == null) {
		 let error = "Error occurred";
	}

	if (property != null) {

		creditScore = property.creditScore;

		const creditScoreArray = creditScore.split('-');

		let checkCreditScore = 0;
		if (creditScoreArray.length > 1)
		{
			checkCreditScore = parseInt(creditScoreArray[0]); 
		} else
		{
			checkCreditScore = parseInt(creditScore); 
		}

		if (comparisonSymbolMap != null) {
			let compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.creditScoreAcceptedCompareId));
			let symbol = compareOperatorVO.symbol;
			accepted = compareBenchmarkedValue(benchmarkingSetting.creditScoreAccepted, checkCreditScore, symbol);
			acceptCompareText = compareOperatorVO.nickName;

			compareOperatorVO = comparisonSymbolMap.get(parseInt(benchmarkingSetting.creditScoreRejectedCompareId));
			symbol = compareOperatorVO.symbol;
			rejected = compareBenchmarkedValue(benchmarkingSetting.creditScoreRejected, checkCreditScore, symbol);
			passFlag = accepted && !rejected;
			rejectCompareText = compareOperatorVO.nickName;
		}
	}

	indicator  = {};
	indicator.meetsBenchmark = passFlag;
	indicator.indicatorName =  IndicatorVO.CREDIT_SCORE_INDICATOR_NAME;
	indicator.indicatorAcronym = IndicatorVO.CREDIT_SCORE_INDICATOR_ACRONYM;
	indicator.indicatorValue =  creditScore;
	indicator.property =  property;
	indicator.valueType =  IndicatorVO.DECIMAL;
	indicator.helpVariableMap = new Map();


	populateCreditScoreIndicatorValueMap(indicator, property, creditScore, acceptCompareText,benchmarkingSetting.creditScoreAccepted, rejectCompareText,benchmarkingSetting.creditScoreRejected, IndicatorVO.DECIMAL);

	populatePropertyValuesIntoMap(indicator, property);

	indicator.helpVariableMap = Object.fromEntries(indicator.helpVariableMap); //needs to convert Map to JSON

	return indicator;

};

export const populateCreditScoreIndicatorValueMap = (indicator,property,creditScore,acceptCompareText,acceptValue,rejectCompareText,rejectValue,valueType) => {


	indicator.helpVariableMap.set("VAR_CREDIT_SCORE", AppUtility.getIndicatorValue(valueType, creditScore));

	indicator.helpVariableMap.set("VAR_REQUIRED_CREDIT_SCORE", acceptValue);

	let buffer = new StringBuffer();
	buffer.append(acceptCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, acceptValue));

	indicator.helpVariableMap.set("VAR_ACCEPT_RANGE", buffer.toString());

	buffer = AppUtility.clearBuffer(buffer);
	buffer.append(rejectCompareText);
	buffer.append(" ");
	buffer.append(AppUtility.getIndicatorValue(valueType, rejectValue));

	indicator.helpVariableMap.set("VAR_REJECT_RANGE", buffer.toString());

	let acceptText = "";
	let rejectText = "";

	acceptText = "The <b>credit score</b> for this property is green, which means the credit score of the property buyer is above your minimum credit score requirement of <b>" + acceptValue.toString() + "</b>. <br><br>Which means lenders will be more willing to approve your loan.";
	rejectText = "The <b>credit score</b> for this property is red, which means the credit score of the property buyer is below your minimum credit score requirement of <b>" + acceptValue.toString() + "</b>. <br><br>Which means lenders either won't be able to approve your loan or may be required to offer you a higher interest rate, which can result in higher monthly payments.";

	if (indicator.meetsBenchmark) {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", acceptText);
	} else {
		indicator.helpVariableMap.set("VAR_RESULT_TEXT", rejectText);
	}

	//no return 

};

export const compareBenchmarkedValue = (benchmarkedValue,value,compareSymbol) => {

	let passFlag = false;
	if (compareSymbol != null) {
		compareSymbol = compareSymbol.trim();

		if (compareSymbol == ">")
			return compareGreaterThan(benchmarkedValue, value);
		if (compareSymbol == ">=")
			return compareGreaterThanOrEqualTo(benchmarkedValue, value);
		if (compareSymbol == "<")
			return compareLessThan(benchmarkedValue, value);
		if (compareSymbol == "<=")
			return compareLessThanOrEqualTo(benchmarkedValue, value);
		if (compareSymbol =="=")
			return compareEqualTo(benchmarkedValue, value);
		if (compareSymbol == "!=")
			return !compareEqualTo(benchmarkedValue, value);
	}
	
};

export const compareGreaterThan = (benchmarkedValue,value) => {

	return value > benchmarkedValue;

};

export const compareGreaterThanOrEqualTo = (benchmarkedValue,value) => {

	return value >= benchmarkedValue;

};

export const compareLessThan = (benchmarkedValue,value) => {

	return value < benchmarkedValue;

};

export const compareLessThanOrEqualTo = (benchmarkedValue,value) => {

	return value <= benchmarkedValue;

};

export const compareEqualTo = (benchmarkedValue,value) => {

	if (value == benchmarkedValue)
	{
		return true;
	}else
	{
		return false;
	}

};

export const populatePropertyValuesIntoMap = (indicator,property) => {


	if (property != null && indicator != null) {
		let addressBuffer = new StringBuffer();
		indicator.helpVariableMap.set("VAR_PROPERTY_NAME", property.nickName);

		addressBuffer.append(property.location);

		indicator.helpVariableMap.set("VAR_PROPERTY_ADDRESS", addressBuffer.toString());

	}

	//no return 

};

export const getPresentValue = (yearCashFlow,yearSalesProceeds,irr,yearNumber) => {

	let pv = 0;
	irr = irr / 100;
	let totalCashFlow = yearCashFlow + yearSalesProceeds;
	pv = totalCashFlow / Math.pow(1 + irr, yearNumber);

	pv = parseInt(pv);
	
	//console.log("yearCashFlow:"+yearCashFlow);
	//console.log("yearSalesProceeds:"+yearSalesProceeds);
	//console.log("totalCashFlow:"+totalCashFlow);
	//console.log("yearNumber:"+yearNumber);
	//console.log("pv:"+pv);
	//console.log("---------------------------------------------");

	return pv;

};


const setAll = (map1,map2) => {

for (let [key, value] of map1) {
	//console.log(key + " = " + value);
	map2.set(key,value);
}

};

export const calculateFutureCashFlows = (property,benchmarkingSettings) => {

 const propertyCashFlowMaps = new Map(); // This is a convenient way add put all the maps in one collection to pass it to the AnalysisDialog to help display cashflow details
 // It is not mission critical

	if (property.investmentType == "Rental Property")
	{
		if (parseInt(property.expenseRateOfGrowth) > 0 && 
		    parseInt(property.rentRateOfGrowth) > 0 && 
			parseInt(property.vacancyRate) > 0 && 
			parseInt(property.monthlyExpenses) > 0 && 
			parseInt(property.monthlyPayment) > 0)
			{
				const projectedYearOfSale = parseInt(property.projectedYearOfSale.toLowerCase().replace("year","").trim());
										
				const projectedFutureCashFlows = getEstimatedFutureCashFlows(property,projectedYearOfSale,property.projectedSaleProceeds,benchmarkingSettings);

				for (let i = 1; i <= projectedYearOfSale; i++) {

					const annualCashFlow = projectedFutureCashFlows.get(i).get("VAR_ANNUAL_CASH_FLOW");
					const annualCashFlowAsNumber = projectedFutureCashFlows.get(i).get("VAR_ANNUAL_CASH_FLOW_AS_NUMBER");

					propertyCashFlowMaps.set(i,projectedFutureCashFlows.get(i));

				}


			}
	}

	return propertyCashFlowMaps;
};


/* This is not business logic function, just a helper method to convert db settings to the format the app expects it*/
export const getIndicatorBenchmarkSettings = (settings) => {

	const benchmarkSettings = {};

	benchmarkSettings.grmAccepted = settings.grossRentMultiplier;
	benchmarkSettings.grmRejected = settings.grossRentMultiplier;
	benchmarkSettings.grmAcceptedCompareId =  getAcceptedComparisonId(settings.grossRentMultiplierComparison);
	benchmarkSettings.grmRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.grmAcceptedCompareId);

	benchmarkSettings.cocAccepted = settings.cashOnCashReturn;
	benchmarkSettings.cocRejected = settings.cashOnCashReturn;
	benchmarkSettings.cocAcceptedCompareId =  getAcceptedComparisonId(settings.cashOnCashReturnComparison);
	benchmarkSettings.cocRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.cocAcceptedCompareId);
	
	benchmarkSettings.rrrAccepted = settings.internalRateOfReturn;  //??? no rrr in settings, by design
	benchmarkSettings.rrrRejected = settings.internalRateOfReturn;
	benchmarkSettings.rrrAcceptedCompareId =  getAcceptedComparisonId(settings.internalRateOfReturnComparison);
	benchmarkSettings.rrrRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.rrrAcceptedCompareId);

	benchmarkSettings.berAccepted = settings.breakEvenRatio;
	benchmarkSettings.berRejected = settings.breakEvenRatio;
	benchmarkSettings.berAcceptedCompareId =  getAcceptedComparisonId(settings.breakEvenRatioComparison);
	benchmarkSettings.berRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.berAcceptedCompareId);
	
	benchmarkSettings.capAccepted = settings.capitalizationRate;
	benchmarkSettings.capRejected = settings.capitalizationRate;
	benchmarkSettings.capAcceptedCompareId =  getAcceptedComparisonId(settings.capitalizationRateComparison);
	benchmarkSettings.capRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.capAcceptedCompareId);
	
	benchmarkSettings.dcrAccepted = settings.debtCoverageRatio;  //???  multiple
	benchmarkSettings.dcrRejected = settings.debtCoverageRatio;
	benchmarkSettings.dcrAcceptedCompareId =  getAcceptedComparisonId(settings.debtCoverageRatioComparison);
	benchmarkSettings.dcrRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.dcrAcceptedCompareId);

	benchmarkSettings.lvrAccepted = settings.loanToValueRatio;  //??? multiple
	benchmarkSettings.lvrRejected = settings.loanToValueRatio;
	benchmarkSettings.lvrAcceptedCompareId =  getAcceptedComparisonId(settings.loanToValueRatioComparison);
	benchmarkSettings.lvrRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.lvrAcceptedCompareId);

	benchmarkSettings.rule70Accepted = settings.ruleOf70;
	benchmarkSettings.rule70Rejected = settings.ruleOf70;
	benchmarkSettings.rule70AcceptedCompareId =  getAcceptedComparisonId(settings.ruleOf70Comparison);
	benchmarkSettings.rule70RejectedCompareId =  getRejectedComparisonId(benchmarkSettings.rule70AcceptedCompareId);

	benchmarkSettings.roiAccepted = settings.roi;
	benchmarkSettings.roiRejected = settings.roi;
	benchmarkSettings.roiAcceptedCompareId =  getAcceptedComparisonId(settings.roiComparison);
	benchmarkSettings.roiRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.roiAcceptedCompareId);

	benchmarkSettings.keyRiskIndicatorAccepted = settings.keyRiskIndicator;
	benchmarkSettings.keyRiskIndicatorRejected = settings.keyRiskIndicator;
	benchmarkSettings.keyRiskIndicatorAcceptedCompareId =  getAcceptedComparisonId(settings.keyRiskIndicatorComparison);
	benchmarkSettings.keyRiskIndicatorRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.keyRiskIndicatorAcceptedCompareId);

	benchmarkSettings.netProfitAccepted = settings.netProfit;
	benchmarkSettings.netProfitRejected = settings.netProfit;
	benchmarkSettings.netProfitAcceptedCompareId =  getAcceptedComparisonId(settings.netProfitComparison);
	benchmarkSettings.netProfitRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.netProfitAcceptedCompareId);
	
	benchmarkSettings.ncfAccepted = settings.netCashFlow;
	benchmarkSettings.ncfRejected = settings.netCashFlow;
	benchmarkSettings.ncfAcceptedCompareId =  getAcceptedComparisonId(settings.netCashFlowComparison);
	benchmarkSettings.ncfRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.ncfAcceptedCompareId);

	benchmarkSettings.marketValuePercentAccepted = settings.marketValuePercent;
	benchmarkSettings.marketValuePercentRejected = settings.marketValuePercent;
	benchmarkSettings.marketValuePercentAcceptedCompareId =  getAcceptedComparisonId(settings.marketValuePercentComparison);
	benchmarkSettings.marketValuePercentRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.marketValuePercentAcceptedCompareId);

	benchmarkSettings.backEndRatioAccepted = settings.backEndRatio;
	benchmarkSettings.backEndRatioRejected = settings.backEndRatio;
	benchmarkSettings.backEndRatioAcceptedCompareId =  getAcceptedComparisonId(settings.backEndRatioComparison);
	benchmarkSettings.backEndRatioRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.backEndRatioAcceptedCompareId);
	
	benchmarkSettings.frontEndRatioAccepted = settings.frontEndRatio;
	benchmarkSettings.frontEndRatioRejected = settings.frontEndRatio;
	benchmarkSettings.frontEndRatioAcceptedCompareId =  getAcceptedComparisonId(settings.frontEndRatioComparison);
	benchmarkSettings.frontEndRatioRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.frontEndRatioAcceptedCompareId);
	
	benchmarkSettings.priceToRentRatioAccepted = settings.priceToRentRatio;
	benchmarkSettings.priceToRentRatioRejected = settings.priceToRentRatio;
	benchmarkSettings.priceToRentRatioAcceptedCompareId =  getAcceptedComparisonId(settings.priceToRentRatioComparison);
	benchmarkSettings.priceToRentRatioRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.priceToRentRatioAcceptedCompareId);

	benchmarkSettings.costOfDebtAccepted = settings.costOfDebt;
	benchmarkSettings.costOfDebtRejected = settings.costOfDebt;
	benchmarkSettings.costOfDebtAcceptedCompareId =  getAcceptedComparisonId(settings.costOfDebtComparison);
	benchmarkSettings.costOfDebtRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.costOfDebtAcceptedCompareId);

	benchmarkSettings.creditScoreAccepted = settings.creditScore;
	benchmarkSettings.creditScoreRejected = settings.creditScore;
	benchmarkSettings.creditScoreAcceptedCompareId =  getAcceptedComparisonId(settings.creditScoreComparison);
	benchmarkSettings.creditScoreRejectedCompareId =  getRejectedComparisonId(benchmarkSettings.creditScoreAcceptedCompareId);


	return benchmarkSettings;
};

const getAcceptedComparisonId = (comparisonText) => {

	let id=0;

	for (let [key, value] of COMPARISON_SYMBOL_MAP) {
		//console.log(key + " = " + value);

		const compareOperatorVO = value;
		if (compareOperatorVO.nickName == comparisonText)
		{
			id=compareOperatorVO.id;
			break;
		}
	}

	return id;
};


const getRejectedComparisonId = (acceptedComparisonId) => {

	let id=0;

	const accetedCompareOperatorVO = COMPARISON_SYMBOL_MAP.get(acceptedComparisonId);

	const acceptedSymbol = accetedCompareOperatorVO.symbol;
	let rejectedSymbol = "";

	if (acceptedSymbol == ">")
	{
		rejectedSymbol = "<=";

	} else if (acceptedSymbol == ">=")
	{
		rejectedSymbol = "<";
		
	} else if (acceptedSymbol == "=")
	{
		rejectedSymbol = "!=";

	} else if (acceptedSymbol == "<")
	{
		rejectedSymbol = ">=";

	} else if (acceptedSymbol == "<=")
	{
		rejectedSymbol = ">";

	} else if (acceptedSymbol == "!=")
	{
		rejectedSymbol = "=";
	}


	for (let [key, value] of COMPARISON_SYMBOL_MAP) {
		//console.log(key + " = " + value);

		const compareOperatorVO = value;
		if (compareOperatorVO.symbol == rejectedSymbol)
		{
			id=compareOperatorVO.id;
			break;
		}
	}

	return id;
};

/*
exports.getAmortizationSchedule = getAmortizationSchedule;
exports.getMortgageBalanceOnSpecificYear = getMortgageBalanceOnSpecificYear;
exports.getMortgageBalanceOnSpecificMonth = getMortgageBalanceOnSpecificMonth;
exports.getEstimatedFutureCashFlows = getEstimatedFutureCashFlows;
exports.getNetProfitFromFutureSale = getNetProfitFromFutureSale;
exports.getNetCashFlowIndicator = getNetCashFlowIndicator;
exports.getNetOperatingIncome = getNetOperatingIncome;
exports.getGrossOperatingIncome = getGrossOperatingIncome;
exports.getMonthlyScheduledIncome = getMonthlyScheduledIncome;
exports.getCreditLoss = getCreditLoss;
exports.getTotalExpenses = getTotalExpenses;
exports.populateNetCashFlowIndicatorValueMap = populateNetCashFlowIndicatorValueMap;
exports.getGRMIndicator = getGRMIndicator;
exports.populateGRMIndicatorValueMap = populateGRMIndicatorValueMap;
exports.getCapRateIndicator = getCapRateIndicator;
exports.populateCapRateIndicatorValueMap = populateCapRateIndicatorValueMap;
exports.getCashOnCashIndicator = getCashOnCashIndicator;
exports.populateCashOncashIndicatorValueMap = populateCashOncashIndicatorValueMap;
exports.getInternalRateOfReturnIndicator = getInternalRateOfReturnIndicator;
exports.populateInternalRateOfReturnIndicatorValueMap = populateInternalRateOfReturnIndicatorValueMap;
exports.getNetPresentValueMap = getNetPresentValueMap;
exports.getProfitabilityIndexIndicator = getProfitabilityIndexIndicator;
exports.populateProfitabilityIndexIndicatorValueMap = populateProfitabilityIndexIndicatorValueMap;
exports.getDebtCoverageRatioRentalIndicator = getDebtCoverageRatioRentalIndicator;
exports.populateDebtCoverageRatioIndicatorValueMap = populateDebtCoverageRatioIndicatorValueMap;
exports.getDebtCoverageRatioHomeIndicator = getDebtCoverageRatioHomeIndicator;
exports.populateDebtCoverageRatioHomeIndicatorValueMap = populateDebtCoverageRatioHomeIndicatorValueMap;
exports.getDebtCoverageRatioFlipIndicator = getDebtCoverageRatioFlipIndicator;
exports.populateDebtCoverageRatioFlipIndicatorValueMap = populateDebtCoverageRatioFlipIndicatorValueMap;
exports.getCostOfDebtIndicator = getCostOfDebtIndicator;
exports.getAmortizationTotalInterest = getAmortizationTotalInterest;
exports.populateCostOfDebtIndicatorValueMap = populateCostOfDebtIndicatorValueMap;
exports.getBreakEvenRatioIndicator = getBreakEvenRatioIndicator;
exports.populateBreakEvenRatioIndicatorValueMap = populateBreakEvenRatioIndicatorValueMap;
exports.getLoanToValueRatioIndicator = getLoanToValueRatioIndicator;
exports.populateLoanToValueRatioIndicatorValueMap = populateLoanToValueRatioIndicatorValueMap;
exports.get70PercentRuleIndicator = get70PercentRuleIndicator;
exports.populate70PercentRuleIndicatorValueMap = populate70PercentRuleIndicatorValueMap;
exports.getROIIndicator = getROIIndicator;
exports.populateROIIndicatorValueMap = populateROIIndicatorValueMap;
exports.getKeyRiskIndicator = getKeyRiskIndicator;
exports.populateKeyRiskIndicatorValueMap = populateKeyRiskIndicatorValueMap;
exports.getBudgetIndicator = getBudgetIndicator;
exports.populateBudgetIndicatorValueMap = populateBudgetIndicatorValueMap;
exports.getBudgetHomeIndicator = getBudgetHomeIndicator;
exports.populateBudgetHomeIndicatorValueMap = populateBudgetHomeIndicatorValueMap;
exports.getNetProfitIndicator = getNetProfitIndicator;
exports.populateNetProfitIndicatorValueMap = populateNetProfitIndicatorValueMap;
exports.getMarketValuePercentIndicator = getMarketValuePercentIndicator;
exports.populateMarketValuePercentIndicatorValueMap = populateMarketValuePercentIndicatorValueMap;
exports.getMarketValuePercentFlipIndicator = getMarketValuePercentFlipIndicator;
exports.populateMarketValuePercentFlipIndicatorValueMap = populateMarketValuePercentFlipIndicatorValueMap;
exports.getBackEndRatioIndicator = getBackEndRatioIndicator;
exports.populateBackEndRatioIndicatorValueMap = populateBackEndRatioIndicatorValueMap;
exports.getFrontEndRatioIndicator = getFrontEndRatioIndicator;
exports.populateFrontEndRatioIndicatorValueMap = populateFrontEndRatioIndicatorValueMap;
exports.getPriceToRentRatioIndicator = getPriceToRentRatioIndicator;
exports.populatePriceToRentRatioIndicatorValueMap = populatePriceToRentRatioIndicatorValueMap;
exports.getPricePerSizeIndicator = getPricePerSizeIndicator;
exports.populatePricePerSizeIndicatorValueMap = populatePricePerSizeIndicatorValueMap;
exports.getCreditScoreIndicator = getCreditScoreIndicator;
exports.populateCreditScoreIndicatorValueMap = populateCreditScoreIndicatorValueMap;
exports.compareBenchmarkedValue = compareBenchmarkedValue;
exports.compareGreaterThan = compareGreaterThan;
exports.compareGreaterThanOrEqualTo = compareGreaterThanOrEqualTo;
exports.compareLessThan = compareLessThan;
exports.compareLessThanOrEqualTo = compareLessThanOrEqualTo;
exports.compareEqualTo = compareEqualTo;
exports.populatePropertyValuesIntoMap = populatePropertyValuesIntoMap;
exports.getPresentValue = getPresentValue;
exports.calculateFutureCashFlows = calculateFutureCashFlows;
exports.getIndicatorBenchmarkSettings = getIndicatorBenchmarkSettings;
exports.COMPARISON_SYMBOL_MAP = COMPARISON_SYMBOL_MAP;

*/

//console.log("This is a test");

//console.log(IndicatorVO.BACK_END_RATIO_INDICATOR_NAME);

/*
const yearNumber = 15;
const loanAmount = 550000;
const annualInterestRate = 3.5; 
schedule = getAmortizationSchedule(yearNumber,loanAmount,annualInterestRate);
console.log(schedule);
*/

/*
const yearNumber = 15;
const inProperty = {
	"name" : "Miami Property",
	"expenseRateOfGrowth" : 3.0,
	"rentRateOfGrowth" : 2.0,
	"vacancyRate" : 1.5,
	"administrativeExpense" : 3000,
	"propertyTaxes" : 2000,
	"insuranceExpense" : 1500,
	"maintenanceExpense" : 1000,
	"generalExpense" : 800
};

*/

//const cashFlows = getEstimatedFutureCashFlows(inProperty,yearNumber);

//console.log(cashFlows);

/*
const benchmarkingSetting = {
    "id":10,
    "grmAccepted":10,
    "grmRejected":10,	
    "grmAcceptedCompareId":1,
    "grmRejectedCompareId":2,
    "cocAccepted":10,
    "cocRejected":10,
    "cocAcceptedCompareId":3,
    "cocRejectedCompareId":4,
    "rrrAccepted":10,
    "rrrRejected":10,    
    "rrrAcceptedCompareId":5,
    "rrrRejectedCompareId":6,
    "berAccepted":10,
    "berRejected":10,    
    "berAcceptedCompareId":7,
    "berRejectedCompareId":8,
    "capAccepted":10,
    "capRejected":10,    
    "capAcceptedCompareId":1,
    "capRejectedCompareId":5,
    "dcrAccepted":10,
    "dcrRejected":10,
    "dcrAcceptedCompareId":11,
    "dcrRejectedCompareId":12,
    "lvrAccepted":10,
    "lvrRejected":10,    
    "lvrAcceptedCompareId":13,
    "lvrRejectedCompareId":14,
    "rule70Accepted":10,
    "rule70Rejected":10,    
    "rule70AcceptedCompareId":15,
    "rule70RejectedCompareId":16,
    "roiAccepted":10,
    "roiRejected":10,    
    "roiAcceptedCompareId":17,
    "roiRejectedCompareId":18,
    "keyRiskIndicatorAccepted":10,
    "keyRiskIndicatorRejected":10,    
    "keyRiskIndicatorAcceptedCompareId":19,
    "keyRiskIndicatorRejectedCompareId":20,
    "netProfitAccepted":10,
    "netProfitRejected":10,    
    "netProfitAcceptedCompareId":21,
    "netProfitRejectedCompareId":22,
    "marketValuePercentAccepted":10,
    "marketValuePercentRejected":10,    
    "marketValuePercentAcceptedCompareId":23,
    "marketValuePercentRejectedCompareId":24,
    "backEndRatioAccepted":10,
    "backEndRatioRejected":10,    
    "backEndRatioAcceptedCompareId":25,
    "backEndRatioRejectedCompareId":26,
    "frontEndRatioAccepted":10,
    "frontEndRatioRejected":10,    
    "frontEndRatioAcceptedCompareId":27,
    "frontEndRatioRejectedCompareId":28,
    "priceToRentRatioAccepted":10,
    "priceToRentRatioRejected":10,    
    "priceToRentRatioAcceptedCompareId":29,
    "priceToRentRatioRejectedCompareId":30,
    "creditScoreAccepted":10,
    "creditScoreRejected":10,    
    "creditScoreAcceptedCompareId":31,
    "creditScoreRejectedCompareId":32,
    "pricePerSizeAccepted":10,
    "pricePerSizeRejected":10,    
    "pricePerSizeAcceptedCompareId":33,
    "pricePerSizeRejectedCompareId":34,
    "costOfDebtAccepted":10,
    "costOfDebtRejected":10,    
    "costOfDebtAcceptedCompareId":35,
	"costOfDebtRejectedCompareId":36
};

//const indicator = getGRMIndicator(inProperty,benchmarkingSetting,COMPARISON_SYMBOL_MAP);

const indicator = getCapRateIndicator(inProperty,benchmarkingSetting,COMPARISON_SYMBOL_MAP);

console.log(indicator);

*/




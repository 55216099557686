import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import FirstPage from './FirstPage';
import PropertyDetailPage from './PropertyDetailPage';
import PdfReportFooter from './PdfReportFooter';
import GlossaryPage from './GlossaryPage';

const PDFReport = ({ data }) => {
	return (
		<Document title='Property-Report'>
			<Page style={styles.body}>
				
				<FirstPage
					logoUrl={'/images/logo.png'}
					passedCount={data.greenIndicatorsFound}
					failedCount={data.redIndicatorsFound}
					indicators={data.indicators}
					categoryName={data.investmentType}
					address={data.address}
					city={data.city}
					stateCode={data.state}
					zip={data.zipcode}
					price={data.price}
				/>
				<PropertyDetailPage {...data} />
				<GlossaryPage glossary={data.glossary} />
				<PdfReportFooter />
				
			</Page>
		</Document>
	);
};

const styles = StyleSheet.create({
	body: {
		paddingTop: 24,
		paddingBottom: 48,
		paddingHorizontal: 24,
	},
});

export default PDFReport;

import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';

//import appLogo from '../images/logo-nocircle.png';

import appLogo from './img/website-images/real-estate-property-analytics-logo.png';

import closeIcon from './img/icons/x2.png';

import { parseOutFirstName } from '../utilities/AppUtility';

import Dialog from 'react-bootstrap-dialog'

import axios from 'axios';

import { authMiddleWare } from '../utilities/Auth';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#e37627'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class emailConfirmation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			confirmationCode:'',
			errors: [],
			loading: false
		};
	}

	componentWillMount = () => {

		try
		{
			
			// 2. check user is logped in
			console.log("About to check logged in user.");

			authMiddleWare(this?.props?.history);
			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.get('/api/alreadyLoggedInCheck')
				.then((response) => {

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					//console.log(JSON.stringify(response));

					//If user is logged in
					if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
					{
						//Retrieve user
						
						authMiddleWare(this?.props?.history);
						const authToken = localStorage.getItem('AuthToken');
						const refreshToken = localStorage.getItem('RefreshToken');
						axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                        axios
                        .get('/api/user')
                        .then((response) => {
                            console.log(response.data);
           
							if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
							{
								localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
								localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
							}
			
                            this.setState({ 
                                user: response.data,
								email: response.data.email
                            });	

							localStorage.setItem('SessionExpired','N')
    
                            
                        })
                        .catch((error) => {
                            console.log('Error in getting user record:'+error);
        
                            this.setState({ 
                                user: null
                            });	
                            //If user is not logged in redirect to signup
							this.props.history.push('/signup');

                    
                          });
					} 

				})
				.catch((error) => {
					console.log(error);
					if(error.response.status === 403) {
						
						//If user is not logged in redirect to signup
						this.props.history.push('/signup');
					}
					
				});

			
			} catch(err)
			{
				console.log("Error checking logged in user:"+err);
			
			}
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.UI.errors) {
			this.setState({
				errors: nextProps.UI.errors
			});
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	displayAlert = (title,message) => {

		this.dialog.show({
			title: title,
			body: message,
			actions: [
			  Dialog.OKAction(() => {
				//console.log('OK was clicked!')
				
			  })
			],
			bsSize: 'small',
			onHide: (dialog) => {
			  dialog.hide()
			  
			}
		  });
	};


	resendConfirmationCode = (event) => {
		event.preventDefault();
		
		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 

		axios
		.post('/api/sendConfirmationCode')
		.then((response) => {
				
		if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
		{
			localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
			localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
		}

		//alert("A new confirmation code has been sent to your email.");		

		this.displayAlert("New Confirmation Code","A new confirmation code has been sent to your email.");

		})
		.catch((error) => {
	
			console.log(error);

		});

	}

	handleSubmit = (event) => {
		event.preventDefault();

     
		if (this.state.confirmationCode ==="")
		{
			this.displayAlert("Missig Confirmation Code","Enter the confirmation code sent to your email.");

			this.setState({ 
				confirmationCode: ''
			});
			return;
		} 

		//const firstName= parseOutFirstName(this.state.name);
		this.setState({ loading: true });
		//this.setState({ firstName: firstName });

		const payload = {
			confirmationCode: this.state.confirmationCode
		};

		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

		axios
		.post('/api/validateConfirmationCode', payload)
		.then((response) => {
				
			if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
			{
				localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
				localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
			}

				this.setState({ 
					loading: false,
				});
				
				if (this.state.user.planType == "trial")
				{
					this.props.history.push('/app');
				} else 
				{
					this.props.history.push('/signup/terms-and-conditions');
				}
				

			})
			.catch((error) => {
		
				this.displayAlert("Invalid Confirmation Code","Invalid confirmation code. Enter again or click the Resend code button to get a new one");

				this.setState({
					errors: error,
					confirmationCode: '',
					loading: false
				});
			});

		
	};

	
	checkValue = (e) => {	
		//var value = e.target.value;
		var value = e.target.checked;
		//alert("You selected " + value);

		this.setState({
			termsChecked: value
		});

	  }

	handleTermsAndPrivacyChange = () => {
		//setChecked(!checked);
	  };

	goHome = () => {
		window.location.href ="/";
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#e37627' //your color
			  }
			}
		  });
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<div>
			
			<div style={{float:'right'}}><a href="/pricing"><img src={closeIcon} style={{height:'30px'}}/></a> &nbsp;&nbsp;&nbsp;&nbsp;</div>
			
			<Container component="main" maxWidth="xs">
				
				<CssBaseline />
				<div className={classes.paper}>

			{/*	<img src={appLogo} style={{height:'45px',cursor:'pointer'}} onClick={this.goHome}/>  */}
					
					{/*
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#e37627',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
					GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>

					*/}

					<img src={appLogo} style={{height:'85px',cursor:'pointer',paddingBottom:"10px"}} onClick={this.goHome}/> 

					<Typography component="h1" variant="h5">
					Confirm your email
					</Typography>
					
					{(this.state?.user?.planType != "trial") && 

						<div style={{paddingTop:'10px',paddingBottom:'10px'}}>
							<span class="dot-circle"></span>
							<span class="dot"></span>
							<span class="dot"></span>
						</div>

					}

					<form className={classes.form} noValidate>
					<ThemeProvider theme={theme}>
						<Grid container spacing={2}>
				
					
							<Grid item xs={12}>
								
								<span style={{fontSize:'19px',color:'#000000'}}>A verification code has been sent to you at <b>{this.state.email}</b>. Enter the code below: </span>
							
							</Grid>

							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="confirmationCode"
									label="Confirmation Code"
									type="regular"
									name="confirmationCode"  
									value={this.state.confirmationCode}
									inputProps={{style: {fontSize: 25, fontWeight:'bold'}}}
									InputLabelProps={{style: {fontSize: 23}}}
									autoComplete="confirmationCode"
									helperText={errors.confirmationCode}
									error={errors.confirmationCode ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>


							<Grid item xs={12}>
								
								<span style={{fontSize:'19px',color:'#000000'}}>
									<button onClick={this.resendConfirmationCode} class="button-link">Resend code</button>
									<br></br>
									Haven't received an email? Please check your spam folder. 
								</span>
							
							</Grid>
					
					
						</Grid>

				

						<Button
							type="submit"
							fullWidth
							variant="contained" 
							style={{color:'#FFFFFF',fontSize:'20px',fontWeight:'bold'}}
							color="primary"
							className={classes.submit}
							onClick={this.handleSubmit}
                            disabled={loading}
						>
							Confirm Email
							{loading && <CircularProgress size={30} className={classes.progess} />}
						</Button>
					


						</ThemeProvider>
					</form>
					<br></br><br></br><br></br>
				</div>
				<Dialog ref={(component) => { this.dialog = component }}/> 
			</Container>
			</div>
		);
	}
}

export default withStyles(styles)(emailConfirmation);

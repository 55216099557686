import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">
                                    The <b>capitalization rate</b> is a metric that allows investors to measure the <b>earning ability</b> of a rental property. It is the ratio between a property's net operating income and the purchase price.

                                    </div>

                                    <div class="col-md-12" style={{paddingTop:'10px'}}>
                                    The <b>capitalization rate</b> is sometimes called the <b>net yield</b> or simply <b>cap rate</b>.
                                    </div>
                                     
                                     <div class="col-md-12" style={{paddingTop:'10px'}}>
                                    Capitalization rates may vary in different areas of a city for many reasons such as desirability of location, level of crime, and general condition of an area.
                                    </div>

                                    <div class="col-md-12" style={{paddingTop:'10px'}}>
                                     To find out the prevailing <b>capitalization rate</b> in a particular area, you can check with an appraiser or lender in that area. Or you can take the average capitalization rate of comparable properties recently sold in the area that are renting.
                                    </div>
                                    
                                    <div class="col-md-12" style={{paddingTop:'10px'}}>
                                    This program uses a target <b>capitalization rate</b> of <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b> or higher to determine the earning ability of the property. You can change this in settings to match your needs.
                                    </div>

                                    <div class="col-md-12" style={{paddingTop:'10px'}}>
                                    <b>Note:</b> The interest expenses (mortgage payments) are not deducted from the income when calculating the capitalization rate. This is mainly because this indicator measures the earning ability of the property regardless of how you finance it. Interest expenses are excluded so that the property valuation does not depend on the amount of debt used to purchase the property.

                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The higher the <b>capitalization rate</b>, the better, as this is the money that really flows into your pocket. Most investors would not go for a capitalization rate lower than <b>7%</b>.
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>capitalization rate</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                    <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                    
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Purchase Price       
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}
                                                    </div>

                                                </div>

                                          
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Monthly Rent         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT}
                                                    </div>

                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Vacancy Rate         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_VACANCY_RATE}%

                                                </div>

                                                </div>

                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Annual Expenses
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_ANNUAL_EXPENSES}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>
                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                    {/*  STEP 1 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Gross Annual Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Gross Annual Income = <br></br>
                                                       <b>Monthly Rent x 12</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Annual Income = <br></br>
                                                        <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT} x 12 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Annual Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>
                                        <br></br>

                                        {/*  STEP 2 */}
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 2:</span> <b>Calculate Vacancy and Credit Loss</b>
                                            </div>
                                        </div>

                                        
                                        <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Vacancy and Credit Loss = <br></br>
                                                       <b>(Gross Annual Income x Vacancy Rate) / 100</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Vacancy and Credit Loss = <br></br>
                                                        <b>({this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME} x {this.state.indicator.helpVariableMap.VAR_VACANCY_RATE}) / 100 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Vacancy and Credit Loss = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_VACANCY_CREDIT_LOSS}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>

                                        <br></br>


                                    {/*  STEP 3 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 3:</span> <b>Calculate Gross Operating Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Gross Operating Income = <br></br>
                                                        <b>Gross Annual Income - Vacancy Credit Loss</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_INCOME} - {this.state.indicator.helpVariableMap.VAR_VACANCY_CREDIT_LOSS}</b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_OPERATING_INCOME}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        <br></br>

       
                                    {/*  STEP 4 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 4:</span> <b>Calculate Net Operating Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Net Operating Income = <br></br>
                                                        <b>Gross Operating Income - Annual Expenses</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Net Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_OPERATING_INCOME} - {this.state.indicator.helpVariableMap.VAR_ANNUAL_EXPENSES} </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Net Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_NET_OPERATING_INCOME}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        <br></br>


       
                                    {/*  STEP 5 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 5:</span> <b>Calculate Capitalization Rate</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Capitalization Rate = <br></br>
                                                        <b>Net Operating Income / Purchase Price</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Capitalization Rate = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_NET_OPERATING_INCOME} / {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE} </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Capitalization Rate = <br></br>
                                                       <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        
                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Capitalization Rate benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"center",fontWeight:"bold"}}>
                                                PURCHASE PRICE<br></br>
                                                MONTHLY RENT<br></br>
                                                VACANCY RATE<br></br>
                                                EXPENSES
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                  {/* TIP */}
                  {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>2)</b> Increase the <span style={{fontWeight:'600'}}>monthly rent</span><br></br>     
                            <b>3)</b> Lower your <span style={{fontWeight:'600'}}>expenses</span><br></br>                    
                        </div>

                    </div>        
                }         

                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

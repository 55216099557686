import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles(theme => ({
  root: {
    width: 300 + theme.spacing(3) * 2
  },
  margin: {
    height: theme.spacing(3)
  }
}));

const PrettoSlider = withStyles({
  root: {
    color: "#004ec1", //"#52af77"
    height: 8
  },
  thumb: {
    height: 34,
    width: 34,
    backgroundColor: "#fff",
    border: "4px solid currentColor",
    marginTop: -14,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  track: {
    height: 3,
    borderRadius: 0
  },
  rail: {
    height: 3,
    borderRadius: 0,
    opacity: 1
  }
})(Slider);

export default function CustomizedSlider(props) {
  const classes = useStyles();


  return (
    <div className={classes.root} style={{ height: "2vh" }} name={props.name}>
      <PrettoSlider
        orientation="horizontal"
        aria-label="pretto slider"
        id={props.id}
        name={props.name}
        min={props.min}
        max={props.max}
        step={props.step}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange} 
      />
    </div>
  );
}
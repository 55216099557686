import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>cost-of-debt</b> indicator compares the percentage of the cost of debt in relation to the loan amount, which shows how much money you are paying the lender in interest relative to the total loan amount.
                                        </div>
                             
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The lower the <b>cost-of-debt</b>, the better. Basically, you want to pay the lender as little as possible in interest.
                                    <br></br><br></br>
                                    A good rule of thumb is to avoid paying more than <b>85%</b> of the loan amount in interest.
                                    <br></br><br></br>
                                    To achieve this goal, you have two options: negotiate a lower interest rate or choose a lower loan term (such as 15, 20, 30, or 40 years).

                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold", color:"#777777"}}>
                                        You can adjust your <b>cost-of-debt</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Loan Amount
                                                            {(this.state.indicator.property.price > 0 || 
                                                             this.state.indicator.property.downPayment > 0) &&        

                                                                <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                                    <table class="calculation-info-table">
                                                                        {this.state.indicator.property.price > 0 && 
                                                                            <tr>
                                                                                <td>Purchase Price</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td></td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.price)}</td>
                                                                            </tr>
                                                                        }

                                                                        {this.state.indicator.property.downPayment > 0 && 
                                                                            <tr>
                                                                                <td>Down Payment</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td style={{fontSize:'14px'}}>-</td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.downPayment)}</td>
                                                                            </tr>
                                                                        }
                                                                       
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}</b></td>
                                                                            </tr>

                                                                        </table>

                                                                    
                                                                    </div>
                                                                }
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Interest Rate
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_INTEREST_RATE}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Loan Term
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_LOAN_TERM}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row">

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Total Interest Payments
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_TOTAL_INTEREST}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                   
                                        {/* ---------------------  STEP 1 ------------------ */}
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate cost of debt</b>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px", paddingLeft:"25%"}}>

                                                    <table>
                                                        <tr>
                                                            <td style={{paddingTop:'20px'}}>Cost of Debt = </td>
                                                            <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>Total Interest <br></br>Payments</b></div>
                                                                 
                                                                <b>Loan Amount</b>

                                                            </td>
                                                            <td  style={{paddingTop:'20px'}}> x 100</td>
                                                        </tr>

                                                    </table>

                                                   
                                          
                                                    <div style={{paddingTop:'25px'}}>
                                                        <table>
                                                            <tr>
                                                                <td style={{paddingTop:'4px'}}>Cost of Debt = </td>
                                                                <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                    <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>{this.state.indicator.helpVariableMap.VAR_TOTAL_INTEREST}</b></div>
                                                                    
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}</b>

                                                                </td>
                                                                <td  style={{paddingTop:'4px'}}> x 100</td>
                                                            </tr>

                                                        </table>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                               {/* ---------------------  STEP 4 ------------------ */}


                                               <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 2:</span> <b>Results</b>
                                            </div>
                                            </div>

                                                    <div class="row">
                                                        <div class="col-md-12 indicator-info-formula-table">
                                                            <div class="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:"30px"}}>
                                                                <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                                Cost of Debt = 
                                                                </div>
                                                                <div class="col-md-4 indicator-info-formula-table-col1">
                                                                    <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                            </div>
                                                                      

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Cost of Debt benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                INTEREST RATE<br></br>
                                                LOAN TERM<br></br>
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                {/* TIP */}
                {this.state.indicator?.meetsBenchmark == false &&
                                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>interest rate</span><br></br>                     
                        </div>

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>2)</b> Choose a lower <span style={{fontWeight:'600'}}>loan term</span>
                            <br></br>
                            &nbsp;&nbsp;&nbsp; (15 year, 20 years, 30 years, 40 years and so on)
                            <br></br>                     
                        </div>

                    </div>        
                }            

                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

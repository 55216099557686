
/*
    The values in this function should really be in backend for 
    security reasons and exposed as service endpoint.
*/

const plans = {
    "lite" : {
        "name":"Lite",
        "price": {"monthly":19.95,"annually":16.56},
        "logins": 1,
        "profiles": 2,
        "properties": 20,
        "advancedSeaches": 5
    },
    "standard" : {
        "name":"Standard",
        "price": {"monthly":49.95,"annually":41.46},
        "logins": 1,
        "profiles": 5,
        "properties": 100,
        "advancedSeaches": 10
    },
    "advanced" : {
        "name":"Advanced",
        "price": {"monthly":99.95,"annually":82.96},
        "logins": 1,
        "profiles": 20,
        "properties": "unlimited",
        "advancedSeaches": 30
    },
    "enterprise" : {
        "name":"Enterprise",
        "price": {"monthly":199.95,"annually":165.96},
        "logins": 10,
        "profiles": 40,
        "properties": "unlimited",
        "advancedSeaches": 80
    }
}

const recaptchaSiteKey = "6LccFHIlAAAAAOWSX_rItSAFqBcq4GXr09r5bEg9";

const numberOfTrialDays = 7;

const maxTrialPropertiesToShow = 1; // After the limit is exceeded they will be blurred

const maxTrialPropertiesToAllowToAdd = 1; //The maximum number of properties a user can add during trial by clicking the ADD button

module.exports = {
  
    stripePublishablecKey: "pk_test_51MGXlGCNdI4GwICQ4EnVZmjpguZPaSmTonEjDLMbTNUU7x6AIN8u3B4CU8drHClG1yOS6ZvMzaSeJA3148In5Di600Gc3krBj6",   //Test Public Key
    
    //stripePublishablecKey: "",   //Production Public Key
 
    plans: plans,

    numberOfTrialDays: numberOfTrialDays,

    maxTrialPropertiesToShow : maxTrialPropertiesToShow,
    
    maxTrialPropertiesToAllowToAdd: maxTrialPropertiesToAllowToAdd,

    recaptchaSiteKey:recaptchaSiteKey
};
import React from 'react';
import { Image, View, Text, StyleSheet } from '@react-pdf/renderer';
import IndicatorValue from './IndicatorValue';

const IndicatorTiles = ({ indicators }) => {
  return (
    <View style={styles.boxesWrapper}>
      {indicators.map((item, index) => (
  
        <View key={index} style={styles.indicatorsBox}>
          {item.disabled == false &&
          <Image
            src={item.meetsBenchmark ? '/images/passed-tile.png' : '/images/failed-tile.png'}
            style={styles.tile}
          />
          }
          {item.disabled == true &&
          <Image
            src={'/images/disabled-tile.png'}
            style={styles.tile}
          />
          }

          {item.disabled == false &&
          <View style={[styles.tileContent, (!item.meetsBenchmark) && styles.textWhite]}>
            <Text style={styles.text}>{item.indicatorName}</Text>
            <Text style={[styles.text, styles.tileValue]}>
              <IndicatorValue value={item.indicatorValue} valueType={item.valueType} />
            </Text>
          </View>
          }

          {item.disabled == true &&
          <View style={[styles.tileContent]}>
            <Text style={styles.text}>{item.indicatorName}</Text>
            <Text style={[styles.text, styles.tileValue]}>
              N/A
            </Text>
          </View>
          }


        </View>
        
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  boxesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 4,
    marginHorizontal: -4
  },
  indicatorsBox: {
    margin: '0.5%',
    flexBasis: '19%',
    position: 'relative'
  },
  tile: {
    width: '100%'
  },
  tileContent: {
    position: 'absolute',
    padding: 8,
    fontSize: 15,
    width: '100%',
    height: '100%',
    top: 0,
  },
  textWhite: {
    color: '#fff',
  },
  text: {
    textAlign: 'center'
  },
  tileValue: {
    position: 'absolute',
    bottom: 24,
    left: 6,
    right: 0
  }
});

export default IndicatorTiles;
import React, { Component } from 'react';

import { Helmet } from "react-helmet";

import ProfileEditDialog from '../dialogs/ProfileEditDialog';

import { FileEarmarkText } from 'react-bootstrap-icons';
import { Trash } from 'react-bootstrap-icons';

import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

//import { capitalizeWord } from '../utilities/AppUtility';

import Dialog from 'react-bootstrap-dialog'

import {OverlayTrigger,Tooltip} from "react-bootstrap";

import { getPlanInfo, convertPlanInfoUnlimitedToNumbers, upperCaseFirstLetter } from '../utilities/AppUtility';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
});

class Profiles extends Component {

    constructor(props) {
        super(props);

            this.state = {
                profiles :[],
                uiLoading: true
            };     

            this.profileEditDialogRef = React.createRef();

    }

    componentWillMount = () => {

     this.loadProfiles();

    };

    reloadPage()
    {
        localStorage.setItem('ProfileListStale',"Y");
        localStorage.setItem('CurrentPage', 'profiles');
        window.location.reload(false);
    }

    loadProfiles()
    {
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

        axios
        .get('/api/profiles')
        .then((response) => {

            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
            {
                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            }

            //console.log(JSON.stringify(response.data));

            this.setState({
                profiles: response.data,
                uiLoading: false
            });
        })
        .catch((error) => {
            console.log(error);

            if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
            } else
            {
                this.setState({
                    uiLoading: false
                });
            }

            });
    };

    deleteProfileFromDB(id)
    {
        const profile = {
            id: id
          };
      
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

        axios
            .post('/api/deleteProfile', profile)
            .then((response) => {

                if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

                console.log("Completed deleting profile");

                localStorage.setItem('ProfileListStale',"Y");
                localStorage.setItem('CurrentPage', 'profiles');
                window.location.reload(false);

                this.reloadPage(); //We only need to reload page because we want the changes reflected in the top profile dropdown
                //this.loadProfiles();
            })
            .catch((error) => {
                console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
                }
                
            });
    };  

    
  FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }


    openProfileEditDialog(profile,event) {

        event.preventDefault();

        //console.log(this.props.user);
        let planInfo = getPlanInfo(this.props.user.planName);
        planInfo = convertPlanInfoUnlimitedToNumbers(planInfo);

        let message= "You have exceeded the number of profiles you can have for this plan. Please delete some of your existing profiles or go to MY ACCOUNT and upgrade your plan.";
        let profiles = planInfo.profiles;

        if (this.props.user.planType === 'trial')
        {
            profiles= 1;
            message= "You have exceeded the number of profiles you can have for the trial version, you must UPGRADE to a subscription plan to get all features.";
        }
        
        if (this.state.profiles.length >= profiles && profile == null)
        {
            this.dialog.show({
                title: 'Plan Limit Exceeded',
                body: message,
                actions: [
                  Dialog.OKAction(() => {
                    //console.log('OK was clicked!')
                    
                  })
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                  dialog.hide()
                  
                }
              });

        } else
        {
            var dialog = this.profileEditDialogRef.current;
            dialog.PopulateDialog(profile,this);
        }

    }



    deleteProfile(id,event) {

        event.preventDefault();

        //this.dialog.showAlert('Hello Dialog!');

        this.dialog.show({
            title: 'Delete Confirmation',
            body: 'Do you want to delete this profile? All associated properties and advanced searches will be deleted.',
            actions: [
              Dialog.CancelAction(),
              Dialog.OKAction(() => {
                //console.log('OK was clicked!')
                this.deleteProfileFromDB(id);
              })
            ],
            bsSize: 'small',
            onHide: (dialog) => {
              dialog.hide()
              
            }
          })
    }
    

    render() {

        const { classes } = this.props;

        if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
            return (

                <div>
                    <div classX="my-property-heading">
                        <h1 class="dashboard-heading" style={{paddingBottom:'2px',marginBottom:'2px'}}>
                            Profiles
                        
                            <span style={{paddingLeft:'10px',fontSize:'18px',fontWeight:'plain',color:'#949494'}}>
                            <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 150, hide: 100 }} 
                                            trigger={["hover","click"]}
                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltipWhite">
                                            
                                                <div className='helpText'>
                                                    {this.state.profiles.length} advanced searches
                                                </div>
                                            
                                            </Tooltip>}
                                            >
                                                <span>
                                ({this.state.profiles.length})
                                </span>

                                </OverlayTrigger>
                            </span>

                        </h1>      
                        <div class="page-short-description view-mode-padding">
                        Profiles are used to organized properties together. Perhaps you are a real estate agent and want to create a profile for each of your clients. Or perhaps you want to create a profile for each city or state where you might be looking for properties.
                        </div>               
                    </div>

                    <section class="my-alerts-content"  style={{paddingTop:'10px'}}>
        
                        <div class="row">
                            <div class="col-md-12" style={{paddingLeft:'0px',marginLeft:'0px'}}>
                    
                            <button type="button" class="btn btn-primary add-btn" onClick={(e) => this.openProfileEditDialog(null, e)} style={{marginBottom:"10px",marginLeft:"16px"}}>
                                    ADD
                            </button>
                                
                    
    {/* ======================================== Trial table Begins ==================================== */}
                                <div class="top-row-list-properties">
                                            {this.state.profiles.map((profile) => (
                                                        <div class="row properties-row">
                                                            <div class="col-md-12">
                                                            <div class="custom-control custom-checkbox">
                                                                {/*   <input type="checkbox" class="custom-control-input" id="customCheck2" checked /> */}
                                                                    <label class="row-container custom-label" for="customCheck2">
                                                                        <div class="row"  style={{borderBottom:"none"}}>
                                                            
                                                                            <div class="col-lg-12" style={{paddingTop:"5px",marginTop:"5px"}}>
                                                                                <div class="row">
                                                                                    <div class="col-md-3">
                                                                                        <p class="rate-heading table-header-text-center">Profile Name</p>
                                                                                        <p class="green-text"><h2 class="property-heading" style={{fontSize:"20px",fontWeight:"400"}}><a href="#!" style={{textDecoration:"underline"}}  onClick={(e) => this.openProfileEditDialog(profile, e)}>{profile.profileName}</a></h2></p>
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        <p class="rate-heading text-center">Budget</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>{this.FormatMoney(profile.budget)}</p>
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        <p class="rate-heading text-center">Bills</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>{this.FormatMoney(profile.monthlyBills)}</p>
                                                                                    </div>
                                                                                    <div class="col-md-1">
                                                                                        <p class="rate-heading text-center">Income</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>{this.FormatMoney(profile.monthlyGrossIncome)}</p>
                                                                                    </div>
                                                                            
                                                                                    <div class="col-md-2">
                                                                                        <p class="rate-heading text-center">Financing Preference</p>
                                                                                        <p class="rate-value text-center green-value" style={{fontSize:"18px"}}>{upperCaseFirstLetter(profile.financingPreference)}</p>
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        <p class="rate-heading text-center">Actions</p>
                                                                                        <p class="rate-value text-center" style={{fontSize:"18px"}}>
                                                                                        <h3 class="total-value">
                                                                                            <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openProfileEditDialog(profile, e)}><FileEarmarkText className="ml-2" style={{height:"24px"}}/> </a>
                                                                                                &nbsp;
                                                                                            <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.deleteProfile(profile.id, e)}><Trash className="ml-2"  style={{height:"24px"}}/></a>
                                                                                        </h3>
                                                                                        </p>
                                                                                    </div>
                                                                                
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row" style={{borderTop:"none",height:"1px",marginBottom:"0px",paddingBottom:"0px"}}>

                                                                        </div>
                                                                    </label>
                                                                </div>                                                         
                                                        </div>


                                                    </div>
                                                

                                            ))}    

                                        <Dialog ref={(component) => { this.dialog = component }}/>     
                                </div>
    
    {/* ========================================= Trial Table Ends ======================================*/}

                                <ProfileEditDialog ref={this.profileEditDialogRef}></ProfileEditDialog>
                            </div>
                        </div>
                    </section>

        
                    <Helmet>
                        <script src="js/grid-functions.js" type="text/javascript" />
                    {/*     <script src="vendor/jquery/jquery.min.js"></script> */}
                        <script src="js/sb-admin-2.min.js"></script>
                        <script src="vendor/bootstrap/bootstrap.bundle.min.js"></script>
                    </Helmet>

                </div>


            );
        }
    }
}

export default (withStyles(styles)(Profiles));

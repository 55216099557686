import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { authMiddleWare } from '../utilities/Auth';

import * as BLO from '../utilities/BLO';
import AnalysisDialog from '../dialogs/AnalysisDialog';

import axios from 'axios';
import {
	ComposableMap,
	ZoomableGroup,
	Geographies,
	Geography,
	Marker,
} from 'react-simple-maps';

import withStyles from '@material-ui/core/styles/withStyles';

const markerColors = {
	0: '#FF0000',
	1: '#FF0000',
	2: '#FF4500',
	3: '#FF6600',
	4: '#FFA500',
	5: '#FFD700',
	6: ' #FFFF00',
	7: '#32CD32',
	8: '#008000',
	9: '#006400',
};



const styles = (theme) => ({

	propertyCard: {
		border: '1px solid #ccc',
		borderRadius: '5px',
		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
		maxWidth: '150px',
		padding: '8px',
		backgroundColor: '#fff',
		marginBottom: '4px'
	},
	
	propertyImage: {
		width: '100%',
		height: 'auto',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		borderRadius: '4px'
	},
	
	propertyPrice: {
		marginTop: '8px',
		marginBottom: '8px',
		fontSize: '16px',
		color: '#ff5733',
		fontWeight: 'bold',
		textAlign:'left'
	},
	
	propertyAddress: {
		fontSize: '12px',
		color:'#000000',
		textAlign:'left'
	}
	,
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
	
});

class PropertiesMap extends Component {

	state = {
	    properties:[],
        uiLoading: true       
    };

	constructor(props) {
		super(props);
		this.state = {
			scaleFactor: 1,
			properties:[],
			uiLoading: true     
		};

		this.analysisDialogRef = React.createRef();
	}


	componentWillMount = () => {

        this.loadProperties();

		if (this.props.settings == null || (this.props.settings != null && this.props.settings.benchmarkSettings == null))
        {
            this.loadSettings();
        }

	}

	loadProperties()
    {
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

        axios
        .get('/api/properties')
        .then((response) => {
		

            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
            {
                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            }
			
            this.setState({
                properties: response.data,
                uiLoading: false
            });
			
        })
        .catch((error) => {

		    console.log(error);
            if (error && error.response && error.response.status && error.response.status === 403) {
                localStorage.setItem('SessionExpired',"Y");
                //window.location.reload();
                window.location.href = '/login';
            } else
            {
                this.setState({
                    uiLoading: false
                });
            }
            
        });
		

    }
	
	openAnalysisDialog(property,event) {

        event.preventDefault();

		const toolTip = document.getElementById("tooltip-"+property.id);

		toolTip.style.display = "none";
		toolTip.style.visibility = "hidden";


        var dialog = this.analysisDialogRef.current;
        dialog.PopulateDialog(property,this.props.settings,this.props.profileId,this.props.profile);
    }

	loadSettings()
    {
        try
        {
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
            axios
                .get('/api/settings')
                .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }
    
                    const settings = response.data;
                    const benchmarkSettings = BLO.getIndicatorBenchmarkSettings(settings);
                    settings.benchmarkSettings = benchmarkSettings;

                    //console.log("benchmarkSettings:");
                    //console.log(JSON.stringify(benchmarkSettings));

                    //console.log("Account Settings:");
                    //console.log(JSON.stringify(settings));

                    this.props.settings = settings;

                    this.setState({
                        settings: settings
                    });
                    
                })
                .catch((error) => {
                    console.log(error);
                    if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                    }
                });
            } catch(error)
            {
              console.log(error);   
            }

    }

	getIndicatorCountList(property)
    {

        let indicatorList = [];

        let map = new Map();
        map.set('Rental PropertyGRM', 1);
        map.set('Rental PropertyCAP', 2);
        map.set('Rental PropertyCOC', 3);
        map.set('Rental PropertyPI', 4);
        map.set('Rental PropertyIRR', 5);
        map.set('Rental PropertyDCR', 6);
        map.set('Rental PropertyBER', 7);
        map.set('Rental PropertyLVR', 8);
        map.set('Rental PropertyNCF', 9);

        map.set('Flipping PropertyR70', 1);
        map.set('Flipping PropertyKRI', 2);
        map.set('Flipping PropertyDCR', 3);
        map.set('Flipping PropertyLVR', 4);
        map.set('Flipping PropertyROI', 5);
        map.set('Flipping PropertyNP', 6);
        map.set('Flipping PropertyMVP', 7);
        map.set('Flipping PropertyPPQ', 8);
        map.set('Flipping PropertyBUDGET', 9);

        map.set('Primary HomeBER', 1);
        map.set('Primary HomeFE', 2);
        map.set('Primary HomeDCR', 3);
        map.set('Primary HomeLVR', 4);
        map.set('Primary HomeCOD', 5);
        map.set('Primary HomeMVP', 6);
        map.set('Primary HomePPQ', 7);
        map.set('Primary HomePTR', 8);
        map.set('Primary HomeBUDGET', 9);

        let indicatorMap = new Map();

        
        if (property != null && property.hasOwnProperty('indicators'))
        {
            let i = 0;
            for (let indicator of property.indicators) {

                
                let status = "";
                if (indicator.meetsBenchmark)
                {
                    status = "passed";
                }else
                {
                    status = "failed";
                }

                if (this.isIndicatorDisabled(property,indicator))
                {
                    status = "disabled"
                }

                indicatorMap.set(map.get(property.investmentType+indicator.indicatorAcronym),{"indicatorName":indicator.indicatorName,"status":status});
            }

            //sort by value
             let sortedMapValues = new Map(
                 [...indicatorMap.entries()].sort((a, b) => String(b[1]).localeCompare(a[1]))
             );
           
             //add to list
             for (let record of sortedMapValues.values())
             {
                indicatorList.push(record);
             }
           
        }

        return indicatorList;	
    }

	isIndicatorDisabled (property,indicator){ 
    
        let isDisabled = false;

        //console.log(indicator.indicatorAcronym);
        if (
            (property.financingType !== "Cash") || 
            (property.financingType === "Cash" && indicator.indicatorAcronym !== "DCR" && indicator.indicatorAcronym !== "BER" && indicator.indicatorAcronym !== "LVR" && indicator.indicatorAcronym !== "FE" && indicator.indicatorAcronym !== "COD") 
        )
        {
            isDisabled = false;
        } else
        {
            isDisabled = true;
        }                                         

        return isDisabled;
    };

	onMouseMoveEnd({ zoom }) {
		this.setState({ scaleFactor: zoom });
	}

	render() {

		const { classes } = this.props;

		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<div style={{backgroundColor:"#ebf8ff"}}>
				<ComposableMap>
					<ZoomableGroup onMoveEnd={(event) => this.onMouseMoveEnd(event)}>
						<Geographies
							geography={'/countries.geo.json'}
							disableOptimization={true}
						>
							{({ geographies }) =>
								geographies.map((geo) => (
									<Geography
										key={geo.rsmKey}
										geography={geo}
										fill='#0088b0'
										stroke='#e3e3e3'
									/>
								))
							}
						</Geographies>
						{/*
						{this.state.properties.map(
							({
								id,
								lat,
								lng,
								greenIndicatorsFound,
								price,
								propertyImageHighResolution,
								address,
								city,
								state,
								country,
								zipcode,
							}) => {

							*/}
							{this.state.properties.map((property,i) => (	
							/*(	return ( */
									<OverlayTrigger
										key={property.id} 
										placement="auto"
										trigger={["click"]}
										overlay={
											<Tooltip id={`tooltip-${property.id}`}>
												<a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openAnalysisDialog(property, e)}>
													<div class= {classes.propertyCard}>
													<img
															class={classes.propertyImage}
															src={property.propertyImageHighResolution} 
															alt=''
														/>
														<div class={classes.propertyPrice}>
															{Intl.NumberFormat('en-US', {
																style: 'currency',
																currency: 'USD',
															}).format(property.price)}
														</div>
														<div class="indicators" style={{textAlign:"left","paddingBottom":'5px'}}>
                                                                                        
                                                                                            

																						{this.getIndicatorCountList(property).map((indicatorCheck, index) => ( 
																							
																							<span>
																							{indicatorCheck.status == "passed" && 
																							<i class="fas fa-check-square greenish-tick"></i>
																							}

																							{indicatorCheck.status == "failed" && 
																							<i class="fas fa-check-square red-tick"></i>
																							}

																							{indicatorCheck.status == "disabled" && 
																							<i class="fas fa-check-square gray-tick"></i>
																							}

																							</span>

																						))}
																							
																						</div>
														<div class={classes.propertyAddress}>
															{property.address}, {property.city}, {property.state} {property.zipcode}, {property.country}
														</div>
													</div>
												</a>
											</Tooltip>
										}
									>
										<Marker
											key={property.id}
											coordinates={[property.lng, property.lat]}
											marker={{
												coordinates: [property.lng, property.lat],
												population: property.greenIndicatorsFound + 1,
											}}
										>
											<circle
												id='mapPropertyOverview'
												fill={markerColors[property.greenIndicatorsFound]}
												r={property.greenIndicatorsFound + 1}
												style={{
													transform: `scale(${2 / (this.state.scaleFactor + 1)})`,
												}}
											/>
										</Marker>
									</OverlayTrigger>
							/*	);
							} */
							)
						)}
					</ZoomableGroup>
				</ComposableMap>

				<AnalysisDialog ref={this.analysisDialogRef}></AnalysisDialog> 
				</div>
			);
		}
	}
}

export default (withStyles(styles)(PropertiesMap));

import React, { Component } from 'react';

import {LegalDisclaimerPageHTML} from '../static/SignUp_LegalDisclaimerPageHTML';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#2451b3'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class main extends Component {

	state = {
        displayScreen:false,
		loading: false,
	};

	constructor(props) {
		super(props);

		this.indexPageElement = null;

	}

	goHome = () => {
		window.location.href ="/";
	};

	componentWillMount = () => {
	}

	componentDidMount= () => {
	
	  };


	goHome = () => {
		window.location.href ="/";
	};
	
	render() {

		const { classes } = this.props;
		const { errors, loading } = this.state;

			return (
				<div>
				
					<LegalDisclaimerPageHTML handleClick={this.buttonClicked} goHome={this.goHome} showButton={false} user={this.state.user} loading={this.state.loading} classes={classes}/>
				
				</div>
				
	)};

}

export default withStyles(styles)(main);

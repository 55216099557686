import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardContent} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { authMiddleWare } from '../utilities/Auth';
import axios from 'axios';

import checkmark from '../pages/img/icons/checkmark.png';

import visaLogo from  '../pages/img/icons/visa.png';
import discoverLogo from  '../pages/img/icons/discover.png';
import mastercardLogo from  '../pages/img/icons/mastercard.png';
import americanexpressLogo from  '../pages/img/icons/american-express.png';

import { FileEarmarkText } from 'react-bootstrap-icons';
import { Trash } from 'react-bootstrap-icons';

import ConfirmDialog  from '../dialogs/ConfirmDialog';

import { upperCaseFirstLetter } from '../utilities/AppUtility';

import Dialog from '@material-ui/core/Dialog';
import Dialog2 from 'react-bootstrap-dialog'

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CheckoutForm from "./CheckoutForm";

import LoginEditDialog from '../dialogs/LoginEditDialog';

import { getPlanInfo } from '../utilities/AppUtility';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const { stripePublishablecKey, plans } = require('../utilities/Constants');

const stripePromise = loadStripe(stripePublishablecKey);

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});


class SubscriptionDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.parentState.name,
			email: this.props.parentState.email,
			phoneNumber: this.props.parentState.phoneNumber,
			country: this.props.parentState.country,
			blogUrl: this.props.parentState.blogUrl,
			type: this.props.parentState.type,
			hasSubscription: this.props.parentState.hasSubscription,
			stripeCustomerId: this.props.parentState.stripeCustomerId,
			stripeSubscriptionId: this.props.parentState.stripeSubscriptionId,
			user: props.parentState.user,
			uiLoading: true,
			confirmOpen: false,
			paymentMethod: {},
			openEditPaymentDialog : false,
			disableButton: '',
			paymentHistory: null,
			paymentHistoryLoading: true,
			accountLogins: null,
			accountLoginsLoading: true
		};

		this.loginEditDialogRef = React.createRef();

	}

	componentWillMount = () => {
		
		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
		axios
			.post('/api/subscription', {
				subscriptionId: this.state.stripeSubscriptionId
			  })
			.then((response) => {

				if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

				//alert(response.data);

				const subscriptionSatus = response.data.status;

				axios
				.post('/api/getCustomerPaymentMethod', {
					stripeCustomerId: this.state.stripeCustomerId
				})
				.then((responsePaymentMethod) => {
			
					const paymentMethod = responsePaymentMethod.data;

				//	console.log("PAYMENT METHOD:");
					console.log(JSON.stringify(paymentMethod));
				//	alert(JSON.stringify(paymentMethod));

					
					this.setState({
						stripeSubscriptionStatus: subscriptionSatus,
						paymentMethod:paymentMethod,	
						uiLoading: false
					});
	
					if (subscriptionSatus === 'canceled')
					{
						this.setState({
							disableButton: 'true'
						});
					}

					this.loadPaymentHistory(paymentMethod.customer);

					if (this.state.user.planName === "enterprise"  && this.state.user.planType === "subscription" && this.state.user.accountType ==="corporate" && this.state.user.role ==="admin")
					{
						this.loadAccountLogins(this.state.user.accountNumber);
					}
					
				})
				.catch((error) => {
					console.log(error);
					if (error && error.response && error.response.status && error.response.status === 403) {
						localStorage.setItem('SessionExpired',"Y");
						//window.location.reload();
						window.location.href = '/login';
					} 
				});

			})
			.catch((error) => {
				console.log("An error occurred while retrieving subscription:");
				console.log(error);
				if (error && error.response && error.response.status && error.response.status === 403) {
					localStorage.setItem('SessionExpired',"Y");
					//window.location.reload();
					window.location.href = '/login';
				} 
			});

	};


	loadPaymentHistory = (customerId) => {

		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
		axios
			.post('/api/getPaymentHistory', {
				customerId:customerId
			  })
			.then((response) => {

				if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

				//alert(response.data);

				console.log(JSON.stringify(response.data));

				const paymentHistory = response.data;

				this.setState({
					paymentHistory: paymentHistory,
					paymentHistoryLoading: false
				});

			})
			.catch((error) => {
				console.log(error);
				if (error && error.response && error.response.status && error.response.status === 403) {
					localStorage.setItem('SessionExpired',"Y");
					//window.location.reload();
					window.location.href = '/login';
				} 
			});
	}

	loadAccountLogins = (accountNumber) => {

		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
		axios
			.post('/api/accountLogins', {
				accountNumber:accountNumber
			  })
			.then((response) => {
		//		alert(JSON.stringify(response.data));

				if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
				{
					localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
				}

				console.log(JSON.stringify(response.data));

				const accountLogins = response.data;

				this.setState({
					accountLogins: accountLogins,
					accountLoginsLoading: false
				});

			})
			.catch((error) => {
				console.log(error);
				if (error && error.response && error.response.status && error.response.status === 403) {
					localStorage.setItem('SessionExpired',"Y");
					//window.location.reload();
					window.location.href = '/login';
				} 
			});
	}

	 
	formatMoney(value)
	{
	  let dollarUS = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	  });
  
  
	  let formattedValue = dollarUS.format(value/100);
  
	  if (formattedValue.endsWith(".00"))
	  {
		formattedValue = formattedValue.substring(0, formattedValue.length-3);
	  }
	  //alert(formattedValue);
  
	  return formattedValue;
	}

	formatDate(value)
	{
	  const date = new Date(value * 1000); //expects milliseconds

	  let formattedValue =[
		this.padTo2Digits(date.getMonth() + 1),
		this.padTo2Digits(date.getDate()),
		date.getFullYear(),
	  ].join('/');

	
	  return formattedValue;
	}

	padTo2Digits(num) {
		return num.toString().padStart(2, '0');
	}

	closePaymentDialog = () => {

		this.setState({ openEditPaymentDialog: false});
	}

	showEditPaymentDialog = (flag) => {

		this.setState({ openEditPaymentDialog: flag });
	}

	setConfirmOpen = (flag) => {

		this.setState({ confirmOpen: flag });
	}

	cancelSubscription = () => {

		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
		axios
			.post('/api/cancelSubscription', {
				subscriptionId: this.state.stripeSubscriptionId
			  })
			.then((response) => {
				//alert(response.data);

				if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

				this.setState({
					stripeSubscriptionStatus: 'cancelled',
					disableButton: 'true'	
				});

				 //Call backend code to update user's hasSubscription flag
				 authMiddleWare(this?.props?.history);
				const authToken = localStorage.getItem('AuthToken');
				const refreshToken = localStorage.getItem('RefreshToken');
				axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
				 axios
				   .post('/api/user', {
					stripeSubscriptionId: ''
				   })
				   .then((response) => {

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					 //alert("Updated record successfully");
					 
					 window.location.reload(false);

				   })
				   .catch((error) => {
					console.log(error);
					if (error && error.response && error.response.status && error.response.status === 403) {
						localStorage.setItem('SessionExpired',"Y");
						//window.location.reload();
						window.location.href = '/login';
					} else {
					 window.location.reload(false);
					}
					 
			 
				   });


			})
			.catch((error) => {
				console.log(error);
				if (error && error.response && error.response.status && error.response.status === 403) {
					localStorage.setItem('SessionExpired',"Y");
					//window.location.reload();
					window.location.href = '/login';
				} 
			});

	}

	getPlanPrice= (planName) => {
	   
		let formattedPrice = "";
 
		if (planName != null)
		{
			let dollarUS = Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
			  });
		  
			  const price = plans[planName].price;
			  formattedPrice = dollarUS.format(price);
		}
 
		return formattedPrice;
	 };

	 getPlanName= (planName) => {
	   
		let name = "";

		if (planName != null)
		{
			name = plans[planName].name;
		}

		return name;
	 };

	goToUpGrade = (event) => {
    
		//    if (this.state.user.planName !=='')
		//    {
		//        window.location.href ="/signup/terms-and-conditions";
	
		//    }else
		//    {
				//this.state.props.history.push('/pricing');
				window.location.href ="/pricing";
		 //   }
	}

	openLoginEditDialog(login,event) {

        event.preventDefault();

		const planInfo = getPlanInfo(this.state.user.planName);

		let logins = planInfo.logins;
		let message= "You have exceeded the number of logins you can have for this plan. Please delete some of your existing logins or upgrade your plan.";

        if (this.state.user.planType === 'trial')
        {
            logins = 1;
            message= "You have exceeded the number of logins you can have for the trial version, you must UPGRADE to a subscription plan to get all features.";
        }
		
        if (this.state.accountLogins.length >= logins)
        {
            this.dialog.show({
                title: 'Plan Limit Exceeded',
                body: message,
                actions: [
                  Dialog2.OKAction(() => {
                    //console.log('OK was clicked!')
                    
                  })
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                  dialog.hide()
                  
                }
              });

        } else
        {
        	var dialog = this.loginEditDialogRef.current;
        	dialog.PopulateDialog(login,this);
		}
    }

    deleteLogin(user,event) {

        event.preventDefault();

     //   this.dialog.showAlert('Hello Dialog!');

        this.dialog.show({
            title: 'Delete Confirmation',
            body: 'Do You Want To Delete This Login?',
            actions: [
              Dialog2.CancelAction(),
              Dialog2.OKAction(() => {
                //console.log('OK was clicked!')
                this.deleteUser(user);
              })
            ],
            bsSize: 'small',
            onHide: (dialog) => {
              dialog.hide()
              
            }
          })
    }

	deleteUser(user)
    {
        const userData = {
			email:user.email
          };
      
		console.log(JSON.stringify(userData));  

        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 

        axios
            .post('/api/deleteAccountLogin', userData)
            .then((response) => {
				
				if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

                console.log("Completed deleting login");

                this.reloadPage(); //We only need to reload page because we want the changes reflected in the top profile dropdown
                
            })
            .catch((error) => {
                console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
					window.location.href = '/login';
                }
                
            });
    };  

	reloadPage() {
		window.location.reload(false);
	}

	render() {
		const { classes, ...rest } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<div>

					{(this.state.user.role !== 'admin' || (this.state.user.role === 'admin' && this.state.user.planName !== 'enterprise')) && 
						
						<div class="row properties-row" style={{paddingLeft:'0px', marginLeft:'0px'}}>
											<div class="col-md-12" style={{paddingLeft:'0px', marginLeft:'0px'}}>
											<div class="custom-control custom-checkbox" style={{paddingLeft:'0px', marginLeft:'7px'}}>
												{/*   <input type="checkbox" class="custom-control-input" id="customCheck2" checked /> */}
													<label class="row-container custom-label" for="customCheck2">
														<div class="row"  style={{borderBottom:"none"}}>
											
															<div class="col-lg-12" style={{paddingTop:"5px",marginTop:"5px"}}>
																<div class="row">
																	<div class="col-md-2">
																		<p class="rate-heading">Name</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{this.state.user.name}</p>
																	</div>
																	<div class="col-md-4">
																		<p class="rate-heading">Email</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{this.state.user.email}</p>
																	</div>
																	<div class="col-md-2">
																		<p class="rate-heading">Role</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{this.state.user.role}</p>
																	</div>
																	<div class="col-md-3">
																		<p class="rate-heading">Last Login</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{this.state.user.lastLoginDate}</p>
																	</div>
																	
															</div>
															</div>
														</div>
														<div class="row" style={{borderTop:"none",height:"1px",marginBottom:"0px",paddingBottom:"0px"}}>

														</div>
													</label>
												</div>                                                         
										</div>


									</div>
					}

					{/* *********** LOGINS ************/}
					{!this.state.accountLoginsLoading && this.state.user.role === 'admin'  && this.state.user.planType === 'subscription'  && this.state.user.planName === 'enterprise' && 
					<div>
						<h1 class="accountLabel">Logins</h1>
						<p class="accountLabel">You can create new logins for members in your organization.</p>
					
						<button type="button" class="btn btn-primary add-btn" onClick={(e) => this.openLoginEditDialog(null, e)} style={{marginBottom:"10px",marginLeft:"0px"}}>
                            ADD
                        </button>
						<div class="top-row-list-properties">
							{this.state?.accountLogins?.map((login) => (
										<div class="row properties-row" style={{paddingLeft:'0px', marginLeft:'0px'}}>
											<div class="col-md-12" style={{paddingLeft:'0px', marginLeft:'0px'}}>
											<div class="custom-control custom-checkbox" style={{paddingLeft:'0px', marginLeft:'7px'}}>
												{/*   <input type="checkbox" class="custom-control-input" id="customCheck2" checked /> */}
													<label class="row-container custom-label" for="customCheck2">
														<div class="row"  style={{borderBottom:"none"}}>
											
															<div class="col-lg-12" style={{paddingTop:"5px",marginTop:"5px"}}>
																<div class="row">
																	<div class="col-md-2">
																		<p class="rate-heading">Name</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{login.name}</p>
																	</div>
																	<div class="col-md-4">
																		<p class="rate-heading">Email</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{login.email}</p>
																	</div>
																	<div class="col-md-2">
																		<p class="rate-heading">Role</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{login.role}</p>
																	</div>
																	<div class="col-md-2">
																		<p class="rate-heading">Last Login</p>
																		<p class="rate-value green-value" style={{fontSize:"18px"}}>{login.lastLoginDate}</p>
																	</div>
																	{this.state.user.email !== login.email && 
																	<div class="col-md-2">
																		<p class="rate-heading text-center">Actions</p>
																		<p class="rate-value text-center" style={{fontSize:"18px"}}>
																		<h3 class="total-value">
																			<a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openLoginEditDialog(login, e)}><FileEarmarkText className="ml-2" style={{height:"24px"}}/> </a>
																				&nbsp;
																			<a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.deleteLogin(login, e)}><Trash className="ml-2"  style={{height:"24px"}}/></a>
																		</h3>
																		</p>
																	</div>
																	}

																	{this.state.user.email === login.email && 
																	<div class="col-md-2">
																		<p class="rate-heading text-center">(ME)</p>
																	</div>
																	}
														
															</div>
															</div>
														</div>
														<div class="row" style={{borderTop:"none",height:"1px",marginBottom:"0px",paddingBottom:"0px"}}>

														</div>
													</label>
												</div>                                                         
										</div>


									</div>
								

							))}    

						</div>

						<LoginEditDialog ref={this.loginEditDialogRef} user={this.state.user}></LoginEditDialog>

					</div>
					}
					{/* ************ SUBSCRIPTION *************/}
					{this.state.user.role === 'admin' && 
					<div>
						<h1 class="accountLabel">Your Subscription</h1>
						<p class="accountLabel">Manage your subscription.</p>
						
						<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Card className={classes.root} variant="outlined">
										<CardContent>
											<Typography variant="h5" component="h2">
												<span style={{color:"#e37627",fontWeight:"500"}}>{this.getPlanName(this.state?.user?.planName)} Subscription</span>
											</Typography>
											<Typography variant="body2" component="p">
											<img src={checkmark} style={{height:"18px"}}/> {plans[this.state?.user?.planName].logins} Login(s)  &nbsp; &nbsp; <img src={checkmark} style={{height:"18px"}}/> {plans[this.state?.user?.planName].profiles} Profiles &nbsp; &nbsp; <img src={checkmark} style={{height:"18px"}}/> {plans[this.state?.user?.planName].advancedSeaches} Advanced Searches
												<br></br><br></br>
											</Typography>
											<Typography className={classes.pos} style={{fontSize:'18px'}} color="accountLabel">
												
												<div>
													<strong>Plan:</strong> {this.getPlanName(this.state?.user?.planName)} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <Button variant="contained" style={{backgroundColor:"#e37627",height:"25px", color:"#FFFFFF"}}  onClick={this.goToUpGrade}>Upgrade</Button>
												</div>

												<div style={{paddingTop:"6px"}}>
													<strong>Frequency:</strong> {upperCaseFirstLetter(this.state?.user?.billingFrequency)}
												</div>

												<div style={{paddingTop:"6px"}}>
													<strong>Status:</strong> <span style={{color:"#e37627",fontSize:"18px",fontWeight:"bold"}}>{upperCaseFirstLetter(this.state.stripeSubscriptionStatus)}</span>
												</div>

												<div style={{paddingTop:"6px"}}>
													<strong>Price:</strong> <span style={{color:"#000000",fontSize:"18px",fontWeight:"bold"}}>
														
													{(this.state.stripeSubscriptionStatus !=='trialing') && (
														<span>
														{this.getPlanPrice(this.state?.user?.planName)}
														</span>

													)}

													{(this.state.stripeSubscriptionStatus ==='trialing') && (
														<span>
														$0   <span style={{color:'#7c7c7c', fontSize:'14px'}}> &nbsp; <i>({this.getPlanPrice(this.state?.user?.planName)} after trial period ends)</i></span>
														</span>

													)}
														
														</span>
												</div>

												<div style={{paddingTop:"6px"}}>
													<strong>Payment Method:</strong> 
													<br></br>

													{this.state.paymentMethod.card && 
														<div style={{border:'1px solid #000000'}}>
															<table>
																<tr>
																	<td style={{verticalAlign:"top",color:"#000000",fontSize:"16px",fontWeight:"bold"}}>
																		{upperCaseFirstLetter(this.state.paymentMethod.type)}....{this.state.paymentMethod.card.last4}
																		<br></br>
																		{this.state.paymentMethod.billing_details.name}
																	</td>
																	<td style={{verticalAlign:"top"}}>
																		<div style={{paddingLeft:'50px'}}>
																			{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='visa' && <img src={visaLogo}/>}
																			{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='amex' && <img src={americanexpressLogo}/>}
																			{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='discover' && <img src={discoverLogo}/>}
																			{this.state.paymentMethod.card &&  this.state.paymentMethod.card.brand==='mastercard' && <img src={mastercardLogo}/>}
																	
																		</div>
																	</td>
																	<td style={{verticalAlign:'top',paddingLeft:'20px',paddingTop:'3px'}}>
																		<Button variant="contained" style={{backgroundColor:"#e37627",height:"25px", color:"#FFFFFF"}}  onClick={() => this.showEditPaymentDialog(true)}>Edit</Button>
																	</td>
																</tr>

															</table>
														</div>
													}
												</div>

											</Typography>
										</CardContent>
										<CardActions>
						
											<Button disabled={ this.state.disableButton ? "true" : "" } variant="contained" style={{color:"#838080"}} onClick={() => this.setConfirmOpen(true)}>Cancel Subscription</Button>

										</CardActions>	
									</Card>		
								</Grid>
						</Grid>	
					</div>
					}

					{/* *********** ACCOUNT HISTORY ************/}
					{this.state.user.role === 'admin' && 
						<div>
						<br></br><br></br>
						<h1 class="accountLabel">Transaction History</h1>
						<p class="accountLabel">List of transactions.</p>
					
						<div class="top-row-list-properties">
												{this.state?.paymentHistory?.map((payment) => (
															<div class="row properties-row" style={{paddingLeft:'0px', marginLeft:'0px'}}>
																<div class="col-md-12" style={{paddingLeft:'0px', marginLeft:'0px'}}>
																<div class="custom-control custom-checkbox" style={{paddingLeft:'0px', marginLeft:'7px'}}>
																	{/*   <input type="checkbox" class="custom-control-input" id="customCheck2" checked /> */}
																		<label class="row-container custom-label" for="customCheck2">
																			<div class="row"  style={{borderBottom:"none"}}>
																
																				<div class="col-lg-12" style={{paddingTop:"5px",marginTop:"5px"}}>
																					<div class="row">
																						<div class="col-md-2">
																							<p class="rate-heading">Date</p>
																							<p class="rate-value green-value" style={{fontSize:"18px"}}>{this.formatDate(payment.created)}</p>
																						</div>
																						<div class="col-md-2">
																							<p class="rate-heading">Amount</p>
																							<p class="rate-value green-value" style={{fontSize:"18px"}}>{this.formatMoney(payment.amount)}</p>
																						</div>
																						<div class="col-md-2">
																							<p class="rate-heading">Method</p>
																							<p class="rate-value green-value" style={{fontSize:"18px"}}>{payment.capture_method}</p>
																						</div>
																						<div class="col-md-6">
																							<p class="rate-heading">Description</p>
																							<p class="rate-value green-value" style={{fontSize:"18px"}}>{payment.description}</p>
																						</div>
																		
																					
																				</div>
																				</div>
																			</div>
																			<div class="row" style={{borderTop:"none",height:"1px",marginBottom:"0px",paddingBottom:"0px"}}>

																			</div>
																		</label>
																	</div>                                                         
															</div>


														</div>
													

												))}    

											<Dialog2 ref={(component) => { this.dialog = component }}/>     
						</div>
					</div>			
					}				



					{/***** DIALOG ******/}								
					<ConfirmDialog
						title="Cancel Subscription?"
						open={this.state.confirmOpen}
						setOpen={this.setConfirmOpen}
						onConfirm={this.cancelSubscription}
					>
						Are you sure you want to cancel this subscription?
					</ConfirmDialog>

					{/*  EDIT PAYMENT METHOD DIALOG  */}	
					<Dialog open={this.state.openEditPaymentDialog} onClose={this.closePaymentDialog} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Edit Payment Method</DialogTitle>
						<DialogContent>
						
						<Elements stripe={stripePromise}>

							<CheckoutForm targetAction="EditPaymentMethod" user={this.state.user} paymentMethod={this.state?.paymentMethod}/>

						</Elements>	
												
						</DialogContent>
						<DialogActions>
						<Button onClick={this.closePaymentDialog} color="primary">
							Cancel
						</Button>
					
						</DialogActions>
					</Dialog>						

			 	</div>
			);
		}
	}
}


export default withStyles(styles)(SubscriptionDetails);

import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReCAPTCHA from 'react-google-recaptcha';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';
import Dialog from 'react-bootstrap-dialog'

//import appLogo from '../images/logo-nocircle.png';

import closeIcon from './img/icons/x2.png';

import axios from 'axios';

import { authMiddleWare } from '../utilities/Auth';
import { useAccordionToggle } from 'react-bootstrap';

const { recaptchaSiteKey } = require('../utilities/Constants');

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#2451b3'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			message: '',
			firstname: '',
			lastname: '',
			subject:'',
			company:'',
          	email: '',
			errors: [],
			loading: false,
			buttonText: 'Send',
			plan:'',
			type:'',
			termsChecked: false,
			displayScreen:true
		};

		this.captchaRef = React.createRef();
	}


	componentWillMount = () => {


	}
	

	

	handleSubmit = (event) => {
		event.preventDefault();
	
		try
		{
			const token = this.captchaRef.current.getValue();

			if (this.state.firstname ==="")
			{
				this.displayAlert("Invalid First Name","Enter your first name");
				return;
			}

			if (this.state.lastname ==="")
			{
				this.displayAlert("Invalid Last Name","Enter your last name");
				return;
			}

			if (this.state.email ==="")
			{
				this.displayAlert("Invalid Email","Enter valid email");
				return;
			}

			if (this.state.message ==="")
			{
				this.displayAlert("Invalid Message","Enter a message");
				return;
			}


			if (token == null || token ==='')
			{
				this.displayAlert("Human Verifiction","Please revify you are not a robot");
				return;	
			}
			
			//alert(token);

			const data = {
				token: token
			};
	
			axios
				.post('/api/verifyCaptcha', data)
				.then((response) => {
					
					
				//	console.log(JSON.stringify(response));
					
					if (response != null && response.data != null && response.data.isHuman != null && response.data.isHuman == true)
					{
					
						const messageData = {
							firstname:this.state.firstname,
							lastname:this.state.lastname,
							subject:this.state.subject,
							company:this.state.company,
							email:this.state.email,
							message:this.state.message
						};

						axios
						.post('/api/sendMessage', messageData)
						.then((response) => {
					
							this.dialog.show({
								title: "Message Sent",
								body: "Your message has been sent, we will be in touch with you in 24 to 48 hours",
								actions: [
								  Dialog.OKAction(() => {
									window.location.reload(true);
									
								  })
								],
								bsSize: 'small',
								onHide: (dialog) => {
								  dialog.hide()
								  window.location.reload(true);
								}
							  });

						})
						.catch((error) => {
							
							console.log("Error sending email:"+error);
							this.displayAlert("Message Failed","Your message wasn't sent, please try again.");
						}); 
						
					}
	
				})
				.catch((error) => {
				
					this.displayAlert("Human Verifiction","We were unable to verify you are a human. Please try again");
	
				});

				
		} catch(error)
		{
			alert(error);
		}
	}

	handleSubmitXX = (event) => {
		event.preventDefault();

		if (this.state.email ==="")
		{
			this.displayAlert("Invalid Email","Enter valid email");
			return;
		}

		if (this.state.password ==="")
		{
			this.displayAlert("Invalid Email","Enter valid password");
			return;
		}

		if (!this.state.termsChecked)
		{
			//alert("You must accept to the Terms of Service");
			let title = "Terms of Service"
			let message = "You must accept the Terms of Service and Privacy Policy"

			this.displayAlert(title,message);
			  
			return;
		}

		//const firstName= parseOutFirstName(this.state.name);
		this.setState({ loading: true });
		//this.setState({ firstName: firstName });

		let accountType = "individual";

		if (this.state.planName === 'enterprise')
		{
			accountType = "corporate";
		}

		const newUserData = {
			name: this.state.name,
			email: this.state.email,
			password: this.state.password,
			planType: this.state.planType,
			planName: this.state.planName,
			billingFrequency: this.state.billingFrequency,
			accountType:accountType,
			role: 'admin' //All users are admin my default
		};

		axios
			.post('/api/signup', newUserData)
			.then((response) => {
				
				localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
				localStorage.setItem('SessionExpired','N')

				this.setState({ 
					loading: false,
				});
				
				this.props.history.push('/signup/email-confirmation');

				/*
				axios
				.post('/api/initiateActivation', newUserData)
				.then((response) => {
			
				  localStorage.setItem('ActivationFirstName', `${newUserData.firstName}`);
				  localStorage.setItem('ActivationEmail', `${newUserData.email}`);

				  this.props.history.push('/activate');
			
				})
				.catch((error) => {
					
					console.log("Error sending email:"+error);
				}); 
				
				*/

			})
			.catch((error) => {
			
				console.log(error);
				//console.log(JSON.stringify(error));
				//console.log(error.status);
					
				if (error != null && ((error.status && error.status === 400) || error.toString().indexOf("code 400") >=0)) {
					this.displayAlert("Duplicate Email","This email is already in use.");
				} else {
					this.displayAlert("Error","There was an issue while creating your account. Try again later.");
				}

				this.setState({
					errors: error,
					loading: false
				});
			});
	};

	displayAlert = (title,message) => {

		this.dialog.show({
			title: title,
			body: message,
			actions: [
			  Dialog.OKAction(() => {
				//console.log('OK was clicked!')
				
			  })
			],
			bsSize: 'small',
			onHide: (dialog) => {
			  dialog.hide()
			  
			}
		  });
	};

	
	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	goHome = () => {
		window.location.href ="/";
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#2451b3' //your color
			  }
			}
		  });
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<div>

			{this.state.displayScreen && 
			<div>
				<div style={{float:'right'}}><a href="/"><img src={closeIcon} style={{height:'30px'}}/></a> &nbsp;&nbsp;&nbsp;&nbsp;</div>

			<Container component="main" maxWidth="xs">
				
				<CssBaseline />
				<div className={classes.paper}>

			{/*	<img src={appLogo} style={{height:'45px',cursor:'pointer'}} onClick={this.goHome}/>  */}
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#2451b3',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
					GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Contact Us
					</Typography>

					<form className={classes.form} noValidate>
				

					<ThemeProvider theme={theme}>
						<Grid container spacing={2}>
						
							<Grid item xs={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="firstname"
									label="First Name"
									name="firstname"
									type="regular"
									autoComplete="firstname"
									helperText={errors.firstname}
									error={errors.firstname ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item xs={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="lastname"
									label="Last Name"
									name="lastname"
									type="regular"
									autoComplete="lastname"
									inputProps={{style: {fontSize: 16}}}
									InputLabelProps={{style: {fontSize: 16}}}
									helperText={errors.lastname}
									error={errors.lastname ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									id="subject"
									label="What would you like to contact us about?"
									type="regular"
									name="subject"  
									inputProps={{style: {fontSize: 16}}}
									InputLabelProps={{style: {fontSize: 16}}}
									autoComplete="subject"
									helperText={errors.subject}
									error={errors.subject ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email Address"
									type="regular"
									name="email"  
									inputProps={{style: {fontSize: 16}}}
									InputLabelProps={{style: {fontSize: 16}}}
									autoComplete="email"
									helperText={errors.email}
									error={errors.email ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									id="company"
									label="Company"
									type="regular"
									name="company"  
									inputProps={{style: {fontSize: 16}}}
									InputLabelProps={{style: {fontSize: 16}}}
									autoComplete="company"
									helperText={errors.company}
									error={errors.company ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth 
									multiline
									rows={4}
									maxRows={6}
									id="message"
									label="Message"
									type="regular"
									name="message"  
									inputProps={{style: {fontSize: 16}}}
									InputLabelProps={{style: {fontSize: 16}}}
									autoComplete="message"
									helperText={errors.message}
									error={errors.message ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>
					
						</Grid>

						<br></br>
				
						<label>
					
							<div style={{paddingBottom:'5px',fontWeight:'100'}}>
					
								<span style={{fontSize:'15px',color:'#000000'}}> 
								By submitting this form you agree to GoRepa’s <a href="/privacy.html" target="_blank">privacy policy</a>.
								</span>

							</div>
						
						</label>
				
						<ReCAPTCHA sitekey={recaptchaSiteKey} ref={this.captchaRef}/>

						<Button
							type="submit"
							fullWidth
							variant="contained" 
							style={{color:'#FFFFFF',fontSize:'20px',fontWeight:'bold'}}
							color="primary"
							className={classes.submit}
							onClick={this.handleSubmit}
                            disabled={loading}
						>
							{this.state.buttonText}
							{loading && <CircularProgress size={30} className={classes.progess} />}
						</Button>
					

						</ThemeProvider>
					</form>
					<br></br><br></br><br></br>
				</div>
			</Container>
			</div>
			}

				<Dialog ref={(component) => { this.dialog = component }}/>     
			</div>
		);
	}
}

export default withStyles(styles)(signup);

import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">
                                    The <b>break-even ratio</b> is an indicator used by lenders when underwriting investment properties; its purpose is to estimate how vulnerable a property is of defaulting on its debt should rental income decline.
                                    </div>

                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The lower the <b>break-even ratio</b> the better. This ratio typically ranges between <b>70%</b> and <b>100%</b>. Most lenders look for a <b>break-even ratio</b> of <b>85%</b> or less.
                                    
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>break-even ratio</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                    <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                    
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Monthly Mortgage         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_MONTHLY_MORTGAGE_PAYMENT}
                                                    </div>

                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Monthly Rent         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT}
                                                    </div>

                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Vacancy Rate         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_VACANCY_RATE}%

                                                    </div>

                                                </div>

                             
                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Annual Expenses
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_ANNUAL_EXPENSES}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>
                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                       
                                    {/*  STEP 1 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Annual Mortgage</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Annual Mortgage Payments = <br></br>
                                                       <b>Mortgage Payment x 12</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Annual Mortgage Payments = <br></br>
                                                        <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_MORTGAGE_PAYMENT} x 12 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Annual Mortgage Payments = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_MORTGAGE_PAYMENT}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>
                                        <br></br>
    
                                    {/*  STEP 2 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 2:</span> <b>Calculate Gross Annual Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Gross Annual Income = <br></br>
                                                       <b>Monthly Rent x 12</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Annual Income = <br></br>
                                                        <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT} x 12 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Annual Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>
                                        <br></br>

                                        {/*  STEP 3 */}
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 3:</span> <b>Calculate Vacancy and Credit Loss</b>
                                            </div>
                                        </div>

                                        
                                        <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Vacancy and Credit Loss = <br></br>
                                                       <b>(Gross Annual Income x Vacancy Rate) / 100</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Vacancy and Credit Loss = <br></br>
                                                        <b>({this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME} x {this.state.indicator.helpVariableMap.VAR_VACANCY_RATE}) / 100 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Vacancy and Credit Loss = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_VACANCY_CREDIT_LOSS}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>

                                        <br></br>


                                    {/*  STEP 3 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 4:</span> <b>Calculate Gross Operating Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Gross Operating Income = <br></br>
                                                        <b>Gross Annual Income - Vacancy Credit Loss</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME} - {this.state.indicator.helpVariableMap.VAR_VACANCY_CREDIT_LOSS}</b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_OPERATING_INCOME}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        <br></br>

       
                                    {/*  STEP 5 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 5:</span> <b>Calculate Break-Even Ratio</b>
                                           </div>
                                       </div>

                                   
                                       <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1">
                                                    Break-Even Ratio = 
                                                    </div>
                                                </div>
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:'15px'}}>
                                                    <div class="col-md-8 indicator-info-formula-table-col2">
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center",borderBottom: "2px solid  #1c7cfb"}}>
                                                                    <b>(Annual Mortgage Payments + Operating Expenses)</b>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center"}}>
                                                                    <b>Gross Operating Income</b>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

     
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1">
                                                    Break-Even Ratio = 
                                                    </div>
                                                </div>
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:'15px'}}>
                                                    <div class="col-md-8 indicator-info-formula-table-col2">
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center",borderBottom: "2px solid  #1c7cfb"}}>
                                                                    <b>({this.state.indicator.helpVariableMap.VAR_ANNUAL_MORTGAGE_PAYMENT} + {this.state.indicator.helpVariableMap.VAR_ANNUAL_EXPENSES})</b>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center"}}>
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_GROSS_OPERATING_INCOME}</b>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-8 indicator-info-formula-table-col1">
                                                    Break-Even Ratio = &nbsp; &nbsp; <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                    </div>
                                                </div>
                                             
                                            </div>
                                        </div>
                                        <br></br>


                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Break-Even Ratio benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                        <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                MONTHLY RENT<br></br>
                                                VACANCY RATE<br></br>
                                                MORTGAGE PAYEMENTS<br></br>
                                                ANNUAL EXPENSES
                                                
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                  {/* TIP */}
                  {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Put down a larger <span style={{fontWeight:'600'}}>down payment</span><br></br>
                            <b>2)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>3)</b> Increase the <span style={{fontWeight:'600'}}>monthly rent</span><br></br>     
                            <b>4)</b> Lower your <span style={{fontWeight:'600'}}>expenses</span><br></br>                    
                        </div>

                    </div>        
                }         

                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

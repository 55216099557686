export const authMiddleWare = (history) => {
    const authToken = localStorage.getItem('AuthToken');
    if(authToken === null){
        try
        {
            history.push('/login');
        }catch(error)
        {
            window.location.href = '/login';
        }
    }
}
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Pie } from 'react-chartjs-2';
import Slider from '@material-ui/core/Slider';

import twitterFooterWhite from '../pages/img/website-images/twitter-footer-white.png';

import loginIconBlue from '../pages/img/website-images/login-icon-blue.png';
import propertyAnalyticsLogoTransparent from '../pages/img/website-images/real-estate-property-analytics-logo-transparent.png';
import propertyAnalyticsLogo from '../pages/img/website-images/real-estate-property-analytics-logo.png';
import loginIconWhite from '../pages/img/website-images/login-icon-white.png';
import propertyAnalyticsFeatureBlue from '../pages/img/website-images/property-analytics-feature-blue.png';
import advancedSearchesBlue from '../pages/img/website-images/advanced-searches-blue.png';
import reportingAndAnalyticsGreen from '../pages/img/website-images/reporting-and-analytics-green.png';
import profilesFeatureBlue from '../pages/img/website-images/profiles-feature-blue.png';
import financialIndicatorsFeatureRed from '../pages/img/website-images/financial-indicators-feature-red.png';
import freeTrial from '../pages/img/website-images/free-trial.png';
import moneyBackGuarantee from '../pages/img/website-images/money-back-guarantee.png';
import cancelAnyTime from '../pages/img/website-images/cancel-any-time.png';
import satisfactionGurantee from '../pages/img/website-images/satisfaction-gurantee.png';
import securedSsl from '../pages/img/website-images/secured-ssl.png';
import instagramFooterWhite from '../pages/img/website-images/instagram-footer-white.png';
import linkedinFooterWhite from '../pages/img/website-images/linkedin-footer-white.png';
import facebookFooterWhite from '../pages/img/website-images/facebook-footer-white.png';

import {MortagageCalculator} from '../components/MortagageCalculator';


const styles = (theme) => ({
// Your styles here...
});

class Calculators extends Component {
constructor(props) {
	super(props);
	this.state = {
	purchasePrice: '425,000',
	downPayment: '85,000',
	downPaymentPercent: '',
	interestRate: '7',
	loanTerm: '30',
	monthlyPayment: 0,
	taxesInsuranceCollapsed: true,
	propertyTax: '280',
	homeownersInsurance: '65',
	PMI: '98',
	homeownersAssociation: '50',
	propertyTaxSlider: 280, // added propertyTaxSlider field
	chartData: {
		labels: ['Monthly Payment', 'Property Tax', 'Homeowner\'s Insurance', 'PMI', 'Homeowners Association'],
		datasets: [
		  {
			data: [0, 0, 0, 0, 0],
			backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D'],
			hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D']
		  }
		]
	  }
	};
}

componentWillMount = () => {
	this.calculateMonthlyPayment();
}

calculateMonthlyPayment = () => {
	//const { purchasePrice, downPayment, interestRate, loanTerm } = this.state;
	const { purchasePrice, downPayment, interestRate, loanTerm, monthlyPayment, propertyTax, homeownersInsurance, PMI, homeownersAssociation } = this.state; // added propertyTax, homeownersInsurance and PMI to the destructure

	const principal = parseFloat(purchasePrice.replace(/,/g, '')) - parseFloat(downPayment.replace(/,/g, ''));
	const interest = parseFloat(interestRate) / 100 / 12;
	const periods = parseFloat(loanTerm) * 12;

	const x = Math.pow(1 + interest, periods);
	const monthly = (principal * x * interest) / (x - 1);

	this.setState({ monthlyPayment: monthly.toFixed(2) });

	const downPaymentPercent = ((parseFloat(downPayment.replace(/,/g, '')) / parseFloat(purchasePrice.replace(/,/g, ''))) * 100).toFixed(2);
	this.setState({ downPaymentPercent });

	
	const chartData = {
		labels: ['Monthly Payment', 'Property Tax', 'Homeowner\'s Insurance', 'PMI', 'Homeowners Association'],
		datasets: [
		  {
			data: [monthlyPayment, propertyTax, homeownersInsurance, PMI, homeownersAssociation],
			backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D'],
			hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D']
		  }
		]
	  };
	
	  this.setState({ chartData });

};

handlePurchasePriceChange = (e) => {
	this.setState({ purchasePrice: e.target.value }, this.calculateMonthlyPayment);
};

/*
handlePurchasePriceSliderChange = (e) => {
	const value = Math.min(Math.round(e.target.value / 1000) * 1000, 10000000);
	const formattedValue = Number(value).toLocaleString();
	this.setState({ purchasePrice: formattedValue }, this.calculateMonthlyPayment);
};
*/

handlePurchasePriceSliderChange = (e, value) => {
    const formattedValue = Number(value).toLocaleString();
    this.setState({ purchasePrice: formattedValue }, this.calculateMonthlyPayment);
  };


handlePropertyTaxSliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ propertyTax: formattedValue }, this.calculateMonthlyPayment);
};

handleHomeownersInsuranceSliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ homeownersInsurance: formattedValue }, this.calculateMonthlyPayment);
};

handlePMISliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ PMI: formattedValue }, this.calculateMonthlyPayment);
};

handleHOASliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ homeownersAssociation: formattedValue }, this.calculateMonthlyPayment);
};

handleDownPaymentChange = (e) => {
	const value = e.target.value.replace(/\D/g, '');
	const formattedValue = Number(value).toLocaleString();
	this.setState({ downPayment: formattedValue }, this.calculateMonthlyPayment);
};

/*
handleDownPaymentSliderChange = (e) => {
	const value = Math.min(Math.round(e.target.value / 1000) * 1000, 800000);
	const formattedValue = Number(value).toLocaleString();
	this.setState({ downPayment: formattedValue }, this.calculateMonthlyPayment);
};
*/

handleDownPaymentSliderChange = (e,value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ downPayment: formattedValue }, this.calculateMonthlyPayment);
};

handleInterestRateChange = (e) => {
	this.setState({ interestRate: e.target.value }, this.calculateMonthlyPayment);
};

/*
handleInterestRateSliderChange = (e) => {
	const value = Math.min(Math.round(e.target.value / 0.25) * 0.25, 12);
	this.setState({ interestRate: value }, this.calculateMonthlyPayment);
};
*/

handleInterestRateSliderChange = (e,value) => {
	//const value = Math.min(Math.round(e.target.value / 0.25) * 0.25, 12);
	
	//const value2 = Math.min(Math.round(value / 0.25) * 0.25, 12);

	this.setState({ interestRate: value }, this.calculateMonthlyPayment);
};


handleLoanTermChange = (e) => {
	this.setState({ loanTerm: e.target.value }, this.calculateMonthlyPayment);
};

/*
handleLoanTermSliderChange = (e) => {
	const value = e.target.value;
	this.setState({ loanTerm: value }, this.calculateMonthlyPayment);
};
*/
handleLoanTermSliderChange = (e,value) => {
	this.setState({ loanTerm: value }, this.calculateMonthlyPayment);
};


formatNumberWithCommas(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

handleCollapseSection = (event) => {

	event.preventDefault();


	if (this.state.taxesInsuranceCollapsed == true)
	{
		this.setState({ taxesInsuranceCollapsed: false });

	}else
	{
		this.setState({ taxesInsuranceCollapsed: true });
	}
}

render() {
	const { classes } = this.props;
	const { purchasePrice, downPayment, downPaymentPercent, interestRate, loanTerm, monthlyPayment, propertyTax, homeownersInsurance, PMI, homeownersAssociation } = this.state; // added propertyTax, homeownersInsurance and PMI to the destructure

	return (
<html lang="en">
    <head>
        <meta charset="UTF-8"/>
        <title>Pricing | GoRepa</title>
        <meta name="description" content="Mortgage Calculator" />
        <meta name="keywords" content="Mortgage Calculator" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
     
        <meta property="og:url" content="https://www.gorepa.com/pricing.html" />
        <meta property="og:type" content="" />
        <meta property="og:title" content="Mortgage Calculator | GoRepa" />
        <meta property="og:description" content="Mortgage Calculator" />
        <meta property="og:image" content="" />
        <meta name="twitter:image" content="" />
        <link rel="canonical" href="https://www.gorepa.com/pricing.html" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        {/* <link rel="stylesheet" type="text/css" href="css/style.css" /> */}

        <script src="js/jquery-3.5.1.min.js"></script>
        <script type="text/javascript" src="js/sites-scripts.js"></script>
      
        {/*
      <link rel="stylesheet" type="text/css" href="css/page-specific/pricing.css" />
    */}
 
      </head>

      <body class="bodyStyle">

        <div class="pricePagecontent ">
        
            <div class="pricePagegr-encloser-cookie " id="cookie-policy-banner">
        
                <div class="pricePagegr-cookie-container ">
                    <div class="pricePagegr-cookie-enclosure ">
                        <div class="pricePagegr-cookie-head pricePagegr-space-fix ">
                            <div class="pricePagegr-cookie-title ">
                                This Site Uses Cookies
                            </div>
                            <div class="pricePagegr-cookie-permission " id="cookieAllow" onclick="cookieAccept();">
                                Allow
                            </div>
                        </div>
                        <div class="pricePagegr-cookie-content ">
                            <div>
                                We use cookies to enhance site navigation, analyze site usage, and assist us to improve your website experience as set out in our <a href="/cookie-policy.html">Cookie Policy</a>.
                            </div>
                                                                        </div>
                                                                                </div>
                </div>
            </div>
           
            {/* START Header */}

            <header class="pricePageheader " id="theHeader">

            <div class="pricePagerow pricePagegr-mobile-top-menu-encloser ">
            <div id="mobilePrimaryMenu">
                <div class="pricePagenavbar-header ">
                    <div class="pricePagegr-mobile-menu-wrap pricePagegr-space-fix ">
                        <div class="pricePagegr-mobile-menu-left-side pricePagegr-space-fix ">
                            <div class="pricePagegr-hamburger-button ">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div class="pricePagegr-mobile-menu-logo-container ">
                                <a href="/" title="">
                                    <img class="pricePagelogo pricePagescrollView " src={propertyAnalyticsLogo} alt="real estate property analytics" />
                                </a>
                            </div>
                        </div>
                        <div class="pricePagegr-mobile-menu-right-side pricePagegr-space-fix ">
                            <div class="pricePagegr-tablet-tick-contact-container ">
                                <a class="pricePagebtn pricePagenormal pricePagegr-gtitem-blue " href="/login">
                                                                             LOGIN
                                </a>
                            </div>
                            
							{/*
                            {!props.isUserLoggedIn && 
                            <div class="pricePagegr-mobile-menu-signup-container ">
                                <a class="pricePagebtn pricePagenormal pricePagegreen " href="/pricing">
                                    SIGN UP for FREE
                                </a>
                            </div>
                            }

                            {props.isUserLoggedIn && 
                            <div class="pricePagegr-mobile-menu-signup-container ">
                                <div class="pricePagebtn pricePagenormal pricePagegreen " onClick={logOutUser}>
                                    LOGOUT
                                </div>
                            </div>
                            }
						*/}
                            
                            <div class="pricePagegr-mobile-menu-login-container ">
                                <a href="/login">
                                    <img class="pricePagegr-menu-login-icon " src="images/login-icon-blue.png" alt="Login" border="0" />
                                </a>
                            </div>
                            <div class="pricePagegr-tablet-tick-button-container ">
                                <a href="/signup">
                                    <img class="pricePagegr-menu-login-icon " src="images/search-icon-blue.png" alt="Login" border="0" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="pricePagegr-tablet-special-search " id="tabletChannelSearch">
                        <div class="pricePagegr-tablet-tick-field-enclosure " id="channel-search">

                        </div>
                    </div>
                </div>
                <div class="pricePagenavbar-collapse pricePageignore-additional-spaces pricePagegr-mobile-secondary-menu ">
                    <ul class="pricePagenav pricePagenavbar-nav ">
        
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="SOLUTIONS">
                            <a data-path="SOLUTIONS" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                SOLUTION
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li class="pricePagemobileSubtitle ">
                                    <a href="/homebuyers.html" class="">
                                        BY INVESTOR TYPE
                                    </a>
                                </li>
                                <li>
                                    <a href="/homebuyers.html" class="">
                                        For Home Buyers
                                    </a>
                                </li>
                                <li>
                                    <a href="/property-flipping-investors.html" class="">
                                        For House Flipping Investors
                                    </a>
                                </li>
                                <li>
                                    <a href="/rental-property-investors.html" class="">
                                        For Rental Property Investors
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="COMPANY">
                            <a data-path="COMPANY" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                COMPANY         
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li>
                                    <a href="/about.html" class="">
                                        About Us
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        {/*
                        <li class="">
                            <a href="/pricing" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                PRICING
                            </a>
                        </li>
                        */}
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="Resources">
                            <a data-path="Resources" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                Resources
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li>
                                    <a href="/blog" >
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a href="/faq.html" class="">
                                        FAQ
                                    </a>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="Support">
                            <a data-path="Support" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                Support
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li>
                                    <a href="/faq.html" >
                                        FAQ
                                    </a>
                                </li>
                       
                                <li>
                                    <a href="/contactus" >
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a href="/login" >
                                        Login
                                    </a>
                                </li>
                                
                            </ul>
                        </li>

                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                
                    </ul>
                    <div class="pricePagegr-mobile-point-buttonsWrap ">
                        <div class="pricePagegr-mobile-point-buttonsBox ">
                            <div class="pricePagegr-mobile-point-buttons ">
                                <a class="pricePagebtn pricePagenormal pricePagegr-gtitem-blue " href="/login">
                                                                                LOGIN
                                                                        </a>
                                                                    <a class="pricePagebtn pricePagenormal pricePagegreen " href="/signup">
                                    SIGN UP for FREE
                                </a>
                                                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pricePagerow pricePagegr-desktop-main-menu-wrap ">
            <div class="pricePagecontainer pricePageignore-additional-spaces pricePagegr-desktop-main-menu-container pricePagegr-space-fix ">
                <div class="pricePagegr-desktop-menu pricePageignore-additional-spaces ">
                    <div id="primaryMenu" class="pricePagenavbar " style={{minWidth:'100%',padding:'0px',margin:'0px'}}>
                        <ul class="pricePagenav pricePagenavbar-nav pricePagenav-pills pricePagegr-space-fix " style={{minWidth:'100%'}}>
                            <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{float:'left'}}>
                                        <ul class="pricePagegr-main-menu-wrap pricePagegr-space-fix ">
                                        <li class="pricePagedesktopLogo pricePageignore-additional-spaces ">
                                            <a href="/" title="">
                                                <img class="pricePagelogo pricePagegr-normal-view " src={propertyAnalyticsLogoTransparent} alt="real estate property analytics" />
                                                <img class="pricePagelogo pricePagegr-scroll-view " src={propertyAnalyticsLogo} alt="real estate property analytics" />
                                            </a>
                                        </li>
                                        <li class="pricePagedropDown pricePagesolutions ">
                                            <a class="pricePagemenuNoLink pricePagedropdown-toggleX " data-hover="dropDown" data-toggle="dropDown">
                                                SOLUTION
                                            </a>
                                            <ul class="pricePagemenu-dropdown pricePagecol3 ">
                                                <li class="pricePagegr-space-fix pricePagegr-menu-nav-enclosure ">
                                                    <ul class="pricePagecol1 pricePagecolAll ">
                                                        <li class="pricePagegr-top-list ">
                                                            <a href="/homebuyers.html" class="">
                                                                BY INVESTOR TYPE
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                            <ul class="pricePagegr-menu-left-side ">
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/homebuyers.html" class="">
                                                                        For Home Buyers
                                                                    </a>
                                                                </li>
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/property-flipping-investors.html" class="">
                                                                        For House Flipping Investors
                                                                    </a>
                                                                </li>
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/rental-property-investors.html" class="">
                                                                        For Rental Property Investors
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="pricePagedropDown pricePagecompany ">
                                            
                                            <a class="menuNoLink pricePagedropdown-toggleX " data-hover="dropDown" data-toggle="dropDown">
                                                COMPANY
                                            </a>
                                         
                                            <ul class="pricePagemenu-dropdown pricePagecol2 ">
                                                <li class="pricePagegr-space-fix pricePagegr-menu-nav-enclosure ">
                                                    <ul class="pricePagecol1 pricePagecolAll ">
                                                        <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                            <ul class="pricePagegr-menu-left-side ">
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/about.html" class="">
                                                                        About Us
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        {/*
                                        <li class="pricePagegr-single-link pricePagepricing ">
                                            <a href="/pricing/" class="">
                                                PRICING
                                            </a>
                                        </li>
                                        */}
                                        </ul>
                                    </td>

                                    <td style={{float:'right'}}>
                                        <ul class="pricePagegr-main-menu-wrap pricePagegr-space-fix ">
                                        <li class="pricePagedropDown pricePageresources pricePagerightRefferer ">
                                            <a class="pricePagemenuNoLink pricePagedropdown-toggleX " data-hover="dropDown" data-toggle="dropDown">
                                                Resources
                                            </a>
                                            <ul class="pricePagemenu-dropdown pricePagegr-wrap-col2 pricePagegr-space-fix ">
                                                <li class="pricePagegr-wrap-col-left ">
                                                    <ul>
                                                        <li>
                                                            <ul class="pricePagecol1 pricePagecolAll ">
                                                                <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                                    <ul class="pricePagemenugr-left-side ">
                                                                        <li class="pricePagelevel2 ">
                                                                            <a href="/blog" >
                                                                                Blog
                                                                            </a>
                                                                        </li>
                                                                        <li class="pricePagelevel2 ">
                                                                            <a href="/faq.html" class="">
                                                                                FAQ
                                                                            </a>
                                                                        </li>
                                                               
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="pricePagedropDown pricePagesupport pricePagerightRefferer ">
                                            <a class="pricePagemenuNoLink ">
                                                Support
                                            </a>
                                            <ul class="pricePagemenu-dropdown pricePagecol2 ">
                                                <li class="pricePagegr-space-fix pricePagegr-menu-nav-enclosure ">
                                                    <ul class="pricePagecol1 pricePagecolAll ">
                                                        <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                            <ul class="pricePagegr-menu-left-side ">
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/faq.html" >
                                                                        FAQ
                                                                    </a>
                                                                </li>
                                                     
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/contactus" >
                                                                        Contact Us
                                                                    </a>
                                                                </li>
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/login" >
                                                                        Login
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        
                                        <li class="pricePagegr-desktop-login pricePagegr-desktop-login-blue ">
                                            <a href="/login">
                                                <img class="pricePagegr-menu-login-icon " src={loginIconBlue} alt="Login" border="0"/>
                                                <span class="pricePagegr-menu-login-text ">
                                                                Login
                                                            </span>
                                            </a>
                                        </li>
                                        <li class="pricePagegr-desktop-login pricePagedesktopLoginWhite ">
                                            <a href="/login">
                                                <img class="pricePagegr-menu-login-icon " src={loginIconWhite} alt="Login" border="0"/>
                                                <span class="pricePagegr-menu-login-text ">
                                                                Login
                                                            </span>
                                            </a>
                                        </li>

                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-blue ">
                                            
                                            <a class="pricePagebtn pricePagegr-gtitem-blue " href="/login">
                                                LOGIN
                                            </a>
                                            
                                        </li>
										
										{/*
										{props.isUserLoggedIn && 
                                        
                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-blue ">
                                            
                                            <a class="pricePagebtn pricePagegr-gtitem-blue "  onClick={logOutUser}>
                                                LOGOUT
                                            </a>
                                            
                                        </li>
                                        }
										*/}

                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-white ">
                                                <a class="pricePagebtn pricePagegr-gtitem-white " href="/login">
                                                LOGIN
                                                </a>
                                        </li>

										
										{/*
										{props.isUserLoggedIn && 
                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-white ">
                                                <a class="pricePagebtn pricePagegr-gtitem-white " onClick={logOutUser}>
                                                LOGOUT
                                                </a>
                                        </li>
                                        }
									*/}

                                        <li class="pricePagegr-signup-nomoney-menu ">
                                            <a class="pricePagebtn pricePagegreen " href="/signup">
                                                SIGN UP for FREE
                                            </a>
                                        </li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
    
                        </ul>
                    
                    </div>
                    
                    <div class="pricePagegr-desktop-search " id="desktopChannelSearch">
                        <div class="pricePagegr-desktop-search-item-enclosure " id="channel-search">

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </header>
            <div class="pricePagegr-space-fix "></div>
            {/* End Header */}
            {/*  Start Content  */}
            <div class="pricePagegr-enclose-all ">
                <div class="pricePagepricing ">

                <div class="pricePagegr-entry-page-rectangle-container pricePagegr-the-low-menu ">
        <div class="pricePagegr-entry-page-rectangle-wrap ">
        <div class="pricePagecontainer pricePageignore-additional-spaces ">
            <div class="pricePagegr-first-info-special-wrapper pricePagegr-space-fix ">
            <h1 class="pricePageintroPageBannerTitle pricePagegr-color-white pricePagetext-center ">
            Mortgage Calculator
            </h1>
            <h4 class="pricePagegr-section-title2 pricePagetext-center pricePagewhite ">
         		Effortlessly navigate your home-buying journey with<br/> 
				our intuitive Mortgage Calculator, designed to<br/> 
				provide you with accurate and personalized monthly<br/> 
				payment estimates, helping you make informed<br/> 
				financial decisions for your dream home.<br/>


            </h4>



            </div>
        </div>
        </div>

        </div>


        </div>


		{/* Content */}							
		<div>
		<MortagageCalculator/>
			
		</div>

        {/* END Section6  */}
        </div>
                {/* Stop Loading Content  */}

                {/* Start Loading Bottom Blocks  */}
                <div class="pricePagegr-enclose-g-container pricePagegr-enclosed-rotate-wrap ">
                <div class="pricePagegr-enclosed-rotate ">
                    <div class="pricePagecontainer pricePageignore-additional-spaces ">
                        <div class="pricePagerow pricePagegr-space-fix ">
                            <div class="pricePagecol-md-8 pricePagegr-footer-left pricePagetextX pricePageignore-additional-spaces ">
                                <div>
                                    <div class="pricePagegr-start-text-enclosure pricePagetext-center ">
                                        <h5 class="pricePagewhite pricePagegr-spaces-0 ">
                                            Simplify your property search and analysis efforts. Try GoRepa<sup style={{fontsize:'12px'}}>&#174;</sup>.
                                        </h5>
                                        <div class="pricePagewhite ">
                                            The only real estate analytics platform that allows you to pick properties like you pick stocks with green and red signals.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pricePagecol-md-4 pricePagegr-footer-right pricePageignore-additional-spaces ">
                                <div>
                                    <div class="pricePagegr-start-button-enclosure pricePagetext-center ">
                                        <a class="pricePagebtn pricePagenormal pricePagegr-gtitem-white " href="/login">LOGIN</a>
                                        <a class="pricePagebtn pricePagenormal pricePagegreen " href="/signup">SIGN UP for FREE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
                {/* Stop Loading Bottom Blocks  */}
            </div>
            {/* Stop Content  */}

                    {/* Start Template Footer  */}
                    <footer class="pricePagev2 ">
                    <div class="pricePagefooter ">
                        <div class="">
                            <div class="pricePagerow ">
                                <div class="pricePagecontainer pricePageignore-additional-spaces ">
                                    <div class="pricePagegr-footer-wrap " style={{width:'100%'}}>
                                        <div class="pricePagegr-footer-top-wrap pricePagegr-space-fix ">
                                            <div class="pricePagegr-footer-top pricePagegr-space-fix ">
                                                <div class="pricePagecolAll pricePagecol1 ">
                                                    <ul class="pricePagegr-footer-enclose-wrap pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-logo-wrap ">
                                                            <a class="pricePagegr-footer-enclose-logo " href="/">
                                                                <img class="pricePagelogo "style={{width:'55px'}} src={propertyAnalyticsLogoTransparent} alt="real estate property analytics" border="0"/>
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-title pricePagegr-footer-enclose-1 ">
                                                            <h5 class="pricePagegr-top-spaces10 pricePagegr-bottom-spaces-0 ">
                                                                Real Estate Property Analytics. Simplified.
                                                            </h5>
                                                        </li>
                                        
                                                        <li class="pricePagegr-social-box pricePagegr-space-fix pricePagegr-bottom-spaces-b ">
                                                            <ul class="pricePagesocial pricePagegr-space-fix ">
                                                                <li>
                                                                    <a class="pricePagegr-footer-flickr " href="https://www.instagram.com/gorepa.analytics/"  target="_blank">
                                                                        <img alt="instagram" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={instagramFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="pricePagegr-footer-linkedin " href="https://www.linkedin.com/company/gorepa/"  target="_blank">
                                                                        <img alt="linkedin" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={linkedinFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="pricePagegr-footer-twitter " href="https://www.twitter.com/GoRepaAnalytics"  target="_blank">
                                                                        <img alt="twitter" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={twitterFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="pricePagegr-footer-facebook " href="https://www.facebook.com/GoRepaAnalytics"  target="_blank">
                                                                        <img alt="facebook" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={facebookFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                        
                                                <div class="pricePagecolAll pricePagecol3 ">
                                                    <ul class="pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                SOLUTION
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/homebuyers.html">
                                                                For Homebuyers
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/property-flipping-investors.html">
                                                                For Property Flipping Investors
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/rental-property-investors.html">
                                                                For Rental Property Investors
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="pricePagecolAll pricePagecol4 ">
                                                    <ul class="pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                COMPANY
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/about.html">
                                                                About Us
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="pricePagecolAll pricePagecol5 ">
                                                    <ul class="pricePagefooterNav pricePagegr-second-level ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                RESOURCES
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/blog" >
                                                                Blog
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/faq.html">
                                                                FAQ
                                                            </a>
                                                        </li>
                                               
                                                    </ul>
                                                </div>
                                                <div class="pricePagecolAll pricePagecol6 ">
                                                    <ul class="pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                CONTACT US
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/login">
                                                                Login
                                                            </a>
                                                        </li>
                                                  
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/contactus">
                                                                Contact Us
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pricePagegr-footer-bottom-wrap ">
                                            <ul class="pricePagegr-space-fix ">
                                                <li class="pricePagegr-footer-enclose-policy pricePagegr-space-fix ">
                                                    <a href="/privacy.html">
                                                        Privacy Policy
                                                    </a>
                                                    <a href="/terms-of-service.html" >
                                                        Terms and Conditions
                                                    </a>
                                                </li>
                                                <li class="pricePagegr-footer-copyright ">
                                                    &#169; 2023 Copyright GoRepa<sup style={{fontsize:'12px'}}>&#174;</sup>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            





        <script type="text/javascript" src="js/jquery.colorbox-min.js"></script>
        
</body>

</html>

	);
}
}

export default withStyles(styles)(Calculators);

import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

const GlossaryPage = ({ glossary }) => {
	return (
		<View style={styles.container} break={true}>
			<View style={styles.pageHead}>
				<Text style={styles.fwBold}>Glossary of Terms</Text>
			</View>
			<View style={styles.table}>
				<View style={styles.tableRow}>
					<View
						style={[
							styles.tableCol,
							styles.tableColHeader,
							styles.tableColLeft,
						]}
					>
						<Text style={[styles.fwBold]}>Term</Text>
					</View>
					<View
						style={[
							styles.tableCol,
							styles.tableColHeader,
							styles.tableColRight,
						]}
					>
						<Text style={[styles.fwBold]}>Definition</Text>
					</View>
				</View>
				{glossary.map((row, index) => (
					<View key={index} style={styles.tableRow}>
						<View style={[styles.tableCol, styles.tableColLeft]}>
							<Text style={styles.tableCell}>{row.term}</Text>
						</View>
						<View style={[styles.tableCol, styles.tableColRight]}>
							<Text style={[styles.tableCell]}>{row.definition}</Text>
						</View>
					</View>
				))}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		fontSize: 11,
		marginTop: 10,
	},
	pageHead: {
		textAlign: 'center',
	},
	table: {
		display: 'table',
		width: '100%',
		marginTop: 15,
	},
	tableRow: {
		flexDirection: 'row',
		borderLeft: '1px solid #bfbfbf',
	},
	tableCol: {
		border: '1px solid #bfbfbf',
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableColHeader: {
		borderTopWidth: 1,
		textAlign: 'center',
		padding: 5,
	},
	tableColLeft: {
		width: '25%',
	},
	tableColRight: {
		width: '75%',
	},
	tableCell: {
		padding: 5,
	},
	fwBold: {
		fontFamily: 'Helvetica-Bold',
	},
});

export default GlossaryPage;

import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12" style={{paddingBottom:'10px'}}>
                                    The <b>cash on cash return</b> is a percentage that measures the return on the cash invested in the first year of the investment.
                                    </div>
                                    
                                    <div class="col-md-12" style={{paddingTop:'5px',paddingBottom:'10px'}}>
                                    It differs from the <i>internal rate of return</i> and the <i>capitalization rate</i> in that the <b>cash on cash return</b> only focuses on the cash return in the first year, as opposed to the entire projected ownership life, therefore no compounding or discounting to the present is required.
                                    </div>

                                    <div class="col-md-12" style={{paddingTop:'5px',paddingBottom:'10px'}}>
                                    A way to view the cash on cash return is to compare it to a return on a certificate of deposit. You deposit money in the bank and the bank pays you an annual return, say <b>5%</b>. The <b>5%</b> is the cash on cash return for CD.
                                    </div>

                                    <div class="col-md-12" style={{paddingTop:'5px',paddingBottom:'10px'}}>
                                    Please note that the cash on cash return does not include property appreciation, which is a non-cashflow item until the year of sale. Therefore, if you are evaluating a property on a long-term basis, you need to focus on the annual cash flow as it relates to your investment rather than on property appreciation.
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The higher the <b>cash on cash return</b>, the better. However, it depends on the returns you would get on alternative investments, such as stocks or bonds.
                                    
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>cash on cash return</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                    <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                    
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Initial Investment
                                                    {this.state.indicator.property.financingType == "Loan" &&         

                                                            <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                                <table class="calculation-info-table">
                                                                
                                                                    {this.state.indicator.property.downPayment > 0 && 
                                                                        <tr>
                                                                            <td>Down Payment</td>
                                                                            <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                            <td></td>
                                                                            <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.downPayment)}</td>
                                                                        </tr>
                                                                    }

                                                                    {this.state.indicator.property.closingCosts > 0 && 
                                                                        <tr>
                                                                            <td>Closing Costs</td>
                                                                            <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                            <td>+</td>
                                                                            <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.closingCosts)}</td>
                                                                        </tr>
                                                                    }
                                                                
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_INITIAL_INVESTMENT}</b></td>
                                                                        </tr>

                                                                </table>

                                                                
                                                            </div>
                                                            }

                                                            {this.state.indicator.property.financingType == "Cash" &&        

                                                            <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                            <table class="calculation-info-table">

                                                                {this.state.indicator.property.price > 0 && 
                                                                    <tr>
                                                                        <td>Purchase Price</td>
                                                                        <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                        <td></td>
                                                                        <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.price)}</td>
                                                                    </tr>
                                                                }

                                                                {this.state.indicator.property.closingCosts > 0 && 
                                                                        <tr>
                                                                            <td>Closing Costs</td>
                                                                            <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                            <td>+</td>
                                                                            <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.closingCosts)}</td>
                                                                        </tr>
                                                                    }

                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_INITAL_INVESTMENT}</b></td>
                                                                    </tr>

                                                            </table>


                                                            </div>
                                                            } 
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_INITIAL_INVESTMENT}
                                                    </div>

                                                </div>

                                          
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Monthly Rent         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT}
                                                    </div>

                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Vacancy Rate         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_VACANCY_RATE}%

                                                    </div>

                                                </div>

                                                {this.state.indicator.property.financingType == "Loan" &&               
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Monthly Mortgage         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_MONTHLY_MORTGAGE_PAYMENT}
                                                    </div>

                                                </div>
                                                }

                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Annual Expenses
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_ANNUAL_EXPENSES}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>
                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                       
                                    {/*  STEP 1 */}
                                    {this.state.indicator.property.financingType == "Loan" && 
                                    <span>
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Annual Mortgage</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Annual Mortgage Payments = <br></br>
                                                       <b>Mortgage Payment x 12</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Annual Mortgage Payments = <br></br>
                                                        <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_MORTGAGE_PAYMENT} x 12 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Annual Mortgage Payments = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_MORTGAGE_PAYMENT}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>
                                        <br></br>
                                     </span> 
                                     }  
    
                                    {/*  STEP 2 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 2' : 'STEP 1'}:</span> <b>Calculate Gross Annual Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Gross Annual Income = <br></br>
                                                       <b>Monthly Rent x 12</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Annual Income = <br></br>
                                                        <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT} x 12 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Annual Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>
                                        <br></br>

                                        {/*  STEP 3 */}
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 3' : 'STEP 2'}:</span> <b>Calculate Vacancy and Credit Loss</b>
                                            </div>
                                        </div>

                                        
                                        <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table"  style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                                        Vacancy and Credit Loss = <br></br>
                                                       <b>(Gross Annual Income x Vacancy Rate) / 100</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Vacancy and Credit Loss = <br></br>
                                                        <b>({this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME} x {this.state.indicator.helpVariableMap.VAR_VACANCY_RATE}) / 100 </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Vacancy and Credit Loss = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_VACANCY_CREDIT_LOSS}</b>
                                                       </div>

                                              
                                           </div>
                                       </div>

                                        <br></br>


                                    {/*  STEP 3 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 4' : 'STEP 3'}:</span> <b>Calculate Gross Operating Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Gross Operating Income = <br></br>
                                                        <b>Gross Annual Income - Vacancy Credit Loss</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_ANNUAL_INCOME} - {this.state.indicator.helpVariableMap.VAR_VACANCY_CREDIT_LOSS}</b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Gross Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_OPERATING_INCOME}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        <br></br>

       
                                    {/*  STEP 5 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 5' : 'STEP 4'}:</span> <b>Calculate Net Operating Income</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Net Operating Income = <br></br>
                                                        <b>Gross Operating Income - Annual Expenses</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Net Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_GROSS_OPERATING_INCOME} - {this.state.indicator.helpVariableMap.VAR_ANNUAL_EXPENSES} </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Net Operating Income = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_NET_OPERATING_INCOME}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        <br></br>



                                    {/*  STEP 6 */}
                                    {this.state.indicator.property.financingType == "Loan" && 
                                    <span>
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 6:</span> <b>Calculate Net Annual Cash Flow</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Net Annual Cash Flow = <br></br>
                                                        <b>Net Operating Income - Annual Mortgage</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Net Annual Cash Flow = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_NET_OPERATING_INCOME} - {this.state.indicator.helpVariableMap.VAR_ANNUAL_MORTGAGE_PAYMENT} </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Net Annual Cash Flow = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_CASH_FLOW}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        <br></br>
                                        </span>
                                        }


       
                                    {/*  STEP 5 */}
                                    <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 7' : 'STEP 5'}:</span> <b>Calculate Cash on Cash Return</b>
                                           </div>
                                       </div>

                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:'10px',paddingLeft:'5%'}}>
                                            
                                                        Cash on Cash Return = <br></br>
                                                        <b>Net Annual Cash Flow / Initial Cash Investment</b>


                                                       <div style={{paddingTop:'10px'}}>
                                                       Cash on Cash Return = <br></br>
                                                       <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_CASH_FLOW} / {this.state.indicator.helpVariableMap.VAR_INITIAL_INVESTMENT} </b>
                                                       </div>

                                                       <div style={{paddingTop:'10px'}}>
                                                       Cash on Cash Return = <br></br>
                                                       <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                       </div>

                                             

                                           </div>
                                       </div>
                                        <br></br>

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Cash on Cash Return benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                        <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                INITIAL CASH INVESTMENT<br></br>
                                                MONTHLY RENT<br></br>
                                                VACANCY RATE<br></br>
                                                {this.state.indicator.property.financingType == "Loan" &&    
                                                <span>
                                                MORTGAGE PAYEMENTS<br></br>
                                                </span>
                                                }
                                                ANNUAL EXPENSES
                                                
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                
                  {/* TIP */}
                  {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                        {this.state.indicator.property.financingType  == "Loan" && 
                            <span><b>1)</b> Put down a larger <span style={{fontWeight:'600'}}>down payment</span><br></br></span>
                          }
                          {this.state.indicator.property.financingType  == "Cash" && 
                            <span><b>1)</b> Consider getting a <span style={{fontWeight:'600'}}>mortgage</span> instead of paying cash<br></br></span>
                          }
                            <b>2)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>3)</b> Increase the <span style={{fontWeight:'600'}}>monthly rent</span><br></br>          
                            <b>4)</b> Lower your <span style={{fontWeight:'600'}}>monthly expenses</span><br></br>             
                        </div>

                    </div>        
                }        
                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

import React, { Component } from 'react';

import AnalysisDialog from '../dialogs/AnalysisDialog';
import PropertyEditDialog from '../dialogs/PropertyEditDialog';
import IndicatorInfoDialog from '../dialogs/IndicatorInfoDialog';
import PhotoDialog from '../dialogs/PhotoDialog';

import repeatGrid1Image from '../pages/img/others/RepeatGrid1.png';

import { pdf } from '@react-pdf/renderer';
import PDFReport from '../components/pdf-report'; 

import { FileEarmarkText } from 'react-bootstrap-icons';
import { Trash } from 'react-bootstrap-icons';
import { BarChartLine } from 'react-bootstrap-icons';
import { Printer } from 'react-bootstrap-icons';
import { Link45deg } from 'react-bootstrap-icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import { getPlanInfo, convertPlanInfoUnlimitedToNumbers, convertPropertyDate, getElapsedHours } from '../utilities/AppUtility';

import { getReportData } from '../utilities/ReportUtility';

import { isIndicatorDisabled } from '../utilities/IndicatorUtility';

import { Helmet } from "react-helmet";

import Dialog from 'react-bootstrap-dialog'

import { authMiddleWare } from '../utilities/Auth';

//import BLO from '../utilities/BLO';
import * as BLO from '../utilities/BLO';

import axios from 'axios';

const { maxTrialPropertiesToShow, maxTrialPropertiesToAllowToAdd } = require('../utilities/Constants');

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
});


class Properties extends Component {

    state = {
        properties:[],
        uiLoading: true
    };

    constructor(props) {
        super(props);

            this.analysisDialogRef = React.createRef();
            this.propertyEditDialogRef  = React.createRef();
            this.indicatorInfoDialogRef = React.createRef();
            this.photoDialogRef = React.createRef();

            this.RenderIndicatorValue = this.RenderIndicatorValue.bind(this);
    }

    componentWillMount = () => {

        if (this.props.properties == null)
        {
            this.loadProperties();
        } else 
        {
            this.setState({
                properties: this.props.properties,
                uiLoading: false
            }, () => {
                this.checkHighResImagesExists(this.state.properties);
            }); 

            
        }

        if (this.props.settings == null || (this.props.settings != null && this.props.settings.benchmarkSettings == null))
        {
            this.loadSettings();
        }
    };

    componentWillReceiveProps = (nextProps) => {

       if (nextProps.properties != null && (this.props.properties.length !== nextProps.properties.length || JSON.stringify(this.props.properties) !== JSON.stringify(nextProps.properties) )) 
       {

        this.setState({
            properties: nextProps.properties,
            uiLoading: false
        }, () => {
            this.checkHighResImagesExists(this.state.properties);
        }); 

       }

     }; 

    loadProperties()
    {
        this.props.parentRef.loadProperties();
    }

    loadSettings()
    {
        try
        {
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
            axios
                .get('/api/settings')
                .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }
    
                    const settings = response.data;
                    const benchmarkSettings = BLO.getIndicatorBenchmarkSettings(settings);
                    settings.benchmarkSettings = benchmarkSettings;

                    //console.log("benchmarkSettings:");
                    //console.log(JSON.stringify(benchmarkSettings));

                    //console.log("Account Settings:");
                    //console.log(JSON.stringify(settings));

                    this.props.settings = settings;

                    this.setState({
                        settings: settings
                    });
                    
                })
                .catch((error) => {
                    console.log(error);
                    if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                    }
                });
            } catch(error)
            {
              console.log(error);   
            }

    }

    GetBenchmarkSettings(settings)
    {
      if (settings == null)
      {
        return BLO.getIndicatorBenchmarkSettings(settings);
      }else
      {
        return settings.benchmarkSettings;
      }
    }
  
    NormalizePropertyType(type,street)
    {
        //console.log("Property Type:");
        //console.log(type);
  
        if (type.toLowerCase().includes("manufactur") || type.toLowerCase().includes("mobile"))
        {
          type = "Mobile Home";
  
        } else if (type.toLowerCase().includes("apartment") || type.toLowerCase().includes("multi family") || type.toLowerCase().includes("duplex") || type.toLowerCase().includes("triplex") || type.toLowerCase().includes("fourplex"))
        {
          type = "Multi-family";
  
        } else if (street.toLowerCase().includes(" unit ") || street.toLowerCase().includes(" apt "))
        {
          type = "Condo";
  
        } else if (type.toLowerCase().includes("typehome") || type.toLowerCase().includes("singlefamily"))
        {
          type = "Single Family Home";

        } else if (type.toLowerCase().includes("lots") || type.toLowerCase().includes("land"))
        {
          type = "Lot";
        } 
  
        return type;
    }
  

    RenderIndicatorValue(value,valueType)
    {
        var formattedValue = value;

        if (valueType === 'DOLLAR_AMOUNT')
        {
            /*
            formattedValue =  value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              });
              */

              var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
                });

                formattedValue = formatter.format(value);

        } else if (valueType === 'PERCENT')
        {
            formattedValue = value+'%';
        }

        return formattedValue;
    }



    deletePropertyFromDB(id)
    {
        const property = {
            id: id
          };
      
        
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 
        axios
            .post('/api/deleteProperty', property)
            .then((response) => {
        
                if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

                console.log("Completed deleting property");
            
                this.loadProperties();
            })
            .catch((error) => {
                
                console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
                }
                
            });
    }  
    

    deleteProperty(id,event) {

        event.preventDefault();

        this.dialog.show({
            title: 'Delete Confirmation',
            body: 'Do you want to delete this property?',
            actions: [
              Dialog.CancelAction(),
              Dialog.OKAction(() => {
                
                this.deletePropertyFromDB(id);
              })
            ],
            bsSize: 'small',
            onHide: (dialog) => {
              dialog.hide()
              
            }
          })
    }

    openPhotoDialog(property,event) {

        event.preventDefault();

        var dialog = this.photoDialogRef.current;
        dialog.PopulateDialog(property);
    
    }

    openIndicatorInfoDialog(id,event,property,indicator) {

        event.preventDefault();

        const isDisabled = isIndicatorDisabled(property,indicator);

        if (isDisabled)
        {
            alert("This indicator is not applicable when you are paying for the property entirely with cash.");
        } else
        {
            //Although we have the indicator that was calculated by the back end jobs, it doesn't have the 
            //display variables needed for display since we excluded those from the back end to keep the 
            //payload small and to fix a circular reference issue in the lambda.
            const calculatedIndicator = this.calculateIndicator(property,indicator);

            //Also calculate future cashflows (Needed only for rental, PI and IRR)
            const clonedProperty = JSON.parse(JSON.stringify(property));

            const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
            const profile = this.props?.profile;
            
            //Needs to come from profile
            settings.budgetAccepted=profile?.budget;   
            settings.budgetRejected=profile?.budget;   
            settings.budgetAcceptedCompareId=5;
            settings.budgetRejectedCompareId=1;
        
            //Needs to come from property
            settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
            settings.pricePerSizeRejected = property.trendingPricePerSqft;
            settings.pricePerSizeAcceptedCompareId =  5;
            settings.pricePerSizeRejectedCompareId =  1;

            const propertyCashFlowMaps = BLO.calculateFutureCashFlows(clonedProperty,settings)

            calculatedIndicator.propertyCashFlowMaps = propertyCashFlowMaps; 
            clonedProperty.propertyCashFlowMaps = propertyCashFlowMaps; 
            calculatedIndicator.property = clonedProperty;

            //alert("Opening dialog info");
            var dialog = this.indicatorInfoDialogRef.current;
            dialog.PopulateDialog(calculatedIndicator);
           
        }

    }

    calculateIndicator(property,indicator)
    {

        switch(property.investmentType) {

            case "Primary Home":
              indicator = this.populatePrimaryHomeIndicator(property,indicator);
              break;
            case "Rental Property":
              indicator = this.populateRentalIndicator(property,indicator);
              break;
            case "Flipping Property":
              indicator = this.populateFlippingIndicator(property,indicator);
              break;
            
          }

        return indicator;
    }

    populatePrimaryHomeIndicator(property,indicator)
    {
      let calculatedIndicator = null;

      //const settings = Settings.GetProfileBenchmarkingSettings();
      const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
      const profile = this.props?.profile;
      
      //Needs to come from profile
      settings.budgetAccepted=profile?.budget;   
      settings.budgetRejected=profile?.budget;   
      settings.budgetAcceptedCompareId=5;
      settings.budgetRejectedCompareId=1;
  
      //Needs to come from property
      settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
      settings.pricePerSizeRejected = property.trendingPricePerSqft;
      settings.pricePerSizeAcceptedCompareId =  5;
      settings.pricePerSizeRejectedCompareId =  1;

      switch(indicator.indicatorAcronym) {

        case "BE":
             
            calculatedIndicator = BLO.getBackEndRatioIndicator(property,settings);
                
          break;
        
        case "FE":
            
            calculatedIndicator = BLO.getFrontEndRatioIndicator(property,settings);    
                
          break;
        
        case "DCR":
            
            calculatedIndicator = BLO.getDebtCoverageRatioHomeIndicator(property,settings);
                
          break;

        case "LVR":
              
            calculatedIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
                
            break;

        case "COD":
            
            calculatedIndicator = BLO.getCostOfDebtIndicator(property,settings);
              
            break;

        case "MVP":
            
            calculatedIndicator = BLO.getMarketValuePercentIndicator(property,settings);
              
            break;

        case "PPQ":
            
            calculatedIndicator = BLO.getPricePerSizeIndicator(property,settings);
          
            break;

        case "PTR":
            
            calculatedIndicator = BLO.getPriceToRentRatioIndicator(property,settings);
      
            break;

        case "CS":
            
            calculatedIndicator = BLO.getCreditScoreIndicator(property,settings);
      
            break;
    
        case "BUDGET":
                
                calculatedIndicator = BLO.getBudgetHomeIndicator(property,settings);
            
                break;
        
      }

      return calculatedIndicator;
    }
    
    FormatNumber(value)
    {
      
      try
      {
        if (value.toString().indexOf(",") <0)
        {
          value = value.toLocaleString("en-US")
        }
      } catch(error)
      {
  
      }
  
      return value;
    }
  
    populateFlippingIndicator(property,indicator)
    {
        let calculatedIndicator = null;

        //const settings = Settings.GetProfileBenchmarkingSettings();
        const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
        const profile = this.props?.profile;
    
        //Needs to come from profile
        settings.budgetAccepted=profile?.budget;   
        settings.budgetRejected=profile?.budget;   
        settings.budgetAcceptedCompareId=5;
        settings.budgetRejectedCompareId=1;
    
        //Needs to come from property
        settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
        settings.pricePerSizeRejected = property.trendingPricePerSqft;
        settings.pricePerSizeAcceptedCompareId =  5;
        settings.pricePerSizeRejectedCompareId =  1;

        switch(indicator.indicatorAcronym) {

            case "R70":
                    
                calculatedIndicator = BLO.get70PercentRuleIndicator(property,settings);
                      
              break;
            
            case "KRI":
                
                calculatedIndicator = BLO.getKeyRiskIndicator(property,settings);    
                      
              break;
            
            case "DCR":
                
                calculatedIndicator = BLO.getDebtCoverageRatioFlipIndicator(property,settings);
              
              break;
    
            case "LVR":
                
                calculatedIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
                      
                break;
    
            case "ROI":
                
                calculatedIndicator = BLO.getROIIndicator(property,settings);
                    
                break;
    
            case "NP":
                
                calculatedIndicator = BLO.getNetProfitIndicator(property,settings);
                    
                break;
    
            case "MVP":
                  
                calculatedIndicator = BLO.getMarketValuePercentIndicator(property,settings);
                
                break;
    
            case "PPQ":
                
                calculatedIndicator = BLO.getPricePerSizeIndicator(property,settings);
            
                break;
    
            case "BUDGET":
                
                calculatedIndicator = BLO.getBudgetIndicator(property,settings);
            
                break;
        
          }

          return calculatedIndicator;
    }
  
    populateRentalIndicator(property,indicator)
    {
        let calculatedIndicator = null;

        //const settings = Settings.GetProfileBenchmarkingSettings();
        const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.props.settings))); // clone it since need budget based on the profile value and trending price per sqft
        const profile = this.props?.profile;
        
        //Needs to come from profile
        settings.budgetAccepted=profile?.budget;   
        settings.budgetRejected=profile?.budget;   
        settings.budgetAcceptedCompareId=5;
        settings.budgetRejectedCompareId=1;
    
        //Needs to come from property
        settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
        settings.pricePerSizeRejected = property.trendingPricePerSqft;
        settings.pricePerSizeAcceptedCompareId =  5;
        settings.pricePerSizeRejectedCompareId =  1;

        switch(indicator.indicatorAcronym) {

            case "GRM":
                  
                calculatedIndicator = BLO.getGRMIndicator(property,settings);
                      
              break;
            
            case "CAP":
                
                calculatedIndicator = BLO.getCapRateIndicator(property,settings);
                      
              break;
            
            case "COC":
                
                calculatedIndicator = BLO.getCashOnCashIndicator(property,settings);
              
              break;
    
            case "PI":
                  
                calculatedIndicator = BLO.getProfitabilityIndexIndicator(property,settings);
                      
                break;
    
            case "IRR":
                
                calculatedIndicator = BLO.getInternalRateOfReturnIndicator(property,settings);
                    
                break;
    
            case "DCR":
                
                calculatedIndicator = BLO.getDebtCoverageRatioRentalIndicator(property,settings);
            
                break;
    
            case "BER":
                
                calculatedIndicator = BLO.getBreakEvenRatioIndicator(property,settings);
                
                break;
    
            case "LVR":
                
                calculatedIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
            
                break;
    
            case "NCF":
                
                calculatedIndicator = BLO.getNetCashFlowIndicator(property,settings);

                break;
        
          }

          return calculatedIndicator;
    }

    checkHighResImagesExists(properties)
    {
        /*
        This method is mainly for the demo profile, since properties in the 
        demo profile the property.propertyImage the data is in the field, but it has a lower quality.
        But the property.propertyImageHighResolution is a URL with a high quality image, but the 
        URL might not exist at sometime in the future.

        Note: However, this function would work on any property that has the propertyImageHighResolution field populated
        */

        try
        {
          
            for (let property of properties)
            {
                if (property.hasOwnProperty('propertyImageHighResolution') && property.propertyImageHighResolution != null && property.propertyImageHighResolution !== "")
                {
                    const me = this;
                    const url = property.propertyImageHighResolution;
                    this.ifUrlExist(url, function(exists) {

                       // console.log("IF URL EXISTS:"+exists);
                        if (!exists)
                        {
                           // console.log("CHANGING PROPERTY IMAGE");
                            property.propertyImageHighResolution = "";
                            
                            me.setState({          
                                properties:properties
                            });
                        }
                      });
          
                }       
            }

        }catch(error)
        {
            console.log(error);
        }

    }

    
    ifUrlExist(url, callback) {

        try
        {
            let request = new XMLHttpRequest;
            request.open('GET', url, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.setRequestHeader('Accept', '*/*');
            request.onprogress = function(event) {

                //console.log("CAME BACK ******");
                let status = event.target.status;
                let statusFirstNumber = (status).toString()[0];

                //console.log("STATUS:"+status);
                //console.log("statusFirstNumber:"+statusFirstNumber);

                switch (statusFirstNumber) {
                    case '2':
                        request.abort();
                        return callback(true);
                    default:
                        request.abort();
                        return callback(false);
                };
            };

            request.onerror = function(event) {
                request.abort();
                return callback(false);
            };

            request.send('');
        } catch(error)
        {
            console.log("ERROR CHECKING IMAGE URL");
            console.log(error);
        }
    }

    
    getDemoProfile() 
    {
        let demoProfile = null;

        try
        {
            if (this.props.parentRef != null
                &&
                this.props.parentRef.props != null 
                &&
                this.props.parentRef.props.parentRef != null 
                &&
                this.props.parentRef.props.parentRef.state != null 
                &&
                this.props.parentRef.props.parentRef.state.profiles != null 
                &&
                this.props.parentRef.props.parentRef.state.profiles.length > 0
                )
                {
                    const profiles = this.props.parentRef.props.parentRef.state.profiles;
                    const demoProfiles = profiles.filter(profile => profile.type === 'demo');
                    demoProfile = demoProfiles[0];
                }

        }catch(error)
        {

        }

        return demoProfile;
    }

    openPropertyEditDialog(property,event) {

        event.preventDefault();

        let planInfo = getPlanInfo(this.props?.user?.planName);

        planInfo = convertPlanInfoUnlimitedToNumbers(planInfo);

        let title = "Plan Limit Exceeded";
        let message= "You have exceeded the number of properties you can have for this plan. Please delete some of your existing properties or go to MY ACCOUNT and upgrade your plan.";
        let properties = planInfo.properties;

        if (this.props.user.planType === 'trial')
        {
            //properties= convertPlanInfoUnlimitedToNumbers(getPlanInfo("lite").properties); //We will limit to the lowest plan that way when they switch we will OK. But we will gray out all properties, except the first 2
            message= "You have exceeded the number of properties you can have for the trial version, you must UPGRADE to a subscription plan to get all features.";
            properties = maxTrialPropertiesToAllowToAdd;
        }
        
        //Don't allow adding properties in the demo profile
        if (this.props.profile != null && this.props.profile.type != null && this.props.profile.type === 'demo') //Can't save demo property
        {
            title = "Demo Profile Limits";
            message= "You can not add properties to the Demo profile, its purpose is to showcase the application features. Switch to the Default profile to add your own properties."
            properties = 0;
        }

        let accountProperties = this.state.properties;

        //Don't count demo properties in the total property count
        const demoProfile = this.getDemoProfile();
        if (demoProfile != null)
        {
            accountProperties = accountProperties.filter(property => property.profileId != demoProfile.id);
        }

        if (accountProperties.length >= properties && property == null)
        {
            this.dialog.show({
                title: title,
                body: message,
                actions: [
                  Dialog.OKAction(() => {
                    //console.log('OK was clicked!')
                    
                  })
                ],
                bsSize: 'small',
                onHide: (dialog) => {
                  dialog.hide()
                  
                }
              });

        } else
        {
    
        var dialog = this.propertyEditDialogRef.current;
        dialog.PopulateDialog(property,this,this.props.investmentType,this.props.profileId,this.props.settings,this.props.profile,this.props.user);
        }

    }

    async runReport(property,event) {

        event.preventDefault();

        try {
            const reportData = getReportData(property,this.props.profile,this.props.settings);
            
			const blob = await pdf(
				<PDFReport data={reportData} />
			).toBlob();

			const pdfURL = URL.createObjectURL(blob);
			window.open(pdfURL, '_blank');
		} catch (error) {
			console.error('Report generation failed due to error: ', error);
		}
     
    }

    openAnalysisDialog(property,event) {

        event.preventDefault();

        var dialog = this.analysisDialogRef.current;
        dialog.PopulateDialog(property,this.props.settings,this.props.profileId,this.props.profile);
    }


    getIndicatorCountList(property)
    {

        let indicatorList = [];

        let map = new Map();
        map.set('Rental PropertyGRM', 1);
        map.set('Rental PropertyCAP', 2);
        map.set('Rental PropertyCOC', 3);
        map.set('Rental PropertyPI', 4);
        map.set('Rental PropertyIRR', 5);
        map.set('Rental PropertyDCR', 6);
        map.set('Rental PropertyBER', 7);
        map.set('Rental PropertyLVR', 8);
        map.set('Rental PropertyNCF', 9);

        map.set('Flipping PropertyR70', 1);
        map.set('Flipping PropertyKRI', 2);
        map.set('Flipping PropertyDCR', 3);
        map.set('Flipping PropertyLVR', 4);
        map.set('Flipping PropertyROI', 5);
        map.set('Flipping PropertyNP', 6);
        map.set('Flipping PropertyMVP', 7);
        map.set('Flipping PropertyPPQ', 8);
        map.set('Flipping PropertyBUDGET', 9);

        map.set('Primary HomeBER', 1);
        map.set('Primary HomeFE', 2);
        map.set('Primary HomeDCR', 3);
        map.set('Primary HomeLVR', 4);
        map.set('Primary HomeCOD', 5);
        map.set('Primary HomeMVP', 6);
        map.set('Primary HomePPQ', 7);
        map.set('Primary HomePTR', 8);
        map.set('Primary HomeBUDGET', 9);

        let indicatorMap = new Map();

        
        if (property != null && property.hasOwnProperty('indicators'))
        {
            let i = 0;
            for (let indicator of property.indicators) {

                
                let status = "";
                if (indicator.meetsBenchmark)
                {
                    status = "passed";
                }else
                {
                    status = "failed";
                }

                if (isIndicatorDisabled(property,indicator))
                {
                    status = "disabled"
                }

                indicatorMap.set(map.get(property.investmentType+indicator.indicatorAcronym),{"indicatorName":indicator.indicatorName,"status":status});
            }

            //sort by value
             let sortedMapValues = new Map(
                 [...indicatorMap.entries()].sort((a, b) => String(b[1]).localeCompare(a[1]))
             );
           
             //add to list
             for (let record of sortedMapValues.values())
             {
                indicatorList.push(record);
             }
           
        }

        return indicatorList;	
    }

    getGreenIndicatorCountList(property)
    {
        const greenIndicatorCountList = [];
        if (property != null && property.hasOwnProperty('indicators'))
        {
            let i = 0;
            for (let indicator of property.indicators) {
                if (indicator.meetsBenchmark && !isIndicatorDisabled(property,indicator))
                {
                greenIndicatorCountList.push(i);
                }
            }

        }

        return greenIndicatorCountList;	
    }

    getRedIndicatorCountListOLD(property)
    {
        const redIndicatorCountList = [];
        if (property != null && property.hasOwnProperty('greenIndicatorsFound'))
        {
            const remaining = 9 - property.greenIndicatorsFound;
            
            for (let i = 1; i <= remaining; i++) {
            redIndicatorCountList.push(i);
            }

        }

        return redIndicatorCountList;	
    }


    getRedIndicatorCountList(property)
    {
        const redIndicatorCountList = [];
        if (property != null && property.hasOwnProperty('indicators'))
        {
            let i = 0;
            for (let indicator of property.indicators) {
                if (!indicator.meetsBenchmark && !isIndicatorDisabled(property,indicator))
                {
                    redIndicatorCountList.push(i);
                }
            }

        }

        return redIndicatorCountList;	
    }

    filterInputChange = (event) => {
  
        let propertyValue = event.target.value;
       
        this.props.parentRef.filterInputChanged(propertyValue);

    };

    getPropertyDate(property)
    {
        let formattedPropertyDate = "";

        let propertyDate = null;
        
        if (property.alertId != null && property.alertId !=="")
        {
            propertyDate = new Date(property.releasedDate._seconds * 1000);

        } else 
        {
            propertyDate = new Date(property.createdDate);  
        }

        formattedPropertyDate = propertyDate.toLocaleDateString();

        return formattedPropertyDate;
    }

    getPropertyClassName(property,index)
    {
        let className = "col-md-12";

        try
        {
            //1. NEW label: Figure out if the property needs to the NEW label
            let propertyDate = null;
            let lastLoginDate = null;

            if (property.alertId != null && property.alertId !=="") //Created from service
            {
                propertyDate = new Date(property.releasedDate._seconds * 1000);
                lastLoginDate = new Date(this.props.user.previousLoginDate);  

            } else                                                  //Created manually
            {
                propertyDate = new Date(property.createdDate);  
                lastLoginDate = new Date(this.props.user.lastLoginDate);  
            }
     
            if (propertyDate > lastLoginDate && getElapsedHours(propertyDate.toISOString()) < 24) //This is to limit to show only the properties added in the last 24 hours
            {
                className = "col-md-12 properties-row-label";
            }

            //2. Blur Trial Subscription: Figure out if the trial property records needs to be blured
            
            if (this.props.user != null && this.props.user.planType === 'trial')
            {
                if (this.props != null && this.props.profile != null && this.props.profile.type != null && this.props.profile.type=='demo')
                {
                    // I don't want to blur the demo records
                } else 
                {
                    const rowIndex = index+1;

                    if (rowIndex > maxTrialPropertiesToShow)
                    {
                        className = className + " blur-component";
                    }
                }
            }

        } catch(error)
        {
            console.log(error);
        }

        return className;
    }

    getTrialMessageClassName(property,index)
    {
        let className = "trial-property-message-hide";

        try
        {
            if (this.props.user != null && this.props.user.planType === 'trial')
            {
                if (this.props != null && this.props.profile != null && this.props.profile.type != null && this.props.profile.type=='demo')
                {
                    // I don't want to blur the demo records
                } else 
                {
                    const rowIndex = index+1;

                    if (rowIndex > maxTrialPropertiesToShow)
                    {
                        className = "trial-property-message-show";
                    }
                }
            }

        } catch(error)
        {
            console.log(error);
        }

        return className;
    }

    
    render() {

        const { classes } = this.props;

        if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
        
            return (
            
            <div>
          
                    <div class="toggle-div">
                
                        <div style={{width:'100%'}}>

                            <div class="row">
                                <div class="col-md-4">
                                    <button type="button" class="btn btn-primary add-btn"  onClick={(e) => this.openPropertyEditDialog(null, e)} style={{marginBottom:"0px",marginLeft:"0px",marginTop:'10px'}}>
                                        ADD
                                    </button>
                                </div>

                                {/* FILTER*/}
                                <div class="col-md-4 filter-input-icons" style={{paddingTop:'18px'}}>
                                    <i class="fa fa-search filter-icon"></i>
                                    <input type="text" class="form-select filter-input-component" style={{height:'30px',paddingLeft:'35px'}} id="filter" placeholder="Enter street, city, price" name="filter" defaultValue={this.props.filter}  onChange={this.filterInputChange}/>
                                
                                </div>
                            </div>

                        </div>

                            
                            </div>

                                                    <div classx="top-row-list-properties">
                                            
                                                        {/* Note: The ? after properties prevents errors if array is empty*/}    
                                                        {/* this.state.properties?.map((property, i) => ( */}

                                                        {this.state.properties?.filter(property => property.investmentType == this.props.investmentType && property.profileId == this.props.profileId).map((property,i) => (	

                                                        <div class="row properties-row">
                                                            
                                                            {/* Message to show when the trial max has reached*/}
                                                            <div class={this.getTrialMessageClassName(property,i)}>
                                                                To unlock <b><a href="/pricing">UPGRADE</a></b> to a subscription plan
                                                            </div>
                                                            
                                                            {/* Actual property */}
                                                            <div class={this.getPropertyClassName(property,i)} style={{marginLeft:'0px'}}>
                                                                
                                                                <div class="custom-control custom-checkbox property-table-padding" styleMOBILE={{paddingLeft:'0px'}}>
                                                                    
                                                                    {/* <input type="checkbox" class="custom-control-input" id={`propertyCheck${i}`} /> */}

                                                                    <label class="custom-control-label custom-label" forX={`propertyCheck${i}`}>
                                                                        <div class="row">
                                                                            <div class="col-lg-5 properties-top-col">
                                                                                <div class="row">
                                                                                    <div class="col-md-5 text-center">
                                                                                        <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openPhotoDialog(property, e)}>
                                                                                            {(!property.hasOwnProperty('propertyImageHighResolution') || property.propertyImageHighResolution == null || property.propertyImageHighResolution ==='') && 
                                                                                            <img src={`${property.propertyImage}`}  class="property-img" alt="" />
                                                                                            }
                                                                                        </a>
                                                                                        <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openPhotoDialog(property, e)}>
                                                                                            {(property.hasOwnProperty('propertyImageHighResolution') && property.propertyImageHighResolution != null && property.propertyImageHighResolution !=='') && 
                                                                                            <img src={`${property.propertyImageHighResolution}`}  class="property-img" alt="" />
                                                                                            }
                                                                                        </a>
                                                                                       <span class="property-date"> Added: {this.getPropertyDate(property)}</span>
                                                                                        <h3 class="grid-price">{this.RenderIndicatorValue(property.price,"currency")}</h3>
                                                                                    </div>
                                                                                    <div class="col-md-7">
                                                                                        <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openPropertyEditDialog(property, e)}>
                                                                                            <h2 class="property-heading">{property.nickName}</h2>
                                                                                            <p class="house-address">
                                                                                                {property.address}, {property.city}, {property.state}, {property.zipcode}
                                                                                                <br></br>
                                                                                                {property.numberBedrooms} bd | {property.numberBathrooms} ba | {this.FormatNumber(property.livingAreaSize)} sqft  
                                                                                                <br></br>
                                                                                                {this.NormalizePropertyType(property.propertyType,property.address)}  
                                                                                            </p>
                                                                                        </a>
                                                                                        <hr />
                                                                                        <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openAnalysisDialog(property, e)}>
                                                                                            <div class="indicators">
                                                                                        
                                                                                            

                                                                                            {this.getIndicatorCountList(property).map((indicatorCheck, index) => ( 
                                                                                                
                                                                                                <span>
                                                                                                {indicatorCheck.status == "passed" && 
                                                                                                <i class="fas fa-check-square greenish-tick"></i>
                                                                                                }

                                                                                                {indicatorCheck.status == "failed" && 
                                                                                                <i class="fas fa-check-square red-tick"></i>
                                                                                                }

                                                                                                {indicatorCheck.status == "disabled" && 
                                                                                                <i class="fas fa-check-square gray-tick"></i>
                                                                                                }

                                                                                                </span>

                                                                                            ))}

                                                                                            {/*
                                                                                            {this.getGreenIndicatorCountList(property).map((greenIndicatorCount, index) => ( 
                                                                                                <i class="fas fa-check-square greenish-tick"></i>
                                                                                            ))}

                                                                                            {this.getRedIndicatorCountList(property).map((redIndicatorCount, index) => ( 
                                                                                                <i class="fas fa-check-square red-tick"></i>
                                                                                            ))}
                                                                                            */}
                                                                                                
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-7 value-top-col">
                                                                                <div class="row">

                                                                                    {property.indicators.map((indicator, index) => (  
                                                                                    
                                                                                        <div className={`col-md-3 property-indicator-width`}  style={{display:`${index > 4  && "none"}`}}>{index > 4  && "dont-display"}
                                                                                            {index <= 4  &&
                                                                                                <span>
                                                                                                    <p class="rate-heading text-center property-indicator-height">{indicator.indicatorName}</p>
                                                                                                    {!isIndicatorDisabled(property,indicator) && 
                                                                                                    <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(1, e, property,indicator)}>
                                                                                                        <p className={`rate-value text-center ${indicator.meetsBenchmark == true  && "green-value"} ${indicator.meetsBenchmark == false  && "red-value"}`}>

                                                                                                            {this.RenderIndicatorValue(indicator.indicatorValue,indicator.valueType)}
                                                                                                        </p>
                                                                                                    </a>
                                                                                                    }

                                                                                                    {isIndicatorDisabled(property,indicator) && 
                                                                                                        <p style={{textAlign:'center',fontWeight:'bold'}}>
                                                                                                            N/A
                                                                                                        </p>
                                                                                                    }

                                                                                                </span>
                                                                                            }
                                                                                        </div>

                                                                                    ))}
                                                                                </div>

                                                                                <div class="row">

                                                                                {property.indicators.map((indicator, index) => (  
                                                                                    
                                                                                    <div className={`col-md-3 property-indicator-width`}  style={{display:`${index <= 4  && "none"}`}}>{index <= 4  && "dont-display"}
                                                                                        {index > 4  &&
                                                                                            <span>
                                                                                                <p class="rate-heading text-center property-indicator-height">{indicator.indicatorName}</p>
                                                                                                {!isIndicatorDisabled(property,indicator) && 
                                                                                                <a href="" style={{textDecoration:"none"}} onClick={(e) => this.openIndicatorInfoDialog(1, e, property,indicator)}>
                                                                                                    <p className={`rate-value text-center ${indicator.meetsBenchmark == true  && "green-value"} ${indicator.meetsBenchmark == false  && "red-value"}`}>
                                                                                                    
                                                                                                        {this.RenderIndicatorValue(indicator.indicatorValue,indicator.valueType)}
                                                                                                    </p>
                                                                                                </a>
                                                                                                }

                                                                                                {isIndicatorDisabled(property,indicator) && 
                                                                                                    <p style={{textAlign:'center',fontWeight:'bold'}}>
                                                                                                        N/A
                                                                                                    </p>
                                                                                                }
                                                                                            </span>
                                                                                        }
                                                                                    </div>

                                                                                ))}

                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <h3 class="total-value">{this.RenderIndicatorValue(property.price,"DOLLAR_AMOUNT")}</h3>
                            
                                                                                <h3 class="total-value">
                                                                                <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openAnalysisDialog(property, e)}><BarChartLine className="ml-2" style={{height:"24px"}}/> </a>
                                                                                &nbsp;
                                                                                <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.openPropertyEditDialog(property, e)}><FileEarmarkText className="ml-2" style={{height:"24px"}}/> </a>
                                                                                &nbsp;
                                                                                {property.propertyLink != null && property.propertyLink != '' &&
                                                                                    <a href={property.propertyLink} target="_blank" id="propertyDetailLink"  tyle={{textDecoration:"none",color:"#FFFFFF"}}>
                                                                                        <Link45deg  style={{fontSize:'25px',color:"#FFFFFF"}}/>
                                                                                    </a>
                                                                                }
                                                                                &nbsp;
                                                                                <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.runReport(property, e)}><Printer className="ml-2" style={{height:"24px"}}/> </a>
                                                                                &nbsp;
                                                                                <a href="#!" style={{textDecoration:"none",color:"#FFFFFF"}}  onClick={(e) => this.deleteProperty(property.id, e)}><Trash className="ml-2"  style={{height:"24px"}}/></a>
                                                                                </h3>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>

                                            
                                                    </div>
                                                        </div>
                                                    
                                                        ))} 
&nbsp;
                                                        {/*    No Properties   */}
                                                        {this.props?.propertyCounts?.selectedInvestmentPropertyCount === 0 && 

                                                            <div class="no-record-found-properties">
                                                                 No properties found.<br></br>
                                                                 You can add new properties for the selected profile by clicking the <b>ADD</b> button or by creating an <b>ADVANCED SEARCH</b> from the left navigation menu.
                                                           
                                                            </div>    
                                                        }


                            <Dialog ref={(component) => { this.dialog = component }}/> 

                            <AnalysisDialog ref={this.analysisDialogRef}></AnalysisDialog>       
                            <PropertyEditDialog ref={this.propertyEditDialogRef}></PropertyEditDialog>    
                            <IndicatorInfoDialog ref={this.indicatorInfoDialogRef}></IndicatorInfoDialog>   
                            <PhotoDialog ref={this.photoDialogRef}></PhotoDialog> 
                            
                    </div>

                                                        
                {/* <button class="btn load-more-btn">Load More</button>  */}


                    <Helmet>
                        <script src="js/grid-functions.js" type="text/javascript" />
                        <script src="vendor/jquery/jquery.min.js"></script>
                        <script src="js/sb-admin-2.min.js"></script>
                        <script src="vendor/bootstrap/bootstrap.bundle.min.js"></script>
                    </Helmet>
                                                                        
                </div>

            
                
            );
                                                                                        }
        }
}

export default (withStyles(styles)(Properties));


var StringBuffer = require("stringbuffer");// npm install stringbuffer

var IndicatorVO = require("./IndicatorVO.js");

const { plans } = require('./Constants');

export const convertPlanInfoUnlimitedToNumbers = (planInfo) => {

	const planInfoNew =  JSON.parse(JSON.stringify(planInfo));

	if (planInfoNew.logins==="unlimited")
	{
		planInfoNew.logins = 1000000;
	}

	if (planInfoNew.profiles==="unlimited")
	{
		planInfoNew.profiles = 1000000;
	}

	if (planInfoNew.properties==="unlimited")
	{
		planInfoNew.properties = 1000000;
	}

	if (planInfoNew.advancedSeaches==="unlimited")
	{
		planInfoNew.advancedSeaches = 1000000;
	}

	return planInfoNew;
}

export const upperCaseFirstLetter = (str) => {

	if (str != null)
	{
		return str.charAt(0).toUpperCase() + str.slice(1);
	} else
	{
		return "";
	}
}

export const getPlanInfo = (planName) => {

	let planInfo = {};

	if (planName != null)
	{
		planInfo = plans[planName];
	}

	return planInfo;
}


export const getElapsedDays = (user) => {
	
	let Difference_In_Time = 0;

	const date1 = new Date(user.createdAt);
	const date2 = new Date();

	if (user != null && date1 !="" && date2 != "")
	{

		Difference_In_Time = date2.getTime() - date1.getTime();

		// To calculate the no. of days between two dates
		var Difference_In_Days = parseInt(Difference_In_Time / (1000 * 3600 * 24));
	}

	return Difference_In_Days;

}


export const getElapsedHours = (theDateString) => {
	
	let Difference_In_Time = 0;

	const date1 = new Date(theDateString);
	const date2 = new Date();

	if (theDateString != null && theDateString != "" && date1 !="" && date2 != "")
	{

		Difference_In_Time = date2.getTime() - date1.getTime();

		// To calculate the no. of hours between two dates
		var Difference_In_Days = parseInt(Difference_In_Time / (1000*60*60));
	}

	return Difference_In_Days;

}

export const getTermYears = (value) => {

	 let years =0;
	 
	 value = value.replace("Year","").trim();
	 
	 years = parseInt(value);

	/*
	 if (value.equals("10 Year"))
	 {
		 years=10;
	 
	 }else if (value.equals("15 Year"))
	 {
		 years=15;
		 
	 } else if (value.equals("30 Year"))
	 {
		 years=30;
		 
	 } else if (value.equals("40 Year"))
	 {
		 years=40;
		 
	 } else if (value.equals("50 Year"))
	 {
		 years=50;
	 } 
	 */
		 
	 return years;
 
};


export const incrementByPercent = (value,percentagePoint) => {

		value = parseInt(value);
		percentagePoint = parseFloat(percentagePoint);

		let increasedValue = value;

		if (value != 0 && percentagePoint != 0)
		{
			let percentage = (value * percentagePoint)/100;
			increasedValue = increasedValue + percentage;
		}

		return increasedValue;
	
};


export const getNumberFromString = (value) => {

	const formattedValue = Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
 
	 return formattedValue;
   
};


export const getAmountStringFromDouble = (value) => {

	value = parseInt(value);
	let dollarUS = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});


	let formattedValue = dollarUS.format(value);

	if (formattedValue.endsWith(".00"))
	{
		formattedValue = formattedValue.substring(0, formattedValue.length-3);
	}
	//alert(formattedValue);

  
	  return formattedValue;
	
};


export const getAmountStringFromDoubleNoFractionalPart = (value) => {

	return getAmountStringFromDouble(value);
	
};


export const clearBuffer = (buffer) => {

		if (buffer != null)
		{
			buffer = new StringBuffer();
		}

	return buffer;
	
};

export const getPercentValue = (number) => {

	let value = number.toString() +"%";

    return value;		  
	  
};

export const getNumberValue = (number) => {

		  let value =null;
		  
		  value= number.toString();		
					  
		  return value;	
	  
};


export const getIndicatorValue = (valueType,value) => {

		let result = 0;
		
		if (value != null)
		{
			switch (valueType) {
			case IndicatorVO.DECIMAL:
				
				if (value.toString().indexOf(",") <0)
				{
					result = value.toLocaleString("en-US")
				}
			
				break;

			case IndicatorVO.DOLLAR_AMOUNT:
				result = getAmountStringFromDouble(value);
				break;

			case IndicatorVO.PERCENT:
				
				result = getPercentValue(value);

				break;
			}
		}

		return result;
	
};


export const getSaleProceedsYear = (property) => {

		let saleProceedsYear = 0;

		let value = property.yearOfSale;

		if (value != null)
		{	
			const searchRegExp = /Year/g;
			const replaceWith = '';
			value = value.replace(searchRegExp, replaceWith);

			value = value.trim();
			
			saleProceedsYear = parseInt(value);
		}
				
		return saleProceedsYear;
	
};

export const roundToTheNearest = (value,nearest) => {

		let valueMultiplied = value * nearest;
		let valueRounded = Math.round(valueMultiplied);
		let valueDivided = valueRounded / nearest;

		return valueDivided;
	
};


export const setAll = (map1,map2) => {

    for (let [key, value] of map1) {
        //console.log(key + " = " + value);
        map2.set(key,value);
    }

};

export const parseOutFirstName = (name) => {

	let firstName = '';

	try
	{
		if (name != null && name !='')
		{
			var details=name.split(' ');

			if (details.length>0)
			{
				firstName = details[0];
				if (firstName != null)
				{
					firstName = firstName.trim();
				}
			}
		}

	} catch(err)
	{
		firstName = '';
	}

	return firstName;
};

export const capitalizeWord = (str) => {	

	if (str != null)
	{
		return str.charAt(0).toUpperCase() + str.slice(1);
	} else
	{
		return "";
	}
};

export const convertPropertyDate = (dateString) => {

	let formattedDate = "";

	try 
	{
		const count = dateString.split("/").length - 1;
		if (count == 2) 
		{
			formattedDate = this.convertShortDateString(dateString);
		} else
		{
			formattedDate = this.convertLongDateString(dateString);
		}
	} catch(error)
	{
		//console.log(error);
	}

	return formattedDate;
}

export const convertLongDateString = (dateString) => {

	let formattedDate = "";

	try {
		let date = new Date(dateString);

		formattedDate = (date.getMonth()+1).toString()+"/"+(date.getDate()+1).toString()+"/"+date.getFullYear();
	} catch(error)
	{

	}

	return formattedDate;
}

export const convertShortDateString = (dateString) => {

	let formattedDate = "";

	try {
	   
		formattedDate = dateString.replace("/0","/").trim();
		if (formattedDate.startsWith("0"))
		{
			formattedDate = formattedDate.substring(1,formattedDate.length).trim();
		}

		let start = formattedDate.indexOf(" ");
		if (start >=0)
		{
			formattedDate = formattedDate.substring(0,start).trim();
		}

	} catch(error)
	{
		formattedDate = dateString;
	}

	return formattedDate;
}

/*
exports.getTermYears = getTermYears;
exports.incrementByPercent = incrementByPercent;
exports.getAmountStringFromDouble = getAmountStringFromDouble;
exports.getAmountStringFromDoubleNoFractionalPart = getAmountStringFromDoubleNoFractionalPart;
exports.clearBuffer = clearBuffer;
exports.getPercentValue = getPercentValue;
exports.getNumberValue = getNumberValue;
exports.getIndicatorValue = getIndicatorValue;
exports.getSaleProceedsYear = getSaleProceedsYear;
exports.roundToTheNearest = roundToTheNearest;
exports.getNumberFromString = getNumberFromString;
exports.parseOutFirstName = parseOutFirstName;
exports.capitalizeWord = capitalizeWord;
exports.getPlanInfo = getPlanInfo;
exports.convertPlanInfoUnlimitedToNumbers = convertPlanInfoUnlimitedToNumbers;
exports.upperCaseFirstLetter = upperCaseFirstLetter;
exports.getElapsedDays = getElapsedDays;
exports.convertPropertyDate = convertPropertyDate;
exports.convertLongDateString = convertLongDateString;
exports.convertShortDateString = convertShortDateString;

*/


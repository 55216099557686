import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';

//import appLogo from '../images/logo-nocircle.png';

import closeIcon from './img/icons/x2.png';

import { parseOutFirstName } from '../utilities/AppUtility';

import axios from 'axios';

import { authMiddleWare } from '../utilities/Auth';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#ff642d'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class viewer extends Component {
	constructor(props) {
		super(props);

		this.state = {
          	proxy: '',
			password: '',
			errors: [],
			loading: false,
			okToRender: false
		};
	}

	componentWillMount = () => {

		try
		{
			// 2. check user is logped in
			console.log("About to check logged in user.");

			authMiddleWare(this?.props?.history);
			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.get('/api/alreadyLoggedInCheck')
				.then((response) => {

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					//console.log(JSON.stringify(response));

					if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
					{
						axios
						.get('/api/performValidityCheck')
						.then((response) => {

							//Do nothing, let this page render

							this.setState({
								okToRender: true
							}); 
						})
						.catch((error) => {
	
								this.props.history.push("/app");
							
						});
					} 

				})
				.catch((error) => {
					try
					{
						//console.log(error);
						if(error.response.status === 403) {
							//Do nothing, let this page render to allow user to log in
							this.props.history.push("/login");
						} else
						{
							this.props.history.push("/app");
						}
					} catch(error)
					{
						this.props.history.push("/app");
					}
					
				});
			
			} catch(err)
			{
				console.log("Error checking logged in user:"+err);
			
			}
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.UI.errors) {
			this.setState({
				errors: nextProps.UI.errors
			});
		}
	}


	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();


		if (this.state.proxy ==="")
		{
			alert("Enter valid proxy");
			return;
		}

		if (this.state.password ==="")
		{
			alert("Enter valid password");
			return;
		}

		//const firstName= parseOutFirstName(this.state.name);
		this.setState({ loading: true });
		//this.setState({ firstName: firstName });

		const newData = {
			proxy: this.state.proxy,
			password: this.state.password
		};

	    authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 

		axios
			.post('/api/setProxy', newData)
			.then((response) => {
				
				if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

				this.setState({ 
					loading: false,
				});
				
				alert("Proxy Set");
				this.props.history.push('/app');

			})
			.catch((error) => {
				
				alert("There was an issue while setting proxy. Try again later.");

				this.setState({
					errors: error,
					loading: false
				});
			});
	};

	
	checkValue = (e) => {	
		//var value = e.target.value;
		var value = e.target.checked;
		//alert("You selected " + value);

		this.setState({
			termsChecked: value
		});

	  }

	handleTermsAndPrivacyChange = () => {
		//setChecked(!checked);
	  };

	goHome = () => {
		window.location.href ="/";
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#ff642d' //your color
			  }
			}
		  });
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<div>

			{this.state.okToRender && 	
			 <div>
	
			<Container component="main" maxWidth="xs">
				
				<CssBaseline />
				<div className={classes.paper}>

			{/*	<img src={appLogo} style={{height:'45px',cursor:'pointer'}} onClick={this.goHome}/>  */}
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#ff642d',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
						Real State
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Set
					</Typography>
					<form className={classes.form} noValidate>
					<ThemeProvider theme={theme}>
						<Grid container spacing={2}>
					
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="proxy"
									label="Proxy"
									type="regular"
									name="proxy"  
									inputProps={{style: {fontSize: 23}}}
									InputLabelProps={{style: {fontSize: 23}}}
									autoComplete="proxy"
									helperText={errors.proxy}
									error={errors.proxy ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>


							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									inputProps={{style: {fontSize: 23}}}
									InputLabelProps={{style: {fontSize: 23}}}
									autoComplete="current-password"
									helperText={errors.password}
									error={errors.password ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>
					
					
						</Grid>

						<br></br>
			
						<Button
							type="submit"
							fullWidth
							variant="contained" 
							style={{color:'#FFFFFF',fontSize:'20px',fontWeight:'bold'}}
							color="primary"
							className={classes.submit}
							onClick={this.handleSubmit}
                            disabled={loading}
						>
							Submit
							{loading && <CircularProgress size={30} className={classes.progess} />}
						</Button>
					
						</ThemeProvider>
					</form>
					<br></br><br></br><br></br>
				</div>
			</Container>
			</div>
			}

			{!this.state.okToRender && 
				<div>Logging out user</div>	
			}

		  </div>	
		);
	}
}

export default withStyles(styles)(viewer);

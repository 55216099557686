import React, { Component } from 'react';
import { Modal, Button, Dropdown, DropdownButton, Glyphicon,OverlayTrigger,Tooltip,Accordion,Card } from "react-bootstrap";
import MaskedInput from 'react-text-mask' 

import { InfoCircleFill } from 'react-bootstrap-icons';

import { XCircleFill} from 'react-bootstrap-icons';

import { Helmet } from "react-helmet";

import CurrencyInput from '../types/CurrencyInput'

import stopicon from '../pages/img/icons/stop2-sm.png';
import goicon from '../pages/img/icons/go2-sm.png';

import zipcodeRangeIcon from '../pages/img/icons/zip-range-icon-sm.png';
import zipcodeIcon from '../pages/img/icons/zip-icon-sm.png';
import cityStateIcon from '../pages/img/icons/city-icon-sm.png';

import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from 'react-bootstrap-dialog'

import CustomizedSlider from '../types/CustomizedSlider';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '40%',
		top: '35%'
	}
});

class AlertEditDialog extends Component { 
    state = {
        isOpen: false,
        areComponentsRendered: false,  
        showValidationMessage:false,
        validationErrors: [],
        alertName : "",
        fromDate : "",
        toDate : "",
        fromPrice : "",
        fromPriceAsNumber : 0,
        toPrice : "",
        toPriceAsNumber : 0,
        investmentType : 'Primary Home',
        propertyType : 'Single Family Home',
        type : "single-home",
        locateBy : "zipcode",
        numberBedrooms:'-',
        numberBathrooms:'-',
        livingAreaSize: '0',
        livingAreaSizeAsNumber : 0,
        minimumGreenIndicatorCount: '4',
        zipCodeList : [{ zipcode: ""}],
        fromZipcode : "",
        toZipcode : "",
        city : "",
        state : "",
        alert:null,
        profileId:"",

        uiSaving: false
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.PopulateDialog = this.PopulateDialog.bind(this);
     /*
        this.FetchRecordFromDatabase = this.FetchRecordFromDatabase.bind(this);
        this.PresetComponents = this.PresetComponents.bind(this);
        this.SortIndicatorList = this.SortIndicatorList.bind(this);
        this.SortByIndicator = this.SortByIndicator.bind(this);
        this.CreateIndicatorListCopy = this.CreateIndicatorListCopy.bind(this);
        this.GetFilteredIndicatorTypes = this.GetFilteredIndicatorTypes.bind(this);
        this.SetSelectedLocateByDropdown = this.SetSelectedLocateByDropdown.bind(this);
        this.CreateEmptyRecord = this.CreateEmptyRecord.bind(this);
        this.SetIndicatorNameSelectedIndex = this.SetIndicatorNameSelectedIndex.bind(this);
        this.GetPreFilteredSelectedValues = this.GetPreFilteredSelectedValues.bind(this);
        
     */
        this.locateByDropdownRef = React.createRef();
        
        //Populare indicator list before rendering
        var currentIndicatorList = [];
  
        this.SortIndicatorList(currentIndicatorList);
        this.state.indicatorTypes = currentIndicatorList;
        this.setState({indicatorTypes : currentIndicatorList });

        this.alertEditoDialogRef =  React.createRef();
  
     }

     componentDidUpdate() {
        this.PresetComponents();
    }

    componentDidMount() {
   
    }

    componentWillUnmount() {
    }

  PopulateDialog(alert,profileId,profile,user,parentReference)
  {      
      this.ClearFields();

      //console.log("ALERT ---------------:");
      //console.log(JSON.stringify(alert));

      this.setState({
        profileId: profileId,
        profile:profile,
        user:user
      });

      if (alert != null)
      {

          let number_bedrooms = '-';  
          let number_bathrooms = '-';

          if (alert.number_bedrooms != null && alert.number_bedrooms != '')
          {
            number_bedrooms = alert.number_bedrooms;
          }

          if (alert.number_bathrooms != null && alert.number_bathrooms != '')
          {
            number_bathrooms = alert.number_bedrooms;
          }

          this.setState({
            alertName : alert.alert_name,
            fromDate : this.ReverseDateFormat(alert.start_date),
            toDate : this.ReverseDateFormat(alert.end_date),
            fromPrice : this.FormatMoney(alert.from_price),
            fromPriceAsNumber : alert.from_price,
            toPrice : this.FormatMoney(alert.to_price),
            toPriceAsNumber : alert.to_price,
            investmentType : alert.investment_type,
            propertyType : alert.property_type,
            type : alert.type,
            locateBy : alert.locate_by,
            numberBedrooms:number_bedrooms,
            numberBathrooms:number_bathrooms,
            livingAreaSize: this.FormatNumber(alert.living_space_size),
            livingAreaSizeAsNumber : alert.living_space_size,
            minimumGreenIndicatorCount: alert.number_green_indicators,
            zipCodeList : alert.zip_list,
            fromZipcode : alert.from_zip,
            toZipcode : alert.to_zip,
            fromPrice : alert.from_price,
            toPrice : alert.to_price,
            city : alert.city,
            state : alert.state,
            alert:alert
          }, () => {


                    //populate locate by
                    if (this.state.locateBy == 'zipcode')
                    {
                        this.HandleLocateBySelect('zipcode,Locate by zipcode');
          
                    }else if (this.state.locateBy == 'zipcode-range')
                    {
          
                        this.HandleLocateBySelect('zipcode-range,Locate by zipcode range');
          
                    } else if (this.state.locateBy == 'city-state')
                    {
                        this.HandleLocateBySelect('city-state,Locate by city state');
          
                    }
          
          });


                    
      } else
      {
        this.PopulateProfileFields(profileId,profile,user);
      }


      this.setState({
        parentReference: parentReference
      });
    

      this.openModal();
  }


  ClearFields()
  {
    this.setState({
        accountId:'',
        profileName: '',
        monthlyBills:'0',
        monthlyBillsAsNumber:0,
        monthlyGrossIncome:'0',
        monthlyGrossIncomeAsNumber:0,
        creditScore:'_',
        budget:'0',
        budgetAsNumber:0,
        profile: null,

        isOpen: false,
        areComponentsRendered: false,  
        showValidationMessage:false,
        validationErrors: [],
        alertName : "",
        fromDate : "",
        toDate : "",
        fromPrice : "",
        fromPriceAsNumber : 0,
        toPrice : "",
        toPriceAsNumber : 0,
        investmentType : 'Primary Home',
        propertyType : 'Single Family Home',
        type : "single-home",
        locateBy : "zipcode",
        numberBedrooms:'-',
        numberBathrooms:'-',
        livingAreaSize: '0',
        livingAreaSizeAsNumber : 0,
        minimumGreenIndicatorCount: '4',
        zipCodeList : [{ zipcode: ""}],
        fromZipcode : "",
        toZipcode : "",
        city : "",
        state : "",
        alert:null,
        profileId:"",

        uiSaving: false
      });

  }

  PopulateProfileFields(profileId,profile,user)
  {
    
      if (profile != null)
      {

        let investmentType = "";
        switch (user.investorType) {
          case "homebuyer":
              investmentType = "Primary Home";
          break;
          case "rental":
              investmentType = "Rental Property";
          break;
          case "flipper":
              investmentType = "Flipping Property";
          break;
      }

         this.setState({
           fromPrice:this.FormatMoney(0),
           fromPriceAsNumber:0,
           //toPrice:this.FormatMoney(profile.budget),  //DON'T do this because a person could have $50,000 in cash enough for down payment and closing costs, but obviuosly this doesn't represent the price of the house they are looking for
           //toPriceAsNumber:profile.budget,
           toPrice:this.FormatMoney(0),
           toPriceAsNumber:0,
           investmentType : investmentType,
         });
      } else 
      {
        this.PopulateProfileFieldsFromDB(profileId,user);
      }
 
     
  }

  PopulateProfileFieldsFromDB(profileId,user)
  {
       const payload = {
         profileId:profileId
       }
 
       authMiddleWare(this?.props?.history);
       const authToken = localStorage.getItem('AuthToken');
       const refreshToken = localStorage.getItem('RefreshToken');
       axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 
 
       axios
       .post('/api/profile', payload)
       .then((response) => {
         
        if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
        {
            localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
        }

        const profile = response.data;

        let investmentType = "";
        switch (user.investorType) {
          case "homebuyer":
              investmentType = "Primary Home";
          break;
          case "rental":
              investmentType = "Rental Property";
          break;
          case "flipper":
              investmentType = "Flipping Property";
          break;
      }

         this.setState({
           fromPrice:this.FormatMoney(0),
           fromPriceAsNumber:0,
           toPrice:this.FormatMoney(profile.budget),
           toPriceAsNumber:profile.budget,
           investmentType : investmentType,
         });
 
       })
       .catch((error) => {
         console.log(error);
 
         if (error && error.response && error.response.status && error.response.status === 403) {
               localStorage.setItem('SessionExpired',"Y");
               //window.location.reload();
               window.location.href = '/login';
           }
         
       });
  }

  HandleSelect(event) {

    var elements = event.split(",");
    var key = elements[0];
    var value = elements[1];

    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
  
    });


  }

  handleNumberInputChange = (event) => {
  
    var numberVar = event.target.value.replace(/[^\d.-]/g, '');
  
    numberVar = numberVar.replace('....','.').replace('...','..').replace('..','.');

    var formattedValue = Intl.NumberFormat().format(numberVar);
    
    this.setState({
      [event.target.name]: formattedValue
    });

    var key = event.target.name+"AsNumber"
    var val = Number(numberVar);  
    var obj  = {}
    obj[key] = val
    this.setState(obj, () => {
      // Do anything after the state has changed   
    
  
  });
  
  };


  handleInputChange = (event) => {
  
    var formattedValue = event.target.value;
   // if (formattedValue =="") // This is not needed and instruces a bug
   // {
   //   formattedValue = "$0"; // This is not needed and instruces a bug
   // }

   let propertyName = event.target.name;

    this.setState({
      [propertyName]: formattedValue
    }, () => {
    
      //If number currency only use number value
      if (formattedValue.startsWith("$"))
      {
        var key = propertyName+"AsNumber"
        var val = Number(formattedValue.replace(/[^0-9\.-]+/g,""));  
        var obj  = {}
        obj[key] = val
        this.setState(obj, () => {
            // Do anything after the state has changed   
        });
      
      } 

    });

    
    
	};

  ChangeCustomNumberSliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangeNumberSliderValue(event);
  }

  ChangeNumberSliderValue(event) {
  
    var key = event.target.name;
    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;

    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
     
  });

    var obj  = {}
    obj[keyFormatted] = Intl.NumberFormat().format(value);
    this.setState(obj, () => {

    });
    
  }

  HandleCurrencyEnterFocus(event) {

    //console.log(event.target.name);

    let key = event.target.name;

    if (key.endsWith("Column"))
    {
        key = key.replace("Column","");
    }

    if (this.state[key] =="0" || this.state[key] =="$0")
    {
  
      document.getElementById(event.target.name).value = "";
      
      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandleCurrencyExitFocus(event) {
   
    let key = event.target.name;

    if (key.endsWith("Column"))
    {
        key = key.replace("Column","");
    }

    if (this.state[key] =="" || this.state[key] ==" ")
    {
  
      document.getElementById(event.target.name).value = "$0";
      
      var obj  = {}
      obj[key] = 0
      this.setState(obj);
    }

  }

  HandlePercentEnterFocus(event) {

    if (this.state[event.target.name] =="0" || this.state[event.target.name] =="0%")
    {
      const key = event.target.name;
      const keyFormatted = key+"AsNumber";
      document.getElementById(key).value = "";

      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandlePercentExitFocus(event) {
   
    if (this.state[event.target.name] =="" || this.state[event.target.name] ==" ")
    {
      const key = event.target.name;
      document.getElementById(key).value = "0%";

      var obj  = {}
      obj[key] = 0
      this.setState(obj);
    }

  }


  ChangeCustomCurrencySliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    if (name.endsWith("InputCashFlowColumnAsNumber"))
    {
      name = name.replace("Input","");
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    //console.log("HERE- VALUE:");
    //console.log(value);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangeCurrencySliderValue(event);

  };

  ChangeCurrencySliderValue(event) {
  
    let key = event.target.name;

    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;
  
    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
    });

    var obj  = {}
    obj[keyFormatted] = value
    this.setState(obj)
    
  }

  ChangeCurrencySliderValue(event) {
  
    let key = event.target.name;
    
    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;
  
    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   

    });

    var obj  = {}
    obj[keyFormatted] = this.FormatMoney(value);
    this.setState(obj)
    
  }

  FormatNumber(value)
  {
    var number = "0";

    try 
    {
      number = Intl.NumberFormat().format(value)

    } catch (error) {
    }

    return number;
  }

  FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }

SetSelectedLocateByDropdown(record)
{
  var event = "";

  switch (record.locateBy) {
    case 'zipcode':
      event ='zipcode,Locate by zipcode';
      break;
    case 'zipcode-range':
      event ='zipcode-range,Locate by zipcode range';
      break;
    case 'city-state':
      event ='city-state,Locate by city state';

      var selectStateObj =  document.querySelector('[id=stateSelect]');
   
      for (let a = 0; a < selectStateObj.options.length; a++) {
  
         if (selectStateObj.options[a].value == record.state)
         {
            selectStateObj.selectedIndex = a;          
         }
       }
   
      

      break;
  } 


  this.HandleLocateBySelect(event);

}

  PresetComponents()
  {
    if (!this.state.areComponentsRendered)
    {
    
      this.setState({areComponentsRendered:true}); 
    }

  }    


    
      // handle input change
  HandleZipcodeInputChange (e, index) {

    e.preventDefault();

    const { name, value } = e.target;
    const list = [...this.state.zipCodeList];
    list[index][name] = value;
  
    this.setState({ zipCodeList: list })

  };

   // handle click event of the Remove button
   HandleZipcodeRemoveClick (e,index) {
    e.preventDefault();
    const list = [...this.state.zipCodeList];
    list.splice(index, 1);

    this.setState({ zipCodeList: list })
  };

  // handle click event of the Add button
  HandleZipcodeAddClick (e) {
   
    e.preventDefault();
    var list = this.state.zipCodeList;
    list.push({ zipcode: ""});

    this.setState({ zipCodeList: list })

  };
      

    HandleFromZipcodeChange(e){
 
      this.setState({fromZipcode : e.target.value});
    }

    HandleToZipcodeChange(e){
 
      this.setState({toZipcode : e.target.value});
    }

    HandleStateChange(e){
 
      this.setState({state : e.target.value});
    }

    HandleCityChange(e){
 
      this.setState({city : e.target.value});
    }

    HandleLocateBySelect(event) {

        var elements = event.split(",");
        
        var value = elements[0];
        var elementHTML = elements[1]; 
        
  
        for (let i = 0; i < this.locateByDropdownRef.current.children.length; i++) {
            var child = this.locateByDropdownRef.current.children[i];
      
            if (child.tagName == "BUTTON")
            {
                child.innerHTML = elementHTML;
            }
          }
        

        // Enable the right div for the selection
        var zipcodeBock = document.getElementById('zipcodeBock');
        var zipcodeRangeBock = document.getElementById('zipcodeRangeBock');
        var cityStateBock = document.getElementById('cityStateBock');
        
        zipcodeBock.style.display = "none";
        zipcodeRangeBock.style.display = "none";
        cityStateBock.style.display = "none";

        this.state.locateBy = value;

        switch (value) {
          case 'zipcode':
            zipcodeBock.style.display = 'block';
            break;
          case 'zipcode-range':
            zipcodeRangeBock.style.display = 'block';
            break;
          case 'city-state':
            cityStateBock.style.display = 'block';
            break;
        } 
      
    }

    HandleType(event) {
      this.setState({type : event.target.value});
    }



    GetPreFilteredSelectedValues()
    {
      var selectedIndicatorArr = [];
      var indicatorSelectArray =  document.querySelectorAll('[id=indicatorNameDropdownSelect]');
      for (let e = 0; e < indicatorSelectArray.length; e++) {
         if (indicatorSelectArray[e].value != '')
         {
          //alert(indicatorSelectArray[e].value);
          selectedIndicatorArr.push(indicatorSelectArray[e].value);
        }
      }//end loop

      return selectedIndicatorArr;
    }

    GetFilteredIndicatorTypes()
    {
      /*
        This method is a little complex because we need to filter used values but keep for the dropdown where it is selected. 
      */
      var filteredList = [];
      //Get list of selected indicators
      var selectedIndicatorArr = [];
      var indicatorSelectArray =  document.querySelectorAll('[id=indicatorNameDropdownSelect]');
      for (let e = 0; e < indicatorSelectArray.length; e++) {
         if (indicatorSelectArray[e].value != '')
         {
          //alert(indicatorSelectArray[e].value);
          selectedIndicatorArr.push(indicatorSelectArray[e].value);
        }
      }//end loop

      //Get filtered list
      var indicatorTypes = this.state.indicatorTypes;

//alert("types:"+indicatorTypes.length);
//alert(JSON.stringify(indicatorTypes));

      for (let index = 0; index < indicatorTypes.length; index++) {

        var indexIndicatorTypes = indicatorTypes[index]; 
        
        filteredList[index] = [];

//alert("indicatorSelectArray.length:"+indicatorSelectArray.length);
//alert(indicatorSelectArray);        
//alert(index);

        for (let e = 0; e < indexIndicatorTypes.length; e++) {

          //alert("v1:"+indexIndicatorTypes[e].indicator+", v2:"+indicatorSelectArray[index].value + " ["+(indexIndicatorTypes[e].indicator == indicatorSelectArray[index].value).toString() +"]");
      
          // Include it if not in list of used indicators and when the indicator = the selected indicator for that dropdown
          if (!selectedIndicatorArr.includes(indexIndicatorTypes[e].indicator))
          {
            //alert("(M)"+index+"-"+indexIndicatorTypes[e].indicator);
            filteredList[index].push(indexIndicatorTypes[e]);

          } else  if (indexIndicatorTypes[e].indicator == indicatorSelectArray[index].value)
          {
            //alert("(S)"+index+"-"+indexIndicatorTypes[e].indicator);
            filteredList[index].push(indexIndicatorTypes[e]);
          }

        }
      }

     // this.SetIndicatorNameSelectedIndex(selectedIndicatorArr);

      return filteredList;
    }

    SetIndicatorNameSelectedIndex(selectedIndicatorArr)
    {

        var indicatorSelectArray =  document.querySelectorAll('[id=indicatorNameDropdownSelect]');

        for (let e = 0; e < indicatorSelectArray.length; e++) {
        
          var value = selectedIndicatorArr[e];

          for (let a = 0; a < indicatorSelectArray[e].options.length; a++) {

            if (indicatorSelectArray[e].options[a].value == value)
            {
              indicatorSelectArray[e].selectedIndex = a;  
              break;
       
            }
          

          }
        }//end loop

    }

    CreateIndicatorListCopy(list)
    {
      let copiedList = JSON.parse(JSON.stringify(list));
         
      return copiedList;
    }

    ConvertDateFormat(date)
    {

      /*
      const [year, month, day] = date.split('-');
      const result = [month, day, year].join('/');
      */

      const result = new Date(date);

      //const result = "02/02/2022";

      return result;
    }


    FormatTimeStampToDateString(timestamp)
    {
       // console.log(timestamp);

        const fireBaseTime = new Date(
            timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
          );

        //console.log(fireBaseTime.toLocaleDateString());  

        return fireBaseTime.toLocaleDateString();
    }

    ReverseDateFormat(timestamp)
    {
        const dateString = this.FormatTimeStampToDateString(timestamp);

        let dateStringArray = dateString.split("/");
    
        const year = dateStringArray[2];

        let month = dateStringArray[0];
        
        let day = dateStringArray[1];
        
        if (day.length==1)
        {
            day = "0"+day;
        }

        if (month.length==1)
        {
            month = "0"+month;
        }
        
        const result = year+"-"+month+"-"+day;

        console.log("result:"+result);  

        return result;
    
    }

    ReverseDateFormatOLD(timestamp)
    {

      /*
      const [month, day, year] = date.split('/');
      const result = [year, month, day].join('-');
      */

      const fireBaseTime = new Date(
        timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
      );

      fireBaseTime.setMonth(fireBaseTime.getMonth()+1);

      const day = (fireBaseTime.getDate() < 10 ? '0' : '') + fireBaseTime.getDate();
      const month = (fireBaseTime.getMonth() < 10 ? '0' : '') + fireBaseTime.getMonth();
      const year = fireBaseTime.getFullYear();

      const result = year+"-"+month+"-"+day;

      console.log("result:"+result);  

      return result;
    }

    SortIndicatorList(list)
    {
       list.sort(this.SortByIndicator);
    }
    
    SortByIndicator(x,y)
    {
      return ((x.indicator == y.indicator) ? 0 : ((x.indicator > y.indicator) ? 1 : -1 ));
    }

    HandleSave(event,obj){

    var alertJSON = {
      //account_id: 1, Note: You don't have to set this accunt_id is set on the backend
      profile_id: this.state.profileId,
      alert_name: "",
      city: "",
      state: "",
      from_price: "",
      to_price: "",
      from_zip: "",
      to_zip: "",
      investment_type: "",
     // last_processed_date_time: "", //DONT ADD MESSES UP UPDATE
     // last_processed_status: "",    //DONT ADD MESSES UP UPDATE
      locate_by: "",
      number_green_indicators: 0,
      start_date: "",
      end_date: "",
      type: "",
      zip_list : [],
      living_space_size: 0,
      property_type:"",
      number_bedrooms:0,
      number_bathrooms:0,
      status: "",
    };

      var message = "";

      var name = document.querySelector('#alertName').value;

      var fromDate = document.querySelector('#alertStartDate').value;
      var toDate = document.querySelector('#alertEndDate').value;

      var livingAreaSize = document.querySelector('#livingAreaSize').value;

      var fromPrice = document.querySelector('#fromPrice').value;
      fromPrice = Number(fromPrice.replace(/[^0-9\.]+/g,""));

      var toPrice = document.querySelector('#toPrice').value;
      toPrice = Number(toPrice.replace(/[^0-9\.]+/g,""));

      //remove empty zipcodes
      var filteredZipcodeList = [];
      for (let a = 0; a < this.state.zipCodeList.length; a++) {
        if (this.state.zipCodeList[a].zipcode != '')
        {
          filteredZipcodeList.push(this.state.zipCodeList[a]);
        }
      }

      this.state.validationErrors = [];
      
      let passedValidation = true;

      //if (name == '' || fromDate == '' || toDate == '' || fromPrice =='' || toPrice == '')
      //{
          if (name =='')
          {
            //alert('Please enter an alert name');
            message = 'Please enter an search name';

            this.state.validationErrors.push(message);

            passedValidation = false;
          }
          
          if (fromDate == '')
          {
            //alert('Please enter the alert start date');
            message = 'Please enter the search start date';

            this.state.validationErrors.push(message);

            passedValidation = false;
          }  
          
          if (toDate == '')
          {
            //alert('Please enter the alert end date');
            message = 'Please enter the serch end date';

            this.state.validationErrors.push(message);

            passedValidation = false;
          } 

          if (fromDate != '' && toDate != '')
          {
              if (new Date(fromDate) > new Date(toDate))
              {
                 message = 'End date needs to be greater than start date';

                this.state.validationErrors.push(message);

                passedValidation = false;
              }
          }

          if (livingAreaSize != null && livingAreaSize != '')
          {
            if (parseInt(livingAreaSize) < 0)
            {
                message = 'Minimum Sqft must be a positive number';

                this.state.validationErrors.push(message);

                passedValidation = false;
            }
          }


      /*    
          if (fromPrice == '')
          {
            //alert('Please enter the starting price');
            message = 'Please enter the starting price';
            this.state.validationErrors.push(message);
            passedValidation = false;
          }  
         */ 
          if (toPrice == '')
          {
            //alert('Please enter the ending price');
            message = 'Please enter the ending price';

            this.state.validationErrors.push(message);

            passedValidation = false;
          } 
          
          if (toPrice < fromPrice)
          {
            //alert('The starting price must be less than the ending price');
            message = 'The starting price must be less than the ending price';

            this.state.validationErrors.push(message);

            passedValidation = false;
          } 
          
          if (this.state.locateBy =='')
          {
            //alert('Please indicate how to locate the properties. Locate By.');
            message = 'Please indicate how to locate the properties. Locate By.';

            this.state.validationErrors.push(message);

            passedValidation = false;

          } 
          
          if (this.state.locateBy =='zipcode' && filteredZipcodeList.length==0)
          {
             //alert('Please enter at least one zipcode');

             message = 'Please enter at least one zipcode';
             
             this.state.validationErrors.push(message);

             passedValidation = false;

          } 

          if (this.state.locateBy =='zipcode')
          {
              for (let zipcode of filteredZipcodeList)
              {
                
                if (zipcode.zipcode.replace("_","").length < 5)
                 {
                    message = 'All zipcode must have at least five digits';
             
                    this.state.validationErrors.push(message);

                    passedValidation = false;

                    break;
                 }
              }
          }
          
          if (this.state.locateBy =='zipcode-range')
          {
            if (this.state.fromZipcode =='')
            {
             //alert('Please enter a starting zipcode');
             message = 'Please enter a starting zipcode';
             
             this.state.validationErrors.push(message);

             passedValidation = false;

            } else if (this.state.fromZipcode.replace("_","").length < 5)
            {
                message = 'The starting zipcode must have at least 5 digits';
                
                this.state.validationErrors.push(message);

                passedValidation = false;
                
            }

            if (this.state.toZipcode =='')
            {
             //alert('Please enter a ending zipcode');

             message = 'Please enter a ending zipcode';
            
             this.state.validationErrors.push(message);
            
             passedValidation = false;
            
            }else if (this.state.toZipcode.replace("_","").length < 5)
            {
                message = 'The ending zipcode must have at least 5 digits';
                
                this.state.validationErrors.push(message);

                passedValidation = false;
                
            }

            if (this.state.fromZipcode !='' && this.state.toZipcode !='')
            {
              let fromZipNum = 0;
              let toZipNum = 0;

              try
              {
                fromZipNum = parseInt(this.state.fromZipcode);

              }catch(error)
              {
                message = 'The starting zipcode must be a numeric zipcode';
            
                this.state.validationErrors.push(message);
                
                passedValidation = false;
              }

              try
              {
                toZipNum = parseInt(this.state.toZipcode);

              }catch(error)
              {
                message = 'The ending zipcode must be a numeric zipcode';
            
                this.state.validationErrors.push(message);
                
                passedValidation = false;
              }

              if (fromZipNum != 0 && fromZipNum < 0)
              {
                message = 'The starting zipcode must be a positive number';
            
                this.state.validationErrors.push(message);
                
                passedValidation = false;
              }

              if (toZipNum != 0 && toZipNum < 0)
              {
                message = 'The ending zipcode must be a positive number';
            
                this.state.validationErrors.push(message);
                
                passedValidation = false;
              }

              if (fromZipNum != 0 && toZipNum != 0)
              {
                 const difference = toZipNum - fromZipNum;

                 if (fromZipNum > toZipNum)
                 {
                  message = 'The ending zipcode must be greater than the starting zipcode';
            
                  this.state.validationErrors.push(message);
                  
                  passedValidation = false;
                 
                } else if (difference > 10)
                {
                  message = 'The zipcode range must not span more than 10 zipcodes';
            
                  this.state.validationErrors.push(message);
                  
                  passedValidation = false;
                }

              }
              
            }

          }
          
          if (this.state.locateBy =='city-state' && (this.state.city =='' || this.state.state ==''))
          {
            passedValidation = false;
             
            if (this.state.city =='')
            {
             //alert('Please enter a city');

             message = 'Please enter a city';

             this.state.validationErrors.push(message);

            } 
            
            if (this.state.state =='')
            {
             //alert('Please select a state');
             message = 'Please select a state';

             this.state.validationErrors.push(message);

            }
          }

      if (message != "")
      {
          this.setState({
            showValidationMessage: true
          });
          
          /*
          this.dialog.show({
            title: 'Validation Message',
            body: message,
            actions: [
              Dialog.OKAction(() => {
                //console.log('OK was clicked!')
                
              })
            ],
            bsSize: 'small',
            onHide: (dialog) => {
              dialog.hide()
              
            }
          })
          */
      }

      if (passedValidation)
      {
        this.dialog.hide();

        //alertJSON.account_id                = ;  Note: You don't have to set this accunt_id is set on the backend
        alertJSON.profile_id                = this.state.profileId;
        alertJSON.alert_name                = name;
        alertJSON.city                      = this.state.city;
        alertJSON.state                     = this.state.state;
        alertJSON.from_price                = parseInt(this.state.fromPriceAsNumber);
        alertJSON.to_price                  = parseInt(this.state.toPriceAsNumber);
        alertJSON.from_zip                  = this.state.fromZipcode;
        alertJSON.to_zip                    = this.state.toZipcode;
        alertJSON.investment_type           = this.state.investmentType;
        if (this.state.alert == null)  //Insert
        {
          alertJSON.last_processed_date_time  = '';
          alertJSON.last_processed_status     = '';
        }
        alertJSON.locate_by                 = this.state.locateBy;
        alertJSON.number_green_indicators   = this.state.minimumGreenIndicatorCount;
        alertJSON.start_date                = this.ConvertDateFormat(fromDate);
        alertJSON.end_date                  = this.ConvertDateFormat(toDate);
        alertJSON.type                      = this.state.type;
        alertJSON.zip_list                  = filteredZipcodeList;
        alertJSON.number_bedrooms           = parseFloat(this.state.numberBedrooms);
        alertJSON.number_bathrooms          = parseFloat(this.state.numberBathrooms);
        alertJSON.living_space_size         = this.state.livingAreaSizeAsNumber;
        alertJSON.property_type             = this.state.propertyType;
        alertJSON.status                    = 'on';

        this.setState({
          uiSaving: true
        });

        if (this.state.locateBy =='zipcode')
        {
          alertJSON.from_zip = filteredZipcodeList[0].zipcode;
        }

        if (this.state.alert == null)  //Insert
        {
          authMiddleWare(this?.props?.history);
          const authToken = localStorage.getItem('AuthToken');
          const refreshToken = localStorage.getItem('RefreshToken');
          axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 

          axios
            .post('/api/createAlert', alertJSON)
            .then((response) => {
              
              if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
              {
                  localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                  localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              }

              console.log("Completed creating alert");

              this.setState({
                uiSaving: false
              });

              this.closeModal();
              this.state.parentReference.loadAlerts();
              this.props.homeRef.chooseAppropriateTrialProfile("alertCreate");
              
              //Show message about alerts running in the background
              if (localStorage.getItem('ShowAlertInfoMessage') == null || (localStorage.getItem('ShowAlertInfoMessage') != null && localStorage.getItem('ShowAlertInfoMessage') === 'true'))
              {
                this.state.parentReference.showAlertInfoMessage();
              }
              
            })
            .catch((error) => {
            
              alert(error);

              console.log(error);

              if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
              } else
              {
                alert("Error saving alert");
                this.setState({
                  uiSaving: false
                });
              }
              
              
            });
    
          } else  //Update 
          {
            const id = this.state.alert.id;

            //Don't update alertJSON.last_processed_date_time and last_processed_status, let the values in the record remain
            alertJSON.status                    = this.state.alert.status;

            //alert(this.state.alert.last_processed_date_time);
           
            const payload = {
              id:id,
              alert:alertJSON
            }
    
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 

            axios
            .post('/api/updateAlert', payload)
            .then((response) => {
              
              if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
              {
                  localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                  localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              }

              console.log("Completed updating alert");
              
              this.setState({
                uiSaving: false
              });

              this.closeModal();
              
              this.state.parentReference.loadAlerts();

              //Show message about alerts running in the background
              if (localStorage.getItem('ShowAlertInfoMessage') == null || (localStorage.getItem('ShowAlertInfoMessage') != null && localStorage.getItem('ShowAlertInfoMessage') === 'true'))
              {
                this.state.parentReference.showAlertInfoMessage();
              }

            })
            .catch((error) => {
              console.log(error);

              if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
              } else
              {
                alert("Error saving alert");
                this.setState({
                  uiSaving: false
                });
              }
              
            });
    
          }    

      }

     // alert(JSON.stringify(alertJSON));
     // console.log(JSON.stringify(alertJSON));


     // alert(this.state.fromPrice); // Will only be used to set the values
     // alert(this.state.toPrice); // Will only be used to set the values

    }

    HandleCloseValidationMessage(event,obj){

      //document.querySelector('#propertyNickname').focus();
  
      //document.body.click();
  
      this.setState({
        showValidationMessage: false
      });
    
    }
  

    render() {

      const { classes } = this.props;

      //Note: backdrop="static"  prevents the modal from closing when you click outside
        return (
            <Modal size="lg" show={this.state.isOpen}  backdrop="static" onHide={this.closeModal}> 

          <Helmet>
     {/*       <link rel="stylesheet" href="https://code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css"/>
            <script src="https://code.jquery.com/jquery-1.12.4.js"></script>
            <script src="https://code.jquery.com/ui/1.12.1/jquery-ui.js"></script> */}
            <script src="js/application.js"></script>
          </Helmet>

            <Modal.Header closeButton>
              <Modal.Title>Advanced Search</Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal"  ref={this.alertEditoDialogRef}>
            
               <div class="modal-body"  style={{padding:"0px",margin:"0px"}}>
                                <div class="container"  style={{padding:"0px",margin:"0px"}}>

                                    <div class="card2">
                                 
                                        <div class="card-body alert-edit-modal">

                                            <div class="tab-content">
                                                <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                                    <form action="/action_page.php">

                                                        {/* Alert Name */}
                                                        <div class="row">

                                                            <div class="col-md-8">
                                                                <div class="form-group">
                                                                <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>Any name you want to use to identify <br/>this search.</div>
                                                                     
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="alertName">Search Name</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                    
                                                                    <input type="text" class="form-select alert-edit-modal-component" id="alertName" placeholder="i.e. Orlando Rental Properties" name="alertName" defaultValue={this.state.alertName} required/>
                                                        
                                                                    
                                                                </div>
                                                            </div>

                                                          {/*
                                                            <div class="col-md-4">
                                                                <div class="form-group">
                                                                <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>This allows you to pause alerts.</div>
                                                                     
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="alertName">Status</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                    
                                                                    <BootstrapSwitchButton checked={true} onstyle="success" offstyle="danger" size="xs"/>
                                                                    
                                                                </div>
                                                            </div>
                                                        */}  

                                                        </div>    {/* ----- End Row ----*/}
                                                        

                                                        {/* Investment Type */} 
                                                        <div class="row">

                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                    <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>What do you intent to use the property for? <br/>Primary home, rental property or you just plan to flip it. <br/><br/>The financial indicators are different for each type.<br/><br/></div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="alertStartDate">Investment Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                        </OverlayTrigger>
                                                                        
                                                                        <DropdownButton id="investmentTypeDropdownSelect" name="investmentTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.investmentType} onSelect={(e) => this.HandleSelect(e)}>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['investmentType', 'Primary Home']}>Primary Home</Dropdown.Item>
                                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['investmentType', 'Rental Property']}>Rental Property</Dropdown.Item>
                                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['investmentType', 'Flipping Property']}>Flipping Property</Dropdown.Item>
                                                                        </DropdownButton>
                                                                        
                                                                    </div>
                                                                </div>
                                                        </div>    {/* ----- End Row ----*/}

                                                        
                                                          <div class="row">
                                                
                                                            <div class="col-md-6">
                                                                  <div class="form-group">
                                                                  <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                       
                                                                            <div className='helpText'>The date when the search will start.</div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                        <label class="dialog-form-label" for="alertStartDate">Search Start Date</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                      </OverlayTrigger>
                                                                     
                                                                      <input type="date" class="form-select alert-edit-modal-component" id="alertStartDate" placeholder="Alert Start Date" name="alertStartDate"  defaultValue={this.state.fromDate} required/>
                                                                      
                                                                      
                                                                  </div>
                                                              </div>


                                                          <div class="col-md-6">
                                                                  <div class="form-group">
                                                                  <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                       
                                                                            <div className='helpText'>The date when the search will end.</div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                        <label class="dialog-form-label" for="alertEndDate">Search End Date</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                      </OverlayTrigger>
                                                                      
                                                                      <input type="date" class="form-select alert-edit-modal-component"   id="alertEndDate" placeholder="Alert End Date" name="alertEndDate" defaultValue={this.state.toDate} required/>
                                                                      
                                                                  </div>
                                                              </div>
                                                            </div> {/* ----- End Row ----*/}


                                                          <div class="row space-bottom">
                                                            <div class="col-md-12">
                                                                <br/>
                                                                <div className="centeredLabel">- - - - - CRITERIA - - - - -</div>
                                                                
                                                             </div>
                                                           </div>


                                                        <div class="row">


                                                              <div class="col-md-6">
                                                                <div class="form-group">
                                                                <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>The type of property the search should look for.</div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="alertStartDate">Property Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                    
                                                                    <DropdownButton id="propertyTypeDropdownSelect" name="propertyTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.propertyType} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Single Family Home']}>Single Family Home</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Townhouse']}>Townhouse</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Multi-family']}>Multi-family</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Condo']}>Condo</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Mobile Home']}>Mobile Home</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Lot']}>Lot</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Any']}>Any</Dropdown.Item>
                                                                        </DropdownButton>
                                                                        
                                                                    </div>
                                                                </div>
                                                          </div>
                    

                                                          <div class="row">
                                                            
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>What is the preferred method for locating the properties? <br></br>
                                                                              By zip code(s), a range of zip codes, or city and state?</div>
                                                                          
                                                                        </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="locateByDropdownSelect">Locate By</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                        </OverlayTrigger>
                                                                        
                                                                        <DropdownButton ref={this.locateByDropdownRef} id="locateByDropdownSelect" className="customDropdownSelectWrapper"  title='Locate by zipcode' onSelect={(e) => this.HandleLocateBySelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['zipcode', 'Locate by zipcode']}><img  src={zipcodeIcon}/> <span style={{paddingLeft:"18px"}}>Zipcode</span> </Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['zipcode-range', 'Locate by zipcode range']}><img  src={zipcodeRangeIcon}/><span style={{paddingLeft:"10px"}}>Zipcode Range</span></Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['city-state', 'Locate by city state']}><img  src={cityStateIcon}/> <span style={{paddingLeft:"15px"}}>City, State </span></Dropdown.Item>
                                                                        </DropdownButton>
                                                                        
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>Please indicate the location for the property search.</div>
                                                                          
                                                                        </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="locateByDropdownSelect">Location</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                        </OverlayTrigger>
                                                         
                                                                  {/*  BEGIN  */}

                                                                  {/*  ZIPCODE */}
                                                                    <div id="zipcodeBock" style={{display:"block"}} >

                                                                      {this.state.zipCodeList.map((x, i) => {
                                                                      return (
                                                                      <div>
                                                                        <div>
                                                                        <MaskedInput
                                                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                            class="form-select alert-edit-modal-component noWrapInput" 
                                                                            style={{width:"40%"}}
                                                                            name="zipcode"
                                                                            placeholder="Enter Zipcode"
                                                                            value={x.zipcode}
                                                                            onChange={e => this.HandleZipcodeInputChange(e, i)} 
                                                                            required
                                                                          />
                                                                        

                                                                          
                                                                            {this.state.zipCodeList.length !== 1 && <button class="zipcodeButton btn btn-danger btn-circle btn-tiny"
                                                                              onClick={e => this.HandleZipcodeRemoveClick(e,i)}>-</button>}
                                                                            {this.state.zipCodeList.length - 1 === i && <button class="zipcodeButton btn btn-primary btn-circle btn-tiny" onClick={e => this.HandleZipcodeAddClick(e)}>+</button>}
                                                                          
                                                                        </div>

                                                                      <br></br>
                                                                      
                                                                      </div>  
                                                                        
                                                                      );
                                                                    })}

                                                                    {/* 
                                                                    
                                                                    <div style={{ marginTop: 20 }}>{JSON.stringify(this.state.zipCodeList)}</div>
                                                                      
                                                                      */}

                                                                  </div> {/* END ZIPCODE BLOCK */}


                                                                  {/*  ZIPCODE RANGE*/}

                                                                  <div id="zipcodeRangeBock" style={{display:"none"}} >
                                                                          <MaskedInput
                                                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                            class="form-select alert-edit-modal-component noWrap" 
                                                                            style={{width:"40%",marginRight:"10px"}}
                                                                            name="fromZipcode"
                                                                            placeholder="From Zipcode"
                                                                            value={this.state.fromZipcode}
                                                                            onChange={this.HandleFromZipcodeChange.bind(this)} 
                                                                            required
                                                                          />

                                                                          <span>To</span>

                                                                          <MaskedInput
                                                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                            class="form-select alert-edit-modal-component noWrap" 
                                                                            style={{width:"40%",marginLeft:"10px"}}
                                                                            name="toZipcode"
                                                                            placeholder="To Zipcode"
                                                                            value={this.state.toZipcode}
                                                                            onChange={this.HandleToZipcodeChange.bind(this)} 
                                                                            required
                                                                          />
                                                                  </div>

                                                                  {/*  END ZIPCODE RANGE BLOCK */}

                                                                  {/*  CITY, STATE BLOCK*/}

                                                                  <div id="cityStateBock" style={{display:"none"}} >

                                                                      <input
                                                                            type="text" 
                                                                            class="form-select alert-edit-modal-component noWrap" 
                                                                            style={{width:"60%",marginRight:"10px"}}
                                                                            name="city"
                                                                            placeholder="City"
                                                                            value={this.state.city}
                                                                            onChange={this.HandleCityChange.bind(this)} 
                                                                            required
                                                                          />

                                                                      <select name="stateSelect" 
                                                                      id="stateSelect" 
                                                                      class="form-select alert-edit-modal-component noWrap" 
                                                                      style={{width:"36%"}}
                                                                        onChange={this.HandleStateChange.bind(this)}>
                                                                            <option value=""></option>
                                                                            <option value="AL">AL</option>
                                                                            <option value="AK">AK</option>
                                                                            <option value="AS">AS</option>
                                                                            <option value="AZ">AZ</option>
                                                                            <option value="AR">AR</option>
                                                                            <option value="CA">CA</option>
                                                                            <option value="CO">CO</option>
                                                                            <option value="CT">CT</option>
                                                                            <option value="DE">DE</option>
                                                                            <option value="DC">DC</option>
                                                                            <option value="FL">FL</option>
                                                                            <option value="GA">GA</option>
                                                                            <option value="GU">GU</option>
                                                                            <option value="HI">HI</option>
                                                                            <option value="ID">ID</option>
                                                                            <option value="IL">IL</option>
                                                                            <option value="IN">IN</option>
                                                                            <option value="IA">IA</option>
                                                                            <option value="KS">KS</option>
                                                                            <option value="KY">KY</option>
                                                                            <option value="LA">LA</option>
                                                                            <option value="ME">ME</option>
                                                                            <option value="MD">MD</option>
                                                                            <option value="MA">MA</option>
                                                                            <option value="MI">MI</option>
                                                                            <option value="MN">MN</option>
                                                                            <option value="MS">MS</option>
                                                                            <option value="MO">MO</option>
                                                                            <option value="MT">MT</option>
                                                                            <option value="NE">NE</option>
                                                                            <option value="NV">NV</option>
                                                                            <option value="NH">NH</option>
                                                                            <option value="NJ">NJ</option>
                                                                            <option value="NM">NM</option>
                                                                            <option value="NY">NY</option>
                                                                            <option value="NC">NC</option>
                                                                            <option value="ND">ND</option>
                                                                            <option value="MP">MP</option>
                                                                            <option value="OH">OH</option>
                                                                            <option value="OK">OK</option>
                                                                            <option value="OR">OR</option>
                                                                            <option value="PA">PA</option>
                                                                            <option value="PR">PR</option>
                                                                            <option value="RI">RI</option>
                                                                            <option value="SC">SC</option>
                                                                            <option value="SD">SD</option>
                                                                            <option value="TN">TN</option>
                                                                            <option value="TX">TX</option>
                                                                            <option value="UT">UT</option>
                                                                            <option value="VT">VT</option>
                                                                            <option value="VA">VA</option>
                                                                            <option value="VI">VI</option>
                                                                            <option value="WA">WA</option>
                                                                            <option value="WV">WV</option>
                                                                            <option value="WI">WI</option>
                                                                            <option value="WY">WY</option>
                                                                        </select>

                                                                  </div>

                                                                  {/*  CITY, STATE BLOCK END */}

                                                                  {/*  END */}  





                                                                </div>
                                                            </div>

                                                          </div>

                                                          <div class="row">

                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>Please indicate the minimum property price for the search.<br></br><br></br>(Optional)<br></br></div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="fromPrice">From Price</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                    
                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="fromPrice" placeholder="$0" name="fromPrice" defaultValue={this.state.fromPrice} value={this.state.fromPrice} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                    <span class="desktop-only-block">
                                                                        <input type="range" className="custom-range" id="fromPriceAsNumber" name="fromPriceAsNumber" min="0" max="5000000" step="50000" value={this.state.fromPriceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                    </span>
                                                                    <span class="mobile-only-block">
                                                                        <CustomizedSlider id="fromPriceAsNumber" name="fromPriceAsNumber" min={0} max={5000000} step={50000} value={this.state.fromPriceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                        <br></br>     
                                                                    </span>
  
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>Please indicate the maximum property price for the search.<br></br><br></br>(Optional)<br></br></div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="toPrice">To Price</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                    
                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="toPrice" placeholder="$0" name="toPrice" defaultValue={this.state.toPrice} value={this.state.toPrice} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                    <span class="desktop-only-block">
                                                                      <input type="range" className="custom-range" id="toPriceAsNumber" name="toPriceAsNumber" min="0" max="5000000" step="50000" value={this.state.toPriceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                    </span>  
                                                                    <span class="mobile-only-block">
                                                                        <CustomizedSlider  id="toPriceAsNumber" name="toPriceAsNumber" min={0} max={5000000} step={50000} value={this.state.toPriceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                     <br></br>     
                                                                    </span>


                                                                </div>
                                                            </div>


                                                          </div>

                                                          <div class="row">
      

                                                              {/* Bedrooms */} 
                                                              <div class="col-md-3">
                                                              <div class="form-group">
                                                              <OverlayTrigger
                                                                      placement="auto"
                                                                      delay={{ show: 150, hide: 100 }} 
                                                                      trigger={["hover","click"]}
                                                                      overlay={<Tooltip className="messagetooltip">
                                                                    
                                                                        <div className='helpText'>The number of bedrooms the property must have.<br></br><br></br>(Optional)<br></br></div>
                                                                    </Tooltip>}
                                                                    >
                                                                    <div>
                                                                            <label class="dialog-form-label" for="numberBedroomsDropdownSelect">Bedrooms</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                    </div>
                                                                  </OverlayTrigger>
                                                                
                                                                  <DropdownButton id="numberBedroomsDropdownSelect" name="numberBedroomsDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.numberBedrooms} onSelect={(e) => this.HandleSelect(e)}>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '1']}>1</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '1.5']}>1.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '2']}>2</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '2.5']}>2.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '3']}>3</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '3.5']}>3.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '4']}>4</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '4.5']}>4.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '5']}>5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '5.5']}>5.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '6']}>6</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '6.5']}>6.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '7']}>7</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '7.5']}>7.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '8+']}>8+</Dropdown.Item>
                                                                      
                                                                    </DropdownButton>
                                                                        
                                                                </div>
                                                            </div>

                                                            {/* Bathrooms */} 
                                                            <div class="col-md-3">
                                                              <div class="form-group">
                                                              <OverlayTrigger
                                                                      placement="auto"
                                                                      delay={{ show: 150, hide: 100 }} 
                                                                      trigger={["hover","click"]}
                                                                      overlay={<Tooltip className="messagetooltip">
                                                                    
                                                                        <div className='helpText'>The number of bathrooms the property must have.<br></br><br></br>(Optional)<br></br></div>
                                                                    </Tooltip>}
                                                                    >
                                                                    <div>
                                                                            <label class="dialog-form-label" for="numberBathroomsDropdownSelect">Bathrooms</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                    </div>
                                                                  </OverlayTrigger>
                                                                
                                                                  <DropdownButton id="numberBathroomsDropdownSelect" name="numberBathroomsDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.numberBathrooms} onSelect={(e) => this.HandleSelect(e)}>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '1']}>1</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '1.5']}>1.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '2']}>2</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '2.5']}>2.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '3']}>3</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '3.5']}>3.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '4']}>4</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '4.5']}>4.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '5']}>5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '5.5']}>5.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '6']}>6</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '6.5']}>6.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '7']}>7</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '7.5']}>7.5</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '8+']}>8+</Dropdown.Item>
                                                                      
                                                                    </DropdownButton>
                                                                        
                                                                </div>
                                                            </div>

                                                            {/* Spft */}
                                                            <div class="col-md-3">
                                                                
                                                                <div class="form-group">
                                                                    <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip  className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>The property's minimum living <br></br>area size in square feet.<br></br><br></br>(Optional)<br></br></div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="livingAreaSize">Minimum Sqft</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                
                                                                      <input type="text" class="form-select alert-edit-modal-component" id="livingAreaSize" placeholder="" name="livingAreaSize" defaultValue={this.state.livingAreaSize} value={this.state.livingAreaSize} onChange={this.handleNumberInputChange}/>
                                                                      <span class="desktop-only-block">
                                                                        <input type="range" className="custom-range" id="livingAreaSizeAsNumber" name="livingAreaSizeAsNumber" min="0" max="8000" step="1" value={this.state.livingAreaSizeAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                                      </span>

                                                                      <span class="mobile-only-block">
                                                                          <CustomizedSlider id="livingAreaSizeAsNumber" name="livingAreaSizeAsNumber" min={0} max={8000} step={1} value={this.state.livingAreaSizeAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                                        <br></br>     
                                                                      </span>

                                                                </div>
                                                                
                                                            </div>
                                                          
                                                        </div>  {/* ----- End Row ----*/}



                                                        <div class="row space-bottom">
                                                            <div class="col-md-12">
                                                                <br/>
                                                                <div className="centeredLabel">- - - - - INDICATORS - - - - -</div>
                                                                
                                                             </div>
                                                           </div>


                                                        <div class="row">


                                                              <div class="col-md-5">
                                                                <div class="form-group">
                                                                <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>

                                                                            <div style={{maxWidth:'350px',paddingBottom:'10px'}}>   
                                                                            What this means is that the search engine will only pick properties with the number of green indicators greater or equal to the minimum specified in this field. 
                                                                            </div>

                                                                            <div style={{maxWidth:'350px',paddingBottom:'10px'}}>
                                                                            There are a total of nine indicators calculated by the platform regardless of investment type (primary home, rental property, or flipping property).
                                                                            </div>

                                                                            <div style={{maxWidth:'350px',paddingBottom:'10px'}}>
                                                                            You can decide if you want to search for properties with all nine green indicators or perhaps you want to consider properties that have some red indicators but still have great potential.
                                                                            </div>

                                                                            <div style={{maxWidth:'350px',paddingBottom:'10px'}}>
                                                                            Note: The higher the value in this field, the fewer the number of matches the search engine will find. A value of 4 or 5 for this field is a good middle ground. 
                                                                            </div>

                                                                          </div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="alertStartDate">Minimum Green Indicator Count</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                    
                                                                    <DropdownButton id="minimumGreenIndicatorCountDropdownSelect" name="minimumGreenIndicatorCountDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.minimumGreenIndicatorCount} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '1']}>1</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '2']}>2</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '3']}>3</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '4']}>4</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '5']}>5</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '6']}>6</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '7']}>7</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '8']}>8</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['minimumGreenIndicatorCount', '9']}>9</Dropdown.Item>
                                                                        </DropdownButton>
                                                                        
                                                                    </div>
                                                                </div>
                                                          </div>
                    

                                                </form>

                                                            </div>


                                                            {/* form row end */}


                                                </div>

                                            </div>
                                        </div>
        
                                   
                                        <div class="modal-footer">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-5">&nbsp;</div>
                                                    <div class="col-md-2">
                                                       <button id="SaveButton" type="button" class="btn btn-primary btn-block" style={{width:"100px"}} onClick={e => this.HandleSave(e, this)} >Save</button>
                                                    </div>
                                                    <div class="col-md-5">&nbsp;</div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>


                                </div>
                                                    
                                <Dialog ref={(component) => { this.dialog = component }}/>


                                {/* Validation Message Modal begin */}

                                  <Modal
                                    show={this.state.showValidationMessage} 
                                    container={this.alertEditoDialogRef}
                                    onHide={e => this.HandleCloseValidationMessage(e, this)}
                                    backdrop="static" 
                                    keyboard={false}
                                  >
                                          <Modal.Header closeButton>
                                            <Modal.Title>Missing Required Fields</Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body style={{padding:'10px'}}>

                                            {this.state.validationErrors.map((error,i) => (
                                              
                                              <div>
                                                <XCircleFill style={{color:"red"}}/> {error}<br></br>
                                               </div>
                                            ))}


                                          </Modal.Body>
                                          
                                          <Modal.Footer>
                                            <Button variant="secondary" onClick={e => this.HandleCloseValidationMessage(e, this)}>
                                              Close
                                            </Button>

                                            
                                          </Modal.Footer>
                                  </Modal>

                                {/*  Validation Message Modal end */}



            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>

            {this.state.uiSaving && 
              <main className={classes.content} style={{height:'1px'}}>
                  <div className={classes.toolbar} />
                  {this.state.uiSaving && <CircularProgress size={150} className={classes.uiProgess} />}
              </main>
            }    

          </Modal>


        );
    }
}


export default (withStyles(styles)(AlertEditDialog));
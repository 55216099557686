import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>credit score</b> indicator compares the property buyer's credit score against the minimum required credit score specified in the benchmark settings. 
                                        </div>

                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The higher the <b>credit score</b>, the better.  Lenders look at a buyer's credit score to determine loan eligibility.
                                    <br></br><br></br>
                                    It's recommended you have a credit score of <b>620</b> or higher when you apply for a conventional loan. If your score is below <b>620</b>, lenders either won't be able to approve your loan or may be required to offer you a higher interest rate, which can result in higher monthly payments. 
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>credit score</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Buyer's Credit Score
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_CREDIT_SCORE}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Minimum Required Credit Score
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_REQUIRED_CREDIT_SCORE}
                                                        </div>
                                                    
                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">

                                   
                                        {/* ---------------------  STEP 1 ------------------ */}
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 1:</span> <b>Compare buyer's credit score against minimum required.</b>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table" style={{paddingTop:"15px"}}>

                                            {this.state.indicator.helpVariableMap.VAR_RESULT_TEXT}

                                            </div>
                                        </div>

                                                                      

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                
                                                    This outcome can be changed by changing the credit score benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                BUYER'S CREDIT SCORE<br></br>
                                                
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      


                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

import React, { Component } from 'react';
import { Modal, Button, Dropdown, DropdownButton, OverlayTrigger,Tooltip} from "react-bootstrap";
import { InfoCircleFill } from 'react-bootstrap-icons';
import { Link45deg } from 'react-bootstrap-icons';

import { FileEarmarkText,  House } from 'react-bootstrap-icons';

import { XCircleFill} from 'react-bootstrap-icons';

import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import lookupHelpBlackDestopImg from '../pages/img/help/lookup-help-black.png';

import lookupHelpWhiteDesktopImg from '../pages/img/help/lookup-help-white.png';
       
import lookupHelpBlackMobileImg from '../pages/img/help/lookup-help-black-mobile.png';

import lookupHelpWhiteMobileImg from '../pages/img/help/lookup-help-white-mobile.png';

import analyzeButtonImg from '../pages/img/help/analyze-button.png';

import saveButtonImg from '../pages/img/help/save-button.png';

import greatNewsImg from '../pages/img/help/great-news.png';

import zestimate from '../pages/img/others/zestimate.png';

import rentzestimate from '../pages/img/others/rentzestimate.png';

import rentrategrowth from '../pages/img/others/rent-rate-growth.png';

import Dialog from 'react-bootstrap-dialog'

import { ChatRightTextFill } from 'react-bootstrap-icons';

import CurrencyInput from '../types/CurrencyInput'

import PercentInput from '../types/PercentInput'

import AnalysisDialog from '../dialogs/AnalysisDialog';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import CircularProgress from '@material-ui/core/CircularProgress';

import { upperCaseFirstLetter } from '../utilities/AppUtility';

import {CircularProgressbar}  from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../pages/css/custom-circular-progress.css';

import withStyles from '@material-ui/core/styles/withStyles';

import 'react-tabs/style/react-tabs.css';

import CustomizedSlider from '../types/CustomizedSlider';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

//import BLO from '../utilities/BLO';
import * as BLO from '../utilities/BLO';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '40%',
		top: '35%'
	}
});


class PropertyEditDialog extends Component { 


  /* Actual one for application */

    state = {
        isOpen: false,
        propertyPrice:'0',
        propertyPriceAsNumber:0,
        marketValue:'0',
        marketValueAsNumber:0,
        marketValuePercent:'10%',
        marketValuePercentAboveBelow:'below',
        downPayment:'0',
        downPaymentAsNumber:0,
        downPaymentPercent:'0%',
        closingCosts:'0',
        closingCostsAsNumber:0,
        closingCostsPercent:'0%',
        interestRate:'0%',
        interestRateAsNumber:0,
        annualAppreciation:'0%',
        annualAppreciationAsNumber:0,
        rentRateOfGrowth:'0%',
        rentRateOfGrowthAsNumber:0,
        expenseRateOfGrowth:'0%',
        expenseRateOfGrowthAsNumber:0,
        vacancyRate:'0%',
        vacancyRateAsNumber:0,
        monthlyPayment:'0',
        monthlyPaymentAsNumber:0,
        monthlyRent:'0',
        monthlyRentAsNumber:0,
        monthlyBills:'0',
        monthlyBillsAsNumber:0,
        monthlyGrossIncome:'0',
        monthlyGrossIncomeAsNumber:0,
        creditScore:'_',
        monthlyExpenses:'0',
        monthlyExpensesAsNumber:0,
        initialExpenses:'0',
        initialExpensesAsNumber:0,
        projectedSaleProceeds:'0',
        projectedSaleProceedsAsNumber:0,
        projectedSaleProceedsMap : null,
        trendingMonthlyRent:'0',
        trendingMonthlyRentAsNumber:0,
        pricePerSqft:'0',
        pricePerSqftAsNumber:0,
        trendingPricePerSqft:'0',
        trendingPricePerSqftAsNumber:0,
        year1CashFlow:'0',
        year1CashFlowAsNumber:0,
        year1CashFlowMap:null,
        year2CashFlow:'0',
        year2CashFlowAsNumber:0,
        year2CashFlowMap:null,
        year3CashFlow:'0',
        year3CashFlowAsNumber:0,
        year3CashFlowMap:null,
        year4CashFlow:'0',
        year4CashFlowAsNumber:0,
        year4CashFlowMap:null,
        year5CashFlow:'0',
        year5CashFlowAsNumber:0,
        year5CashFlowMap:null,
        year6CashFlow:'0',
        year6CashFlowAsNumber:0,
        year6CashFlowMap:null,
        year7CashFlow:'0',
        year7CashFlowAsNumber:0,
        year7CashFlowMap:null,
        year8CashFlow:'0',
        year8CashFlowAsNumber:0,
        year8CashFlowMap:null,
        year9CashFlow:'0',
        year9CashFlowAsNumber:0,
        year9CashFlowMap:null,
        year10CashFlow:'0',
        year10CashFlowAsNumber:0,
        year10CashFlowMap:null,
        year11CashFlow:'0',
        year11CashFlowAsNumber:0,
        year11CashFlowMap:null,
        year12CashFlow:'0',
        year12CashFlowAsNumber:0,
        year12CashFlowMap:null,
        year13CashFlow:'0',
        year13CashFlowAsNumber:0,
        year13CashFlowMap:null,
        year14CashFlow:'0',
        year14CashFlowAsNumber:0,
        year14CashFlowMap:null,
        year15CashFlow:'0',
        year15CashFlowAsNumber:0,
        year15CashFlowMap:null,
        year16CashFlow:'0',
        year16CashFlowAsNumber:0,
        year16CashFlowMap:null,
        year17CashFlow:'0',
        year17CashFlowAsNumber:0,
        year17CashFlowMap:null,
        year18CashFlow:'0',
        year18CashFlowAsNumber:0,
        year18CashFlowMap:null,
        year19CashFlow:'0',
        year19CashFlowAsNumber:0,
        year19CashFlowMap:null,
        year20CashFlow:'0',
        year20CashFlowAsNumber:0,
        year20CashFlowMap:null,
        year21CashFlow:'0',
        year21CashFlowAsNumber:0,
        year21CashFlowMap:null,
        year22CashFlow:'0',
        year22CashFlowAsNumber:0,
        year22CashFlowMap:null,
        year23CashFlow:'0',
        year23CashFlowAsNumber:0,
        year23CashFlowMap:null,
        year24CashFlow:'0',
        year24CashFlowAsNumber:0,
        year24CashFlowMap:null,
        year25CashFlow:'0',
        year25CashFlowAsNumber:0,
        year25CashFlowMap:null,
        year26CashFlow:'0',
        year26CashFlowAsNumber:0,
        year26CashFlowMap:null,
        year27CashFlow:'0',
        year27CashFlowAsNumber:0,
        year27CashFlowMap:null,
        year28CashFlow:'0',
        year28CashFlowAsNumber:0,
        year28CashFlowMap:null,
        year29CashFlow:'0',
        year29CashFlowAsNumber:0,
        year29CashFlowMap:null,
        year30CashFlow:'0',
        year30CashFlowAsNumber:0,
        year30CashFlowMap:null,
        propertyCashFlowMaps:null,
        propertyNickname:'',
        mls:'',
        propertyLink:'',
        address:'',
        city:'',
        stateCode:'_',
        zipcode:'',
        country:'US',
        investmentType : '_',
        propertyType : 'Single Family Home',
        propertySubType: '',
        financingType : 'Loan',
        term:'_',
        termAsNumber:0,
        loanType:'Fixed',
        repaymentType:'Principal & Interest',
        projectedYearOfSale:'_',
        propertyImage: null,
        imagePreviewURL:'',
        propertyNotes:'',
        principalAndInterest:'0',
        principalAndInterestAsNumber:0,
        propertyTaxes:'0',
        propertyTaxesAsNumber:0,
        homeownersInsurance:'0',
        homeownersInsuranceAsNumber:0,
        otherMonthlyPayment:'0',
        otherMonthlyPaymentAsNumber:0,
        hoa:'0',
        hoaAsNumber:0,
        rentalMaintenance:'0',
        rentalMaintenanceAsNumber:0,
        rentalMaintenancePercent:'0%',
        rentalUtilities:'0',
        rentalUtilitiesAsNumber:0,
        rentalPropertyManagement:'0',
        rentalPropertyManagementAsNumber:0,
        rentalPropertyManagementPercent:'0%',
        rentalBookkeeping:'0',
        rentalBookkeepingAsNumber:0,
        rentalOtherExpenses:'0',
        rentalOtherExpensesAsNumber:0,
        rentalTaxFiling:'0',
        rentalTaxFilingAsNumber:0,
        rentalAppraisalFees:'0',
        rentalAppraisalFeesAsNumber:0,
        rentalHomeInspection:'0',
        rentalHomeInspectionAsNumber:0,
        rentalTenantScreening:'0',
        rentalTenantScreeningAsNumber:0,
        rentalMarketing:'0',
        rentalMarketingAsNumber:0,
        rentalOtherInitialExpenses:'0',
        rentalOtherInitialExpensesAsNumber:0,
        flippingMaintenance:'0',
        flippingMaintenanceAsNumber:0,
        flippingUtilities:'0',
        flippingUtilitiesAsNumber:0,
        flippingOtherExpenses:'0',
        flippingOtherExpensesAsNumber:0,
        livingAreaSize:'0',
        livingAreaSizeAsNumber:0,
        lotSize:'0',
        lotSizeAsNumber:0,
        afterRepairValue:'0',
        afterRepairValueAsNumber:0,
        sellingCosts:'0',
        sellingCostsAsNumber:0,
        sellingCostsPercent: '0%',
        holdingCosts:'0',
        holdingCostsAsNumber:0,
        rehabCosts:'0',
        rehabCostsAsNumber:0,
        rehabType:'Cosmetic Rehab',
        budget:'0',
        budgetAsNumber:0,
        holdingMonths:'0',
        holdingMonthsAsNumber:0,
        numberBedrooms:'-',
        numberBathrooms:'-',
        yearBuilt:'0',
        autoInsurance:'0',
        autoInsuranceAsNumber:0,
        cable:'0',
        cableAsNumber:0,
        carMaintenance:'0',
        carMaintenanceAsNumber:0,
        carPayment:'0',
        carPaymentAsNumber:0,
        cellPhone:'0',
        cellPhoneAsNumber:0,
        charitableDonations:'0',
        charitableDonationsAsNumber:0,
        childCare:'0',
        childCareAsNumber:0,
        childSupport:'0',
        childSupportAsNumber:0,
        clothing:'0',
        clothingAsNumber:0,
        creditCard:'0',
        creditCardAsNumber:0,
        dining:'0',
        diningAsNumber:0,
        entertainment:'0',
        entertainmentAsNumber:0,
        gasoline:'0',
        gasolineAsNumber:0,
        electric:'0',
        electricAsNumber:0,
        groceries:'0',
        groceriesAsNumber:0,
        insuranceHealthMedical:'0',
        insuranceHealthMedicalAsNumber:0,
        internet:'0',
        internetAsNumber:0,
        lifeInsurance:'0',
        lifeInsuranceAsNumber:0,
        medicalDentalVision:'0',
        medicalDentalVisionAsNumber:0,
        phone:'0',
        phoneAsNumber:0,
        recreationVacation:'0',
        recreationVacationAsNumber:0,
        studentLoan:'0',
        studentLoanAsNumber:0,
        tuition:'0',
        tuitionAsNumber:0,
        water:'0',
        waterAsNumber:0,
        otherMonthlyBill:'0',
        otherMonthlyBillAsNumber:0,
        showPropertyLookup:false,
        selectedLookupTabIndex:0,
        lookupState:'_',
        showAnalyzePropertyPrompt:false,
        similarSoldProperties:[],
        autoCalculateMonthlyExpenses:true, 
        autoCalculateInitialExpenses:true,
        autoCalculateRehabCosts:true,
        autoCalculateYearCashFlows:true,
        autoCalculateSaleProceeds:true,
        autoCalculateSellingCosts:true,
        autoCalculateHoldingCosts:true,
        forceRecalculateFutureCashFlows:false,
        profileId:null,
        settings:null,
        dbProperty : null,
        showValidationMessage:false,
        validationErrors: [],
        parentReference: null,
        loading: false,
        uiSaving: false,
        lookUpCounter:0,
        profile:null,

        lookUpTrendingRentCounter:0,
		    loadingTrendingRent: false,
        trendingRentInterval:null,
        
        lookUpPricePerSqftCounter:0,
		    loadingPricePerSqft: false,
        pricePerSqftInterval:null,
        
        lookUpVancacyRateCounter:0,
		    loadingVancacyRate: false,
        vancacyRateInterval:null,
        
        lookUpClosingCostsCounter:0,
        loadingClosingCosts: false,
        closingCostsInterval:null,

        lookUpInflationRateCounter:0,
		    loadingInflationRate: false,
        inflationRateInterval:null,

        lookUpRateOfGrowthCounter:0,
		    loadingRentRateOfGrowth: false,
        rentRateOfGrowthInterval:null,

        lookUpTrendingPriceInAreaCounter:0,
		    loadingTrendingPriceInArea: false,
        trendingPriceInAreaInterval:null,

        showMessageBox:false,
        showProcessMessage: false,
        showSuccessMessage: false,

        trendingRentResultSuccess:true,
        trendingRentResultFailure:false,

        vancacyRateResultSuccess:true,
        vancacyRateResultFailure:false,

        rentRateOfGrowthResultSuccess:true,
        rentRateOfGrowthResultFailure:false,

        inflationRateResultSuccess:true,
        inflationRateResultFailure:false,

        closingCostsResultSuccess:true,
        closingCostsResultFailure:false,

        pricePerSqftResultSuccess:true,
        pricePerSqftResultFailure:false,

        trendingPriceInAreaResultSuccess:true,
        trendingPriceInAreaResultFailure:false,

        messageBoxBottomTextSuccess:true,
        messageBoxBottomTextFailure:false

      };
   
      
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.analysisDialogRef = React.createRef();
        this.mainModalInfoDialogRef = React.createRef();

     }

     componentDidUpdate() 
     {
 
     }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }


    PopulateDialog(property,parentReference,selectedInvestmentType,selectedProfileId,settings,profile,user)
    {
      this.ClearFields();

      //console.log("property:");
      //console.log(JSON.stringify(property));

      //console.log("Settings:");
      //console.log(JSON.stringify(settings));

      console.log("selectedInvestmentType:");
      console.log(selectedInvestmentType);

      console.log("selectedProfileId:");
      console.log(selectedProfileId);


      //console.log("HERE:");
      //console.log(JSON.stringify(property));

      this.setState({          
        dbProperty : property,
        profileId:selectedProfileId,
        profile:profile,
        user:user,
        settings: settings,
        annualAppreciation:settings?.annualHomeAppreciationPercent+'%',
        annualAppreciationAsNumber:settings?.annualHomeAppreciationPercent,
        sellingCostsPercent: settings?.sellingCostPercent+'%',
        loadingTrendingRent: false,
		    loadingPricePerSqft: false,
		    loadingVancacyRate: false,
        loadingClosingCosts: false,
		    loadingInflationRate: false,
		    loadingRentRateOfGrowth: false,
		    loadingTrendingPriceInArea: false,
        showMessageBox : false,
        showProcessMessage: false,
        showSuccessMessage: false
      }, () => {

        if (property ==null) // Only for new add
        {         
            this.InitializeHoldingCostsBreakdownFields();     
        }
      });

        if (property ==null) // Only for new add
        {         
            this.setState({
              investmentType:selectedInvestmentType
            }, () => {
              this.HideShowInvestmentTypeFields(null,null,this.state.investmentType);
              
              this.HideShowInitialFinancingType(profile,user);
              
              this.PopulateProfileFields(profile);

            });
      
        } else  //update
        {
          this.PopulateDialogFields(property,settings);
        }

        this.setState({
          parentReference: parentReference
        });

        this.openModal();
    }

    PopulateProfileFields(profile)
    {
      
      if (profile != null) {
           
           this.setState({
         
    monthlyBills:this.FormatMoney(profile.monthlyBills),
    monthlyBillsAsNumber:profile.monthlyBills,
    monthlyGrossIncome:this.FormatMoney(profile.monthlyGrossIncome),
    monthlyGrossIncomeAsNumber:profile.monthlyGrossIncome,
    budget:this.FormatMoney(profile.budget),
    budgetAsNumber:profile.budget,
    creditScore:profile.creditScore,

    otherMonthlyBill:this.FormatMoney(profile.otherMonthlyBill),	
    otherMonthlyBillAsNumber:profile.otherMonthlyBill,
    autoInsurance:this.FormatMoney(profile.autoInsurance),
    autoInsuranceAsNumber:profile.autoInsurance,
    cable:this.FormatMoney(profile.cable),
    cableAsNumber:profile.cable,
    carMaintenance:this.FormatMoney(profile.carMaintenance),
    carMaintenanceAsNumber:profile.carMaintenance,
    carPayment:this.FormatMoney(profile.carPayment),
    carPaymentAsNumber:profile.carPayment,
    cellPhone:this.FormatMoney(profile.cellPhone),
    cellPhoneAsNumber:profile.cellPhone,
    charitableDonations:this.FormatMoney(profile.charitableDonations),
    charitableDonationsAsNumber:profile.charitableDonations,
    childCare:this.FormatMoney(profile.childCare),
    childCareAsNumber:profile.childCare,
    childSupport:this.FormatMoney(profile.childSupport),
    childSupportAsNumber:profile.childSupport,
    clothing:this.FormatMoney(profile.clothing),
    clothingAsNumber:profile.clothing,
    creditCard:this.FormatMoney(profile.creditCard),
    creditCardAsNumber:profile.creditCard,
    dining:this.FormatMoney(profile.dining),
    diningAsNumber:profile.dining,
    entertainment:this.FormatMoney(profile.entertainment),
    entertainmentAsNumber:profile.entertainment,
    gasoline:this.FormatMoney(profile.gasoline),
    gasolineAsNumber:profile.gasoline,
    electric:this.FormatMoney(profile.electric),
    electricAsNumber:profile.electric,
    groceries:this.FormatMoney(profile.groceries),
    groceriesAsNumber:profile.groceries,
    insuranceHealthMedical:this.FormatMoney(profile.insuranceHealthMedical),
    insuranceHealthMedicalAsNumber:profile.insuranceHealthMedical,
    internet:this.FormatMoney(profile.internet),
    internetAsNumber:profile.internet,
    lifeInsurance:this.FormatMoney(profile.lifeInsurance),
    lifeInsuranceAsNumber:profile.lifeInsurance,
    medicalDentalVision:this.FormatMoney(profile.medicalDentalVision),
    medicalDentalVisionAsNumber:profile.medicalDentalVision,
    phone:this.FormatMoney(profile.phone),
    phoneAsNumber:profile.phone,
    recreationVacation:this.FormatMoney(profile.recreationVacation),
    recreationVacationAsNumber:profile.recreationVacation,
    studentLoan:this.FormatMoney(profile.studentLoan),
    studentLoanAsNumber:profile.studentLoan,
    tuition:this.FormatMoney(profile.tuition),
    tuitionAsNumber:profile.tuition,
    water:this.FormatMoney(profile.water),
    waterAsNumber:profile.water
    }, () => {

      const calculatedMonthlyBills = parseInt(this.state.otherMonthlyBillAsNumber)+parseInt(this.state.autoInsuranceAsNumber)+parseInt(this.state.cableAsNumber)+parseInt(this.state.carMaintenanceAsNumber)+parseInt(this.state.carPaymentAsNumber)+parseInt(this.state.cellPhoneAsNumber)+ parseInt(this.state.charitableDonationsAsNumber)+ parseInt(this.state.childCareAsNumber)+parseInt(this.state.childSupportAsNumber)+parseInt(this.state.clothingAsNumber)+parseInt(this.state.creditCardAsNumber)+parseInt(this.state.diningAsNumber)+parseInt(this.state.entertainmentAsNumber)+parseInt(this.state.gasolineAsNumber)+parseInt(this.state.electricAsNumber)+parseInt(this.state.groceriesAsNumber)+parseInt(this.state.insuranceHealthMedicalAsNumber)+parseInt(this.state.internetAsNumber)+parseInt(this.state.lifeInsuranceAsNumber)+parseInt(this.state.medicalDentalVisionAsNumber)+parseInt(this.state.phoneAsNumber)+parseInt(this.state.recreationVacationAsNumber)+parseInt(this.state.studentLoanAsNumber)+parseInt(this.state.tuitionAsNumber)+parseInt(this.state.waterAsNumber);

      
      if (isNaN(calculatedMonthlyBills) || calculatedMonthlyBills ==0)
      {
        this.setState({
          otherMonthlyBill:this.FormatMoney(profile.monthlyBills),	
          otherMonthlyBillAsNumber:profile.monthlyBills
        });

      }
  });

          }
    }

 PopulateProfileFieldsOLD(profileId)
 {
      const payload = {
        profileId:profileId
      }

      authMiddleWare(this?.props?.history);
      const authToken = localStorage.getItem('AuthToken');
      const refreshToken = localStorage.getItem('RefreshToken');
      axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

      axios
      .post('/api/profile', payload)
      .then((response) => {
        
        if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
        {
            localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
        }

        this.setState({
          monthlyBills:this.FormatMoney(response.data.monthlyBills),
          monthlyBillsAsNumber:response.data.monthlyBills,
          monthlyGrossIncome:this.FormatMoney(response.data.monthlyGrossIncome),
          monthlyGrossIncomeAsNumber:response.data.monthlyGrossIncome,
          budget:this.FormatMoney(response.data.budget),
          budgetAsNumber:response.data.budget,
          creditScore:response.data.creditScore,

          otherMonthlyBill:this.FormatMoney(response.data.otherMonthlyBill),	
          otherMonthlyBillAsNumber:response.data.otherMonthlyBill,
          autoInsurance:this.FormatMoney(response.data.autoInsurance),
          autoInsuranceAsNumber:response.data.autoInsurance,
          cable:this.FormatMoney(response.data.cable),
          cableAsNumber:response.data.cable,
          carMaintenance:this.FormatMoney(response.data.carMaintenance),
          carMaintenanceAsNumber:response.data.carMaintenance,
          carPayment:this.FormatMoney(response.data.carPayment),
          carPaymentAsNumber:response.data.carPayment,
          cellPhone:this.FormatMoney(response.data.cellPhone),
          cellPhoneAsNumber:response.data.cellPhone,
          charitableDonations:this.FormatMoney(response.data.charitableDonations),
          charitableDonationsAsNumber:response.data.charitableDonations,
          childCare:this.FormatMoney(response.data.childCare),
          childCareAsNumber:response.data.childCare,
          childSupport:this.FormatMoney(response.data.childSupport),
          childSupportAsNumber:response.data.childSupport,
          clothing:this.FormatMoney(response.data.clothing),
          clothingAsNumber:response.data.clothing,
          creditCard:this.FormatMoney(response.data.creditCard),
          creditCardAsNumber:response.data.creditCard,
          dining:this.FormatMoney(response.data.dining),
          diningAsNumber:response.data.dining,
          entertainment:this.FormatMoney(response.data.entertainment),
          entertainmentAsNumber:response.data.entertainment,
          gasoline:this.FormatMoney(response.data.gasoline),
          gasolineAsNumber:response.data.gasoline,
          electric:this.FormatMoney(response.data.electric),
          electricAsNumber:response.data.electric,
          groceries:this.FormatMoney(response.data.groceries),
          groceriesAsNumber:response.data.groceries,
          insuranceHealthMedical:this.FormatMoney(response.data.insuranceHealthMedical),
          insuranceHealthMedicalAsNumber:response.data.insuranceHealthMedical,
          internet:this.FormatMoney(response.data.internet),
          internetAsNumber:response.data.internet,
          lifeInsurance:this.FormatMoney(response.data.lifeInsurance),
          lifeInsuranceAsNumber:response.data.lifeInsurance,
          medicalDentalVision:this.FormatMoney(response.data.medicalDentalVision),
          medicalDentalVisionAsNumber:response.data.medicalDentalVision,
          phone:this.FormatMoney(response.data.phone),
          phoneAsNumber:response.data.phone,
          recreationVacation:this.FormatMoney(response.data.recreationVacation),
          recreationVacationAsNumber:response.data.recreationVacation,
          studentLoan:this.FormatMoney(response.data.studentLoan),
          studentLoanAsNumber:response.data.studentLoan,
          tuition:this.FormatMoney(response.data.tuition),
          tuitionAsNumber:response.data.tuition,
          water:this.FormatMoney(response.data.water),
          waterAsNumber:response.data.water
          }, () => {
  
            const calculatedMonthlyBills = parseInt(this.state.otherMonthlyBillAsNumber)+parseInt(this.state.autoInsuranceAsNumber)+parseInt(this.state.cableAsNumber)+parseInt(this.state.carMaintenanceAsNumber)+parseInt(this.state.carPaymentAsNumber)+parseInt(this.state.cellPhoneAsNumber)+ parseInt(this.state.charitableDonationsAsNumber)+ parseInt(this.state.childCareAsNumber)+parseInt(this.state.childSupportAsNumber)+parseInt(this.state.clothingAsNumber)+parseInt(this.state.creditCardAsNumber)+parseInt(this.state.diningAsNumber)+parseInt(this.state.entertainmentAsNumber)+parseInt(this.state.gasolineAsNumber)+parseInt(this.state.electricAsNumber)+parseInt(this.state.groceriesAsNumber)+parseInt(this.state.insuranceHealthMedicalAsNumber)+parseInt(this.state.internetAsNumber)+parseInt(this.state.lifeInsuranceAsNumber)+parseInt(this.state.medicalDentalVisionAsNumber)+parseInt(this.state.phoneAsNumber)+parseInt(this.state.recreationVacationAsNumber)+parseInt(this.state.studentLoanAsNumber)+parseInt(this.state.tuitionAsNumber)+parseInt(this.state.waterAsNumber);

            
            if (isNaN(calculatedMonthlyBills) || calculatedMonthlyBills ==0)
            {
              this.setState({
                otherMonthlyBill:this.FormatMoney(response.data.monthlyBills),	
                otherMonthlyBillAsNumber:response.data.monthlyBills
              });
      
            }
        });

      })
      .catch((error) => {
        console.log(error);

        if (error && error.response && error.response.status && error.response.status === 403) {
              localStorage.setItem('SessionExpired',"Y");
              //window.location.reload();
              window.location.href = '/login';
          }
        
      });
 }

 PopulateDialogFields(property,settings)
 {
  
  //console.log("THE PROPERTY:");
  //console.log(JSON.stringify(property));

  let term = "";
  if (isNaN(property.term))
  {
    
    term = property.term.replace("-"," ").replace("year", "Years");
  }else
  {
    term = property.term + " Years";
  }

  let pricePerSqftFormatted = "";

  if (property.trendingPricePerSqft == null || property.trendingPricePerSqft ==0)
  { 
    if (property.similarSoldProperties != null)
    {
    pricePerSqftFormatted = this.CalculatePropertyPricePerSqftAverage(property.similarSoldProperties);
    property.trendingPricePerSqft = this.ConvertToNumber(pricePerSqftFormatted);
    }
  }
  
  this.setState({
      propertyPrice:this.FormatMoney(property.price),
      propertyPriceAsNumber:property.price,
      marketValue:this.FormatMoney(property.marketValue),
      marketValueAsNumber:property.marketValue,
      marketValuePercent:property.marketValuePercent,
      marketValuePercentAboveBelow:property.marketValuePercentAboveBelow,
      downPayment:this.FormatMoney(property.downPayment),
      downPaymentAsNumber:property.downPayment,
      downPaymentPercent:this.FormatPercent(property.downPaymentPercent),
      closingCosts:this.FormatMoney(property.closingCosts),
      closingCostsAsNumber:property.closingCosts,
      interestRate:this.FormatPercent(property.interestRate),
      interestRateAsNumber:parseFloat(property.interestRate),
      annualAppreciation:settings?.annualHomeAppreciationPercent+'%',
      annualAppreciationAsNumber:settings?.annualHomeAppreciationPercent,
      rentRateOfGrowth:this.FormatPercent(property.rentRateOfGrowth),
      rentRateOfGrowthAsNumber:property.rentRateOfGrowth,
      expenseRateOfGrowth:this.FormatPercent(property.expenseRateOfGrowth),
      expenseRateOfGrowthAsNumber:property.expenseRateOfGrowth,
      vacancyRate:this.FormatPercent(property.vacancyRate),
      vacancyRateAsNumber:property.vacancyRate,
      monthlyPayment:this.FormatMoney(property.monthlyPayment),
      monthlyPaymentAsNumber:property.monthlyPayment,
      monthlyRent:this.FormatMoney(property.monthlyRent),
      monthlyRentAsNumber:property.monthlyRent,
      monthlyBills:this.FormatMoney(property.monthlyBills),
      monthlyBillsAsNumber:property.monthlyBills,
      monthlyGrossIncome:this.FormatMoney(property.monthlyGrossIncome),
      monthlyGrossIncomeAsNumber:property.monthlyGrossIncome,
      creditScore:property.creditScore,
      monthlyExpenses:this.FormatMoney(property.monthlyExpenses),
      monthlyExpensesAsNumber:property.monthlyExpenses,
      initialExpenses:this.FormatMoney(property.initialExpenses),
      initialExpensesAsNumber:property.initialExpenses,
      projectedSaleProceeds:this.FormatMoney(property.projectedSaleProceeds),
      projectedSaleProceedsAsNumber:property.projectedSaleProceeds,
      projectedSaleProceedsMap : null,                                        //MAP
      trendingMonthlyRent:this.FormatMoney(property.trendingMonthlyRent),
      trendingMonthlyRentAsNumber:property.trendingMonthlyRent,
      pricePerSqft:this.FormatMoney(property.pricePerSqft),
      pricePerSqftAsNumber:property.pricePerSqft,
      trendingPricePerSqft:this.FormatMoney(property.trendingPricePerSqft),
      trendingPricePerSqftAsNumber:property.trendingPricePerSqft,
      year1CashFlow:this.FormatMoney(property.year1CashFlow),
      year1CashFlowAsNumber:property.year1CashFlow,
      year1CashFlowMap:null,                                                  //MAP
      year2CashFlow:this.FormatMoney(property.year2CashFlow),
      year2CashFlowAsNumber:property.year2CashFlow,
      year2CashFlowMap:null,
      year3CashFlow:this.FormatMoney(property.year3CashFlow),
      year3CashFlowAsNumber:property.year3CashFlow,
      year3CashFlowMap:null,
      year4CashFlow:this.FormatMoney(property.year4CashFlow),
      year4CashFlowAsNumber:property.year4CashFlow,
      year4CashFlowMap:null,
      year5CashFlow:this.FormatMoney(property.year5CashFlow),
      year5CashFlowAsNumber:property.year5CashFlow,
      year5CashFlowMap:null,
      year6CashFlow:this.FormatMoney(property.year6CashFlow),
      year6CashFlowAsNumber:property.year6CashFlow,
      year6CashFlowMap:null,
      year7CashFlow:this.FormatMoney(property.year7CashFlow),
      year7CashFlowAsNumber:property.year7CashFlow,
      year7CashFlowMap:null,
      year8CashFlow:this.FormatMoney(property.year8CashFlow),
      year8CashFlowAsNumber:property.year8CashFlow,
      year8CashFlowMap:null,
      year9CashFlow:this.FormatMoney(property.year9CashFlow),
      year9CashFlowAsNumber:property.year9CashFlow,
      year9CashFlowMap:null,
      year10CashFlow:this.FormatMoney(property.year10CashFlow),
      year10CashFlowAsNumber:property.year10CashFlow,
      year10CashFlowMap:null,
      year11CashFlow:this.FormatMoney(property.year11CashFlow),
      year11CashFlowAsNumber:property.year11CashFlow,
      year11CashFlowMap:null,
      year12CashFlow:this.FormatMoney(property.year12CashFlow),
      year12CashFlowAsNumber:property.year12CashFlow,
      year12CashFlowMap:null,
      year13CashFlow:this.FormatMoney(property.year13CashFlow),
      year13CashFlowAsNumber:property.year13CashFlow,
      year13CashFlowMap:null,
      year14CashFlow:this.FormatMoney(property.year14CashFlow),
      year14CashFlowAsNumber:property.year14CashFlow,
      year14CashFlowMap:null,
      year15CashFlow:this.FormatMoney(property.year15CashFlow),
      year15CashFlowAsNumber:property.year15CashFlow,
      year15CashFlowMap:null,
      year16CashFlow:this.FormatMoney(property.year16CashFlow),
      year16CashFlowAsNumber:property.year17CashFlow,
      year16CashFlowMap:null,
      year17CashFlow:this.FormatMoney(property.year17CashFlow),
      year17CashFlowAsNumber:property.year18CashFlow,
      year17CashFlowMap:null,
      year18CashFlow:this.FormatMoney(property.year18CashFlow),
      year18CashFlowAsNumber:property.year19CashFlow,
      year18CashFlowMap:null,
      year19CashFlow:this.FormatMoney(property.year19CashFlow),
      year19CashFlowAsNumber:property.year20CashFlow,
      year19CashFlowMap:null,
      year20CashFlow:this.FormatMoney(property.year20CashFlow),
      year20CashFlowAsNumber:property.year20CashFlow,
      year20CashFlowMap:null,
      year21CashFlow:this.FormatMoney(property.year21CashFlow),
      year21CashFlowAsNumber:property.year21CashFlow,
      year21CashFlowMap:null,
      year22CashFlow:this.FormatMoney(property.year22CashFlow),
      year22CashFlowAsNumber:property.year22CashFlow,
      year22CashFlowMap:null,
      year23CashFlow:this.FormatMoney(property.year23CashFlow),
      year23CashFlowAsNumber:property.year23CashFlow,
      year23CashFlowMap:null,
      year24CashFlow:this.FormatMoney(property.year24CashFlow),
      year24CashFlowAsNumber:property.year24CashFlow,
      year24CashFlowMap:null,
      year25CashFlow:this.FormatMoney(property.year25CashFlow),
      year25CashFlowAsNumber:property.year25CashFlow,
      year25CashFlowMap:null,
      year26CashFlow:this.FormatMoney(property.year26CashFlow),
      year26CashFlowAsNumber:property.year26CashFlow,
      year26CashFlowMap:null,
      year27CashFlow:this.FormatMoney(property.year27CashFlow),
      year27CashFlowAsNumber:property.year27CashFlow,
      year27CashFlowMap:null,
      year28CashFlow:this.FormatMoney(property.year28CashFlow),
      year28CashFlowAsNumber:property.year28CashFlow,
      year28CashFlowMap:null,
      year29CashFlow:this.FormatMoney(property.year29CashFlow),
      year29CashFlowAsNumber:property.year29CashFlow,
      year29CashFlowMap:null,
      year30CashFlow:this.FormatMoney(property.year30CashFlow),
      year30CashFlowAsNumber:property.year30CashFlow,
      year30CashFlowMap:null,
      propertyCashFlowMaps:null,                                  //Map
      propertyNickname:'',
      mls:property.mls,
      propertyLink:property.propertyLink,
      address: property.address,
      city: property.city,
      stateCode: property.state,
      zipcode:property.zipcode,
      country:property.country,
      investmentType : property.investmentType,
      propertyType : this.NormalizePropertyType(property.propertyType,property.address),
      propertySubType: property.propertySubType,
      financingType : property.financingType,
      term:term,
      termAsNumber:this.ConvertInitialTermAsNumber(term),
      loanType:property.loanType,
      repaymentType:property.repaymentType,
      projectedYearOfSale:property.projectedYearOfSale,
      propertyImage: property.propertyImage,
      imagePreviewURL:property.propertyImage,
      propertyNotes:property.propertyNotes,
      principalAndInterest:this.FormatMoney(property.principalAndInterest),
      principalAndInterestAsNumber:property.principalAndInterest,
      propertyTaxes:this.FormatMoney(property.propertyTaxes),
      propertyTaxesAsNumber:property.propertyTaxes,
      homeownersInsurance:this.FormatMoney(property.homeownersInsurance),
      homeownersInsuranceAsNumber:property.homeownersInsurance,
      otherMonthlyPayment:this.FormatMoney(property.otherMonthlyPayment),
      otherMonthlyPaymentAsNumber:property.otherMonthlyPayment,
      hoa:this.FormatMoney(property.hoa),
      hoaAsNumber:property.hoa,
      otherMonthlyBill:this.FormatMoney(property.otherMonthlyBill),	
      otherMonthlyBillAsNumber:property.otherMonthlyBill,
      autoInsurance:this.FormatMoney(property.autoInsurance),
      autoInsuranceAsNumber:property.autoInsurance,
      cable:this.FormatMoney(property.cable),
      cableAsNumber:property.cable,
      carMaintenance:this.FormatMoney(property.carMaintenance),
      carMaintenanceAsNumber:property.carMaintenance,
      carPayment:this.FormatMoney(property.carPayment),
      carPaymentAsNumber:property.carPayment,
      cellPhone:this.FormatMoney(property.cellPhone),
      cellPhoneAsNumber:property.cellPhone,
      charitableDonations:this.FormatMoney(property.charitableDonations),
      charitableDonationsAsNumber:property.charitableDonations,
      childCare:this.FormatMoney(property.childCare),
      childCareAsNumber:property.childCare,
      childSupport:this.FormatMoney(property.childSupport),
      childSupportAsNumber:property.childSupport,
      clothing:this.FormatMoney(property.clothing),
      clothingAsNumber:property.clothing,
      creditCard:this.FormatMoney(property.creditCard),
      creditCardAsNumber:property.creditCard,
      dining:this.FormatMoney(property.dining),
      diningAsNumber:property.dining,
      entertainment:this.FormatMoney(property.entertainment),
      entertainmentAsNumber:property.entertainment,
      gasoline:this.FormatMoney(property.gasoline),
      gasolineAsNumber:property.gasoline,
      electric:this.FormatMoney(property.electric),
      electricAsNumber:property.electric,
      groceries:this.FormatMoney(property.groceries),
      groceriesAsNumber:property.groceries,
      insuranceHealthMedical:this.FormatMoney(property.insuranceHealthMedical),
      insuranceHealthMedicalAsNumber:property.insuranceHealthMedical,
      internet:this.FormatMoney(property.internet),
      internetAsNumber:property.internet,
      lifeInsurance:this.FormatMoney(property.lifeInsurance),
      lifeInsuranceAsNumber:property.lifeInsurance,
      medicalDentalVision:this.FormatMoney(property.medicalDentalVision),
      medicalDentalVisionAsNumber:property.medicalDentalVision,
      phone:this.FormatMoney(property.phone),
      phoneAsNumber:property.phone,
      recreationVacation:this.FormatMoney(property.recreationVacation),
      recreationVacationAsNumber:property.recreationVacation,
      studentLoan:this.FormatMoney(property.studentLoan),
      studentLoanAsNumber:property.studentLoan,
      tuition:this.FormatMoney(property.tuition),
      tuitionAsNumber:property.tuition,
      water:this.FormatMoney(property.water),
      waterAsNumber:property.water,    
      rentalMaintenance:this.FormatMoney(property.rentalMaintenance),
      rentalMaintenanceAsNumber:property.rentalMaintenance,
      rentalMaintenancePercent:this.FormatPercent(property.rentalMaintenancePercent),
      rentalUtilities:this.FormatMoney(property.rentalUtilities),
      rentalUtilitiesAsNumber:property.rentalUtilities,
      rentalPropertyManagement:this.FormatMoney(property.rentalPropertyManagement),
      rentalPropertyManagementAsNumber:property.rentalPropertyManagement,
      rentalPropertyManagementPercent:this.FormatPercent(property.rentalPropertyManagementPercent),
      rentalBookkeeping:this.FormatMoney(property.rentalBookkeeping),
      rentalBookkeepingAsNumber:property.rentalBookkeeping,
      rentalOtherExpenses:this.FormatMoney(property.rentalOtherExpenses),
      rentalOtherExpensesAsNumber:property.rentalOtherExpenses,
      rentalTaxFiling:this.FormatMoney(property.rentalTaxFiling),
      rentalTaxFilingAsNumber:property.rentalTaxFiling,
      rentalAppraisalFees:this.FormatMoney(property.rentalAppraisalFees),
      rentalAppraisalFeesAsNumber:property.rentalAppraisalFees,
      rentalHomeInspection:this.FormatMoney(property.rentalHomeInspection),
      rentalHomeInspectionAsNumber:property.rentalHomeInspection,
      rentalTenantScreening:this.FormatMoney(property.rentalTenantScreening),
      rentalTenantScreeningAsNumber:property.rentalTenantScreening,
      rentalMarketing:this.FormatMoney(property.rentalMarketing),
      rentalMarketingAsNumber:property.rentalMarketing,
      rentalOtherInitialExpenses:this.FormatMoney(property.rentalOtherInitialExpenses),
      rentalOtherInitialExpensesAsNumber:property.rentalOtherInitialExpenses,
      flippingMaintenance:this.FormatMoney(property.flippingMaintenance),
      flippingMaintenanceAsNumber:property.flippingMaintenance,
      flippingUtilities:this.FormatMoney(property.flippingUtilities),
      flippingUtilitiesAsNumber:property.flippingUtilities,
      flippingOtherExpenses:this.FormatMoney(property.flippingOtherExpenses),
      flippingOtherExpensesAsNumber:property.flippingOtherExpenses,
      livingAreaSize:this.FormatNumber(property.livingAreaSize),
      livingAreaSizeAsNumber:property.livingAreaSize,
      lotSize:this.FormatNumber(property.lotSize),  
      lotSizeAsNumber:property.lotSize,
      afterRepairValue:this.FormatMoney(property.afterRepairValue),
      afterRepairValueAsNumber:property.afterRepairValue,
      sellingCosts:this.FormatMoney(property.sellingCosts),
      sellingCostsAsNumber:property.sellingCosts,
      sellingCostsPercent: this.FormatPercent(settings?.sellingCostPercent),     //Settings
      holdingCosts:this.FormatMoney(property.holdingCosts),
      holdingCostsAsNumber:property.holdingCosts,
      rehabCosts:this.FormatMoney(property.rehabCosts),
      rehabCostsAsNumber:property.rehabCosts,
      rehabType:property.rehabType,
      budget:this.FormatMoney(property.budget),
      budgetAsNumber:property.budget,
      holdingMonths:this.FormatNumber(property.holdingMonths),
      holdingMonthsAsNumber:property.holdingMonths,
      numberBedrooms:property.numberBedrooms,
      numberBathrooms:property.numberBathrooms,
      yearBuilt:property.yearBuilt
    }, () => {

      if (property.similarSoldProperties != null)
      {
        this.setState({
          similarSoldProperties:property.similarSoldProperties
        });
      }

      this.HideShowInvestmentTypeFields(null,null,this.state.investmentType);

      if (this.state.propertyImage != "")
      {
        document.querySelector('#propertyImage').style.display = "block";
      }else
      {
        document.querySelector('#propertyImage').style.display = "none"; 
      }

       //Display property link button
       if (this.state.propertyLink != '' && this.state.propertyLink.toLowerCase().startsWith("http"))
       {
         document.querySelector('#propertyDetailLink').style.visibility = "visible";
 
       } else
       {
         document.querySelector('#propertyDetailLink').style.visibility = "hidden  ";
       }

       //Populate Projected Cashflows

       if (this.state.investmentType == "Rental Property")
       {
          this.HideOrShowProjectedYears(null,null,this.state.projectedYearOfSale);
       }

       //Show or hide Finacing Fields
       this.HideShowLoanFields(null,null,this.state.financingType);

    });



 }


 
  ClearFields()
  {

    this.ResetMessageBoxValues();

    this.setState({
      propertyPrice:'0',
      propertyPriceAsNumber:0,
      marketValue:'0',
      marketValueAsNumber:0,
      marketValuePercent:'10%',
      marketValuePercentAboveBelow:'below',
      downPayment:'0',
      downPaymentAsNumber:0,
      downPaymentPercent:'0%',
      closingCosts:'0',
      closingCostsAsNumber:0,
      interestRate:'0%',
      interestRateAsNumber:0,
      annualAppreciation:this.state.settings?.annualHomeAppreciationPercent+'%',
      annualAppreciationAsNumber:this.state.settings?.annualHomeAppreciationPercent,
      rentRateOfGrowth:'0%',
      rentRateOfGrowthAsNumber:0,
      expenseRateOfGrowth:'0%',
      expenseRateOfGrowthAsNumber:0,
      vacancyRate:'0%',
      vacancyRateAsNumber:0,
      monthlyPayment:'0',
      monthlyPaymentAsNumber:0,
      monthlyRent:'0',
      monthlyRentAsNumber:0,
      monthlyBills:'0',
      monthlyBillsAsNumber:0,
      monthlyGrossIncome:'0',
      monthlyGrossIncomeAsNumber:0,
      creditScore:'_',
      monthlyExpenses:'0',
      monthlyExpensesAsNumber:0,
      initialExpenses:'0',
      initialExpensesAsNumber:0,
      projectedSaleProceeds:'0',
      projectedSaleProceedsAsNumber:0,
      projectedSaleProceedsMap : null,
      trendingMonthlyRent:'0',
      trendingMonthlyRentAsNumber:0,
      pricePerSqft:'0',
      pricePerSqftAsNumber:0,
      trendingPricePerSqft:'0',
      trendingPricePerSqftAsNumber:0,
      year1CashFlow:'0',
      year1CashFlowAsNumber:0,
      year1CashFlowMap:null,
      year2CashFlow:'0',
      year2CashFlowAsNumber:0,
      year2CashFlowMap:null,
      year3CashFlow:'0',
      year3CashFlowAsNumber:0,
      year3CashFlowMap:null,
      year4CashFlow:'0',
      year4CashFlowAsNumber:0,
      year4CashFlowMap:null,
      year5CashFlow:'0',
      year5CashFlowAsNumber:0,
      year5CashFlowMap:null,
      year6CashFlow:'0',
      year6CashFlowAsNumber:0,
      year6CashFlowMap:null,
      year7CashFlow:'0',
      year7CashFlowAsNumber:0,
      year7CashFlowMap:null,
      year8CashFlow:'0',
      year8CashFlowAsNumber:0,
      year8CashFlowMap:null,
      year9CashFlow:'0',
      year9CashFlowAsNumber:0,
      year9CashFlowMap:null,
      year10CashFlow:'0',
      year10CashFlowAsNumber:0,
      year10CashFlowMap:null,
      year11CashFlow:'0',
      year11CashFlowAsNumber:0,
      year11CashFlowMap:null,
      year12CashFlow:'0',
      year12CashFlowAsNumber:0,
      year12CashFlowMap:null,
      year13CashFlow:'0',
      year13CashFlowAsNumber:0,
      year13CashFlowMap:null,
      year14CashFlow:'0',
      year14CashFlowAsNumber:0,
      year14CashFlowMap:null,
      year15CashFlow:'0',
      year15CashFlowAsNumber:0,
      year15CashFlowMap:null,
      year16CashFlow:'0',
      year16CashFlowAsNumber:0,
      year16CashFlowMap:null,
      year17CashFlow:'0',
      year17CashFlowAsNumber:0,
      year17CashFlowMap:null,
      year18CashFlow:'0',
      year18CashFlowAsNumber:0,
      year18CashFlowMap:null,
      year19CashFlow:'0',
      year19CashFlowAsNumber:0,
      year19CashFlowMap:null,
      year20CashFlow:'0',
      year20CashFlowAsNumber:0,
      year20CashFlowMap:null,
      year21CashFlow:'0',
      year21CashFlowAsNumber:0,
      year21CashFlowMap:null,
      year22CashFlow:'0',
      year22CashFlowAsNumber:0,
      year22CashFlowMap:null,
      year23CashFlow:'0',
      year23CashFlowAsNumber:0,
      year23CashFlowMap:null,
      year24CashFlow:'0',
      year24CashFlowAsNumber:0,
      year24CashFlowMap:null,
      year25CashFlow:'0',
      year25CashFlowAsNumber:0,
      year25CashFlowMap:null,
      year26CashFlow:'0',
      year26CashFlowAsNumber:0,
      year26CashFlowMap:null,
      year27CashFlow:'0',
      year27CashFlowAsNumber:0,
      year27CashFlowMap:null,
      year28CashFlow:'0',
      year28CashFlowAsNumber:0,
      year28CashFlowMap:null,
      year29CashFlow:'0',
      year29CashFlowAsNumber:0,
      year29CashFlowMap:null,
      year30CashFlow:'0',
      year30CashFlowAsNumber:0,
      year30CashFlowMap:null,
      propertyCashFlowMaps:null,
      propertyNickname:'',
      mls:'',
      propertyLink:'',
      address:'',
      city:'',
      stateCode:'_',
      zipcode:'',
      country:'US',
      investmentType : 'Primary Home',
      propertyType : 'Single Family Home',
      propertySubType: '',
      financingType : 'Loan',
      term:'_',
      termAsNumber:0,
      loanType:'Fixed',
      repaymentType:'Principal & Interest',
      projectedYearOfSale:'_',
      propertyImage: null,
      imagePreviewURL:'',
      propertyNotes:'',
      principalAndInterest:'0',
      principalAndInterestAsNumber:0,
      propertyTaxes:'0',
      propertyTaxesAsNumber:0,
      homeownersInsurance:'0',
      homeownersInsuranceAsNumber:0,
      otherMonthlyPayment:'0',
      otherMonthlyPaymentAsNumber:0,
      hoa:'0',
      hoaAsNumber:0,
      otherMonthlyBill:'0',
      otherMonthlyBillAsNumber:0,
      autoInsurance:'0',
      autoInsuranceAsNumber:0,
      cable:'0',
      cableAsNumber:0,
      carMaintenance:'0',
      carMaintenanceAsNumber:0,
      carPayment:'0',
      carPaymentAsNumber:0,
      cellPhone:'0',
      cellPhoneAsNumber:0,
      charitableDonations:'0',
      charitableDonationsAsNumber:0,
      childCare:'0',
      childCareAsNumber:0,
      childSupport:'0',
      childSupportAsNumber:0,
      clothing:'0',
      clothingAsNumber:0,
      creditCard:'0',
      creditCardAsNumber:0,
      dining:'0',
      diningAsNumber:0,
      entertainment:'0',
      entertainmentAsNumber:0,
      gasoline:'0',
      gasolineAsNumber:0,
      electric:'0',
      electricAsNumber:0,
      groceries:'0',
      groceriesAsNumber:0,
      insuranceHealthMedical:'0',
      insuranceHealthMedicalAsNumber:0,
      internet:'0',
      internetAsNumber:0,
      lifeInsurance:'0',
      lifeInsuranceAsNumber:0,
      medicalDentalVision:'0',
      medicalDentalVisionAsNumber:0,
      phone:'0',
      phoneAsNumber:0,
      recreationVacation:'0',
      recreationVacationAsNumber:0,
      studentLoan:'0',
      studentLoanAsNumber:0,
      tuition:'0',
      tuitionAsNumber:0,
      water:'0',
      waterAsNumber:0,
      rentalMaintenance:'0',
      rentalMaintenanceAsNumber:0,
      rentalMaintenancePercent:'0%',
      rentalUtilities:'0',
      rentalUtilitiesAsNumber:0,
      rentalPropertyManagement:'0',
      rentalPropertyManagementAsNumber:0,
      rentalPropertyManagementPercent:'0%',
      rentalBookkeeping:'0',
      rentalBookkeepingAsNumber:0,
      rentalOtherExpenses:'0',
      rentalOtherExpensesAsNumber:0,
      rentalTaxFiling:'0',
      rentalTaxFilingAsNumber:0,
      rentalAppraisalFees:'0',
      rentalAppraisalFeesAsNumber:0,
      rentalHomeInspection:'0',
      rentalHomeInspectionAsNumber:0,
      rentalTenantScreening:'0',
      rentalTenantScreeningAsNumber:0,
      rentalMarketing:'0',
      rentalMarketingAsNumber:0,
      rentalOtherInitialExpenses:'0',
      rentalOtherInitialExpensesAsNumber:0,
      flippingMaintenance:'0',
      flippingMaintenanceAsNumber:0,
      flippingUtilities:'0',
      flippingUtilitiesAsNumber:0,
      flippingOtherExpenses:'0',
      flippingOtherExpensesAsNumber:0,
      livingAreaSize:'0',
      livingAreaSizeAsNumber:0,
      lotSize:'0',
      lotSizeAsNumber:0,
      afterRepairValue:'0',
      afterRepairValueAsNumber:0,
      sellingCosts:'0',
      sellingCostsAsNumber:0,
      sellingCostsPercent: this.state.settings?.sellingCostPercent+'%',
      holdingCosts:'0',
      holdingCostsAsNumber:0,
      rehabCosts:'0',
      rehabCostsAsNumber:0,
      rehabType:'Cosmetic Rehab',
      budget:'0',
      budgetAsNumber:0,
      holdingMonths:'0',
      holdingMonthsAsNumber:0,
      numberBedrooms:'-',
      numberBathrooms:'-',
      yearBuilt:'0',
      showPropertyLookup:false,
      selectedLookupTabIndex:0,
      lookupState:'_',
      showAnalyzePropertyPrompt:false,
      similarSoldProperties:[],
      autoCalculateMonthlyExpenses:true, 
      autoCalculateInitialExpenses:true,
      autoCalculateRehabCosts:true,
      autoCalculateYearCashFlows:true,
      autoCalculateSaleProceeds:true,
      autoCalculateSellingCosts:true,
      autoCalculateHoldingCosts:true 

    }, () => {

      this.HideShowInvestmentTypeFields(null,null,this.state.investmentType);

    });

  }

  HandleCurrencyEnterFocus(event) {

    //console.log(event.target.name);

    let key = event.target.name;

    if (key.endsWith("Column"))
    {
        key = key.replace("Column","");
    }

    if (this.state[key] =="0" || this.state[key] =="$0")
    {
  
      document.getElementById(event.target.name).value = "";
      
      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandleCurrencyExitFocus(event) {
   
    let key = event.target.name;


    const map = new Map();

    map.set('pricePerSqft', 'pricePerSqft');
    map.set('propertyPrice', 'propertyPrice');
    map.set('marketValue', 'marketValue');
    map.set('downPayment', 'downPayment');
    map.set('closingCosts', 'closingCosts');
    map.set('monthlyPayment', 'monthlyPayment');
    map.set('projectedSaleProceeds', 'projectedSaleProceeds');
    map.set('monthlyRent', 'monthlyRent');
    map.set('monthlyExpenses', 'monthlyExpenses');
    map.set('initialExpenses', 'initialExpenses');
    map.set('monthlyGrossIncome', 'monthlyGrossIncome');
    map.set('monthlyBills', 'monthlyBills');
    map.set('budget', 'budget');
    map.set('trendingPricePerSqft', 'trendingPricePerSqft');
    map.set('trendingMonthlyRent', 'trendingMonthlyRent');
    map.set('afterRepairValue', 'afterRepairValue');
    map.set('rehabCosts', 'rehabCosts');
    map.set('sellingCosts', 'sellingCosts');
    
    const mapPercent = new Map();

    mapPercent.set('interestRate', 'interestRate');
    mapPercent.set('rentRateOfGrowth', 'rentRateOfGrowth');
    mapPercent.set('expenseRateOfGrowth', 'expenseRateOfGrowth');
    mapPercent.set('vacancyRate', 'vacancyRate');
    
    if (map.has(key) || mapPercent.has(key) || key.toLowerCase().includes("cashflow"))
    {
        if (key.endsWith("Column"))
        {
            key = key.replace("Column","");
        }

        if (this.state[key] =="" || this.state[key] ==" ")
        {
      
          if (map.has(key) || key.toLowerCase().includes("cashflow"))
          {
          
            document.getElementById(event.target.name).value = "$0";  
          
          } else if (mapPercent.has(key))
          {
            
            document.getElementById(event.target.name).value = "0%";

          }
          
          
          var obj  = {}
          obj[key] = 0
          this.setState(obj);
        }
    }

  }

  HandlePercentEnterFocus(event) {

    if (this.state[event.target.name] =="0" || this.state[event.target.name] =="0%")
    {
      const key = event.target.name;
      const keyFormatted = key+"AsNumber";
      document.getElementById(key).value = "";

      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandlePercentExitFocus(event) {
   
    if (this.state[event.target.name] =="" || this.state[event.target.name] ==" ")
    {
      const key = event.target.name;
      document.getElementById(key).value = "0%";

      var obj  = {}
      obj[key] = 0
      this.setState(obj);
    }

  }


  ChangeCustomCurrencySliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    if (name.endsWith("InputCashFlowColumnAsNumber"))
    {
      name = name.replace("Input","");
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    //console.log("HERE- VALUE:");
    //console.log(value);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangeCurrencySliderValue(event);

  };


  ChangeCurrencySliderValue(event) {
   
    //console.log("ChangeCurrencySliderValue - event.target.name:"+event.target.name);
    //console.log("ChangeCurrencySliderValue - event.target.value:"+event.target.value);
    
    let key = event.target.name;

    console.log(key);

     //Cashflow column have Column at the name used when hiding/showing, remove it
     if (key.endsWith("CashFlowColumnAsNumber"))
     {
        key = key.replace("Column","");
     }
    
    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;
  
    console.log("key:");
    console.log(key);
    
    console.log("value:");
    console.log(value);

    var obj  = {}
    obj[key] = Number(value)
    this.setState(obj, () => {

      // Do anything after the state has changed   
    this.HandleAdditionalSpecificLogic(event); 
    });

    var obj  = {}
    obj[keyFormatted] = value
    this.setState(obj)
    
  }


  ChangeCustomPercentSliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangePercentSliderValue(event);
  }

  ChangePercentSliderValue(event) {
  
    var key = event.target.name;
    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;

    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
    this.HandleAdditionalSpecificLogic(event); 
  });

    var obj  = {}
    obj[keyFormatted] = value+"%"
    this.setState(obj)
    
  }


  ChangeCustomNumberSliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangeNumberSliderValue(event);
  }


  ChangeNumberSliderValue(event) {
  
    var key = event.target.name;
    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;

    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
     
  });

    var obj  = {}
    obj[keyFormatted] = Intl.NumberFormat().format(value);
    this.setState(obj, () => {
      // Do anything after the state has changed   
      if (event.target.name.startsWith("livingAreaSize"))
      {
          this.CalculateRehabCosts();
          this.CalculatePricePerSqft();
      }
    });
    
  }

  ChangeCustomYearSliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangeYearSliderValue(event);
  }

  
  ChangeYearSliderValue(event) {
  
    var key = event.target.name;
    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;

    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
     
  });

    var obj  = {}
    obj[keyFormatted] = parseInt(value);
    this.setState(obj)

}

HandleCloseValidationMessage(event,obj){

  //document.querySelector('#propertyNickname').focus();

  //document.body.click();

  this.setState({
    showValidationMessage: false
  });

}
    

  HandleSave(event,obj){
  
    if (this.state.profile != null && this.state.profile.type != null && this.state.profile.type === 'demo') //Can't save demo property
    {

      this.dialog.show({
        title: 'Demo Profile Limits',
        body: "Demo properties can't be saved, their purpose is to showcase the application features. Switch to the Default profile to create your own properties.",
        actions: [
          Dialog.OKAction(() => {
            //console.log('OK was clicked!')
            
          })
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
          
        }
      });

    } else 
    {

      const property = this.GetProperty();

      if (this.state.dbProperty != null) //It is an update, not an insert
      {
          property.alertId = this.state.dbProperty.alertId;
          property.createdBy = this.state.dbProperty.createdBy;
          property.createdDate = this.state.dbProperty.createdDate;
          property.greenIndicatorsFound = this.state.dbProperty.greenIndicatorsFound;
          property.greenIndicatorsRequired = this.state.dbProperty.greenIndicatorsRequired;
          property.id = this.state.dbProperty.id;
          property.uniquePropertyIdentifier = this.state.dbProperty.uniquePropertyIdentifier;

          if (this.state.dbProperty.image == null || this.state.dbProperty.image == '')
          {
            property.propertyImage = this.state.imagePreviewURL;
          }else
          {
            property.propertyImage = this.state.dbProperty.image;
          }
          
      } else //insert
      {
          property.id = null;
          property.alertId = null;
          property.createdBy = localStorage.getItem('SessionUserName'); //[LOGGED IN USER]
          property.createdDate = new Date().toISOString();
          property.releasedDate = property.createdDate;
          property.greenIndicatorsFound = 0; 
          property.greenIndicatorsRequired = null;
          property.uniquePropertyIdentifier = this.state.propertyLink;
          property.propertyImage = this.state.imagePreviewURL;
          property.queuedFlag = "N";

      }

      //TODO - set from user session
      //property.accountId = accountId;  Note: No need to set the account id since it is populated in the backend

      const passedValidation = this.ValidateProperty(property);

      if (passedValidation)
      {
        let indicators    = this.GetPropertyIndicators(property);
        indicators        = this.GetLightIndicators(indicators);
        
        property.indicators = indicators;
        property.greenIndicatorsFound = this.GetGreenIndicatorCount(indicators);
    
        //console.log(JSON.stringify(property));
    
        this.SaveProperty(property);
      }
    }
  }

  SaveProperty(property)
  {
    
    this.setState({
      uiSaving: true
    });

    if (this.state.dbProperty == null)  //Insert
    {
      
      authMiddleWare(this?.props?.history);
      const authToken = localStorage.getItem('AuthToken');
      const refreshToken = localStorage.getItem('RefreshToken');
      axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
      axios
        .post('/api/createProperty', property)
        .then((response) => {
          
          if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
          {
              localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          }

          console.log("Completed creating property");
  
          this.setState({
            uiSaving: false
           });

          this.closeModal();
          this.state.parentReference.loadProperties();

        })
        .catch((error) => {
       
          console.log(error);

          if (error && error.response && error.response.status && error.response.status === 403) {
            localStorage.setItem('SessionExpired',"Y");
            //window.location.reload();
            window.location.href = '/login';
          } else 
          {
            this.setState({
              uiSaving: false
           });
          }
          
        });

      } else  //Update 
      {
        const id = this.state.dbProperty.id;

        const payload = {
          id:id,
          property:property
        }
        
        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
        axios
        .post('/api/updateProperty', payload)
        .then((response) => {
          
          if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
          {
              localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          }

          this.setState({
            uiSaving: false
          });
         
          console.log("Completed updating property");
          this.closeModal();
          this.state.parentReference.loadProperties();

        })
        .catch((error) => {
          console.log(error);

          if (error && error.response && error.response.status && error.response.status === 403) {
            localStorage.setItem('SessionExpired',"Y");
            //window.location.reload();
            window.location.href = '/login';
          } else 
          {
            this.setState({
              uiSaving: false
           });

           alert("There was an error saving the property. Try again.");
          }
          
        });

      }


  }

  DecorateFieldAsRequired(fieldName)
  {
    try
    {
      const element = document.getElementById(fieldName); 
      element.classList.add("blinkingBorder");


      element.addEventListener('focus', (event) => {
        event.target.classList.remove("blinkingBorder");
      });

      //Add focus listener to incremental bar

      try
      {
        const element2 = document.getElementById(fieldName+"AsNumber");

        if (element2 != null && element2 != undefined)
        {
          element2.addEventListener('focus', (event) => {
            element.classList.remove("blinkingBorder");
          });
        }

      }catch(error2)
      {

      }

    } catch(error)
    {
      console.log(error);
    }
  }

  UndecorateFieldsAsRequired()
  {
    const fields = [];
    fields.push("address");
    fields.push("city");
    fields.push("stateDropdownSelect");
    fields.push("zipcode");
    fields.push("propertyPrice");
    fields.push("downPayment");
    fields.push("termDropdownSelect");
    fields.push("interestRate");
    fields.push("closingCosts");
    fields.push("monthlyPayment");
    fields.push("monthlyRent");
    fields.push("monthlyExpenses");
    fields.push("vacancyRate");
    fields.push("initialExpenses");
    fields.push("marketValue");
    fields.push("rentRateOfGrowth");
    fields.push("expenseRateOfGrowth");
    fields.push("projectedYearOfSaleDropdownSelect");
    fields.push("projectedSaleProceeds");
    fields.push("livingAreaSize");
    fields.push("monthlyBills");
    fields.push("monthlyGrossIncome");
    fields.push("creditScoreDropdownSelect");
    fields.push("trendingPricePerSqft");
    fields.push("trendingMonthlyRent");
    fields.push("holdingMonths");
    fields.push("afterRepairValue");
    fields.push("rehabCosts");
    fields.push("holdingCosts");
    fields.push("sellingCosts");
    fields.push("budget");

    //Validate cash flows
    try
    {
      if (this.state.projectedYearOfSale !='' && this.state.projectedYearOfSale !='_')
      {

        let failedYearCashflowCounter = 0;
        const projectedYearOfSale = parseInt(this.state.projectedYearOfSale.toLowerCase().replace("year","").trim());
        for (let x = 1; x <= projectedYearOfSale; x = x + 1) {

          try
          {
 
              const elementName = "year"+x+"InputCashFlowColumn";
        
              fields.push(elementName);

            } catch(error2)
            {
              console.log(error2);
            }

        }  // end loop
      }

    } catch(error)
    {
      console.log(error);
    }

    for (var i in fields) {

      const element = document.getElementById(fields[i]);
      try{
        if (element.classList != null) {
            element.classList.remove("blinkingBorder");
        }
      } catch(error)
      {
       // console.log(fields[i]);
        console.log(error);
      }
    }
  }

  ValidateProperty(property)
  {
    let passedValidation = true;

    this.state.validationErrors = [];

    try{

      this.UndecorateFieldsAsRequired();

      const errors = [];
      const financeErrors = [];

      if (property.address =='')
      {
        errors.push('Please enter the street address');

        passedValidation = false;

        this.DecorateFieldAsRequired("address");
      }

      if (property.city =='')
      {
        errors.push('Please enter the city');

        passedValidation = false;

        this.DecorateFieldAsRequired("city");
      }

      if (property.state =='_')
      {
        errors.push('Please enter the state');

        passedValidation = false;

        this.DecorateFieldAsRequired("stateDropdownSelect");
      }

      if (property.zipcode =='')
      {
        errors.push('Please enter the zipcode');

        passedValidation = false;

        this.DecorateFieldAsRequired("zipcode");
      }

      if (property.price ==0)
      {
        errors.push('Please enter the purchase price');

        passedValidation = false;

        this.DecorateFieldAsRequired("propertyPrice");
      }

      if (property.financingType=="Loan" && property.downPayment ==0)
      {
        financeErrors.push('Please enter the down payment');

        passedValidation = false;

        this.DecorateFieldAsRequired("downPayment");
      }

      if (property.financingType=="Loan" && (property.term =="_" || property.term ==""))
      {
        financeErrors.push('Please enter the loan term');

        passedValidation = false;

        this.DecorateFieldAsRequired("termDropdownSelect");
      }

      if (property.financingType=="Loan" && property.interestRate ==0)
      {
        financeErrors.push('Please enter the loan interest rate');

        passedValidation = false;

        this.DecorateFieldAsRequired("interestRate");
      }

      if (property.closingCosts ==0)
      {
        financeErrors.push('Please enter the closing costs');

        passedValidation = false;

        this.DecorateFieldAsRequired("closingCosts");
      }

      if (property.financingType=="Loan" && property.monthlyPayment ==0)
      {
        financeErrors.push('Please enter the monthly payment');

        passedValidation = false;

        this.DecorateFieldAsRequired("monthlyPayment");
      }

      
      //investment tyoe specific field validation
      if (property.investmentType == "Rental Property")
      {    
        const passed = this.ValidateRentalProperty(property,errors);

        if (passed == false)
        {
          passedValidation = false;
        }
      
      } else if (property.investmentType == "Flipping Property")
      {
        const passed = this.ValidateFlippingProperty(property,errors);

        if (passed == false)
        {
          passedValidation = false;
        }        
      
      } else if (property.investmentType == "Primary Home")
      {
        const passed = this.ValidatePrimaryProperty(property,errors);        

        if (passed == false)
        {
          passedValidation = false;
        }
      }
      

      if (financeErrors.length > 0)
      {
        this.state.validationErrors.unshift({key:"FINANCING",errors:financeErrors});
        document.querySelector('#financingPlus').innerHTML = "-";
        document.querySelector('#financingContent').style.display = "block";
      }

      if (errors.length > 0)
      {
        this.state.validationErrors.unshift({key:"GENERAL",errors:errors});
          
      }

      if (passedValidation == false)
      {
        
        this.setState({
          showValidationMessage: true
        });

      }

    }catch(error)
    {
      console.log(error);
    }

    return passedValidation;

  }

  ValidatePrimaryProperty(property, errors)
  {
    let passedValidation = true;

    const buyersFinancialStatusErrors = [];

    const areaCurrentTrendErrors = [];
    
    try{

      var message = "";

      if (property.livingAreaSize ==0)
      {
        errors.push('Please enter the living space');

        passedValidation = false;

        this.DecorateFieldAsRequired("livingAreaSize");
      }

      if (property.marketValue ==0)
      {
        errors.push('Please enter the market value');

        passedValidation = false;

        this.DecorateFieldAsRequired("marketValue");
      }

      if (property.monthlyBills ==0)
      {
        buyersFinancialStatusErrors.push('Please enter the monthly bills');

        passedValidation = false;

        this.DecorateFieldAsRequired("monthlyBills");
      }

      if (property.monthlyGrossIncome ==0)
      {
        buyersFinancialStatusErrors.push('Please enter the monthly gross income');

        passedValidation = false;

        this.DecorateFieldAsRequired("monthlyGrossIncome");
      }

      if (property.creditScore =="" || property.creditScore =="_")
      {
        buyersFinancialStatusErrors.push('Please enter the credit score');

        passedValidation = false;

        this.DecorateFieldAsRequired("creditScoreDropdownSelect");
      }

      if (property.trendingPricePerSqft ==0)
      {
        areaCurrentTrendErrors.push('Please enter the trending price per sqft');

        passedValidation = false;

        this.DecorateFieldAsRequired("trendingPricePerSqft");
      }
      if (property.trendingMonthlyRent ==0)
      {
        areaCurrentTrendErrors.push('Please enter the trending monthly rent');

        passedValidation = false;

        this.DecorateFieldAsRequired("trendingMonthlyRent");
      }

      if (buyersFinancialStatusErrors.length > 0)
      {
        this.state.validationErrors.push({key:"BUYER'S FINANCIAL STATUS",errors:buyersFinancialStatusErrors});
        document.querySelector('#buyersBudgetPlus').innerHTML = "-";
        document.querySelector('#buyersBudgetContent').style.display = "block";
      }
      
      if (areaCurrentTrendErrors.length > 0)
      {
        this.state.validationErrors.push({key:"AREA CURRENT TRENDS",errors:areaCurrentTrendErrors});
        document.querySelector('#areaTrendPlus').innerHTML = "-";
        document.querySelector('#areaTrendContent').style.display = "block";
      }

      
    }catch(error)
    {
      console.log(error);
    }

    return passedValidation;

  }


  ValidateRentalProperty(property,errors)
  {
    let passedValidation = true;

    const rentalErrors = [];

    const projectionsErrors = [];

    try{

      
      if (property.monthlyRent ==0)
      {
        rentalErrors.push('Please enter the monthly rent');

        passedValidation = false;

        this.DecorateFieldAsRequired("monthlyRent");
      }

      if (property.monthlyExpenses ==0)
      {
        rentalErrors.push('Please enter the monthly expenses');

        passedValidation = false;

        this.DecorateFieldAsRequired("monthlyExpenses");
      }
 
      if (property.vacancyRate == 0)
      {
        rentalErrors.push('Please enter the vacancy rate');

        passedValidation = false;

        this.DecorateFieldAsRequired("vacancyRate");
      }
 
      if (property.initialExpenses ==0)
      {
        rentalErrors.push('Please enter the initial expenses');

        passedValidation = false;

        this.DecorateFieldAsRequired("initialExpenses");
      }

      if (property.marketValue ==0)
      {
        errors.push('Please enter the market value');

        passedValidation = false;

        this.DecorateFieldAsRequired("marketValue");
      }

      if (property.rentRateOfGrowth ==0)
      {
        rentalErrors.push('Please enter the rent rate of growth');

        passedValidation = false;

        this.DecorateFieldAsRequired("rentRateOfGrowth");
      }

      if (property.expenseRateOfGrowth ==0)
      {
        rentalErrors.push('Please enter the expense rate of growth');

        passedValidation = false;

        this.DecorateFieldAsRequired("expenseRateOfGrowth");
      }

      if (property.projectedYearOfSale =='' || property.projectedYearOfSale =='_')
      {
        projectionsErrors.push('Please enter the projected year of sale');

        passedValidation = false;

        this.DecorateFieldAsRequired("projectedYearOfSaleDropdownSelect");
      }

      if (property.projectedSaleProceeds == 0)
      {
        projectionsErrors.push('Please enter the projected sale proceeds');

        passedValidation = false;

        this.DecorateFieldAsRequired("projectedSaleProceeds");
      }

      /*
      alert(property["year1CashFlow"]);
      if (property.year1CashFlow ==0)
      {
        projectionsErrors.push('Please enter projected cashflows');

        passedValidation = false;

        this.DecorateFieldAsRequired("year1CashFlowColumn");
      }
      */

     //Validate cash flows
     try
     {
      if (property.projectedYearOfSale !='' && property.projectedYearOfSale !='_')
      {

        let failedYearCashflowCounter = 0;
        const projectedYearOfSale = parseInt(this.state.projectedYearOfSale.toLowerCase().replace("year","").trim());
        for (let x = 1; x <= projectedYearOfSale; x = x + 1) {

          try
          {
              const fieldName = "year"+x+"CashFlow";

              const elementName = "year"+x+"InputCashFlowColumn";
        
              if (property[fieldName] ==0)
              {
              
                failedYearCashflowCounter = failedYearCashflowCounter + 1;

                this.DecorateFieldAsRequired(elementName);
              }
            } catch(error2)
            {
              console.log(error2);
            }

        }  // end loop

        if (failedYearCashflowCounter > 0)
        {
            projectionsErrors.push('Please enter projected cashflows');

            passedValidation = false;
        }

      }

     }catch(error)
     {
      console.log(error);
     }

  
      if (rentalErrors.length > 0)
      {
        this.state.validationErrors.push({key:"RENTAL INFORMATION",errors:rentalErrors});
        document.querySelector('#rentalPlus').innerHTML = "-";
        document.querySelector('#rentalContent').style.display = "block";
      }
      
      if (projectionsErrors.length > 0)
      {
        this.state.validationErrors.push({key:"PROJECTIONS",errors:projectionsErrors});
        document.querySelector('#projectionsPlus').innerHTML = "-";
        document.querySelector('#projectionsContent').style.display = "block";
      }
      
    }catch(error)
    {
      console.log(error);
    }

    return passedValidation;

  }

  ValidateFlippingProperty(property, errors)
  {
    let passedValidation = true;

    try{

      const flippgingCostErrors = [];
      const buyersBudgetErrors = [];

      if (property.holdingMonths ==0)
      {
        errors.push('Please enter the humber of holding months');

        passedValidation = false;

        this.DecorateFieldAsRequired("holdingMonths");
      }

      if (property.afterRepairValue ==0)
      {
        errors.push('Please enter the after repair value');

        passedValidation = false;

        this.DecorateFieldAsRequired("afterRepairValue");
      }

  
      if (property.rehabCosts ==0)
      {
        flippgingCostErrors.push('Please enter the rehab costs');

        passedValidation = false;

        this.DecorateFieldAsRequired("rehabCosts");
      }

      if (property.holdingCosts ==0)
      {
        flippgingCostErrors.push('Please enter the holding costs');

        passedValidation = false;

        this.DecorateFieldAsRequired("holdingCosts");
      }

      if (property.sellingCosts == 0)
      {
        flippgingCostErrors.push('Please enter the selling costs');

        passedValidation = false;

        this.DecorateFieldAsRequired("sellingCosts");
      }

      if (property.budget =='')
      {
        buyersBudgetErrors.push('Please enter the budget');

        passedValidation = false;

        this.DecorateFieldAsRequired("budget");
      }

      if (flippgingCostErrors.length > 0)
      {
        this.state.validationErrors.push({key:"FLIPPING COSTS",errors:flippgingCostErrors});
        document.querySelector('#flippingCostsPlus').innerHTML = "-";
        document.querySelector('#flippingCostsContent').style.display = "block";
      }

      if (buyersBudgetErrors.length > 0)
      {
        this.state.validationErrors.push({key:"BUYER'S BUDGET",errors:buyersBudgetErrors});
        document.querySelector('#buyersBudgetPlus').innerHTML = "-";
        document.querySelector('#buyersBudgetContent').style.display = "block";
      }

    }catch(error)
    {
      console.log(error);
    }

    return passedValidation;

  }


  GetPropertyIndicators(property)
  {
    let indicators = null;

    if (property.investmentType == "Rental Property")
    {
      indicators = this.GetRentalIndicators(property);          
    
    } else if (property.investmentType == "Flipping Property")
    {
      indicators = this.GetFlippingIndicators(property);          
    
    } else if (property.investmentType == "Primary Home")
    {
      indicators = this.GetPrimaryHomeIndicators(property);          
    }

    return indicators;
  }

  GetLightIndicators(indicators)
  {
    const lightIndicators = [];

    for (const indicator of indicators)
    {
      const lightIndicator = {};
      lightIndicator.indicatorAcronym = indicator.indicatorAcronym;
      lightIndicator.indicatorName = indicator.indicatorName;
      lightIndicator.indicatorValue = indicator.indicatorValue;
      lightIndicator.meetsBenchmark = indicator.meetsBenchmark;
      lightIndicator.valueType = indicator.valueType;

      lightIndicators.push(lightIndicator);
    }

    return lightIndicators;
  }

  GetGreenIndicatorCount(indicators)
  {
    let greenIndicatorCount = 0;
    for (const indicator of indicators)
    {
      if (indicator.meetsBenchmark)
      {
        greenIndicatorCount = greenIndicatorCount + 1;
      }
    }

    return greenIndicatorCount;
  }

  GetRentalIndicators(property)
  {
    //const settings = Settings.GetProfileBenchmarkingSettings();
    const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.state.settings))); // clone it since need budget based on the profile value and trending price per sqft
    const profile = this.state?.profile;
  
    //Needs to come from profile
    settings.budgetAccepted=profile?.budget;   
    settings.budgetRejected=profile?.budget;   
    settings.budgetAcceptedCompareId=5;
    settings.budgetRejectedCompareId=1;

    //Needs to come from property
    settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
    settings.pricePerSizeRejected = property.trendingPricePerSqft;
    settings.pricePerSizeAcceptedCompareId =  5;
    settings.pricePerSizeRejectedCompareId =  1;

    const grmIndicator = BLO.getGRMIndicator(property,settings);
    const capIndicator = BLO.getCapRateIndicator(property,settings);
    const cocIndicator = BLO.getCashOnCashIndicator(property,settings);

    const piIndicator = BLO.getProfitabilityIndexIndicator(property,settings);
    const irrIndicator = BLO.getInternalRateOfReturnIndicator(property,settings);
    const dcrIndicator = BLO.getDebtCoverageRatioRentalIndicator(property,settings);

    const berIndicator = BLO.getBreakEvenRatioIndicator(property,settings);
    const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
    const ncfIndicator = BLO.getNetCashFlowIndicator(property,settings);

    const indicators = [];
    indicators.push(grmIndicator);
    indicators.push(capIndicator);
    indicators.push(cocIndicator);

    indicators.push(piIndicator);
    indicators.push(irrIndicator);
    indicators.push(dcrIndicator);

    indicators.push(berIndicator);
    indicators.push(ltvIndicator);
    indicators.push(ncfIndicator);

    //console.log(JSON.stringify(indicators));

    return indicators;
  }

  GetBenchmarkSettings(settings)
  {
    if (settings == null)
    {
      return BLO.getIndicatorBenchmarkSettings(settings);
    }else
    {
      return settings.benchmarkSettings;
    }
  }

  GetFlippingIndicators(property)
  {
    //const settings = Settings.GetProfileBenchmarkingSettings();
    const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.state.settings))); // clone it since need budget based on the profile value and trending price per sqft
    const profile = this.state?.profile;
	
    //Needs to come from profile
    settings.budgetAccepted=profile?.budget;   
    settings.budgetRejected=profile?.budget;   
    settings.budgetAcceptedCompareId=5;
    settings.budgetRejectedCompareId=1;

    //Needs to come from property
    settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
    settings.pricePerSizeRejected = property.trendingPricePerSqft;
    settings.pricePerSizeAcceptedCompareId =  5;
    settings.pricePerSizeRejectedCompareId =  1;

    const seventyPercentIndicator = BLO.get70PercentRuleIndicator(property,settings);
    const keyRiskIndicator = BLO.getKeyRiskIndicator(property,settings);    
    const dcrIndicator = BLO.getDebtCoverageRatioFlipIndicator(property,settings);

    const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
    const roiIndicator = BLO.getROIIndicator(property,settings);
    const netProfitIndicator = BLO.getNetProfitIndicator(property,settings);

    const marketValueIndicator = BLO.getMarketValuePercentIndicator(property,settings);
    const pricePerSqFtIndicator = BLO.getPricePerSizeIndicator(property,settings);
    const budgetIndicator = BLO.getBudgetIndicator(property,settings);

    const indicators = [];
    indicators.push(seventyPercentIndicator);
    indicators.push(keyRiskIndicator);
    indicators.push(dcrIndicator);

    indicators.push(ltvIndicator);
    indicators.push(roiIndicator);
    indicators.push(netProfitIndicator);
    
    indicators.push(marketValueIndicator);
    indicators.push(pricePerSqFtIndicator);
    indicators.push(budgetIndicator);
    
    //console.log(JSON.stringify(indicators));

    return indicators;
  }


  GetPrimaryHomeIndicators(property)
  {
    //const settings = Settings.GetProfileBenchmarkingSettings();
    const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.state.settings))); // clone it since need budget based on the profile value and trending price per sqft
    const profile = this.state?.profile;
	
    //Needs to come from profile
    settings.budgetAccepted=profile?.budget;   
    settings.budgetRejected=profile?.budget;   
    settings.budgetAcceptedCompareId=5;
    settings.budgetRejectedCompareId=1;

    //Needs to come from property
    settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
    settings.pricePerSizeRejected = property.trendingPricePerSqft;
    settings.pricePerSizeAcceptedCompareId =  5;
    settings.pricePerSizeRejectedCompareId =  1;

    const backEndRatioIndicator = BLO.getBackEndRatioIndicator(property,settings);
    const frontEndRatioIndicator = BLO.getFrontEndRatioIndicator(property,settings);    
    const dcrIndicator = BLO.getDebtCoverageRatioHomeIndicator(property,settings);

    const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
    const costOfDebtIndicator = BLO.getCostOfDebtIndicator(property,settings);
    const marketValueIndicator = BLO.getMarketValuePercentIndicator(property,settings);
        
    const pricePerSqFtIndicator = BLO.getPricePerSizeIndicator(property,settings);
    const priceToRentIndicator = BLO.getPriceToRentRatioIndicator(property,settings);
    const budgetIndicator = BLO.getBudgetHomeIndicator(property,settings);

    //const creditScoreIndicator = BLO.getCreditScoreIndicator(property,settings);

    const indicators = [];
    indicators.push(backEndRatioIndicator);
    indicators.push(frontEndRatioIndicator);
    indicators.push(dcrIndicator);

    indicators.push(ltvIndicator);
    indicators.push(costOfDebtIndicator);
    indicators.push(marketValueIndicator);
    
    indicators.push(pricePerSqFtIndicator);
    indicators.push(priceToRentIndicator);
    indicators.push(budgetIndicator);

    //indicators.push(creditScoreIndicator);
    
    //console.log(JSON.stringify(indicators));

    return indicators;

  }

  AnalyzeProperty(event,obj){
  
    event.preventDefault();

    const property = this.GetProperty();

    var dialog = this.analysisDialogRef.current;
    dialog.PopulateDialog(property,this.state.settings,this.state.profileId,this.state.profile);
  }


  GetProperty(){

    const property = {
      "profileId": this.state.profileId,
      "nickName" : this.state.propertyNickname,
      "price": this.state.propertyPriceAsNumber,
      "marketValue" : this.state.marketValueAsNumber,
      "marketValuePercent": this.state.marketValuePercent,
      "marketValuePercentAboveBelow" : this.state.marketValuePercentAboveBelow,
      "downPayment" : this.state.downPaymentAsNumber,
      "downPaymentPercent": this.state.downPaymentPercent,
      "closingCosts" : this.state.closingCostsAsNumber,
      "interestRate" : this.state.interestRateAsNumber,
      "annualAppreciation" : this.state.annualAppreciation,
      "rentRateOfGrowth" : this.state.rentRateOfGrowthAsNumber,
      "expenseRateOfGrowth" : this.state.expenseRateOfGrowthAsNumber,
      "vacancyRate" : this.state.vacancyRateAsNumber,
      "monthlyPayment" : this.state.monthlyPaymentAsNumber,
      "monthlyRent" : this.state.monthlyRentAsNumber,
      "monthlyBills" : this.state.monthlyBillsAsNumber,
      "monthlyGrossIncome": this.state.monthlyGrossIncomeAsNumber,
      "creditScore" : this.state.creditScore,
      "monthlyExpenses" : this.state.monthlyExpensesAsNumber,
      "initialExpenses" : this.state.initialExpensesAsNumber,
      "projectedSaleProceeds" : this.state.projectedSaleProceedsAsNumber,
      "trendingMonthlyRent" : this.state.trendingMonthlyRentAsNumber,
      "pricePerSqft" : this.state.pricePerSqftAsNumber,
      "trendingPricePerSqft" : this.state.trendingPricePerSqftAsNumber,
      "year1CashFlow" : this.state.year1CashFlowAsNumber,
      "year2CashFlow" : this.state.year2CashFlowAsNumber,
      "year3CashFlow" : this.state.year3CashFlowAsNumber,
      "year4CashFlow" : this.state.year4CashFlowAsNumber,
      "year5CashFlow" : this.state.year5CashFlowAsNumber,
      "year6CashFlow" : this.state.year6CashFlowAsNumber,
      "year7CashFlow" : this.state.year7CashFlowAsNumber,
      "year8CashFlow" : this.state.year8CashFlowAsNumber,
      "year9CashFlow" : this.state.year9CashFlowAsNumber,
      "year10CashFlow" : this.state.year10CashFlowAsNumber,
      "year11CashFlow" : this.state.year11CashFlowAsNumber,
      "year12CashFlow" : this.state.year12CashFlowAsNumber,
      "year13CashFlow" : this.state.year13CashFlowAsNumber,
      "year14CashFlow" : this.state.year14CashFlowAsNumber,
      "year15CashFlow" : this.state.year15CashFlowAsNumber,
      "year16CashFlow" : this.state.year16CashFlowAsNumber,
      "year17CashFlow" : this.state.year17CashFlowAsNumber,
      "year18CashFlow" : this.state.year18CashFlowAsNumber,
      "year19CashFlow" : this.state.year19CashFlowAsNumber,
      "year20CashFlow" : this.state.year20CashFlowAsNumber,
      "year21CashFlow" : this.state.year21CashFlowAsNumber,
      "year22CashFlow" : this.state.year22CashFlowAsNumber,
      "year23CashFlow" : this.state.year23CashFlowAsNumber,
      "year24CashFlow" : this.state.year24CashFlowAsNumber,
      "year25CashFlow" : this.state.year25CashFlowAsNumber,
      "year26CashFlow" : this.state.year26CashFlowAsNumber,
      "year27CashFlow" : this.state.year27CashFlowAsNumber,
      "year28CashFlow" : this.state.year28CashFlowAsNumber,
      "year29CashFlow" : this.state.year29CashFlowAsNumber,
      "year30CashFlow" : this.state.year30CashFlowAsNumber,
      "propertyCashFlowMaps": this.state.propertyCashFlowMaps,
      "mls" : this.state.mls,
      "propertyLink" : this.state.propertyLink,
      "address" : this.state.address,
      "city" : this.state.city,
      "state" : this.state.stateCode,
      "zipcode" : this.state.zipcode,
      "country" : this.state.country,
      "investmentType" : this.state.investmentType,
      "propertyType" : this.state.propertyType,
      "propertySubType" : this.state.propertySubType,
      "financingType" : this.state.financingType,
      "term" : this.state.termAsNumber,
      "loanType" : this.state.loanType,
      "repaymentType" : this.state.repaymentType,
      "projectedYearOfSale" : this.state.projectedYearOfSale,
      "propertyImage" : this.state.propertyImage,
      "propertyNotes" : this.state.propertyNotes,
      "principalAndInterest" : this.state.principalAndInterestAsNumber,
      "propertyTaxes" : this.state.propertyTaxesAsNumber,
      "homeownersInsurance" : this.state.homeownersInsuranceAsNumber,
      "otherMonthlyPayment" : this.state.otherMonthlyPaymentAsNumber,
      "hoa" : this.state.hoaAsNumber,
      "otherMonthlyBill" : this.state.otherMonthlyBillAsNumber,
      "autoInsurance" : this.state.autoInsuranceAsNumber,
      "cable" : this.state.cableAsNumber,
      "carMaintenance" : this.state.carMaintenanceAsNumber,
      "carPayment" : this.state.carPaymentAsNumber,
      "cellPhone" : this.state.cellPhoneAsNumber,
      "charitableDonations" : this.state.charitableDonationsAsNumber,
      "childCare" : this.state.childCareAsNumber,
      "childSupport" : this.state.childSupportAsNumber,
      "clothing" : this.state.clothingAsNumber,
      "creditCard" : this.state.creditCardAsNumber,
      "dining" : this.state.diningAsNumber,
      "entertainment" : this.state.entertainmentAsNumber,
      "gasoline" : this.state.gasolineAsNumber,
      "electric" : this.state.electricAsNumber,
      "groceries" : this.state.groceriesAsNumber,
      "insuranceHealthMedical" : this.state.insuranceHealthMedicalAsNumber,
      "internet" : this.state.internetAsNumber,
      "lifeInsurance" : this.state.lifeInsuranceAsNumber,
      "medicalDentalVision" : this.state.medicalDentalVisionAsNumber,
      "phone" : this.state.phoneAsNumber,
      "recreationVacation" : this.state.recreationVacationAsNumber,
      "studentLoan" : this.state.studentLoanAsNumber,
      "tuition" : this.state.tuitionAsNumber,
      "water" : this.state.waterAsNumber,
      "rentalMaintenance" : this.state.rentalMaintenanceAsNumber,
      "rentalMaintenancePercent" : this.PercentFormatToNumber(this.state.rentalMaintenancePercent),
      "rentalUtilities" : this.state.rentalUtilitiesAsNumber,
      "rentalPropertyManagement" : this.state.rentalPropertyManagementAsNumber,
      "rentalPropertyManagementPercent" : this.PercentFormatToNumber(this.state.rentalPropertyManagementPercent),
      "rentalBookkeeping" : this.state.rentalBookkeepingAsNumber,
      "rentalOtherExpenses" : this.state.rentalOtherExpensesAsNumber,
      "rentalTaxFiling" : this.state.rentalTaxFilingAsNumber,
      "rentalAppraisalFees" : this.state.rentalAppraisalFeesAsNumber,
      "rentalHomeInspection" : this.state.rentalHomeInspectionAsNumber,
      "rentalTenantScreening" : this.state.rentalTenantScreeningAsNumber,
      "rentalMarketing" : this.state.rentalMarketingAsNumber,
      "rentalOtherInitialExpenses" : this.state.rentalOtherInitialExpensesAsNumber,
      "flippingMaintenance" : this.state.flippingMaintenanceAsNumber,
      "flippingUtilities" : this.state.flippingUtilitiesAsNumber,
      "flippingOtherExpenses" : this.state.flippingOtherExpensesAsNumber,
      "livingAreaSize" : this.state.livingAreaSizeAsNumber,
      "lotSize" : this.state.lotSizeAsNumber,
      "afterRepairValue": this.state.afterRepairValueAsNumber,
      "sellingCosts" : this.state.sellingCostsAsNumber,
      "sellingCostsPercent" : this.state.sellingCostsPercent,
      "holdingCosts" : this.state.holdingCostsAsNumber,
      "rehabCosts" : this.state.rehabCostsAsNumber,
      "rehabType" : this.state.rehabType,
      "budget" : this.state.budgetAsNumber,
      "holdingMonths" : this.state.holdingMonthsAsNumber,
      "numberBedrooms" : this.state.numberBedrooms,
      "numberBathrooms" : this.state.numberBathrooms,
      "yearBuilt" : this.state.yearBuilt,
      "similarSoldProperties": this.state.similarSoldProperties

    };

    //alert(JSON.stringify(property));
  
    return property;
  }


  HandleShowPropertyLookup(event,obj){

    event.preventDefault();

    this.ResetMessageBoxValues();

    this.setState({
      showPropertyLookup: true,
      lookUpCounter:0

    }, () => {
      if (document.getElementById("progressTimer") != null)
      {
        document.getElementById("progressTimer").style.display = "none";
      }
    });

  }

  HandleClosePropertyLookup(event,obj){

    //document.querySelector('#propertyNickname').focus();

    //document.body.click();

    this.setState({
      showPropertyLookup: false
    });
  

  }

  ResetMessageBoxValues()
  {

    this.setState({
        lookUpTrendingRentCounter:0,
		    loadingTrendingRent: false,
        trendingRentInterval:null,
        
        lookUpPricePerSqftCounter:0,
		    loadingPricePerSqft: false,
        pricePerSqftInterval:null,
        
        lookUpVancacyRateCounter:0,
		    loadingVancacyRate: false,
        vancacyRateInterval:null,
        
        lookUpClosingCostsCounter:0,
        loadingClosingCosts: false,
        closingCostsInterval:null,

        lookUpInflationRateCounter:0,
		    loadingInflationRate: false,
        inflationRateInterval:null,

        lookUpRateOfGrowthCounter:0,
		    loadingRentRateOfGrowth: false,
        rentRateOfGrowthInterval:null,

        lookUpTrendingPriceInAreaCounter:0,
		    loadingTrendingPriceInArea: false,
        trendingPriceInAreaInterval:null,

        showMessageBox:false,
        showProcessMessage: false,
        showSuccessMessage: false,

        trendingRentResultSuccess:true,
        trendingRentResultFailure:false,

        vancacyRateResultSuccess:true,
        vancacyRateResultFailure:false,

        rentRateOfGrowthResultSuccess:true,
        rentRateOfGrowthResultFailure:false,

        inflationRateResultSuccess:true,
        inflationRateResultFailure:false,

        closingCostsResultSuccess:true,
        closingCostsResultFailure:false,

        pricePerSqftResultSuccess:true,
        pricePerSqftResultFailure:false,

        trendingPriceInAreaResultSuccess:true,
        trendingPriceInAreaResultFailure:false,

        messageBoxBottomTextSuccess:true,
        messageBoxBottomTextFailure:false

      });
  }
  HandlePropertyLookupButton(event,obj){

      switch(this.state.selectedLookupTabIndex) {
        case 0: //By Address

            this.HandlePropertyLookupByAddress(event,obj);
            break;
        case 1: //By MLSID

            this.HandlePropertyLookupByMLS(event,obj);
            break;
        case 2: //By url
     
            this.HandlePropertyLookupByURL(event,obj);
            break;

        default:
          // code block
      }

      this.StartCountUp();
  
  };

  
  HandleCloseAnalyzePropertyPrompt(event,obj){

    this.setState({
      showAnalyzePropertyPrompt: false
    });
  
  }

  HandleAnalyzePropertyPrompt(event,obj){
    this.setState({
      showAnalyzePropertyPrompt: false
    });
  }

  HandlePropertyLookupByURL(event,obj)
  {

    this.setState({
      loading: true
    });

    const url = document.querySelector('#lookupURL').value;

    if (url =="")
    {
      alert("Please enter a URL");
      this.setState({
        loading: false
       });
       
      return;
    }

    const payload = {
      url: url
    };

    const options = {
      url: '/api/lookupPropertyByURL',
      method: 'post',
      data: payload
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options)
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      //alert(JSON.stringify(response));

      //console.log(JSON.stringify(response));

      this.PopulatePropertyFields(response);

      if (document.getElementById("lookupHelp") != null)
      {
      document.getElementById("lookupHelp").style.display = "none";
      }
    })
    .catch((err) => {
      //alert(err);
      this.HandlePropertyLookupError(err);
    });
  }

  HandlePropertyLookupByMLS(event,obj)
  {

    if (document.querySelector('#lookupMLSID') != null)
    {
        this.setState({
          loading: true
        });

        const mls = document.querySelector('#lookupMLSID').value;

        const payload = {
          mls: mls
        };

        if (mls == "")
        {
          alert("Please enter a MLS#");
          this.setState({
            loading: false
          });
          
          return;
        }

        const options = {
          url: '/api/lookupPropertyByMLSID',
          method: 'post',
          data: payload
        };

        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
        axios(options)
        .then((response) => {

          if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
          {
              localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          }

          // alert(JSON.stringify(response));
          // console.log(JSON.stringify(response));

          this.PopulatePropertyFields(response);

          if (document.getElementById("lookupHelp") != null)
          {
          document.getElementById("lookupHelp").style.display = "none";
          }

        })
        .catch((err) => {
          //alert(err);
          this.HandlePropertyLookupError(err);
        });
      }
  }

  HandlePropertyLookupByAddress(event,obj)
  {

    this.setState({
      loading: true
    });

    const street = document.querySelector('#lookupAddress').value;
    const city = document.querySelector('#lookupCity').value;
    const state = this.state.lookupState;
    const zip = document.querySelector('#lookupZipcode').value;

    if (street == "" || state =="")
    {
      alert("Please enter a valid address");
      this.setState({
        loading: false
       });

      return;
    }

    const payload = {
      street: street,
      city: city,
      state: state,
      zip : zip
    };

    const options = {
      url: '/api/lookupPropertyByAddress',
      method: 'post',
      data: payload
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options)
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
			    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      //alert(JSON.stringify(response));

      //console.log(JSON.stringify(response));

      console.log("Found property...");

      const propertyString = JSON.stringify(response.data).trim();
      //console.log("propertyString:"+propertyString);

      if (response.data != null && propertyString !="")
      {
          console.log("About to call PopulatePropertyFields");
          
          this.PopulatePropertyFields(response);

          if (document.getElementById("lookupHelp") != null)
          {
          document.getElementById("lookupHelp").style.display = "none";
          }
      } else
      {
        this.HandlePropertyLookupError("Came back empty")
      }
 
    })
    .catch((err) => {
      //alert(err);
      this.HandlePropertyLookupError(err);
    });
  }

  HandlePropertyLookupError(err)
  {
    alert("The property couldn't be found");

    try
    {
      console.log(err);

      this.setState({
          //showPropertyLookup: false,
          loading: false
      });

      this.StopCountUp();
    } catch(error)
    {
      console.log("HandlePropertyLookupError-Error:"+error);
    }
  }

  StartCountUp(){

      //The initial starting point of the counter is 0
//      var counter = 0;

      this.setState({
        lookUpCounter:0
      }, () => {

        document.getElementById("progressTimer").style.display = "";
        
      });
      
  
      const thisReference = this;

      var interval = setInterval(function(){

        try
        {

          let counter = thisReference.state.lookUpCounter + 1;

          thisReference.setState({
            lookUpCounter:counter
          }, () => {
            try
            {
              if( thisReference.state.lookUpCounter == 80 ){
         
                thisReference.setState({
                  lookUpCounter : 0,
                  loading : false
                });

                clearInterval(interval);
                document.getElementById("progressTimer").style.display = "none";
              }
            } catch(error)
            {
              console.log(error);
            }
          
          });


        } catch(error)
        {
          console.log(error);
        } 
        
      }, 1000);
    
  }

  StopCountUp(){
    if (document.getElementById("progressTimer") != null)
    {
      document.getElementById("progressTimer").style.display = "none";
    }

  }

  
  NormalizePropertyType(type,street)
  {
      console.log("Property Type:");
      console.log(type);

      try
      {
          if (type.toLowerCase().includes("manufactur") || type.toLowerCase().includes("mobile"))
          {
            type = "Mobile Home";

          } else if (type.toLowerCase().includes("apartment") || type.toLowerCase().includes("multi family") || type.toLowerCase().includes("duplex") || type.toLowerCase().includes("triplex") || type.toLowerCase().includes("fourplex"))
          {
            type = "Multi-family";

          } else if (street.toLowerCase().includes(" unit ") || street.toLowerCase().includes(" apt "))
          {
            type = "Condo";

          } else if (type.toLowerCase().includes("typehome") || type.toLowerCase().includes("singlefamily") || type.toLowerCase().includes("single family"))
          {
            type = "Single Family Home";

          } else if (type.toLowerCase().includes("lots") || type.toLowerCase().includes("land"))
          {
            type = "Lot";
          }
      } catch(error)
      {

      }  

      if (type == null || type =="")
      {
        type = "Single Family Home";
      }


      return type;
  }

  PopulatePropertyFields(response)
  {

    //console.log(JSON.stringify(response.data));

    try
    {
      const lotSize = this.FixLotSize(response.data.lotSize);

      const lotSizeFormatted = this.FormatNumber(lotSize);

      let rent = response.data.rent;

      if (rent =='')
      {
        rent = '$0';
      }
      
      this.setState({
        address: response.data.street,
        city : response.data.city,
        zipcode : response.data.zip,
        mls : response.data.mls,
        propertyLink : response.data.propertyLink,
        propertyType : this.NormalizePropertyType(response.data.propertyType,response.data.street),
        propertySubType: response.data.propertySubType,
        propertyPrice : response.data.price,
        propertyPriceAsNumber : this.ConvertFormmattedNumberToNumber(response.data.price),
        marketValue : response.data.estimate,
        marketValueAsNumber : this.ConvertFormmattedNumberToNumber(response.data.estimate),
        downPayment : response.data.downPayment,
        downPaymentAsNumber : this.ConvertFormmattedNumberToNumber(response.data.downPayment),
        downPaymentPercent : this.FixPercentValue(response.data.downPaymentPercent),
        interestRate : this.FixPercentValue(response.data.interestRate),
        interestRateAsNumber : this.RemovePercentValue(response.data.interestRate),

        livingAreaSize : response.data.livingArea,
        livingAreaSizeAsNumber: this.ConvertFormmattedNumberToNumber(response.data.livingArea),

        lotSizeAsNumber : lotSize,
        lotSize:  lotSizeFormatted,

        stateCode : response.data.state,
        
        numberBedrooms: response.data.numberBedRooms,
        numberBathrooms: response.data.numberBathRooms,

        yearBuilt: response.data.yearBuilt,

        pricePerSqft : response.data.pricePerSqft,
        pricePerSqftAsNumber : this.ConvertFormmattedNumberToNumber(response.data.pricePerSqft),

        term: this.ParseTerm(response.data.loanTerm),

        principalAndInterest: response.data.principalAndInterest,
        principalAndInterestAsNumber : this.ConvertFormmattedNumberToNumber(response.data.principalAndInterest),

        propertyTaxes : response.data.propertyTaxes,
        propertyTaxesAsNumber : this.ConvertFormmattedNumberToNumber(response.data.propertyTaxes),

        homeownersInsurance : response.data.homeInsurance,
        homeownersInsuranceAsNumber : this.ConvertFormmattedNumberToNumber(response.data.homeInsurance),

        hoa : response.data.hoa,
        hoaAsNumber : this.ConvertFormmattedNumberToNumber(response.data.hoa),

        holdingMonths:this.FormatNumber(this.state.settings.holdingMonths),
        holdingMonthsAsNumber:this.state.settings.holdingMonths,

        monthlyPayment : response.data.monthlyPayment,
        monthlyPaymentAsNumber : this.ConvertFormmattedNumberToNumber(response.data.monthlyPayment),

        monthlyRent : rent,
        monthlyRentAsNumber : this.ConvertFormmattedNumberToNumber(rent),

        trendingMonthlyRent : rent,
        trendingMonthlyRentAsNumber : this.ConvertFormmattedNumberToNumber(rent),

        projectedYearOfSale : "Year 10",

        imagePreviewURL: response.data.image,

        showPropertyLookup: false,
        loading: false

      }, () => {

        if (response.data.image != "")
        {
          document.querySelector('#propertyImage').style.display = "block";
        }else
        {
          document.querySelector('#propertyImage').style.display = "none"; 
        }

        if (this.state.term =="")
        {
          this.setState({term: '30 Years'});

        }

        this.setState({
          showMessageBox: true,
          showProcessMessage: true
        });

        this.ConvertTermAsNumber(this.state.term);

        this.CalculateMarketValuePercent();

        this.LookUpItems();
      
        this.CalculateMonthlyExpenses();

        this.CalculateInitialExpenses();

        this.CalculateRehabCosts();

        this.CalculateSellingCosts();

        this.CalculateHoldingCosts();

        //Force recalculate projected years of sale
        this.ForceCalculateFutureCashFlows();

        //After it all done check to see if we are ready to analyze
        //this.CheckAllRequiredFieldsAvaialbleForAnalysis();

      });

      //Display property link button
      if (response.data.propertyLink != '' && response.data.propertyLink.toLowerCase().startsWith("http"))
      {
        document.querySelector('#propertyDetailLink').style.visibility = "visible";

      } else
      {
        document.querySelector('#propertyDetailLink').style.visibility = "hidden  ";
      }
    } catch(error)
    {

    }
  }

  LookUpItems()
  {
    /* 
        NOTE: We need to lookup all the additional items, but I want to change the 
              other of the lookup based on the investment type even though they 
              run asynchronously, this makes sense for resource utilization purposes.

        this.LookupTrendingRent();

        this.LookupTrendingPricePerSqft();

        this.LookupClosingCosts();
        
        this.LookupInflationRate(); //CACHE:This can be cached monthly globally refreshed monthly. It is the same for all customers and properties

        this.LookupRentalVancacyRate(); //CACHE:This can be cached monthly globally (all states) refreshed monthly. It is the same for all customers and properties
  
        this.LookupRentRateOfGrowth();  

        this.LookupTrendingPriceInArea();

        Possible Optimizations:
          1) Price per SqFt: You can get Trending Price Per Sqft from this.LookupTrendingPriceInArea(); even though it uses sold properties, that's still good.
                              If it doesn't get anything the you can call this.LookupTrendingPricePerSqft();
          
          2) Inflation Rate: This can be cached monthly globally refreshed monthly. It is the same for all customers and properties.

          3) Vancacy Rate: This can be cached monthly globally (all states) refreshed monthly. It is the same for all customers and properties.
    */

        if (this.state.investmentType === "Primary Home")
        {
            this.LookupClosingCosts();          // REQUIRED (For this investment type)
            this.LookupTrendingRent();          // REQUIRED (For this investment type)
            
            if (this.state.marketValueAsNumber ==0 || this.state.marketValue=='' || this.state.marketValue=='$0')  
            {
              
              this.LookupTrendingPriceInArea();   // REQUIRED (For this investment type ONLY if marketValue was not populated)
            
              this.StartPricePerSqftProgress(); // We are going to go ahead and start the PricePerSqft Progress since we pigging backing into trending price to get trending price per sqft

            } else //Since the price in area gets you similar properties, we will derive the trending price per sqft from there
            {
              this.LookupTrendingPricePerSqft();  // REQUIRED (For this investment type) 
            }

            //this.LookupInflationRate(); 
            //this.LookupRentalVancacyRate(); 
            //this.LookupRentRateOfGrowth();  

        } else if (this.state.investmentType === "Rental Property")
        {
            this.LookupClosingCosts();        // REQUIRED (For this investment type)
            this.LookupInflationRate();       // REQUIRED (For this investment type)
            this.LookupRentalVancacyRate();   // REQUIRED (For this investment type)
            this.LookupRentRateOfGrowth();    // REQUIRED (For this investment type)

            if (this.state.marketValueAsNumber ==0 || this.state.marketValue=='' || this.state.marketValue=='$0')  
            {
              this.LookupTrendingPriceInArea(); // REQUIRED (For this investment type - ONLY if marketValue was not populated
            }

            if (this.state.monthlyRentAsNumber ==0 || this.state.monthlyRent=='' || this.state.monthlyRent=='$0')  
            {
              this.LookupTrendingRent();        // REQUIRED (For this investment type - ONLY if rent was not populated

              //**********************    I M P O R T A N T    ************************ */
              //NOTE: DO a https://www.rentometer.com/ implentation too

            }
            
            //this.LookupTrendingPricePerSqft();

        } if (this.state.investmentType === "Flipping Property")
        {
            this.LookupClosingCosts();        // REQUIRED (For this investment type)
            this.LookupTrendingPriceInArea(); // REQUIRED (For this investment type)

            //this.LookupTrendingPricePerSqft();
            //this.LookupTrendingRent();
            //this.LookupInflationRate(); 
            //this.LookupRentalVancacyRate(); 
            //this.LookupRentRateOfGrowth(); 

        }



  }

  ForceCalculateFutureCashFlows()
  {
   
    this.setState({
      forceRecalculateFutureCashFlows : true
    }, () => {
      
      this.CalculateRentalSaleProceeds();
      this.CalculateFutureCashFlows();
      this.ShowFutureCashflowFields();

    });
  }

  ShowFutureCashflowFields()
  {
    try
    {
      if (this.state.investmentType == "Rental Property")
      {
        if (this.state.projectedYearOfSale != "_")
        {
          const projectedYearOfSale = parseInt(this.state.projectedYearOfSale.toLowerCase().replace("year","").trim());
          for (let x = 0; x <= projectedYearOfSale; x = x + 1) {
            let value = "Year "+x;

            //console.log("ShowFutureCashflowFields()-value"+value);

            this.HideOrShowProjectedYears(null,null,value);
          }
        }
      }

    }catch(error)
    {
      console.log(error);
    }
  }

  StartClosingCostsProgress(){

    this.setState({
      lookUpClosingCostsCounter:0,
      loadingClosingCosts : true
    }, () => {

    });
    

    const thisReference = this;

    var interval = setInterval(function(){

      try
      {

        let counter = thisReference.state.lookUpClosingCostsCounter + 1;

        thisReference.setState({
          lookUpClosingCostsCounter:counter
        }, () => {
          try
          {
            if( thisReference.state.lookUpClosingCostsCounter == 100 ){
       
              /*
              thisReference.setState({
                loadingClosingCosts : false
              }, () => {
                
                thisReference.setState({
                  lookUpClosingCostsCounter : 0
                });
      
              });
              */

              clearInterval(interval);
            }
          } catch(error)
          {
            console.log(error);
          }
        
        });


      } catch(error)
      {
        console.log(error);
      } 
      
    }, 1000);
  
    this.setState({
      closingCostsInterval : interval
    });
}

StopClosingCostsProgress(){
  
  if (this.state.closingCostsInterval != null)
  {
      clearInterval(this.state.closingCostsInterval);

      this.setState({
        lookUpClosingCostsCounter : 100
      }, () => {
        this.CheckProgressSuccessCompletion();
      });
      
    }

}

StartTrendingPriceInAreaProgress(){

  this.setState({
    lookUpTrendingPriceInAreaCounter:0,
    loadingTrendingPriceInArea : true
  }, () => {

  });
  

  const thisReference = this;

  var interval = setInterval(function(){

    try
    {

      let counter = thisReference.state.lookUpTrendingPriceInAreaCounter + 1;

      thisReference.setState({
        lookUpTrendingPriceInAreaCounter:counter
      }, () => {
        try
        {
          if( thisReference.state.lookUpTrendingPriceInAreaCounter == 100 ){
     
            /*
            thisReference.setState({
              loadingTrendingPriceInArea : false
            }, () => {
              
              thisReference.setState({
                lookUpTrendingPriceInAreaCounter : 0
              });
    
            });
            */

            clearInterval(interval);
          }
        } catch(error)
        {
          console.log(error);
        }
      
      });


    } catch(error)
    {
      console.log(error);
    } 
    
  }, 1000);

  this.setState({
    trendingPriceInAreaInterval : interval
  });
}

StopTrendingPriceInAreaProgress(){

if (this.state.trendingPriceInAreaInterval != null)
{
    clearInterval(this.state.trendingPriceInAreaInterval);

    this.setState({
      lookUpTrendingPriceInAreaCounter : 100
    }, () => {
      this.CheckProgressSuccessCompletion();
    });
  }

}


StartRentRateOfGrowthProgress(){

  this.setState({
    lookUpRentRateOfGrowthCounter:0,
    loadingRentRateOfGrowth : true
  }, () => {

  });
  

  const thisReference = this;

  var interval = setInterval(function(){

    try
    {

      let counter = thisReference.state.lookUpRentRateOfGrowthCounter + 1;

      thisReference.setState({
        lookUpRentRateOfGrowthCounter:counter
      }, () => {
        try
        {
          if( thisReference.state.lookUpRentRateOfGrowthCounter == 100 ){
     
            /*
            thisReference.setState({
              loadingRentRateOfGrowth : false
            }, () => {
              
              thisReference.setState({
                lookUpRentRateOfGrowthCounter : 0
              });
    
            });
            */

            clearInterval(interval);
          }
        } catch(error)
        {
          console.log(error);
        }
      
      });


    } catch(error)
    {
      console.log(error);
    } 
    
  }, 1000);

  this.setState({
    rentRateOfGrowthInterval : interval
  });
}

StopRentRateOfGrowthProgress(){

if (this.state.rentRateOfGrowthInterval != null)
{
    clearInterval(this.state.rentRateOfGrowthInterval);

    this.setState({
      lookUpRentRateOfGrowthCounter : 100
    }, () => {
      this.CheckProgressSuccessCompletion();
    });
  }

}



StartInflationRateProgress(){

  this.setState({
    lookUpInflationRateCounter:0,
    loadingInflationRate : true
  }, () => {

  });
  

  const thisReference = this;

  var interval = setInterval(function(){

    try
    {

      let counter = thisReference.state.lookUpInflationRateCounter + 1;

      thisReference.setState({
        lookUpInflationRateCounter:counter
      }, () => {
        try
        {
          if( thisReference.state.lookUpInflationRateCounter == 100 ){
     
            /*
            thisReference.setState({
              loadingInflationRate : false
            }, () => {
              
              thisReference.setState({
                lookUpInflationRateCounter : 0
              });
    
            });
            */

            clearInterval(interval);
          }
        } catch(error)
        {
          console.log(error);
        }
      
      });


    } catch(error)
    {
      console.log(error);
    } 
    
  }, 1000);

  this.setState({
    inflationRateInterval : interval
  });
}

StopInflationRateProgress(){

if (this.state.inflationRateInterval != null)
{
    clearInterval(this.state.inflationRateInterval);

    this.setState({
      lookUpInflationRateCounter : 100
    }, () => {
      this.CheckProgressSuccessCompletion();
    });
  }

}


  StartVancacyRateProgress(){

    this.setState({
      lookUpVancacyRateCounter:0,
      loadingVancacyRate : true
    }, () => {

    });
    

    const thisReference = this;

    var interval = setInterval(function(){

      try
      {

        let counter = thisReference.state.lookUpVancacyRateCounter + 1;

        thisReference.setState({
          lookUpVancacyRateCounter:counter
        }, () => {
          try
          {
            if( thisReference.state.lookUpVancacyRateCounter == 100 ){
       
              /*
              thisReference.setState({
                loadingVancacyRate : false
              }, () => {
                
                thisReference.setState({
                  lookUpVancacyRateCounter : 0
                });
      
              });
              */

              clearInterval(interval);
            }
          } catch(error)
          {
            console.log(error);
          }
        
        });


      } catch(error)
      {
        console.log(error);
      } 
      
    }, 1000);
  
    this.setState({
      vancacyRateInterval : interval
    });
}

StopVancacyRateProgress(){
  
  if (this.state.vancacyRateInterval != null)
  {
      clearInterval(this.state.vancacyRateInterval);

      this.setState({
        lookUpVancacyRateCounter : 100
      }, () => {
        this.CheckProgressSuccessCompletion();
      });
    }

}

  StartTrendingRentProgress(){

    this.setState({
      lookUpTrendingRentCounter:0,
      loadingTrendingRent : true
    }, () => {

    });
    

    const thisReference = this;

    var interval = setInterval(function(){

      try
      {

        let counter = thisReference.state.lookUpTrendingRentCounter + 1;

        thisReference.setState({
          lookUpTrendingRentCounter:counter
        }, () => {
          try
          {
            if( thisReference.state.lookUpTrendingRentCounter == 100 ){
       
              /*
              thisReference.setState({
                loadingTrendingRent : false
              }, () => {
                
                thisReference.setState({
                  lookUpTrendingRentCounter : 0
                });
      
              });
              */

              clearInterval(interval);
            }
          } catch(error)
          {
            console.log(error);
          }
        
        });


      } catch(error)
      {
        console.log(error);
      } 
      
    }, 1000);
  
    this.setState({
      trendingRentInterval : interval
    });
}

StopTrendingRentProgress(){
  
  if (this.state.trendingRentInterval != null)
  {
      clearInterval(this.state.trendingRentInterval);

      this.setState({
        lookUpTrendingRentCounter : 100
      }, () => {
        this.CheckProgressSuccessCompletion();
      });
  }

}

StartPricePerSqftProgress(){

  this.setState({
    lookUpPricePerSqftCounter:0,
    loadingPricePerSqft : true
  }, () => {

  });
  

  const thisReference = this;

  var interval = setInterval(function(){

    try
    {

      let counter = thisReference.state.lookUpPricePerSqftCounter + 1;

      thisReference.setState({
        lookUpPricePerSqftCounter:counter
      }, () => {
        try
        {
          if( thisReference.state.lookUpPricePerSqftCounter == 100 ){
     
            /*
            thisReference.setState({
              loadingPricePerSqft : false
            }, () => {
              
              thisReference.setState({
                lookUpPricePerSqftCounter : 0
              });
    
            });
            */

            clearInterval(interval);
          }
        } catch(error)
        {
          console.log(error);
        }
      
      });


    } catch(error)
    {
      console.log(error);
    } 
    
  }, 1000);

  this.setState({
    pricePerSqftInterval : interval
  });
}

StopPricePerSqftProgress(){

if (this.state.pricePerSqftInterval != null)
{
    clearInterval(this.state.pricePerSqftInterval);

    this.setState({
      lookUpPricePerSqftCounter : 100
    }, () => {
      this.CheckProgressSuccessCompletion();
    });
  }

}



CheckProgressSuccessCompletion(){
  
/*
  
  if (this.state.trendingRentInterval != null)
  {
      clearInterval(this.state.trendingRentInterval);

      this.setState({
        lookUpTrendingRentCounter : 100
      });
  }
  */

  if (this.state.investmentType == "Rental Property")
  {
   /* 
        console.log("============================================");
        console.log("monthlyRentAsNumber:"+this.state.monthlyRentAsNumber);
        console.log("vacancyRateAsNumber:"+this.state.vacancyRateAsNumber); 
        console.log("closingCostsAsNumber:"+this.state.closingCostsAsNumber);  
        console.log("expenseRateOfGrowthAsNumber:"+this.state.expenseRateOfGrowthAsNumber); 
        console.log("rentRateOfGrowthAsNumber:"+this.state.rentRateOfGrowthAsNumber); 
        console.log("marketValueAsNumber:"+this.state.marketValueAsNumber); 
   */

    if (this.state.monthlyRentAsNumber > 0 && 
        this.state.vacancyRateAsNumber > 0 && 
        this.state.closingCostsAsNumber > 0 &&  
        this.state.expenseRateOfGrowthAsNumber > 0 && 
        this.state.rentRateOfGrowthAsNumber > 0  && 
        this.state.marketValueAsNumber > 0)
        {
   
          this.setState({
            showProcessMessage:false,
            showSuccessMessage:true
          });
        }  

  } else if (this.state.investmentType == "Primary Home")
  {
    if (this.state.trendingMonthlyRentAsNumber > 0 && 
      this.state.trendingPricePerSqftAsNumber > 0 && 
      this.state.closingCostsAsNumber > 0 &&  
      this.state.marketValueAsNumber > 0)
      {
        this.setState({
          showProcessMessage:false,
          showSuccessMessage:true
        });
      }  

  } else if (this.state.investmentType == "Flipping Property")
  {
    if (this.state.closingCostsAsNumber > 0 &&  
      this.state.afterRepairValueAsNumber > 0)
      {
        this.setState({
          showProcessMessage:false,
          showSuccessMessage:true
        });
      }  
  }

}


  CheckAllRequiredFieldsAvaialbleForAnalysis()
  {
    let isReadyForAnalysis = false;

      /*
    console.log("-----------------------------------------------------");
    console.log("Inside CheckAllRequiredFieldsAvaialbleForAnalysis()");

    console.log("this.state.livingAreaSizeAsNumber:"+this.state.livingAreaSizeAsNumber);
    console.log("this.state.propertyPriceAsNumber:"+this.state.propertyPriceAsNumber);  
    console.log("this.state.marketValueAsNumber:"+this.state.marketValueAsNumber);
    console.log("this.state.downPaymentAsNumber:"+this.state.downPaymentAsNumber); 
    console.log("this.state.termAsNumber:"+this.state.termAsNumber);
    console.log("this.state.interestRateAsNumber:"+this.state.interestRateAsNumber); 
    console.log("this.state.closingCostsAsNumber:"+this.state.closingCostsAsNumber); 
    console.log("this.state.monthlyPaymentAsNumber:"+this.state.monthlyPaymentAsNumber);
    */

    //Check Common fields to all investment types
    if (this.state.livingAreaSizeAsNumber > 0 && 
      this.state.propertyPriceAsNumber > 0 &&  
      this.state.downPaymentAsNumber > 0 && 
      this.state.termAsNumber > 0 && 
      this.state.interestRateAsNumber > 0 && 
      this.state.closingCostsAsNumber > 0 && 
      this.state.monthlyPaymentAsNumber > 0
      )
      {
        
        console.log("CheckAllRequiredFieldsAvaialbleForAnalysis() core fulfilled");
        /* console.log("this.state.investmentType:"+this.state.investmentType);
        console.log("this.state.holdingMonthsAsNumber:"+this.state.holdingMonthsAsNumber); 
        console.log("this.state.rehabCostsAsNumber:"+this.state.rehabCostsAsNumber); 
        console.log("this.state.holdingCostsAsNumber:"+this.state.holdingCostsAsNumber); 
        console.log("this.state.sellingCostsAsNumber:"+this.state.sellingCostsAsNumber); 
        console.log("this.state.budgetAsNumber:"+this.state.budgetAsNumber);
        */

        
        console.log("this.state.investmentType:"+this.state.investmentType); 
        console.log("this.state.monthlyBillsAsNumber:"+this.state.monthlyBillsAsNumber); 
        console.log("this.state.monthlyGrossIncomeAsNumber:"+this.state.monthlyGrossIncomeAsNumber);
        console.log("this.state.marketValueAsNumber:"+this.state.marketValueAsNumber); 
        console.log("this.state.trendingPricePerSqftAsNumber:"+this.state.trendingPricePerSqftAsNumber); 
        console.log("this.state.trendingMonthlyRent:"+this.state.trendingMonthlyRentAsNumber); 
        

          if (this.state.investmentType == "Primary Home" && 
              this.state.monthlyBillsAsNumber > 0 && 
              this.state.monthlyGrossIncomeAsNumber > 0 && 
              this.state.marketValueAsNumber > 0 && 
              this.state.trendingPricePerSqftAsNumber > 0 && 
              this.state.trendingMonthlyRentAsNumber > 0)
          { 
                isReadyForAnalysis = true;
              
          } else if (this.state.investmentType == "Rental Property" && 
          this.state.monthlyRentAsNumber > 0 && 
          this.state.monthlyExpensesAsNumber > 0 && 
          this.state.vacancyRateAsNumber > 0 && 
          this.state.initialExpensesAsNumber > 0 && 
          this.state.marketValueAsNumber > 0 && 
          this.state.rentRateOfGrowthAsNumber > 0 && 
          this.state.expenseRateOfGrowthAsNumber > 0 && 
          this.state.projectedYearOfSale != "" && 
          this.state.projectedSaleProceedsAsNumber > 0 && 
          this.state.year1CashFlowAsNumber > 0)     //TODO: Also need to check remaining year cash flows based on Year selected
          {
              isReadyForAnalysis = true;

          } else if (this.state.investmentType == "Flipping Property" && 
            this.state.holdingMonthsAsNumber > 0 && 
            this.state.rehabCostsAsNumber > 0 && 
            this.state.holdingCostsAsNumber > 0 && 
            this.state.sellingCostsAsNumber > 0 && 
            this.state.budgetAsNumber > 0)
            {
              //console.log("CheckAllRequiredFieldsAvaialbleForAnalysis() flipping fulfilled");

              isReadyForAnalysis = true;
            }

           if (isReadyForAnalysis)
           {
         
             this.setState({
              showAnalyzePropertyPrompt: true
              });
           }
      }

  }

  LookupTrendingRent()
  {
    
    //Set monthly rent only if monthly rent wasn't populate by property lookup or manually
    if (this.state.monthlyRentAsNumber ==0 || this.state.monthlyRent=='' || this.state.monthlyRent=='$0')  
    {

        if (this.state.investmentType == "Rental Property" || this.state.investmentType == "Primary Home")
        {
          this.StartTrendingRentProgress();
        }

        const payload = {
          zipcode: this.state.zipcode,
          city: this.state.city,
          state: this.state.stateCode,
          numberBedrooms: this.state.numberBedrooms,
          numberBathrooms: this.state.numberBathrooms,
          propertySqFt: this.state.livingAreaSizeAsNumber
        };

        const options = {
          url: '/api/lookupTrendingRent',
          method: 'post',
          data: payload
        };

        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
        axios(options)
        .then((response) => {

          if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
          {
              localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          }

            this.StopTrendingRentProgress();

            if (!response.data.hasOwnProperty('trendingRent') || isNaN(response.data.trendingRent) || response.data.trendingRent ==0  || response.data.trendingRent == null) {
        
              this.setState({
                trendingRentResultSuccess:false,
                trendingRentResultFailure:true,
                messageBoxBottomTextSuccess:false,
                messageBoxBottomTextFailure:true
              });

            } else {
                this.setState({
                  monthlyRentAsNumber: response.data.trendingRent, 
                  monthlyRent: this.FormatNumber(response.data.trendingRent),
                  trendingMonthlyRentAsNumber: response.data.trendingRent, 
                  trendingMonthlyRent: this.FormatNumber(response.data.trendingRent)
                }, () => {
          
                  this.CalculateMonthlyExpenses();
                  this.CalculateHoldingCosts();
                  this.CheckProgressSuccessCompletion();
                // this.CheckAllRequiredFieldsAvaialbleForAnalysis();  
                });
            }
    
        })
        .catch((err) => {
          this.setState({
            trendingRentResultSuccess:false,
            trendingRentResultFailure:true,
            messageBoxBottomTextSuccess:false,
            messageBoxBottomTextFailure:true
          });
          this.StopTrendingRentProgress();
          //alert(err);
          console.log(err);
        });
      }
  }


  LookupTrendingPricePerSqft()
  {    
    if (this.state.investmentType == "Primary Home")
    {
      this.StartPricePerSqftProgress();
    }
    
    const payload = {
      city: this.state.city,
      state: this.state.stateCode,
      zipcode: this.state.zipcode,
      street: this.state.address,
      numberBedrooms: this.state.numberBedrooms,
      numberBathrooms: this.state.numberBathrooms
    };

    const options = {
      url: '/api/lookupTrendingPricePerSqft',
      method: 'post',
      data: payload
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options)
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      this.StopPricePerSqftProgress();
      //alert(JSON.stringify(response));

      //Set price per sqft only if monthly price per sqft wasn't populate  manually
      if (this.state.trendingPricePerSqft ==0 || this.state.trendingPricePerSqft=='' || this.state.trendingPricePerSqft=='$0')
      {
        if (!response.data.hasOwnProperty('trendingPricePerSqft') || isNaN(response.data.trendingPricePerSqft) || response.data.trendingPricePerSqft ==0  || response.data.trendingPricePerSqft == null) {
        
          this.setState({
            pricePerSqftResultSuccess:false,
            pricePerSqftResultFailure:true,
            messageBoxBottomTextSuccess:false,
            messageBoxBottomTextFailure:true
          });

        } else {
            this.setState({
              trendingPricePerSqft: this.FormatNumber(response.data.trendingPricePerSqft),
              trendingPricePerSqftAsNumber: response.data.trendingPricePerSqft
            }, () => {
              this.CheckProgressSuccessCompletion();
              //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
            });

        }
      }

    })
    .catch((err) => {
               this.setState({
                pricePerSqftResultSuccess:false,
                pricePerSqftResultFailure:true,
                messageBoxBottomTextSuccess:false,
                messageBoxBottomTextFailure:true
              });

      this.StopPricePerSqftProgress();
      //alert(err);
      console.log(err);
    });

  }

  
  LookupTrendingPriceInArea()
  {    

    if ((this.state.investmentType == "Primary Home" || this.state.investmentType == "Rental Property") && this.state.marketValueAsNumber ==0)
    {
        this.StartTrendingPriceInAreaProgress();

    } else if (this.state.investmentType == "Flipping Property")
    {
      this.StartTrendingPriceInAreaProgress();
    }

    const payload = {
      street : this.state.address,
      city : this.state.city,
      state : this.state.stateCode,
      zipcode : this.state.zipcode,
      numberBedrooms : this.state.numberBedrooms,
      numberBathrooms : this.state.numberBathrooms,
      propertySqFt : this.state.livingAreaSize,
      propertyType: this.state.propertyType,
      propertySubType: this.state.propertySubType
    };

    const options = {
      url: '/api/lookupTrendingPriceInArea',
      method: 'post',
      data: payload
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options,{timeout:240000})
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      this.StopTrendingPriceInAreaProgress();

      //alert(JSON.stringify(response.data));

      //if (("trendingPrice" in response.data)==true)

      if (response.data != null)
      {
          //Set price per sqft only if monthly rent wasn't populate  manually
          if (this.state.afterRepairValueAsNumber ==0 || this.state.afterRepairValue=='' || this.state.afterRepairValue=='$0')
          {
            if (!response.data.hasOwnProperty('trendingPrice') || isNaN(response.data.trendingPrice) || response.data.trendingPrice ==0  || response.data.trendingPrice == null) {

                 this.setState({
                trendingPriceInAreaResultSuccess:false,
                trendingPriceInAreaResultFailure:true,
                messageBoxBottomTextSuccess:false,
                messageBoxBottomTextFailure:true
              });

            } else {
        
              this.setState({
                afterRepairValue: this.FormatNumber(response.data.trendingPrice),
                afterRepairValueAsNumber: response.data.trendingPrice,
                similarSoldProperties : response.data.similarSoldProperties

              }, () => {
                 this.CalculateSellingCosts();  
                 this.CalculateFlippingTrendingPricePerSqft();
                 this.CheckProgressSuccessCompletion();
                // this.CheckAllRequiredFieldsAvaialbleForAnalysis();  
              });
            }
            
          }


          //Set market value if it is zero for some reason
          if (this.state.marketValueAsNumber ==0 || this.state.marketValue=='' || this.state.marketValue=='$0')
          {
            if (!response.data.hasOwnProperty('trendingPrice') || isNaN(response.data.trendingPrice) || response.data.trendingPrice ==0  || response.data.trendingPrice == null) {

              this.setState({
                trendingPriceInAreaResultSuccess:false,
                trendingPriceInAreaResultFailure:true,
                messageBoxBottomTextSuccess:false,
                messageBoxBottomTextFailure:true
              });

            } else {

              let marketValue = response.data.trendingPrice;

              if (response.data.hasOwnProperty('marketValue'))
              {
                marketValue = response.data.marketValue;
              }

              this.setState({
                marketValue: this.FormatNumber(marketValue),
                marketValueAsNumber: marketValue
              }, () => {
                this.CheckProgressSuccessCompletion();
                //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
              });
            }
          }

          
          //Set trending price per sqft if it zero and it is primary home
          if (this.state.investmentType === "Primary Home")
          {
              if (this.state.trendingPricePerSqft ==0 || this.state.trendingPricePerSqft=='' || this.state.trendingPricePerSqft=='$0')
              {
            
                if (response.data.hasOwnProperty('similarSoldProperties'))
                { 
                  this.PopulateTrendingPricePerSqftFromSimilarProperties(response.data.similarSoldProperties);

                }
              }
          }


      }


    })
    .catch((err) => {

          this.setState({
                trendingPriceInAreaResultSuccess:false,
                trendingPriceInAreaResultFailure:true,
                messageBoxBottomTextSuccess:false,
                messageBoxBottomTextFailure:true
              });
      this.StopTrendingPriceInAreaProgress();
     
      //If it fails then go ahead and call the normal trending price per sqft function it is a Primary Home
      if (this.state.investmentType === "Primary Home")
      {
              if (this.state.trendingPricePerSqft ==0 || this.state.trendingPricePerSqft=='' || this.state.trendingPricePerSqft=='$0')
              {
          
                this.LookupTrendingPricePerSqft();
              }
      }

      //alert("trending price lookup:"+err);
      console.log(err);
    });

  }

  LookupClosingCosts()
  {    

    this.StartClosingCostsProgress();

    const payload = {
      purchasePrice : this.state.propertyPrice,
      downPaymentPercent: this.state.downPaymentPercent,
      interestRate: this.state.interestRateAsNumber,
      loanTerm: this.state.term,
      state: this.state.stateCode,
      zipcode: this.state.zipcode
    };

    const options = {
      url: '/api/lookupClosingCosts',
      method: 'post',
      data: payload
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options)
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      this.StopClosingCostsProgress();

      //console.log("LookupClosingCost() - CAME BACK from lookupClosingCosts");
      //console.log(JSON.stringify(response.data));

      //Set closing costs only if monthly rent wasn't populate  manually
      if (this.state.closingCosts ==0 || this.state.closingCosts=='' || this.state.closingCosts=='$0')
      {
        if (!response.data.hasOwnProperty('closingCosts') || isNaN(response.data.closingCosts) || response.data.closingCosts ==0  || response.data.closingCosts == null) {
        
          /*
          this.setState({
            closingCostsResultSuccess:false,
            closingCostsResultFailure:true,
            messageBoxBottomTextSuccess:false,
            messageBoxBottomTextFailure:true
          });
          */

          this.CalculateClosingCostsFromLoanAmount();

        } else
        {
            this.setState({
              closingCosts: this.FormatNumber(response.data.closingCosts),
              closingCostsAsNumber: response.data.closingCosts
            }, () => {
              //this.CheckAllRequiredFieldsAvaialbleForAnalysis();

                if (this.state.closingCosts ==0 || this.state.closingCosts=='' || this.state.closingCosts=='$0')
                {
                  this.CalculateClosingCostsFromLoanAmount();
                  
                }

                this.CalculateClosingCostsPercent();
                this.CheckProgressSuccessCompletion();
                
            });
        }
      }

    })
    .catch((err) => {

      /*
      this.setState({
        closingCostsResultSuccess:false,
        closingCostsResultFailure:true,
        messageBoxBottomTextSuccess:false,
        messageBoxBottomTextFailure:true
      });
      */

      this.CalculateClosingCostsFromLoanAmount();
      
      this.StopClosingCostsProgress();

      //alert(err);
      console.log(err);

      if (this.state.closingCosts ==0 || this.state.closingCosts=='' || this.state.closingCosts=='$0')
      {
        this.CalculateClosingCostsFromLoanAmount();
      }

    });

  }

  PopulateTrendingPricePerSqftFromSimilarProperties(similarSoldProperties)
  {
      try
      {

        let averagePricePerSqFt = 0;
        let totalPricePerSqFt = 0;

        for (let selectedProperty of similarSoldProperties)
        {
          let price = selectedProperty.price;
          price = this.ConvertToNumber(price);

          let sqFt = selectedProperty.sqFt;
          sqFt = this.ConvertToNumber(sqFt);
  
          let pricePerSqFt = 0;

          if (price > 0 && sqFt > 0)
          {
              pricePerSqFt = parseInt(price / sqFt);
              totalPricePerSqFt = totalPricePerSqFt + pricePerSqFt; 
          }

        }

        averagePricePerSqFt = totalPricePerSqFt / similarSoldProperties.length;

        averagePricePerSqFt = parseInt(averagePricePerSqFt);

        this.setState({
          trendingPricePerSqft: this.FormatNumber(averagePricePerSqFt),
          trendingPricePerSqftAsNumber: averagePricePerSqFt
        }, () => {
          this.StopPricePerSqftProgress();

          this.CheckProgressSuccessCompletion();
          //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
        });

      }catch(error)
      {
        console.log(error);
      }

  }

  ConvertToNumber(value)
  {
      value = value.toString();
      let valueNumber = 0;

      try
      {
          let valueString = value.toString();
          valueString = value.toString().replace(",","").replace("$","").replace("+","").replace("-","").replace("/mo","");

          if (valueString.endsWith("+"))
          {
              valueString = valueString.slice(0, -1);
          }

          valueNumber = parseInt(valueString);
      } catch(error)
      {
          console.log(error);
          valueNumber = value;
      }

      return valueNumber;
  }

  CalculateClosingCostsFromLoanAmount()
  {
      //console.log("Inside CalculateClosingCostsFromLoanAmount()");

      if (this.state.downPaymentAsNumber > 0 && this.state.propertyPriceAsNumber > 0)
      {
        //console.log("downPaymentAsNumber:"+this.state.downPaymentAsNumber);
        //console.log("propertyPriceAsNumber :"+this.state.propertyPriceAsNumber);
        //console.log("Settings.closingCostsPercent :"+this.state.settings?.closingCostsPercent);

        const loanAmount = this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber;
        const closingCosts =  (loanAmount * this.state.settings?.closingCostsPercent)/100;

        //console.log("closingCosts:"+closingCosts);

        this.setState({
          closingCosts: this.FormatMoney(closingCosts),
          closingCostsAsNumber: closingCosts
        }, () => {
          this.CalculateClosingCostsPercent();

          if (closingCosts ==0)
          {
            this.setState({
              closingCostsResultSuccess:false,
              closingCostsResultFailure:true,
              messageBoxBottomTextSuccess:false,
              messageBoxBottomTextFailure:true
            });
          }

        });

      } else
      {
        this.setState({
          closingCostsResultSuccess:false,
          closingCostsResultFailure:true,
          messageBoxBottomTextSuccess:false,
          messageBoxBottomTextFailure:true
        });
      }
  }


  LookupInflationRate()
  {    

    if (this.state.investmentType == "Rental Property")
    {
      this.StartInflationRateProgress();
    }

    const payload = {
    };

    const options = {
      url: '/api/lookupInflationRate',
      method: 'post',
      data: {payload}
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options)
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      this.StopInflationRateProgress();

      //Set expense rate of growth only if monthly rent wasn't populate  manually
      if (this.state.expenseRateOfGrowthAsNumber ==0 )
      {
        if (!response.data.hasOwnProperty('inflationRate') || isNaN(response.data.inflationRate) || response.data.inflationRate ==0  || response.data.inflationRate == null) {
        
          this.setState({
            inflationRateResultSuccess:false,
            inflationRateResultFailure:true,
            messageBoxBottomTextSuccess:false,
            messageBoxBottomTextFailure:true
          });

        } else {
          this.setState({
            expenseRateOfGrowth: this.FormatPercent(response.data.inflationRate),
            expenseRateOfGrowthAsNumber: response.data.inflationRate
          }, () => {
            //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
            this.ForceCalculateFutureCashFlows();
            this.CheckProgressSuccessCompletion();
          });
        }
      }

    })
    .catch((err) => {

        this.setState({
        inflationRateResultSuccess:false,
        inflationRateResultFailure:true,
        messageBoxBottomTextSuccess:false,
        messageBoxBottomTextFailure:true
      });
      
      this.StopInflationRateProgress();
      //alert(err);
      console.log(err);
    });

  }

  
  LookupRentalVancacyRate()
  {    

    if (this.state.investmentType == "Rental Property")
    {
      this.StartVancacyRateProgress();
    }

    const payload = {
      state: this.state.stateCode
    };

    const options = {
      url: '/api/lookupRentalVacancyRate',
      method: 'post',
      data: payload
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options)
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      this.StopVancacyRateProgress();

      //Set vacancy rate only if monthly rent wasn't populate  manually
      if (this.state.vacancyRateAsNumber ==0)
      {
        if (!response.data.hasOwnProperty('vacancyRate') || isNaN(response.data.vacancyRate) || response.data.vacancyRate ==0  || response.data.vacancyRate == null) {
        
          this.setState({
            vancacyRateResultSuccess:false,
            vancacyRateResultFailure:true,
            messageBoxBottomTextSuccess:false,
            messageBoxBottomTextFailure:true
          });

        } else {
          this.setState({
            vacancyRate: this.FormatPercent(response.data.vacancyRate),
            vacancyRateAsNumber: response.data.vacancyRate
          }, () => {
            //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
            this.ForceCalculateFutureCashFlows();
            this.CheckProgressSuccessCompletion();
          });
        }
      }

    })
    .catch((err) => {
        this.setState({
        vancacyRateResultSuccess:false,
        vancacyRateResultFailure:true,
        messageBoxBottomTextSuccess:false,
        messageBoxBottomTextFailure:true
      });
      
      this.StopVancacyRateProgress();
      //alert(err);
      console.log(err);
    });

  }


  LookupRentRateOfGrowth()
  {    

    if (this.state.investmentType == "Rental Property")
    {
        this.StartRentRateOfGrowthProgress();
    }

    const payload = {
      city: this.state.city,
      state: this.state.stateCode,
      numberOfBedrooms: this.state.numberBedrooms
    };

    const options = {
      url: '/api/lookupRentRateOfGrowth',
      method: 'post',
      data: payload
    };

    authMiddleWare(this?.props?.history);
    const authToken = localStorage.getItem('AuthToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
    axios(options)
    .then((response) => {

      if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
      {
          localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
          localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
      }

      this.StopRentRateOfGrowthProgress();

      //Set vacancy rate only if monthly rent wasn't populate  manually
      if (this.state.rentRateOfGrowthAsNumber ==0)
      {  
        if (!response.data.hasOwnProperty('rentRateOfGrowth') || isNaN(response.data.rentRateOfGrowth) || response.data.rentRateOfGrowth ==0  || response.data.rentRateOfGrowth == null) {
        
          this.setState({
            rentRateOfGrowthResultSuccess:false,
            rentRateOfGrowthResultFailure:true,
            messageBoxBottomTextSuccess:false,
            messageBoxBottomTextFailure:true
          });

        } else {   
          this.setState({
            rentRateOfGrowth: this.FormatPercent(response.data.rentRateOfGrowth),
            rentRateOfGrowthAsNumber: response.data.rentRateOfGrowth
          }, () => {
            //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
            this.ForceCalculateFutureCashFlows();
            this.CheckProgressSuccessCompletion();
          });
        }
      }

    })
    .catch((err) => {

      this.setState({
        rentRateOfGrowthResultSuccess:false,
        rentRateOfGrowthResultFailure:true,
        messageBoxBottomTextSuccess:false,
        messageBoxBottomTextFailure:true
      });
      
      this.StopRentRateOfGrowthProgress();
      //alert(err);
      console.log(err);
    });

  }


  PercentFormatToNumber(value)
  {
    try
    {
      value = value.replace("%","");
      value = Number(value);
    }catch(error)
    {

    }

    return value;
  }

  FormatNumber(value)
  {
    
    try
    {
      if (value.toString().indexOf(",") <0)
      {
        value = value.toLocaleString("en-US")
      }
    } catch(error)
    {

    }

    return value;
  }

  FormatPercent(value)
  {

    if (value != null)
    {
      if (!value.toString().endsWith("%"))
      {
        value = value.toString() +"%";
      }
    }
    
    return value;
  }
  

  FormatNumberToPercentOneDecimal(value)
  {

    const rounded = Math.round(value * 10) / 10
    
    const valueFormatted = rounded.toString()+"%";

    return valueFormatted;
  }

  FixLotSize(value)
  {
    if (value != null && value !== undefined)
    {
      if(!isNaN(value)){
        value = value.toString();
      }

      if (value.toLowerCase().indexOf("acres")>=0)
      {
        const theArray = value.split(" ")

        if (theArray.length >1)
        {
          const acres = theArray[0].trim();

          value = parseFloat(value) * 43560;  //convert acres to sqft

          value = Math.floor(value);

    
        }

      }
    }

    return value;
  }

  ParseTerm(value)
  {
    let term = "";

    if (value != null && value != "")
    {
        if (value.startsWith("15"))
        {
          term = "15 Years";

        } else if (value.startsWith("20"))
        {
          term = "20 Years";
        
        }else if (value.startsWith("30"))
        {
          term = "30 Years";
        
        }else if (value.startsWith("40"))
        {
          term = "40 Years";
        }
    } else 
    {
      term = "30 Years";
    } 

    if (term =="")
    {
      term = "30 Years";
    }


    return term;
  }

  RemovePercentValue(value)
  {
    if (value.toString().includes("%"))
    {
       value = this.ConvertFormmattedNumberToNumber(value.toString().replace("%",""));
    }

    return value;
  }

  FixPercentValue(value)
  {
    if (!value.endsWith("%"))
    {
      value = value+"%";
    }

    return value;
  }

  ConvertFormmattedNumberToNumber(formattedValue)
  {
    //console.log("formattedValue:");
    //console.log(formattedValue);

    let val = "";

    if (formattedValue != null && formattedValue !== undefined)
    {
        if(!isNaN(formattedValue)){
          formattedValue = formattedValue.toString();
        }

        val = Number(formattedValue.replace(/[^0-9\.-]+/g,""));  
      }

    return val;
  }

  HandleMonthlyBillsBreakdownClose(event,obj){

    //This is the only way close OverlayTrigger programmaly.
    //  - Set <OverlayTrigger rootClose={true}
    //  - And onClick event trigger call document.body.click()

    //TODO: Add login to check if any fields have been changed to pop a message: Save Change Made or Close without Saving?

    document.body.click();

  }


  HandleMortgagePaymentBreakClose(event,obj){

    //This is the only way close OverlayTrigger programmaly.
    //  - Set <OverlayTrigger rootClose={true}
    //  - And onClick event trigger call document.body.click()

    //TODO: Add login to check if any fields have been changed to pop a message: Save Change Made or Close without Saving?

    document.body.click();

  }

  HandleMonthlyExpensesBreakClose(event,obj){

    //This is the only way close OverlayTrigger programmaly.
    //  - Set <OverlayTrigger rootClose={true}
    //  - And onClick event trigger call document.body.click()

    //TODO: Add login to check if any fields have been changed to pop a message: Save Change Made or Close without Saving?

    document.body.click();

  }

  HandleInitialExpensesBreakClose(event,obj){

    //This is the only way close OverlayTrigger programmaly.
    //  - Set <OverlayTrigger rootClose={true}
    //  - And onClick event trigger call document.body.click()

    //TODO: Add login to check if any fields have been changed to pop a message: Save Change Made or Close without Saving?

    document.body.click();

  }

  
  HandleHoldingCostsBreakClose(event,obj){

    //This is the only way close OverlayTrigger programmaly.
    //  - Set <OverlayTrigger rootClose={true}
    //  - And onClick event trigger call document.body.click()

    //TODO: Add login to check if any fields have been changed to pop a message: Save Change Made or Close without Saving?

    document.body.click();

  }


  HandleFinancingExpand(event,obj){

    var value = document.querySelector('#financingPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#financingPlus').innerHTML = "-";

      document.querySelector('#financingContent').style.display = "block";
      
    } else
    {
      document.querySelector('#financingPlus').innerHTML = "+";

      document.querySelector('#financingContent').style.display = "none";
    }
    
  }

  HandleBuyersBudgetExpand(event,obj){

    var value = document.querySelector('#buyersBudgetPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#buyersBudgetPlus').innerHTML = "-";

      document.querySelector('#buyersBudgetContent').style.display = "block";
      
    } else
    {
      document.querySelector('#buyersBudgetPlus').innerHTML = "+";

      document.querySelector('#buyersBudgetContent').style.display = "none";
    }
    
  }

  HandleRentalExpand(event,obj){

    var value = document.querySelector('#rentalPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#rentalPlus').innerHTML = "-";

      document.querySelector('#rentalContent').style.display = "block";
      
    } else
    {
      document.querySelector('#rentalPlus').innerHTML = "+";

      document.querySelector('#rentalContent').style.display = "none";
    }
    
  }


  HandleProjectionsExpand(event,obj){

    var value = document.querySelector('#projectionsPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#projectionsPlus').innerHTML = "-";

      document.querySelector('#projectionsContent').style.display = "block";
      
    } else
    {
      document.querySelector('#projectionsPlus').innerHTML = "+";

      document.querySelector('#projectionsContent').style.display = "none";
    }
    
  }

  HandleFlippingCostsExpand(event,obj){

    var value = document.querySelector('#flippingCostsPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#flippingCostsPlus').innerHTML = "-";

      document.querySelector('#flippingCostsContent').style.display = "block";
      
    } else
    {
      document.querySelector('#flippingCostsPlus').innerHTML = "+";

      document.querySelector('#flippingCostsContent').style.display = "none";
    }
    
  }


  HandleBuyerExpand(event,obj){

    var value = document.querySelector('#buyerPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#buyerPlus').innerHTML = "-";

      document.querySelector('#buyerContent').style.display = "block";
      
    } else
    {
      document.querySelector('#buyerPlus').innerHTML = "+";

      document.querySelector('#buyerContent').style.display = "none";
    }
    
  }


  HandleAreaExpand(event,obj){

    var value = document.querySelector('#areaTrendPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#areaTrendPlus').innerHTML = "-";

      document.querySelector('#areaTrendContent').style.display = "block";
      
    } else
    {
      document.querySelector('#areaTrendPlus').innerHTML = "+";

      document.querySelector('#areaTrendContent').style.display = "none";
    }
    
  }

  HandleSelectedImageOLD(event,obj){ //IT JUSTS FINE, BUT IT DOESN'T HAVE LOGIC TO RESIZE THE IMAGE

    document.querySelector('#propertyImage').style.display = "block";

    /*
    const imagePreviewURL = URL.createObjectURL(event.target.files[0]);
    this.setState({
      propertyImage: event.target.files[0].name,
      imagePreviewURL : imagePreviewURL
    });
    */

    //Now get the Base64 data of file
    const reader = new FileReader();
      reader.readAsBinaryString(event.target.files[0]);

      const imageName = event.target.files[0].name;
      const imageType = event.target.files[0].type;
      const me = this;

      reader.onload = function(event) {
        // Convert file to Base64 string
		// btoa is built int javascript function for base64 encoding
        
        console.log("read the file");
        const base64String = window.btoa(event.target.result)
        const imageDataURI = "data:"+imageType+";base64,"+base64String;

        me.setState({
          propertyImage: imageName,
          imagePreviewURL : imageDataURI
        });

      };
      reader.onerror = function() {
        console.log("can't read the file");
      
      };

  }



  HandleSelectedImage(event,obj){

    //const [ imageFile ] = event.target.files;
    const imageFile = event.target.files[0];
    const { type: mimeType } = imageFile;
  
    const imageName = event.target.files[0].name;
    const imageType = event.target.files[0].type;
    const me = this;

    //alert(imageFile);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(imageFile);
    fileReader.onload = (fileReaderEvent) => {
  
      const imageAsBase64 = fileReaderEvent.target.result;

      //alert(imageAsBase64);
      const image = document.createElement("img");
      image.src = imageAsBase64;
  
      image.onload = function() {  //You need to give time to the image to load
      
          const imageResizeWidth = 300;
          // if (image.width <= imageResizeWidth) {
          //  return;
          // }
      
          const canvas = document.createElement('canvas');
          canvas.width = imageResizeWidth;
          //canvas.height = ~~(image.height * (imageResizeWidth / image.width));
          //const context = canvas.getContext('2d', { alpha: false });
          const context = canvas.getContext('2d');
          //alert(context);
          
          // if (!context) {
          //  return;
          // }
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
      
          
          //const resizedImageBinary = canvas.toBlob();
          const resizedImageAsBase64 = canvas.toDataURL(mimeType);

       //     console.log("resizedImageAsBase64:");
       //     console.log(resizedImageAsBase64);

            me.setState({
              propertyImage: imageName,
              imagePreviewURL : resizedImageAsBase64
            });

      };


    };
  }
  
  HandleNotesExpand(event,obj){

    var value = document.querySelector('#notesPlus').innerHTML;

    if (value =="+")
    {
      document.querySelector('#notesPlus').innerHTML = "-";

      document.querySelector('#notesContent').style.display = "block";
      
    } else
    {
      document.querySelector('#notesPlus').innerHTML = "+";

      document.querySelector('#notesContent').style.display = "none";
    }
    
  }

  handlePercentInputChange = (event) => {
  
    var numberVar = event.target.value.replace(/[^\d.-]/g, '');
  
    numberVar = numberVar.replace('....','.').replace('...','..').replace('..','.');

    var formattedValue = numberVar

    if(event.target.value.endsWith("%"))
    {
      formattedValue = numberVar+"%";
    }
    
    this.setState({
      [event.target.name]: formattedValue
    });

    var key = event.target.name+"AsNumber"
    var val = Number(numberVar);  
    var obj  = {}
    obj[key] = val
    this.setState(obj, () => {
  
      this.HandleAdditionalSpecificLogic(event);     

    });
    
    //Delay putting % if it was removed, this is to allow user to remove the % when typing it
    /*
    if(!formattedValue.endsWith("%"))
    {
          setTimeout(
            function() {

                formattedValue = document.querySelector('#'+event.target.name).value

                if(!formattedValue.endsWith("%"))
                {
                  formattedValue = formattedValue+"%";
                  this.setState({
                    [event.target.name]: formattedValue
                  });
                }
            }
            .bind(this),
            10000
        );
    }
    */

  };


  handleNumberInputChange = (event) => {
  
    var numberVar = event.target.value.replace(/[^\d.-]/g, '');
  
    numberVar = numberVar.replace('....','.').replace('...','..').replace('..','.');

    var formattedValue = Intl.NumberFormat().format(numberVar);
    
    this.setState({
      [event.target.name]: formattedValue
    });

    var key = event.target.name+"AsNumber"
    var val = Number(numberVar);  
    var obj  = {}
    obj[key] = val
    this.setState(obj, () => {
      // Do anything after the state has changed   
    
      if (event.target.name == "livingAreaSize")
      {
          this.CalculateRehabCosts();
          this.CalculatePricePerSqft();
      }

  });
  
  };

  handleYearInputChange = (event) => {
  
    var numberVar = event.target.value.replace(/[^\d.-]/g, '');
  
    numberVar = numberVar.replace('....','.').replace('...','..').replace('..','.');

    var formattedValue = Intl.NumberFormat().format(numberVar);

    formattedValue = formattedValue.replace(',','').replace(',','');
    
    if (formattedValue.length <=4)
    {
      if (formattedValue =='0')
      {
        formattedValue='';
      }
      this.setState({
        [event.target.name]: formattedValue
      });

      var key = event.target.name+"AsNumber"
      var val = Number(numberVar);  
      var obj  = {}
      obj[key] = val
      this.setState(obj)
    }

  };

  handlePropertyLinkInputChange = (event) => {
    
        this.setState({
          [event.target.name]: event.target.value
        }, () => {
        
          if (event.target.value != '' && event.target.value.toLowerCase().startsWith("http"))
          {
            document.querySelector('#propertyDetailLink').style.visibility = "visible";
  
          } else
          {
            document.querySelector('#propertyDetailLink').style.visibility = "hidden  ";
          }
    
        });
    
        
      };
    
  
    handleTextAreaInputChange = (event) => {
    
        this.setState({
          [event.target.name]: event.target.value
        }, () => {
        
        
        });
    
        
      };
  

  handleInputChange = (event) => {
  
    var formattedValue = event.target.value;
   // if (formattedValue =="") // This is not needed and instruces a bug
   // {
   //   formattedValue = "$0"; // This is not needed and instruces a bug
   // }

   let propertyName = event.target.name;

    //Cashflow column have Column at the name used when hiding/showing, remove it
    if (propertyName.endsWith("CashFlowColumn"))
    {
      propertyName = propertyName.replace("Column","");
    }

    //console.log("propertyName:");
    //console.log(propertyName);

    //console.log("formattedValue:");
    //console.log(formattedValue);

    this.setState({
      [propertyName]: formattedValue
    }, () => {
    
      //If number currency only use number value
      if (formattedValue.startsWith("$"))
      {
        var key = propertyName+"AsNumber"
        var val = Number(formattedValue.replace(/[^0-9\.-]+/g,""));  
        var obj  = {}

        //console.log("key:");
        //console.log(key);
    
        //console.log("val:");
        //console.log(val);

        obj[key] = val
        this.setState(obj, () => {
            // Do anything after the state has changed  
            
            //console.log("AFTER KEY:");  
            //console.log(this.state[key]);

          this.HandleAdditionalSpecificLogic(event); 
        });
      
      } 

    });

    
    
	};

  HandleAdditionalSpecificLogic(event)
  {
    
    //Now we handle additional specific field changes - Should be the exception.
    if (event.target.name.startsWith('downPayment') || event.target.name.startsWith('propertyPrice'))
    {
      this.CalculateDownpaymentPercent();
    }

    if (event.target.name.startsWith('closingCosts') || event.target.name.startsWith('downPayment') || event.target.name.startsWith('propertyPrice'))
    {
      this.CalculateClosingCostsPercent();
    }

    if (event.target.name.startsWith('propertyPrice'))
    {
      this.CalculatePricePerSqft();
    }

    if (event.target.name.startsWith('marketValue') || event.target.name.startsWith('propertyPrice'))
    {
      this.CalculateMarketValuePercent();
    }

    if (event.target.name.startsWith('downPayment') || event.target.name.startsWith('propertyPrice') || event.target.name.startsWith('interestRate'))
    {
       this.CalculateMonthlyPayment();
    }

    if (event.target.name.startsWith('downPayment') || event.target.name.startsWith('propertyPrice') || event.target.name.startsWith('interestRate')  || event.target.name.startsWith('term') )
    {
       this.CalculateRentalSaleProceeds();
    }

    if (event.target.name.startsWith('annualAppreciation'))
    {
      //Reset the manual and recaculate
      this.setState({          
        autoCalculateSaleProceeds: true
      }, () => {
        
        this.CalculateRentalSaleProceeds();
  
      });
    }

    
    if (event.target.name.startsWith('otherMonthlyBill')  || event.target.name.startsWith('autoInsurance')  || event.target.name.startsWith('cable')  || event.target.name.startsWith('carMaintenance')  || event.target.name.startsWith('carPayment')  || event.target.name.startsWith('cellPhone')  || event.target.name.startsWith('charitableDonations')   || event.target.name.startsWith('childCare')   || event.target.name.startsWith('childSupport')   || event.target.name.startsWith('clothing')  || event.target.name.startsWith('creditCard')   || event.target.name.startsWith('dining')  || event.target.name.startsWith('entertainment')   || event.target.name.startsWith('gasoline')  || event.target.name.startsWith('electric')  || event.target.name.startsWith('groceries')   || event.target.name.startsWith('insuranceHealthMedical')   || event.target.name.startsWith('internet')  || event.target.name.startsWith('lifeInsurance')   || event.target.name.startsWith('medicalDentalVision')   || event.target.name.startsWith('phone')   || event.target.name.startsWith('recreationVacation')  || event.target.name.startsWith('studentLoan')   || event.target.name.startsWith('tuition')   || event.target.name.startsWith('water'))
    {
      this.CalculateMonthlyBillsFromBreakdown();
    }

    if (event.target.name.startsWith('principalAndInterest') || event.target.name.startsWith('propertyTaxes') || event.target.name.startsWith('homeownersInsurance')  || event.target.name.startsWith('hoa')  || event.target.name.startsWith('otherMonthlyPayment'))
    {
      this.CalculateMortgagePaymentFromBreakdown();
    }

    if (event.target.name.startsWith('rentalMaintenance') || event.target.name.startsWith('rentalUtilities') || event.target.name.startsWith('rentalPropertyManagement') || event.target.name.startsWith('rentalBookkeeping') || event.target.name.startsWith('rentalTaxFiling') || event.target.name.startsWith('rentalOtherExpenses'))
    {
      this.CalculateMonthlyExpensesFromBreakdown();
    }

    if (event.target.name.startsWith('rentalAppraisalFees') || event.target.name.startsWith('rentalHomeInspection') || event.target.name.startsWith('rentalTenantScreening') || event.target.name.startsWith('rentalMarketing') || event.target.name.startsWith('rentalOtherInitial'))
    {
      this.CalculateInitialExpensesFromBreakdown();
    }

    if (event.target.name.startsWith('monthlyRent') || event.target.name.startsWith('rentalMaintenance') || event.target.name.startsWith('rentalPropertyManagement'))
    {
        this.CalculateRentExpensePercentages();
    }

    if (event.target.name.startsWith('monthlyRent'))
    {
      this.CalculateMonthlyExpenses();
    }

    if (event.target.name.startsWith('monthlyPayment') ||
       event.target.name.startsWith('principalAndInterest') || 
       event.target.name.startsWith('propertyTaxes') || 
       event.target.name.startsWith('homeownersInsurance') || 
       event.target.name.startsWith('hoa') || 
       event.target.name.startsWith('otherMonthlyPayment') 
    )
    {
      this.CalculateHoldingCosts();
    }

    if (event.target.name.startsWith('afterRepairValue'))
    {
      this.CalculateSellingCosts();
    }


    if (event.target.name.startsWith('rehabCosts'))
    {
      this.StopCalculatingRehabCostsAutomatically(event);
    }

    if (event.target.name.startsWith('sellingCosts'))
    {
      this.StopCalculatingSellingCostsAutomatically(event);
      this.CalculateSellingCostsPercent();

    }

    if (event.target.name.startsWith('monthlyExpenses') ||
       event.target.name.startsWith('rentalMaintenance') || 
       event.target.name.startsWith('rentalPropertyManagement') ||
       event.target.name.startsWith('rentalUtilities') ||
       event.target.name.startsWith('rentalBookkeeping') ||
       event.target.name.startsWith('rentalTaxFiling') ||
       event.target.name.startsWith('rentalOtherExpenses') 
       ) 
    {
      this.StopCalculatingMonthlyExpensesAutomatically(event);
    }

    
    if (event.target.name.startsWith('initialExpenses') ||
       event.target.name.startsWith('rentalAppraisalFees') || 
       event.target.name.startsWith('rentalHomeInspection') ||
       event.target.name.startsWith('rentalTenantScreening') ||
       event.target.name.startsWith('rentalMarketing') ||
       event.target.name.startsWith('rentalOtherInitialExpenses') 
       ) 
    {
      this.StopCalculatingInitialExpensesAutomatically(event);
    }

    
    if (event.target.name.startsWith('holdingCosts'))
    {
      this.StopCalculatingHoldingCostsAutomatically(event);
    }
    
    if (event.target.name.startsWith('flippingUtilities') ||
       event.target.name.startsWith('flippingMaintenance') || 
       event.target.name.startsWith('flippingOtherExpenses') || 
       event.target.name.startsWith('monthlyPayment')   
       ) 
    {
      this.StartCalculatingHoldingCostsAutomatically(event);
    }

    if (event.target.name.startsWith('monthlyPayment'))
    {
      this.ResetMonthlyPaymentBreakdownFields(event);     
    }

    if (event.target.name.startsWith('monthlyBills'))
    {
      this.ResetMonthlyBillsBreakdownFields(event);     
    }
    
    if (event.target.name.startsWith('propertyPrice')
        ||
        event.target.name.startsWith('monthlyPayment')
        ||
        event.target.name.startsWith('monthlyRent')
        ||
        event.target.name.startsWith('rentalUtilities')
        ||
        event.target.name.startsWith('rentalMaintenance')
        ||
        event.target.name.startsWith('rentalUtilities')
        ||
        event.target.name.startsWith('rentalPropertyManagement')
        ||
        event.target.name.startsWith('rentalBookkeeping')
        ||
        event.target.name.startsWith('rentalOtherExpenses')
        ||
        event.target.name.startsWith('rentalTaxFiling')
        ||
        event.target.name.startsWith('monthlyExpenses')
        ||
        event.target.name.startsWith('expenseRateOfGrowth') 
        || 
        event.target.name.startsWith('rentRateOfGrowth') 
        || 
        event.target.name.startsWith('vacancyRate')
     //   ||
     //   event.target.name.startsWith('projectedSaleProceeds') //We won't include the sales proceeds in the last cash flow, it confuses things
    )
    {
      this.CalculateFutureCashFlows();
    }

    if (event.target.name.startsWith('projectedSaleProceeds'))
    {
      this.StopCalculatingSaleProceedsAutomatically(event);
    }

    //This logic is to stop auto calculating rental years cashflows if any yearcash flow is entered manually
    const projectedYearOfSale = parseInt(this.state.projectedYearOfSale.toLowerCase().replace("year","").trim());
    for (let x = 0; x < projectedYearOfSale; x = x + 1) {
      const fieldName = "year"+x+"CashFlow";

      if (event.target.name.startsWith(fieldName))
      {
         this.StopCalculatingYearCashFlowsAutomatically(event);
      }

    }

  }

  CalculateFutureCashFlows()
  {
   // console.log("------------------------------------");
   // console.log("Inside CalculateFutureCashFlows()");
   // console.log("autoCalculateYearCashFlows:"+this.state.autoCalculateYearCashFlows);
   // console.log("investmentType:"+this.state.investmentType );
   // console.log("expenseRateOfGrowthAsNumber:"+this.state.expenseRateOfGrowthAsNumber);
   // console.log("rentRateOfGrowthAsNumber:"+this.state.rentRateOfGrowthAsNumber);
   // console.log("vacancyRateAsNumber:"+this.state.vacancyRateAsNumber);
   // console.log("monthlyExpensesAsNumber:"+this.state.monthlyExpensesAsNumber);
   // console.log("monthlyPaymentAsNumber:"+this.state.monthlyPaymentAsNumber);

    if (this.state.autoCalculateYearCashFlows)
    {
   
        if (this.state.investmentType == "Rental Property" || this.state.forceRecalculateFutureCashFlows == true)
        {
     
          if (this.state.expenseRateOfGrowthAsNumber != 0 && 
            this.state.rentRateOfGrowthAsNumber != 0 && 
            this.state.vacancyRateAsNumber != 0 && 
            this.state.monthlyExpensesAsNumber > 0 && 
            this.state.monthlyPaymentAsNumber > 0)
            {
     
              const property = this.GetProperty();
              const projectedYearOfSale = parseInt(property.projectedYearOfSale.toLowerCase().replace("year","").trim());

              const settings =JSON.parse(JSON.stringify(this.GetBenchmarkSettings(this.state.settings))); // clone it since need budget based on the profile value and trending price per sqft
              const profile = this.state?.profile;
            
              //Needs to come from profile
              settings.budgetAccepted=profile?.budget;   
              settings.budgetRejected=profile?.budget;   
              settings.budgetAcceptedCompareId=5;
              settings.budgetRejectedCompareId=1;

              //Needs to come from property
              settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
              settings.pricePerSizeRejected = property.trendingPricePerSqft;
              settings.pricePerSizeAcceptedCompareId =  5;
              settings.pricePerSizeRejectedCompareId =  1;
              
              const projectedFutureCashFlows = BLO.getEstimatedFutureCashFlows(property,projectedYearOfSale,this.state.projectedSaleProceedsAsNumber,settings);
     
              const propertyCashFlowMaps = new Map(); // This is a convenient way add put all the maps in one collection to pass it to the AnalysisDialog to help display cashflow details
                                                      // It is not mission critical

              for (let i = 1; i <= projectedYearOfSale; i++) {

                const annualCashFlow = projectedFutureCashFlows.get(i).get("VAR_ANNUAL_CASH_FLOW");
                const annualCashFlowAsNumber = projectedFutureCashFlows.get(i).get("VAR_ANNUAL_CASH_FLOW_AS_NUMBER");

                var key = "year"+i+"CashFlow";  
                var val = annualCashFlow;  
                var obj  = {}
                obj[key] = val
                this.setState(obj, () => {
                    // Do anything after the state has changed   
                  
                });

                var key2 = "year"+i+"CashFlowAsNumber"; 
                var val2 = annualCashFlowAsNumber;  
                var obj2  = {}
                obj2[key2] = val2
                this.setState(obj2, () => {
                    // Do anything after the state has changed   
                  
                });

                var key3 = "year"+i+"CashFlowMap"; 
                var val3 = projectedFutureCashFlows.get(i);  
                var obj3  = {}
                obj3[key3] = val3
                this.setState(obj3, () => {
                    // Do anything after the state has changed   
                  
                });

                propertyCashFlowMaps.set(i,projectedFutureCashFlows.get(i));

                this.setState({          
                  propertyCashFlowMaps: propertyCashFlowMaps
                });

              }
                this.setState({forceRecalculateFutureCashFlows : false});
            }
        }
      }
  }

  StopCalculatingYearCashFlowsAutomatically(event) // As soon as the user change the rehab costs manually, stop auto calculating it
  {
    this.setState({          
      autoCalculateYearCashFlows: false,
      propertyCashFlowMaps: null
    });
  }

  StopCalculatingSaleProceedsAutomatically(event) // As soon as the user change the rehab costs manually, stop auto calculating it
  {
    this.setState({          
      autoCalculateSaleProceeds: false
    });
  }


  ResetMonthlyPaymentBreakdownFields(event)
  {
    this.setState({      
      principalAndInterest:'$0',
      principalAndInterestAsNumber:0,
      propertyTaxes:'$0',
      propertyTaxesAsNumber:0,
      homeownersInsurance:'$0',
      homeownersInsuranceAsNumber:0,
      hoa:'$0',
      hoaAsNumber:0,
      otherMonthlyPayment:this.state.monthlyPayment,
      otherMonthlyPaymentAsNumber:this.state.monthlyPaymentAsNumber
    });

  }

  
  ResetMonthlyBillsBreakdownFields(event)
  {
    this.setState({      
      autoInsurance:'$0',
      autoInsuranceAsNumber:0,
      cable:'$0',
      cableAsNumber:0,
      carMaintenance:'$0',
      carMaintenanceAsNumber:0,
      carPayment:'$0',
      carPaymentAsNumber:0,
      cellPhone:'$0',
      cellPhoneAsNumber:0,
      charitableDonations:'$0',
      charitableDonationsAsNumber:0,
      childCare:'$0',
      childCareAsNumber:0,
      childSupport:'$0',
      childSupportAsNumber:0,
      clothing:'$0',
      clothingAsNumber:0,
      creditCard:'$0',
      creditCardAsNumber:0,
      dining:'$0',
      diningAsNumber:0,
      entertainment:'$0',
      entertainmentAsNumber:0,
      gasoline:'$0',
      gasolineAsNumber:0,
      electric:'$0',
      electricAsNumber:0,
      groceries:'$0',
      groceriesAsNumber:0,
      insuranceHealthMedical:'$0',
      insuranceHealthMedicalAsNumber:0,
      internet:'$0',
      internetAsNumber:0,
      lifeInsurance:'$0',
      lifeInsuranceAsNumber:0,
      medicalDentalVision:'$0',
      medicalDentalVisionAsNumber:0,
      phone:'$0',
      phoneAsNumber:0,
      recreationVacation:'$0',
      recreationVacationAsNumber:0,
      studentLoan:'$0',
      studentLoanAsNumber:0,
      tuition:'$0',
      tuitionAsNumber:0,
      water:'$0',
      waterAsNumber:0,
      otherMonthlyBill:this.state.monthlyBills,
      otherMonthlyBillAsNumber:this.state.monthlyBillsAsNumber
    });

  }

  StopCalculatingRehabCostsAutomatically(event) // As soon as the user change the rehab costs manually, stop auto calculating it
  {
    this.setState({          
      autoCalculateRehabCosts: false
    });
  }

  StopCalculatingSellingCostsAutomatically(event) // As soon as the user change the selling costs manually, stop auto calculating it
  {
    this.setState({          
      autoCalculateSellingCosts: false
    });
  }
  
  StartCalculatingHoldingCostsAutomatically(event) 
  {
    this.setState({          
      autoCalculateHoldingCosts: true
    }, () => {
      
      this.CalculateHoldingCosts();

    });

  }


  StopCalculatingHoldingCostsAutomatically(event) // As soon as the user change the holding costs manually, stop auto calculating it
  {
    this.setState({          
      autoCalculateHoldingCosts: false
    });

    if (event.target.name.startsWith('holdingCosts')) 
    {
      const other = this.state.holdingCostsAsNumber - this.state.monthlyPayment;
      this.setState({      
        flippingUtilities:'$0',
        flippingUtilitiesAsNumber:0,
        flippingMaintenance:'$0',
        flippingMaintenanceAsNumber:0,
        flippingOtherExpenses: this.FormatMoney(other),
        flippingOtherExpensesAsNumber:other
      });
    }
  }

  StopCalculatingInitialExpensesAutomatically(event) // As soon as the user change the monthly expenses manually, stop auto calculating it
  {
    this.setState({          
      autoCalculateInitialExpenses: false
    });

    //If the monthly expense is set manually then set all the breakdown values to zero except other
    if (event.target.name.startsWith('initialExpenses')) 
    {
      this.setState({          
        rentalAppraisalFees: '$0',
        rentalAppraisalFeesAsNumber: 0,
        rentalHomeInspection: '$0',
        rentalHomeInspectionAsNumber: 0,
        rentalTenantScreening: '$0',
        rentalTenantScreeningAsNumber: 0,
        rentalMarketing: '$0',
        rentalMarketingAsNumber: 0,
        rentalOtherInitialExpenses: this.state.initialExpenses,
        rentalOtherInitialExpensesAsNumber: this.state.initialExpensesAsNumber
      });
   
    }
  }


  StopCalculatingMonthlyExpensesAutomatically(event) // As soon as the user change the monthly expenses manually, stop auto calculating it
  {
    this.setState({          
      autoCalculateMonthlyExpenses: false
    });

    //If the monthly expense is set manually then set all the breakdown values to zero except other
    if (event.target.name.startsWith('monthlyExpenses')) 
    {
      //const other =  parseInt(this.state.monthlyExpensesAsNumber) - parseInt(this.state.monthlyPayment);

      this.setState({          
        rentalMaintenance: '$0',
        rentalMaintenanceAsNumber: 0,
        rentalMaintenancePercent:'0%',
        rentalPropertyManagement: '0',
        rentalPropertyManagementAsNumber: 0,
        rentalPropertyManagementPercent:'0%',
        rentalUtilities: '$0',
        rentalUtilitiesAsNumber: 0,
        rentalBookkeeping: '$0',
        rentalBookkeepingAsNumber: 0,
        rentalTaxFiling: '$0',
        rentalTaxFilingAsNumber: 0,
        rentalOtherExpenses: this.state.monthlyExpenses,
        rentalOtherExpensesAsNumber: this.state.monthlyExpensesAsNumber

        //rentalOtherExpenses: this.FormatMoney(other),
        //rentalOtherExpensesAsNumber: other
      });
   
    }
  }

  CalculateRentExpensePercentages()
  {
        //recalculate rentalMaintenance %
        if (this.state.monthlyRentAsNumber > 0 && this.state.rentalMaintenanceAsNumber > 0)
        {
          var percent = parseInt((this.state.rentalMaintenanceAsNumber * 100)/this.state.monthlyRentAsNumber);
          this.setState({          
            rentalMaintenancePercent: percent+"%"
         });
        }

         //recalculate rentalPropertyManagement %
         if (this.state.monthlyRentAsNumber > 0 && this.state.rentalPropertyManagementAsNumber > 0)
         {
      
           var percent = parseInt((this.state.rentalPropertyManagementAsNumber * 100)/this.state.monthlyRentAsNumber);
           this.setState({          
            rentalPropertyManagementPercent: percent+"%"
          });
         }
  }


  InitializeHoldingCostsBreakdownFields()
  {

    let utilities = this.state.flippingUtilitiesAsNumber;
    if (utilities == 0)
    {
      utilities = this.state.settings?.utilitiesMonthlyCost;
    }

    let maintenance = this.state.flippingMaintenanceAsNumber;
    if (maintenance == 0)
    {
      maintenance = this.state.settings?.maintenanceFlippingCosts;
    }
   
    this.setState({          
      flippingUtilities:this.FormatMoney(utilities),
      flippingUtilitiesAsNumber:utilities,
      flippingMaintenance:this.FormatMoney(maintenance),
      flippingMaintenanceAsNumber:maintenance
    }, () => {
  
      if (this.state.holdingCostsAsNumber ==0 && (this.state.flippingUtilitiesAsNumber != 0 || this.state.flippingMaintenanceAsNumber != 0 ||  this.state.flippingOtherExpensesAsNumber !=0))
      {
        this.CalculateHoldingCostsFromBreakdown();
      }
    });
  }

  CalculateHoldingCosts()
  {

    if (this.state.autoCalculateHoldingCosts)
    {
      this.CalculateHoldingCostsFromBreakdown();
    }
  }

  CalculateSellingCosts()
  {
    if (this.state.autoCalculateSellingCosts)
    {
      if (this.state.afterRepairValueAsNumber > 0)
      {
        const sellingCosts = (this.state.afterRepairValueAsNumber * this.state.settings?.sellingCostPercent)/100;
        
        this.setState({          
          sellingCosts: this.FormatMoney(sellingCosts),
          sellingCostsAsNumber: sellingCosts
        }, () => {
          //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
        });
      } 
    }
  }

  CalculateFlippingTrendingPricePerSqft() //NOTE: For Flipping properties we don't lookup the trendigPricePerSqt since we aready 
  {                                       // have similarProperties we can use to calculate it.
      try
      {
        if (this.state.investmentType == "Flipping Property")
        {
          if (this.state.similarSoldProperties != null)
          {
            let totalSqFt = 0;
            let trendingPricePerSqFt = 0;

            for (let similarProperty of this.state.similarSoldProperties) {

              totalSqFt = totalSqFt + (similarProperty.price/similarProperty.sqFt);
            }

            if (this.state.similarSoldProperties.length > 0)
            {
              trendingPricePerSqFt = totalSqFt / this.state.similarSoldProperties.length;

              trendingPricePerSqFt = parseInt(trendingPricePerSqFt);

              this.setState({
                trendingPricePerSqft: this.FormatMoney(trendingPricePerSqFt),
                trendingPricePerSqftAsNumber: trendingPricePerSqFt
              });

            }
          }
        }

      } catch(error)
      {

      } 
  }

  CalculateMonthlyExpenses()
  {
    
    if (this.state.autoCalculateMonthlyExpenses)
    {
      const rentalMaintenance = parseInt((this.state.monthlyRentAsNumber * this.state.settings?.maintenanceRentPercent)/100);
      const rentalPropertyManagement = parseInt((this.state.monthlyRentAsNumber * this.state.settings?.propertyManagementPercent)/100);


      if (this.state.settings != null)
      {
          this.state.settings.utilitiesMonthlyCost    = parseFloat(this.state.settings.utilitiesMonthlyCost);
          this.state.settings.bookkeepingMonthlyCost  = parseFloat(this.state.settings.bookkeepingMonthlyCost);
          this.state.settings.taxFilingMonthlyCost    = parseFloat(this.state.settings.taxFilingMonthlyCost);
      }

      this.setState({          
        rentalMaintenance: this.FormatMoney(rentalMaintenance),
        rentalMaintenanceAsNumber: rentalMaintenance,
        rentalMaintenancePercent: this.state.settings?.maintenanceRentPercent.toString()+"%",
        rentalPropertyManagement: this.FormatMoney(rentalPropertyManagement),
        rentalPropertyManagementAsNumber: rentalPropertyManagement,
        rentalPropertyManagementPercent: this.state.settings?.propertyManagementPercent.toString()+"%",
        rentalUtilities: this.FormatMoney(this.state.settings?.utilitiesMonthlyCost),
        rentalUtilitiesAsNumber: this.state.settings?.utilitiesMonthlyCost,
        rentalBookkeeping: this.FormatMoney(this.state.settings?.bookkeepingMonthlyCost),
        rentalBookkeepingAsNumber: this.state.settings?.bookkeepingMonthlyCost,
        rentalTaxFiling: this.FormatMoney(this.state.settings?.taxFilingMonthlyCost),
        rentalTaxFilingAsNumber: this.state.settings?.taxFilingMonthlyCost

      }, () => {
      
        this.CalculateMonthlyExpensesFromBreakdown();
        //this.CheckAllRequiredFieldsAvaialbleForAnalysis();

      });
   
    }
  }

  CalculatePricePerSqft()
  {
    if (this.state.propertyPriceAsNumber > 0 && this.state.livingAreaSizeAsNumber > 0)
    {
       const pricePerSqft = parseInt(this.state.propertyPriceAsNumber / this.state.livingAreaSizeAsNumber);

       this.setState({          
        pricePerSqft: this.FormatMoney(pricePerSqft),
        pricePerSqftAsNumber:pricePerSqft
      }, () => {
        
      });
    }
  }

  CalculateRehabCosts()
  {
    if (this.state.autoCalculateRehabCosts)
    {
      let costPerSqFt = 0;

      try
      {
          if (this.state.rehabType == "Cosmetic Rehab")
          {
            costPerSqFt = parseFloat(this.state.settings?.cosmeticRehabPerSqFt);

          }else if (this.state.rehabType == "Heavy Rehab")
          {
            costPerSqFt = parseFloat(this.state.settings?.heavyRehabPerSqFt);

          }if (this.state.rehabType == "Full Gut Rehab")
          {
            costPerSqFt = parseFloat(this.state.settings?.fullGutRehabPerSqFt);
          }
      } catch(err)
      {
        console.log(err);
      }

      const rehabCosts = this.state.livingAreaSizeAsNumber * costPerSqFt;

      this.setState({          
        rehabCosts: this.FormatMoney(rehabCosts),
        rehabCostsAsNumber: rehabCosts
      }, () => {
        //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
      });

    }
  }

  CalculatePropertyPricePerSqftAverage(similarProperties)
  {
    
    let pricePerSqftAverage = 0;
    let pricePerSqftAverageFormatted = "";

    try
    {

  //    alert(JSON.stringify(similarProperties));
      
      let total = 0;
      for (let property of similarProperties)
      {
        let pricePerSqft = this.ConvertToNumber(property.price) / this.ConvertToNumber(property.sqFt);

        pricePerSqft = parseInt(pricePerSqft);

        total = total + pricePerSqft;
      }
   
      pricePerSqftAverage = total/similarProperties.length;

      pricePerSqftAverage = parseInt(pricePerSqftAverage);

      pricePerSqftAverageFormatted = this.FormatMoney(pricePerSqftAverage);

    }catch(error)
    {
       console.log(error);
    }


    return pricePerSqftAverageFormatted;
  }

  CalculatePropertyPricePerSqft(property)
  {
     let pricePerSqft = "";

     try
     {
        
        pricePerSqft = this.ConvertToNumber(property.price) / this.ConvertToNumber(property.sqFt);

        pricePerSqft = parseInt(pricePerSqft);

        pricePerSqft = this.FormatMoney(pricePerSqft);

     }catch(error)
     {
        console.log(error);
     }

     return pricePerSqft;
  }

  CalculateInitialExpenses()
  {
    if (this.state.autoCalculateInitialExpenses)
    {
     
      try
      {
        if (this.state.settings != null)
        {
          this.state.settings.appraisalFeeCost   = parseFloat(this.state.settings.appraisalFeeCost);
          this.state.settings.homeInspectionCost = parseFloat(this.state.settings.homeInspectionCost);
          this.state.settings.tenantScreeningCost= parseFloat(this.state.settings.tenantScreeningCost);
          this.state.settings.marketingCost      = parseFloat(this.state.settings.marketingCost);
        }
      } catch(err)
      {
        
      }

      this.setState({          
        rentalAppraisalFees: this.FormatMoney(this.state.settings?.appraisalFeeCost),
        rentalAppraisalFeesAsNumber: this.state.settings?.appraisalFeeCost,
        
        rentalHomeInspection: this.FormatMoney(this.state.settings?.homeInspectionCost),
        rentalHomeInspectionAsNumber: this.state.settings?.homeInspectionCost,

        rentalTenantScreening: this.FormatMoney(this.state.settings?.tenantScreeningCost),
        rentalTenantScreeningAsNumber: this.state.settings?.tenantScreeningCost,

        rentalMarketing: this.FormatMoney(this.state.settings?.marketingCost),
        rentalMarketingAsNumber: this.state.settings?.marketingCost

      }, () => {
      
        this.CalculateInitialExpensesFromBreakdown();
        //this.CheckAllRequiredFieldsAvaialbleForAnalysis();

      });
   
    }
  }


  CalculateMonthlyBillsFromBreakdown()
  {
    
        var monthlyBills = parseInt(this.state.otherMonthlyBillAsNumber)+parseInt(this.state.autoInsuranceAsNumber)+parseInt(this.state.cableAsNumber)+parseInt(this.state.carMaintenanceAsNumber)+parseInt(this.state.carPaymentAsNumber)+parseInt(this.state.cellPhoneAsNumber)+ parseInt(this.state.charitableDonationsAsNumber)+ parseInt(this.state.childCareAsNumber)+parseInt(this.state.childSupportAsNumber)+parseInt(this.state.clothingAsNumber)+parseInt(this.state.creditCardAsNumber)+parseInt(this.state.diningAsNumber)+parseInt(this.state.entertainmentAsNumber)+parseInt(this.state.gasolineAsNumber)+parseInt(this.state.electricAsNumber)+parseInt(this.state.groceriesAsNumber)+parseInt(this.state.insuranceHealthMedicalAsNumber)+parseInt(this.state.internetAsNumber)+parseInt(this.state.lifeInsuranceAsNumber)+parseInt(this.state.medicalDentalVisionAsNumber)+parseInt(this.state.phoneAsNumber)+parseInt(this.state.recreationVacationAsNumber)+parseInt(this.state.studentLoanAsNumber)+parseInt(this.state.tuitionAsNumber)+parseInt(this.state.waterAsNumber);

        this.setState({          
          monthlyBills: this.FormatMoney(monthlyBills),
          monthlyBillsAsNumber: monthlyBills
        }, () => {
          
        });
  }

  CalculateMortgagePaymentFromBreakdown()
  {


      var monthlyPayment = parseInt(this.state.principalAndInterestAsNumber)+parseInt(this.state.propertyTaxesAsNumber)+parseInt(this.state.homeownersInsuranceAsNumber)+parseInt(this.state.hoaAsNumber)+parseInt(this.state.otherMonthlyPaymentAsNumber);

      this.setState({          
        monthlyPayment: monthlyPayment,
        monthlyPaymentAsNumber: monthlyPayment
          }, () => {
          
        this.CalculateMonthlyExpensesFromBreakdown();

      });

  }

  
    CalculateHoldingCostsFromBreakdown()
  {
    const holdingCosts = parseInt(this.state.flippingUtilitiesAsNumber) + parseInt(this.state.flippingMaintenanceAsNumber) + parseInt(this.state.flippingOtherExpensesAsNumber) + parseInt(this.state.monthlyPaymentAsNumber);

    if (!isNaN(holdingCosts))
    {
      this.setState({          
        holdingCosts: this.FormatMoney(holdingCosts),
        holdingCostsAsNumber: holdingCosts
      }, () => {
        //this.CheckAllRequiredFieldsAvaialbleForAnalysis();
      });
    }

  }


  CalculateMonthlyExpensesFromBreakdown()
  {

    var monthlyExpenses = parseInt(this.state.rentalMaintenanceAsNumber)+parseInt(this.state.rentalUtilitiesAsNumber)+parseInt(this.state.rentalPropertyManagementAsNumber)+parseInt(this.state.rentalBookkeepingAsNumber)+parseInt(this.state.rentalTaxFilingAsNumber)+parseInt(this.state.rentalOtherExpensesAsNumber)+parseInt(this.state.monthlyPaymentAsNumber);

    this.setState({          
      monthlyExpenses: monthlyExpenses,
      monthlyExpensesAsNumber: monthlyExpenses
  });

  }

  CalculateInitialExpensesFromBreakdown()
  {
    var initialExpenses = parseInt(this.state.rentalAppraisalFeesAsNumber)+
                          parseInt(this.state.rentalHomeInspectionAsNumber)+
                          parseInt(this.state.rentalTenantScreeningAsNumber)+
                          parseInt(this.state.rentalMarketingAsNumber)+
                          parseInt(this.state.rentalOtherInitialExpensesAsNumber);

    this.setState({          
      initialExpenses: initialExpenses,
      initialExpensesAsNumber: initialExpenses
    });

  }

  CalculateRentalSaleProceeds()
  {
    //alert("Calculating Sales Proceeds");
    
    if (this.state.autoCalculateSaleProceeds)
    {
      if (this.state.propertyPriceAsNumber > 0 && 
          this.state.interestRateAsNumber && 
          this.state.term !='_' && 
          this.state.repaymentType != '_' &&
          this.state.projectedYearOfSale != '_')
          {

            const loanAmount            = this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber;
            const annualInterestRate    = this.state.interestRateAsNumber;
            const loanTermYears         = this.state.termAsNumber;
            const yearOfSale            = parseInt(this.state.projectedYearOfSale.toLowerCase().replace("year","").trim());
            const purchasePrice         = this.state.propertyPriceAsNumber;
            const annualHomeAppreciation= this.state.annualAppreciationAsNumber; 
            const financingType         = this.state.financingType;

            const result = BLO.getNetProfitFromFutureSale(yearOfSale,loanAmount,annualInterestRate, loanTermYears, purchasePrice,annualHomeAppreciation,financingType,this.state.settings);
            const netProfitsFromFutureSale = result.netProfitsFromFutureSale;

            this.setState({          
              projectedSaleProceeds: this.FormatMoney(netProfitsFromFutureSale),
              projectedSaleProceedsAsNumber: netProfitsFromFutureSale,
              projectedSaleProceedsMap: result.helpVariableMap
            }, () => {
              this.CalculateFutureCashFlows();
            });
            

            
          }
    }
  }
 
  CalculateMonthlyPayment()
  {
      if (this.state.financingType == 'Loan' && this.state.propertyPriceAsNumber > 0 && this.state.interestRateAsNumber >0 && this.state.term !='_' && this.state.repaymentType != '_')
      {

          if (this.state.loanType == 'Fixed' && this.state.repaymentType == 'Principal & Interest')
          {

            this.CalculateMortgagePayment_Fixed_Principal_Interest();

          } else if (this.state.loanType == 'Fixed' && this.state.repaymentType == 'Interest Only')
          {
            
            this.CalculateMortgagePayment_Fixed_Interest_Only();
            
          } else if (this.state.loanType == 'Variable' && this.state.repaymentType == 'Principal & Interest')
          {
            
            this.CalculateMortgagePayment_Variable_Principal_Interest();
            
          } else if (this.state.loanType == 'Variable' && this.state.repaymentType == 'Interest Only')
          {
            
            this.CalculateMortgagePayment_Variable_Interest_Only();  
          } 

      }

  }

  CalculateMortgagePayment_Fixed_Principal_Interest()
  {

    //alert("Fixed - Principal & Interest");
  // Get the user's input from the form. Assume it is all valid.
    // Convert interest from a percentage to a decimal, and convert from
    // an annual rate to a monthly rate. Convert payment period in years
    // to the number of monthly payments.
    var principal = this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber;
    var interest = this.state.interestRateAsNumber / 100 / 12;
    var payments = this.state.termAsNumber * 12;

    // Now compute the monthly payment figure, using esoteric math.
    var x = Math.pow(1 + interest, payments);
    var monthly = (principal*x*interest)/(x-1);

    // Check that the result is a finite number. If so, display the results.
    if (!isNaN(monthly) && 
        (monthly != Number.POSITIVE_INFINITY) &&
        (monthly != Number.NEGATIVE_INFINITY)) {

        var principalAndInterest = this.round(monthly);
        var totalPayments = this.round(monthly * payments);
        var totalInterest = this.round((monthly * payments) - principal);

        //alert(monthlyPayment);

        //var propertyTaxesAsNumber = this.state.propertyTaxesAsNumber;
        //var propertyTaxes = this.state.propertyTaxes;

        //if (propertyTaxesAsNumber ==0)
        //{
        //  propertyTaxesAsNumber = this.GetPropertyTaxes();
        //  propertyTaxes = this.FormatMoney(propertyTaxesAsNumber);
        //}

        /*
        console.log("this.state.propertyPriceAsNumber:"+this.state.propertyPriceAsNumber);
        console.log("this.state.downPaymentAsNumber:"+this.state.downPaymentAsNumber);
        console.log("this.state.interestRateAsNumber:"+this.state.interestRateAsNumber);
        console.log("this.state.termAsNumber:"+this.state.termAsNumber);

        console.log("principalAndInterest:"+principalAndInterest);
        console.log("this.state.propertyTaxesAsNumber:"+this.state.propertyTaxesAsNumber);
        console.log("this.state.homeownersInsuranceAsNumber:"+this.state.homeownersInsuranceAsNumber);
        console.log("this.state.hoaAsNumber:"+this.state.hoaAsNumber);
        console.log("this.state.otherMonthlyPaymentAsNumber:"+this.state.otherMonthlyPaymentAsNumber);
        */

        this.setState({  
          otherMonthlyPaymentAsNumber:0,
          otherMonthlyPayment:'$0'
        });
        
        this.state.otherMonthlyPaymentAsNumber = 0;

        var monthlyPayment = principalAndInterest+this.state.propertyTaxesAsNumber+this.state.homeownersInsuranceAsNumber+this.state.hoaAsNumber+this.state.otherMonthlyPaymentAsNumber;
       
        //console.log("monthlyPayment:"+monthlyPayment);

        this.setState({          
          monthlyPayment: monthlyPayment,
          monthlyPaymentAsNumber: monthlyPayment,
          principalAndInterest: principalAndInterest,
          principalAndInterestAsNumber: principalAndInterest,
          //propertyTaxes:propertyTaxes,
          //propertyTaxesAsNumber:propertyTaxes
        }, () => {
          
          this.CalculateMonthlyExpensesFromBreakdown();
  
        });

    }
    
}

GetPropertyTaxes()
{
  //Load from table. Load table from https://smartasset.com/taxes/property-taxes#1ln7EgcrRA 


  return 0;
}

round(x) {
  //return Math.round(x*100)/100;  // rounds a number to two decimal places.
  return Math.round(x);
}

  CalculateMortgagePayment_Fixed_Interest_Only()
  {
    alert("Fixed - Interest Only");
  }

  CalculateMortgagePayment_Variable_Principal_Interest()
  {
    alert("Variable - Principal & Interest");
  }

  CalculateMortgagePayment_Variable_Interest_Only()
  {
    alert("Variable - Interest Only");
  }


  CalculateDownpaymentPercent()
  {
       //recalculate downpayment %
       if (this.state.propertyPriceAsNumber > 0 && this.state.downPaymentAsNumber > 0)
       {
    
         var percent = parseInt((this.state.downPaymentAsNumber * 100)/this.state.propertyPriceAsNumber);
         this.setState({          
            downPaymentPercent: percent+"%"
        });
       }
  }

  CalculateClosingCostsPercent()
  {
       //recalculate downpayment %
       if (this.state.propertyPriceAsNumber > 0 && this.state.downPaymentAsNumber > 0)
       {    
         //var percent = parseInt((this.state.closingCostsAsNumber * 100)/(this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber));
         var percent = this.FormatNumberToPercentOneDecimal((this.state.closingCostsAsNumber * 100)/(this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber));

         this.setState({          
            closingCostsPercent: percent
        });
       }
  }

  CalculateSellingCostsPercent()
  {

       if (this.state.afterRepairValueAsNumber > 0 && this.state.sellingCostsAsNumber > 0)
       {
    
         var percent = parseInt((this.state.sellingCostsAsNumber * 100)/this.state.afterRepairValueAsNumber);
         this.setState({          
            sellingCostsPercent: percent+"%"
        });
       }
  }


  CalculateMarketValuePercent()
  {
    if (this.state.investmentType == 'Rental Property' || this.state.investmentType == 'Primary Home')
    {
          //recalculate Market Value %
          if (this.state.propertyPriceAsNumber > 0 && this.state.marketValueAsNumber > 0)
          {
        
            //document.querySelector('#marketValueLabel').style.visibility = "visible";
            document.querySelector('#marketValueLabel').style.display = "block";

            var percent = parseInt((  (this.state.marketValueAsNumber - this.state.propertyPriceAsNumber) * 100)/this.state.marketValueAsNumber);
            
            if (percent ==0)
            {
              //document.querySelector('#marketValueLabel').style.visibility = "hidden";
              document.querySelector('#marketValueLabel').style.display = "none";
            }

            var belowAbove = '';
            if (percent >0)
            {
              belowAbove = 'below';
              document.querySelector('#marketValuePercent').style.color = "green";
            }else
            {
              belowAbove = 'above';
              document.querySelector('#marketValuePercent').style.color = "red";
            }

            this.setState({          
              marketValuePercent: Math.abs(percent)+"%"
            });

            this.setState({          
              marketValuePercentAboveBelow: belowAbove
            });
          } else
          {
            //document.querySelector('#marketValueLabel').style.visibility = "hidden";
            document.querySelector('#marketValueLabel').style.display = "none";
          }
      } else
      {
        document.querySelector('#marketValueLabel').style.display = "none";
      }
  }

  
  HandleSelect(event) {

    var elements = event.split(",");
    var key = elements[0];
    var value = elements[1];

    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
      this.HandleAdditionalSelectSpecificLogic(event,key,value);
    });


  }

  HandleAdditionalSelectSpecificLogic(event,key,value)
  {
  
    if (key == 'projectedYearOfSale')
    {
      this.HideOrShowProjectedYears(event,key,value);
      this.CalculateFutureCashFlows();
      this.CalculateRentalSaleProceeds();

    } else if (key == 'financingType')
    {

      this.HideShowLoanFields(event,key,value);
      this.CalculateRentalSaleProceeds();
      this.CalculateFutureCashFlows();
    
    } else if (key == 'investmentType')
    {
      this.HideShowInvestmentTypeFields(event,key,value);

    }  else if (key == 'term')
    {
      this.ConvertTermAsNumber(value);
      this.CalculateRentalSaleProceeds();
    }

    if (key == 'financingType' || key == 'term' || key == 'loanType' || key == 'repaymentType')
    {
      this.CalculateMonthlyPayment();
    }

    if (key == 'rehabType')
    {
      this.CalculateRehabCosts();

    }
  
  }

  ConvertInitialTermAsNumber(value)
  {
    var valueNumber = 0;
    try{
        value = value.replace(' Years','')
        valueNumber = parseInt(value);

    } catch(error)
    {
      console.log(error);
    }
        return valueNumber;
  }
  

  ConvertTermAsNumber(value)
  {
      if (value !='_')
      {
        value = value.replace(' Years','')
        var valueNumber = parseInt(value);
      

        this.setState({
          termAsNumber: valueNumber
        }, () => {
        
          this.CalculateMonthlyPayment();

        });
        
      }
  }
  
  HideShowInitialFinancingType(profile,user)
  {
      let financingPreference = ""

      if (user.investorType === "homebuyer" || user.investorType === "realtor")
      {
        financingPreference = "Loan";
      } else if (user.investorType === "flipper" || user.investorType === "rental")
      {
        if (profile.hasOwnProperty("financingPreference")) 
        {
            financingPreference = upperCaseFirstLetter(profile.financingPreference);
        } else 
        {
          financingPreference = "Loan";
        } 
      }
      this.setState({
        financingType: financingPreference
      });

      this.HideShowLoanFields(null,null,financingPreference);

  }

  HideShowInvestmentTypeFields(event,key,value)
  {
    if (value == 'Rental Property')
    {

      document.querySelector("#rentalBlock").style.display='block';
      document.querySelector("#projectionBlock").style.display='block';
      document.querySelector("#marketValueColumn").style.display='block';

      document.querySelector("#buyerBlock").style.display='none';
      document.querySelector("#areaTrendBlock").style.display='none';
      document.querySelector("#averageMonthlyRentBlock").style.display='none';
      document.querySelector("#afterRepairValueColumn").style.display='none';
      document.querySelector("#holdingMonthsRow").style.display='none';
      document.querySelector("#flippingCostsBlock").style.display='none';
      document.querySelector("#buyersBudgetBlock").style.display='none';

      //Also populate the initial expenses fields
      this.CalculateInitialExpenses();

      this.ShowFutureCashflowFields();

    }if (value == 'Flipping Property')
    {

      document.querySelector("#afterRepairValueColumn").style.display='block';
      document.querySelector("#holdingMonthsRow").style.display='block';
      document.querySelector("#flippingCostsBlock").style.display='block';
      document.querySelector("#buyersBudgetBlock").style.display='block';
      document.querySelector("#areaTrendBlock").style.display='block';

      document.querySelector("#rentalBlock").style.display='none';
      document.querySelector("#projectionBlock").style.display='none';
      document.querySelector("#buyerBlock").style.display='none';
      document.querySelector("#averageMonthlyRentBlock").style.display='none';
      document.querySelector("#marketValueColumn").style.display='none';

      //Also populate holding costs  
      this.CalculateHoldingCosts();

    }if (value == 'Primary Home')
    {

      document.querySelector("#buyerBlock").style.display='block';
      document.querySelector("#areaTrendBlock").style.display='block';
      document.querySelector("#averageMonthlyRentBlock").style.display='block';
      document.querySelector("#marketValueColumn").style.display='block';

      document.querySelector("#afterRepairValueColumn").style.display='none';
      document.querySelector("#holdingMonthsRow").style.display='none';
      document.querySelector("#flippingCostsBlock").style.display='none';
      document.querySelector("#rentalBlock").style.display='none';
      document.querySelector("#projectionBlock").style.display='none';
      document.querySelector("#buyersBudgetBlock").style.display='none';
     
    }

    this.CalculateMarketValuePercent();

  }

  HideShowLoanFields(event,key,value)
  {
    if (value == 'Loan')
    {
      document.querySelector("#loanFinancingBlock").style.display='inline';

      document.querySelector("#downPaymentColumn").style.visibility='visible';

      document.querySelector("#monthlyPaymentColumn").style.visibility='visible';

    }else if (value == 'Cash')
    {
      document.querySelector("#loanFinancingBlock").style.display='none';

      document.querySelector("#downPaymentColumn").style.visibility='hidden';

      document.querySelector("#monthlyPaymentColumn").style.visibility='hidden';
    }
  }

  HideOrShowProjectedYears(event,key,value)
  {
    
        const yearNumber = parseInt(value.replace('Year ',''));

        let yearCounter = 1;

        //Show all elements
        while (yearCounter <= yearNumber)
        {
          const isOdd = !this.IsEvenNumber(yearCounter);

          if (isOdd)
          {
            const rowId = "cashflowRow"+yearCounter+"-"+(yearCounter+1);

            const element = document.querySelector('#'+rowId);

            element.style.display = "inline"; //Make row visible

            document.querySelector("#year"+(yearCounter+1)+"CashFlowColumn").style.visibility='visible'; //Make column visible

          }

          yearCounter = yearCounter + 1;
        }

        yearCounter = yearNumber;

        if (!this.IsEvenNumber(yearNumber))
        {
          yearCounter = yearCounter + 1

          document.querySelector("#year"+yearCounter+"CashFlowColumn").style.visibility='hidden';

        }

        //Hide elements
        while (yearCounter <=30)
        {

          const isOdd = !this.IsEvenNumber(yearCounter);

          if (isOdd)
          {
            const rowId = "cashflowRow"+yearCounter+"-"+(yearCounter+1);

            const element = document.querySelector('#'+rowId);

            element.style.display = "none";

          }

          yearCounter = yearCounter + 1;
        }

    
  }

  FormatMoneyIfNotAlreadyFormatted(value)
  {
    if (!value.startsWith("$"))
    {
      value = this.FormatMoney(value);
    }

    return value;
  }

  FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
   
  }

  IsEvenNumber(number)
  {
    let isEven = true;

    if (number >0)
    {
      if ((number%2) > 0)
      {
        isEven = false;
      }
    }  

    return isEven;
  }

  SetSwitchValue(key,value)
  {
    
    var obj  = {}
    obj[key] = value
    this.setState(obj);

  }



    render() {

      const { classes } = this.props;


        //Note: backdrop="static"  prevents the modal from closing when you click outside
        return (
                    
            <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} backdrop="static" dialogClassName="property-edit-modal-width"> 

            <Modal.Header closeButton>
              <Modal.Title>
                
              {this.state.dbProperty == null  &&
                <span>Add Property</span>
              }

              {this.state.dbProperty != null  &&
                <span>Edit Property</span>
              }
              
              </Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal" ref={this.mainModalInfoDialogRef}>
            
              <div class="modal-body" style={{padding:"0px",margin:"0px"}}>
                    <div classx="container" style={{padding:"0px",margin:"0px"}}>

                    <div class="card2">
                                
                                <div class="card-body alert-edit-modal">

                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                            <form action="/action_page.php">
                                      
                                                {/* form row begins */}

                                                {/* Lookup Button */}
                                                {this.state.dbProperty == null  && 

                                                <div class="row">

                                              <div class="col-md-7">
                                                  <div class="form-group">
                                                  <button class="btn  btn-warning  view-btn lookupButton" id="lookupButton" style={{maxWidth:'130px'}} onClick={e => this.HandleShowPropertyLookup(e, this)}>LookUp</button>
                                                  <OverlayTrigger
                                                          placement="auto"
                                                          delay={{ show: 150, hide: 100 }} 
                                                          trigger={["hover","click"]}
                                                          overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                        
                                                            <div className='helpText'>You can either enter all the property<br></br> information manually or click this<br></br> button and let the application try to<br></br> locate the property details by:<br></br><br></br>
                                                          <span style={{fontWeight:"bold",color:"#ffa500"}}>ADDRESS</span><br></br>
                                                          <span style={{fontWeight:"bold",color:"#ffa500"}}>MLS NUMBER</span> <br></br>
                                                            or <span style={{fontWeight:"bold",color:"#ffa500"}}>URL</span><br></br></div>
                                                        </Tooltip>}
                                                        >
                                                        <span>
                                                        <InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                        </span>
                                                        
                                                      </OverlayTrigger>

                                                        <span id="lookupHelp" class="desktop-only-block">
                                                          <img src={lookupHelpWhiteDesktopImg} style={{width:"355px",height:"50px"}}/>
                                                        </span>
                                                      
                                                        <span id="lookupHelp" class="mobile-only-block">
                                                          <img src={lookupHelpWhiteMobileImg} style={{width:"320px"}}/>
                                                        </span>
                                                      
                                                  </div>
                                              </div>
                                              </div>  
                                              }
                                              {/* ----- End Row ----*/}
           

                                              {/* ----- Message Box Begins----*/}
                                              {this.state.showMessageBox  && 

                                              <div class="row">
                                                  <div class="col-md-12" style={{borderRadius: '10px', border: '1px solid #ffa500', padding: '20px', marginBottom:'15px', paddingTop:'10px'}}>
                                               
                                                    {/* ----- Progress Message----*/}
                                                    {this.state.showProcessMessage  && 
                                                    <div>
                                                          <div style={{paddingBottom:'5px',color:'#d1d1d1'}}>We found the property details.<br></br>Now we need to look up these remaining items:</div>
                                                        
                                                    
                                                          {this.state.loadingTrendingRent  && 
                                                            <div style={{paddingTop:'1px'}}>
                                                                <table>
                                                                  <tr>
                                                                    <td><span style={{fontSize:'15px', color:'#FFFFFF'}}> <span style={{fontSize:'20px'}}>&#x2022;</span> Looking up <span style={{color:'#ffa500'}}>Trending Rent</span> </span></td>
                                                                    <td>&nbsp; &nbsp;</td>
                                                                    <td>
                                                                        {this.state.trendingRentResultSuccess  && 
                                                                          <div style={{ width: 40, height: 40 }} id="trendingRentResult">
                                                                          <CircularProgressbar value={this.state.lookUpTrendingRentCounter} text={`${this.state.lookUpTrendingRentCounter}%`} styles={{text: {fontSize: '28px',fontWeight:'bold',fill: '#ffa500'}}} />
                                                                          </div>
                                                                        }

                                                                        {this.state.trendingRentResultFailure  && 
                                                                          <div style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</div>
                                                                        }

                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                              </div>
                                                          }

                                                          {this.state.loadingVancacyRate  && 
                                                            <div style={{paddingTop:'5px'}}>
                                                                <table>
                                                                  <tr>
                                                                    <td><span style={{fontSize:'15px', color:'#FFFFFF'}}> <span style={{fontSize:'20px'}}>&#x2022;</span> Looking up <span style={{color:'#ffa500'}}>Vacancy Rate</span> </span></td>
                                                                    <td>&nbsp; &nbsp;</td>
                                                                    <td>
                                                                        {this.state.vancacyRateResultSuccess  &&
                                                                          <div style={{ width: 40, height: 40 }} id="vancacyRateResult">
                                                                          <CircularProgressbar value={this.state.lookUpVancacyRateCounter} text={`${this.state.lookUpVancacyRateCounter}%`} styles={{text: {fontSize: '28px',fontWeight:'bold',fill: '#ffa500'}}} />
                                                                          </div>
                                                                        }

                                                                        {this.state.vancacyRateResultFailure  &&

                                                                            <div style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</div>
                                                                        }
                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                            </div>
                                                          }
                                                          
                                                          {this.state.loadingRentRateOfGrowth  && 
                                                            <div style={{paddingTop:'5px'}}>
                                                                <table>
                                                                  <tr>
                                                                    <td><span style={{fontSize:'15px', color:'#FFFFFF'}}> <span style={{fontSize:'20px'}}>&#x2022;</span> Looking up <span style={{color:'#ffa500'}}>Rent Rate of Growth</span> </span></td>
                                                                    <td>&nbsp; &nbsp;</td>
                                                                    <td>
                                                                    {this.state.rentRateOfGrowthResultSuccess  && 
                                                                        <div style={{ width: 40, height: 40 }} id="rentRateOfGrowthResult">
                                                                        <CircularProgressbar value={this.state.lookUpRentRateOfGrowthCounter} text={`${this.state.lookUpRentRateOfGrowthCounter}%`} styles={{text: {fontSize: '28px',fontWeight:'bold',fill: '#ffa500'}}} />
                                                                        </div>
                                                                    }

                                                                    {this.state.rentRateOfGrowthResultFailure  && 

                                                                    <div style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</div>
                                                                    }
                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                            </div>
                                                          }

                                                          {this.state.loadingInflationRate  && 
                                                            <div style={{paddingTop:'5px'}}>
                                                                <table>
                                                                  <tr>
                                                                    <td><span style={{fontSize:'15px', color:'#FFFFFF'}}> <span style={{fontSize:'20px'}}>&#x2022;</span> Looking up <span style={{color:'#ffa500'}}>Expense Rate of Growth</span> </span></td>
                                                                    <td>&nbsp; &nbsp;</td>
                                                                    <td>
                                                                    {this.state.inflationRateResultSuccess  && 
                                                                        <div style={{ width: 40, height: 40 }} id="inflationRateResult">
                                                                        <CircularProgressbar value={this.state.lookUpInflationRateCounter} text={`${this.state.lookUpInflationRateCounter}%`} styles={{text: {fontSize: '28px',fontWeight:'bold',fill: '#ffa500'}}} />
                                                                        </div>
                                                                    }

                                                                    {this.state.inflationRateResultFailure  && 

                                                                    <div style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</div>
                                                                    }
                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                            </div>
                                                          }


                                                          {this.state.loadingClosingCosts  && 
                                                            <div style={{paddingTop:'5px'}}>
                                                                <table>
                                                                  <tr>
                                                                    <td><span style={{fontSize:'15px', color:'#FFFFFF'}}> <span style={{fontSize:'20px'}}>&#x2022;</span> Looking up <span style={{color:'#ffa500'}}>Closing Costs</span> </span></td>
                                                                    <td>&nbsp; &nbsp;</td>
                                                                    <td>
                                                                    {this.state.closingCostsResultSuccess  && 
                                                                        <div style={{ width: 40, height: 40 }} id="closingCostsResult">
                                                                        <CircularProgressbar value={this.state.lookUpClosingCostsCounter} text={`${this.state.lookUpClosingCostsCounter}%`} styles={{text: {fontSize: '28px',fontWeight:'bold',fill: '#ffa500'}}} />
                                                                        </div>
                                                                    }

                                                                    {this.state.closingCostsResultFailure  && 

                                                                    <div style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</div>
                                                                    }

                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                              </div>
                                                          }

                                                          {this.state.loadingPricePerSqft  && 
                                                            <div style={{paddingTop:'5px'}}>
                                                                <table>
                                                                  <tr>
                                                                    <td><span style={{fontSize:'15px', color:'#FFFFFF'}}> <span style={{fontSize:'20px'}}>&#x2022;</span> Looking up <span style={{color:'#ffa500'}}>Trending Price per SqFt</span> </span></td>
                                                                    <td>&nbsp; &nbsp;</td>
                                                                    <td>
                                                                    {this.state.pricePerSqftResultSuccess  && 
                                                                        <div style={{ width: 40, height: 40 }} id="pricePerSqftResult">
                                                                        <CircularProgressbar value={this.state.lookUpPricePerSqftCounter} text={`${this.state.lookUpPricePerSqftCounter}%`} styles={{text: {fontSize: '28px',fontWeight:'bold',fill: '#ffa500'}}} />
                                                                        </div>
                                                                    }

                                                                    {this.state.pricePerSqftResultFailure  && 

                                                                    <div style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</div>
                                                                    }

                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                              </div>  
                                                          }

                                                          {this.state.loadingTrendingPriceInArea  && 
                                                            <div style={{paddingTop:'5px'}}>
                                                              <table>
                                                                <tr>
                                                                  <td><span style={{fontSize:'15px', color:'#FFFFFF'}}> <span style={{fontSize:'20px'}}>&#x2022;</span> Looking up 
                                                                  
                                                                  {(this.state.investmentType == "Rental Property" || this.state.investmentType == "Primary Home")  && 
                                                                    <span style={{color:'#ffa500'}}> Market Value</span> 
                                                                  }

                                                                  {this.state.investmentType == "Flipping Property"  && 
                                                                    <span style={{color:'#ffa500'}}> After Repair Value (AVR)</span> 
                                                                  }
                                                                  
                                                                  </span></td>
                                                                  <td>&nbsp; &nbsp;</td>
                                                                  <td>
                                                                  {this.state.trendingPriceInAreaResultSuccess  && 
                                                                      <div style={{ width: 40, height: 40 }} id="trendingPriceInAreaResult">
                                                                      <CircularProgressbar value={this.state.lookUpTrendingPriceInAreaCounter} text={`${this.state.lookUpTrendingPriceInAreaCounter}%`} styles={{text: {fontSize: '28px',fontWeight:'bold',fill: '#ffa500'}}} />
                                                                      </div>
                                                                  }
                                                                  {this.state.trendingPriceInAreaResultFailure  && 

                                                                  <div style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</div>
                                                                  }
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </div>
                                                          }
        
                                                        {this.state.messageBoxBottomTextSuccess  && 
                                                          <div style={{fontSize:'13px',color:'#d1d1d1',paddingTop:'25px'}}>
                                                            *** These could take up to 60 seconds. <span style={{color:'#ffa500'}}>Hang tight!</span> *** 
                                                          </div>
                                                        }

                                                        {this.state.messageBoxBottomTextFailure  && 
                                                          <div style={{fontSize:'13px',color:'#d1d1d1',paddingTop:'25px'}}>
                                                          You must enter the value of all fields that <span  style={{color:'#cc0019',fontWeight:'bold'}}>FAILED</span> manually.<br></br>
                                                          Then you can <b>Save</b> or <b>Analyze</b> the property.
                                                        </div>
                                                        }

                                                     </div> 
                                                    }
                                                    {/* ----- End Progress Message ----*/}  

                                                    {/* ----- Success Message----*/}
                                                    {this.state.showSuccessMessage  && 
                                                      <div style={{color:'#FFFFFF'}}>
                                                        
                                                         <img src={greatNewsImg}/>
                                                         <div style={{paddingBottom:'15px',paddingLeft:'6px'}}>
                                                         We have populated all the required fields.
                                                         </div>
                                                         <div style={{paddingLeft:'6px'}}>
                                                         You now can <b>Save</b> or <b>Analyze</b> <br></br>
                                                         </div>
                                                         <div style={{paddingLeft:'6px', paddingBottom:'5px'}}>
                                                         the property by scrolling to the bottom of the page. 
                                                         </div>
 
                                                      </div>
                                                    }
                                                    {/* ----- End Success Message ----*/}  
                                                    

                                                  </div>
                                              </div>
                                              }
                                              {/* ----- End Message Box ----*/}  

                                          

                                                {/* Investment Type */} 
                                                <div class="row">

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                    <OverlayTrigger
                                                            placement="auto"
                                                            delay={{ show: 150, hide: 100 }} 
                                                            trigger={["hover","click"]}
                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                          
                                                              <div className='helpText'><div style={{paddingBottom:"8px"}}>What do you intend to use the property for? <br></br>
                                                             
                                                             <div style={{paddingTop:'10px'}}>
                                                              A primary home, a rental property, or a property you plan to flip.
                                                              </div>

                                                              </div>
                                                              {/*
                                                              <span style={{fontWeight:"bold",color:"#ffa500"}}>PRIMARY HOME</span> &nbsp; &nbsp; -> Buy the property and live in it. <br></br><span style={{fontWeight:"bold",color:"#ffa500"}}>RENTAL PROPERTY</span> -> Buy property and rent it.<br></br> or <span style={{fontWeight:"bold",color:"#ffa500"}}>FLIPPING</span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  -> Buy property, fix it and resell it.
                                                              */}
                                                               <div style={{paddingTop:'5px'}}>
                                                               The financial indicators are different for each investment type.
                                                               </div>
                                                               <br/></div>
                                                          </Tooltip>}
                                                          >
                                                          <div>
                                                                  <label class="dialog-form-label" for="alertStartDate">Investment Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block"/><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30}/> 
                                                          </div>
                                                        </OverlayTrigger>
                                                        
                                                        <DropdownButton disabled={this.state.dbProperty != null} id="investmentTypeDropdownSelect" name="investmentTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.investmentType} onSelect={(e) => this.HandleSelect(e)}>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['investmentType', 'Primary Home']}>Primary Home</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['investmentType', 'Rental Property']}>Rental Property</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['investmentType', 'Flipping Property']}>Flipping Property</Dropdown.Item>
                                                        </DropdownButton>
                                                        
                                                    </div>
                                                </div>
                                                </div>    {/* ----- End Row ----*/}

                                                

                                                {/* Property Nickname */} 
                                                <div class="row">
{/*
                                                    <div class="col-md-5">
                                                        <div class="form-group">
                                                        <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                    <div className='helpText'>This is the name you are giving the property for your records.</div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="propertyNickname">Property Nickname</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                            </OverlayTrigger>
                                                            
                                                            <input type="text" class="form-select alert-edit-modal-component" id="propertyNickname" placeholder="i.e. Miami Downtown Condo" name="propertyNickname" defaultValue={this.state.propertyNickname} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                            
                                                        </div>
                                                    </div>
        */}
                                                    <div class="col-md-3">
                                                      <div class="form-group">
                                                      <OverlayTrigger
                                                              placement="auto"
                                                              delay={{ show: 150, hide: 100 }} 
                                                              trigger={["hover","click"]}
                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                            
                                                                <div className='helpText'>
                                                                
                                                                  <div style={{paddingBottom:"8px"}}>The type of property: </div>

                                                                  <span style={{fontWeight:"bold",color:"#ffa500"}}>SINGLE FAMILY</span>
                                                                  <br></br>
                                                                  <span style={{fontWeight:"bold",color:"#ffa500"}}>TOWNHOUSE</span>
                                                                  <br></br> 
                                                                  <span style={{fontWeight:"bold",color:"#ffa500"}}>MULTI-FAMILY</span>
                                                                  <br></br> 
                                                                  <span style={{fontWeight:"bold",color:"#ffa500"}}>CONDO</span>

                                                                  <br></br><br></br>
                                                                  The property type doesn't impact the financial indicators,<br></br>
                                                                  this is only informational.
                                                                  <br></br><br></br>
                                                                  <b>(OPTIONAL)</b>
                                                                  </div>
                                                            </Tooltip>}
                                                            >
                                                            <div>
                                                                    <label class="dialog-form-label" for="alertStartDate">Property Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block"/><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30}/>
                                                            </div>
                                                          </OverlayTrigger>
                                                          
                                                          <DropdownButton id="propertyTypeDropdownSelect" name="propertyTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.propertyType} onSelect={(e) => this.HandleSelect(e)}>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Single Family Home']}>Single Family Home</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Townhouse']}>Townhouse</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Multi-family']}>Multi-family</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Condo']}>Condo</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Mobile Home']}>Mobile Home</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['propertyType', 'Lot']}>Lot</Dropdown.Item>
                                                              </DropdownButton>
                                                              
                                                          </div>
                                                      </div>

                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                        <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                    <div className='helpText'>
                                                                      The MLS number is essentially a serial number for each <br></br>
                                                                      property on the market. It was created to make it easier <br></br>
                                                                      to differentiate properties and find properties quickly.
                                                                      
                                                                      <br></br><br></br>
                                                                      The MLS number doesn't impact the financial indicators,<br></br>
                                                                      this is only informational. It helps identify the property.

                                                                      <br></br><br></br>
                                                                      <b>(OPTIONAL)</b>

                                                                      </div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="mls">MLS#</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                            </OverlayTrigger>
                                                            
                                                            <input type="text" class="form-select alert-edit-modal-component" id="mls" placeholder="" name="mls" defaultValue={this.state.mls} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                            
                                                        </div>
                                                    </div>


                                                  </div>    {/* ----- End Row ----*/}

                                                {/* Address */} 
                                                <div class="row">

                                                    <div class="col-md-10">
                                                        <div class="form-group">
                                                        <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                    <div className='helpText'>
                                                                      The street address where the property is located.
                                                                      <br></br><br></br>
                                                                      The street address is used in the financial indicator<br></br>
                                                                      calculations when trying to locate comparable <br></br>
                                                                      properties in the area to determine trends.<br></br>
                                                                      <br></br>
                                                                      
                                                                      </div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="location">Address Line</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                            </OverlayTrigger>
                                                            
                                                            <input type="text" class="form-select alert-edit-modal-component" id="address" placeholder="i.e. 123 Main St" name="address" defaultValue={this.state.address} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                            
                                                        </div>
                                                    </div>
                                                  </div>    {/* ----- End Row ----*/}

                                                    {/* Address */} 
                                                <div class="row">

                                                  <div class="col-md-4">
                                                      <div class="form-group">
                                                      <OverlayTrigger
                                                              placement="auto"
                                                              delay={{ show: 150, hide: 100 }} 
                                                              trigger={["hover","click"]}
                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                            
                                                                <div className='helpText'>The city where the property is located.
                                                                <br></br><br></br>
                                                                      The city is used in the financial indicator<br></br>
                                                                      calculations when trying to locate comparable <br></br>
                                                                      properties in the area to determine trends.<br></br>
                                                                      <br></br>
                                                                
                                                                </div>
                                                            </Tooltip>}
                                                            >
                                                            <div>
                                                                    <label class="dialog-form-label" for="city">City</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                            </div>
                                                          </OverlayTrigger>
                                                          
                                                          <input type="text" class="form-select alert-edit-modal-component" id="city" placeholder="i.e.Anytown" name="city" defaultValue={this.state.city} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                          
                                                      </div>
                                                  </div>

                                                  
                                                      {/* State */} 
                                                      <div class="col-md-2">
                                                          <div class="form-group">
                                                          <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip className="messagetooltip">
                                                                
                                                                    <div className='helpText'>The state where the property is located.
                                                                    
                                                                    <br></br><br></br>
                                                                      The state is used in the financial indicator<br></br>
                                                                      calculations when trying to locate comparable <br></br>
                                                                      properties in the area to determine trends.<br></br>
                                                                      <br></br>
                                                                    </div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="stateDropdownSelect">State</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                              </OverlayTrigger>
                                                              
                                                              <DropdownButton 
                                                                id="stateDropdownSelect" 
                                                                name="stateDropdownSelect" 
                                                                className="customDropdownSelectWrapper"  
                                                                style={{ maxHeight: "28px" }}
                                                                title={this.state.stateCode} onSelect={(e) => this.HandleSelect(e)}>
                                                                                                                                                
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'AL']}>AL</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'AK']}>AK</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'AZ']}>AZ</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'AR']}>AR</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'CA']}>CA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'CO']}>CO</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'CT']}>CT</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'DE']}>DE</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'FL']}>FL</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'GA']}>GA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'HI']}>HI</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'ID']}>ID</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'IL']}>IL</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'IN']}>IN</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'IA']}>IA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'KS']}>KS</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'KY']}>KY</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'LA']}>LA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'ME']}>ME</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'MD']}>MD</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'MA']}>MA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'MI']}>MI</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'MN']}>MN</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'MS']}>MS</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'MO']}>MO</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'MT']}>MT</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'NE']}>NE</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'NV']}>NV</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'NH']}>NH</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'NJ']}>NJ</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'NM']}>NM</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'NY']}>NY</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'NC']}>NC</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'ND']}>ND</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'OH']}>OH</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'OK']}>OK</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'OR']}>OR</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'PA']}>PA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'RI']}>RI</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'SC']}>SC</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'SD']}>SD</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'TN']}>TN</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'TX']}>TX</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'UT']}>UT</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'VT']}>VT</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'VA']}>VA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'WA']}>WA</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'WV']}>WV</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'WI']}>WI</Dropdown.Item>
                                                                      <div style={{paddingBottom:"8px"}}></div>
                                                                      <Dropdown.Item className="dropdownitem"  eventKey={['stateCode', 'WY']}>WY</Dropdown.Item>
                                                                
                                                              </DropdownButton>
                                                              
                                                          </div>
                                                      </div>
                                          

                                                  <div class="col-md-2">
                                                      <div class="form-group">
                                                      <OverlayTrigger
                                                              placement="auto"
                                                              delay={{ show: 150, hide: 100 }} 
                                                              trigger={["hover","click"]}
                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                            
                                                                <div className='helpText'>The property ZIP Code.
                                                                
                                                                <br></br><br></br>
                                                                      The ZIP code is used in the financial indicator<br></br>
                                                                      calculations when trying to locate comparable <br></br>
                                                                      properties in the area to determine trends.<br></br>
                                                                      <br></br>
                                                                </div>
                                                            </Tooltip>}
                                                            >
                                                            <div>
                                                                    <label class="dialog-form-label" for="zipcode">ZIP Code</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                            </div>
                                                          </OverlayTrigger>
                                                          
                                                          <input type="text" class="form-select alert-edit-modal-component" id="zipcode" maxlength="5" placeholder="i.e. 99999" name="zipcode" defaultValue={this.state.zipcode} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                          
                                                      </div>
                                                  </div>

                                                  
                                                      {/* Country */} 
                                                      <div class="col-md-2">
                                                          <div class="form-group">
                                                          <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip className="messagetooltip">
                                                                
                                                                    <div className='helpText'>The country where the property is located.
                                                                    
                                                                    <br></br><br></br>
                                                                      The country is used in the financial indicator<br></br>
                                                                      calculations when trying to locate comparable <br></br>
                                                                      properties in the area to determine trends.<br></br>
                                                                      <br></br>
                                                                    </div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="countryDropdownSelect">Country</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                              </OverlayTrigger>
                                                              
                                                              <DropdownButton id="countryDropdownSelect" name="countryDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.country} onSelect={(e) => this.HandleSelect(e)}>
                                                              <Dropdown.Item className="dropdownitem"  eventKey={['country', 'US']}>US</Dropdown.Item>
                                                              <div style={{paddingBottom:"8px"}}></div>
                                                              <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AD']}>AD</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AE']}>AE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AF']}>AF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AG']}>AG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AI']}>AI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AL']}>AL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AM']}>AM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AO']}>AO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AQ']}>AQ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AR']}>AR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AS']}>AS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AT']}>AT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AU']}>AU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AW']}>AW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AX']}>AX</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'AZ']}>AZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BA']}>BA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BB']}>BB</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BD']}>BD</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BE']}>BE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BF']}>BF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BG']}>BG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BH']}>BH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BI']}>BI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BJ']}>BJ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BL']}>BL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BM']}>BM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BN']}>BN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BO']}>BO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BQ']}>BQ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BR']}>BR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BS']}>BS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BT']}>BT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BV']}>BV</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BW']}>BW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BY']}>BY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'BZ']}>BZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CA']}>CA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CC']}>CC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CD']}>CD</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CF']}>CF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CG']}>CG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CH']}>CH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CI']}>CI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CK']}>CK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CL']}>CL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CM']}>CM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CN']}>CN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CO']}>CO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CR']}>CR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CU']}>CU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CV']}>CV</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CW']}>CW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CX']}>CX</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CY']}>CY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'CZ']}>CZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'DE']}>DE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'DJ']}>DJ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'DK']}>DK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'DM']}>DM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'DO']}>DO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'DZ']}>DZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'EC']}>EC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'EE']}>EE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'EG']}>EG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'EH']}>EH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ER']}>ER</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ES']}>ES</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ET']}>ET</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'FI']}>FI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'FJ']}>FJ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'FK']}>FK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'FM']}>FM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'FO']}>FO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'FR']}>FR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GA']}>GA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GB']}>GB</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GD']}>GD</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GE']}>GE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GF']}>GF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GG']}>GG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GH']}>GH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GI']}>GI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GL']}>GL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GM']}>GM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GN']}>GN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GP']}>GP</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GQ']}>GQ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GR']}>GR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GS']}>GS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GT']}>GT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GU']}>GU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GW']}>GW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'GY']}>GY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'HK']}>HK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'HM']}>HM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'HN']}>HN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'HR']}>HR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'HT']}>HT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'HU']}>HU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ID']}>ID</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IE']}>IE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IL']}>IL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IM']}>IM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IN']}>IN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IO']}>IO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IQ']}>IQ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IR']}>IR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IS']}>IS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'IT']}>IT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'JE']}>JE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'JM']}>JM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'JO']}>JO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'JP']}>JP</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KE']}>KE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KG']}>KG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KH']}>KH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KI']}>KI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KM']}>KM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KN']}>KN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KP']}>KP</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KR']}>KR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KW']}>KW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KY']}>KY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'KZ']}>KZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LA']}>LA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LB']}>LB</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LC']}>LC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LI']}>LI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LK']}>LK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LR']}>LR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LS']}>LS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LT']}>LT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LU']}>LU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LV']}>LV</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'LY']}>LY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MA']}>MA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MC']}>MC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MD']}>MD</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ME']}>ME</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MF']}>MF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MG']}>MG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MH']}>MH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MK']}>MK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ML']}>ML</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MM']}>MM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MN']}>MN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MO']}>MO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MP']}>MP</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MQ']}>MQ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MR']}>MR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MS']}>MS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MT']}>MT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MU']}>MU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MV']}>MV</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MW']}>MW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MX']}>MX</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MY']}>MY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'MZ']}>MZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NA']}>NA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NC']}>NC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NE']}>NE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NF']}>NF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NG']}>NG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NI']}>NI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NL']}>NL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NO']}>NO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NP']}>NP</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NR']}>NR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NU']}>NU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'NZ']}>NZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'OM']}>OM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PA']}>PA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PE']}>PE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PF']}>PF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PG']}>PG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PH']}>PH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PK']}>PK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PL']}>PL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PM']}>PM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PN']}>PN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PR']}>PR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PS']}>PS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PT']}>PT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PW']}>PW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'PY']}>PY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'QA']}>QA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'RE']}>RE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'RO']}>RO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'RS']}>RS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'RU']}>RU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'RW']}>RW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SA']}>SA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SB']}>SB</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SC']}>SC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SD']}>SD</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SE']}>SE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SG']}>SG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SH']}>SH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SI']}>SI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SJ']}>SJ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SK']}>SK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SL']}>SL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SM']}>SM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SN']}>SN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SO']}>SO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SR']}>SR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SS']}>SS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ST']}>ST</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SV']}>SV</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SX']}>SX</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SY']}>SY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'SZ']}>SZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TC']}>TC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TD']}>TD</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TF']}>TF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TG']}>TG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TH']}>TH</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TJ']}>TJ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TK']}>TK</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TL']}>TL</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TM']}>TM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TN']}>TN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TO']}>TO</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TR']}>TR</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TT']}>TT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TV']}>TV</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TW']}>TW</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'TZ']}>TZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'UA']}>UA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'UG']}>UG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'UM']}>UM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'US']}>US</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'UY']}>UY</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'UZ']}>UZ</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'VA']}>VA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'VC']}>VC</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'VE']}>VE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'VG']}>VG</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'VI']}>VI</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'VN']}>VN</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'VU']}>VU</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'WF']}>WF</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'WS']}>WS</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'YE']}>YE</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'YT']}>YT</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ZA']}>ZA</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ZM']}>ZM</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['country', 'ZW']}>ZW</Dropdown.Item>
                                                                    

                                                              </DropdownButton>
                                                              
                                                          </div>
                                                      </div>
                                                  

                                                  </div>    {/* ----- End Row ----*/}
                                          

                                                      {/* Property Size */}   
                                                      <div class="row">
                                                                                                            
                                                          <div class="col-md-4">
                                                                          
                                                                <div class="form-group">
                                                                    <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip  className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>
                                                                            
                                                                            <div style={{paddingBottom:"10px"}}>
                                                                            The living area square footage refers to the <br></br>
                                                                            <span style={{color:"#ffa500"}}>“living area”</span> of the property, which only includes <br></br>
                                                                            those spaces in the property which can actually be <br></br>
                                                                            <span style={{color:"#ffa500"}}>“lived”</span> in. <br></br>
                                                                            </div>
                                                                            

                                                                            <div style={{paddingBottom:"10px"}}>
                                                                            This includes the <span style={{color:"#ffa500"}}>kitchen</span>, the <span style={{color:"#ffa500"}}>living room</span>, <br></br>
                                                                            the <span style={{color:"#ffa500"}}>dining room</span>, <span style={{color:"#ffa500"}}>bedrooms</span>, and <span style={{color:"#ffa500"}}>bathrooms</span>, <br></br>
                                                                            <span style={{color:"#ffa500"}}>stairways</span> and <span style={{color:"#ffa500"}}>hallways</span>.<br></br>
                                                                            </div>
                                                                            

                                                                            <div style={{paddingBottom:"10px"}}>
                                                                            An unfinished basement and a garage would <br></br>
                                                                            not be included in the living area <br></br>
                                                                            square footage.<br></br>
                                                                            </div>
                                                                            

                                                                            <div style={{paddingBottom:"10px"}}>
                                                                            Another common standard is to include all space <br></br>
                                                                            that uses the same heating and cooling system <br></br>
                                                                            in the home.<br></br>
                                                                            </div>

                                                                            <div style={{paddingBottom:"10px"}}>
                                                                            The living area square footage is used to <br></br>
                                                                            calculate the <span style={{color:"#ffa500"}}>Price per SqFt</span> indicator.<br></br>
                                                                            </div>


                                                                            </div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="livingAreaSize">Living Area (sqft)</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                
                                                                      <input type="text" inputmode="numeric" class="form-select alert-edit-modal-component" id="livingAreaSize" placeholder="" name="livingAreaSize" defaultValue={this.state.livingAreaSize} value={this.state.livingAreaSize} onChange={this.handleNumberInputChange}/>
                                                                      
                                                                      <span class="desktop-only-block">
                                                                        <input type="range" className="custom-range" id="livingAreaSizeAsNumber" name="livingAreaSizeAsNumber" min="0" max="8000" step="1" value={this.state.livingAreaSizeAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                                      </span>

                                                                      <span class="mobile-only-block">
                                                                        <CustomizedSlider id="livingAreaSizeAsNumber" name="livingAreaSizeAsNumber" min={0} max={8000} step={1} value={this.state.livingAreaSizeAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                                        <br></br>
                                                                      </span>
                                                                </div>
                                                                
                                                            </div>

                                                            <div class="col-md-4">
                                                                          
                                                                  <div class="form-group">
                                                                      <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip  className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                                The lot size refers to the size of the <span style={{color:"#ffa500"}}>land</span> that your <br></br>property is on.

                                                                                <br></br><br></br>
                                                                  The lot size doesn't impact the financial indicators,<br></br>
                                                                  this is only informational.

                                                                              <br></br><br></br>
                                                                              <b>(OPTIONAL)</b>
                                                                              </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="lotSize">Lot Size (sqft)</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                  
                                                                        <input type="text" inputmode="numeric" class="form-select alert-edit-modal-component" id="lotSize" placeholder="" name="lotSize" defaultValue={this.state.lotSize} value={this.state.lotSize} onChange={this.handleNumberInputChange}/>
                                                                        <span class="desktop-only-block">
                                                                            <input type="range" className="custom-range" id="lotSizeAsNumber" name="lotSizeAsNumber" min="0" max="8000" step="1" value={this.state.lotSizeAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                          <CustomizedSlider id="lotSizeAsNumber" name="lotSizeAsNumber" min={0} max={8000} step={1} value={this.state.lotSizeAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                                          <br></br>     
                                                                      </span>

                                                                  </div>
                                                                  
                                                              </div>

                                                              <div class="col-md-3">
                                                                      <div class="form-group">
                                                                          <OverlayTrigger
                                                                              placement="auto"
                                                                              delay={{ show: 150, hide: 100 }} 
                                                                              trigger={["hover","click"]}
                                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                            
                                                                                <div className='helpText'>
                                                                                Price per square foot, is a metric often used in helping to <br></br>
                                                                                determine a property’s value, it can be a useful comparison<br></br>
                                                                                tool against similar properties in the area.
                                                                                <br></br><br></br>
                                                                                The price per square foot of a property is the price of <br></br> 
                                                                                a property divided by the square footage of the property.

                                                                                <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                                If the property is listed you can get it from listing <br></br>
                                                                                websites like:<br></br><br></br>
                                                                                <ul>
                                                                                <li><a href="https://www.zillow.com/" target="blank">https://www.zillow.com/</a></li>
                                                                                <li><a href="https://www.remax.com/" target="blank">https://www.remax.com/</a></li>
                                                                                <li><a href="https://www.trulia.com/" target="blank">https://www.trulia.com/</a></li>
                                                                                <li><a href="https://www.redfin.com/" target="blank">https://www.redfin.com/</a></li>
                                                                                <li><a href="https://www.realtor.com/" target="blank">https://www.realtor.com/</a></li>
                                                                                </ul>

                                                                                If the property is not listed just divide the<br></br>
                                                                                listing price by the living area square footage.

                                                                                <br></br><br></br>
                                                                                <div style={{paddingBottom:"10px"}}>
                                                                            The price per square foot is used to <br></br>
                                                                            calculate the <span style={{color:"#ffa500"}}>Price per SqFt</span> indicator.<br></br>
                                                                            </div>

                                                                                  </div>
                                                                            </Tooltip>}
                                                                            >
                                                                            <div>
                                                                                    <label class="dialog-form-label" for="pricePerSqft">Price Per Square Foot</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                          
                                                                          <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="pricePerSqft" placeholder="Price per SqFt $" name="pricePerSqft" defaultValue={this.state.pricePerSqft} value={this.state.pricePerSqft} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                          <span class="desktop-only-block">
                                                                            <input type="range" className="custom-range" id="pricePerSqftAsNumber" name="pricePerSqftAsNumber" min="0" max="600" step="1" value={this.state.pricePerSqftAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                          </span>

                                                                          <span class="mobile-only-block">
                                                                              <CustomizedSlider id="pricePerSqftAsNumber" name="pricePerSqftAsNumber" min={0} max={600} step={1} value={this.state.pricePerSqftAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                              <br></br>     
                                                                          </span>
                                                                          
                                                                          </div>
                                                                    </div>


                                                            </div> {/* end row */}  

                                                            <div class="row">
      

                                                            {/* Bedrooms */} 
                                                            <div class="col-md-2">
                                                            <div class="form-group">
                                                            <OverlayTrigger
                                                                    placement="auto"
                                                                    delay={{ show: 150, hide: 100 }} 
                                                                    trigger={["hover","click"]}
                                                                    overlay={<Tooltip className="messagetooltip">
                                                                  
                                                                      <div className='helpText'>The number of bedrooms the property has.
                                                                      
                                                                      <br></br><br></br>
                                                                      The number of bedrooms is used in the financial indicator<br></br>
                                                                      calculations when trying to locate comparable <br></br>
                                                                      properties in the area to determine trends.<br></br>
                                                                      <br></br>

                                                                      </div>
                                                                  </Tooltip>}
                                                                  >
                                                                  <div>
                                                                          <label class="dialog-form-label" for="numberBedroomsDropdownSelect">Bedrooms</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                  </div>
                                                                </OverlayTrigger>
                                                              
                                                                <DropdownButton id="numberBedroomsDropdownSelect" name="numberBedroomsDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.numberBedrooms} onSelect={(e) => this.HandleSelect(e)}>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '1']}>1</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '1.5']}>1.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '2']}>2</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '2.5']}>2.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '3']}>3</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '3.5']}>3.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '4']}>4</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '4.5']}>4.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '5']}>5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '5.5']}>5.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '6']}>6</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '6.5']}>6.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '7']}>7</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '7.5']}>7.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBedrooms', '8+']}>8+</Dropdown.Item>
                                                                    
                                                                  </DropdownButton>
                                                                      
                                                              </div>
                                                          </div>

                                                                            {/* Bathrooms */} 
                                                                            <div class="col-md-2">
                                                            <div class="form-group">
                                                            <OverlayTrigger
                                                                    placement="auto"
                                                                    delay={{ show: 150, hide: 100 }} 
                                                                    trigger={["hover","click"]}
                                                                    overlay={<Tooltip className="messagetooltip">
                                                                  
                                                                      <div className='helpText'>The number of bathrooms the property has.
                                                                      
                                                                      <br></br><br></br>
                                                                      The number of bathrooms is used in the financial indicator<br></br>
                                                                      calculations when trying to locate comparable <br></br>
                                                                      properties in the area to determine trends.<br></br>
                                                                      <br></br>
                                                                      </div>
                                                                  </Tooltip>}
                                                                  >
                                                                  <div>
                                                                          <label class="dialog-form-label" for="numberBathroomsDropdownSelect">Bathrooms</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                  </div>
                                                                </OverlayTrigger>
                                                              
                                                                <DropdownButton id="numberBathroomsDropdownSelect" name="numberBathroomsDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.numberBathrooms} onSelect={(e) => this.HandleSelect(e)}>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '1']}>1</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '1.5']}>1.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '2']}>2</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '2.5']}>2.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '3']}>3</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '3.5']}>3.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '4']}>4</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '4.5']}>4.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '5']}>5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '5.5']}>5.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '6']}>6</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '6.5']}>6.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '7']}>7</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '7.5']}>7.5</Dropdown.Item>
                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['numberBathrooms', '8+']}>8+</Dropdown.Item>
                                                                    
                                                                  </DropdownButton>
                                                                      
                                                              </div>
                                                          </div>



                                                          <div class="col-md-2">
                                                                          
                                                              <div class="form-group">
                                                                  <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip  className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>The year the property was built.
                                                                          
                                                                          <br></br><br></br>
                                                                  The year built doesn't impact the financial indicators,<br></br>
                                                                  this is only informational.

                                                                  <br></br><br></br>
                                                                  <b>(OPTIONAL)</b>
                                                                          </div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="yearBuilt">Year Built</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                  </OverlayTrigger>
                                                              
                                                                    <input type="text" inputmode="numeric" class="form-select alert-edit-modal-component" id="yearBuilt" placeholder="" name="yearBuilt" defaultValue={this.state.yearBuilt} value={this.state.yearBuilt} onChange={this.handleYearInputChange}/>
                                                                    <span class="desktop-only-block">
                                                                      <input type="range" className="custom-range" id="yearBuiltAsNumber" name="yearBuiltAsNumber" min="1920" max="2030" step="1" value={this.state.yearBuiltAsNumber} onChange=  {this.ChangeYearSliderValue.bind(this)}/>  
                                                                    </span>

                                                                    <span class="mobile-only-block">
                                                                        <CustomizedSlider id="yearBuiltAsNumber" name="yearBuiltAsNumber" min={1920} max={2030} step={1} value={this.state.yearBuiltAsNumber} onChange=  {this.ChangeCustomYearSliderValue.bind(this)}/> 
                                                                        <br></br>     
                                                                    </span>
                                                              </div>
                                                              
                                                          </div>


                                                        
                                                    </div>  {/* ----- End Row ----*/}


                                                  {/* Purchase Price */}   
                                                  <div class="row">
                                                  
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                    <div className='helpText'>Price refers to the asking price of the property.<br></br><br></br>Hopefully you can purchase the property for a price below its market value.<br></br>Many times homeowners need to sell their property fast for various reasons,<br></br>such as job relocation, divorce or need to raise money quickly and choose<br></br>to sell below market value to expedite the sale.
                                                                  
                                                                    <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                                If the property is listed you can get it from listing <br></br>
                                                                                websites like:<br></br><br></br>
                                                                                <ul>
                                                                                <li><a href="https://www.zillow.com/" target="blank">https://www.zillow.com/</a></li>
                                                                                <li><a href="https://www.remax.com/" target="blank">https://www.remax.com/</a></li>
                                                                                <li><a href="https://www.trulia.com/" target="blank">https://www.trulia.com/</a></li>
                                                                                <li><a href="https://www.redfin.com/" target="blank">https://www.redfin.com/</a></li>
                                                                                <li><a href="https://www.realtor.com/" target="blank">https://www.realtor.com/</a></li>
                                                                                </ul>

                                                                                If the property is not listed just call the seller<br></br>
                                                                                and ask for the asking price.

                                                                    <br></br><br></br>
                                                                    The property price is critical for various financial indicators.<br></br>  
                                                                    It is one of the most important property values.
                                                                    <br></br><br></br>
                                                                    
                                                                    
                                                                    </div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="propertyPrice">Price</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                            </OverlayTrigger>
                                                              
                                                              <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="propertyPrice" placeholder="Purchase Price $" name="propertyPrice" defaultValue={this.state.propertyPrice} value={this.state.propertyPrice} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                              <span class="desktop-only-block">
                                                                  <input type="range" className="custom-range" id="propertyPriceAsNumber" name="propertyPriceAsNumber" min="0" max="5000000" step="50000" value={this.state.propertyPriceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                </span>

                                                                <span class="mobile-only-block">
                                                                    <CustomizedSlider id="propertyPriceAsNumber" name="propertyPriceAsNumber" min={0} max={5000000} step={50000} value={this.state.propertyPriceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                    <br></br>     
                                                                </span>
                                                                
                                                              <label class="dialog-form-label" style={{fontSize:'14px',display:'none'}} id="marketValueLabel"><span id="marketValuePercent">{this.state.marketValuePercent}</span> {this.state.marketValuePercentAboveBelow} market value</label>  
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-md-4" id="marketValueColumn">
                                                        <div class="form-group">
                                                            <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                  <div class="desktop-only-block">
                                                                    <div className='helpText'>
                                                                      
                                                                      <div style={{paddingBottom:'9px',maxWidth:'420px'}}>
                                                                      The market value is the amount of money that a property would be sold for on
                                                                      the open market. The market value may or may not match the purchase price.
                                                                      </div>
                                                                      
                                                                      <div style={{color:"#ffa500",paddingTop:"10px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                      
                                                                      <div style={{paddingTop:'5px',maxWidth:'420px'}}>
                                                                      If the property is listed, some listing websites like <a href="https://www.zillow.com" target="blank">https://www.zillow.com</a> <br></br>
                                                                      sometimes give you an estimated market value:
                                                                      </div>

                                                                      <div style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                                                        <img src={zestimate} style={{height:'65px'}}/>
                                                                      </div>

                                                                      <div style={{paddingBottom:'9px'}}>
                                                                      It is not an appraisal and it should be used as a starting point. 
                                                                      </div>

                                                                      <div style={{paddingBottom:'9px',maxWidth:'420px'}}>  
                                                                      you can roughly determine the market value by comparing recent sales in the area.
                                                                      </div>
                                                          
                                                                      <div style={{paddingBottom:'3px',maxWidth:'420px'}}>
                                                                      <b>NOTE:</b>
                                                                      </div>

                                                                      <div style={{paddingBottom:'9px',maxWidth:'420px'}}>
                                                                      The best way to get the most accurate market value is to hire an appraiser.
                                                                      </div>

                                                                      <div class="desktop-only-block" style={{paddingBottom:'9px',maxWidth:'420px'}}>
                                                                      A home appraisal usually costs $300 to $450, but it depends on factors like property size, value, condition, and level of detail.
                                                                      </div>

                                                                      <div style={{paddingBottom:'9px',maxWidth:'420px'}}>
                                                                      The market value is critical for various financial indicator calculations.
                                                                      </div>
                                                                      </div>
                                                                  </div>    

                                                                  <div class="mobile-only-block">
                                                                  <div className='helpText'>
                                                                      
                                                                      <div style={{paddingBottom:'9px',maxWidth:'420px'}}>
                                                                      Market value is the potential sale price on the open market, which may differ from the purchase price.
                                                                      </div>
                                                                      
                                                                      <div style={{color:"#ffa500",paddingTop:"10px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                      
                                                                      <div style={{paddingTop:'5px',maxWidth:'420px'}}>
                                                                      If the property is listed, some listing websites like <a href="https://www.zillow.com" target="blank">https://www.zillow.com</a> <br></br>
                                                                      sometimes give you an estimated market value:
                                                                      </div>

                                                                      <div style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                                                        <img src={zestimate} style={{height:'65px'}}/>
                                                                      </div>

                                                                      <div style={{paddingBottom:'9px'}}>
                                                                      It is not an appraisal and it should be used as a starting point. 
                                                                      </div>

                                                                      <div style={{paddingBottom:'9px',maxWidth:'420px'}}>  
                                                                      you can roughly determine the market value by comparing recent sales in the area. 
                                                                      however, the best way to get the most accurate market value is to hire an appraiser.
                                                                      </div>
                                                          
                                                                      <div class="desktop-only-block" style={{paddingBottom:'9px',maxWidth:'420px'}}>
                                                                      A home appraisal usually costs $300 to $450, but it depends on factors like property size, value, condition, and level of detail.
                                                                      </div>

                                                                      <div style={{paddingBottom:'9px',maxWidth:'420px'}}>
                                                                      The market value is critical for various financial indicator calculations.
                                                                      </div>
                                                                      </div>
                                                                  </div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="marketValue">Market Value  <span style={{fontSize:'11px',color:'#FFFFFF'}}>(Appraisal)</span></label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                </div>
                                                            </OverlayTrigger>
                                                              
                                                            <table>
                                                              <tr>
                                                                <td  class="compound-column-width">
                                                      
                                                              <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="marketValue" placeholder="Market Price $" name="marketValue" defaultValue={this.state.marketValue} value={this.state.marketValue} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                              
                                                              </td>
                                                                      <td style={{width:'20%'}}>
                                                                      {this.state.similarSoldProperties?.length > 0 &&
                                                                              <OverlayTrigger   
                                                                                placement="auto" 
                                                                                rootClose={true}
                                                                                container={this.mainModalInfoDialogRef}
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["click"]} 
                                                                                overlay={<Tooltip  id="mortgagePaymentBreakdown" name="mortgagePaymentBreakdown"  className="messagetooltip">
                                                                              
                                                                                  <div className='helpText'>
                                                                                      <div class="orangeInfo" style={{textAlign:'center'}}>Comparable Properties</div>

                                                                                        {this.state.similarSoldProperties?.map((similarProperty, i) => (
                                                                                            <div class="row">

                                                                                            <div class="col-md-12" style={{borderBottom: '1px solid #FFFFFF',padding:'10px'}}>
                                                                                              <div style={{fontWeight:'500',fontSize:'17px'}}>{this.FormatMoney(this.ConvertFormmattedNumberToNumber(similarProperty.price))}</div> 
                                                                                              {similarProperty.address} &nbsp; &nbsp; 
                                                                                              <a href={similarProperty.link} target='_blank' style={{float:'right'}}> 
                                                                                                <Link45deg className="ml-2 orangeInfo" style={{fontSize:'25px'}}/>
                                                                                              </a>
                                                                                              
                                                                                              <div style={{paddingTop:'8px',paddingBottom:'8px'}}>{similarProperty.beds} bd | {similarProperty.baths} ba | {this.FormatNumber(similarProperty.sqFt)} sqft</div>
                                                                                              {(similarProperty.soldDate != null && similarProperty.soldDate !='') && 
                                                                                                <span><u>DATE SOLD</u>: <span style={{fontWeight:'500'}}>{similarProperty.soldDate}</span></span>
                                                                                              }
                                                                                            </div> 
        
                                                                                            </div>
        
                                                                                        ))}  
              
                                                                                  </div>

                                                                                  
                                                                              </Tooltip>}
                                                                              >
                                                                                  <House className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                              </OverlayTrigger>
                                                                          }
                                                                          </td>
                                                                        </tr>

                                                                        <tr>
                                                                          <td style={{width:'80%'}}>
                                                                            <span class="desktop-only-block">
                                                                              <input type="range" className="custom-range" id="marketValueAsNumber" name="marketValueAsNumber" min="0" max="5000000" step="50000" value={this.state.marketValueAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                            </span>

                                                                            <span class="mobile-only-block">
                                                                              <CustomizedSlider  id="marketValueAsNumber" name="marketValueAsNumber" marks min={0} max={5000000} step={50000} defaultValue={30} value={this.state.marketValueAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                            <br></br>
                                                                            </span>

                                                                            </td>
                                                                          <td style={{width:'20%'}}></td>
                                                                        </tr>
                                                                    </table>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-md-6" id="afterRepairValueColumn" style={{display:'none'}}>
                                                      <div class="form-group">
                                                          <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                              
                                                                  <div className='helpText'>

                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                    It is the estimated property’s value after it is fully renovated.
                                                                  </div>

                                                                  <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                    You can determine the ARV by referencing nearby comparable properties with similar conditions to your rehabbed property that have recently sold.
                                                                    
                                                                  </div>
                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                  The After Repair Value (AVR) is critical for various financial indicators.<br></br>  
                                                                    It is one of the most important property values.
                                                                    </div>
                                                                  </div>

                                                              </Tooltip>}
                                                              >
                                                              <div>
                                                                      <label class="dialog-form-label" for="afterRepairValue">After Repair Value (AVR)</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                              </div>
                                                          </OverlayTrigger>
                                                            
                                                            <table>
                                                              <tr>
                                                                <td  class="compound-column-width">
                                                      
                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="afterRepairValue" placeholder="After Repair Value $" name="afterRepairValue" defaultValue={this.state.afterRepairValue} value={this.state.afterRepairValue} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                </td>
                                                                      <td style={{width:'20%'}}>
                                                                      {this.state.similarSoldProperties?.length > 0 &&
                                                                              <OverlayTrigger   
                                                                                placement="auto" 
                                                                                rootClose={true}
                                                                                container={this.mainModalInfoDialogRef}
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["click"]} 
                                                                                overlay={<Tooltip  id="mortgagePaymentBreakdown" name="mortgagePaymentBreakdown"  className="messagetooltip">
                                                                              
                                                                                  <div className='helpText'>
                                                                                      <div class="orangeInfo" style={{textAlign:'center'}}>Comparable Properties</div>

                                                                                        {this.state.similarSoldProperties?.map((similarProperty, i) => (
                                                                                            <div class="row">

                                                                                            <div class="col-md-12" style={{borderBottom: '1px solid #FFFFFF',padding:'10px'}}>
                                                                                              <div style={{fontWeight:'500',fontSize:'17px'}}>{this.FormatMoney(similarProperty.price)}</div> 
                                                                                              {similarProperty.address} &nbsp; &nbsp; 
                                                                                              <a href={similarProperty.link} target='_blank' style={{float:'right'}}> 
                                                                                                <Link45deg className="ml-2 orangeInfo" style={{fontSize:'25px'}}/>
                                                                                              </a>
                                                                                              
                                                                                              <div style={{paddingTop:'8px',paddingBottom:'8px'}}>{similarProperty.beds} bd | {similarProperty.baths} ba | {this.FormatNumber(similarProperty.sqFt)} sqft</div>
                                                                                              {(similarProperty.soldDate != null && similarProperty.soldDate !='') && 
                                                                                                <span><u>DATE SOLD</u>: <span style={{fontWeight:'500'}}>{similarProperty.soldDate}</span></span>
                                                                                              }
                                                                              
                                                                                            </div> 
        
                                                                                            </div>
        
                                                                                        ))}  
              
                                                                                  </div>
                                                                                  <div style={{textAlign:'left'}}>
                                                                                      AVR =  (
                                                                                      {this.state.similarSoldProperties?.map((similarProperty, i) => (
                                                                                        <span>{this.FormatMoney(similarProperty.price)} 
                                                                                        
                                                                                      {i < this.state.similarSoldProperties.length-1 &&
                                                                                      <span> +</span> 
                                                                                      }
                                                                                        
                                                                                        </span> 
                                                                                      ))}
                                                                                      ) / {this.state.similarSoldProperties.length}
                                                                                      <br></br><br></br>
                                                                                      AVR = {this.state.afterRepairValue}
                                                                                      <br></br><br></br>
                                                                                  </div>
                                                                              </Tooltip>}
                                                                              >
                                                                                  <House className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                              </OverlayTrigger>
                                                                          }
                                                                          </td>
                                                                        </tr>

                                                                        <tr>
                                                                          <td style={{width:'80%'}}>
                                                                          <span class="desktop-only-block">
                                                                              <input type="range" className="custom-range" id="afterRepairValueAsNumber" name="afterRepairValueAsNumber" min="0" max="5000000" step="50000" value={this.state.afterRepairValueAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                          </span>
                                                                          <span class="mobile-only-block">
                                                                              <CustomizedSlider id="afterRepairValueAsNumber" name="afterRepairValueAsNumber" min={0} max={5000000} step={50000} value={this.state.afterRepairValueAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                              <br></br>     
                                                                          </span>

                                                                          </td>
                                                                          <td style={{width:'20%'}}></td>
                                                                        </tr>
                                                                    </table>
                                                        </div>
                                                      
                                                  </div>
                                              
                                                  </div> {/* ----- End Row ----*/}

                                  
                                                  {/* Holding Months */}   
                                                  <div class="row" id="holdingMonthsRow" style={{display:'none'}}>

                                                          <div class="col-md-6">
                                                                    <div class="form-group">
                                                                    <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The number of months you plan to hold the property before reselling it.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                Note that most lenders won’t lend on properties owned for less than 90 days. Which means buyers might not be able to get a loan to buy your property.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                    The number of months you will hold property impacts many financial indicator calculations.
                                                                                </div>
                                                                                
                                                                                </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="holdingMonthsDropdownSelect">Number of Months <br></br>You Will Hold Property</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                        </OverlayTrigger>
                                                                        
                                                                      <div style={{maxWidth:'30%'}}>  
                                                                        <input type="text" inputmode="numeric" class="form-select alert-edit-modal-component" id="holdingMonths" placeholder="" name="holdingMonths" defaultValue={this.state.holdingMonths} value={this.state.holdingMonths} onChange={this.handleNumberInputChange}/>
                                                                        <span class="desktop-only-block">  
                                                                          <input type="range" className="custom-range" id="holdingMonthsAsNumber" name="holdingMonthsAsNumber" min="0" max="24" step="1" value={this.state.holdingMonthsAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                            <CustomizedSlider id="holdingMonthsAsNumber" name="holdingMonthsAsNumber" min={0} max={24} step={1} value={this.state.holdingMonthsAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                                          <br></br>     
                                                                        </span>
  
                                                                      </div>
                                                                        
                                                                    </div>
                                                                </div>

                                                  </div> {/* ----- End Row ----*/}

                                                    {/* ----------------------------------------- FINANCING -------------------------------------------- */}
                                                  
                                                  <div class="row space-bottom">
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleFinancingExpand(e, this)}>FINANCING <span id="financingPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="financingContent" style={{display:'none',paddingTop:'5px'}}>
                                                          
                                                        <div class="row">
                                                  
                                                              {/* Financing Type */} 
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                    <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                                How do you plan to finance this property?
                                                                              
                                                                                <ul style={{paddingTop:'5px'}}>
                                                                                  <li><span style={{color:"#ffa500", fontWeight:"bold"}}>LOAN</span>: Means you are getting a mortgage to buy the property.</li>
                                                                                  <li><span style={{color:"#ffa500", fontWeight:"bold"}}>CASH</span>: Means you are paying for the property out of pocket.</li>
                                                                                </ul>
                                                                                
                                                                                The financing type significantly affects the outcome of <br></br>
                                                                                the financial indicators.
                                                                                <br></br>
                                                                              </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="financingTypeDropdownSelect">Financing Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                        </OverlayTrigger>
                                                                        
                                                                        <DropdownButton id="financingTypeDropdownSelect" name="financingTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.financingType} onSelect={(e) => this.HandleSelect(e)}>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['financingType', 'Loan']}>Loan</Dropdown.Item>
                                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['financingType', 'Cash']}>Cash</Dropdown.Item>
                                                                        </DropdownButton>
                                                                        
                                                                    </div>
                                                                </div>
                                                          
                                                                
                                                                <div class="col-md-4" id="downPaymentColumn">
                                                                          
                                                                <div class="form-group">
                                                                    <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip  className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              A down payment is the initial, upfront payment you make when purchasing a home. 
                                                                            </div>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                            Traditionally, a mortgage down payment is at least 5% of a home's sale price.
                                                                            </div>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>      
                                                                            <span style={{color:"#ffa500", fontWeight:"bold"}}>20%</span> down payment is the gold standard for homebuying. 
                                                                            </div>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              The down payment is used in many financial indicator calculations.
                                                                            </div>

                                                                              </div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="downPayment">Down Payment</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                      
                                                                      <table>
                                                                        <tr>
                                                                          <td class="compound-column-width">
                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="downPayment" placeholder="Down Payment $" name="downPayment" defaultValue={this.state.downPayment} value={this.state.downPayment} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                          </td>
                                                                          <td style={{width:'20%'}}>
                                                                            <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip  className="messagetooltip">
                                                                                  <div className='helpText'>
                                                                                      
                                                                                    <div style={{paddingBottom:'3px',fontSize:'11px'}}>
                                                                                    Purchase Price = {this.FormatMoney(this.state.propertyPriceAsNumber)} 
                                                                                    </div>

                                                                                    <div style={{paddingBottom:'7px',fontSize:'11px'}}>
                                                                                    Down Payment = {this.FormatMoney(this.state.downPaymentAsNumber)}  
                                                                                    </div>

                                                                                    <div style={{paddingBottom:'5px'}}>
                                                                                  <b>{this.state.downPaymentPercent}</b> of <b>{this.FormatMoney(this.state.propertyPriceAsNumber)}</b> = <b>{this.FormatMoney(this.state.downPaymentAsNumber)}</b>
                                                                                  </div>

                                                                                  </div>
                                                                              </Tooltip>}
                                                                              >
                                                                              <div>
                                                                                <label class="dialog-form-label orangeLabel" id="downPaymentPercent">{this.state.downPaymentPercent}</label>
                                                                              </div>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <td  class="compound-column-width">
                                                                            <span class="desktop-only-block">
                                                                              <input type="range" className="custom-range" id="downPaymentAsNumber" name="downPaymentAsNumber" min="0" max="800000" step="5000" value={this.state.downPaymentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                            </span>

                                                                            <span class="mobile-only-block">
                                                                                <CustomizedSlider id="downPaymentAsNumber" name="downPaymentAsNumber" min={0} max={800000} step={5000} value={this.state.downPaymentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                <br></br>     
                                                                            </span>

                                                                          </td>
                                                                          <td style={{width:'20%'}}></td>
                                                                        </tr>
                                                                      </table>
                                                                      
                                                                        
                                                                </div>
                                                                
                                                            </div>

                                                        </div>  {/* ----- End Row ----*/}

                                                        <div id="loanFinancingBlock">  
  
                                                        <div class="row">
                                                  
                                                              {/* Term Type */} 
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                    <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                                
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The term of your mortgage loan is how long you have to repay the loan. 
                                                                              </div>
                                                                                
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>  
                                                                                For most types of properties, mortgage terms are typically <span style={{color:"#ffa500", fontWeight:"bold"}}>15</span>, <span style={{color:"#ffa500", fontWeight:"bold"}}>20</span> or <span style={{color:"#ffa500", fontWeight:"bold"}}>30</span> years.
                                                                              </div>

                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The loan term has an impact on the financial indicator calculations.
                                                                              </div>
                                                                                
                                                                              </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="termDropdownSelect">Loan Term</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                        </OverlayTrigger>
                                                                        
                                                                        <DropdownButton id="termDropdownSelect" name="termDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.term} onSelect={(e) => this.HandleSelect(e)}>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['term', '15 Years']}>15 Years</Dropdown.Item>
                                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['term', '20 Years']}>20 Years</Dropdown.Item>
                                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['term', '30 Years']}>30 Years</Dropdown.Item>
                                                                                    <div style={{paddingBottom:"8px"}}></div>
                                                                                    <Dropdown.Item className="dropdownitem"  eventKey={['term', '40 Years']}>40 Years</Dropdown.Item>
                                                                        </DropdownButton>
                                                                        
                                                                    </div>
                                                                </div>
                                                          
                                                                
                                                                <div class="col-md-4">
                                                                          
                                                                <div class="form-group">
                                                                    <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip  className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>

                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              The interest rate is the cost you will pay each year to borrow the money, expressed as a percentage rate.
                                                                              </div>

                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              Mortgage rates are determined by the lender.
                                                                              </div>

                                                                              <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                Mortgage interest rates vary based on how the economy is doing today and its outlook.
                                                                              </div>

                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              Economic growth indicators, such as gross domestic product (GDP) and the employment rate, influence mortgage interest rates.
                                                                              </div>
                                                                              
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>

                                                                              Below are a few websites that will tell you the average current mortagage interest rates:

                                                                              </div>

                                                                              <ul>
                                                                                <li><a href="https://www.nerdwallet.com/mortgages/mortgage-rates" target="blank">https://www.nerdwallet.com/mortgages/mortgage-rates</a></li>
                                                                                <li><a href="https://www.bankrate.com/mortgages/mortgage-rates/" target="blank">https://www.bankrate.com/mortgages/mortgage-rates/</a></li>
                                                                              </ul>

                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  The intest rate heavily impacts many financial indicator calculations.
                                                                              </div>

                                                                            </div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="interestRate">Interest Rate</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                    <table>
                                                                        <tr>
                                                                          <td  class="compound-column-width">
                                                                      <PercentInput type="text" class="form-select alert-edit-modal-component" id="interestRate" placeholder="%" name="interestRate" defaultValue={this.state.interestRate} value={this.state.interestRate} onChange={this.handlePercentInputChange}  onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                      <span class="desktop-only-block">
                                                                          <input type="range" className="custom-range" id="interestRateAsNumber" name="interestRateAsNumber" min="0" max="12" step=".25" value={this.state.interestRateAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                                      </span>

                                                                      <span class="mobile-only-block">
                                                                      <CustomizedSlider id="interestRateAsNumber" name="interestRateAsNumber" min={0} max={12} step={.25} value={this.state.interestRateAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                                      <br></br>     
                                                                      </span>

                                                                      </td>
                                                                      <td style={{width:'20%'}}>
                                                                        &nbsp;

                                                                        </td>
                                                                        </tr>
                                                                      </table>


                                                                </div>
                                                                
                                                            </div>

                                                        </div>  {/* ----- End Row ----*/}


                                                        <div class="row">
                                                  
                                                  {/* Rate Type */} 
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                        <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip className="messagetooltip">
                                                                  <div className='helpText'>
                                                                      <div style={{paddingBottom:'9px',maxWidth:'520px'}}>      

                                                                          There are two main types of mortgage interest rates:
                                                                          <ul style={{paddingTop:'8px'}}>
                                                                            <li><span style={{color:"#ffa500", fontWeight:"bold"}}>FIXED RATE:</span> A fixed rate loan has the same interest rate for the entirety of the borrowing period.<br></br><br></br></li>
                                                                            <li><span style={{color:"#ffa500", fontWeight:"bold"}}>VARIABLE RATE:</span> Variable rate loans have an interest rate that changes over time.</li>  
                                                                          </ul>  
                                                              
                                                                          <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                            Currently the application only supports Fixed Rate. 
                                                                            The loan type is used in many financial indicator calculations.
                                                                        </div>
                                                                      </div>        
                                                                            

                                                                  </div>
                                                              </Tooltip>}
                                                              >
                                                              <div>
                                                                      <label class="dialog-form-label" for="loanTypeDropdownSelect">Loan Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                              </div>
                                                            </OverlayTrigger>
                                                            
                                                            <DropdownButton id="loanTypeDropdownSelect" name="loanTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.loanType} onSelect={(e) => this.HandleSelect(e)}>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['loanType', 'Fixed']}>Fixed</Dropdown.Item>

                                                                        {/* We don't have the calculations for variable interest

                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['loanType', 'Variable']}>Variable</Dropdown.Item>

                                                                        */}
                                                            </DropdownButton>
                                                            
                                                        </div>
                                                    </div>
                                              
                                                    
                                                    <div class="col-md-4">
                                                                  <div class="form-group"  class="repayment-type-column-width">
                                                                  <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>
                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  The repayment type indicates whether you will be paying principal & interest <br></br>or only interest on your monthly payments.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  The repayment type has an impact on the financial indicator calculators.
                                                                                </div>

                                                                              </div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="repaymentTypeDropdownSelect">Repayment Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                      </OverlayTrigger>
                                                                      
                                                                      <DropdownButton id="repaymentTypeDropdownSelect" name="repaymentTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.repaymentType} onSelect={(e) => this.HandleSelect(e)}>
                                                                                  <Dropdown.Item className="dropdownitem"  eventKey={['repaymentType', 'Principal & Interest']}>Principal & Interest</Dropdown.Item>
                                                                      
                                                                                  {/* The calculations don't support interest only yet
                                                                                  <div style={{paddingBottom:"8px"}}></div>
                                                                                  <Dropdown.Item className="dropdownitem"  eventKey={['repaymentType', 'Interest Only']}>Interest Only</Dropdown.Item>
                                                                                  */}
                                                                      </DropdownButton>
                                                                      
                                                                  </div>
                                                              </div>
                                                        

                                                        </div>  {/* ----- End Row ----*/}
  
                                                      <br></br>                   
                                                    </div>  {/* ----- End Loan Finacing Block ----*/}

                                                        <div class="row">
                                                  
                                                            {/* Closing Costs */} 

                                                    <div class="col-md-4">
                                                              
                                                          <div class="form-group">
                                                              <OverlayTrigger
                                                                    placement="auto"
                                                                    delay={{ show: 150, hide: 100 }} 
                                                                    trigger={["hover","click"]}
                                                                    overlay={<Tooltip  className="messagetooltip">
                                                                  
                                                                    <div>
                                                                    {this.state.financingType == "Loan" && 
                                                                      <div className='helpText'>
                                                                        <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                          Mortgage closing costs are fees and expenses you pay when you secure a loan for a property, beyond the down payment.
                                                                        </div>

                                                                        <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                          These costs are generally <span style={{color:"#ffa500", fontWeight:"bold"}}>2</span> to <span style={{color:"#ffa500", fontWeight:"bold"}}>5</span> percent of the loan amount and may include title insurance, attorney fees, appraisals, taxes and more.
                                                                        </div>
                                                                        
                                                                        <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                          The closing costs have an impact on the financial indicator calculators.
                                                                        </div>

                                                                      </div>
                                                                    }

                                                                    {this.state.financingType == "Cash" && 
                                                                      <div className='helpText'>
                                                                         <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                         Cash buyers skip many closing costs, but there are still plenty of other fees to pay at closing for the buyer and the seller.
                                                                        </div>

                                                                        <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                         Most fees will be associated with title work, transfer fees, taxes, and insurance. 
                                                                        </div>
                                                                      </div>
                                                                    }
                                                                    </div>
                                                                  
                                                                    
                                                                  </Tooltip>}
                                                                  >
                                                                  <div>
                                                                          <label class="dialog-form-label" for="closingCosts">Closing Costs</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                  </div>
                                                              </OverlayTrigger>
                                                                
                                                                  <table>
                                                                        <tr>
                                                                          <td class="compound-column-width">
                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="closingCosts" placeholder="Down Payment $" name="closingCosts" defaultValue={this.state.closingCosts} value={this.state.closingCosts} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                          </td>
                                                                          <td style={{width:'20%'}}>
                                                                            <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip  className="messagetooltip">
                                                                                  
                                                                                  <div className='helpText'>
                                                                                    
                                                                                  <div style={{paddingBottom:'3px',fontSize:'11px'}}>
                                                                                    Loan Amount = {this.FormatMoney(this.state.propertyPriceAsNumber)} - {this.FormatMoney(this.state.downPaymentAsNumber)}
                                                                                    </div>

                                                                                    <div style={{paddingBottom:'3px',fontSize:'11px'}}>
                                                                                    Loan Amount = {this.FormatMoney(this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber)}
                                                                                    </div>

                                                                                    <div style={{paddingBottom:'7px',fontSize:'11px'}}>
                                                                                    Closing Costs = {this.FormatMoney(this.state.closingCostsAsNumber)}  
                                                                                    </div>

                                                                                    <div style={{paddingBottom:'5px'}}>
                                                                                  <b>{(this.state.propertyPriceAsNumber > 0 && this.state.downPaymentAsNumber > 0) &&
                                                                                      <label>{this.FormatNumberToPercentOneDecimal((this.state.closingCostsAsNumber * 100) / (this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber))}</label>
                                                                                      }</b> of <b>{this.FormatMoney(this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber)}</b> = <b>{this.FormatMoney(this.state.closingCostsAsNumber)}</b>
                                                                                  </div>

                                                                                    
                                                                                  </div>
                                                                              </Tooltip>}
                                                                              >

                                                                              <div>                                                                               
                                                                                {this.state.financingType == "Loan" && 
                                                                                  <div>
                                                                                    {/*
                                                                                    <label class="dialog-form-label orangeLabel" id="closingCostsPercent">{this.state.closingCostsPercent}</label>
                                                                                    */}
                                                                                      {(this.state.propertyPriceAsNumber > 0 && this.state.downPaymentAsNumber > 0) &&
                                                                                      <label class="dialog-form-label orangeLabel" id="closingCostsPercent">{this.FormatNumberToPercentOneDecimal((this.state.closingCostsAsNumber * 100) / (this.state.propertyPriceAsNumber - this.state.downPaymentAsNumber))}</label>
                                                                                      }
                                                                                  </div>
                                                                                }
                                                                              </div>
                                                                             
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <td  class="compound-column-width">
                                                                            <span class="desktop-only-block">
                                                                              <input type="range" className="custom-range" id="closingCostsAsNumber" name="closingCostsAsNumber" min="0" max="800000" step="5000" value={this.state.closingCostsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                            </span>

                                                                            <span class="mobile-only-block">
                                                                                <CustomizedSlider id="closingCostsAsNumber" name="closingCostsAsNumber" min={0} max={800000} step={5000} value={this.state.closingCostsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                <br></br>     
                                                                            </span>

                                                                          </td>
                                                                          <td style={{width:'20%'}}></td>
                                                                        </tr>
                                                                      </table>
                                                          </div>
                                                          
                                                      </div>           
                                                              
                                                      <div class="col-md-4" id="monthlyPaymentColumn" style={{visibility:'visible'}}>
                                                                        
                                                              <div class="form-group">
                                                                  <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip  className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>

                                                                          <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                          A mortgage payment is typically made up of four components: <span style={{color:"#ffa500"}}>principal</span>, <span style={{color:"#ffa500"}}>interest</span>, <span style={{color:"#ffa500"}}>taxes</span> and <span style={{color:"#ffa500"}}>insurance</span>. 
                                                                            </div>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              The <span style={{color:"#ffa500"}}>hoa</span> is sometimes added to the mortgage payment, but it isn't very common.
                                                                            </div>

                                                                            <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>

                                                                            <div style={{paddingBottom:'9px',paddingTop:'5px',maxWidth:'520px'}}>
                                                                              If you populate the following fields the application will calculate the 
                                                                              monthly payment for you:
                                                                            </div>

                                                                            <ul>
                                                                              <li><span style={{color:"#ffa500"}}>Price</span></li>
                                                                              <li><span style={{color:"#ffa500"}}>Financing Type</span></li>
                                                                              <li><span style={{color:"#ffa500"}}>Down Payment</span></li>
                                                                              <li><span style={{color:"#ffa500"}}>Loan Term</span></li>
                                                                              <li><span style={{color:"#ffa500"}}>Loan Type</span></li>
                                                                              <li><span style={{color:"#ffa500"}}>Repayment Type</span></li>
                                                                            </ul>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              The monthly payment has an impact on the financial indicator calculators.
                                                                            </div>

                                                                            </div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="monthlyPayment">Monthly Payment</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                  </OverlayTrigger>
                                                                    
                                                                    <table>
                                                                        <tr>
                                                                          <td  class="compound-column-width">
                                                                        
                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="monthlyPayment" placeholder="$" name="monthlyPayment" defaultValue={this.state.monthlyPayment} value={this.state.monthlyPayment} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                
                                                                          </td>
                                                                          <td style={{width:'20%'}}>
                                                                                  <OverlayTrigger   
                                                                                placement="auto" 
                                                                                rootClose={true}
                                                                                container={this.mainModalInfoDialogRef}
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["click"]} 
                                                                                overlay={<Tooltip  id="mortgagePaymentBreakdown" name="mortgagePaymentBreakdown"  className="messagetooltip">
                                                                              
                                                                                  <div className='helpText'>

                                                                                        <div class="orangeInfo" style={{textAlign:'center'}}>Monthly Payment Breakdown</div>
                                                                                        <br></br>
                                                                                        <div class="row">
                                                                                            <div class="col-md-6">
                                                                                                <label class="dialog-form-label" for="principalAndInterest">Principal & Interest</label>
                                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="principalAndInterest" placeholder="$" name="principalAndInterest" defaultValue={this.state.principalAndInterest} value={this.state.principalAndInterest} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                <input type="range" className="custom-range" id="principalAndInterestAsNumber" name="principalAndInterestAsNumber" min="0" max="10000" step="100" value={this.state.principalAndInterestAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                                    
                                                                                            </div>
                                                                                        
                                                                                            <div class="col-md-6">
                                                                                                <label class="dialog-form-label" for="propertyTaxes">Property Taxes</label>
                                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="propertyTaxes" placeholder="$" name="propertyTaxes" defaultValue={this.state.propertyTaxes} value={this.state.propertyTaxes} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                <input type="range" className="custom-range" id="propertyTaxesAsNumber" name="propertyTaxesAsNumber" min="0" max="8000" step="100" value={this.state.propertyTaxesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  

                                                                                              </div>
                                                                                          </div>  

                                                                                          <div class="row">
                                                                                        
                                                                                              <div class="col-md-6">  
                                                                                        
                                                                                                <label class="dialog-form-label" for="homeownersInsurance">Homeowners Insurance</label>
                                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="homeownersInsurance" placeholder="$" name="homeownersInsurance" defaultValue={this.state.homeownersInsurance} value={this.state.homeownersInsurance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                <input type="range" className="custom-range" id="homeownersInsuranceAsNumber" name="homeownersInsuranceAsNumber" min="0" max="8000" step="100" value={this.state.homeownersInsuranceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                              </div>

                                                                                              <div class="col-md-6">

                                                                                                <label class="dialog-form-label" for="hoa">HOA Fees</label>
                                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="hoa" placeholder="$" name="hoa" defaultValue={this.state.hoa} value={this.state.hoa} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                <input type="range" className="custom-range" id="hoaAsNumber" name="hoaAsNumber" min="0" max="8000" step="100" value={this.state.hoaAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                              </div>
                                                                                          </div>
                                                                                          <div class="row">
                                                                                        
                                                                                              <div class="col-md-6">  
                                                                                        
                                                                                                <label class="dialog-form-label" for="otherMonthlyPayment">Other</label>
                                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="otherMonthlyPayment" placeholder="$" name="otherMonthlyPayment" defaultValue={this.state.otherMonthlyPayment} value={this.state.otherMonthlyPayment} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                <input type="range" className="custom-range" id="otherMonthlyPaymentAsNumber" name="otherMonthlyPaymentAsNumber" min="0" max="8000" step="100" value={this.state.otherMonthlyPaymentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                              </div>

                                                                                    
                                                                                          </div>           
                                                                                      
                                                                                        <div  style={{textAlign:'center',paddingBottom:'5px'}}>TOTAL:<br></br>{this.FormatMoney(this.state.monthlyPaymentAsNumber)}</div>
                                                                                      

                                                                                        
                                                                                        <div style={{textAlign:"center"}}>
                                                                                            <Button style={{fontSize:"16px"}} onClick={e => this.HandleMortgagePaymentBreakClose(e, this)}>DONE</Button> 
                                                                                        </div>   
              
                                                                                  </div>
                                                                              </Tooltip>}
                                                                              >
                                                                                <FileEarmarkText className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                              
                                                                              </OverlayTrigger>

                                                                          </td>
                                                                        </tr>

                                                                        <tr>
                                                                          <td style={{width:'80%'}}>
                                                                            <span class="desktop-only-block">
                                                                                <input type="range" className="custom-range" id="monthlyPaymentAsNumber" name="monthlyPaymentAsNumber" min="0" max="5000" step="100" value={this.state.monthlyPaymentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                            </span>

                                                                            <span class="mobile-only-block">
                                                                                <CustomizedSlider id="monthlyPaymentAsNumber" name="monthlyPaymentAsNumber" min={0} max={5000} step={100} value={this.state.monthlyPaymentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                <br></br>     
                                                                            </span>

                                                                          </td>
                                                                          <td style={{width:'20%'}}></td>
                                                                        </tr>
                                                                    </table>
                                                              </div>
                                                              
                                                          </div>

                                                      </div>  {/* ----- End Row ----*/}


                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* ----------------------------------------- RENTAL -------------------------------------------- */}
                                                    
                                                    <div class="row space-bottom" style={{paddingTop:'10px',display:'none'}} id="rentalBlock">
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleRentalExpand(e, this)}>RENTAL INFORMATION  <span id="rentalPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="rentalContent" style={{display:'none',paddingTop:'5px'}}>
                                                          
                                                      
                                                            <div class="row">
                                                                
                                                                {/* Monthly Rent */} 
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The monthly rent you will charge tenants.
                                                                                </div>
                                                                                
                                                                                <div style={{color:"#ffa500",paddingTop:"10px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                      
                                                                                <div style={{paddingTop:'5px',maxWidth:'520px'}}>
                                                                                <span style={{color:"#ffa500", fontWeight:"bold"}}>1)</span> If the property is listed, some listing websites like <a href="https://www.zillow.com" target="blank">https://www.zillow.com</a> <br></br>
                                                                                sometimes give your an estimated rental value:
                                                                                </div>

                                                                                <div style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                                                                  <img src={rentzestimate} style={{height:'75px'}}/>
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',paddingTop:'9px'}}>
                                                                                <span style={{color:"#ffa500", fontWeight:"bold"}}>2)</span> You can also use the following website to find the median rent in the area:
                                                                                <div style={{paddingTop:'10px'}}>
                                                                                <a href="https://www.zumper.com/rent-research/" target="blank">https://www.zumper.com/rent-research/</a>
                                                                                </div>                        
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  The monthly rent is used in many financial indicator calculations.
                                                                              </div>
                                                                                
                                                                                </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="monthlyRent">Monthly Rent</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="monthlyRent" placeholder="$" name="monthlyRent" defaultValue={this.state.monthlyRent} value={this.state.monthlyRent} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                        <span class="desktop-only-block">
                                                                          <input type="range" className="custom-range" id="monthlyRentAsNumber" name="monthlyRentAsNumber" min="0" max="8000" step="100" value={this.state.monthlyRentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                            <CustomizedSlider id="monthlyRentAsNumber" name="monthlyRentAsNumber" min={0} max={8000} step={100} value={this.state.monthlyRentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                            <br></br>     
                                                                        </span>
                                                                        
                                                                        </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                      <div class="form-group">
                                                                          <OverlayTrigger
                                                                              placement="auto"
                                                                              delay={{ show: 150, hide: 100 }} 
                                                                              trigger={["hover","click"]}
                                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                            
                                                                                <div className='helpText'>The total expenses to keep the rental property.<br></br><br></br>
                                                                                
                                                                                The application will pre-populate these fields with the following values. <br></br>
                                                                                They are just estimtes, you should adjust them according to your needs:<br></br><br></br>
                                                                                
                                                                                <ul>
                                                                                    <li>Utilities = <b>{this.FormatMoney(this.state.settings?.utilitiesMonthlyCost)}</b></li>
                                                                                    <li>Maintenance = <b>{this.FormatMoney(parseInt((this.state.monthlyRentAsNumber * this.state.settings?.maintenanceRentPercent)/100))}</b> ({this.state.settings?.maintenanceRentPercent}% of monthly rent)</li>
                                                                                    <li>Book Keeping = <b>{this.FormatMoney(this.state.settings?.bookkeepingMonthlyCost)}</b></li>
                                                                                    <li>Property Management =  <b>{this.FormatMoney(parseInt((this.state.monthlyRentAsNumber * this.state.settings?.propertyManagementPercent)/100))}</b> ({this.state.settings?.propertyManagementPercent}% of monthly rent)</li>
                                                                                    <li>Tax Filing = <b>{this.FormatMoney(this.state.settings?.taxFilingMonthlyCost)}</b></li>
                                                                                    <li>Any other expenses you can think obout.</li>
                                                                                    <li>Monthly mortgage payment.</li>
                                                                                </ul>
                                                                                
                                                                                The pre-populated values are specified in Settings and <br></br>
                                                                                are simply best effort estimates, you should populate <br></br>
                                                                                these fields with your own values or change the default <br></br> 
                                                                                values in Settings.
            
                                                                                <div style={{paddingBottom:'9px',paddingTop:'9px',maxWidth:'520px'}}>
                                                                                  The monthly expenses is used in many financial indicator calculations.
                                                                              </div>

                                                                                </div>
                                                                            </Tooltip>}
                                                                            >
                                                                            <div>
                                                                                    <label class="dialog-form-label" for="monthlyExpenses">Monthly Expenses</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                          
                                                                        <table>
                                                                        <tr>
                                                                          <td  class="compound-column-width">
  
                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="monthlyExpenses" placeholder="Monthly Expenses $" name="monthlyExpenses" defaultValue={this.state.monthlyExpenses} value={this.state.monthlyExpenses} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                        
                                                                            </td>
                                                                            <td style={{width:'20%'}}>
                                                                                    <OverlayTrigger   
                                                                                  placement="auto" 
                                                                                  rootClose={true}
                                                                                  container={this.mainModalInfoDialogRef}
                                                                                  delay={{ show: 150, hide: 100 }} 
                                                                                  trigger={["click"]} 
                                                                                  overlay={<Tooltip  id="monthlyExpensesBreakdown" name="monthlyExpensesBreakdown"  className="messagetooltip">
                                                                                        
                                                                                        <div className='helpText'>

                                                                                            <div class="orangeInfo" style={{textAlign:'center'}}>Monthly Expenses Breakdown</div>
                                                                                              <br></br>

                                                                                              {/* ----- Breakdown Fields Begin ----*/}
                                                                                              {this.state.rentalOtherExpensesAsNumber >=0  && 
                                                                                              <div>
                                                                                                    <div style={{textAlign:'center'}}><b>Note</b>: Revise the pre-populated estimates for your property.</div>
                                                                                                    <br></br>
                                                                                                
                                                                                                    <div class="row">
                                                                                                          
                                                                                                          <div class="col-md-6">
                                                                                                          <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>In most cases, the tenant will pay the <br></br>majority of utilities, but services like <br></br>sewer and water are generally covered by the owner. <br></br>This expense can range from $30 – $200 per month, <br></br>depending on both the location of the property and <br></br>usage of utilities.<br></br><br></br>
                                                                                                                  <b>Note:</b> This field is being pre-populated <br></br>with an estimated values of {this.FormatMoney(this.state.rentalUtilitiesAsNumber)}  specified in <br></br>Settings. This is just a best effort estimate and <br></br>you should adjust it to your needs.
                                                                                                                  </div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                              <label class="dialog-form-label" for="rentalUtilities">Utilities</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                                              </div>
                                                                                                            </OverlayTrigger>

                                                                                                              <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalUtilities" placeholder="$" name="rentalUtilities" defaultValue={this.state.rentalUtilities} value={this.state.rentalUtilities} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                              <input type="range" className="custom-range" id="rentalUtilitiesAsNumber" name="rentalUtilitiesAsNumber" min="0" max="2000" step="100" value={this.state.rentalUtilitiesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            
          
                                                                                                          </div>
                                                                                                          <div class="col-md-6">
                                                                                                            
                                                                                                            <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>There is no hard rule on the costs of <br></br>monthly maintenance. However, most <br></br>experts recommend a maintenance budget <br></br>of anywhere between 10 to 15 percent of the<br></br>property rent.
                                                                                                                  
                                                                                                                  <br></br><br></br><b>Note:</b> This field is being pre-populated <br></br>with an estimated {this.state.settings?.maintenanceRentPercent}% of the rent, this  <br></br>percent is specified in Settings.  <br></br>This is just a best effort estimate and <br></br>you should adjust it to your needs.

                                                                                                                  </div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                                  <label class="dialog-form-label" for="rentalMaintenance">Maintenance</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                              
                                                                                                                  </div>
                                                                                                            </OverlayTrigger>
                                                                                                            <table>
                                                                                                              <tr>
                                                                                                                <td style={{width:'80%'}}>
                                                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalMaintenance" placeholder="$" name="rentalMaintenance" defaultValue={this.state.rentalMaintenance} value={this.state.rentalMaintenance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                                </td>
                                                                                                                <td style={{width:'20%'}}>
                                                                                                                        <OverlayTrigger
                                                                                                                        placement="auto"
                                                                                                                        delay={{ show: 150, hide: 100 }} 
                                                                                                                        trigger={["hover","click"]}
                                                                                                                        overlay={<Tooltip className="messagetooltipWhite">
                                                                                                                      
                                                                                                                          <div className='helpText'>{this.state.rentalMaintenancePercent} of {this.FormatMoney(this.state.monthlyRentAsNumber)} (monthly rent) is {this.FormatMoneyIfNotAlreadyFormatted(this.state.rentalMaintenance)}.</div>
                                                                                                                      </Tooltip>}
                                                                                                                      >
                                                                                                                      <div>
                                                                                                                          <label class="dialog-form-label orangeLabel" id="rentalMaintenancePercent">{this.state.rentalMaintenancePercent}</label>
                                                                                                                          </div>
                                                                                                                      </OverlayTrigger>
                                                                                                              </td>
                                                                                                              </tr>
                                                                                                              <tr>
                                                                                                                <td  class="compound-column-width"><input type="range" className="custom-range" id="rentalMaintenanceAsNumber" name="rentalMaintenanceAsNumber" min="0" max="2000" step="100" value={this.state.rentalMaintenanceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/></td>
                                                                                                                <td style={{width:'20%'}}></td>
                                                                                                              </tr>
                                                                                                            </table>            

                                                                                                          </div>
                                                                                                    </div>

                                                                                                    <div class="row">
                                                                                                          
                                                                                                    <div class="col-md-6">
                                                                                                          <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>Bookkeeping is the recording of financial <br></br>transactions for your rental properties. <br></br>Bookkeeping can run from $100 to $300 <br></br>dollars monthly.
                                                                                                                  
                                                                                                                  <br></br><br></br><b>Note:</b> This field is being pre-populated <br></br>with an estimated values of {this.FormatMoney(this.state.rentalBookkeepingAsNumber)} specified in <br></br>Settings. This is just a best effort estimate and <br></br>you should adjust it to your needs.

                                                                                                                  </div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                              <label class="dialog-form-label" for="rentalBookkeeping">Bookkeeping</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                              </div>
                                                                                                            </OverlayTrigger>

                                                                                                              <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalBookkeeping" placeholder="$" name="rentalBookkeeping" defaultValue={this.state.rentalBookkeeping} value={this.state.rentalBookkeeping} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                              <input type="range" className="custom-range" id="rentalBookkeepingAsNumber" name="rentalBookkeepingAsNumber" min="0" max="2000" step="100" value={this.state.rentalBookkeepingAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            

                                                                                                          </div>
                                                                                                          <div class="col-md-6">
                                                                                                          <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>The cost of property management <br></br>usually ranges from 6 to 10 percent <br></br>of the monthly rent.
                                                                                                                  
                                                                                                                  <br></br><br></br><b>Note:</b> This field is being pre-populated <br></br>with an estimated {this.state.settings?.propertyManagementPercent}% of the rent, this  <br></br>percent is specified in Settings.  <br></br>This is just a best effort estimate and <br></br>you should adjust it to your needs.

                                                                                                                  </div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                              <label class="dialog-form-label" for="rentalPropertyManagement">Property Management</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                              </div>
                                                                                                            </OverlayTrigger> 

                                                                                                            <table>
                                                                                                              <tr>
                                                                                                                <td style={{width:'80%'}}>

                                                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalPropertyManagement" placeholder="$" name="rentalPropertyManagement" defaultValue={this.state.rentalPropertyManagement} value={this.state.rentalPropertyManagement} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                              </td>
                                                                                                              <td style={{width:'20%'}}>
                                                                                                              <OverlayTrigger
                                                                                                                        placement="auto"
                                                                                                                        delay={{ show: 150, hide: 100 }} 
                                                                                                                        trigger={["hover","click"]}
                                                                                                                        overlay={<Tooltip className="messagetooltipWhite">
                                                                                                                      
                                                                                                                          <div className='helpText'>{this.state.rentalPropertyManagementPercent} of {this.FormatMoney(this.state.monthlyRentAsNumber)} (monthly rent) is {this.FormatMoneyIfNotAlreadyFormatted(this.state.rentalPropertyManagement)}.</div>
                                                                                                                      </Tooltip>}
                                                                                                                      >
                                                                                                                      <div>
                                                                                                                <label class="dialog-form-label orangeLabel" id="rentalPropertyManagementPercent">{this.state.rentalPropertyManagementPercent}</label>
                                                                                                                </div>
                                                                                                                      </OverlayTrigger>
                                                                                                            </td>
                                                                                                            </tr>
                                                                                                            <tr>  
                                                                                                              
                                                                                                            <td  class="compound-column-width"><input type="range" className="custom-range" id="rentalPropertyManagementAsNumber" name="rentalPropertyManagementAsNumber" min="0" max="2000" step="100" value={this.state.rentalPropertyManagementAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>     </td>       
                                                                                                            <td style={{width:'5%'}}></td>
                                                                                                              </tr>
                                                                                                            </table>
                                                                                                          </div>
                                                                                                    </div> 
      
                                                                                                    <div class="row">
                                                                                                    <div class="col-md-6">
                                                                                                          <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>This is the how much you will be an accountant <br></br>every year to do your rental property taxes, <br></br>it usually ranges from $500 to $2,000 per year. <br></br>Just devide that amount by 12 and enter here.
                                                                                                                  
                                                                                                                  <br></br><br></br><b>Note:</b> This field is being pre-populated <br></br>with an estimated values of {this.FormatMoney(this.state.rentalTaxFilingAsNumber)} specified in <br></br>Settings. This is just a best effort estimate and <br></br>you should adjust it to your needs.

                                                                                                                  </div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                              <label class="dialog-form-label" for="rentalTaxFiling">Tax Filing</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                              </div>
                                                                                                            </OverlayTrigger>
                                                                                                          
                                                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalTaxFiling" placeholder="$" name="rentalTaxFiling" defaultValue={this.state.rentalTaxFiling} value={this.state.rentalTaxFiling} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                                  <input type="range" className="custom-range" id="rentalTaxFilingAsNumber" name="rentalTaxFilingAsNumber" min="0" max="2000" step="100" value={this.state.rentalTaxFilingAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>           
                                                                                                              
                                                                                                          </div>
                                                                                                          <div class="col-md-6">
                                                                                                          <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>Other expenses.</div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                              <label class="dialog-form-label" for="rentalOtherExpenses">Other</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                              </div>
                                                                                                            </OverlayTrigger>
                                                                                                            <table>
                                                                                                              <tr>
                                                                                                                <td style={{width:'80%'}}>

                                                                                                              <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalOtherExpenses" placeholder="$" name="rentalOtherExpenses" defaultValue={this.state.rentalOtherExpenses} value={this.state.rentalOtherExpenses} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                              </td>
                                                                                                              <td style={{width:'20%'}}></td>
                                                                                                              </tr>
                                                                                                            <tr>  
                                                                                                              
                                                                                                            <td  class="compound-column-width"><input type="range" className="custom-range" id="rentalOtherExpensesAsNumber" name="rentalOtherExpensesAsNumber" min="0" max="2000" step="100" value={this.state.rentalOtherExpensesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/> </td>           
                                                                                                            <td style={{width:'5%'}}></td>
                                                                                                              </tr>
                                                                                                            </table>
                                                                                                          </div>
                                                                                                    </div> 

                                                                                                    <div class="row">
                                                                                                      
                                                                                                    <div class="col-md-6">
                                                                                                          <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>The monthly loan payment. It includes <br></br>principal & interest, property taxes, homeowners <br></br>insurance, and HOA fees. <br></br><br></br>This value comes from the financing monthly payment field.<br></br><br></br>
                                                                                                                  
                                                                                                                  <table>
                                                                                                                    <tr>
                                                                                                                      <td>Principal & Interest</td>
                                                                                                                      <td>:</td>
                                                                                                                      <td>{this.FormatMoney(this.state.principalAndInterestAsNumber)}</td>
                                                                                                                    </tr><tr>
                                                                                                                      <td>Property Taxes</td>
                                                                                                                      <td>:</td>
                                                                                                                      <td>{this.FormatMoney(this.state.propertyTaxesAsNumber)}</td>
                                                                                                                    </tr><tr>
                                                                                                                      <td>Homeowners Insurance</td>
                                                                                                                      <td>:</td>
                                                                                                                      <td>{this.FormatMoney(this.state.homeownersInsuranceAsNumber)}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                      <td>HOA Fees</td>
                                                                                                                      <td>:</td>
                                                                                                                      <td>{this.FormatMoney(this.state.hoaAsNumber)}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                      <td>Other</td>
                                                                                                                      <td>:</td>
                                                                                                                      <td>{this.FormatMoney(this.state.otherMonthlyPaymentAsNumber)}</td>
                                                                                                                    </tr>
                                                                                                                    <tr style={{borderTop: '1px solid'}}>
                                                                                                                      <td><b>TOTAL</b></td>
                                                                                                                      <td>:</td>
                                                                                                                      <td><b>{this.FormatMoney(this.state.monthlyPaymentAsNumber)}</b></td>
                                                                                                                    </tr>
                                                                                                                  </table>
                                                                                                                  
                                                                                                                  </div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                              <label class="dialog-form-label">Mortgage Payment</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                              </div>
                                                                                                            </OverlayTrigger>

                                                                                                              <div class="form-select alert-edit-modal-component" style={{background:'#000000'}}>{this.FormatMoney(this.state.monthlyPaymentAsNumber)}</div>
                                                                                                              {(this.state.principalAndInterestAsNumber > 0 || this.state.propertyTaxesAsNumber > 0 || this.state.homeownersInsuranceAsNumber > 0 || this.state.hoaAsNumber > 0 || this.state.otherMonthlyPaymentAsNumber > 0)  && 

                                                                                                                <table style={{fontSize:'13px'}}>
                                                                                                                              <tr>
                                                                                                                                <td>Principal & Interest</td>
                                                                                                                                <td>:</td>
                                                                                                                                <td>{this.FormatMoney(this.state.principalAndInterestAsNumber)}</td>
                                                                                                                              </tr><tr>
                                                                                                                                <td>Property Taxes</td>
                                                                                                                                <td>:</td>
                                                                                                                                <td>{this.FormatMoney(this.state.propertyTaxesAsNumber)}</td>
                                                                                                                              </tr><tr>
                                                                                                                                <td>Homeowners Insurance</td>
                                                                                                                                <td>:</td>
                                                                                                                                <td>{this.FormatMoney(this.state.homeownersInsuranceAsNumber)}</td>
                                                                                                                              </tr>
                                                                                                                              <tr>
                                                                                                                                <td>HOA Fees</td>
                                                                                                                                <td>:</td>
                                                                                                                                <td>{this.FormatMoney(this.state.hoaAsNumber)}</td>
                                                                                                                              </tr>
                                                                                                                              <tr>
                                                                                                                                <td>Other</td>
                                                                                                                                <td>:</td>
                                                                                                                                <td>{this.FormatMoney(this.state.otherMonthlyPaymentAsNumber)}</td>
                                                                                                                              </tr>
                                                                                                                              <tr style={{borderTop: '1px solid'}}>
                                                                                                                                <td><b>TOTAL</b></td>
                                                                                                                                <td>:</td>
                                                                                                                                <td><b>{this.FormatMoney(this.state.monthlyPaymentAsNumber)}</b></td>
                                                                                                                              </tr>
                                                                                                                </table>
                                                                                                              }
                                                                                                          </div>

                                                                                                    </div> 
                                                                                                
                                                                                                    <br></br>
                                                                                                    
                                                                                                    <div  style={{textAlign:'center',paddingBottom:'5px'}}>MONTHLY EXPENSES TOTAL:<br></br>{this.FormatMoney(this.state.monthlyExpensesAsNumber)}</div>

                                                                                                </div>
                                                                                                }

                                                                                                {/* ----- Breakdown Fields End ----*/}  

                                                                                                {/* ----- Too Low Value Message Begin ----*/}
                                                                                                {this.state.rentalOtherExpensesAsNumber < 0  &&  
                                                                                                
                                                                                                  <div>   
                                                                                                    The monthly expense of {this.FormatMoney(this.state.monthlyExpensesAsNumber)} is too low.<br></br><br></br>
                                                                                                    You loan monthly payment alone is {this.FormatMoney(this.state.monthlyPaymentAsNumber)}.
                                                                                                    <br></br><br></br>
                                                                                                    Your monthly expenses should include your loan <br></br>
                                                                                                    monthly payment plus maintenance costs, <br></br>
                                                                                                    utilities and any additional costs.
                                                                                                    <br></br><br></br>
                                                                                                  </div> 
                                                                                                      
                                                                                                }  
                                                                                                {/* ----- Too Low Value Message End ----*/}


                                                                                                <div style={{textAlign:"center"}}>
                                                                                                    <Button style={{fontSize:"16px"}} onClick={e => this.HandleMonthlyExpensesBreakClose(e, this)}>DONE</Button> 
                                                                                                </div> 


                                                                                        </div>
                                                                                  </Tooltip>}
        >
                                                                                <FileEarmarkText className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                              
                                                                              </OverlayTrigger>

                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                          <td style={{width:'80%'}}>
                                                                            <span class="desktop-only-block">
                                                                                <input type="range" className="custom-range" id="monthlyExpensesAsNumber" name="monthlyExpensesAsNumber" min="0" max="8000" step="100" value={this.state.monthlyExpensesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                            </span>
                                                                            <span class="mobile-only-block">
                                                                                <CustomizedSlider id="monthlyExpensesAsNumber" name="monthlyExpensesAsNumber" min={0} max={8000} step={100} value={this.state.monthlyExpensesAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                <br></br>     
                                                                            </span>
                                                                                                
                                                                            </td>
                                                                            <td style={{width:'20%'}}></td>
                                                                          </tr>
                                                                        </table>


                                                                          </div>
                                                                </div>
                                                                    
                                                            </div>  {/* ----- End Row ----*/}

                                            
                                                            <div class="row">
                                                                
                                                                {/* Vancancy Rate and Intial Expenses h*/} 
                                                                
                                                                <div class="col-md-4">
                                                                          
                                                                          <div class="form-group">
                                                                                    <OverlayTrigger
                                                                                          placement="auto"
                                                                                          delay={{ show: 150, hide: 100 }} 
                                                                                          trigger={["hover","click"]}
                                                                                          overlay={<Tooltip  className="messagetooltip">
                                                                                        
                                                                                            <div className='helpText'>
                                                                                              
                                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                              The vacancy rate represents the percentage of time rental income is lost due to a property being unoccupied during the year. 
                                                                                            </div>

                                                                                            <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                                            
                                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                            You can get the vacancy rate for your area from the following website:
                                                                                            
                                                                                            <div style={{paddingTop:'15px'}}>

                                                                                            <a href="https://ipropertymanagement.com/research/rental-vacancy-rate#" target="blank">https://ipropertymanagement.com/research/rental-vacancy-rate#</a>   
                                                                                            </div>
                                                                                            </div>

                                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                                The vacancy rate is used in many financial indicator calculations.
                                                                                            </div>

                                                                                              
                                                                                              </div>
                                                                                        </Tooltip>}
                                                                                        >
                                                                                        <div>
                                                                                                <label class="dialog-form-label" for="vacancyRate">Vacancy Rate</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                      
                                                                                    <PercentInput type="text" class="form-select alert-edit-modal-component" id="vacancyRate" placeholder="%" name="vacancyRate" defaultValue={this.state.vacancyRate} value={this.state.vacancyRate} onChange={this.handlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="vacancyRateAsNumber" name="vacancyRateAsNumber" min="0" max="12" step="0.5" value={this.state.vacancyRateAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/> 
                                                                                    </span>

                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="vacancyRateAsNumber" name="vacancyRateAsNumber" min={0} max={12} step={0.5} value={this.state.vacancyRateAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/> 
                                                                                        <br></br>     
                                                                                    </span>

                                                                                        
                                                                                </div>
                                                                                
                                                                </div>
                                                                        
                                                                <div class="col-md-4">

                                                                      <div class="form-group">
                                                                          <OverlayTrigger
                                                                              placement="auto"
                                                                              delay={{ show: 150, hide: 100 }} 
                                                                              trigger={["hover","click"]}
                                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                            
                                                                                <div className='helpText'>
                                                                                  There are one-time expenses you will incur to acquire and rent the property.
                                                                                  <br></br><br></br>
                                                                                
                                                                                The application will pre-populate these fields with the following values. <br></br>
                                                                                They are just estimtes, you should adjust them according to your needs:<br></br><br></br>
                                                                                
                                                                                <ul>
                                                                                  <li>Appraisal Fees = <b>{this.FormatMoney(this.state.settings?.appraisalFeeCost)}</b></li>
                                                                                  <li>Home Inspection = <b>{this.FormatMoney(this.state.settings?.homeInspectionCost)}</b></li>
                                                                                  <li>Marketing = <b>{this.FormatMoney(this.state.settings?.marketingCost)}</b></li>
                                                                                  <li>Tenant Screening = <b>{this.FormatMoney(this.state.settings?.tenantScreeningCost)}</b></li>
                                                                                </ul>
                                                                              
                                                                                The pre-populated values are specified in Settings and <br></br>
                                                                                are simply best effort estimates, you should populate <br></br>
                                                                                these fields with your own values or change the default <br></br> 
                                                                                values in Settings.
            
                                                                                <div style={{paddingBottom:'9px',paddingTop:'9px',maxWidth:'520px'}}>
                                                                                  The initial expenses is used in many financial indicator calculations.
                                                                              </div>
                                                                                </div>
                                                                            </Tooltip>}
                                                                            >
                                                                            <div>
                                                                                    <label class="dialog-form-label" for="initialExpenses">Initial Expenses</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                          
                                                                        <table>
                                                                          <tr>
                                                                            <td  class="compound-column-width">

                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="initialExpenses" placeholder="Monthly Expenses $" name="initialExpenses" defaultValue={this.state.initialExpenses} value={this.state.initialExpenses} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                </td>
                                                                                <td style={{width:'20%'}}>
                                                                                        <OverlayTrigger   
                                                                                      placement="auto" 
                                                                                      rootClose={true}
                                                                                      container={this.mainModalInfoDialogRef}
                                                                                      delay={{ show: 150, hide: 100 }} 
                                                                                      trigger={["click"]} 
                                                                                      overlay={<Tooltip  id="initialExpensesBreakdown" name="initialExpensesBreakdown"  className="messagetooltip">
                                                                                    
                                                                                    <div className='helpText'>

                                                                                        <div class="orangeInfo" style={{textAlign:'center'}}>Initiial Expenses Breakdown</div>
                                                                                            <br></br>

                                                                                            <div style={{textAlign:'center'}}><b>Note</b>: Revise the pre-populated estimates for your property.</div>
                                                                                            <br></br>


                                                                                            <div class="row">
                                                                                                    
                                                                                                    <div class="col-md-6">
                                                                                                    <OverlayTrigger
                                                                                                        placement="auto"
                                                                                                        delay={{ show: 150, hide: 100 }} 
                                                                                                        trigger={["hover","click"]}
                                                                                                        overlay={<Tooltip className="messagetooltipWhite">
                                                                                                        
                                                                                                            <div className='helpText'>Appraisal fees for a single-family home runs <br></br>around $300 to $400.<br></br><br></br>
                                                                                                            <b>Note:</b> This field is being pre-populated <br></br>with an estimated values of {this.FormatMoney(this.state.rentalAppraisalFeesAsNumber)} specified in <br></br>Settings. This is just a best effort estimate and <br></br>you should adjust it to your needs.
                                                                                                            </div>
                                                                                                        </Tooltip>}
                                                                                                        >
                                                                                                        <div>
                                                                                                        <label class="dialog-form-label" for="rentalAppraisalFees">Appraisal Fees</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                                        </div>
                                                                                                    </OverlayTrigger>

                                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalAppraisalFees" placeholder="$" name="rentalAppraisalFees" defaultValue={this.state.rentalAppraisalFees} value={this.state.rentalAppraisalFees} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                        <input type="range" className="custom-range" id="rentalAppraisalFeesAsNumber" name="rentalAppraisalFeesAsNumber" min="0" max="2000" step="100" value={this.state.rentalAppraisalFeesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            

                                                                                                    </div>
                                                                                                    <div class="col-md-6">
                                                                                                    <OverlayTrigger
                                                                                                        placement="auto"
                                                                                                        delay={{ show: 150, hide: 100 }} 
                                                                                                        trigger={["hover","click"]}
                                                                                                        overlay={<Tooltip className="messagetooltipWhite">
                                                                                                        
                                                                                                            <div className='helpText'>A home inspection costs between $200 to $500, <br></br>depending on the property's location and size.<br></br><br></br>
                                                                                                            <b>Note:</b> This field is being pre-populated <br></br>with an estimated values of {this.FormatMoney(this.state.rentalHomeInspectionAsNumber)} specified in <br></br>Settings. This is just a best effort estimate and <br></br>you should adjust it to your needs.
                                                                                                            </div>
                                                                                                        </Tooltip>}
                                                                                                        >
                                                                                                        <div>
                                                                                                        <label class="dialog-form-label" for="rentalHomeInspection">Home Inspection</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                                        </div>
                                                                                                    </OverlayTrigger>

                                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalHomeInspection" placeholder="$" name="rentalHomeInspection" defaultValue={this.state.rentalHomeInspection} value={this.state.rentalHomeInspection} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                        <input type="range" className="custom-range" id="rentalHomeInspectionAsNumber" name="rentalHomeInspectionAsNumber" min="0" max="2000" step="100" value={this.state.rentalHomeInspectionAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            

                                                                                                    </div>
                                                                                            </div>

                                                                                            <div class="row">
                                                                                                    
                                                                                            <div class="col-md-6">
                                                                                                    <OverlayTrigger
                                                                                                        placement="auto"
                                                                                                        delay={{ show: 150, hide: 100 }} 
                                                                                                        trigger={["hover","click"]}
                                                                                                        overlay={<Tooltip className="messagetooltipWhite">
                                                                                                        
                                                                                                            <div className='helpText'>Each credit check can range between $30 to $50. <br></br>Let's say you will screen at least three candidates <br></br>before deciding on one and the cost is $40 per <br></br>screening. <br></br><br></br>Then it would be 3 * $40 = $120<br></br><br></br>
                                                                                                            <b>Note:</b> This field is being pre-populated <br></br>with an estimated screening cost of {this.FormatMoney(this.state.rentalTenantScreeningAsNumber)} <br></br>per candidate for 3 candidates. <br></br>This value is specified in Settings. <br></br>This is just a best effort estimate and <br></br>you should adjust it to your needs.
                                                                                                            </div>
                                                                                                        </Tooltip>}
                                                                                                        >
                                                                                                        <div>
                                                                                                        <label class="dialog-form-label" for="rentalTenantScreening">Tenant Screening</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                                        </div>
                                                                                                    </OverlayTrigger>

                                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalTenantScreening" placeholder="$" name="rentalTenantScreening" defaultValue={this.state.rentalTenantScreening} value={this.state.rentalTenantScreening} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                        <span class="desktop-only-block">
                                                                                                          <input type="range" className="custom-range" id="rentalTenantScreeningAsNumber" name="rentalTenantScreeningAsNumber" min="0" max="2000" step="100" value={this.state.rentalTenantScreeningAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            
                                                                                                        </span>

                                                                                                        <span class="mobile-only-block">
                                                                                                            <CustomizedSlider id="rentalTenantScreeningAsNumber" name="rentalTenantScreeningAsNumber" min={0} max={2000} step={100} value={this.state.rentalTenantScreeningAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>            
                                                                                                            <br></br>     
                                                                                                        </span>

                                                                                                    </div>
                                                                                                

                                                                                        
                                                                                        <div class="col-md-6">
                                                                                        <OverlayTrigger
                                                                                            placement="auto"
                                                                                            delay={{ show: 150, hide: 100 }} 
                                                                                            trigger={["hover","click"]}
                                                                                            overlay={<Tooltip className="messagetooltipWhite">
                                                                                            
                                                                                                <div className='helpText'>These are the costs you will incur from adverting <br></br>the property while it is vacant to find tenants.<br></br><br></br>
                                                                                                There are different ways you can advertize a rental property. <br></br>Here are the most common ones and their typical costs:<br></br><br></br>
                                                                                                <table>
                                                                                                    <tr>
                                                                                                        <td><u>Online (Paid)</u></td><td>:</td><td>Online paid advertisement ranges from $35 and up.</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><u>Post Sign</u></td><td>:</td><td>A post sign can cost $75 and up.</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><u>Newspaper</u></td><td>:</td><td>Local newspapers typically charge $11 an inch.</td>
                                                                                                    </tr>

                                                                                                </table>
                                                                                                <br></br>
                                                                                                    
                                                                                                <b>Note:</b> This field is being pre-populated with an estimated <br></br>values of {this.FormatMoney(this.state.rentalMarketingAsNumber)} specified in Settings. <br></br>
                                                                                                <br></br>
                                                                                                <table>
                                                                                                                <tr>
                                                                                                                  <td>Online (2 websites @ $35)</td>
                                                                                                                  <td>:</td>
                                                                                                                  <td>$70</td>
                                                                                                                </tr><tr>
                                                                                                                  <td>Post Sign</td>
                                                                                                                  <td>:</td>
                                                                                                                  <td>$75</td>
                                                                                                                </tr>
                                                                                                                <tr style={{borderTop: '1px solid'}}>
                                                                                                                  <td><b>TOTAL</b></td>
                                                                                                                  <td>:</td>
                                                                                                                  <td><b>$145</b></td>
                                                                                                                </tr>
                                                                                                              </table>
                                                                                                <br></br>
                                                                                                This is just a best effort estimate and you should adjust it <br></br>to your needs.
                                                                                                </div>
                                                                                            </Tooltip>}
                                                                                            >
                                                                                            <div>
                                                                                            <label class="dialog-form-label" for="rentalMarketing">Marketing</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                            </div>
                                                                                        </OverlayTrigger>

                                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalMarketing" placeholder="$" name="rentalMarketing" defaultValue={this.state.rentalMarketing} value={this.state.rentalMarketing} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                            <input type="range" className="custom-range" id="rentalMarketingAsNumber" name="rentalMarketingAsNumber" min="0" max="2000" step="100" value={this.state.rentalMarketingAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            

                                                                                        </div>


                                                                                            </div> 
                                                                                            
                                                                                            <div class="row">
                                                                                                    
                                                                                            <div class="col-md-6">

                                                                                                  <OverlayTrigger
                                                                                                      placement="auto"
                                                                                                      delay={{ show: 150, hide: 100 }} 
                                                                                                      trigger={["hover","click"]}
                                                                                                      overlay={<Tooltip className="messagetooltipWhite">
                                                                                                      
                                                                                                          <div className='helpText'>Other initial expenses.<br></br><br></br>
                                                                                                        </div>
                                                                                                      </Tooltip>}
                                                                                                      >
                                                                                                      <div>
                                                                                                      <label class="dialog-form-label" for="rentalOtherInitialExpenses">Other</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                                      </div>
                                                                                                  </OverlayTrigger>

                                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rentalOtherInitialExpenses" placeholder="$" name="rentalOtherInitialExpenses" defaultValue={this.state.rentalOtherInitialExpenses} value={this.state.rentalOtherInitialExpenses} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                      <input type="range" className="custom-range" id="rentalOtherInitialExpensesAsNumber" name="rentalOtherInitialExpensesAsNumber" min="0" max="2000" step="100" value={this.state.rentalOtherInitialExpensesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            

                                                                                                  </div>

                                                                                              </div>


                                                                                        <br></br>


                                                                                                    <div  style={{textAlign:'center',paddingBottom:'5px'}}>TOTAL:<br></br>{this.FormatMoney(this.state.initialExpensesAsNumber)}</div>



                                                                                                    <div style={{textAlign:"center"}}>
                                                                                                        <Button style={{fontSize:"16px"}} onClick={e => this.HandleInitialExpensesBreakClose(e, this)}>DONE</Button> 
                                                                                                    </div> 


                                                                                        </div>
                                                                                    </Tooltip>}
                                                                                    >
                                                                                      <FileEarmarkText className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                                    
                                                                                    </OverlayTrigger>

                                                                                      </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                      <td style={{width:'80%'}}>
                                                                                          <input type="range" className="custom-range" id="initialExpensesAsNumber" name="initialExpensesAsNumber" min="0" max="8000" step="100" value={this.state.initialExpensesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </td>
                                                                                      <td style={{width:'20%'}}></td>
                                                                                    </tr>
                                                                        </table>





                                                                      </div>
                                                                </div>
                                                                    
                                                            </div>  {/* ----- End Row ----*/}

                                                            <div class="row">
                                                                
                                                            <div class="col-md-4">
                                                                          
                                                                          <div class="form-group">
                                                                                    <OverlayTrigger
                                                                                          placement="auto"
                                                                                          delay={{ show: 150, hide: 100 }} 
                                                                                          trigger={["hover","click"]}
                                                                                          overlay={<Tooltip  className="messagetooltip">
                                                                                        
                                                                                            <div className='helpText'>
                                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                              It is the percentage by which the rent is expected<br></br> to go up every year.<br></br><br></br>
                                                                                              Basically every year rent trends to go up, this varies <br></br>from city to city and the economy.
                                                                                              </div>

                                                                                              <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                                              <div style={{paddingBottom:'9px',paddingTop:'9px'}}>
                                                                                                  You can get the rent rate of growth from the following website:
                                                                                                  <div style={{paddingTop:'10px'}}>
                                                                                                  <a href="https://www.zumper.com/rent-research/" target="blank">https://www.zumper.com/rent-research/</a>
                                                                                                  </div>                        
                                                                                              </div>

                                                                                              <div style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                                                                                <img src={rentrategrowth} style={{height:'65px'}}/>
                                                                                              </div>

                                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                                The rent rate of growth is used in many financial indicator calculations.
                                                                                            </div>

                                                                                            </div>
                                                                                        </Tooltip>}
                                                                                        >
                                                                                        <div>
                                                                                                <label class="dialog-form-label" for="rentRateOfGrowth">Rent Rate of Growth</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                                      
                                                                                    <PercentInput type="text" class="form-select alert-edit-modal-component" id="rentRateOfGrowth" placeholder="%" name="rentRateOfGrowth" defaultValue={this.state.rentRateOfGrowth} value={this.state.rentRateOfGrowth} onChange={this.handlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="rentRateOfGrowthAsNumber" name="rentRateOfGrowthAsNumber" min="0" max="12" step="0.5" value={this.state.rentRateOfGrowthAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/> 
                                                                                    </span>  

                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="rentRateOfGrowthAsNumber" name="rentRateOfGrowthAsNumber" min={0} max={12} step={0.5} value={this.state.rentRateOfGrowthAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/> 
                                                                                        <br></br>     
                                                                                    </span>
  
                                                                                </div>
                                                                                
                                                                            </div>

                                                              <div class="col-md-4"> 
                                                                {/* Expense Rate of Growth*/} 
                                                                
                                                                    <div class="form-group">
                                                                              <OverlayTrigger
                                                                                    placement="auto"
                                                                                    delay={{ show: 150, hide: 100 }} 
                                                                                    trigger={["hover","click"]}
                                                                                    overlay={<Tooltip  className="messagetooltip">
                                                                                  
                                                                                      <div className='helpText'>

                                                                                        <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                            It is the percentage by which the expenses are expected<br></br> to grow every year.<br></br><br></br>The expense rate of growth usually runs around the current inflation rate. 
                                                                                            
                                                                                            <br></br><br></br>You can the current inflation rate for your expense rate of growth.
                                                                                        </div>

                                                                                        <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                                The following website will tell you the current inflation rate:
                                                                                                <br></br><br></br>

                                                                                                <a href="https://www.fxempire.com/macro/united-states/inflation-rate" target="blank">https://www.fxempire.com/macro/united-states/inflation-rate</a>
                                                                                        </div>

                                                                                        <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                            The expense rate of growth is used in many financial indicator calculations.
                                                                                        </div>         
                                                                                        
                                                                                        
                                                                                        </div>
                                                                                  </Tooltip>}
                                                                                  >
                                                                                  <div>
                                                                                          <label class="dialog-form-label" for="expenseRateOfGrowth">Expense Rate of Growth</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                  </div>
                                                                              </OverlayTrigger>
                                                                                
                                                                              <PercentInput type="text" class="form-select alert-edit-modal-component" id="expenseRateOfGrowth" placeholder="%" name="expenseRateOfGrowth" defaultValue={this.state.expenseRateOfGrowth} value={this.state.expenseRateOfGrowth} onChange={this.handlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                              <span class="desktop-only-block">
                                                                                <input type="range" className="custom-range" id="expenseRateOfGrowthAsNumber" name="expenseRateOfGrowthAsNumber" min="0" max="12" step="0.5" value={this.state.expenseRateOfGrowthAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/> 
                                                                              </span>  

                                                                              <span class="mobile-only-block">
                                                                                  <CustomizedSlider id="expenseRateOfGrowthAsNumber" name="expenseRateOfGrowthAsNumber" min={0} max={12} step={0.5} value={this.state.expenseRateOfGrowthAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/> 
                                                                                <br></br>     
                                                                              </span>
    
                                                                          </div>
                                                                          
                                                                      </div>
                                                                    
                                                            </div>  {/* ----- End Row ----*/}



                                                        </div>

                                                      </div>
                                                    </div>  {/* ----- End Row ----*/}

                                  
                                                    {/* ----------------------------------------- COSTS -------------------------------------------- */}
                                                    
                                                    <div class="row space-bottom" style={{paddingTop:'10px',display:'none'}} id="flippingCostsBlock">
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleFlippingCostsExpand(e, this)}>FLIPPING COSTS<span id="flippingCostsPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="flippingCostsContent" style={{display:'none',paddingTop:'5px'}}>
                                                          
                                                      
                                                            <div class="row">
                                                                
                                                                {/* Rehab Costs */} 
                                                                <div class="col-md-4">
                                                                  <div class="form-group"  classX="repayment-type-column-width">
                                                                      <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>This field is to specify the type of rehab you <br></br>
                                                                          need to do on the property.
                                                                          <br></br><br></br>
                                                                          &#8226; <b><u>Cosmetic Rehab:</u></b><br></br>
                                                                          Exterior & interior paint, new flooring, new fixtures. <br></br>
                                                                          Costs around $10 to $20 per SF <br></br><br></br>
                                                                          &#8226; <b><u>Heavy Rehab:</u></b> <br></br>
                                                                        Major exterior work, re-using existing floor plan, <br></br>
                                                                        new kitchen, new bathrooms, finishes throughout.<br></br>
                                                                          Costs around $35 to $50 per SF<br></br><br></br>
                                                                          &#8226; <b><u>Full Gut Rehab:</u></b><br></br> 
                                                                          Major exterior work, interior structural changes, <br></br>
                                                                          new MEP systems, new kitchen, bathrooms, finishes <br></br>
                                                                          throughout. Costs around $50 to $80 per SF

                                                                          </div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="rehabTypeDropdownSelect">Rehab Type</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                      </OverlayTrigger>
                                                                      
                                                                      <DropdownButton id="rehabTypeDropdownSelect" name="rehabTypeDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.rehabType} onSelect={(e) => this.HandleSelect(e)}>
                                                                                  <Dropdown.Item className="dropdownitem"  eventKey={['rehabType', 'Cosmetic Rehab']}>Cosmetic Rehab</Dropdown.Item>
                                                                                  <div style={{paddingBottom:"8px"}}></div>
                                                                                  <Dropdown.Item className="dropdownitem"  eventKey={['rehabType', 'Heavy Rehab']}>Heavy Rehab</Dropdown.Item>
                                                                                  <div style={{paddingBottom:"8px"}}></div>
                                                                                  <Dropdown.Item className="dropdownitem"  eventKey={['rehabType', 'Full Gut Rehab']}>Full Gut Rehab</Dropdown.Item>
                                                                      </DropdownButton>
                                                                      
                                                                  </div>
                                                              </div>
                                                                <div class="col-md-3">
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                              
                                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                    The amount of money expected to be spent on 
                                                                                    repairs and renovations.
                                                                                  </div>

                                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>              
                                                                                    This application will pre-populate rehab costs by using
                                                                                    the <b>Cost per SF Method</b>.
                                                                                  </div>

                                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}> 
                                                                                  Cost per Square Foot estimation is a quick estimation 
                                                                                  technique that uses an estimated $/SF value and multiplies 
                                                                                  it by the property square footage that is being rehabbed. 
                                                                                  The application will calculate the rehab costs as follows
                                                                                  depending on the rehab type selected:

                                                                                  </div>

                                                                                  <table border='1'>
                                                                                  <tr style={{borderBottom: '1px solid'}}>
                                                                                      <td style={{padding: '5px'}}><b>Rehab Type</b></td>
                                                                                      <td style={{padding: '5px'}}><b>Cost Per SqFt</b></td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{padding: '5px'}}>Cosmetic Rehab</td>
                                                                                    <td style={{padding: '5px'}}>{this.FormatMoney(this.state.settings?.cosmeticRehabPerSqFt)}</td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{padding: '5px'}}>Heavy Rehab</td>
                                                                                    <td style={{padding: '5px'}}>{this.FormatMoney(this.state.settings?.heavyRehabPerSqFt)}</td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                      <td style={{padding: '5px'}}>Full Gut Rehab</td>
                                                                                      <td style={{padding: '5px'}}>{this.FormatMoney(this.state.settings?.fullGutRehabPerSqFt)}</td>
                                                                                  </tr>
                                                                                  </table>

                                                                                  <div style={{paddingTop:'9px',paddingBottom:'9px',maxWidth:'520px'}}>

                                                                                  You can change these values in Settings.
                                                                                  </div>

                                                                                  <div class="desktop-only-block">              
                                                                                    <span style={{color:'red'}}>
                                                                                    <b>VERY IMPORTANT</b>:
                                                                                    </span>
                                                                                    <br></br>
                                                                                    <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                      Although it is OK to use the Cost per SF method
                                                                                      to get a rough idea whether the property is profitable,
                                                                                      you should always get a detailed estimation of the   
                                                                                      rehab costs before making any investment decisions.
                                                                                    </div>
                                                                                  </div>
                                                                                  
                                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                    <span style={{fontWeight: '600'}}>
                                                                                    We highly recommend you enter your own detailed rehab 
                                                                                    cost estimates in this field and override the pre-populated
                                                                                    value.
                                                                                    </span>
                                                                                    </div>

                                                                                  <br></br>
                                                                                  <u>CALCULATION</u>:
                                                                                  {this.state.autoCalculateRehabCosts  &&
                                                                                    <div>
                                                                                      <table>
                                                                                        <tr>
                                                                                          <td>Living Area (sqft)</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>*</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>Cost Per SqFt</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>=</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>Rehab Costs</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>&nbsp;</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                          <td style={{textAlign:'center'}}>{this.state.livingAreaSize}</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>*</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td style={{textAlign:'center'}}>
                                                                                            {this.state.rehabType =="Cosmetic Rehab" &&
                                                                                              <span>{this.FormatMoney(this.state.settings?.cosmeticRehabPerSqFt)}</span>
                                                                                            }

                                                                                            {this.state.rehabType =="Heavy Rehab" &&
                                                                                              <span>{this.FormatMoney(this.state.settings?.heavyRehabPerSqFt)}</span>
                                                                                            }

                                                                                            {this.state.rehabType =="Full Gut Rehab" &&
                                                                                              <span>{this.FormatMoney(this.state.settings?.fullGutRehabPerSqFt)}</span>
                                                                                            }
                                                                                            
                                                                                            </td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td>=</td>
                                                                                          <td>&nbsp;</td>
                                                                                          <td style={{textAlign:'center'}}>{this.FormatMoney(this.state.rehabCostsAsNumber)}</td>
                                                                                        </tr>
                                                                                      </table>
                                                                                      <br></br>
                                                                                    </div>
                                                                                  }
                                                                              
                                                                              </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="rehabCosts">Rehab Costs</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="rehabCosts" placeholder="$" name="rehabCosts" defaultValue={this.state.rehabCosts} value={this.state.rehabCosts} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                        <span class="desktop-only-block">
                                                                          <input type="range" className="custom-range" id="rehabCostsAsNumber" name="rehabCostsAsNumber" min="0" max="130000" step="100" value={this.state.rehabCostsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                            <CustomizedSlider id="rehabCostsAsNumber" name="rehabCostsAsNumber" min={0} max={130000} step={100} value={this.state.rehabCostsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                            <br></br>     
                                                                        </span>
          
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    
                                                            </div>  {/* ----- End Row ----*/}

                                                            <div class="row">
                                                                
                                                                <div class="col-md-4">
                                                                      <div class="form-group">
                                                                          <OverlayTrigger
                                                                              placement="auto"
                                                                              delay={{ show: 150, hide: 100 }} 
                                                                              trigger={["hover","click"]}
                                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                            
                                                                                <div className='helpText'>The costs you will incur from holding the property.<br></br>Also called carrying costs, includes everything you have <br></br>pay monthly while you rehab the property:<br></br><br></br>- Mortgage or loan payment<br></br>- Property insurance <br></br>- Real estate taxes<br></br>- Utilities<br></br>- HOA fees<br></br>- Lawn Maintenance Service<br></br>- Other</div>
                                                                            </Tooltip>}
                                                                            >
                                                                            <div>
                                                                                    <label class="dialog-form-label" for="holdingCosts">Holding Costs <span style={{fontSize:'12px',color:'#FFFFFF'}}>(Monthly)</span></label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                          
                                                                          

                                                                              {/* ----- Holding Costs Begin ----*/}

                                                                                <table>
                                                                                <tr>
                                                                                  <td  class="compound-column-width">

                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="holdingCosts" placeholder="$" name="holdingCosts" defaultValue={this.state.holdingCosts} value={this.state.holdingCosts} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                                                                      
                                                                                    </td>
                                                                                    <td style={{width:'20%'}}>
                                                                                            <OverlayTrigger   
                                                                                          placement="auto" 
                                                                                          rootClose={true}
                                                                                          container={this.mainModalInfoDialogRef}
                                                                                          delay={{ show: 150, hide: 100 }} 
                                                                                          trigger={["click"]} 
                                                                                          overlay={<Tooltip  id="holdingCostsBreakdown" name="holdingCostsBreakdown"  className="messagetooltip">
                                                                                                
                                                                                                <div className='helpText'>

                                                                                                    <div class="orangeInfo" style={{textAlign:'center'}}>Monthly Holding Costs Breakdown</div>
                                                                                                      <br></br>

                                                                                                    

                                                                                                      {/* ----- Breakdown Fields Begin ----*/}
                                                                                                      {this.state.flippingOtherExpensesAsNumber >=0  &&  
                                                                                                        
                                                                                                        <div>  
                                                                                                                  <div style={{textAlign:'center'}}><b>Note</b>: Revise the pre-populated estimates for your property.</div>
                                                                                                                  <br></br>
                                                                                                                  
                                                                                                                  <div class="row">
                                                                                                                        
                                                                                                                        <div class="col-md-6">
                                                                                                                        <OverlayTrigger
                                                                                                                              placement="auto"
                                                                                                                              delay={{ show: 150, hide: 100 }} 
                                                                                                                              trigger={["hover","click"]}
                                                                                                                              overlay={<Tooltip className="messagetooltipWhite">
                                                                                                                            
                                                                                                                                <div className='helpText'>In most cases, the tenant will pay the <br></br>majority of utilities, but services like <br></br>sewer and water are generally covered by the owner. <br></br>This expense can range from $30 – $200 per month, <br></br>depending on both the location of the property and <br></br>usage of utilities.<br></br><br></br>
                                                                                                                                <b>Note:</b> This field is being pre-populated <br></br>with an estimated values of {this.FormatMoney(this.state.flippingUtilitiesAsNumber)}  specified in <br></br>Settings. This is just a best effort estimate and <br></br>you should adjust it to your needs.
                                                                                                                                </div>
                                                                                                                            </Tooltip>}
                                                                                                                            >
                                                                                                                            <div>
                                                                                                                            <label class="dialog-form-label" for="flippingUtilities">Utilities</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                                                            </div>
                                                                                                                          </OverlayTrigger>

                                                                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="flippingUtilities" placeholder="$" name="flippingUtilities" defaultValue={this.state.flippingUtilities} value={this.state.flippingUtilities} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                                            <input type="range" className="custom-range" id="flippingUtilitiesAsNumber" name="flippingUtilitiesAsNumber" min="0" max="2000" step="100" value={this.state.flippingUtilitiesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>            

                                                                                                                        </div>
                                                                                                                        <div class="col-md-6">
                                                                                                                          
                                                                                                                          <OverlayTrigger
                                                                                                                              placement="auto"
                                                                                                                              delay={{ show: 150, hide: 100 }} 
                                                                                                                              trigger={["hover","click"]}
                                                                                                                              overlay={<Tooltip className="messagetooltipWhite">
                                                                                                                            
                                                                                                                                <div className='helpText'>The monthly maintance expenses.
                                                                                                                                
                                                                                                                                <br></br><br></br><b>Note:</b> This field is being pre-populated <br></br>with an estimated value of {this.state.settings?.maintenanceFlippingCosts} specified in Settings.  <br></br>This is just a best effort estimate and <br></br>you should adjust it to your needs.

                                                                                                                                </div>
                                                                                                                            </Tooltip>}
                                                                                                                            >
                                                                                                                            <div>
                                                                                                                                <label class="dialog-form-label" for="flippingMaintenance">Maintenance</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                                            
                                                                                                                                </div>
                                                                                                                          </OverlayTrigger>
                                                                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="flippingMaintenance" placeholder="$" name="flippingMaintenance" defaultValue={this.state.flippingMaintenance} value={this.state.flippingMaintenance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                                          <         input type="range" className="custom-range" id="flippingMaintenanceAsNumber" name="flippingMaintenanceAsNumber" min="0" max="2000" step="100" value={this.state.flippingMaintenanceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>
                                                                                                                          
                                                                                                                        </div>
                                                                                                                  </div>

                                                                                                                  <div class="row">
                                                                                                                  
                                                                                                                        <div class="col-md-6">
                                                                                                                        <OverlayTrigger
                                                                                                                              placement="auto"
                                                                                                                              delay={{ show: 150, hide: 100 }} 
                                                                                                                              trigger={["hover","click"]}
                                                                                                                              overlay={<Tooltip className="messagetooltipWhite">
                                                                                                                            
                                                                                                                                <div className='helpText'>Other expenses.</div>
                                                                                                                            </Tooltip>}
                                                                                                                            >
                                                                                                                            <div>
                                                                                                                            <label class="dialog-form-label" for="flippingOtherExpenses">Other</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                                            </div>
                                                                                                                          </OverlayTrigger>
                                                                                                                          
                                                                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="flippingOtherExpenses" placeholder="$" name="flippingOtherExpenses" defaultValue={this.state.flippingOtherExpenses} value={this.state.flippingOtherExpenses} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                                                              <input type="range" className="custom-range" id="flippingOtherExpensesAsNumber" name="flippingOtherExpensesAsNumber" min="0" max="2000" step="100" value={this.state.flippingOtherExpensesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/> 
                                                                                                                              
                                                                                                                        </div>
                                                                                                                        <div class="col-md-6">
                                                                                                                        <OverlayTrigger
                                                                                                                              placement="auto"
                                                                                                                              delay={{ show: 150, hide: 100 }} 
                                                                                                                              trigger={["hover","click"]}
                                                                                                                              overlay={<Tooltip className="messagetooltipWhite">
                                                                                                                            
                                                                                                                                <div className='helpText'>The monthly loan payment. It includes <br></br>principal & interest, property taxes, homeowners <br></br>insurance, and HOA fees. <br></br><br></br>This value comes from the financing monthly payment field.<br></br><br></br>
                                                                                                                                
                                                                                                                                <table>
                                                                                                                                  <tr>
                                                                                                                                    <td>Principal & Interest</td>
                                                                                                                                    <td>:</td>
                                                                                                                                    <td>{this.FormatMoney(this.state.principalAndInterestAsNumber)}</td>
                                                                                                                                  </tr><tr>
                                                                                                                                    <td>Property Taxes</td>
                                                                                                                                    <td>:</td>
                                                                                                                                    <td>{this.FormatMoney(this.state.propertyTaxesAsNumber)}</td>
                                                                                                                                  </tr><tr>
                                                                                                                                    <td>Homeowners Insurance</td>
                                                                                                                                    <td>:</td>
                                                                                                                                    <td>{this.FormatMoney(this.state.homeownersInsuranceAsNumber)}</td>
                                                                                                                                  </tr>
                                                                                                                                  <tr>
                                                                                                                                    <td>HOA Fees</td>
                                                                                                                                    <td>:</td>
                                                                                                                                    <td>{this.FormatMoney(this.state.hoaAsNumber)}</td>
                                                                                                                                  </tr>
                                                                                                                                  <tr>
                                                                                                                                    <td>Other</td>
                                                                                                                                    <td>:</td>
                                                                                                                                    <td>{this.FormatMoney(this.state.otherMonthlyPaymentAsNumber)}</td>
                                                                                                                                  </tr>
                                                                                                                                  <tr style={{borderTop: '1px solid'}}>
                                                                                                                                    <td><b>TOTAL</b></td>
                                                                                                                                    <td>:</td>
                                                                                                                                    <td><b>{this.FormatMoney(this.state.monthlyPaymentAsNumber)}</b></td>
                                                                                                                                  </tr>
                                                                                                                                </table>
                                                                                                                                
                                                                                                                                </div>
                                                                                                                            </Tooltip>}
                                                                                                                            >
                                                                                                                            <div>
                                                                                                                            <label class="dialog-form-label">Mortgage Payment</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                                                            </div>
                                                                                                                          </OverlayTrigger>

                                                                                                                            <div class="form-select alert-edit-modal-component" style={{background:'#000000'}}>{this.FormatMoney(this.state.monthlyPaymentAsNumber)}</div>
                                                                                                                          
                                                                                                                            {(this.state.principalAndInterestAsNumber > 0 || this.state.propertyTaxesAsNumber > 0 || this.state.homeownersInsuranceAsNumber > 0 || this.state.hoaAsNumber > 0 || this.state.otherMonthlyPaymentAsNumber > 0)  && 

                                                                                                                              <table style={{fontSize:'13px'}}>
                                                                                                                                    <tr>
                                                                                                                                      <td>Principal & Interest</td>
                                                                                                                                      <td>:</td>
                                                                                                                                      <td>{this.FormatMoney(this.state.principalAndInterestAsNumber)}</td>
                                                                                                                                    </tr><tr>
                                                                                                                                      <td>Property Taxes</td>
                                                                                                                                      <td>:</td>
                                                                                                                                      <td>{this.FormatMoney(this.state.propertyTaxesAsNumber)}</td>
                                                                                                                                    </tr><tr>
                                                                                                                                      <td>Homeowners Insurance</td>
                                                                                                                                      <td>:</td>
                                                                                                                                      <td>{this.FormatMoney(this.state.homeownersInsuranceAsNumber)}</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                      <td>HOA Fees</td>
                                                                                                                                      <td>:</td>
                                                                                                                                      <td>{this.FormatMoney(this.state.hoaAsNumber)}</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr>
                                                                                                                                      <td>Other</td>
                                                                                                                                      <td>:</td>
                                                                                                                                      <td>{this.FormatMoney(this.state.otherMonthlyPaymentAsNumber)}</td>
                                                                                                                                    </tr>
                                                                                                                                    <tr style={{borderTop: '1px solid'}}>
                                                                                                                                      <td><b>TOTAL</b></td>
                                                                                                                                      <td>:</td>
                                                                                                                                      <td><b>{this.FormatMoney(this.state.monthlyPaymentAsNumber)}</b></td>
                                                                                                                                    </tr>
                                                                                                                              </table>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                  </div> 

                                                                                                                  <br></br>

                                                                                                                  <div  style={{textAlign:'center',paddingBottom:'5px'}}>HOLDING COSTS TOTAL:<br></br>{this.FormatMoney(this.state.holdingCostsAsNumber)}</div>

                                                                                                          </div> 
                                                                                                          
                                                                                                        }  
                                                                                                        {/* ----- Breakdown Fields End ----*/}  

                                                                                                        {/* ----- Too Low Value Message Begin ----*/}
                                                                                                        {this.state.flippingOtherExpensesAsNumber < 0  &&  
                                                                                                        
                                                                                                          <div>   
                                                                                                            The holding costs of {this.FormatMoney(this.state.holdingCostsAsNumber)} is too low.<br></br><br></br>
                                                                                                            You loan monthly payment alone is {this.FormatMoney(this.state.monthlyPaymentAsNumber)}.
                                                                                                            <br></br><br></br>
                                                                                                            Your holding costs should include your loan <br></br>
                                                                                                            monthly payment plus maintenance costs, <br></br>
                                                                                                            utilities and any additional costs.
                                                                                                            <br></br><br></br>
                                                                                                          </div> 
                                                                                                          
                                                                                                        }  
                                                                                                        {/* ----- Too Low Value Message End ----*/}


                                                                                                              <div style={{textAlign:"center"}}>
                                                                                                                  <Button style={{fontSize:"16px"}} onClick={e => this.HandleHoldingCostsBreakClose(e, this)}>DONE</Button> 
                                                                                                              </div> 


                                                                                                </div>
                                                                                          </Tooltip>}
                                                                              >
                                                                                        <FileEarmarkText className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                                      
                                                                                      </OverlayTrigger>

                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                  <td style={{width:'80%'}}>
                                                                                      <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="holdingCostsAsNumber" name="holdingCostsAsNumber" min="0" max="20000" step="100" value={this.state.holdingCostsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="holdingCostsAsNumber" name="holdingCostsAsNumber" min={0} max={20000} step={100} value={this.state.holdingCostsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>
                                                                                                        
                                                                                    </td>
                                                                                    <td style={{width:'20%'}}></td>
                                                                                  </tr>
                                                                                </table>

                                                                              {/* ----- Holding Costs End ----*/}



                                                                          </div>
                                                                </div>
                                                                
                                                                <div class="col-md-4">
                                                                          
                                                                          <div class="form-group">
                                                                          <OverlayTrigger
                                                                              placement="auto"
                                                                              delay={{ show: 150, hide: 100 }} 
                                                                              trigger={["hover","click"]}
                                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                            
                                                                                <div className='helpText'>The costs you will incur from selling the property.<br></br><br></br>Generally, selling costs will be around 7% to 10% of the sales price.<br></br><br></br>Including:<br></br>- Buyer's Agent Commission (~3% of Sales Price)<br></br>- Seller's Agent Commission (~3% of Sales Price) <br></br>- Seller Assisted Closing Costs<br></br>- Transfer Taxes/Excise Taxes<br></br>- Home Warranty ($400 to $500)<br></br>- Termite Letter ($50 to $100)<br></br>- Other
                                                                                
                                                                                <br></br><br></br><b>Note:</b> This field is being pre-populated <br></br>with an estimated {this.state.settings?.sellingCostPercent}% of the AVR (After Repair Value), <br></br>this percent is specified in Settings.  <br></br>This is just a best effort estimate and <br></br>you should adjust it to your needs.

                                                                                
                                                                                </div>
                                                                            </Tooltip>}
                                                                            >
                                                                            <div>
                                                                                    <label class="dialog-form-label" for="holdingCosts">Selling Costs</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                          
                                                                        <table>
                                                                            <tr>
                                                                                <td classx="compound-column-width" style={{width:'60%'}}>
                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="sellingCosts" placeholder="Selling Costs $" name="sellingCosts" defaultValue={this.state.sellingCosts} value={this.state.sellingCosts} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                </td>
                                                                                <td style={{width:'20%'}}>
                                                                                <OverlayTrigger
                                                                                placement="auto"
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["hover","click"]}
                                                                                overlay={<Tooltip  className="messagetooltip">
                                                                                        <div className='helpText'>{this.state.sellingCostsPercent} of {this.FormatMoney(this.state.afterRepairValueAsNumber)} (After Repair Value [AVR]) is {this.FormatMoney(this.state.sellingCostsAsNumber)}.</div>
                                                                                    </Tooltip>}
                                                                                    >
                                                                                    <div>
                                                                                    <label class="dialog-form-label orangeLabel" id="sellingCostsPercent">{this.state.sellingCostsPercent}</label>
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td  classx="compound-column-width" style={{width:'60%'}}>
                                                                                <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="sellingCostsAsNumber" name="sellingCostsAsNumber" min="0" max="2000000" step="100" value={this.state.sellingCostsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>   
                                                                                </span>

                                                                                <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="sellingCostsAsNumber" name="sellingCostsAsNumber" min={0} max={2000000} step={100} value={this.state.sellingCostsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>   
                                                                                    <br></br>     
                                                                                </span>
                          
                                                                                </td>
                                                                                <td style={{width:'20%'}}></td>
                                                                            </tr>
                                                                        </table>
                                                                          </div>
                                                                              
                                                                                
                                                                </div>
                                                                        
                                                              
                                                                    
                                                            </div>  {/* ----- End Row ----*/}



                                                        </div>

                                                      </div>
                                                    </div>  {/* ----- End Row ----*/}

                                                
                                                    {/* ----------------------------------------- BUYER'S BUDGET -------------------------------------------- */}
                                                                                          
                                                    <div class="row space-bottom" style={{paddingTop:'10px',display:'none'}} id="buyersBudgetBlock">
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleBuyersBudgetExpand(e, this)}>BUYER'S BUDGET<span id="buyersBudgetPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="buyersBudgetContent" style={{display:'none',paddingTop:'5px'}}>
                                                          
                                                      
                                                            <div class="row">
                                                                
                                                                {/* Budget */} 
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>Your budget allocated for this investment.</div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="budget">Budget</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="budget" placeholder="$" name="budget" defaultValue={this.state.budget} value={this.state.budget} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                        <span class="desktop-only-block">
                                                                            <input type="range" className="custom-range" id="budgetAsNumber" name="budgetAsNumber" min="0" max="2000000" step="100" value={this.state.budgetAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                            <CustomizedSlider id="budgetAsNumber" name="budgetAsNumber" min={0} max={2000000} step={100} value={this.state.budgetAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                            <br></br>     
                                                                        </span>
                                
                                                                        </div>
                                                                    </div>
                      
                                                                    
                                                            </div>  {/* ----- End Row ----*/}

                                                                                                  </div>

                                                      </div>
                                                    </div>  {/* ----- End Row ----*/}


                                                    {/* ----------------------------------------- BUYER INFORMATION -------------------------------------------- */}
                                                    
                                                    <div class="row space-bottom" style={{paddingTop:'10px',display:'none'}} id="buyerBlock">
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleBuyerExpand(e, this)}>BUYER'S FINANCIAL STATUS<span id="buyerPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="buyerContent" style={{display:'none',paddingTop:'5px'}}>
                                                          
                                                      
                                                            <div class="row">
                                                                
                                                                {/* Monthly Gross Income */}
                                                                <div class="col-md-4">
                                                                  <div class="form-group">
                                                                      <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>
                                                                              
                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                            Gross income is the buyer's total household income before you deduct taxes, debt payments and other expenses.
                                                                            </div>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                            The monthly gross income has an impact on the financial indicator calculators.
                                                                            </div>

                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                            
                                                                              <span style={{fontWeight:"bold",color:"#ffa500"}}>NOTE:</span> This field will be pre-populated from the value in the profile, but you can always change it here.

                                                                            </div>
                                                                              
                                                                              </div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="monthlyGrossIncome">Monthly Gross Income</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                      
                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="monthlyGrossIncome" placeholder="$" name="monthlyGrossIncome" defaultValue={this.state.monthlyGrossIncome} value={this.state.monthlyGrossIncome} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                      <span class="desktop-only-block">
                                                                          <input type="range" className="custom-range" id="monthlyGrossIncomeAsNumber" name="monthlyGrossIncomeAsNumber" min="0" max="20000" step="100" value={this.state.monthlyGrossIncomeAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                      </span>

                                                                      <span class="mobile-only-block">
                                                                          <CustomizedSlider id="monthlyGrossIncomeAsNumber" name="monthlyGrossIncomeAsNumber" min={0} max={20000} step={100} value={this.state.monthlyGrossIncomeAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                          <br></br>     
                                                                      </span>

                                                                      </div>
                                                                </div>

                                                                {/* Monthly Bills */} 
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText scrollable-help-text2'>
                                                                                
                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The total amount of money the buyer spends on bills monthly.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  Below are the most common monthly bills that should be considered:
                                                                                </div>

                                                                                  <table border="1">
                                                                                  <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Auto Insurance</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Clothing</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Health, Medical Insurance</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Car Maintenance</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Credit Card(s)</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Life Insurance</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Car Payment, Lease</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Dining, Take-out</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Medical, Dental, Vision</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Cell Phone</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Entertainment</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Phone, Cable, Internet</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Charitable Donations</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Gas (Car)</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Recreation, Vacation</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Child Care, Tuition</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Gas, Electric</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Student Loan(s)</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Child Support</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Groceries</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Water</td>
                                                                                    </tr>
                                                                                  </table>
                                                                                

                                                                                <div style={{paddingBottom:'9px',paddingTop:'9px',maxWidth:'520px'}}>
                                                                                As a general rule, your total housing expense, including taxes and insurance, should not exceed <span style={{color:"#ffa500", fontWeight:"bold"}}>33%</span> of your gross income.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The monthly bills has an impact on the financial indicator calculators.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                            
                                                                                  <span style={{fontWeight:"bold",color:"#ffa500"}}>NOTE:</span> This field will be pre-populated from the value in the profile, but you can always change it here.

                                                                                </div>
                                                                                
                                                                                </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="monthlyBills">Monthly Bills</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                      <table>
                                                                        <tr>
                                                                          <td  class="compound-column-width">
                                                                        
                                                                            <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="monthlyBills" placeholder="$" name="monthlyBills" defaultValue={this.state.monthlyBills} value={this.state.monthlyBills} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
  
                                                                          </td>
                                                                          <td style={{width:'20%'}}>
                                                                                  <OverlayTrigger   
                                                                                placement="auto" 
                                                                                rootClose={true}
                                                                                container={this.mainModalInfoDialogRef}
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["click"]} 
                                                                                overlay={<Tooltip  id="closingCostsBreakdown" name="closingCostsBreakdown"  className="messagetooltip">
                                                                              
                                                                          

                                                                              <div className='helpText scrollable-help-text'>

                                                                            <div class="orangeInfo" style={{textAlign:'center'}}>Monthly Bills Breakdown</div>
                                                                            <br></br>

                                                                            <div class="row">
                                                                              
                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="otherMonthlyBill">Other</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="otherMonthlyBill" placeholder="$" name="otherMonthlyBill" defaultValue={this.state.otherMonthlyBill} value={this.state.otherMonthlyBill} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="otherMonthlyBillAsNumber" name="otherMonthlyBillAsNumber" min="0" max="5000" step="50" value={this.state.otherMonthlyBillAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                              
                                                                                  <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="otherMonthlyBillAsNumber" name="otherMonthlyBillAsNumber" min={0} max={8000} step={50} value={this.state.otherMonthlyBillAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                  </span>
                                                                              </div>

                                                                            </div>

                                                                            <div class="row">
                                                                              
                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="autoInsurance">Auto Insurance</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="autoInsurance" placeholder="$" name="autoInsurance" defaultValue={this.state.autoInsurance} value={this.state.autoInsurance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="autoInsuranceAsNumber" name="autoInsuranceAsNumber" min="0" max="5000" step="50" value={this.state.autoInsuranceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="autoInsuranceAsNumber" name="autoInsuranceAsNumber" min={0} max={8000} step={50} value={this.state.autoInsuranceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                  </span>

                                                                              </div>

                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="cable">Cable</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="cable" placeholder="$" name="cable" defaultValue={this.state.cable} value={this.state.cable} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="cableAsNumber" name="cableAsNumber" min="0" max="5000" step="50" value={this.state.cableAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="cableAsNumber" name="cableAsNumber" min={0} max={8000} step={50} value={this.state.cableAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>

                                                                                </div>

                                                                                <div class="col-md-4">  
                                                                                  <label class="dialog-form-label" for="carMaintenance">Car Maintenance</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="carMaintenance" placeholder="$" name="carMaintenance" defaultValue={this.state.carMaintenance} value={this.state.carMaintenance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="carMaintenanceAsNumber" name="carMaintenanceAsNumber" min="0" max="5000" step="50" value={this.state.carMaintenanceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="carMaintenanceAsNumber" name="carMaintenanceAsNumber" min={0} max={8000} step={50} value={this.state.carMaintenanceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                  </span>
                                                                                </div>                                                                                                      
                                                                                
                                                                            </div> 

                                                                            <div class="row">
                                                                              
                                                                                <div class="col-md-4">
                                                                                    <label class="dialog-form-label" for="carPayment">Car Payment/Lease</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="carPayment" placeholder="$" name="carPayment" defaultValue={this.state.carPayment} value={this.state.carPayment} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="carPaymentAsNumber" name="carPaymentAsNumber" min="0" max="5000" step="50" value={this.state.carPaymentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>
                                                                                    <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="carPaymentAsNumber" name="carPaymentAsNumber" min={0} max={8000} step={50} value={this.state.carPaymentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                  </span>                    
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <label class="dialog-form-label" for="cellPhone">Cell Phone</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="cellPhone" placeholder="$" name="cellPhone" defaultValue={this.state.cellPhone} value={this.state.cellPhone} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="cellPhoneAsNumber" name="cellPhoneAsNumber" min="0" max="5000" step="50" value={this.state.cellPhoneAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>
                                                                                                  
                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="cellPhoneAsNumber" name="cellPhoneAsNumber" min={0} max={8000} step={50} value={this.state.cellPhoneAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>

                                                                                  </div>

                                                                                  <div class="col-md-4">  
                                                                                    <label class="dialog-form-label" for="charitableDonations">Charitable Donations</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="charitableDonations" placeholder="$" name="charitableDonations" defaultValue={this.state.charitableDonations} value={this.state.charitableDonations} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="charitableDonationsAsNumber" name="charitableDonationsAsNumber" min="0" max="5000" step="50" value={this.state.charitableDonationsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>
                                                                                    <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="charitableDonationsAsNumber" name="charitableDonationsAsNumber" min={0} max={8000} step={50} value={this.state.charitableDonationsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                    </span>

                                                                                  </div>                                                                                                      
                                                                                  
                                                                              </div> 
                                                                            

                                                                            <div class="row">
                                                                              
                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="childCare">Child Care</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="childCare" placeholder="$" name="childCare" defaultValue={this.state.childCare} value={this.state.childCare} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="childCareAsNumber" name="childCareAsNumber" min="0" max="5000" step="50" value={this.state.childCareAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="childCareAsNumber" name="childCareAsNumber" min={0} max={8000} step={50} value={this.state.childCareAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                  </span>
                                                                              </div>

                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="childSupport">Child Support</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="childSupport" placeholder="$" name="childSupport" defaultValue={this.state.childSupport} value={this.state.childSupport} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="childSupportAsNumber" name="childSupportAsNumber" min="0" max="5000" step="50" value={this.state.childSupportAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="childSupportAsNumber" name="childSupportAsNumber" min={0} max={8000} step={50} value={this.state.childSupportAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                  </span>
                                                                                </div>

                                                                                <div class="col-md-4">  
                                                                                  <label class="dialog-form-label" for="clothing">Clothing</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="clothing" placeholder="$" name="clothing" defaultValue={this.state.clothing} value={this.state.clothing} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="clothingAsNumber" name="clothingAsNumber" min="0" max="5000" step="50" value={this.state.clothingAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>

                                                                                  <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="clothingAsNumber" name="clothingAsNumber" min={0} max={8000} step={50} value={this.state.clothingAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                  </span>

                                                                                </div>                                                                                                      
                                                                                
                                                                            </div>  

                                                                            <div class="row">
                                                                              
                                                                                <div class="col-md-4">
                                                                                    <label class="dialog-form-label" for="creditCard">Credit Card(s)</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="creditCard" placeholder="$" name="creditCard" defaultValue={this.state.creditCard} value={this.state.creditCard} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="creditCardAsNumber" name="creditCardAsNumber" min="0" max="5000" step="50" value={this.state.creditCardAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>
                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="creditCardAsNumber" name="creditCardAsNumber" min={0} max={8000} step={50} value={this.state.creditCardAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <label class="dialog-form-label" for="dining">Dining, Take-out</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="dining" placeholder="$" name="dining" defaultValue={this.state.dining} value={this.state.dining} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="diningAsNumber" name="diningAsNumber" min="0" max="5000" step="50" value={this.state.diningAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>
                                                                                    <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="diningAsNumber" name="diningAsNumber" min={0} max={8000} step={50} value={this.state.diningAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                  </span>

                                                                                  </div>

                                                                                  <div class="col-md-4">  
                                                                                    <label class="dialog-form-label" for="entertainment">Entertainment</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="entertainment" placeholder="$" name="entertainment" defaultValue={this.state.entertainment} value={this.state.entertainment} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="entertainmentAsNumber" name="entertainmentAsNumber" min="0" max="5000" step="50" value={this.state.entertainmentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>

                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="entertainmentAsNumber" name="entertainmentAsNumber" min={0} max={8000} step={50} value={this.state.entertainmentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>

                                                                                  </div>                                                                                                      
                                                                                  
                                                                              </div>  

                                                                              <div class="row">
                                                                              
                                                                                <div class="col-md-4">
                                                                                    <label class="dialog-form-label" for="gasoline">Gas (Car)</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="gasoline" placeholder="$" name="gasoline" defaultValue={this.state.gasoline} value={this.state.gasoline} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="gasolineAsNumber" name="gasolineAsNumber" min="0" max="5000" step="50" value={this.state.gasolineAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>

                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="gasolineAsNumber" name="gasolineAsNumber" min={0} max={8000} step={50} value={this.state.gasolineAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>

                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <label class="dialog-form-label" for="electric">Gas, Electric</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="electric" placeholder="$" name="electric" defaultValue={this.state.electric} value={this.state.electric} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="electricAsNumber" name="electricAsNumber" min="0" max="5000" step="50" value={this.state.electricAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>
                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="electricAsNumber" name="electricAsNumber" min={0} max={8000} step={50} value={this.state.electricAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                  </div>

                                                                                  <div class="col-md-4">  
                                                                                    <label class="dialog-form-label" for="groceries">Groceries</label>
                                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="groceries" placeholder="$" name="groceries" defaultValue={this.state.groceries} value={this.state.groceries} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                    <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="groceriesAsNumber" name="groceriesAsNumber" min="0" max="5000" step="50" value={this.state.groceriesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                    </span>
                                                                                    <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="groceriesAsNumber" name="groceriesAsNumber" min={0} max={8000} step={50} value={this.state.groceriesAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                  </div>                                                                                                      
                                                                                  
                                                                              </div> 


                                                                            <div class="row">
                                                                              
                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="insuranceHealthMedical">Insurance (Health/Medical)</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="insuranceHealthMedical" placeholder="$" name="insuranceHealthMedical" defaultValue={this.state.insuranceHealthMedical} value={this.state.insuranceHealthMedical} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="insuranceHealthMedicalAsNumber" name="insuranceHealthMedicalAsNumber" min="0" max="5000" step="50" value={this.state.insuranceHealthMedicalAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="insuranceHealthMedicalAsNumber" name="insuranceHealthMedicalAsNumber" min={0} max={8000} step={50} value={this.state.insuranceHealthMedicalAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>
                                                                              </div>

                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="internet">Internet</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="internet" placeholder="$" name="internet" defaultValue={this.state.internet} value={this.state.internet} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="internetAsNumber" name="internetAsNumber" min="0" max="5000" step="50" value={this.state.internetAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="internetAsNumber" name="internetAsNumber" min={0} max={8000} step={50} value={this.state.internetAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>
                                                                                </div>

                                                                                <div class="col-md-4">  
                                                                                  <label class="dialog-form-label" for="lifeInsurance">Life Insurance</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="lifeInsurance" placeholder="$" name="lifeInsurance" defaultValue={this.state.lifeInsurance} value={this.state.lifeInsurance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="lifeInsuranceAsNumber" name="lifeInsuranceAsNumber" min="0" max="5000" step="50" value={this.state.lifeInsuranceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="lifeInsuranceAsNumber" name="lifeInsuranceAsNumber" min={0} max={8000} step={50} value={this.state.lifeInsuranceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>

                                                                                </div>                                                                                                      
                                                                                
                                                                            </div>  


                                                                            <div class="row">
                                                                              
                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="medicalDentalVision">Medical/Dental/Vision</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="medicalDentalVision" placeholder="$" name="medicalDentalVision" defaultValue={this.state.medicalDentalVision} value={this.state.medicalDentalVision} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                      <input type="range" className="custom-range" id="medicalDentalVisionAsNumber" name="medicalDentalVisionAsNumber" min="0" max="5000" step="50" value={this.state.medicalDentalVisionAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                      <CustomizedSlider id="medicalDentalVisionAsNumber" name="medicalDentalVisionAsNumber" min={0} max={8000} step={50} value={this.state.medicalDentalVisionAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                      <br></br>     
                                                                                  </span>

                                                                              </div>

                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="phone">House Phone (Landline)</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="phone" placeholder="$" name="phone" defaultValue={this.state.phone} value={this.state.phone} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="phoneAsNumber" name="phoneAsNumber" min="0" max="5000" step="50" value={this.state.phoneAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="phonAsNumber" name="phonAsNumber" min={0} max={8000} step={50} value={this.state.phonAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>
                                                                                </div>

                                                                                <div class="col-md-4">  
                                                                                  <label class="dialog-form-label" for="recreationVacation">Recreation/Vacation</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="recreationVacation" placeholder="$" name="recreationVacation" defaultValue={this.state.recreationVacation} value={this.state.recreationVacation} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="recreationVacationAsNumber" name="recreationVacationAsNumber" min="0" max="5000" step="50" value={this.state.recreationVacationAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="recreationVacationAsNumber" name="recreationVacationAsNumber" min={0} max={8000} step={50} value={this.state.recreationVacationAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>
                                                                                
                                                                                </div>                                                                                                      
                                                                                
                                                                            </div>  



                                                                            <div class="row">
                                                                              
                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="studentLoan">Student Loan(s)</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="studentLoan" placeholder="$" name="studentLoan" defaultValue={this.state.studentLoan} value={this.state.studentLoan} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="studentLoanAsNumber" name="studentLoanAsNumber" min="0" max="5000" step="50" value={this.state.studentLoanAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>
                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="studentLoanAsNumber" name="studentLoanAsNumber" min={0} max={8000} step={50} value={this.state.studentLoanAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>
                                                                              </div>

                                                                              <div class="col-md-4">
                                                                                  <label class="dialog-form-label" for="tuition">Tuition</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="tuition" placeholder="$" name="tuition" defaultValue={this.state.tuition} value={this.state.tuition} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="tuitionAsNumber" name="tuitionAsNumber" min="0" max="5000" step="50" value={this.state.tuitionAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>

                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="tuitionAsNumber" name="tuitionAsNumber" min={0} max={8000} step={50} value={this.state.tuitionAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>
                                                                                </div>

                                                                                <div class="col-md-4">  
                                                                                  <label class="dialog-form-label" for="water">Water</label>
                                                                                  <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="water" placeholder="$" name="water" defaultValue={this.state.water} value={this.state.water} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                  <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="waterAsNumber" name="waterAsNumber" min="0" max="5000" step="50" value={this.state.waterAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                  </span>

                                                                                  <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="waterAsNumber" name="waterAsNumber" min={0} max={8000} step={50} value={this.state.waterAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                    <br></br>     
                                                                                </span>
                                                                                </div>                                                                                                      
                                                                          
                                                                      </div>  


                                                                      <div  style={{textAlign:'center',paddingBottom:'5px'}}>TOTAL:<br></br>{this.FormatMoney(this.state.monthlyBillsAsNumber)}</div>



                                                                      <div style={{textAlign:"center"}}>
                                                                          <Button style={{fontSize:"16px"}} onClick={e => this.HandleMonthlyBillsBreakdownClose(e, this)}>DONE</Button> 
                                                                      </div>   

                                                                      </div>
                                                                              </Tooltip>}
                                                                              >
                                                                                <FileEarmarkText className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                              
                                                                              </OverlayTrigger>

                                                                          </td>
                                                                        </tr>

                                                                        <tr>
                                                                          <td style={{width:'80%'}}>
                                                                            <span class="desktop-only-block">
                                                                                <input type="range" className="custom-range" id="monthlyBillsAsNumber" name="monthlyBillsAsNumber" min="0" max="8000" step="100" value={this.state.monthlyBillsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                        </span>

                                                                            <span class="mobile-only-block">
                                                                                <CustomizedSlider id="monthlyBillsAsNumber" name="monthlyBillsAsNumber" min={0} max={8000} step={100} value={this.state.monthlyBillsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                            <br></br>     
                                                                            </span>

                                                                          </td>
                                                                          <td style={{width:'20%'}}></td>
                                                                        </tr>
                                                                    </table>

                                                                        </div>
                                                                    </div>
                                                              
                                                                    
                                                            </div>  {/* ----- End Row ----*/}

                                                            <div class="row">
                                                                
                                                                   {/* Budget */} 
                                                                   <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>Your budget allocated for this investment.</div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="budget">Budget</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="budget" placeholder="$" name="budget" defaultValue={this.state.budget} value={this.state.budget} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                        <span class="desktop-only-block">
                                                                            <input type="range" className="custom-range" id="budgetAsNumber" name="budgetAsNumber" min="0" max="2000000" step="100" value={this.state.budgetAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                            <CustomizedSlider id="budgetAsNumber" name="budgetAsNumber" min={0} max={2000000} step={100} value={this.state.budgetAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                            <br></br>     
                                                                        </span>
                                
                                                                        </div>
                                                                    </div>
                                                                    
                                                                {/* Credit Score
                                                                
                                                                <div class="col-md-4">
                                                                          
                                                                          <div class="form-group">
                                                                                    <OverlayTrigger
                                                                                          placement="auto"
                                                                                          delay={{ show: 150, hide: 100 }} 
                                                                                          trigger={["hover","click"]}
                                                                                          overlay={<Tooltip  className="messagetooltip">
                                                                                        
                                                                                            <div className='helpText'>
                                                                                              
                                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                              Buyer's credit score.
                                                                                              </div>

                                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                              The credit score is only used for the credit score financial indicator.
                                                                                              It doesn't impact any of the other financial indicators.
                                                                                              </div>
                                                                                              
                                                                                              </div>
                                                                                        </Tooltip>}
                                                                                        >
                                                                                        <div>
                                                                                                <label class="dialog-form-label" for="creditScore">Credit Score</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                        </div>
                                                                                    </OverlayTrigger>
                                                                            
                                                                                    <DropdownButton id="creditScoreDropdownSelect" name="creditScoreDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.creditScore} onSelect={(e) => this.HandleSelect(e)}>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '760+']}>760+</Dropdown.Item>
                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '740-759']}>740-759</Dropdown.Item>

                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '720-739']}>720-739</Dropdown.Item>

                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '700-719']}>700-719</Dropdown.Item>
                                                                                        
                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '680-699']}>680-699</Dropdown.Item>
                                                                                        
                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '660-679']}>660-679</Dropdown.Item>
                                                                                        
                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '640-659']}>640-659</Dropdown.Item>
                                                                                        
                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '620-639']}>620-639</Dropdown.Item>
                                                                                        
                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '580-619']}>580-619</Dropdown.Item>
                                                                                        
                                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '300-579']}>300-579</Dropdown.Item>
                                                                                      
                                                                                    </DropdownButton>

                                                                                </div>
                                                                                
                                                                            </div>
                                                                 */}       


                                                                    <div class="col-md-4">

                                                                        &nbsp;      
                                                                                
                                                                    </div>
                                                                    
                                                            </div>  {/* ----- End Row ----*/}



                                                        </div>

                                                      </div>
                                                    </div>  {/* ----- End Row ----*/}






                                                    {/* ----------------------------------------- AREA CURRENT TRENDS -------------------------------------------- */}
                                                    
                                                    <div class="row space-bottom" style={{paddingTop:'10px',display:'none'}} id="areaTrendBlock">
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleAreaExpand(e, this)}>AREA CURRENT TRENDS<span id="areaTrendPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="areaTrendContent" style={{display:'none',paddingTop:'5px'}}>
                                                          
                                                      
                                                            <div class="row">
                                                                
                                                                {/* Trending Price Per Square Foot */} 
                                                                <div class="col-md-4">
                                                                  <div class="form-group">
                                                                      <OverlayTrigger
                                                                          placement="auto"
                                                                          delay={{ show: 150, hide: 100 }} 
                                                                          trigger={["hover","click"]}
                                                                          overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>

                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              The trending price per square foot is the average price per square foot properties are selling for in the area. 
                                                                              It helps determine whether the price per square foot of the property falls within the norm.
                                                                              </div>
                                                                              
                                                                              <div style={{color:"#ffa500",paddingTop:"10px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                  
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>  
                                                                              You can calculate the trending price per square foot yourself by finding comparable
                                                                              properties that have sold in the same area in the last 3 months and calculate
                                                                              the average price per square foot. Use listing websites like <a href="https://www.zillow.com" target="blank">https://www.zillow.com</a> <br></br>
                                                                              to find similar sold properties.
                                                                              </div>
                                                                              
                                                                              <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                              The trending price per square foot is only used for the price per square foot financial indicator.
                                                                              It doesn't impact any of the other financial indicators.
                                                                              </div>
                                                                              
                                                                              </div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="pricePerSqft">Trending Price Per Square Foot</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                      
                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="trendingPricePerSqft" placeholder="Trending Price per SqFt $" name="trendingPricePerSqft" defaultValue={this.state.trendingPricePerSqft} value={this.state.trendingPricePerSqft} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                      <span class="desktop-only-block">
                                                                          <input type="range" className="custom-range" id="trendingPricePerSqftAsNumber" name="trendingPricePerSqftAsNumber" min="0" max="1000" step="1" value={this.state.trendingPricePerSqftAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                      </span>

                                                                      <span class="mobile-only-block">
                                                                          <CustomizedSlider id="trendingPricePerSqftAsNumber" name="trendingPricePerSqftAsNumber" min={0} max={1000} step={1} value={this.state.trendingPricePerSqftAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                          <br></br>     
                                                                      </span>
                                      
                                                                      </div>
                                                                </div>
                                                                
                                                                {/* House icon to show how trending price per sqft was calculated */}
                                                                {this.state.investmentType === "Flipping Property" &&
                                                                <div class="col-md-1 desktop-only-block" id="trendingPricePerSqftSimilarProps" style={{padding:"0px",margin:"0px",paddingTop:'35px'}}>
                                                                    {this.state.similarSoldProperties?.length > 0 &&
                                                                              <OverlayTrigger   
                                                                                placement="auto" 
                                                                                rootClose={true}
                                                                                container={this.mainModalInfoDialogRef}
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["click"]} 
                                                                                overlay={<Tooltip  id="mortgagePaymentBreakdown" name="mortgagePaymentBreakdown"  className="messagetooltip">
                                                                              
                                                                                  <div className='helpText'>
                                                                                      <div class="orangeInfo" style={{textAlign:'center'}}>Comparable Properties</div>

                                                                                        {this.state.similarSoldProperties?.map((similarProperty, i) => (
                                                                                            <div class="row">

                                                                                            <div class="col-md-12" style={{borderBottom: '1px solid #FFFFFF',padding:'10px'}}>
                                                                                              <div style={{fontWeight:'500',fontSize:'17px'}}>{this.FormatMoney(similarProperty.price)}</div> 
                                                                                              {similarProperty.address} &nbsp; &nbsp; 
                                                                                              <a href={similarProperty.link} target='_blank' style={{float:'right'}}> 
                                                                                                <Link45deg className="ml-2 orangeInfo" style={{fontSize:'25px'}}/>
                                                                                              </a>
                                                                                              
                                                                                              <div style={{paddingTop:'8px',paddingBottom:'8px'}}>{similarProperty.beds} bd | {similarProperty.baths} ba | {this.FormatNumber(similarProperty.sqFt)} sqft | <b>{this.CalculatePropertyPricePerSqft(similarProperty)}</b> per sqft</div>
                                                                                              {(similarProperty.soldDate != null && similarProperty.soldDate !='') && 
                                                                                                <span><u>DATE SOLD</u>: <span style={{fontWeight:'500'}}>{similarProperty.soldDate}</span></span>
                                                                                              }
                                                                                            </div> 
        
                                                                                            </div>
        
                                                                                        ))}  
              
                                                                                  </div>

                                                                                  <div style={{textAlign:'left'}}>
                                                                                      Trending Price per SqFt =  (
                                                                                      {this.state.similarSoldProperties?.map((similarProperty, i) => (
                                                                                        <span>{this.CalculatePropertyPricePerSqft(similarProperty)} 
                                                                                        
                                                                                      {i < this.state.similarSoldProperties.length-1 &&
                                                                                      <span> +</span> 
                                                                                      }
                                                                                        
                                                                                        </span> 
                                                                                      ))}
                                                                                      ) / {this.state.similarSoldProperties.length}
                                                                                      <br></br><br></br>
                                                                                      Trending Price per SqFt = {this.CalculatePropertyPricePerSqftAverage(this.state.similarSoldProperties)}
                                                                                      <br></br><br></br>
                                                                                  </div>
                                                                              </Tooltip>}
                                                                              >
                                                                                  <House className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                              </OverlayTrigger>
                                                                          }
                                                                </div>
                                                                }

                                                                {/* Average Monthly Rent */} 
                                                                <div class="col-md-4" id="averageMonthlyRentBlock">
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  The trending monthly rent for comparable properties in the area. 
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  <span style={{color:"#ffa500"}}>
                                                                                  <b><u>NOTE:</u></b>
                                                                                  </span>
                                                                                  <br></br>
                                                                                  Although you are not interested in renting, you still need to know whether it is the right time to buy or rent.
                                                                                
                                                                                </div>
                                                                                
                                                                                <div style={{color:"#ffa500",paddingTop:"10px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                      
                                                                                <div style={{paddingTop:'5px',maxWidth:'520px'}}>
                                                                                <span style={{color:"#ffa500", fontWeight:"bold"}}>1)</span> If the property is listed, some listing websites like <a href="https://www.zillow.com" target="blank">https://www.zillow.com</a> <br></br>
                                                                                sometimes give your an estimated rental value:
                                                                                </div>

                                                                                <div style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                                                                  <img src={rentzestimate} style={{height:'75px'}}/>
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',paddingTop:'9px'}}>
                                                                                <span style={{color:"#ffa500", fontWeight:"bold"}}>2)</span> You can also use the following website to find the median rent in the area:
                                                                                <div style={{paddingTop:'10px'}}>
                                                                                <a href="https://www.zumper.com/rent-research/" target="blank">https://www.zumper.com/rent-research/</a>
                                                                                </div>                        
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>  
                                                                                <span style={{color:"#ffa500", fontWeight:"bold"}}>3)</span> You can also calculate the trending rent yourself by finding comparable
                                                                                properties that are renting in the same area and calculate
                                                                                the average rent. Use listing websites like <a href="https://www.redfin.com/" target="blank">https://www.redfin.com/</a> or <a href="https://www.zillow.com" target="blank">https://www.zillow.com</a> <br></br>
                                                                                to find similar properties for rent.
                                                                                </div>
                                                                                
                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The trending rent is used to calculate the Price-to-Rent Ratio financial indicator, 
                                                                                which tells you whether or not it is a good idea to buy at the time 
                                                                                as opposed to renting.
                                                                                </div>
                                                                                
                                                                                </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="trendingMonthlyRent">Trending Monthly Rent</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="trendingMonthlyRent" placeholder="$" name="trendingMonthlyRent" defaultValue={this.state.trendingMonthlyRent} value={this.state.trendingMonthlyRent} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                        <span class="desktop-only-block">
                                                                          <input type="range" className="custom-range" id="trendingMonthlyRentAsNumber" name="trendingMonthlyRentAsNumber" min="0" max="8000" step="100" value={this.state.trendingMonthlyRentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                            <CustomizedSlider id="trendingMonthlyRentAsNumber" name="trendingMonthlyRentAsNumber" min={0} max={8000} step={100} value={this.state.trendingMonthlyRentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                            <br></br>     
                                                                        </span>

                                                                        </div>
                                                                </div>
                                                            
                                                                    
                                                            </div>  {/* ----- End Row ----*/}




                                                        </div>

                                                      </div>
                                                    </div>  {/* ----- End Row ----*/}


                {/* ----------------------------------------- PROJECTIONS -------------------------------------------- */}
                                                    
                <div class="row space-bottom" style={{paddingTop:'10px',display:'none'}} id="projectionBlock">
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleProjectionsExpand(e, this)}>PROJECTIONS  <span id="projectionsPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="projectionsContent" style={{display:'none'}}>

                                                        <div class="row">
                                                  
                                                  {/* Projected Year of Sale */} 
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                        <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip className="messagetooltip">
                                                              
                                                                  <div className='helpText'>
                                                                      <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                        The year you plan to sell the property. or in other words the number of years you plan to keep the property.
                                                                      </div>               

                                                                      <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                      The projected year of sale has an impact on the financial indicator calculators.
                                                                      </div>                   
                                                                  </div>
                                                              </Tooltip>}
                                                              >
                                                              <div>
                                                                      <label class="dialog-form-label" for="projectedYearOfSaleDropdownSelect">Projected Year of Sale</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                              </div>
                                                            </OverlayTrigger>
                                                            
                                                            <DropdownButton id="projectedYearOfSaleDropdownSelect" name="projectedYearOfSaleDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.projectedYearOfSale} onSelect={(e) => this.HandleSelect(e)}>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 1']}>Year 1</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 2']}>Year 2</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 3']}>Year 3</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 4']}>Year 4</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 5']}>Year 5</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 6']}>Year 6</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 7']}>Year 7</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 8']}>Year 8</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 9']}>Year 9</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 10']}>Year 10</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 11']}>Year 11</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 12']}>Year 12</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 13']}>Year 13</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 14']}>Year 14</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 15']}>Year 15</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 16']}>Year 16</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 17']}>Year 17</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 18']}>Year 18</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 19']}>Year 19</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 20']}>Year 20</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 21']}>Year 21</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 22']}>Year 22</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 23']}>Year 23</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 24']}>Year 24</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 25']}>Year 25</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 26']}>Year 26</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 27']}>Year 27</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 28']}>Year 28</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 29']}>Year 29</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['projectedYearOfSale', 'Year 30']}>Year 30</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                            </DropdownButton>
                                                            
                                                        </div>
                                                    </div>
                                              
                                                    
                                                    <div class="col-md-4">
                                                              
                                                    &nbsp;
                                                    
                                                </div>

                                            </div>  {/* ----- End Row ----*/}

                                                  {/* Auto Calculate                                                        
                                                        <div class="row">
                                                        
                                                          <div class="col-md-8" style={{marginTop:'10px'}}>
                                                              
                                                              <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip className="messagetooltip">
                                                              
                                                                  <div className='helpText'>Allow the application to estimated the projected figures,<br></br>except the projected year of sale.</div>
                                                              </Tooltip>}
                                                              >
                                                              <div>
                                                                <BootstrapSwitchButton checked={true} onstyle="warning" offstyle="outline-warning" onlabel='YES' offlabel='NO' size="xs"  onChange={(checked) => {alert(checked)  }}/> <label class="dialog-form-label" for="projectedYearOfSaleDropdownSelect" style={{color:'#FFFFFF'}}>Estimate projected figures</label>  <InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                              </div>
                                                            </OverlayTrigger>
                                                            </div>
                                                            
                                                        </div>
                                                        <br></br>
                                                    */} 
                                                        <div class="row">
                                                  
                                                  {/* Sale Proceeds */} 
                                        
                                                    
                                                    <div class="col-md-12">
                                                              
                                                    <div class="form-group">
                                                        <OverlayTrigger
                                                              placement="auto"
                                                              delay={{ show: 150, hide: 100 }} 
                                                              trigger={["hover","click"]}
                                                              overlay={<Tooltip  className="messagetooltip">
                                                            
                                                                <div className='helpText'>
                                                                  
                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                  The proceeds that would be expected to be received from the sale of 
                                                                  the property in the future.
                                                                  </div>

                                                                  <div style={{color:"#ffa500",paddingTop:"15px"}}>WHERE DO I OBTAIN THIS VALUE?</div>
                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                    This value will be pre-calculated by the application based on the projected year of sale 
                                                                    and the annual appreciation rate specified in Settings.
                                                                  </div>

                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                    Currently the annual appreciation rate is set to:
                                                                    
                                                                    <span style={{color:"#ffa500", fontWeight:"bold",fontSize:'16px',paddingLeft:'5px'}}>{this.FormatPercent(this.state.settings?.annualHomeAppreciationPercent)}</span>
                                                                    </div>
                                                                    <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                    Go to settings to adjust the default annual appreciation rate to your own expectations.

                                                                    </div>

                                                                  <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                      The projected sale proceeds has an impact on the financial indicator calculators.
                                                                      </div>  


                                                                </div>
                                                            </Tooltip>}
                                                            >
                                                            <div>
                                                                    <label class="dialog-form-label" for="projectedSaleProceeds">Projected Sale Proceeds</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                            </div>
                                                        </OverlayTrigger>
                                                          
                                                        <table>
                                                            <tr>
                                                                <td  class="compound-column-width">
    
                                                                    <CurrencyInput type="text" class="form-select alert-edit-modal-component projected-sale-proceeds-with"  id="projectedSaleProceeds" placeholder="Projected Sale Proceeds $" name="projectedSaleProceeds" defaultValue={this.state.projectedSaleProceeds} value={this.state.projectedSaleProceeds} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                        
                                                                    </td>
                                                                            <td style={{width:'20%'}}>
                                                                                    <OverlayTrigger   
                                                                                  placement="auto" 
                                                                                  rootClose={true}
                                                                                  container={this.mainModalInfoDialogRef}
                                                                                  delay={{ show: 150, hide: 100 }} 
                                                                                  trigger={["click"]} 
                                                                                  overlay={<Tooltip  id="monthlyExpensesBreakdown" name="monthlyExpensesBreakdown"  className="messagetooltip">
                                                                                        
                                                                                        <div className='helpText'>

                                                                                            <div class="orangeInfo" style={{textAlign:'center'}}>Future Sale Proceeds Calculator</div>
                                                                                              <br></br>

                                                                                            {this.state.interestRateAsNumber > 0 && 
                                                                                            this.state.projectedYearOfSale !='_' && 
                                                                                            this.state.propertyPriceAsNumber > 0 && 
                                                                                            this.state.downPaymentAsNumber > 0 &&
                                                                                            this.state.termAsNumber > 0 && 

                                                                                                <div class="row">
                                                                                                    <div class="col-md-12">
                                                                            
                                                                                                      <div class="form-group">
                                                                                                        <center>

                                                                                                          <OverlayTrigger
                                                                                                                placement="auto"
                                                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                                                trigger={["hover","click"]}
                                                                                                                overlay={<Tooltip  className="messagetooltipWhite">
                                                                                                              
                                                                                                                  <div className='helpText'>The rate at which the property value is expected <br></br>
                                                                                                                                            to increase every year. The average annual <br></br>
                                                                                                                                            appreciation in the past years has been {this.settings?.annualHomeAppreciationPercent}%.

                                                                                                                                            <br></br><br></br>
                                                                                                                                            Adjust the annual appreciation to your specific location.
                                                                                                                                            
                                                                                                                  </div>
                                                                                                              </Tooltip>}
                                                                                                              >
                                                                                                              <div>
                                                                                                                      <label class="dialog-form-label" for="annualAppreciation">Annual appreciation</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                              </div>
                                                                                                          </OverlayTrigger>
                                                                                                          
                                                                                                          <table>
                                                                                                              <tr>
                                                                                                                <td  class="compound-column-width">
                                                                                                            <input type="text" class="form-select alert-edit-modal-component" id="annualAppreciation" placeholder="%" name="annualAppreciation" defaultValue={this.state.annualAppreciation} value={this.state.annualAppreciation} onChange={this.handlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                                                            <input type="range" className="custom-range" id="annualAppreciationAsNumber" name="annualAppreciationAsNumber" min="0" max="20" step=".25" value={this.state.annualAppreciationAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                                                                            
                                                                                                            
                                                                                                            <div style={{textAlign:'center'}}>
                                                                                                            PROJECTED SALE PROCEEDS:
                                                                                                            </div>
                                                                                                                <center>
                                                                                                                  <div style={{fontWeight:'bold',fontSize:'18px'}}>
                                                                                                                      {this.state.projectedSaleProceeds}
                                                                                                                  </div>
                                                                                                                </center>

                                                                                                            </td>
                                                                                                            <td style={{width:'20%'}}>
                                                                                                              &nbsp;
                                      
                                                                                                              </td>
                                                                                                              </tr>
                                                                                                            </table>
                                                                                                          
                                                                                                            
                                                                                                            
                                                                                                          </center>

                                                                                                          {this.state.autoCalculateSaleProceeds && 
                                                                                                          <span>
                                                                                                              <br></br>
                                                                                                              <div style={{paddingLeft:'5px',marginLeft:'5px',paddingBottom:'5px'}}>
                                                                                                              <u>CALCULATIONS</u>
                                                                                                              </div>
                                                                                                            <div className="scrollable-div">  {/* ----------------- SCROLLABLE DIV ---------------- */}

                                                                                                                    
                                                                                                                      Below are the calculations the application used <br></br>
                                                                                                                      to auto calculate sales proceeds:
                                                                                                                      <br></br><br></br>

                                                                                                                      {this.state.financingType == "Loan" && 
                                                                                                                      <span>
                                                                                                                        <u>STEP 1</u>: <b>Loan Amount</b>
                                                                                                                        <br></br>
                                                                                                                        <div style={{paddingTop:'8px'}}>
                                                                                                                        {this.state.propertyPrice} (price) - {this.state.downPayment} (down payment) = <b>{this.state?.projectedSaleProceedsMap?.get("LOAN_AMOUNT")}</b>
                                                                                                                        </div>
                                                                                                                        <br></br>
                                                                                                                      </span>
                                                                                                                      }

                                                                                                                      <u>{this.state.financingType == "Loan" ? 'STEP 2' : 'STEP 1'}</u>: <b>Resale Value</b>
                                                                                                                      <br></br>
                                                                                                                      <div style={{paddingTop:'8px'}}>
                                                                                                                      Future Resale Value = Price * (1 + Annual Home Appreciate) <sup>years</sup>
                                                                                                                      <br></br>
                                                                                                                      Future Resale Value = {this.state.propertyPrice} * (1 + {this.state?.projectedSaleProceedsMap?.get("HOME_APPRECIATION_PERCENT")}) <sup>{this.state?.projectedSaleProceedsMap?.get("YEAR_OF_SALE")}</sup>
                                                                                                                      <br></br>
                                                                                                                      Future Resale Value = <b>{this.state?.projectedSaleProceedsMap?.get("RESALE_VALUE")}</b>
                                                                                                                      </div>
                                                                                                                      <br></br>
                                                                                                                      Note: The <b>{this.state?.projectedSaleProceedsMap?.get("HOME_APPRECIATION_PERCENT")}</b> is the 
                                                                                                                      the rate at which the property value <br></br> 
                                                                                                                      is expected to increase every year. 
                                                                                                                      <br></br><br></br>

                                                                                                                      {this.state.financingType == "Loan" && 
                                                                                                                      <span>  
                                                                                                                      <u>STEP 3</u>: <b>Mortgage Balance</b>
                                                                                                                      <br></br>
                                                                                                                      Mortgage balance left to pay after {this.state?.projectedSaleProceedsMap?.get("YEAR_OF_SALE")} years
                                                                                                                      <br></br>
                                                                                                                      <div style={{paddingTop:'8px'}}>
                                                                                                                      Mortgage Balance = <b>{this.state?.projectedSaleProceedsMap?.get("MORTGAGE_BALANCE")}</b>
                                                                                                                      </div>
                                                                                                                      <br></br>

                                                                                                                      <u>STEP 4</u>: <b>Home Equity</b>
                                                                                                                      <br></br>
                                                                                                                      <div style={{paddingTop:'8px'}}>
                                                                                                                      Home Equity = Resale Value - Mortgage Balance
                                                                                                                        <br></br>
                                                                                                                      Home Equity = {this.state?.projectedSaleProceedsMap?.get("RESALE_VALUE")} - {this.state?.projectedSaleProceedsMap?.get("MORTGAGE_BALANCE")}<b></b>
                                                                                                                      <br></br>
                                                                                                                      Home Equity = <b>{this.state?.projectedSaleProceedsMap?.get("HOME_EQUITY")}</b>
                                                                                                                      </div>
                                                                                                                      <br></br>
                                                                                                                      </span>
                                                                                                                      }
                                                                                                                      <u>{this.state.financingType == "Loan" ? 'STEP 5' : 'STEP 2'}</u>: <b>Selling Costs</b>
                                                                                                                      <br></br>
                                                                                                                      <div style={{paddingTop:'8px'}}>
                                                                                                                      Selling Costs = Resale Value * Selling Cost Percent
                                                                                                                      <br></br>
                                                                                                                      Selling Costs = {this.state?.projectedSaleProceedsMap?.get("RESALE_VALUE")} * {this.state?.projectedSaleProceedsMap?.get("SELLING_COSTS_PERCENT")}
                                                                                                                      <br></br>
                                                                                                                      Selling Costs = <b>{this.state?.projectedSaleProceedsMap?.get("SELLING_COSTS")}</b>
                                                                                                                      </div>
                                                                                                                      <br></br>
                                                                                                                      Note: The <b>{this.state?.projectedSaleProceedsMap?.get("SELLING_COSTS_PERCENT")}</b> comes from Settings.
                                                                                                                      <br></br><br></br>

                                                                                                                      <u>{this.state.financingType == "Loan" ? 'STEP 6' : 'STEP 3'}</u>: <b>Sale Proceeds</b>
                                                                                                                      <br></br>
                                                                                                                      <div style={{paddingTop:'8px'}}>
                                                                                                                      Sale Proceeds = {this.state.financingType == "Loan" ? 'Home Equity' : 'Resale Value'} - Selling Costs
                                                                                                                      <br></br>
                                                                                                                      Sale Proceeds = {this.state?.projectedSaleProceedsMap?.get("HOME_EQUITY")} - {this.state?.projectedSaleProceedsMap?.get("SELLING_COSTS")}<b></b>
                                                                                                                      <br></br>
                                                                                                                      Sale Proceeds = <b>{this.state?.projectedSaleProceedsMap?.get("CASH_FLOW")}</b>
                                                                                                                      </div>
                                                                                                                      <br></br>                                                                    


                                                                                                            </div>  {/* ----- END SCROLLABLE DIV ------*/}
                                      
                                                                                                          </span>
                                                                                                          }

                                                                                                            <div style={{textAlign:"center"}}>
                                                                                                                <Button style={{fontSize:"16px"}} onClick={e => this.HandleInitialExpensesBreakClose(e, this)}>DONE</Button> 
                                                                                                            </div> 
                                                                                                      </div>
                                                                                                      
                                                                                                  </div>
            
                                                                                                </div>
                                                                                              }


                                                                                            {(this.state.interestRateAsNumber <= 0 || 
                                                                                            this.state.projectedYearOfSale =='_' || 
                                                                                            this.state.propertyPriceAsNumber <= 0 || 
                                                                                            this.state.downPaymentAsNumber <= 0 ||
                                                                                            this.state.termAsNumber <= 0) && 
                                                                                                
                                                                                                <div>
                                                                                                <div class="row" style={{padding:'10px'}}>
                                                                                                
                                                                                                      You have to enter values for the following field(s) <br></br>
                                                                                                      before the application can calcualte the future sale <br></br>
                                                                                                      proceeds:
                                                                                                      
                                                                                                </div>
                                                                                                <div class="row" style={{padding:'10px'}}>
                                                                                                      <br></br><br></br>     
                                                                                                    
                                                                                                      <table>
                                                                                                      {this.state.propertyPriceAsNumber <= 0  && <tr><td>&#8226; Propety Price</td></tr>}
                                                                                                      {this.state.downPaymentAsNumber <= 0 && <tr><td>&#8226; Down Payment</td></tr>}
                                                                                                      {this.state.interestRateAsNumber <= 0 && <tr><td>&#8226; Interest Rate </td></tr>}
                                                                                                      {this.state.termAsNumber <= 0 && <tr><td>&#8226; Loan Term</td></tr>}
                                                                                                      {this.state.projectedYearOfSale =='_' && <tr><td> &#8226; Projected Year of Sale</td></tr>}
                                                                                                      
                                                                                                      </table>
                                                                                                      <br></br><br></br>

                                                                                                  </div> 
                                                                                                  </div>
                                                                                                  }
                                                                                            </div>
                                                                                  </Tooltip>}
        >
                                                                                <FileEarmarkText className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                              
                                                                              </OverlayTrigger>

                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                          <td style={{width:'80%'}}>
                                                                                <span class="desktop-only-block">                    
                                                                                    <input type="range" className="custom-range" id="projectedSaleProceedsAsNumber" name="projectedSaleProceedsAsNumber" min="0" max="5000000" step="50000" value={this.state.projectedSaleProceedsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>
                                                                                </span>

                                                                                <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="projectedSaleProceedsAsNumber" name="projectedSaleProceedsAsNumber" min={0} max={5000000} step={50000} value={this.state.projectedSaleProceedsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>
                                                                                    <br></br>     
                                                                                </span>

                                                                                </td>
                                                                            <td style={{width:'20%'}}></td>
                                                                          </tr>
                                                                        </table>
                                                            
                                                    </div>
                                                    
                                                </div>

                                                <div class="col-md-4">
                                                      &nbsp;
                                                  </div>

                                            </div>  {/* ----- End Row ----*/}

                                            {/* Year 1 - 30 CashFlows*/}

                                      
                                            {Array.from({ length: 16 }, (_, i) => 
                                                <div>
                                                  {i > 0 &&  
                                                    <div id={"cashflowRow"+((i*2)-1)+"-"+(i*2)} style={{display:'none'}}>
                                                    <div class="row">

                                                            <div class="col-md-4" id={"year"+((i*2)-1)+"CashFlowColumn"}>
                                                                  
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                              placement="auto"
                                                                              delay={{ show: 150, hide: 100 }} 
                                                                              trigger={["hover","click"]}
                                                                              overlay={<Tooltip  className="messagetooltip">
                                                                            
                                                                                <div className='helpText'>
                                                                                
                                                                                <div style={{paddingBottom:'10px'}}>
                                                                                  This is the expected cashflow from the property on year {(i*2)-1}.
                                                                                </div>

                                                                                {this.state["year"+((i*2)-1)+"CashFlowMap"] ==null  && 
                                                                              
                                                                                  <span>      
                                                                                  {!this.state.autoCalculateYearCashFlows  && 
                                                                                  <span>
                                                                                  Many factors impact the net cashflow from <br></br>
                                                                                  year to year, including: 
                                                                                  </span>
                                                                                  }

                                                                                  {this.state.autoCalculateYearCashFlows  && 
                                                                                  <span>
                                                                                  Make sure the following fields are populated to <br></br>
                                                                                  allow the application to auto calculate this<br></br> 
                                                                                  year's cashflow:
                                                                                  </span>
                                                                                  }
                                                                                  <br></br><br></br>
                                                                                  <ul>
                                                                                    <li>Monthly Rent</li>
                                                                                    <li>Monthly Expenses</li>
                                                                                    <li>Rent Rate of Growth</li>
                                                                                    <li>Expense Rate of Growth</li>
                                                                                    <li>Vacancy Rate</li>
                                                                                    <li>Monthly Mortgage</li>
                                                                                  </ul>


                                                                              </span>
                                                                            
                                                                                }

                                                                                {this.state["year"+((i*2)-1)+"CashFlowMap"] !=null && this.state.autoCalculateYearCashFlows  && 
                                                                                <div>
                                                                                  Below are the calculations the application used <br></br>
                                                                                  to auto calculate this year's cash flow:
                                                                                  <br></br><br></br>
                                                                                  <u>STEP 1</u>: <b>Gross Annual Income</b>
                                                                                  <br></br>
                                                                                  <div style={{paddingTop:'8px'}}>
                                                                                  {this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_MONTHLY_RENT")} (rent) x 12 = <b>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_GROSS_ANNUAL_INCOME")}</b>
                                                                                  </div>

                                                                                  <br></br>
                                                                                  <u>STEP 2</u>: <b>Vacancy and Credit Loss</b>
                                                                                  <br></br>
                                                                                  <div style={{paddingTop:'8px'}}>
                                                                                  {this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_GROSS_ANNUAL_INCOME")} x {this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_VACANCY_RATE")}% = <b>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_VACANCY_CREDIT_LOSS")}</b>
                                                                                  </div>
                                                                                  
                                                                                  <br></br>
                                                                                  <u>STEP 3</u>: <b>Year {(i*2)-1} Cash Flow</b>
                                                                                  
                                                                                  <br></br>
                                                                                  <div style={{paddingTop:'8px'}}>
                                                                                      <table>
                                                                                        <tr>
                                                                                          <td></td><td>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_GROSS_ANNUAL_INCOME")}</td><td>&nbsp;&nbsp;(Gross Annual Income)</td><td style={{fontSize:'10px'}}>-> Increased by Rent Rate of Growth</td>
                                                                                        </tr>
                                                                                        {this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_SALEPROCEEDS_AS_NUMBER") > 0 &&
                                                                                        <tr>
                                                                                          <td>+</td><td>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_SALEPROCEEDS")}</td><td>&nbsp;&nbsp;(Sales Proceeds)</td><td></td>
                                                                                        </tr>
                                                                                        }
                                                                                        <tr>
                                                                                          <td>-</td><td>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_VACANCY_CREDIT_LOSS")}</td><td>&nbsp;&nbsp;(Vacancy and Credit Loss)</td><td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                          <td>-</td><td>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_ANNUAL_EXPENSES")}</td><td>&nbsp;&nbsp;(Annual Expenses)</td><td style={{fontSize:'10px'}}>-> Increased by Expense Rate of Growth</td>
                                                                                        </tr>
                                                                                        {this.state.financingType == "Loan" &&
                                                                                        <tr>
                                                                                          <td>-</td><td>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_ANNUAL_MORTGAGE_PAYMENT")}</td><td>&nbsp;&nbsp;(Annual Mortgage)</td><td></td>
                                                                                        </tr>
                                                                                        }
                                                                                        
                                                                                      </table>
                                                                                  </div>
                                                                                  ------------<br></br>
                                                                                  &nbsp;<b>{this.state["year"+((i*2)-1)+"CashFlowMap"].get("VAR_ANNUAL_CASH_FLOW")}</b>
                                                                                  <br></br><br></br>
                                                                                </div>
                                                                                }  
                                                                                  The application will forecast the future cashflows based on <br></br>
                                                                                  these values, however you can enter them yourself if you <br></br>
                                                                                  wish to do so.
                                                                                  
                                                                                </div>
                                                                            </Tooltip>}
                                                                            >
                                                                            <div>
                                                                                    <label class="dialog-form-label" for="year1CashFlow">Year {(i*2)-1} Cash Flow</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                          
                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id={"year"+((i*2)-1)+"InputCashFlowColumn"} placeholder="$0" name={"year"+((i*2)-1)+"CashFlowColumn"} defaultValue={this.state["year"+((i*2)-1)+"CashFlow"]} value={this.state["year"+((i*2)-1)+"CashFlow"]} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                        <span class="desktop-only-block">
                                                                            <input type="range" className="custom-range" id={"year"+((i*2)-1)+"InputCashFlowColumnAsNumber"} name={"year"+((i*2)-1)+"CashFlowColumnAsNumber"} min="0" max="100000" step="1000" value={this.state["year"+((i*2)-1)+"CashFlowAsNumber"]} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>
                                                                        </span>

                                                                        <span class="mobile-only-block">
                                                                            <CustomizedSlider id={"year"+((i*2)-1)+"InputCashFlowColumnAsNumber"} name={"year"+((i*2)-1)+"CashFlowColumnAsNumber"} min={0} max={100000} step={1000} value={this.state["year"+((i*2)-1)+"CashFlowAsNumber"]} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>
                                                                            <br></br>     
                                                                        </span>
        
                                                                    </div>
                                                                    
                                                            </div>

                                                            <div class="col-md-4" id={"year"+((i*2))+"CashFlowColumn"}>
                                                                  
                                                                  <div class="form-group">
                                                                      <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip  className="messagetooltip">
                                                                          
                                                                              <div className='helpText'>
                                                                              
                                                                              <div style={{paddingBottom:'10px'}}>
                                                                                This is the expected cashflow from the property on year {(i*2)}.
                                                                              </div>

                                                                              {this.state["year"+((i*2))+"CashFlowMap"] ==null  && 
                                                                              <span>      
                                                                                  {!this.state.autoCalculateYearCashFlows  && 
                                                                                  <span>
                                                                                  Many factors impact the net cashflow from <br></br>
                                                                                  year to year, including: 
                                                                                  </span>
                                                                                  }

                                                                                  {this.state.autoCalculateYearCashFlows  && 
                                                                                  <span>
                                                                                  Make sure the following fields are populated to <br></br>
                                                                                  allow the application to auto calculate this<br></br> 
                                                                                  year's cashflow:
                                                                                  </span>
                                                                                  }
                                                                                  <br></br><br></br>
                                                                                  <ul>
                                                                                    <li>Monthly Rent</li>
                                                                                    <li>Monthly Expenses</li>
                                                                                    <li>Rent Rate of Growth</li>
                                                                                    <li>Expense Rate of Growth</li>
                                                                                    <li>Vacancy Rate</li>
                                                                                    <li>Monthly Mortgage</li>
                                                                                  </ul>


                                                                              </span>
                                                                              }

                                                                              {this.state["year"+((i*2))+"CashFlowMap"] !=null && this.state.autoCalculateYearCashFlows  && 
                                                                              <div>
                                                                                Below are the calculations the application used <br></br>
                                                                                to auto calculate this year's cash flow:
                                                                                <br></br><br></br>
                                                                                <u>STEP 1</u>: <b>Gross Annual Income</b>
                                                                                <br></br>
                                                                                <div style={{paddingTop:'8px'}}>
                                                                                {this.state["year"+((i*2))+"CashFlowMap"].get("VAR_MONTHLY_RENT")} (rent) x 12 = <b>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_GROSS_ANNUAL_INCOME")}</b>
                                                                                </div>

                                                                                <br></br>
                                                                                <u>STEP 2</u>: <b>Vacancy and Credit Loss</b>
                                                                                <br></br>
                                                                                <div style={{paddingTop:'8px'}}>
                                                                                {this.state["year"+((i*2))+"CashFlowMap"].get("VAR_GROSS_ANNUAL_INCOME")} x {this.state["year"+((i*2))+"CashFlowMap"].get("VAR_VACANCY_RATE")}% = <b>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_VACANCY_CREDIT_LOSS")}</b>
                                                                                </div>
                                                                                
                                                                                <br></br>
                                                                                <u>STEP 3</u>: <b>Year {(i*2)} Cash Flow</b>
                                                                                
                                                                                <br></br>
                                                                                <div style={{paddingTop:'8px'}}>
                                                                                    <table>
                                                                                      <tr>
                                                                                        <td></td><td>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_GROSS_ANNUAL_INCOME")}</td><td>&nbsp;&nbsp;(Gross Annual Income)</td><td style={{fontSize:'10px'}}>-> Increased by Rent Rate of Growth</td>
                                                                                      </tr>
                                                                                        {this.state["year"+((i*2))+"CashFlowMap"].get("VAR_SALEPROCEEDS_AS_NUMBER") > 0 &&
                                                                                        <tr>
                                                                                          <td>+</td><td>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_SALEPROCEEDS")}</td><td>&nbsp;&nbsp;(Sales Proceeds)</td><td></td>
                                                                                        </tr>
                                                                                        }
                                                                                                                                                                      <tr>
                                                                                        <td>-</td><td>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_VACANCY_CREDIT_LOSS")}</td><td>&nbsp;&nbsp;(Vacancy and Credit Loss)</td><td></td>
                                                                                      </tr>
                                                                                      <tr>
                                                                                        <td>-</td><td>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_ANNUAL_EXPENSES")}</td><td>&nbsp;&nbsp;(Annual Expenses)</td><td style={{fontSize:'10px'}}>-> Increased by Expense Rate of Growth</td>
                                                                                      </tr>
                                                                                      {this.state.financingType == "Loan" && 
                                                                                      <tr>
                                                                                        <td>-</td><td>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_ANNUAL_MORTGAGE_PAYMENT")}</td><td>&nbsp;&nbsp;(Annual Mortgage)</td><td></td>
                                                                                      </tr>
                                                                                      }
                                                                                      
                                                                                    </table>
                                                                                </div>
                                                                                ------------<br></br>
                                                                                &nbsp;<b>{this.state["year"+((i*2))+"CashFlowMap"].get("VAR_ANNUAL_CASH_FLOW")}</b>
                                                                                <br></br><br></br>
                                                                            
                                                                              </div>
                                                                              
                                                                              }  
                                                                              
                                                                                The application will forecast the future cashflows based on <br></br>
                                                                                these values, however you can enter them yourself if you <br></br>
                                                                                wish to do so.
                                                                                
                                                                              </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="year1CashFlow">Year {(i*2)} Cash Flow</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id={"year"+((i*2))+"InputCashFlowColumn"} placeholder="$0" name={"year"+((i*2))+"CashFlowColumn"} defaultValue={this.state["year"+((i*2))+"CashFlow"]} value={this.state["year"+((i*2))+"CashFlow"]} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                      <span class="desktop-only-block">
                                                                          <input type="range" className="custom-range" id={"year"+((i*2))+"InputCashFlowColumnAsNumber"} name={"year"+((i*2))+"CashFlowColumnAsNumber"} min="0" max="100000" step="1000" value={this.state["year"+((i*2))+"CashFlowAsNumber"]} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>
                                                                      </span>
                                                                      <span class="mobile-only-block">
                                                                          <CustomizedSlider id={"year"+((i*2))+"InputCashFlowColumnAsNumber"} name={"year"+((i*2))+"CashFlowColumnAsNumber"} min={0} max={100000} step={1000} value={this.state["year"+((i*2))+"CashFlowAsNumber"]} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>
                                                                          <br></br>     
                                                                      </span>
        
                                                                  </div>
                                                                  
                                                          </div>

                                                          </div>  {/* ----- End Row ----*/}
                                                    </div>
                                                  }
                                                </div>
                                            )}




                                                        </div>

                                                      </div>
                                                    </div>  {/* ----- End Row ----*/}



                                                    {/* ----------------------------------------- NOTES -------------------------------------------- */}

                                                    <div class="row space-bottom" style={{paddingTop:'10px'}}>
                                                    <div class="col-md-12">
                                                        
                                                        <div className="subsectionLabel" onClick={e => this.HandleNotesExpand(e, this)}>PHOTO & NOTES  <span id="notesPlus" className="plusSign">+</span></div>
                                                        
                                                        <div id="notesContent" style={{display:'none',marginTop:'10px'}}>
                                                          
                                                          {/* ----- PHOTO ----*/}
                                                        <div class="row">

                                                                <div class="col-md-4">
                                                                        
                                                                        <div class="form-group">
                                                                        <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>Photo of the property.</div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="propertyImage">Property Photo</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                    </OverlayTrigger>
                                                                          <img id="propertyImage" name="propertyImage" src={this.state.imagePreviewURL} style={{height:'200px',width:'300px',display:'none',paddingTop:'5px',paddingBottom:'15px'}} />
                                            
                                                                          <input id="pictureInput" type="file" onChange={e => this.HandleSelectedImage(e, this)}/>
                                                                        </div>
                                                                </div>
                                                          </div>

                                                          {/* ----- LINK ----*/}
                                                          <div class="row">

                                                          <div class="col-md-12">
                                                              <div class="form-group">
                                                              <OverlayTrigger
                                                                        placement="auto"
                                                                        delay={{ show: 150, hide: 100 }} 
                                                                        trigger={["hover","click"]}
                                                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                      
                                                                          <div className='helpText'>An external website link with the property details.<br></br>i.e. https://www.zillow.com/homedetails/123_main_St/</div>
                                                                      </Tooltip>}
                                                                      >
                                                                      <div>
                                                                              <label class="dialog-form-label" for="propertyLink">Property Link</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                      </div>
                                                                  </OverlayTrigger>
                                                                  
                                                                  <table cellPadding={0} cellSpacing={0} style={{width:'100%'}}>
                                                                    <tr>
                                                                      <td>
                                                                        <input type="text" class="form-select alert-edit-modal-component" id="propertyLink" placeholder="i.e. https://zillow.com/homedetails/123_main_st" name="propertyLink" defaultValue={this.state.propertyLink} onChange={this.handlePropertyLinkInputChange}/>
                                                                      </td>
                                                                      <td>
                                                                          <a href={this.state.propertyLink} target="_blank" id="propertyDetailLink" style={{visibility:'hidden'}}>
                                                                            <Link45deg className="ml-2 orangeInfo" style={{fontSize:'25px'}}/>
                                                                          </a>
                                                                        
                                                                        </td>
                                                                    </tr>
                                                                  </table>
                                                              </div>
                                                          </div>
                                                          </div> {/* ---- END ROW ----- */}

                                                          {/* ----- NOTES ----*/}
                                                          <div class="row">

                                                          <div class="col-md-12">
                                                                  
                                                                  <div class="form-group">
                                                                  <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                    <div className='helpText'>Any additional notes you desire to add.</div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="propertyNickname">Notes</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                            </OverlayTrigger>
                                                            
                                                            <textarea id="propertyNotes" name="propertyNotes" class="form-select alert-edit-modal-component"  rows="10" cols="100" onChange={this.handleTextAreaInputChange}>
                                                                {this.state.propertyNotes}
                                                            </textarea>


                                                                  </div>
                                                          </div>
                                                          </div>







                                                        </div>

                                                      </div>
                                                    </div>  {/* ----- End Row ----*/}

                                                </form>
                                            </div>
                                        </div>
                                    </div>


                        
                                </div>
                    </div> 

        
                    <AnalysisDialog ref={this.analysisDialogRef}></AnalysisDialog>                                                                                          

                    <Dialog ref={(component) => { this.dialog = component }}/>

                    <div class="modal-footer">
                                            <div class="container">
                                            <center>
                                                <div class="row">
                                                    <div class="col-md-4">&nbsp;</div>
                                                    <div class="col-md-2">
                                                      <button id="SaveButton" disabled={this.state.uiSaving} type="button" class="btn btn-primary btn-block footer-buttons" style={{marginTop:"5px"}} onClick={e => this.HandleSave(e, this)} >Save</button>
                                                    </div>
                                                    <div class="col-md-2">
                                                      <button id="AnalyzeButton" type="button" class="btn btn-primary btn-block footer-buttons" style={{marginTop:"5px"}} onClick={e => this.AnalyzeProperty(e, this)} >Analyze</button>
                                                    </div>
                                                    <div class="col-md-4">&nbsp;</div>
                                                  </div>
                                              </center>

                                            </div>




                    </div>

            </div>
                                                    

                {/* Lookup Modal begin */}

                <Modal
                  show={this.state.showPropertyLookup} 
                  container={this.mainModalInfoDialogRef}
                  onHide={e => this.HandleClosePropertyLookup(e, this)}
                  backdrop="static" 
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Property Lookup <span id="progressTimer" style={{paddingLeft:'26px',fontSize:'18px',color:'#4e73df',display:'none'}}><span id="seconds" style={{fontSize:"20px",fontWeight:"bold"}}>{this.state.lookUpCounter}</span> Seconds...</span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{padding:'10px'}}>

                      <span style={{color:"#4e73df"}}><ChatRightTextFill/> &nbsp; The Lookup can take up to <b>60 seconds</b> to complete. You can look up the property by address, MLSID or property URL.</span>
                      <br></br><br></br>
                      {/* Tabs: https://www.npmjs.com/package/react-tabs */}
                      <Tabs onSelect={index => this.setState({ selectedLookupTabIndex: index })}>
                        <TabList>
                          <Tab selectedClassName='selected-tab'>By Address</Tab>
                          <Tab selectedClassName='selected-tab'>By MLSID</Tab>
                          <Tab selectedClassName='selected-tab'>By URL</Tab>
                        </TabList>

                        <TabPanel>

                          <div class="row">

                                <div class="col-md-11">
                                <div class="form-group">
                                    <div>
                                          <label class="dialog-form-label" for="location">Address Line</label>
                                    </div>

                                    {/* class=alert-edit-modal-component */}
                                    <input type="text" class="form-select lookup-input" id="lookupAddress" placeholder="i.e. 123 Main St" name="lookupAddress" defaultValue=""/>
                                            
                                </div>
                                </div>
                          </div>    {/* ----- End Row ----*/}

                          <div class="row">

                              <div class="col-md-5">
                                  <div class="form-group">
                                      
                                      <label class="dialog-form-label" for="city">City</label> 
                                      <input type="text" class="form-select lookup-input" id="lookupCity" placeholder="i.e.Anytown" name="lookupCity" defaultValue=""/>
                                      
                                  </div>
                              </div>

                              
                              
                                  {/* State */} 
                                  <div class="col-md-2">
                                      <div class="form-group">
                                          
                                          <label class="dialog-form-label" for="lookupStateDropdownSelect">State</label>
                                          <DropdownButton 
                                            id="lookupStateDropdownSelect" 
                                            name="lookupStateDropdownSelect" 
                                            className="lookupDropdownSelectWrapper"  
                                            style={{ maxHeight: "28px" }}
                                            title={this.state.lookupState} onSelect={(e) => this.HandleSelect(e)}>
                                                                                                                            
                                              
  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'AL']}>AL</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'AK']}>AK</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'AZ']}>AZ</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'AR']}>AR</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'CA']}>CA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'CO']}>CO</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'CT']}>CT</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'DE']}>DE</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'FL']}>FL</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'GA']}>GA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'HI']}>HI</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'ID']}>ID</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'IL']}>IL</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'IN']}>IN</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'IA']}>IA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'KS']}>KS</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'KY']}>KY</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'LA']}>LA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'ME']}>ME</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'MD']}>MD</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'MA']}>MA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'MI']}>MI</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'MN']}>MN</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'MS']}>MS</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'MO']}>MO</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'MT']}>MT</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'NE']}>NE</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'NV']}>NV</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'NH']}>NH</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'NJ']}>NJ</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'NM']}>NM</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'NY']}>NY</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'NC']}>NC</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'ND']}>ND</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'OH']}>OH</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'OK']}>OK</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'OR']}>OR</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'PA']}>PA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'RI']}>RI</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'SC']}>SC</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'SD']}>SD</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'TN']}>TN</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'TX']}>TX</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'UT']}>UT</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'VT']}>VT</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'VA']}>VA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'WA']}>WA</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'WV']}>WV</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'WI']}>WI</Dropdown.Item>
                                                  <div style={{paddingBottom:"8px"}}></div>
                                                  <Dropdown.Item className="lookupdropdownitem"  eventKey={['lookupState', 'WY']}>WY</Dropdown.Item>
                                            

                                          </DropdownButton>
                                          
                                      </div>
                                  </div>


                              <div class="col-md-4">
                                  <div class="form-group">
                                      
                                      <label class="dialog-form-label" for="lookupZipcode">Zip Code</label>
                                      <input type="text" class="form-select lookup-input" id="lookupZipcode" maxlength="5" placeholder="i.e. 99999" name="lookupZipcode" defaultValue=""/>
                                      
                                  </div>
                              </div>

                              </div>    {/* ----- End Row ----*/}
  

                        </TabPanel>
                        <TabPanel>
                          
                          <div class="row">

                                <div class="col-md-5">
                                <div class="form-group">
                                    <div>
                                          <label class="dialog-form-label" for="location">MLSID#</label>
                                    </div>

                                    {/* class=alert-edit-modal-component */}
                                    <input type="text" class="form-select lookup-input" id="lookupMLSID" placeholder="i.e. 1234567" name="lookupMLSID" defaultValue=""/>
                                            
                                </div>
                                </div>
                          </div>    {/* ----- End Row ----*/}

                        </TabPanel>
                        <TabPanel>
                          
                        <div class="row">

                          <div class="col-md-12">
                            <div class="form-group">
                                <div>
                                      <label class="dialog-form-label" for="location">URL</label>
                                </div>

                                {/* class=alert-edit-modal-component */}
                                <input type="text" class="form-select lookup-input"  id="lookupURL" placeholder="i.e. https://www.zillow.com/homedetails/123-main-st/123_zpid/" name="lookupURL" defaultValue=""/>
                                        
                            </div>
                          </div>
                        </div>    {/* ----- End Row ----*/}

                        </TabPanel>
                      </Tabs>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={e => this.HandlePropertyLookupButton(e, this)}>LookUp</Button>

                    <Button variant="secondary" onClick={e => this.HandleClosePropertyLookup(e, this)}>
                      Close
                    </Button>

                    {this.state.loading && 
                      <div class="floatAboveEverything">
                        <br></br><br></br>
                        <CircularProgress size={150}/>
                      </div>
                    }
                    
                  </Modal.Footer>
                </Modal>
              {/*  Lookup Modal end */}

                  
              {/* Analyze Property Prompt Modal begin */}

              <Modal
                  show={this.state.showAnalyzePropertyPrompt} 
                  container={this.mainModalInfoDialogRef}
                  onHide={e => this.HandleCloseAnalyzePropertyPrompt(e, this)}
                  backdrop="static" 
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Analyze Property</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{padding:'20px'}}>
        
                    All the necessary fields to analyze this property have been<br></br>
                    entered.

                    <br></br><br></br>
                    <span style={{textAlign:'center',fontSize:'17px'}}>Do you want to analyze this property now?</span>
                    <br></br><br></br>

                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={e => this.HandleAnalyzePropertyPrompt(e, this)}>Yes, Analyze It Now</Button>

                    <Button variant="secondary" onClick={e => this.HandleCloseAnalyzePropertyPrompt(e, this)}>
                      No, I'll Analyze It Later
                    </Button>

                    {this.state.loading && 
                      <div class="floatAboveEverything">
                        <br></br><br></br>
                        <CircularProgress size={150}/>
                      </div>
                    }
                    
                  </Modal.Footer>
                </Modal>
              {/*  Analyze Property Prompt Modal end */}


              
                                {/* Validation Message Modal begin */}

                                <Modal
                                    show={this.state.showValidationMessage} 
                                    container={this.alertEditoDialogRef}
                                    onHide={e => this.HandleCloseValidationMessage(e, this)}
                                    backdrop="static" 
                                    keyboard={false}
                                  >
                                          <Modal.Header closeButton>
                                            <Modal.Title>Missing Required Fields</Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body style={{padding:'10px'}}>

                                            {this.state.validationErrors.map((validationMessage,i) => (
                                              
                                              <div>
                                                <br></br>
                                                <b>{validationMessage.key} - </b><br></br>

                                                {validationMessage.errors.map((error,i) => (
                                                  <div>
                                                    <XCircleFill style={{color:"red"}}/> {error}<br></br>
                                                  </div>
                                                ))}

                                              </div>
                                            ))}


                                          </Modal.Body>
                                          
                                          <Modal.Footer>
                                            <Button variant="secondary" onClick={e => this.HandleCloseValidationMessage(e, this)}>
                                              Close
                                            </Button>

                                            
                                          </Modal.Footer>
                                  </Modal>

                                {/*  Validation Message Modal end */}
    

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>

                  {this.state.uiSaving && 
                      <main className={classes.content}>
                          <div className={classes.toolbar} />
                          {this.state.uiSaving && <CircularProgress size={150} className={classes.uiProgess} />}
                      </main>
                    }

          </Modal>

          

        );
      
    }
}

export default (withStyles(styles)(PropertyEditDialog));

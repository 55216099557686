import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }


  FormatMoneyWithDecimal(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);


    return formattedValue;
  }


  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>net profit</b> tells you exactly how much money you will make from this real estate investment. 
                                        <br></br><br></br>
                                        To calculate the net profit, add up all the expenses, costs, and other out-of-pocket expenses and deduct the total from the expected gross profit. 
                                        <br></br><br></br>
                                        The gross profit is the expected selling price of the property minus the loan repayment amount (if financed).
                                        </div>
                                        
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The higher your profit, the better.
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>net profit</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                    
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    {this.state.indicator.property.financingType == "Loan" &&
                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Down Payment        
                                                    </div>
                                                    }

                                                    {this.state.indicator.property.financingType == "Cash" &&
                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Purchase Price        
                                                    </div>
                                                    }
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    {this.state.indicator.property.financingType == "Loan" &&
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_DOWN_PAYMENT}
                                                    </div>
                                                    }

                                                    {this.state.indicator.property.financingType == "Cash" &&
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}
                                                    </div>
                                                    }

                                                </div>

                                                
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Closing Costs         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_SALE_CLOSING_COSTS}
                                                    </div>

                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Rehab Costs         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_REPAIRS}
                                                    </div>

                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Holding Costs         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_HOLDING_COSTS}
                                                    </div>

                                                </div>
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Selling Costs         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_SELLING_COSTS}
                                                    </div>

                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        After Repair Value
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_AVR}
                                                    </div>

                                                </div>
                                                {this.state.indicator.property.financingType == "Loan" &&           
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Loan Amount
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}
                                                    </div>

                                                </div>
                                                }

                                                {this.state.indicator.property.financingType == "Loan" &&       
                                                <div class="row">

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Monthly Mortgage Payment
                                                        {this.state.indicator.property.principalAndInterest == 0 && 
                                                        this.state.indicator.property.propertyTaxes == 0 && 
                                                        this.state.indicator.property.homeownersInsurance == 0 && 
                                                        this.state.indicator.property.hoa == 0 &&        

                                                            <div style={{fontSize:"11px",fontWeight:"plain"}}>Includes Principal & Interest, Taxes, Insurance and HOA.</div>
                                                        }

                                                        {(this.state.indicator.property.principalAndInterest > 0 || 
                                                        this.state.indicator.property.propertyTaxes > 0 ||
                                                        this.state.indicator.property.homeownersInsurance > 0 ||
                                                        this.state.indicator.property.hoa > 0) &&        

                                                            <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                                <table>
                                                                    {this.state.indicator.property.principalAndInterest > 0 && 
                                                                        <tr>
                                                                            <td>Principal & Interest</td>
                                                                            <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                            <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.principalAndInterest)}</td>
                                                                        </tr>
                                                                    }

                                                                    {this.state.indicator.property.propertyTaxes > 0 && 
                                                                        <tr>
                                                                            <td>Property Taxes</td>
                                                                            <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                            <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.propertyTaxes)}</td>
                                                                        </tr>
                                                                    }
                                                                    {this.state.indicator.property.homeownersInsurance > 0 && 
                                                                        <tr>
                                                                            <td>Homeowners Insurance</td>
                                                                            <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                            <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.homeownersInsurance)}</td>
                                                                        </tr>
                                                                    }
                                                                    {this.state.indicator.property.hoa > 0 && 
                                                                        <tr>
                                                                            <td>HOA</td>
                                                                            <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                            <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.hoa)}</td>
                                                                        </tr>
                                                                    }

                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_MORTGAGE}</b></td>
                                                                        </tr>

                                                                </table>

                                                                
                                                            </div>
                                                        }

                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_MONTHLY_MORTGAGE}
                                                    </div>

                                                </div>
                                                }

                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                   
                                        {/* ---------------------  STEP 1 ------------------ */}
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Total Holding Costs</b>
                                            </div>
                                        </div>
                                        

                                       <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'9%'}}>
                                            <div class="row">

                                                        Multiply the monthly holding costs by the number of <br></br>
                                                        months you will be holding the property.
                                                        <br></br><br></br>
                                                        <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                            Monthly Holding Costs<span style={{float:'right'}}>=></span>
                                                        </div>

                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                           
                                                        </div>
                                                        <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_HOLDING_COSTS}</b>
                                                        </div>
                                            </div>
                                            
                                            <div class="row">
                                                        <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                        Holding Months <span style={{float:'right'}}>=></span>
                                                        </div>
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            x
                                                        </div>
                                                        <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_HOLDING_MONTHS}</b>
                                                        </div>
                                            </div>
                                            

                                            <div class="row">
                                                        <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-5" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'90px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_HOLDING_COSTS}</b>
                                                        </div>
                                            </div>
                                   
                                        </div>

                                        <br></br>                
                                        
                                         {/* ---------------------  STEP 2 ------------------ */}
                                         {this.state.indicator.property.financingType == "Loan" &&             
                                            <span>               
                                         <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 2:</span> <b>Calculate Total Mortgage Payments</b>
                                           </div>
                                        </div>
                                       

                                      <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'9%'}}>
                                           <div class="row">
                                                        Multiply the monthly mortgage payment by the number of <br></br>
                                                        months you will be holding the property.
                                                        <br></br><br></br>


                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           Monthly Mortgage<span style={{float:'right'}}>=></span>
                                                       </div>

                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                          
                                                       </div>
                                                       <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_MORTGAGE}</b>
                                                       </div>
                                           </div>
                                           
                                           <div class="row">
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                       Holding Months <span style={{float:'right'}}>=></span>
                                                       </div>
                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                           x
                                                       </div>
                                                       <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_HOLDING_MONTHS}</b>
                                                       </div>
                                           </div>
                                           

                                           <div class="row">
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-5" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_TOTAL_MORTGAGE_PAYMENTS}</b>
                                                       </div>
                                           </div>
                                  
                                       </div>

                
                                       <br></br>
                                       </span>
                                        }
                                       
                                        {/* ---------------------  STEP 3 ------------------ */}
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 3' : 'STEP 2'}:</span> <b>Calculate Total Out of Pocket Investment</b>
                                            </div>
                                        </div>
                                        

                                       <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'9%'}}>


                                            <div class="row">
   
                                                {this.state.indicator.property.financingType == "Loan" &&    
                                                <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                    Down Payment<span style={{float:'right'}}>=></span>
                                                </div>
                                                }

                                                {this.state.indicator.property.financingType == "Cash" &&    
                                                <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                    Purchase Price<span style={{float:'right'}}>=></span>
                                                </div>
                                                }

                                                <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                    +
                                                </div>
                                          
                                                {this.state.indicator.property.financingType == "Loan" &&    
                                                <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                    <b>{this.state.indicator.helpVariableMap.VAR_DOWN_PAYMENT}</b>
                                                </div>
                                                }
                                                {this.state.indicator.property.financingType == "Cash" &&    
                                                <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                    <b>{this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}</b>
                                                </div>
                                                }
                                            </div>    

                                            
                                            <div class="row">
   
                                                <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                    Closing Costs<span style={{float:'right'}}>=></span>
                                                </div>

                                                <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                    +
                                                </div>
                                                <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                    <b>{this.state.indicator.helpVariableMap.VAR_SALE_CLOSING_COSTS}</b>
                                                </div>
                                            </div>

                                            {this.state.indicator.property.financingType == "Loan" &&           
                                            <div class="row">
   
                                                <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                    Mortgage Payments<span style={{float:'right'}}>=></span>
                                                </div>

                                                <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                    +
                                                </div>
                                                <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                    <b>{this.state.indicator.helpVariableMap.VAR_TOTAL_MORTGAGE_PAYMENTS}</b>
                                                </div>
                                            </div>
                                            }

                                            <div class="row">
                                            
                                                <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                    Rehab Costs<span style={{float:'right'}}>=></span>
                                                </div>

                                                <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                    +
                                                </div>
                                                <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                    <b>{this.state.indicator.helpVariableMap.VAR_REPAIRS}</b>
                                                </div>
                                            </div>


                                            <div class="row">
                                                        <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                        Holding Costs <span style={{float:'right'}}>=></span>
                                                        </div>
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            +
                                                        </div>
                                                        <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_HOLDING_COSTS}</b>
                                                        </div>
                                            </div>
                                            

                                            <div class="row">
                                                        <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                        Selling Costs <span style={{float:'right'}}>=></span>
                                                        </div>
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            +
                                                        </div>
                                                        <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_SELLING_COSTS}</b>
                                                        </div>
                                            </div>
                                            
                                            <div class="row">
                                                        <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-5" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'90px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_TOTAL_INVESTMENT}</b>
                                                        </div>
                                            </div>
                                   
                                        </div>
                                        
                                        <br></br>

                                        {/* ---------------------  STEP 4 ------------------ */}

                                        {this.state.indicator.property.financingType == "Loan" && 
                                       <div>   
                                        <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 4' : 'STEP 3'}:</span> <b>Calculate Loan Repayment</b>
                                           </div>
                                       </div>
                                       

                                      <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'9%'}}>
                                           <div class="row">
                                 
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           Loan Amount<span style={{float:'right'}}>=></span>
                                                       </div>

                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                          
                                                       </div>
                                                       <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}</b>
                                                       </div>
                                           </div>
                                           
                            
                                           {this.state.indicator.helpVariableMap.VAR_MORTGAG_BALANCE_SCHEDULE.payments?.map((payment,i) => (	                    

                                            <div class="row">
                                                        <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                        Month {i+1} Principal Pmt <span style={{float:'right'}}>=></span>
                                                        </div>
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            -
                                                        </div>
                                                        <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                            <b>{this.FormatMoneyWithDecimal(payment.principal)}</b>
                                                        </div>
                                            </div>

                                            ))}


                                           <div class="row">
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-5" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_LOAN_REPAYMENT}</b>
                                                       </div>

                                                  
                                           </div>

                                  
                                       </div>

                
                                       <br></br>
                                       </div>
                                       }

                                         {/* ---------------------  STEP 5 ------------------ */}
                    
                                         <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 5' : 'STEP 3'}:</span> <b>Calculate Profits</b>
                                           </div>
                                       </div>
                                       

                                      <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'9%'}}>
                                           <div class="row">
                                                        Subtract the total out of pocket investment and the <br></br>
                                                        loan repayment (if financed) from the After Repair Value.

                                                        <br></br><br></br>


                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           After Repair Value<span style={{float:'right'}}>=></span>
                                                       </div>

                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                          
                                                       </div>
                                                       <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_AVR}</b>
                                                       </div>
                                           </div>
                                           
                                           <div class="row">
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                       Total Investment <span style={{float:'right'}}>=></span>
                                                       </div>
                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                           -
                                                       </div>
                                                       <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_TOTAL_INVESTMENT}</b>
                                                       </div>
                                           </div>

                                           {this.state.indicator.property.financingType == "Loan" &&  
                                           <div class="row">
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                       Loan Repayment <span style={{float:'right'}}>=></span>
                                                       </div>
                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                           -
                                                       </div>
                                                       <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_LOAN_REPAYMENT}</b>
                                                       </div>
                                           </div>
                                            }
                                           

                                           <div class="row">
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-5" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.VAR_PROFITS}</b>
                                                       </div>

                                                  
                                           </div>

                                    
                                       </div>

                
                                       <br></br>
                                       
                                  
                                        {/* ---------------------  STEP 6 ------------------ */}


                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 6' : 'STEP 4'}:</span> <b>Results</b>
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                    Net Profit = 
                                                    </div>
                                                    <div class="col-md-4 indicator-info-formula-table-col1">
                                                           <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                    </div>
                                                </div>
                                            </div>
                                  </div>
                                   

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Net Profit benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                {this.state.indicator.property.financingType == "Loan" &&   
                                                <span>
                                                MONTHLY MORTGAGE PAYMENT<br></br>
                                                DOWN PAYMENT<br></br>
                                                </span>
                                                }
                                                CLOSING COSTS<br></br>
                                                REHAB COSTS<br></br>
                                                HOLDING COSTS<br></br>
                                                SELLING COSTS<br></br>
                                                AFTER REPAIR VALUE<br></br>
                                                {this.state.indicator.property.financingType == "Loan" &&   
                                                <span>
                                                LOAN AMOUNT
                                                </span>
                                                }
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                  {/* TIP */}
                  {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>2)</b> Lower your <span style={{fontWeight:'600'}}>closing costs</span><br></br>
                            <b>3)</b> Lower your <span style={{fontWeight:'600'}}>rehab costs</span><br></br>
                            <b>4)</b> Lower your <span style={{fontWeight:'600'}}>holding costs</span><br></br>
                            <b>5)</b> Lower your <span style={{fontWeight:'600'}}>selling costs</span><br></br>     
                            {this.state.indicator.property.financingType  == "Loan" && 
                            <span>
                            <b>6)</b> Put down a larger <span style={{fontWeight:'600'}}>down payment</span><br></br>
                            </span>
                            }
                        </div>

                    </div>        
                }            
                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

import Dashboard from '../components/Dashboard';
import Properties from '../components/Properties'; 

import * as BLO from '../utilities/BLO';

import { FileEarmarkText } from 'react-bootstrap-icons';
import { Trash } from 'react-bootstrap-icons';
import { BarChartLine } from 'react-bootstrap-icons';
import { Link45deg } from 'react-bootstrap-icons';

import {Button,OverlayTrigger,Tooltip} from "react-bootstrap";
import { InfoCircleFill } from 'react-bootstrap-icons';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { convertPropertyDate, convertShortDateString } from '../utilities/AppUtility';

import { calculateIndicators } from '../utilities/IndicatorUtility';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
});

class PropertyView extends Component {

    state = {
        properties:[],
        filteredProperties:[],
        uiLoading: true,
        viewMode:'dashboard',
        filter:'',
        viewFlag: false,
        propertyCounts: {
            selectedInvestmentPropertyCount:0,
            primaryHomePropertyCount:0,
            rentalPropertyCount:0,
            flippingPropertyCount:0
        }          
    };

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps = (nextProps) => {

        //if (nextProps.viewMode != null && this.props.viewMode != null && this.props.viewMode !== nextProps.viewMode)
        if (nextProps.viewMode != null && this.props.viewMode !== nextProps.viewMode) 
       {

            let viewFlag = false;

            if (nextProps.viewMode === "dashboard")
            {
                viewFlag = false;

                localStorage.setItem('LastViewMode','dashboard');

            } else if (nextProps.viewMode === "properties")
            {
                viewFlag = true;

                localStorage.setItem('LastViewMode','properties');
            }

            this.setState({
                viewMode: nextProps.viewMode,
                viewFlag:viewFlag
            });
       }

       //if (nextProps.investmentType != null && this.props.investmentType != null && this.props.investmentType !== nextProps.investmentType)
       if (nextProps.investmentType != null  && this.props.investmentType !== nextProps.investmentType)
       {
            this.setPropertyCounts(this.state.filteredProperties,nextProps.investmentType,this.props.profileId);
       }

       //if (nextProps.profileId != null && this.props.profileId != null && this.props.profileId !== nextProps.profileId)
       if (nextProps.profileId != null  && this.props.profileId !== nextProps.profileId)
       {
            this.setPropertyCounts(this.state.filteredProperties,this.props.investmentType,nextProps.profileId);
       }


     }; 

    componentWillMount = () => {

        let viewFlag = false;

        if (this.props.viewMode === "dashboard")
        {
            viewFlag = false;

            localStorage.setItem('LastViewMode','dashboard');

        } else if (this.props.viewMode === "properties")
        {
            viewFlag = true;

            localStorage.setItem('LastViewMode','properties');
        }

        this.setState({
            viewMode: this.props.viewMode,
            viewFlag:viewFlag,
            filter:''
        });

        this.loadProperties();
    };

    loadSettings()
    {
        this.props.parentRef.loadSettings();
    }

    loadProperties()
    {

        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
        axios
			.get('/api/properties')
			.then((response) => {

                if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

                // console.log("PROP-Before refresh token:")
                // console.log("---------------------")
                // console.log(refreshToken);
                // console.log("")
                // console.log("PROP-Return Refresh Token:")
                // console.log("---------------------")
                // console.log(response.headers["refreshtoken"]);

                
                let properties = response.data;

                console.log("SETTINGS:");
                console.log(JSON.stringify(this.props.settings));


                properties = this.recalculatePropertyIndicators(properties,this.props.profile,this.props.settings); 

				this.setState({
					properties: properties,
                    filteredProperties: properties,
                    uiLoading: false
				});

                this.props.parentRef.setState({
					properties: properties
				});

                this.setPropertyCounts(properties,this.props.investmentType,this.props.profileId);

			})
			.catch((error) => {
				console.log(error);
                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
					//window.location.reload();
                    window.location.href = '/login';
				} else
                {
                    this.setState({
                        uiLoading: false
                    });
                }
			});

    }

    recalculatePropertyIndicators(properties,profile,settings)
    {
        /*
            We just want to make sure we get the most accurate indicators, so we will recalculate the indicators when loaded from the database.

            Note: This step is not necessary and skipping will not have impact on the application, but it does garantee the most up date results
        */

            try
            {

                const benchmarkSettings = BLO.getIndicatorBenchmarkSettings(settings);
                settings.benchmarkSettings = benchmarkSettings;

                for (let property of properties)
                {

                    try
                    {
                        //1. First let me sure we recalculate price per sqft if it is not populated

                        if (property.trendingPricePerSqft == null || property.trendingPricePerSqft ==0)
                        { 
                            if (property.similarSoldProperties != null)
                            {
                                const pricePerSqft = this.CalculatePropertyPricePerSqftAverage(property.similarSoldProperties);
                                property.trendingPricePerSqft = pricePerSqft;
                            }
                        }
                        
                        //2. recalculate indicators
                        const indicators = calculateIndicators(property,profile,settings);

                        //Delete property from indicator object to eliminate JSON circular reference error
                        for (let indicator of indicators)
                        {
                            delete indicator.property;
                        }
                        
                            property.indicators = indicators;
                     
                    }catch(error)
                    {
                        console.log(error);
                    }
                }

            }catch(error)
            {
                console.log(error);
            }

            return properties;
    }

    CalculatePropertyPricePerSqftAverage(similarProperties)
    {
      
      let pricePerSqftAverage = 0;

      try
      {
  
    //    alert(JSON.stringify(similarProperties));
        
        let total = 0;
        for (let property of similarProperties)
        {
  
          let pricePerSqft = this.ConvertToNumber(property.price) / this.ConvertToNumber(property.sqFt);

          pricePerSqft = parseInt(pricePerSqft);
  
          total = total + pricePerSqft;
        }
     
        pricePerSqftAverage = total/similarProperties.length;
  
        pricePerSqftAverage = parseInt(pricePerSqftAverage);
  
     
      }catch(error)
      {
         console.log(error);
      }
  
  
      return pricePerSqftAverage;
    }

    ConvertToNumber(value)
  {
      value = value.toString();
      let valueNumber = 0;

      try
      {
          let valueString = value.toString();
          valueString = value.toString().replace(",","").replace("$","").replace("+","").replace("-","").replace("/mo","");

          if (valueString.endsWith("+"))
          {
              valueString = valueString.slice(0, -1);
          }

          valueNumber = parseInt(valueString);
      } catch(error)
      {
          console.log(error);
          valueNumber = value;
      }

      return valueNumber;
  }

    formatInvestmentTypeMessage = (count,investmentType) => {
            
        let invesmentTypeFormatted = investmentType.toLowerCase();

        if (count > 1)
        {
            if (invesmentTypeFormatted.endsWith("property"))
            {
                invesmentTypeFormatted = invesmentTypeFormatted.replace("property","properties");
            } else 
            {
                invesmentTypeFormatted = invesmentTypeFormatted + "s";
            }
        }

        return invesmentTypeFormatted;
    }

    formatInvestmentTypeMessageUpper = (count,investmentType) => {

        let invesmentTypeFormatted = this.formatInvestmentTypeMessage(count,investmentType);

        invesmentTypeFormatted = invesmentTypeFormatted.toUpperCase();

        return invesmentTypeFormatted;
    }

    setPropertyCounts = (properties,investmentType,profileId) => {

        if (properties != null && investmentType != null && profileId != null)
        {
            const selectedInvestmentPropertyCount = properties.filter(property => property.investmentType == investmentType && property.profileId == profileId).length;
            const primaryHomePropertyCount = properties.filter(property => property.investmentType == 'Primary Home' && property.profileId == profileId).length;
            const rentalPropertyCount = properties.filter(property => property.investmentType == 'Rental Property' && property.profileId == profileId).length;
            const flippingPropertyCount = properties.filter(property => property.investmentType == 'Flipping Property' && property.profileId == profileId).length;
            
            //alert(selectedInvestmentPropertyCount);

            this.setState({
                propertyCounts: {
                    selectedInvestmentPropertyCount:selectedInvestmentPropertyCount,
                    primaryHomePropertyCount:primaryHomePropertyCount,
                    rentalPropertyCount:rentalPropertyCount,
                    flippingPropertyCount:flippingPropertyCount
                } 

            }, () => {
                this.props.parentRef.setState({
                    propertyCounts: this.state.propertyCounts
                });
            });
        }
    }

    switchChange = (event) => {
    
        if (event == false)
        {
            //alert("INDICATOR VIEW");
            this.props.parentRef.setState({
                currentPage:"dashboard"
            });

            localStorage.setItem('CurrentPage','dashboard');
            

        } else if (event == true)
        {
           // alert("PROPERTIES VIEW");
            this.props.parentRef.setState({
                currentPage:"properties"
            });

            localStorage.setItem('CurrentPage','properties');
        }

    }

    isFilterDate = (filterValue) => {
        
        let isDateFlag = false;

        try 
        {
            const count = filterValue.split("/").length - 1;
            if (count == 2) 
            {
                isDateFlag = true;
            }
        } catch(error)
        {

        }

        return isDateFlag;
    }

    filterInputChanged = (filterValue) => {
   
        this.setState({
          filter: filterValue
        });

        if (filterValue != null && filterValue !== '')
        { 
           let value = "";
           let filteredProperties = this.state.properties;

           if (this.isFilterDate(filterValue))
           {
                value = convertShortDateString(filterValue); 
                filteredProperties =  this.state.properties?.filter(property => convertPropertyDate(property.createdDate) === value);
           } else 
           {
                value = filterValue.toLowerCase(); 
                value = value.replace("$","").replace(",","").replace(",","").trim();
                filteredProperties =  this.state.properties?.filter(property => JSON.stringify(property).toLocaleLowerCase().includes(value));
           }

           this.setState({
            filteredProperties: filteredProperties
           });

           this.setPropertyCounts(filteredProperties,this.props.investmentType,this.props.profileId);

        }else
        {
            this.setState({
                filteredProperties: this.state.properties
            });
        }

    };
  
    render() {

        const { classes } = this.props;

        if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
            return (
                <div>
                  
                    <div class="row">
                        <div class="col-md-10">
                            <h1 class="dashboard-heading" style={{paddingBottom:'2px',marginBottom:'2px'}}>
                                Properties 
                         
                            <span style={{paddingLeft:'10px',fontSize:'18px',fontWeight:'plain',color:'#949494'}}>
                            <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 150, hide: 100 }} 
                                            trigger={["hover","click"]}
                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltipWhite">
                                            
                                                <div className='helpText'>
                                                    {this.state?.propertyCounts?.selectedInvestmentPropertyCount} {this.formatInvestmentTypeMessage(this.state?.propertyCounts?.selectedInvestmentPropertyCount,this.props?.investmentType)}
                                                </div>
                                            
                                            </Tooltip>}
                                            >
                                                <span>
                                ({this.state?.propertyCounts?.selectedInvestmentPropertyCount})
                                </span>

                                </OverlayTrigger>
                            </span>

                            {this.props?.profile?.type === 'demo' && 
                            <span class="demo-label">TRIAL VERSION</span>
                            }

                            <span class="desktop-only-block" style={{float:'right', fontSize:'14px', paddingRight:'70px',paddingTop:'18px'}}> {this.formatInvestmentTypeMessageUpper(this.state?.propertyCounts?.selectedInvestmentPropertyCount,this.props?.investmentType)}</span>

                            <span class="mobile-only-block" style={{float:'right', fontSize:'11px', paddingRight:'0px',paddingTop:'18px'}}> {this.formatInvestmentTypeMessageUpper(this.state?.propertyCounts?.selectedInvestmentPropertyCount,this.props?.investmentType)}</span>

                            {/*
                            <span class="mobile-only-block infoHelpbutton"> 
                            <button type="button" class="infoHelpbutton" data-toggle="modal" data-target="#helpModal">
                                        <InfoCircleFill className="ml-2 orangeInfo" size={25} /> 
                                </button>
                            </span>
                            */}

                            </h1>
                            <div class="page-short-description view-mode-padding">
                            List of all properties that have been found by Advanced Searches or added by you manually for the selected profile and investment type.
                            </div>
                        </div>

                        <div class="col-md-2">
                                        <div class="desktop-only-block">

                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 150, hide: 100 }} 
                                            trigger={["hover","click"]}
                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltipWhite">
                                            
                                                <div className='helpText'>Switch between Indicator View and Detail View.
                                                <br></br>
                                                Both views show the same properties, but in a different way.
                                                </div>
                                            
                                            </Tooltip>}
                                            >
                                            <div style={{paddingLeft:'25px'}}>
                                                    <label class="dialog-form-label" for="alertName" style={{fontWeight:'bold'}}>View Mode</label>
                                               
                                            </div>
                                        </OverlayTrigger>
                                       
                                        </div>
                                    
                                        
                                        <BootstrapSwitchButton  width={148} offstyle="success" onlabel='Detail View' offlabel='Indicator View' checked={this.state.viewFlag} onChange={this.switchChange}/>
                        </div>
                    </div>

                    {/* HELP BUTTON BEGINS - DESKTOP */}
                    <div id="upper-right-help" class="desktop-only-block">
                        <div>
                          {/*  <button type="button" class="infoHelpbutton" data-toggle="modal" data-target="#help=info-modal"> */}
                            <button type="button" class="infoHelpbutton" data-toggle="modal" data-target="#helpModal">
                                <div>
                                    <InfoCircleFill className="ml-2 orangeInfo" size={25} /> 
                                </div>
                            </button>
                                
                        </div>
                    </div>
                    {/* HELP BUTTON END - DESKTOP */}


                    {this.state.viewMode === "dashboard" && 
                        <Dashboard investmentType = {this.props.investmentType} profileId = {this.props.profileId} settings = {this.props.settings} profile= {this.props.profile} user= {this.props.user} properties= {this.state.filteredProperties} propertyCounts = {this.state.propertyCounts} filter = {this.state.filter} parentRef={this}/>
                    }

                    {this.state.viewMode === "properties" && 
                        <Properties investmentType = {this.props.investmentType} profileId = {this.props.profileId} settings = {this.props.settings} profile= {this.props.profile} user= {this.props.user} properties= {this.state.filteredProperties} propertyCounts = {this.state.propertyCounts} filter = {this.state.filter} parentRef={this}/>
                    }

           
                    {/* -------------------  BEGIN  HELP MODAL ------------------- */}
                    <div class="modal fade help-ifo-modal" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <div style={{fontWeight:'bold'}}>HELP - Properties</div>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" style={{fontSize:'16px'}}>
                                        <p>
                                        These are all the properties that have been found by Advanced Searches or added by you manually for the selected profile and investment type.
                                        </p>

                                        <p>
                                            <div style={{color:'#0032b6',fontWeight:'bold',textDecoration:'underline'}}>ADDING PROPERTIES</div>
                                       
                                            You can add new properties for the selected profile by clicking the <b>ADD</b> button or by creating an <b>ADVANCED SEARCH</b> from the left navigation menu.
                                        </p>
                                        <ul>
                                            <li>
                                                <p>
                                                    <b>Advanced Searches</b> allow you to specify the criteria of properties you want to search, including location, price, bedrooms, bathrooms among other things. The application will search daily for properties matching your criteria and will notify you via email when they are found.
                                                </p> 
                                                
                                                <p>
                                                    The newly found properties will be labeled as <b>NEW</b> when you login into the application. 
                                                </p>

                                            </li>

                                            <li>
                                                <p>
                                                    <b>Add Button</b> allows you to add new properties by entering all the property information.
                                                </p> 
                                                
                                                <p>
                                                You have the option to <b>LOOK UP</b> the property by address, MLS or URL to alleviate the burden of entering all the required fields manually.
                                                </p>

                                            </li>
                                        </ul>

                                        <p>
                                            <div style={{color:'#0032b6',fontWeight:'bold',textDecoration:'underline'}}>ACTION BUTTONS</div>
                                       
                                            The <b>Detail View</b> provides the following action buttons to help you manage the property:

                                            <ul style={{listStyleType: 'none'}}>
                                                    <li>
                                                        <BarChartLine className="ml-2" style={{height:"44px"}}/> - View property analysis
                                                    </li>
                                                    
                                                    <li>
                                                        <FileEarmarkText className="ml-2" style={{height:"44px"}}/> - Edit property details
                                                    </li>

                                                    <li>
                                                        <Link45deg  style={{fontSize:'25px',color:"#000000"}}/> - Open property listing page
                                                    </li>

                                                    <li>
                                                        <Trash className="ml-2"  style={{height:"44px"}}/> - Delete property
                                                    </li>

                                            </ul>
                                        </p>

                    
                     
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* -------------------  END  HELP MODAL ------------------- */}
    

                </div>
            );
        }
    }
}

export default (withStyles(styles)(PropertyView));
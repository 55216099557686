import React, { Component } from 'react';

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';


class IndicatorHeader extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
			indicator: this.props.indicator
		};
        
     }

     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }


    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }


  
    render() {

        return (

            <div>
            
                        <div class="row" style={{paddingBottom:"6px"}}>
                            <div class="col-md-12" >
                                <div style={{textAlign:"left",color:"#000000",paddingLeft:"2px"}}>
                                    
                                    <h3 class="indicator-info-property-name">{this.state.indicator?.property.nickName}</h3>
                                    <div class="indicator-info-property-address">{this.state.indicator?.property.address}, {this.state.indicator?.property.city}, {this.state.indicator?.property.state}, {this.state.indicator?.property.zipcode}</div> <br></br>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="indicator-info-indicator-name">
                                            {this.state.indicator?.indicatorName}
                                        </div>
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="indicator-info-indicator-value">
                                        {this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}

                                        </div>
                                    </div>
                                </div>   
                            </div> 
                            <div class="col-md-3 desktop-only-block">
                                {this.state.indicator?.meetsBenchmark == true && 
                                    <img src={greenTrafficLight}/>
                                }

                                {this.state.indicator?.meetsBenchmark == false && 
                                    <img src={redTrafficLight}/>
                                }

                            </div>
                        </div>

                        <div class="row mobile-only-block"  style={{textAlign:"center",minWidth:"100%"}}>
                                <div class="col-md-12 mobile-only-block"  style={{textAlign:"center",minWidth:"100%"}}>

                                {this.state.indicator?.meetsBenchmark == true && 
                                    <img src={greenTrafficLight}/>
                                }

                                {this.state.indicator?.meetsBenchmark == false && 
                                    <img src={redTrafficLight}/>
                                }

                                </div>

                        </div>

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-benchmark">
                                <br></br>
                                Current Benchmark Settings
                            </div>
                        </div>
                        <div class="row">
                           
                            <div class="col-md-11 indicator-info-indicator-settings">
                                
                                <div class="row">
                                    <div class="col-md-12" style={{borderBottom:"2px solid  #999999",paddingBottom:"2px"}}>
                                        <img src={goicon} class="indicator-info-little-icon"/>
                                        &nbsp;&nbsp;
                                        <span class="indicator-info-indicator-accept">ACCEPT =></span>
                                        <span class="indicator-info-indicator-settings-creteria">{this.state.indicator?.helpVariableMap.VAR_ACCEPT_RANGE}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <img src={stopicon} class="indicator-info-little-icon"/>
                                        &nbsp;&nbsp;
                                        <span class="indicator-info-indicator-accept">REJECT &nbsp;&nbsp;=></span>
                                        <span class="indicator-info-indicator-settings-creteria">{this.state.indicator?.helpVariableMap.VAR_REJECT_RANGE}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1">
                                &nbsp;
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-result-text"  style={{whiteSpace: 'pre-wrap'}}>
                                
                                <div dangerouslySetInnerHTML={{ __html: this.state.indicator?.helpVariableMap.VAR_RESULT_TEXT }}></div>
                            </div>
                        </div>

                           
          
          </div> 
        );
    }
}

export default (IndicatorHeader);

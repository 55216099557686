import React, { Component } from 'react';
import { Modal, Button, Dropdown, DropdownButton, OverlayTrigger,Tooltip} from "react-bootstrap";
import { InfoCircleFill } from 'react-bootstrap-icons';
import { Link45deg } from 'react-bootstrap-icons';

import { FileEarmarkText,  House } from 'react-bootstrap-icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import CurrencyInput from '../types/CurrencyInput'

import 'react-tabs/style/react-tabs.css';

import Dialog from 'react-bootstrap-dialog'

import CustomizedSlider from '../types/CustomizedSlider';

import { authMiddleWare } from '../utilities/Auth';

import { upperCaseFirstLetter } from '../utilities/AppUtility';

import axios from 'axios';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '40%',
		top: '35%'
	}
});

class ProfileEditDialog extends Component { 


  /* Actual one for application */

    state = {
        isOpen: false,
        accountId:1,
        profileName: '',
        monthlyBills:'0',
        monthlyBillsAsNumber:0,
        monthlyGrossIncome:'0',
        monthlyGrossIncomeAsNumber:0,
        creditScore:'_',
        financingPreference:'_',
        budget:'0',
        budgetAsNumber:0,

        autoInsurance:'0',
        autoInsuranceAsNumber:0,
        cable:'0',
        cableAsNumber:0,
        carMaintenance:'0',
        carMaintenanceAsNumber:0,
        carPayment:'0',
        carPaymentAsNumber:0,
        cellPhone:'0',
        cellPhoneAsNumber:0,
        charitableDonations:'0',
        charitableDonationsAsNumber:0,
        childCare:'0',
        childCareAsNumber:0,
        childSupport:'0',
        childSupportAsNumber:0,
        clothing:'0',
        clothingAsNumber:0,
        creditCard:'0',
        creditCardAsNumber:0,
        dining:'0',
        diningAsNumber:0,
        entertainment:'0',
        entertainmentAsNumber:0,
        gasoline:'0',
        gasolineAsNumber:0,
        electric:'0',
        electricAsNumber:0,
        groceries:'0',
        groceriesAsNumber:0,
        insuranceHealthMedical:'0',
        insuranceHealthMedicalAsNumber:0,
        internet:'0',
        internetAsNumber:0,
        lifeInsurance:'0',
        lifeInsuranceAsNumber:0,
        medicalDentalVision:'0',
        medicalDentalVisionAsNumber:0,
        phone:'0',
        phoneAsNumber:0,
        recreationVacation:'0',
        recreationVacationAsNumber:0,
        studentLoan:'0',
        studentLoanAsNumber:0,
        tuition:'0',
        tuitionAsNumber:0,
        water:'0',
        waterAsNumber:0,
        otherMonthlyBill:'0',
        otherMonthlyBillAsNumber:0,

        profile:null,
        loading: false,

        uiSaving: false
        
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.mainModalInfoDialogRef = React.createRef();

     }

     componentDidUpdate() 
     {

     }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    HandleMonthlyBillsBreakdownClose(event,obj){

      //This is the only way close OverlayTrigger programmaly.
      //  - Set <OverlayTrigger rootClose={true}
      //  - And onClick event trigger call document.body.click()
  
      //TODO: Add login to check if any fields have been changed to pop a message: Save Change Made or Close without Saving?
  
      document.body.click();
  
    }
  

    PopulateDialog(profile,parentReference)
    {
        
        this.ClearFields();

        if (profile != null)
        {
            this.setState({
              accountId:profile.accountId,
              profileName: profile.profileName,
              monthlyBills:this.FormatMoney(profile.monthlyBills),
              monthlyBillsAsNumber:profile.monthlyBills,
              monthlyGrossIncome:this.FormatMoney(profile.monthlyGrossIncome),
              monthlyGrossIncomeAsNumber:profile.monthlyGrossIncome,
              creditScore:profile.creditScore,
              financingPreference:upperCaseFirstLetter(profile.financingPreference),
              budget:this.FormatMoney(profile.budget),
              budgetAsNumber:profile.budget,

              otherMonthlyBill:this.FormatMoney(profile.otherMonthlyBill),	
              otherMonthlyBillAsNumber:profile.otherMonthlyBill,
              autoInsurance:this.FormatMoney(profile.autoInsurance),
              autoInsuranceAsNumber:profile.autoInsurance,
              cable:this.FormatMoney(profile.cable),
              cableAsNumber:profile.cable,
              carMaintenance:this.FormatMoney(profile.carMaintenance),
              carMaintenanceAsNumber:profile.carMaintenance,
              carPayment:this.FormatMoney(profile.carPayment),
              carPaymentAsNumber:profile.carPayment,
              cellPhone:this.FormatMoney(profile.cellPhone),
              cellPhoneAsNumber:profile.cellPhone,
              charitableDonations:this.FormatMoney(profile.charitableDonations),
              charitableDonationsAsNumber:profile.charitableDonations,
              childCare:this.FormatMoney(profile.childCare),
              childCareAsNumber:profile.childCare,
              childSupport:this.FormatMoney(profile.childSupport),
              childSupportAsNumber:profile.childSupport,
              clothing:this.FormatMoney(profile.clothing),
              clothingAsNumber:profile.clothing,
              creditCard:this.FormatMoney(profile.creditCard),
              creditCardAsNumber:profile.creditCard,
              dining:this.FormatMoney(profile.dining),
              diningAsNumber:profile.dining,
              entertainment:this.FormatMoney(profile.entertainment),
              entertainmentAsNumber:profile.entertainment,
              gasoline:this.FormatMoney(profile.gasoline),
              gasolineAsNumber:profile.gasoline,
              electric:this.FormatMoney(profile.electric),
              electricAsNumber:profile.electric,
              groceries:this.FormatMoney(profile.groceries),
              groceriesAsNumber:profile.groceries,
              insuranceHealthMedical:this.FormatMoney(profile.insuranceHealthMedical),
              insuranceHealthMedicalAsNumber:profile.insuranceHealthMedical,
              internet:this.FormatMoney(profile.internet),
              internetAsNumber:profile.internet,
              lifeInsurance:this.FormatMoney(profile.lifeInsurance),
              lifeInsuranceAsNumber:profile.lifeInsurance,
              medicalDentalVision:this.FormatMoney(profile.medicalDentalVision),
              medicalDentalVisionAsNumber:profile.medicalDentalVision,
              phone:this.FormatMoney(profile.phone),
              phoneAsNumber:profile.phone,
              recreationVacation:this.FormatMoney(profile.recreationVacation),
              recreationVacationAsNumber:profile.recreationVacation,
              studentLoan:this.FormatMoney(profile.studentLoan),
              studentLoanAsNumber:profile.studentLoan,
              tuition:this.FormatMoney(profile.tuition),
              tuitionAsNumber:profile.tuition,
              water:this.FormatMoney(profile.water),
              waterAsNumber:profile.water,

              profile:profile
            });
        } else 
        {
          this.setState({
            financingPreference:this.GetFinancingPreference(parentReference.props.user)
          });

        }


        this.setState({
          parentReference: parentReference
        });
      
 
        this.openModal();
    }

    GetFinancingPreference(user)
    {
      let financingPreference = ""

      if (user.investorType === "homebuyer" || user.investorType === "realtor")
      {
        financingPreference = "Loan";
      } else if (user.investorType === "flipper" || user.investorType === "rental")
      {
        if (user.hasOwnProperty("financingPreference")) 
        {
            financingPreference = upperCaseFirstLetter(user.financingPreference);
        } else 
        {
          financingPreference = "Loan";
        } 
      }

      return financingPreference;
    }
 
    ClearFields()
    {
      this.setState({
          accountId:1,
          profileName: '',
          monthlyBills:'0',
          monthlyBillsAsNumber:0,
          monthlyGrossIncome:'0',
          monthlyGrossIncomeAsNumber:0,
          creditScore:'_',
          financingPreference:'_',
          budget:'0',
          budgetAsNumber:0,

          otherMonthlyBill:'0',
          otherMonthlyBillAsNumber:0,
          autoInsurance:'0',
          autoInsuranceAsNumber:0,
          cable:'0',
          cableAsNumber:0,
          carMaintenance:'0',
          carMaintenanceAsNumber:0,
          carPayment:'0',
          carPaymentAsNumber:0,
          cellPhone:'0',
          cellPhoneAsNumber:0,
          charitableDonations:'0',
          charitableDonationsAsNumber:0,
          childCare:'0',
          childCareAsNumber:0,
          childSupport:'0',
          childSupportAsNumber:0,
          clothing:'0',
          clothingAsNumber:0,
          creditCard:'0',
          creditCardAsNumber:0,
          dining:'0',
          diningAsNumber:0,
          entertainment:'0',
          entertainmentAsNumber:0,
          gasoline:'0',
          gasolineAsNumber:0,
          electric:'0',
          electricAsNumber:0,
          groceries:'0',
          groceriesAsNumber:0,
          insuranceHealthMedical:'0',
          insuranceHealthMedicalAsNumber:0,
          internet:'0',
          internetAsNumber:0,
          lifeInsurance:'0',
          lifeInsuranceAsNumber:0,
          medicalDentalVision:'0',
          medicalDentalVisionAsNumber:0,
          phone:'0',
          phoneAsNumber:0,
          recreationVacation:'0',
          recreationVacationAsNumber:0,
          studentLoan:'0',
          studentLoanAsNumber:0,
          tuition:'0',
          tuitionAsNumber:0,
          water:'0',
          waterAsNumber:0,

          profile: null
        });

    }

    HandleSelect(event) {

      var elements = event.split(",");
      var key = elements[0];
      var value = elements[1];
  
      var obj  = {}
      obj[key] = value
      this.setState(obj, () => {
        // Do anything after the state has changed   
       
      });
  
  
    }
  
  FormatMoneyIfNotAlreadyFormatted(value)
  {
    if (!value.startsWith("$"))
    {
      value = this.FormatMoney(value);
    }

    return value;
  }

  FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }

  HandleCurrencyEnterFocus(event) {

    //console.log(event.target.name);

    let key = event.target.name;

    if (key.endsWith("Column"))
    {
        key = key.replace("Column","");
    }

    if (this.state[key] =="0" || this.state[key] =="$0")
    {
  
      document.getElementById(event.target.name).value = "";
      
      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandleCurrencyExitFocus(event) {
   
    let key = event.target.name;

    if (key.endsWith("Column"))
    {
        key = key.replace("Column","");
    }

    if (this.state[key] =="" || this.state[key] ==" ")
    {
  
      document.getElementById(event.target.name).value = "$0";
      
      var obj  = {}
      obj[key] = 0
      this.setState(obj);
    }

  }

  HandlePercentEnterFocus(event) {

    if (this.state[event.target.name] =="0" || this.state[event.target.name] =="0%")
    {
      const key = event.target.name;
      const keyFormatted = key+"AsNumber";
      document.getElementById(key).value = "";

      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandlePercentExitFocus(event) {
   
    if (this.state[event.target.name] =="" || this.state[event.target.name] ==" ")
    {
      const key = event.target.name;
      document.getElementById(key).value = "0%";

      var obj  = {}
      obj[key] = 0
      this.setState(obj);
    }

  }

  CalculateMonthlyBillsFromBreakdown()
  {
    
        var monthlyBills = parseInt(this.state.otherMonthlyBillAsNumber)+parseInt(this.state.autoInsuranceAsNumber)+parseInt(this.state.cableAsNumber)+parseInt(this.state.carMaintenanceAsNumber)+parseInt(this.state.carPaymentAsNumber)+parseInt(this.state.cellPhoneAsNumber)+ parseInt(this.state.charitableDonationsAsNumber)+ parseInt(this.state.childCareAsNumber)+parseInt(this.state.childSupportAsNumber)+parseInt(this.state.clothingAsNumber)+parseInt(this.state.creditCardAsNumber)+parseInt(this.state.diningAsNumber)+parseInt(this.state.entertainmentAsNumber)+parseInt(this.state.gasolineAsNumber)+parseInt(this.state.electricAsNumber)+parseInt(this.state.groceriesAsNumber)+parseInt(this.state.insuranceHealthMedicalAsNumber)+parseInt(this.state.internetAsNumber)+parseInt(this.state.lifeInsuranceAsNumber)+parseInt(this.state.medicalDentalVisionAsNumber)+parseInt(this.state.phoneAsNumber)+parseInt(this.state.recreationVacationAsNumber)+parseInt(this.state.studentLoanAsNumber)+parseInt(this.state.tuitionAsNumber)+parseInt(this.state.waterAsNumber);

        this.setState({          
          monthlyBills: this.FormatMoney(monthlyBills),
          monthlyBillsAsNumber: monthlyBills
        }, () => {
          
        });
  }


  ChangeCustomCurrencySliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    if (name.endsWith("InputCashFlowColumnAsNumber"))
    {
      name = name.replace("Input","");
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    //console.log("HERE- VALUE:");
    //console.log(value);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangeCurrencySliderValue(event);

  };

  ChangeCurrencySliderValue(event) {
  
    let key = event.target.name;


    var keyFormatted = key.replace('AsNumber','');
    
    var value = event.target.value;
  
    var obj  = {}
    obj[key] = value
    this.setState(obj, () => {
      // Do anything after the state has changed   
      this.HandleAdditionalSpecificLogic(event);
    });

    var obj  = {}
    obj[keyFormatted] = value
    this.setState(obj)
    
  }

  handleInputChange = (event) => {
  
  //  console.log("STOP 1");
    var formattedValue = event.target.value;
   // if (formattedValue =="") // This is not needed and instruces a bug
   // {
   //   formattedValue = "$0"; // This is not needed and instruces a bug
   // }

   let propertyName = event.target.name;

  // console.log("STOP 2:");

  // console.log("propertyName:");
  // console.log(propertyName);

  // console.log("formattedValue:");
  // console.log(formattedValue);

    this.setState({
      [propertyName]: formattedValue
    }, () => {
    
      //If number currency only use number value
      if (formattedValue.startsWith("$"))
      {
        var key = propertyName+"AsNumber"
        var val = Number(formattedValue.replace(/[^0-9\.-]+/g,""));  
        var obj  = {}
        obj[key] = val
        this.setState(obj, () => {
            // Do anything after the state has changed   
            this.HandleAdditionalSpecificLogic(event);
        });
      
      } 

    });

    
    
	};


  HandleAdditionalSpecificLogic(event)
  {
    
    if (event.target.name.startsWith('otherMonthlyBill')  || event.target.name.startsWith('autoInsurance')  || event.target.name.startsWith('cable')  || event.target.name.startsWith('carMaintenance')  || event.target.name.startsWith('carPayment')  || event.target.name.startsWith('cellPhone')  || event.target.name.startsWith('charitableDonations')   || event.target.name.startsWith('childCare')   || event.target.name.startsWith('childSupport')   || event.target.name.startsWith('clothing')  || event.target.name.startsWith('creditCard')   || event.target.name.startsWith('dining')  || event.target.name.startsWith('entertainment')   || event.target.name.startsWith('gasoline')  || event.target.name.startsWith('electric')  || event.target.name.startsWith('groceries')   || event.target.name.startsWith('insuranceHealthMedical')   || event.target.name.startsWith('internet')  || event.target.name.startsWith('lifeInsurance')   || event.target.name.startsWith('medicalDentalVision')   || event.target.name.startsWith('phone')   || event.target.name.startsWith('recreationVacation')  || event.target.name.startsWith('studentLoan')   || event.target.name.startsWith('tuition')   || event.target.name.startsWith('water'))
    {
      this.CalculateMonthlyBillsFromBreakdown();
    }

  }
  
  submitProfile = (event) => {
    event.preventDefault();
   	

    let passedValidation = true;

    let message = "";

    if (this.state.profileName == null || this.state.profileName ==='')
    {
    
      message = "Please enter a profile name";
      passedValidation = false;

    } else if (this.state.budgetAsNumber ==0)
    {
      message = "Please enter a budget";
      passedValidation = false;
    }

    if (!passedValidation)
    {
        this.dialog.show({
          title: 'Validation Message',
          body: message,
          actions: [
            Dialog.OKAction(() => {
              //console.log('OK was clicked!')
              
            })
          ],
          bsSize: 'small',
          onHide: (dialog) => {
            dialog.hide()
            
          }
        })

      } else {    
        const profile = {
          accountId: this.state.accountId,
          profileName: this.state.profileName,
          monthlyBills: parseInt(this.state.monthlyBillsAsNumber),
          monthlyGrossIncome:parseInt(this.state.monthlyGrossIncomeAsNumber),
          creditScore:this.state.creditScore,
          financingPreference:this.state.financingPreference.toLocaleLowerCase(),
          budget:parseInt(this.state.budgetAsNumber),

          otherMonthlyBill:parseInt(this.state.otherMonthlyBillAsNumber),
          autoInsurance:parseInt(this.state.autoInsuranceAsNumber),
          cable:parseInt(this.state.cableAsNumber),
          carMaintenance:parseInt(this.state.carMaintenanceAsNumber),
          carPayment:parseInt(this.state.carPaymentAsNumber),
          cellPhone:parseInt(this.state.cellPhoneAsNumber),
          charitableDonations:parseInt(this.state.charitableDonationsAsNumber),
          childCare:parseInt(this.state.childCareAsNumber),
          childSupport:parseInt(this.state.childSupportAsNumber),
          clothing:parseInt(this.state.clothingAsNumber),
          creditCard:parseInt(this.state.creditCardAsNumber),
          dining:parseInt(this.state.diningAsNumber),
          entertainment:parseInt(this.state.entertainmentAsNumber),
          gasoline:parseInt(this.state.gasolineAsNumber),
          electric:parseInt(this.state.electricAsNumber),
          groceries:parseInt(this.state.groceriesAsNumber),
          insuranceHealthMedical:parseInt(this.state.insuranceHealthMedicalAsNumber),
          internet:parseInt(this.state.internetAsNumber),
          lifeInsurance:parseInt(this.state.lifeInsuranceAsNumber),
          medicalDentalVision:parseInt(this.state.medicalDentalVisionAsNumber),
          phone:parseInt(this.state.phoneAsNumber),
          recreationVacation:parseInt(this.state.recreationVacationAsNumber),
          studentLoan:parseInt(this.state.studentLoanAsNumber),
          tuition:parseInt(this.state.tuitionAsNumber),
          water:parseInt(this.state.waterAsNumber),

        }

        this.setState({
          uiSaving: true
        });

        if (this.state.profile == null)  //Insert
        {
          authMiddleWare(this?.props?.history);
          const authToken = localStorage.getItem('AuthToken');
          const refreshToken = localStorage.getItem('RefreshToken');
          axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
          
          axios
            .post('/api/createProfile', profile)
            .then((response) => {
              
              if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
              {
                  localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                  localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              }

              console.log("Completed creating profile");

              localStorage.setItem('ProfileListStale',"Y");

              this.setState({
                uiSaving: false
              });

              this.closeModal();
              this.state.parentReference.reloadPage(); //We only need to reload page because we want the changes reflected in the top profile dropdown
              
            })
            .catch((error) => {
              console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                } else 
                {
                  this.setState({
                    uiSaving: false
                });
      
                }
              
            });

          } else  //Update 
          {
            const id = this.state.profile.id;

            const payload = {
              id:id,
              profile:profile
            }

            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 

            axios
            .post('/api/updateProfile', payload)
            .then((response) => {
              
              if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
              {
                  localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                  localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              }

              console.log("Completed updating profile");

              localStorage.setItem('ProfileListStale',"Y");

              this.setState({
                uiSaving: false
              });

              this.closeModal();
              this.state.parentReference.reloadPage(); //We only need to reload page because we want the changes reflected in the top profile dropdown
              
            })
            .catch((error) => {
              console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                } else 
                {
                  this.setState({
                    uiSaving: false
                });
      
                }
              
            });

          }

        }
	};

  render() {

    const { classes } = this.props;


      //Note: backdrop="static"  prevents the modal from closing when you click outside
      return (
      
          <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} backdrop="static" dialogClassName="property-edit-modal-width"> 

            <Modal.Header closeButton>
              <Modal.Title>

              {this.state.profile == null  &&
                  <span>Add Profile</span>
                }

                {this.state.profile != null  &&
                  <span>Edit Profile</span>
                }

              </Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal" ref={this.mainModalInfoDialogRef}>
            
              <div class="modal-body" style={{padding:"0px",margin:"0px"}}>
                    <div classx="container" style={{padding:"0px",margin:"0px"}}>

                    <div class="card2">
                                
                                <div class="card-body alert-edit-modal">

                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                            <form action="/action_page.php">
                                      
                                                {/* form row begins */}


                                                {/* Profile Name */} 
                                                <div class="row">

                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                        <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                    <div className='helpText'>This is the name of the profile. <br></br>You can use any name to help you <br></br>identify a group of properties.</div>

                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="profileName">Profile Name</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                            </OverlayTrigger>
                                                      
                                                            <input type="text" class="form-select alert-edit-modal-component" id="profileName" placeholder="i.e. Mary Jane's Properties" name="profileName" defaultValue={this.state.profileName} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                            
                                                        </div>
                                                    </div>


                                                  </div>    {/* ----- End Row ----*/}

                                                  <div class="row">

                                                        {/* Budget*/} 
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <OverlayTrigger
                                                                    placement="auto"
                                                                    delay={{ show: 150, hide: 100 }} 
                                                                    trigger={["hover","click"]}
                                                                    overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                  
                                                                      <div className='helpText'>This is the maximum amount of money the buyer can afford to spend.<br></br>This is not the price of the property, rather your out of pocket budget.<br></br> For example if you have a total of $40,000 in cash for your budget<br></br> you could buy a $250,000 property if financing it. Or you could by a <br></br> $40,000 house if paying cash.</div>
                                                                  </Tooltip>}
                                                                  >
                                                                  <div>
                                                                          <label class="dialog-form-label" for="monthlyGrossIncome">Budget</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                  </div>
                                                              </OverlayTrigger>
                                                                
                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="budget" placeholder="Purchase Price $" name="budget" defaultValue={this.state.budget} value={this.state.budget} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                <span class="desktop-only-block">
                                                                  <input type="range" className="custom-range" id="budgetAsNumber" name="budgetAsNumber" min="0" max="4000000" step="50000" value={this.state.budgetAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                </span>

                                                                <span class="mobile-only-block">
                                                                    <CustomizedSlider id="budgetAsNumber" name="budgetAsNumber" min={0} max={4000000} step={50000} value={this.state.budgetAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                    <br></br>     
                                                                </span>

                                                                </div>
                                                          </div>

                                                        {/* Financing Preference */} 
                                                        <div class="col-md-4">
                                                                
                                                                <div class="form-group">
                                                                          <OverlayTrigger
                                                                                placement="auto"
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["hover","click"]}
                                                                                overlay={<Tooltip  className="messagetooltip">
                                                                              
                                                                                  <div className='helpText'>Indicate how you plan to finance <br></br>the properties: getting a loan (mortgage) <br></br>or paying cash.</div>
                                                                              </Tooltip>}
                                                                              >
                                                                              <div>
                                                                                      <label class="dialog-form-label" for="financingPreference">Financing Preference</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                              </div>
                                                                          </OverlayTrigger>
                                                                    
                                                                          <DropdownButton id="financingPreferenceDropdownSelect" name="financingPreferenceDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.financingPreference} onSelect={(e) => this.HandleSelect(e)}>
                                                                              
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['financingPreference', 'Loan']}>Loan (Mortgage)</Dropdown.Item>
                                                                              <div style={{paddingBottom:"8px"}}></div>

                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['financingPreference', 'Cash']}>Cash</Dropdown.Item>
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                            
                                                                          </DropdownButton>

                                                                      </div>
                                                                      
                                                  </div>

                                                          
                                                        </div>  {/* ----- End Row ----*/}

                                                  <div class="row">
                                                                
                                                                {/* Monthly Bills */} 
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            rootClose={true}
                                                                            container={this.mainModalInfoDialogRef}
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                          
                                                                              <div className='helpText scrollable-help-text2'>
                                                                                
                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The total amount of money the buyer spends on bills monthly.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                  Below are the most common monthly bills that should be considered:
                                                                                </div>

                                                                                  <table border="1">
                                                                                  <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Auto Insurance</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Clothing</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Health, Medical Insurance</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Car Maintenance</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Credit Card(s)</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Life Insurance</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Car Payment, Lease</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Dining, Take-out</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Medical, Dental, Vision</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Cell Phone</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Entertainment</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Phone, Cable, Internet</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Charitable Donations</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Gas (Car)</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Recreation, Vacation</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Child Care, Tuition</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Gas, Electric</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Student Loan(s)</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Child Support</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Groceries</td>
                                                                                      <td style={{padding:'5px',color:'#ffa500',fontWeight:'bold'}}>Water</td>
                                                                                    </tr>
                                                                                  </table>
                                                                                

                                                                                <div style={{paddingBottom:'9px',paddingTop:'9px',maxWidth:'520px'}}>
                                                                                As a general rule, your total housing expense, including taxes and insurance, should not exceed <span style={{color:"#ffa500", fontWeight:"bold"}}>33%</span> of your gross income.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The monthly bills has an impact on the financial indicator calculators.
                                                                                </div>

                                                                             
                                                                                
                                                                                </div>
                                                                          </Tooltip>}
                                                                          >
                                                                          <div>
                                                                                  <label class="dialog-form-label" for="monthlyBills" style={{paddingBottom:'0xp',marginBottom:'0px'}}>Monthly Bills</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                  <div style={{fontSize:'12px',color:'#FFFFFF'}}>(No impact on rental or flipping properties)</div>
                                                                          </div>
                                                                      </OverlayTrigger>
                                                                        
                                                                          <table>
                                                                            <tr>
                                                                              <td  class="compound-column-width">
                                                                            
                                                                                <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="monthlyBills" placeholder="$" name="monthlyBills" defaultValue={this.state.monthlyBills} value={this.state.monthlyBills} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
      
                                                                              </td>
                                                                              <td style={{width:'20%'}}>
                                                                                      <OverlayTrigger   
                                                                                    placement="auto" 
                                                                                    rootClose={true}
                                                                                    container={this.mainModalInfoDialogRef}
                                                                                    delay={{ show: 150, hide: 100 }} 
                                                                                    trigger={["click"]} 
                                                                                    overlay={<Tooltip  id="closingCostsBreakdown" name="closingCostsBreakdown"  className="messagetooltip">
                                                                                  
                                                                              

                                                                                  <div className='helpText scrollable-help-text'>

                                                                                <div class="orangeInfo" style={{textAlign:'center'}}>Monthly Bills Breakdown</div>
                                                                                <br></br>

                                                                                <div class="row">
                                                                                  
                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="otherMonthlyBill">Other</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="otherMonthlyBill" placeholder="$" name="otherMonthlyBill" defaultValue={this.state.otherMonthlyBill} value={this.state.otherMonthlyBill} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="otherMonthlyBillAsNumber" name="otherMonthlyBillAsNumber" min="0" max="5000" step="50" value={this.state.otherMonthlyBillAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                                  
                                                                                      <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="otherMonthlyBillAsNumber" name="otherMonthlyBillAsNumber" min={0} max={8000} step={50} value={this.state.otherMonthlyBillAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>
                                                                                  </div>

                                                                                </div>

                                                                                <div class="row">
                                                                                  
                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="autoInsurance">Auto Insurance</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="autoInsurance" placeholder="$" name="autoInsurance" defaultValue={this.state.autoInsurance} value={this.state.autoInsurance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="autoInsuranceAsNumber" name="autoInsuranceAsNumber" min="0" max="5000" step="50" value={this.state.autoInsuranceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="autoInsuranceAsNumber" name="autoInsuranceAsNumber" min={0} max={8000} step={50} value={this.state.autoInsuranceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                      </span>

                                                                                  </div>

                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="cable">Cable</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="cable" placeholder="$" name="cable" defaultValue={this.state.cable} value={this.state.cable} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="cableAsNumber" name="cableAsNumber" min="0" max="5000" step="50" value={this.state.cableAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="cableAsNumber" name="cableAsNumber" min={0} max={8000} step={50} value={this.state.cableAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>

                                                                                    </div>

                                                                                    <div class="col-md-4">  
                                                                                      <label class="dialog-form-label" for="carMaintenance">Car Maintenance</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="carMaintenance" placeholder="$" name="carMaintenance" defaultValue={this.state.carMaintenance} value={this.state.carMaintenance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="carMaintenanceAsNumber" name="carMaintenanceAsNumber" min="0" max="5000" step="50" value={this.state.carMaintenanceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="carMaintenanceAsNumber" name="carMaintenanceAsNumber" min={0} max={8000} step={50} value={this.state.carMaintenanceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                      </span>
                                                                                    </div>                                                                                                      
                                                                                    
                                                                                </div> 

                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-md-4">
                                                                                        <label class="dialog-form-label" for="carPayment">Car Payment/Lease</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="carPayment" placeholder="$" name="carPayment" defaultValue={this.state.carPayment} value={this.state.carPayment} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                            <input type="range" className="custom-range" id="carPaymentAsNumber" name="carPaymentAsNumber" min="0" max="5000" step="50" value={this.state.carPaymentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>
                                                                                        <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="carPaymentAsNumber" name="carPaymentAsNumber" min={0} max={8000} step={50} value={this.state.carPaymentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>                    
                                                                                    </div>

                                                                                    <div class="col-md-4">
                                                                                        <label class="dialog-form-label" for="cellPhone">Cell Phone</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="cellPhone" placeholder="$" name="cellPhone" defaultValue={this.state.cellPhone} value={this.state.cellPhone} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="cellPhoneAsNumber" name="cellPhoneAsNumber" min="0" max="5000" step="50" value={this.state.cellPhoneAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>
                                                                                                      
                                                                                        <span class="mobile-only-block">
                                                                                            <CustomizedSlider id="cellPhoneAsNumber" name="cellPhoneAsNumber" min={0} max={8000} step={50} value={this.state.cellPhoneAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                            <br></br>     
                                                                                        </span>

                                                                                      </div>

                                                                                      <div class="col-md-4">  
                                                                                        <label class="dialog-form-label" for="charitableDonations">Charitable Donations</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="charitableDonations" placeholder="$" name="charitableDonations" defaultValue={this.state.charitableDonations} value={this.state.charitableDonations} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="charitableDonationsAsNumber" name="charitableDonationsAsNumber" min="0" max="5000" step="50" value={this.state.charitableDonationsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>
                                                                                        <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="charitableDonationsAsNumber" name="charitableDonationsAsNumber" min={0} max={8000} step={50} value={this.state.charitableDonationsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                        </span>

                                                                                      </div>                                                                                                      
                                                                                      
                                                                                  </div> 
                                                                                

                                                                                <div class="row">
                                                                                  
                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="childCare">Child Care</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="childCare" placeholder="$" name="childCare" defaultValue={this.state.childCare} value={this.state.childCare} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="childCareAsNumber" name="childCareAsNumber" min="0" max="5000" step="50" value={this.state.childCareAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="childCareAsNumber" name="childCareAsNumber" min={0} max={8000} step={50} value={this.state.childCareAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>
                                                                                  </div>

                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="childSupport">Child Support</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="childSupport" placeholder="$" name="childSupport" defaultValue={this.state.childSupport} value={this.state.childSupport} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="childSupportAsNumber" name="childSupportAsNumber" min="0" max="5000" step="50" value={this.state.childSupportAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="childSupportAsNumber" name="childSupportAsNumber" min={0} max={8000} step={50} value={this.state.childSupportAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>
                                                                                    </div>

                                                                                    <div class="col-md-4">  
                                                                                      <label class="dialog-form-label" for="clothing">Clothing</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="clothing" placeholder="$" name="clothing" defaultValue={this.state.clothing} value={this.state.clothing} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="clothingAsNumber" name="clothingAsNumber" min="0" max="5000" step="50" value={this.state.clothingAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>

                                                                                      <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="clothingAsNumber" name="clothingAsNumber" min={0} max={8000} step={50} value={this.state.clothingAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>

                                                                                    </div>                                                                                                      
                                                                                    
                                                                                </div>  

                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-md-4">
                                                                                        <label class="dialog-form-label" for="creditCard">Credit Card(s)</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="creditCard" placeholder="$" name="creditCard" defaultValue={this.state.creditCard} value={this.state.creditCard} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                            <input type="range" className="custom-range" id="creditCardAsNumber" name="creditCardAsNumber" min="0" max="5000" step="50" value={this.state.creditCardAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>
                                                                                        <span class="mobile-only-block">
                                                                                            <CustomizedSlider id="creditCardAsNumber" name="creditCardAsNumber" min={0} max={8000} step={50} value={this.state.creditCardAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                            <br></br>     
                                                                                        </span>
                                                                                    </div>

                                                                                    <div class="col-md-4">
                                                                                        <label class="dialog-form-label" for="dining">Dining, Take-out</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="dining" placeholder="$" name="dining" defaultValue={this.state.dining} value={this.state.dining} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                            <input type="range" className="custom-range" id="diningAsNumber" name="diningAsNumber" min="0" max="5000" step="50" value={this.state.diningAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>
                                                                                        <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="diningAsNumber" name="diningAsNumber" min={0} max={8000} step={50} value={this.state.diningAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>

                                                                                      </div>

                                                                                      <div class="col-md-4">  
                                                                                        <label class="dialog-form-label" for="entertainment">Entertainment</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="entertainment" placeholder="$" name="entertainment" defaultValue={this.state.entertainment} value={this.state.entertainment} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="entertainmentAsNumber" name="entertainmentAsNumber" min="0" max="5000" step="50" value={this.state.entertainmentAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>

                                                                                        <span class="mobile-only-block">
                                                                                            <CustomizedSlider id="entertainmentAsNumber" name="entertainmentAsNumber" min={0} max={8000} step={50} value={this.state.entertainmentAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                            <br></br>     
                                                                                        </span>

                                                                                      </div>                                                                                                      
                                                                                      
                                                                                  </div>  

                                                                                  <div class="row">
                                                                                  
                                                                                    <div class="col-md-4">
                                                                                        <label class="dialog-form-label" for="gasoline">Gas (Car)</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="gasoline" placeholder="$" name="gasoline" defaultValue={this.state.gasoline} value={this.state.gasoline} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="gasolineAsNumber" name="gasolineAsNumber" min="0" max="5000" step="50" value={this.state.gasolineAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>

                                                                                        <span class="mobile-only-block">
                                                                                            <CustomizedSlider id="gasolineAsNumber" name="gasolineAsNumber" min={0} max={8000} step={50} value={this.state.gasolineAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                            <br></br>     
                                                                                        </span>

                                                                                    </div>

                                                                                    <div class="col-md-4">
                                                                                        <label class="dialog-form-label" for="electric">Gas, Electric</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="electric" placeholder="$" name="electric" defaultValue={this.state.electric} value={this.state.electric} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="electricAsNumber" name="electricAsNumber" min="0" max="5000" step="50" value={this.state.electricAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>
                                                                                        <span class="mobile-only-block">
                                                                                            <CustomizedSlider id="electricAsNumber" name="electricAsNumber" min={0} max={8000} step={50} value={this.state.electricAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                            <br></br>     
                                                                                        </span>
                                                                                      </div>

                                                                                      <div class="col-md-4">  
                                                                                        <label class="dialog-form-label" for="groceries">Groceries</label>
                                                                                        <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="groceries" placeholder="$" name="groceries" defaultValue={this.state.groceries} value={this.state.groceries} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                        <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="groceriesAsNumber" name="groceriesAsNumber" min="0" max="5000" step="50" value={this.state.groceriesAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                        </span>
                                                                                        <span class="mobile-only-block">
                                                                                            <CustomizedSlider id="groceriesAsNumber" name="groceriesAsNumber" min={0} max={8000} step={50} value={this.state.groceriesAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                            <br></br>     
                                                                                        </span>
                                                                                      </div>                                                                                                      
                                                                                      
                                                                                  </div> 


                                                                                <div class="row">
                                                                                  
                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="insuranceHealthMedical">Insurance (Health/Medical)</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="insuranceHealthMedical" placeholder="$" name="insuranceHealthMedical" defaultValue={this.state.insuranceHealthMedical} value={this.state.insuranceHealthMedical} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="insuranceHealthMedicalAsNumber" name="insuranceHealthMedicalAsNumber" min="0" max="5000" step="50" value={this.state.insuranceHealthMedicalAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="insuranceHealthMedicalAsNumber" name="insuranceHealthMedicalAsNumber" min={0} max={8000} step={50} value={this.state.insuranceHealthMedicalAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                  </div>

                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="internet">Internet</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="internet" placeholder="$" name="internet" defaultValue={this.state.internet} value={this.state.internet} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="internetAsNumber" name="internetAsNumber" min="0" max="5000" step="50" value={this.state.internetAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="internetAsNumber" name="internetAsNumber" min={0} max={8000} step={50} value={this.state.internetAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                    </div>

                                                                                    <div class="col-md-4">  
                                                                                      <label class="dialog-form-label" for="lifeInsurance">Life Insurance</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="lifeInsurance" placeholder="$" name="lifeInsurance" defaultValue={this.state.lifeInsurance} value={this.state.lifeInsurance} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="lifeInsuranceAsNumber" name="lifeInsuranceAsNumber" min="0" max="5000" step="50" value={this.state.lifeInsuranceAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="lifeInsuranceAsNumber" name="lifeInsuranceAsNumber" min={0} max={8000} step={50} value={this.state.lifeInsuranceAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>

                                                                                    </div>                                                                                                      
                                                                                    
                                                                                </div>  


                                                                                <div class="row">
                                                                                  
                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="medicalDentalVision">Medical/Dental/Vision</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="medicalDentalVision" placeholder="$" name="medicalDentalVision" defaultValue={this.state.medicalDentalVision} value={this.state.medicalDentalVision} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                          <input type="range" className="custom-range" id="medicalDentalVisionAsNumber" name="medicalDentalVisionAsNumber" min="0" max="5000" step="50" value={this.state.medicalDentalVisionAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                          <CustomizedSlider id="medicalDentalVisionAsNumber" name="medicalDentalVisionAsNumber" min={0} max={8000} step={50} value={this.state.medicalDentalVisionAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                          <br></br>     
                                                                                      </span>

                                                                                  </div>

                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="phone">House Phone (Landline)</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="phone" placeholder="$" name="phone" defaultValue={this.state.phone} value={this.state.phone} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="phoneAsNumber" name="phoneAsNumber" min="0" max="5000" step="50" value={this.state.phoneAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="phonAsNumber" name="phonAsNumber" min={0} max={8000} step={50} value={this.state.phonAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                    </div>

                                                                                    <div class="col-md-4">  
                                                                                      <label class="dialog-form-label" for="recreationVacation">Recreation/Vacation</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="recreationVacation" placeholder="$" name="recreationVacation" defaultValue={this.state.recreationVacation} value={this.state.recreationVacation} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="recreationVacationAsNumber" name="recreationVacationAsNumber" min="0" max="5000" step="50" value={this.state.recreationVacationAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="recreationVacationAsNumber" name="recreationVacationAsNumber" min={0} max={8000} step={50} value={this.state.recreationVacationAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                    
                                                                                    </div>                                                                                                      
                                                                                    
                                                                                </div>  



                                                                                <div class="row">
                                                                                  
                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="studentLoan">Student Loan(s)</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="studentLoan" placeholder="$" name="studentLoan" defaultValue={this.state.studentLoan} value={this.state.studentLoan} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="studentLoanAsNumber" name="studentLoanAsNumber" min="0" max="5000" step="50" value={this.state.studentLoanAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>
                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="studentLoanAsNumber" name="studentLoanAsNumber" min={0} max={8000} step={50} value={this.state.studentLoanAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                  </div>

                                                                                  <div class="col-md-4">
                                                                                      <label class="dialog-form-label" for="tuition">Tuition</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="tuition" placeholder="$" name="tuition" defaultValue={this.state.tuition} value={this.state.tuition} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="tuitionAsNumber" name="tuitionAsNumber" min="0" max="5000" step="50" value={this.state.tuitionAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>

                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="tuitionAsNumber" name="tuitionAsNumber" min={0} max={8000} step={50} value={this.state.tuitionAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                    </div>

                                                                                    <div class="col-md-4">  
                                                                                      <label class="dialog-form-label" for="water">Water</label>
                                                                                      <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="water" placeholder="$" name="water" defaultValue={this.state.water} value={this.state.water} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                                      <span class="desktop-only-block">
                                                                                        <input type="range" className="custom-range" id="waterAsNumber" name="waterAsNumber" min="0" max="5000" step="50" value={this.state.waterAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                                      </span>

                                                                                      <span class="mobile-only-block">
                                                                                        <CustomizedSlider id="waterAsNumber" name="waterAsNumber" min={0} max={8000} step={50} value={this.state.waterAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                    </span>
                                                                                    </div>                                                                                                      
                                                                              
                                                                          </div>  


                                                                          <div  style={{textAlign:'center',paddingBottom:'5px'}}>TOTAL:<br></br>{this.FormatMoney(this.state.monthlyBillsAsNumber)}</div>



                                                                          <div style={{textAlign:"center"}}>
                                                                              <Button style={{fontSize:"16px"}} onClick={e => this.HandleMonthlyBillsBreakdownClose(e, this)}>DONE</Button> 
                                                                          </div>   

                                                                          </div>
                                                                                  </Tooltip>}
                                                                                  >
                                                                                    <FileEarmarkText className="ml-2 orangeInfo" style={{fontSize:"23px", fontWeight:"bold", padding:"0px",margin:"0px"}}/>
                                                                                  
                                                                                  </OverlayTrigger>

                                                                              </td>
                                                                            </tr>

                                                                            <tr>
                                                                              <td style={{width:'80%'}}>
                                                                                <span class="desktop-only-block">
                                                                                    <input type="range" className="custom-range" id="monthlyBillsAsNumber" name="monthlyBillsAsNumber" min="0" max="8000" step="100" value={this.state.monthlyBillsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                            </span>

                                                                                <span class="mobile-only-block">
                                                                                    <CustomizedSlider id="monthlyBillsAsNumber" name="monthlyBillsAsNumber" min={0} max={8000} step={100} value={this.state.monthlyBillsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                                <br></br>     
                                                                                </span>

                                                                              </td>
                                                                              <td style={{width:'20%'}}></td>
                                                                            </tr>
                                                                        </table>

                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-4">
                                                                      <div class="form-group">
                                                                          <OverlayTrigger
                                                                              placement="auto"
                                                                              delay={{ show: 150, hide: 100 }} 
                                                                              trigger={["hover","click"]}
                                                                              overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                            
                                                                                <div className='helpText'>Please provide the buyer's pre-tax monthly income.</div>
                                                                            </Tooltip>}
                                                                            >
                                                                            <div>
                                                                                    <label class="dialog-form-label" for="monthlyGrossIncome"  style={{paddingBottom:'0xp',marginBottom:'0px'}}>Monthly Gross Income</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                                    <div style={{fontSize:'12px',color:'#FFFFFF'}}>(No impact on rental or flipping properties)</div>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                          
                                                                          <CurrencyInput type="text" class="form-select alert-edit-modal-component" id="monthlyGrossIncome" placeholder="Purchase Price $" name="monthlyGrossIncome" defaultValue={this.state.monthlyGrossIncome} value={this.state.monthlyGrossIncome} onChange={this.handleInputChange} onFocus= {this.HandleCurrencyEnterFocus.bind(this)} onBlur= {this.HandleCurrencyExitFocus.bind(this)}/>
                                                                          <span class="desktop-only-block">
                                                                              <input type="range" className="custom-range" id="monthlyGrossIncomeAsNumber" name="monthlyGrossIncomeAsNumber" min="0" max="20000" step="100" value={this.state.monthlyGrossIncomeAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                                          </span>

                                                                          <span class="mobile-only-block">
                                                                              <CustomizedSlider id="monthlyGrossIncomeAsNumber" name="monthlyGrossIncomeAsNumber" min={0} max={20000} step={100} value={this.state.monthlyGrossIncomeAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                              <br></br>     
                                                                          </span>

                                                                          </div>
                                                                    </div>
                                                                    
                                                            </div>  {/* ----- End Row ----*/}


                                                  <div class="row">

                                                   <div class="col-md-4">
                                                                
                                                                <div class="form-group">
                                                                          <OverlayTrigger
                                                                                placement="auto"
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["hover","click"]}
                                                                                overlay={<Tooltip  className="messagetooltip">
                                                                              
                                                                                  <div className='helpText'>Buyer's credit score. <br></br>This is only used for primary homes, <br></br>it is not used for rental or flipping <br></br>properties.</div>
                                                                              </Tooltip>}
                                                                              >
                                                                              <div>
                                                                                      <label class="dialog-form-label" for="creditScore">Credit Score</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} />
                                                                                      <div style={{fontSize:'12px',color:'#FFFFFF'}}>(No impact, informational only)</div> 
                                                                              </div>
                                                                          </OverlayTrigger>
                                                                    
                                                                          <DropdownButton id="creditScoreDropdownSelect" name="creditScoreDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.creditScore} onSelect={(e) => this.HandleSelect(e)}>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '760+']}>760+</Dropdown.Item>
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '740-759']}>740-759</Dropdown.Item>

                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '720-739']}>720-739</Dropdown.Item>

                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '700-719']}>700-719</Dropdown.Item>
                                                                              
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '680-699']}>680-699</Dropdown.Item>
                                                                              
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '660-679']}>660-679</Dropdown.Item>
                                                                              
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '640-659']}>640-659</Dropdown.Item>
                                                                              
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '620-639']}>620-639</Dropdown.Item>
                                                                              
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '580-619']}>580-619</Dropdown.Item>
                                                                              
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['creditScore', '300-579']}>300-579</Dropdown.Item>
                                                                            
                                                                          </DropdownButton>

                                                                      </div>
                                                                      
                                                                  </div>
                                                  
                                                                
                                                  </div>  

                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <Dialog ref={(component) => { this.dialog = component }}/>
                        
                                </div>
                    </div> 

        
                    <div class="modal-footer">
                                            <div class="container">
                                            <center>
                                                <div class="row">
                                                    <div class="col-md-5">&nbsp;</div>
                                                    <div class="col-md-2">
                                                      <button id="SaveButton" type="button" class="btn btn-primary btn-block footer-buttons" style={{marginTop:"5px"}} onClick={this.submitProfile}  >Save</button>
                                                    </div>
                                                
                                                    <div class="col-md-6">&nbsp;</div>
                                                  </div>
                                              </center>

                                            </div>




                    </div>

            </div>
                                                    
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>

            {this.state.uiSaving && 
              <main className={classes.content} style={{height:'1px'}}>
                  <div className={classes.toolbar} />
                  {this.state.uiSaving && <CircularProgress size={150} className={classes.uiProgess} />}
              </main>
            }    

          </Modal>
   
      );
      
    }
}

export default (withStyles(styles)(ProfileEditDialog));
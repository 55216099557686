import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>internal rate of return</b> (IRR) measures the profitability of a potential investment based on its predicted future cash flows.
                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>internal rate of return</b> (IRR) is normally used to rank several prospective rental properties an investor is considering. 
                                         </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        Assuming all other factors are equal among the various properties, the property with the highest <b>internal rate of return</b> would probably be considered the best and undertaken first.
                                        </div>
     
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The higher the internal rate of return (IRR), the better. The higher the <b>IRR</b> for a project, the more attractive the project is to undertake.

                                    <br></br><br></br>
                                    Also if the <b>IRR is</b> less than the cost of borrowing used to fund the property, the property will clearly be a losing investment.

                                    <br></br><br></br>
                                    That's why most investors will insist that in order to be acceptable, a property must be expected to earn an <b>internal rate of return</b> that is at least several percentage points higher than the mortgage interest rate.
                                    
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>internal rate of return</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                    
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Initial Investment        
                                                        {this.state.indicator.property.financingType == "Loan" &&         

                                                        <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                            <table class="calculation-info-table">
                                                            
                                                                {this.state.indicator.property.downPayment > 0 && 
                                                                    <tr>
                                                                        <td>Down Payment</td>
                                                                        <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                        <td></td>
                                                                        <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.downPayment)}</td>
                                                                    </tr>
                                                                }

                                                                {this.state.indicator.property.closingCosts > 0 && 
                                                                    <tr>
                                                                        <td>Closing Costs</td>
                                                                        <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                        <td>+</td>
                                                                        <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.closingCosts)}</td>
                                                                    </tr>
                                                                }
                                                            
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_INITAL_INVESTMENT}</b></td>
                                                                    </tr>

                                                            </table>

                                                            
                                                        </div>
                                                        }

                                                        {this.state.indicator.property.financingType == "Cash" &&        

                                                        <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                        <table class="calculation-info-table">

                                                            {this.state.indicator.property.price > 0 && 
                                                                <tr>
                                                                    <td>Purchase Price</td>
                                                                    <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                    <td></td>
                                                                    <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.price)}</td>
                                                                </tr>
                                                            }

                                                            {this.state.indicator.property.closingCosts > 0 && 
                                                                    <tr>
                                                                        <td>Closing Costs</td>
                                                                        <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                        <td>+</td>
                                                                        <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.closingCosts)}</td>
                                                                    </tr>
                                                                }

                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_INITAL_INVESTMENT}</b></td>
                                                                </tr>

                                                        </table>


                                                        </div>
                                                        }
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_INITAL_INVESTMENT}
                                                    </div>

                                                </div>

                                                {this.state.indicator.helpVariableMap.VAR_CASHFLOWS?.map((yearCashFlow, i) => (
                                          
                                                    <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Year {yearCashFlow.year} Cash Flow
                                                                {this.state.indicator.property?.propertyCashFlowMaps != null && this.state.indicator.property?.propertyCashFlowMaps.get(i+1) != null && 
                                                                <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                                        <table class="calculation-info-table">
                
                                                                                <tr>
                                                                                    <td>Gross Annual Income</td>
                                                                                    <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                    <td></td>
                                                                                    <td style={{textAlign:'right'}}>{this.state.indicator.property?.propertyCashFlowMaps.get(i+1).get("VAR_GROSS_ANNUAL_INCOME")}</td>
                                                                                </tr>
                                                                        
                                                                                <tr>
                                                                                    <td>Vacancy and Credit Loss</td>
                                                                                    <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                    <td>-</td>
                                                                                    <td style={{textAlign:'right'}}>{this.state.indicator.property?.propertyCashFlowMaps.get(i+1).get("VAR_VACANCY_CREDIT_LOSS")}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>Annual Expenses</td>
                                                                                    <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                    <td>-</td>
                                                                                    <td style={{textAlign:'right'}}>{this.state.indicator.property?.propertyCashFlowMaps.get(i+1).get("VAR_ANNUAL_EXPENSES")}</td>
                                                                                </tr>

                                                                                {this.state.indicator.property?.propertyCashFlowMaps.get(i+1).get("VAR_ANNUAL_MORTGAGE_PAYMENT") != "$0" && 
                                                                                <tr>
                                                                                    <td>Annual Mortgage</td>
                                                                                    <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                    <td>-</td>
                                                                                    <td style={{textAlign:'right'}}>{this.state.indicator.property?.propertyCashFlowMaps.get(i+1).get("VAR_ANNUAL_MORTGAGE_PAYMENT")}</td>
                                                                                </tr>
                                                                                }
                                                                                
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{yearCashFlow.cashFlow}</b></td>
                                                                                </tr>
                
                                                                        </table>
                
        
                                                                </div>
                                                                }
                                                                {this.state.indicator.property?.propertyCashFlowMaps == null && 
                                                                <div>
                                                                
                                                                    <span style={{fontWeight:"500",fontSize:"11px"}}>
                                                                    Year {yearCashFlow.year} Gross Annual Income<br></br>
                                                                        less Vacancy and Credit Loss<br></br>
                                                                        less Annual Expenses<br></br>
                                                                        less Mortgage Payments
                                                                    </span>
                                                                </div>     
                                                                }           
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {yearCashFlow.cashFlow}
                                                        </div>

                                                    </div>

                                                ))}

                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Sales Proceeds         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_SALE_PROCEEDS}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">

                                    <div style={{paddingRight:'10px',paddingBottom:'15px'}}>    
                                    Because a dollar today is worth more than a dollar earned in the future, it's necessary to convert future cash flows to their present value. This process is known as calculating the Net Present Value (NPV).
                                    </div>

                                    {/* ---------------------  STEP 1 ------------------ */}
                                        
                                    {this.state.indicator.helpVariableMap.VAR_CASHFLOWS?.map((yearCashFlow, i) => (
                                     <div>
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP {yearCashFlow.year}:</span> <b>Calculate Year {yearCashFlow.year} Net Preset Value</b>
                                            </div>
                                        </div>
                                        
                                        
                                      <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'9%'}}>
                                           <div class="row">
                                                <table>
                                                         <tr>
                                                            <td style={{paddingTop:'5px'}}>Year {yearCashFlow.year} Cash Flow = </td>
                                                            <td style={{textAlign:"left",paddingLeft:'5px',paddingRight:'5px'}}>
                                                               Rent 
                                                               {yearCashFlow.saleProceeds != "$0" && 
                                                                <span> + Sale Proceeds</span>
                                                               }
                                                            </td>
                                                        </tr>

                                                        
                                                        {yearCashFlow.saleProceeds != "$0" && 
                                                        <tr><td style={{paddingTop:'10px'}}></td><td></td></tr>
                                                        }

                                                        {yearCashFlow.saleProceeds != "$0" && 
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Year {yearCashFlow.year} Cash Flow = </td>
                                                            <td style={{textAlign:"left",paddingLeft:'5px',paddingRight:'5px'}}>
                                                            {yearCashFlow.cashFlow} + {yearCashFlow.saleProceeds}
                                                            </td>
                                                        </tr>
                                                        }

                                                        <tr><td style={{paddingTop:'10px'}}></td><td></td></tr>
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Year {yearCashFlow.year} Cash Flow = </td>
                                                            <td style={{textAlign:"left",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                {yearCashFlow.totalCashFlow}
                                                            </td>
                                                        </tr>

                                                        <tr><td style={{paddingTop:'10px'}}></td><td></td></tr>
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Net Present Value = </td>
                                                            <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}>
                                                                     <b> 
                                                                       Annual Cash Flow
                                                                    </b>
                                                                </div>
                                                                 
                                                                <b>(1 + IRR)<sup>Year#</sup></b>

                                                            </td>
                                                        </tr>

                                                        <tr><td style={{paddingTop:'10px'}}></td><td></td></tr>
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Net Present Value = </td>
                                                            <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}>
                                                                     <b> 
                                                                     {yearCashFlow.totalCashFlow}
                                                                    </b>
                                                                </div>
                                                                 
                                                                <b>(1 + {this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)})<sup>{yearCashFlow.year}</sup></b>

                                                            </td>
                                                        </tr>

                                                        
                                                        <tr><td style={{paddingTop:'10px'}}></td><td></td></tr>
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Net Present Value = </td>
                                                            <td style={{textAlign:"left",paddingLeft:'5px',paddingRight:'5px'}}>
                                                               <b>{yearCashFlow.npv}</b>
                                                            </td>
                                                        </tr>

                                                    </table>
                                            </div>




                                        </div>                               



                                        <br></br>                
                                        </div>
                                        ))}

                                        
                                         {/* ---------------------  STEP 2 ------------------ */}
                    
                                         <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP {this.state.indicator.helpVariableMap.VAR_CASHFLOWS.length+1}:</span> <b>Add up all the net present values</b>
                                           </div>
                                       </div>
                                       

                                      <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'9%'}}>
                                           
                                        {this.state.indicator.helpVariableMap.VAR_CASHFLOWS?.map((yearCashFlow, i) => (
                                        
                                            <div class="row">
                                                 
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           Year {yearCashFlow.year}<span style={{float:'right'}}>=></span>
                                                       </div>

                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                          +
                                                       </div>
                                                       <div class="col-md-5" style={{textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{yearCashFlow.npv}</b>
                                                       </div>
                                            </div>

                                        ))}
                                          
                                           <div class="row">
                                                       <div class="col-md-5" style={{textAlign:"left",maxWidth:'250px'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                           
                                                       </div>
                                                       <div class="col-md-5" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'90px'}}>
                                                           <b>{this.state.indicator.helpVariableMap.TOTAL_NPV}</b>
                                                       </div>
                                           </div>
                                  
                                       </div>

                                       <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                As you can see, if you use a discount rate of <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b> to calculate the present value of future cash flows, 
                                                the total discounted cash flows of <b>{this.state.indicator.helpVariableMap.TOTAL_NPV}</b> match or approximate 
                                                the initial investment of <b>{this.state.indicator.helpVariableMap.VAR_INITAL_INVESTMENT}</b>. 
                                                That is the measure that tells you the Internal Rate of Return (IRR) of this investment is <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>.

                                            </div>
                                        </div>
     
                                
                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Internal Rate of Return benchmark settings or by changing the following property values:
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                INITIAL CASH INVESTMENT<br></br>
                                                ANNUAL CASH FLOWS <br></br>
                                                SALES PROCEEDS<br></br>
                                            
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      
                
     
                 {/* TIP */}
                 {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Increase the <span style={{fontWeight:'600'}}>monthly rent</span><br></br> 
                            <b>2)</b> Reduce your <span style={{fontWeight:'600'}}>monthly expenses</span><br></br>   
                            <b>3)</b> Increase your <span style={{fontWeight:'600'}}>sale proceeds</span><br></br>                 
                        </div>

                    </div>        
                }             


                {/* No Mortgage TIP */}
                {this.state.indicator?.property.financingType =="Cash" &&
                                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <br></br>

                        <b>Note:</b> No mortgage has been specified for this property, therefore the initial investment is the entire purchase price; which means the property is not being financed. 
                        <br></br><br></br>
                        To get better results add a mortgage to finance part of the purchase price.

                    </div>        
                }             

                <br></br><br></br><br></br><br></br>
                </div>
          
          </main> 
        );
    }
}

export default (GRMIndicator);

import React, { Component } from 'react';
import { Modal, Button, Dropdown, DropdownButton, OverlayTrigger,Tooltip} from "react-bootstrap";
import { InfoCircleFill } from 'react-bootstrap-icons';
import { Link45deg } from 'react-bootstrap-icons';

import { FileEarmarkText,  House } from 'react-bootstrap-icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import CurrencyInput from '../types/CurrencyInput'

import 'react-tabs/style/react-tabs.css';

import CustomizedSlider from '../types/CustomizedSlider';

import loan from '../pages/img/icons/loan.png';
import cash from '../pages/img/icons/cash.png';

import { authMiddleWare } from '../utilities/Auth';

import Dialog from 'react-bootstrap-dialog'

import axios from 'axios';


const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '40%',
		top: '35%'
	}
});

class FinancingPreferenceDialog extends Component { 


  /* Actual one for application */

    state = {
        isOpen: false,
        uiSaving: false,
        financingPreference:'',
        user:null
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.mainModalInfoDialogRef = React.createRef();

     }

     componentDidUpdate() 
     {

     }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }


    PopulateDialog(user,parentRef)
    {
        
        this.ClearFields();

        this.setState({
          user: user
        });
      
 
        this.openModal();
    }

 
    ClearFields()
    {
      this.setState({
          financingPreference:''
        });

    }


    selectFinancingPreference= (event) => {

      //console.log(event.target.name);
 
       const radioElement = document.getElementById(event.target.name);
 
       radioElement.checked = true;
 
       this.setState({
         financingPreference: event.target.name
       });
 
   };

   onChangeValue= (event) => {
 
     //console.log(event.target.value);
 
     this.setState({
       financingPreference: event.target.value
     });
 
   };

  submit= (event) => {
    event.preventDefault();
   	

    if (this.state.financingPreference ==='')
    {

      this.dialog.show({
        title: 'Invalid Selection',
        body: 'You must select one of the options',
        actions: [
          Dialog.OKAction(() => {
            //console.log('OK was clicked!')
            
          })
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
          
        }
      })

    } else {

        const payload = {
          financingPreference: this.state.financingPreference
        }

        this.setState({
          uiSaving: true
        });

        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
        
        axios
          .post('/api/updateFinancingPreference', payload)
          .then((response) => {

            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
            {
                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            }

            console.log("Investor Type Updated");

            this.setState({
              uiSaving: false
            });

            this.closeModal();
            
          })
          .catch((error) => {
            console.log(error);

              if (error && error.response && error.response.status && error.response.status === 403) {
                      localStorage.setItem('SessionExpired',"Y");
                      //window.location.reload();
                      window.location.href = '/login';
              } else 
              {
                this.setState({
                  uiSaving: false
                });
      
              }
            
          });
     
      }

	};


  render() {

    const { classes } = this.props;


      //Note: backdrop="static"  prevents the modal from closing when you click outside
      return (
      
          <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} backdrop="static" dialogClassName="property-edit-modal-width"> 

           {/* <Modal.Header closeButton> */}
            <Modal.Header>
              <Modal.Title>

         
                <span>About You</span>
             

              </Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal" ref={this.mainModalInfoDialogRef}>
            
              <div class="modal-body" style={{padding:"0px",margin:"0px"}}>
                    <div classx="container" style={{padding:"0px",margin:"0px"}}>

                    <div class="card2">
                        <div class="card-body alert-edit-modal" style={{backgroundColor:'#FFFFFF'}}>

                            <div style={{paddingBottom:'15px',fontSize:'20px'}}>
                           What is your financing preference?
                            </div> 

                            <div class="row">

                                {/* LOAN/MORTGAGE */}
                                <div class="col-md-6" style={{textAlign:'center'}}>
                             
                                      <div style={{fontSize:'20px',fontWeight:'bold'}}>
                                        LOAN / MORTGAGE
                                      </div>
                                      <OverlayTrigger
                                          placement="auto"
                                          delay={{ show: 150, hide: 100 }} 
                                          trigger={["hover","click"]}
                                          overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                        
                                        <div className='helpText'>You plan to get a loan <br></br>or mortgage to buy the properties.</div>
                                          
                                        </Tooltip>}
                                        >
                                      
                                      <img src={loan} style={{height:'160px'}} name="loan" onClick={this.selectFinancingPreference}/>
                                    
                                      </OverlayTrigger>
                                      <br></br>
                                      <input type="radio" value="loan" id="loan" name="financingPreference" style={{height:'30px',width:'30px',marginTop:'10px'}} onChange={this.onChangeValue}/>
                              
                                </div>

                                {/* CASH */}
                                <div class="col-md-6" style={{textAlign:'center'}}>

                                    <div style={{fontSize:'20px',fontWeight:'bold'}}>
                                    CASH
                                    </div>
                                    <OverlayTrigger
                                        placement="auto"
                                        delay={{ show: 150, hide: 100 }} 
                                        trigger={["hover","click"]}
                                        overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                      
                                          <div className='helpText'>You plan to pay cash <br></br>to buy the properties.</div>
                                        
                                      </Tooltip>}
                                      >
                                    
                                    <img src={cash} name="cash" style={{height:'160px'}} onClick={this.selectFinancingPreference}/>
                                  
                                    </OverlayTrigger>
                                    <br></br>
                                    <input type="radio" value="cash" id="cash" name="financingPreference" style={{height:'30px',width:'30px',marginTop:'10px'}} onChange={this.onChangeValue}/>
                                     
                                  </div>


                            </div>

                        </div>
                        
                    </div>
              </div> 

        
                    <div class="modal-footer"  style={{backgroundColor:'#FFFFFF'}}>
                                            <div class="container">
                                            <center>
                                                <div class="row">
                                                    <div class="col-md-5">&nbsp;</div>
                                                    <div class="col-md-2">
                                                      <button id="SaveButton" type="button" class="btn btn-primary btn-block footer-buttons" style={{marginTop:"5px"}} onClick={this.submit}  >Continue</button>
                                                    </div>
                                                
                                                    <div class="col-md-6">&nbsp;</div>
                                                  </div>
                                              </center>

                                            </div>




                    </div>

                    <Dialog ref={(component) => { this.dialog = component }}/>                
            </div>
                                                    
            </Modal.Body>
            {/*
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>
            */}

            {this.state.uiSaving && 
              <main className={classes.content} style={{height:'1px'}}>
                  <div className={classes.toolbar} />
                  {this.state.uiSaving && <CircularProgress size={150} className={classes.uiProgess} />}
              </main>
            }    

          </Modal>
   
      );
      
    }
}

export default (withStyles(styles)(FinancingPreferenceDialog));
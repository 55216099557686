import React, { Component } from 'react';

class Search extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                <div class="my-property-heading">
                    <h1 class="dashboard-heading">Property Search</h1>
                </div>
                <div class="row search-bar-main">
                    <div class="col-md-11 col-sm-8 col-xs-6">
                        <input type="text" class="search-bar-property" placeholder="Search.." name="search"/>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <div class="seach-icons">
                                <button type="submit" class="search-icon"><i class="fa fa-search"></i></button>
                            </div>

                        </div>
                    </div>
                    <div class="search-by-sec">
                        <ul class="search-by-list">
                            <li>
                                <p>Search By:</p>
                            </li>
                            <li><label class="radio-inline">Address</label><input type="radio" name="optradio" checked/>
                            </li>
                                <li><label class="radio-inline">Zipcode</label><input type="radio" name="optradio" checked/>
                            </li>
                                    <li><label class="radio-inline">MLSID</label><input type="radio" name="optradio" checked/>
                            </li>
                        </ul>
                    </div>
                                <section class="property-detail">
                                    <div class="row properties-row">
                                        <div class="col-md-12">
                                            <div class="property-content">
                                                <h3>Property Name</h3>
                                                <h4>Rental Property</h4>
                                                <div class="indicators"><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i>
                                                    <i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square red-tick"></i><i class="fas fa-check-square red-tick"></i></div>
                                                <p>Single family home - 2 bathrooms, 1 living room, 4 bedrooms, backyard, frontyard, pool, two car garage, 2500 square feet * ROI:10%, Cap Rate:25.50%, Break-Even Ratio:34%, Back End Ratio:12%</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row properties-row">
                                        <div class="col-md-12">
                                            <div class="property-content">
                                                <h3>Property Name</h3>
                                                <h4>Rental Property</h4>
                                                <div class="indicators"><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i>
                                                    <i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square red-tick"></i><i class="fas fa-check-square red-tick"></i></div>
                                                <p>Single family home - 2 bathrooms, 1 living room, 4 bedrooms, backyard, frontyard, pool, two car garage, 2500 square feet * ROI:10%, Cap Rate:25.50%, Break-Even Ratio:34%, Back End Ratio:12%</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row properties-row">
                                        <div class="col-md-12">
                                            <div class="property-content">
                                                <h3>Property Name</h3>
                                                <h4>Rental Property</h4>
                                                <div class="indicators"><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i>
                                                    <i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square red-tick"></i><i class="fas fa-check-square red-tick"></i></div>
                                                <p>Single family home - 2 bathrooms, 1 living room, 4 bedrooms, backyard, frontyard, pool, two car garage, 2500 square feet * ROI:10%, Cap Rate:25.50%, Break-Even Ratio:34%, Back End Ratio:12%</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row properties-row">
                                        <div class="col-md-12">
                                            <div class="property-content">
                                                <h3>Property Name</h3>
                                                <h4>Rental Property</h4>
                                                <div class="indicators"><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i><i class="fas fa-check-square greenish-tick"></i>
                                                    <i class="fas fa-check-square red-tick"></i><i class="fas fa-check-square red-tick"></i><i class="fas fa-check-square red-tick"></i></div>
                                                <p>Single family home - 2 bathrooms, 1 living room, 4 bedrooms, backyard, frontyard, pool, two car garage, 2500 square feet * ROI:10%, Cap Rate:25.50%, Break-Even Ratio:34%, Back End Ratio:12%</p>
                                            </div>
                                        </div>

                                    </div>
                                    <button class="btn load-more-btn">Load More</button>
                                </section>

            </div>

        );
    }
}

export default (Search);

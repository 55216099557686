import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const IndicatorInfo = ({
	categoryName,
	address,
	city,
	state,
	zip,
	price,
	passedCount,
	failedCount,
}) => {
	return (
		<View style={styles.wrapper}>
			<View style={[styles.box, styles.fwBold]}>
				<Text style={styles.categoryName}>{categoryName}</Text>
				<View style={styles.address}>
					<Text>{address}</Text>
					<Text>
						{city}, {state} {zip}
					</Text>
				</View>
				<Text style={styles.price}>
					{new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
					})
						.format(price)
						.replace(/(\.|,)00$/g, '')}
				</Text>
			</View>
			<View style={[styles.box, styles.boxSmall]}>
				<Text style={[styles.fwBold]}>PASSED</Text>
				<Text style={[styles.fwBold, styles.numbeer, styles.numberSuccess]}>
					{passedCount}
				</Text>
				<Text>Indicators</Text>
			</View>
			<View style={[styles.box, styles.boxSmall]}>
				<Text style={[styles.fwBold]}>FAILED</Text>
				<Text style={[styles.fwBold, styles.numbeer, styles.numberFailed]}>
					{failedCount}
				</Text>
				<Text>Indicators</Text>
			</View>
			<View style={styles.box}></View>
		</View>
	);
};

const styles = StyleSheet.create({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginVertical: 4,
		marginHorizontal: -4,
	},
	box: {
		margin: '0.5%',
		flexBasis: '26%',
	},
	boxSmall: {
		flexBasis: '22%',
		textAlign: 'center',
		border: '1px solid #000',
		borderRadius: 3,
		padding: 5,
		display: 'flex',
		justifyContent: 'space-evenly',
		fontSize: 14,
		color: '#555',
	},
	fwBold: {
		fontFamily: 'Helvetica-Bold',
	},
	numbeer: {
		fontSize: 28,
	},
	numberSuccess: {
		color: 'green',
	},
	numberFailed: {
		color: 'red',
	},
	categoryName: {
		fontSize: 16,
	},
	address: {
		fontSize: 13,
		color: '#555',
		fontFamily: 'Helvetica',
		marginVertical: 3,
	},
	price: {
		fontSize: 24,
		marginTop: 5,
	},
});

export default IndicatorInfo;

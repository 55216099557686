import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';
import Dialog from 'react-bootstrap-dialog'

//import appLogo from '../images/logo-nocircle.png';

import appLogo from './img/website-images/real-estate-property-analytics-logo.png';

import closeIcon from './img/icons/x2.png';

import axios from 'axios';

import { authMiddleWare } from '../utilities/Auth';
import { useAccordionToggle } from 'react-bootstrap';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#e37627'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			firstName: '',
			name: '',
          	email: '',
			password: '',
			errors: [],
			loading: false,
			buttonText: 'Continue',
			plan:'',
			type:'',
			termsChecked: false,
			displayScreen:false
		};
	}


	componentWillMount = () => {

		try
		{
			// 1. Set Type and Plan
			this.parseQueryStringParameters();


			// 2. check user is logped in
			console.log("About to check logged in user.");


			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.get('/api/alreadyLoggedInCheck')
				.then((response) => {

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					//console.log(JSON.stringify(response));

					if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
					{
		    			
						//TODO, it depends whther it is a trial account and a bunch of other stuff. Need to get user to make good decisions
						//this.props.history.push("/signup/email-confirmation");

							//Retrieve user
							authMiddleWare(this?.props?.history);
							const authToken = localStorage.getItem('AuthToken');
							const refreshToken = localStorage.getItem('RefreshToken');
							axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
							axios
							.get('/api/user')
							.then((response) => {
								//console.log(response.data);
			   
								if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
								{
									localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
									localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
								}
				
								const user = response.data;
	
								localStorage.setItem('SessionExpired','N')
	

								if (user.planType==="trial")
								{
									if (user.emailHasBeenConfirmed == null || user.emailHasBeenConfirmed ==="N")
									{
										this.props.history.push('/signup/email-confirmation');
									} else
									{
										this.props.history.push('/pricing');
									}
								
								} else 
								{
									if (user.emailHasBeenConfirmed == null || user.emailHasBeenConfirmed ==="N")
									{
										this.props.history.push('/signup/email-confirmation');

									} else if (user.termsAndConditionsAccepted == null || user.termsAndConditionsAccepted ==="N")
									{
										this.props.history.push('/signup/terms-and-conditions');

									}  else if (user.stripeSubscriptionId == null || user.stripeSubscriptionId ==="N")
									{
										this.props.history.push('/signup/billing-information');

									}  else
									{
										//TODO - This is where we should have the option to change the plan in stripe
									}
								}

								this.setState({ 
									user: response.data,
									email: response.data.email,
									displayScreen:true
								});	
	

							})
							.catch((error) => {
								console.log('Error in getting user record:'+error);
			
								this.setState({ 
									user: null,
									displayScreen:true
								});	
								//If user is not logged in redirect to signup
								//this.props.history.push('/pricing');
	
						
							  });
			
					} else
					{
						this.setState({ 
							user: null,
							displayScreen:true
						});	

					}

				})
				.catch((error) => {
					
					this.setState({ 
						user: null,
						displayScreen:true
					});	
					
				});
			
			} catch(err)
			{
				this.setState({ 
					user: null,
					displayScreen:true
				});	
				console.log("Error checking logged in user:"+err);
			
			}
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.UI.errors) {
			this.setState({
				errors: nextProps.UI.errors
			});
		}
	}

	parseQueryStringParameters = () => {

		// Query string example
		//https://www.sitepoint.com/get-url-parameters-with-javascript/

		const queryString = window.location.search;
	
		let type = "	"; //trial or subscription

		let plan = "";

		let frequency = "";

		if (queryString != null && queryString != '')
		{
			const urlParams = new URLSearchParams(queryString);

			type = urlParams.get('type');

			plan = urlParams.get('plan');	
			
			frequency = urlParams.get('frequency');
		}

		if (type.trim() === "")
		{
			type = "trial";
		}

		if (plan.trim() === "")
		{
			plan = "standard";
		}

		this.setState({
			planType:type,
			planName:plan,
			billingFrequency:frequency
		}, () => {
		   
			if (type==='trial')
			{
				this.setState({ 
					buttonText:'Start My Free Trial'
				});	
			}
		});

	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();

		if (this.state.email ==="")
		{
			this.displayAlert("Invalid Email","Enter valid email");
			return;
		}

		if (this.state.password ==="")
		{
			this.displayAlert("Invalid Email","Enter valid password");
			return;
		}

		if (!this.state.termsChecked)
		{
			//alert("You must accept to the Terms of Service");
			let title = "Terms of Service"
			//let message = "You must accept the Terms of Service and Privacy Policy"
			let message = "You must accept the Terms of Service"

			this.displayAlert(title,message);
			  
			return;
		}

		//const firstName= parseOutFirstName(this.state.name);
		this.setState({ loading: true });
		//this.setState({ firstName: firstName });

		let accountType = "individual";

		if (this.state.planName === 'enterprise')
		{
			accountType = "corporate";
		}

		const newUserData = {
			name: this.state.name,
			email: this.state.email.trim(),
			password: this.state.password.trim(),
			planType: this.state.planType,
			planName: this.state.planName,
			billingFrequency: this.state.billingFrequency,
			accountType:accountType,
			role: 'admin' //All users are admin my default
		};

		axios
			.post('/api/signup', newUserData)
			.then((response) => {
				
				localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
				localStorage.setItem('RefreshToken', response.data.refreshToken);

				localStorage.setItem('SessionExpired','N')

				this.setState({ 
					loading: false,
				});
				
				this.props.history.push('/signup/email-confirmation');

				/*
				axios
				.post('/api/initiateActivation', newUserData)
				.then((response) => {
			
				  localStorage.setItem('ActivationFirstName', `${newUserData.firstName}`);
				  localStorage.setItem('ActivationEmail', `${newUserData.email}`);

				  this.props.history.push('/activate');
			
				})
				.catch((error) => {
					
					console.log("Error sending email:"+error);
				}); 
				
				*/

			})
			.catch((error) => {
			
				console.log(error);
				//console.log(JSON.stringify(error));
				//console.log(error.toString());
				
				if (error != null && error.toString().indexOf("code 300")>=0) {
					this.displayAlert("Validation Error","Please enter a valid email and password.");					
				} else if (error != null && ((error.status && error.status === 400) || error.toString().indexOf("code 400") >=0)) {
					this.displayAlert("Duplicate Email","This email is already in use.");
				} else {
					this.displayAlert("Error","There was an issue while creating your account. Try again later.");
				}

				this.setState({
					errors: error,
					loading: false
				});
			});
	};

	displayAlert = (title,message) => {

		this.dialog.show({
			title: title,
			body: message,
			actions: [
			  Dialog.OKAction(() => {
				//console.log('OK was clicked!')
				
			  })
			],
			bsSize: 'small',
			onHide: (dialog) => {
			  dialog.hide()
			  
			}
		  });
	};

	checkValue = (e) => {	
		//var value = e.target.value;
		var value = e.target.checked;
		//alert("You selected " + value);

		this.setState({
			termsChecked: value
		});

	  }

	handleTermsAndPrivacyChange = () => {
		//setChecked(!checked);
	  };

	goHome = () => {
		window.location.href ="/";
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#e37627' //your color
			  }
			}
		  });
		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<div>
			{this.state.displayScreen && 
			<div>
				<div style={{float:'right'}}><a href="/pricing"><img src={closeIcon} style={{height:'30px'}}/></a> &nbsp;&nbsp;&nbsp;&nbsp;</div>

			<Container component="main" maxWidth="xs">
				
				<CssBaseline />
				<div className={classes.paper}>

				<img src={appLogo} style={{height:'85px',cursor:'pointer',paddingBottom:"10px"}} onClick={this.goHome}/> 

				 {/*
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#e37627',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
					GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>

			*/}	
					<Typography component="h1" variant="h5">
						<span style={{fontWeight:"500"}}>Sign up</span>
					</Typography>

					<form className={classes.form} noValidate>
					<ThemeProvider theme={theme}>
						<Grid container spacing={2}>
						{/*	
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="name"
									label="Name"
									name="name"
									type="regular"
									autoComplete="name"
									helperText={errors.name}
									error={errors.name ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>
							*/}
					
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email Address"
									type="regular"
									name="email"  
									/*inputProps={{style: {fontSize: 23}}}
									InputLabelProps={{style: {fontSize: 23}}} */
									autoComplete="email"
									helperText={errors.email}
									error={errors.email ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>


							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password" 
									/*
									inputProps={{style: {fontSize: 23}}}
									InputLabelProps={{style: {fontSize: 23}}}  */
									autoComplete="current-password"
									helperText={errors.password}
									error={errors.password ? true : false}
									onChange={this.handleChange}
								/>
							</Grid>
					
					
						</Grid>

						<br></br>
				
						<label>
							<table>
								<tr>
									<td>
										<input type="checkbox" onChange={this.checkValue} style={{width:'22px',height:'22px'}}/>
									</td>
									<td style={{paddingBottom:'5px'}}>
										{/*
										<span style={{fontSize:'15px',color:'#000000'}}>&nbsp; I agree to the <a href="/terms-of-service" target="_blank">Terms of Service</a> and <a href="" target="_blank">Privacy Policy</a>. </span>
										*/}
										<span style={{fontSize:'15px',color:'#000000'}}>&nbsp; I agree to the <a href="/terms-of-service" target="_blank">Terms of Service</a>. </span>

									</td>
								</tr>
								
							</table>
						</label>

						<div style={{textAlign:'center',width:'100%',fontWeight:'bold',paddingTop:'4px'}}>No Credit Card Required</div>


						<Button
							type="submit"
							fullWidth
							variant="contained" 
							style={{color:'#FFFFFF',fontSize:'16px',fontWeight:'bold'}}
							color="primary"
							className={classes.submit}
							onClick={this.handleSubmit}
                            disabled={loading}
						>
							{this.state.buttonText}
							{loading && <CircularProgress size={30} className={classes.progess} />}
						</Button>
						{this.state.planType == "trial" && 
						<div style={{textAlign:'center',width:'100%',fontWeight:'bold'}}>7-day free trial
						</div>
						
						}
						<div style={{textAlign:'center',width:'100%',paddingTop:'10px'}}>
						<Grid container>
							<Grid item>
								<Link href="login" variant="body2" style={{fontSize:'18px'}}>
									Already have an account? <strong>Log in</strong>
								</Link>
							</Grid>
						</Grid>
						</div>


						</ThemeProvider>
					</form>
					<br></br><br></br><br></br>
				</div>
			</Container>
			</div>
			}

				<Dialog ref={(component) => { this.dialog = component }}/>     
			</div>
		);
	}
}

export default withStyles(styles)(signup);

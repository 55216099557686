import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>Rule of 70</b> states that an investor should pay no more than <b>70%</b> of the after-repair value minus repairs in order make a profit.
                                        
                                        </div>

                             
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The lower the value for the Rule of 70, the better. Anything below <b>70%</b> is considered good.

                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>Rule of 70</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Purchase Price
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        After Repair Value
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_AVR}
                                                    </div>

                                                </div>

                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Rehab Costs
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_REPAIRS}
                                                        </div>
                                                    
                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">


                                        {/* ---------------------  STEP 1 ------------------ */}

                                        <div class="row">
    
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Rule of 70</b>
                                           </div>
                                       </div>
                                       
                                       <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table" style={{paddingLeft:'15%'}}>

                                                <div style={{paddingTop:"12px"}}>
                                                    Rule of 70 = <b>After Repair Value x .70</b>
                                                </div>

                                                <div style={{paddingTop:"12px"}}>
                                                    Rule of 70 = <b>{this.state.indicator.helpVariableMap.VAR_AVR} x .70</b>
                                                </div>

                                                <div style={{paddingTop:"12px"}}>
                                                    Rule of 70 = <b>{this.state.indicator.helpVariableMap.VAR_GROSS_70_RULE}</b>
                                                </div>

                                                <br></br>
                                            </div>
                                        </div>

                                   
                                        {/* ---------------------  STEP 2 ------------------ */}
                                        <div class="row">
    
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 2:</span> <b>Calculate maximum purchase price</b>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table" style={{paddingLeft:'15%'}}>

                                                <div style={{paddingTop:"12px"}}>
                                                    Maximum purchase price = <span class="mobile-only-block"><br></br></span><b>Rule of 70 - Rehab Costs</b>
                                                </div>

                                                <div style={{paddingTop:"12px"}}>
                                                    Maximum purchase price = <span class="mobile-only-block"><br></br></span><b>{this.state.indicator.helpVariableMap.VAR_GROSS_70_RULE} - {this.state.indicator.helpVariableMap.VAR_REPAIRS}</b>
                                                </div>

                                                <div style={{paddingTop:"12px"}}>
                                                    Maximum purchase price = <span class="mobile-only-block"><br></br></span><b>{this.state.indicator.helpVariableMap.VAR_NET_70_RULE}</b>
                                                </div>

                                                <br></br>

                                                This means that you shouldn't pay more than <b>{this.state.indicator.helpVariableMap.VAR_NET_70_RULE}</b> for this property. <br></br><br></br>
                                            </div>
                                        </div>



                                        {/* ---------------------  STEP 3 ------------------ */}


                                               <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 3:</span> <b>Calculate the percentage you are paying</b>
                                            </div>
                                            </div>

                                                    <div class="row">
                                                        <div class="col-md-12 indicator-info-formula-table">

                                                            <div classx="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:"20px"}}>
                                                                <table>
                                                                    <tr>
                                                                        <td style={{paddingTop:'5px'}}>Rule of 70 = </td>
                                                                        <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                            <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px'}}>
                                                                                <b> 
                                                                                <span class="desktop-only-block">Purchase </span>Price + Rehab Costs
                                                                                </b>
                                                                            </div>
                                                                            
                                                                            <b>After Repair Value</b>

                                                                        </td>
                                                                       
                                                                    </tr>

                                                                </table>
                                                            </div>

                                                            <div classx="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:"20px"}}>
                                                                <table>
                                                                    <tr>
                                                                        <td style={{paddingTop:'5px'}}>Rule of 70 = </td>
                                                                        <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                            <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px'}}>
                                                                                <b> 
                                                                                {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE} + {this.state.indicator.helpVariableMap.VAR_REPAIRS}
                                                                                </b>
                                                                            </div>
                                                                            
                                                                            <b>{this.state.indicator.helpVariableMap.VAR_AVR}</b>

                                                                        </td>
                                                                       
                                                                    </tr>

                                                                </table>
                                                            </div>




                                                            <div classx="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:"20px"}}>
                                                                    <table>
                                                                        <tr>
                                                                        
                                                                        <td>Rule of 70 = <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b></td>
                                                                            </tr>
                                                                    </table>    
                                                            
                                                            </div>
                                                        </div>
                                            </div>
                                                                      

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Rule of 70 benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                PURCHASE PRICE<br></br>
                                                AFTER REPAIR VALUE<br></br>
                                                REHAB COSTS
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                  {/* TIP */}
                  {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>2)</b> Lower your <span style={{fontWeight:'600'}}>rehab costs</span><br></br>
                        </div>

                    </div>        
                }       
                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

import React from "react";

import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import "../pages/css/checkout.css";

import poweredByStripe from '../pages/img/help/powered-by-stripe.png';

import { parseOutFirstName } from '../utilities/AppUtility';

import {createTheme,ThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import { authMiddleWare } from '../utilities/Auth';

import Dialog from 'react-bootstrap-dialog'

class CheckoutForm extends React.Component {

  constructor(props) {
		super(props);

    this.state = {
			name: '',
      company: '',
      hasSubscription: false,
			stripeCustomerId:'',
			stripeSubscriptionId:'',
      stripeSubscriptionStatus:'',
      cardIsValid: false,
      loading: false,
      disableButton: 'true',
      errors: [],
		};
	}
  
  componentWillMount = () => {
    this.setState({
      uiLoading: false
    });

	};


  handleCardChange = (event) => {
		
    if (event.complete) {
    
        if (!event.error) {
        
          this.setState({
            cardIsValid: true
          }, () => {
            this.checkButtonShouldBeEnabled();            
          });

        } else
        {
          this.setState({
            cardIsValid: false
          }, () => {
           this.checkButtonShouldBeEnabled();            
          });
        }
    } else
    {
      this.setState({
        cardIsValid: false
      }, () => {
        this.checkButtonShouldBeEnabled();            
      });
    }
  
	};

  checkButtonShouldBeEnabled = () =>
  {
      if (this.state.name.trim() != '' && 
      (this.props.user.planName !== "enterprise" || (this.props.user.planName === "enterprise" && this.state.company.trim() != '')) &&
          this.state.cardIsValid)
      {
        
        this.setState({
          disableButton: ''
        });
      }else
      {
        this.setState({
          disableButton: 'true'
        });
      }

  }

  handleChange = (event) => {

    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.checkButtonShouldBeEnabled();            
    });

	};

  handleSubmit = async event => {

    event.preventDefault();

    const me = this;

    //const targetAction = this.props.targetAction;

    this.setState({ loading: true });

    const stripeCustomerId = this.state.stripeCustomerId;

    const { stripe, elements, billingFrequency, plan } = this.props;

    if (!stripe || !elements) {

      return;

    }
    
    const card = elements.getElement(CardElement);

    if (this.state.name.trim() != '' && 
       this.props.user.email.trim() != '' && 
       (this.props.user.planName !== "enterprise" || (this.props.user.planName === "enterprise" && this.state.company.trim() != '')) &&
       this.state.cardIsValid)
    {

      this.setState({
        disableButton: 'true'
      });

      const name = this.state.name;
      const company = this.state.company;
      const email = this.props.user.email;
      
      stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: this.state.name,
        },
      })
      .then(function(result) {
      
          const paymentMethodId = result.paymentMethod.id;
          
          //----------- SIGN UP -------------/
          const firstName= parseOutFirstName(name);
          
          const newUserData = {
            firstName: firstName,
            name: name,
            company:company,
            billingFrequency: billingFrequency,
            plan: plan,
            planType: 'subscription',
            email:email,
            paymentMethodId: paymentMethodId
          };

          //alert(JSON.stringify(newUserData));

          authMiddleWare(this?.props?.history);
          const authToken = localStorage.getItem('AuthToken');
          const refreshToken = localStorage.getItem('RefreshToken');
          axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
          axios
            .post('/api/subscribeUserInPlan', newUserData)
            .then((response) => {
    
              if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
              {
                  localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                  localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              }

              console.log("CAME BACK");
              me.setState({ loading: false });
              window.location.href = '/app';
      
            })
            .catch((error) => {
              console.log("Error processing:"+error);

              try
              {
                  me.setState({
                    errors: "Error processing",
                    disableButton: '',
                    loading: false 
                    
                  });
                } catch(error)
                {

                }
            });
          
          //---------------- SIGN UP end ===================/

      }).catch(function(err) {
        this.setState({ loading: false });
        this.displayAlert("Error","Error processing. Try again later");
 //       window.location.reload();
                          
        console.log(err);
      });

    } else {

      this.setState({
        disableButton: 'true'
      });
      this.displayAlert("Invalid Credit Card","Please enter all required fields: Name, Email and Credit Card");
    }
    

  };

  displayAlert = (title,message) => {

		this.dialog.show({
			title: title,
			body: message,
			actions: [
			  Dialog.OKAction(() => {
				//console.log('OK was clicked!')
				
			  })
			],
			bsSize: 'small',
			onHide: (dialog) => {
			  dialog.hide()
			  
			}
		  });
	};

  render() {

    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "20px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fc0d1b",
          iconColor: "#fc0d1b",
        },
      },
    };

    const theme = createTheme({
      palette: {
        primary: {
          main: '#e37627' //your color
        }
      }
    });

    
    //const { errors } = this.state;

		const { errors, loading } = this.state;
       
    return (

      <div>

      {/*   <form onSubmit={handleSubmit}> */}
      <form onSubmit={this.handleSubmit}>

        <div className="sr-combo-inputsX" style={{maxWidth:"600px"}}>


          {this.props.user.planName === "enterprise" &&  
          <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="text"
              id="company"
              name="company" 
              value= {this.state.company}
              placeholder="Company Name"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px",fontSize:'20px'}} 
              onChange={this.handleChange}
            />
          </div>

          }

          <div className="sr-combo-inputs-row" style={{paddingBottom:"10px"}}>
            <input
              type="text"
              id="name"
              name="name" 
              value= {this.state.name}
              placeholder="Name on Card"
              autoComplete="cardholder"
              className="sr-input" 
              style={{borderRadius:"4px",fontSize:'20px'}} 
              onChange={this.handleChange}
            />
          </div>
          <div style={{color:'#fc0d1b',fontWeight:'bold',fontSize:'16px'}}> {errors.name} </div>

          <br></br>
          
          <div className="sr-combo-inputs-rowx" style={{paddingBottom:"10px"}}>
            <CardElement
              className="sr-input sr-card-element"
              options={options} 
              disabled={this.state.uiLoading} 
              style={{borderRadius:"4px"}} 
              onChange={this.handleCardChange}
            />
          </div>

        </div>

        <br></br>
        <center>
            <ThemeProvider theme={theme}>
                <Button
                        type="submit"
                        fullWidth
                        variant="contained" 
                        style={{color:'#FFFFFF',fontSize:'20px',fontWeight:'bold',maxWidth:'400px'}}
                        color="primary" 
                        disabled={this.state.disableButton}
                    >
                        Sign Up

                        {loading && <CircularProgress size={30} style={{position: 'absolute'}} />}
                    </Button>
            </ThemeProvider> 
        </center>

      </form>
      <br></br>

   <img src={poweredByStripe} style={{height:"80px"}}/> 

    <br></br>

    <div className="banner">
        <span style={{marginTop:"10px",marginBottom:"5px"}}>

          We don't save your payment information, your payment is handled directly my our payment processor. We use <strong>Stripe</strong> as our payment processor, which adheres to the standards set by <strong>PCI-DSS</strong> as managed by the <strong>PCI</strong> Security Standards Council. You can read about Stripe's PCI-DSS compliance <a href="https://stripe.com/guides/pci-compliance" target="_blank">here</a>. 

        </span>
    </div>

    <Dialog ref={(component) => { this.dialog = component }}/> 
      </div>

    );

  }

}


export default function InjectedCheckoutForm(props) {

  return (

    <ElementsConsumer>

      {({ stripe, elements }) => (

        <CheckoutForm stripe={stripe} elements={elements} targetAction={props.targetAction} user={props.user} billingFrequency={props.billingFrequency} plan={props.plan}/>

      )}

    </ElementsConsumer>

  );

}


var AppUtility = require("./AppUtility.js");

const LESS_BETTER_INDICATORS        = ['R70','LVR','PPQ','BUDGET','GRM','BER','BE','FE','COD','PTR'];
const GREEATER_BETTER_INDICATORS    = ['KRI','DCR','ROI','NP','MVP','CAP','COC','PI','IRR','NCF','CS'];

export const aggregateMetrics = (properties, investmentType, profileId, profile) => {

    const metrics = {};
    
    if (properties != null)
    {
        try
        {
            //Filter properties and profile
            const filteredProperties = properties.filter(property => property.investmentType == investmentType && property.profileId == profileId);

            //1. Convert budget
            const budgetK = getKPartOfNumber(profile.budget);
            metrics.budgetK = budgetK;

            //2. Find lowest price property
            const lowestPriceProperty = getLowestPriceProperty(filteredProperties);
            metrics.lowestPriceProperty = lowestPriceProperty;

            //3. Find highest price property
            const highestPriceProperty = getHighestPriceProperty(filteredProperties);
            metrics.highestPriceProperty = highestPriceProperty;

            //4. Find properties with most green indicators
            const mostGreenIndicatorProperties = getPropertiesWithMostGreenIndicators(filteredProperties);
            metrics.mostGreenIndicatorProperties = mostGreenIndicatorProperties;

            //5. Find best performing indicators
            const bestPerformingIndicators = getBestPerformingIndicators(filteredProperties);
            metrics.bestPerformingIndicators = bestPerformingIndicators;

            //6. Find best most green indicators by location
            const mostGreenIndicatorsByLocation = getMostGreenIndicatorsByLocation(filteredProperties);
            metrics.mostGreenIndicatorsByLocation = mostGreenIndicatorsByLocation;

        } catch(error)
        {
            console.log(error);
        }
        
    }

    console.log(JSON.stringify(metrics));

    return metrics;
}

/* ----------------------------------------------------------------------------------------- */
/*                         P R I V A T E     F U N C T I O N S                               */
/* ----------------------------------------------------------------------------------------- */


const getMostGreenIndicatorsByLocation = (properties) => {

    let mostGreenIndicatorsByLocation = {};

    //1. Get location counts
    const StateCountMap     = getDistinctStateCountMap(properties);
    const cityStateCountMap = getDistinctCityStateCountMap(properties);
    const zipCountMap       = getDistinctZipCountMap(properties);

    console.log("StateCountMap.size:"+StateCountMap.size);
    console.log("cityStateCountMap.size:"+cityStateCountMap.size);
    console.log("zipCountMap.size:"+zipCountMap.size);
    
    const criteria = decideWithCriteriaToUse(StateCountMap.size,cityStateCountMap.size,zipCountMap.size);

    switch(criteria) {
        case "state":
            mostGreenIndicatorsByLocation = getMostIndicatorCountByState(StateCountMap,properties);
          break;
        case "cityState":
            mostGreenIndicatorsByLocation = getMostIndicatorCountByCityState(cityStateCountMap,properties);
          break;
        case "zipcode":
            mostGreenIndicatorsByLocation = getMostIndicatorCountByZipcode(zipCountMap,properties);
            break;

      }

    return mostGreenIndicatorsByLocation;
}

const getBestPerformingIndicators = (properties) => {

    const bestPerformingIndicators = [];

    if (properties.length > 0)
    {
        for (let indicator of properties[0].indicators) {

            if (indicator.indicatorAcronym == 'CS') //Skip Credit Score, it makes no sense
            {
                continue;
            }

            let property = getBestPerformingPropertyForThisIndicator(indicator,properties);
            bestPerformingIndicators.push(property);
        }
    }

    return bestPerformingIndicators;
}


const getPropertiesWithMostGreenIndicators = (properties) => {

    const mostGreenIndicatorProperties = [];

    let propertyArray = [];

    //Count indicator
    for (let property of properties) {

        let {greenIndicatorCount,redIndicatorCount} = getIndicatorCounts(property);
  
        propertyArray.push({
            id:property.id,
            address: {
                street:property.address,
                city:property.city,
                state:property.state,
                zip:property.zipcode
            },
            greenIndicatorCount:greenIndicatorCount,
            redIndicatorCount:redIndicatorCount
        });

    }

    //Sort by green indicators desc
    
    propertyArray = propertyArray.sort((a, b) => {
        if (a.greenIndicatorCount > b.greenIndicatorCount) {
          return -1;
        }
    });

    let counter = 0;  
    for (let property of propertyArray) {

        mostGreenIndicatorProperties.push(property);

        counter = counter + 1;

        if (counter >= 4)
        {
            break;
        }

    }

    return mostGreenIndicatorProperties;
}

const getLowestPriceProperty = (properties) => {

    const lowestPriceProperty = {
        priceK:0,
        address:{}
    };

    let lastPrice = 9999999999999;
    let lastProperty = null;

    for (let property of properties) {
        
        if (property.price < lastPrice)
        {
            lastPrice = property.price;
            lastProperty = property;
        }
     }

     if (lastProperty != null)
     {
        //console.log(JSON.stringify(lastProperty));

        lowestPriceProperty.priceK = getKPartOfNumber(lastProperty.price);
        lowestPriceProperty.address = {};
        lowestPriceProperty.address.street = lastProperty.address;
        lowestPriceProperty.address.city = lastProperty.city;
        lowestPriceProperty.address.state = lastProperty.state;
        lowestPriceProperty.address.zip = lastProperty.zipcode;

     }

    return lowestPriceProperty;
}

const getHighestPriceProperty = (properties) => {

    const highestPriceProperty = {
        priceK:0,
        address:{}
    };

    let lastPrice = 0;
    let lastProperty = null;

    for (let property of properties) {
        
        if (property.price > lastPrice)
        {
            lastPrice = property.price;
            lastProperty = property;
        }
     }

     if (lastProperty != null)
     {
        highestPriceProperty.priceK = getKPartOfNumber(lastProperty.price);
        highestPriceProperty.address = {}; 
        highestPriceProperty.address.street = lastProperty.address;
        highestPriceProperty.address.city = lastProperty.city;
        highestPriceProperty.address.state = lastProperty.state;
        highestPriceProperty.address.zip = lastProperty.zipcode;
     }

    return highestPriceProperty;
}

const getBestPerformingPropertyForThisIndicator = (checkIndicator,properties) => {

    let propertyIndicator = {};

    let lastValue = 0;
    let lastProperty = null;

    let currentProperty = null;

    if (LESS_BETTER_INDICATORS.includes(checkIndicator.indicatorAcronym))
    {
        lastValue = 9999999999999;
    } else
    {
        lastValue = -9999999999999;
    }

    for (let property of properties) {
        
        currentProperty = property;

        //console.log("property:"+property.address);

        for (let indicator of property.indicators) {

            if (checkIndicator.indicatorAcronym === indicator.indicatorAcronym )
            {
                //console.log("--------------------------------");
                //console.log(indicator.indicatorName);
                //console.log(indicator.indicatorValue);
                //console.log(lastValue);

                if (LESS_BETTER_INDICATORS.includes(checkIndicator.indicatorAcronym))
                {
                    if (indicator.indicatorValue < lastValue)
                    {
                        lastValue = indicator.indicatorValue;
                        lastProperty = property;
                    }

                } else
                {
                    if (indicator.indicatorValue > lastValue)
                    {
                        lastValue = indicator.indicatorValue;
                    lastProperty = property;
                }
            }


                break;
            }
        }

    }

    //Needed when you only have one property
    if (lastProperty == null && properties.length == 1 && currentProperty != null)
    {
        lastProperty = currentProperty;
    }

    if (lastProperty != null)
    {
        let selectedIndicator = null;
        //Get selected Indicator
        for (let indicator of lastProperty.indicators) {

            if (checkIndicator.indicatorAcronym === indicator.indicatorAcronym )
            {
                selectedIndicator = indicator;
                break;
            }
        }

        if (selectedIndicator != null)
        {
            //Get best preforming
            propertyIndicator = {
                id :lastProperty.id,
                address: {
                    street:lastProperty.address,
                    city:lastProperty.city,
                    state:lastProperty.state,
                    zip:lastProperty.zipcode
                },
                indicatorName:selectedIndicator.indicatorName,
                indicatorValue: AppUtility.getIndicatorValue(selectedIndicator.valueType,selectedIndicator.indicatorValue),
                passed:selectedIndicator.meetsBenchmark
            };
        }

    }

    return propertyIndicator;
}

const decideWithCriteriaToUse = (stateCount,cityStateCount,zipCount) => {

    let criteria = "state"; //defaults to state

    if (stateCount >=4)
    {
        criteria = "state";

    } else if (cityStateCount >=4)
    {
        criteria = "cityState";

    }else if (zipCount >=4)
    {
        criteria = "zipcode";
    } else 
    {
        if (stateCount > cityStateCount && stateCount > zipCount)
        {
            criteria = "state";

        } else if (cityStateCount > stateCount && cityStateCount  > zipCount)
        {
            criteria = "cityState";

        } else if (zipCount > stateCount && zipCount > cityStateCount)
        {
            criteria = "zipcode";
        } 


    }

    return criteria;
}

const getDistinctStateCountMap = (properties) =>{

    const StateCountMap = new Map();

    for (let property of properties) {

        let count  = 0;

        const key = property.state.toUpperCase();

        if (StateCountMap.has(key))
        {
            count = StateCountMap.get(key) + 1;

        }else
        {
            count = 1;
        }

        StateCountMap.set(key,count);
    }

    return StateCountMap;

}

const getDistinctCityStateCountMap = (properties) => {
    
    const cityStateCountMap = new Map();

    
    for (let property of properties) {

        let count  = 0;

        const key = property.city+"_"+property.state;
        
        if (cityStateCountMap.has(key))
        {
            count = cityStateCountMap.get(key) + 1;

        }else
        {
            count = 1;
        }

        cityStateCountMap.set(key,count);
    }

    return cityStateCountMap;

}

const getDistinctZipCountMap = (properties) => {
    
    const zipCountMap = new Map();

    for (let property of properties) {

        let count  = 0;
        
        const key = property.zipcode.toUpperCase();

        if (zipCountMap.has(key))
        {
            count = zipCountMap.get(key) + 1;

        }else
        {
            count = 1;
        }

        zipCountMap.set(key,count);
    }

    return zipCountMap;

}


const getMostIndicatorCountByState = (StateCountMap,properties) =>{

    const lineChartLabels = [];
    const lineChartGreenDataPoints = [];
    const lineChartRedDataPoints =[];

    for (const [key, value] of StateCountMap) {
        
        const filteredProperties = properties.filter(property => property.state == key);
        
        const [totalGreenIndicatorCounts,totalRedIndicatorCounts] = getPropertiesTotalIndicatorCount(filteredProperties);

        lineChartLabels.push(key);
        lineChartGreenDataPoints.push(totalGreenIndicatorCounts);
        lineChartRedDataPoints.push(totalRedIndicatorCounts);
    }

    const result = {
        "lineChartLabels" : lineChartLabels,
        "lineChartGreenDataPoints" : lineChartGreenDataPoints,
        "lineChartRedDataPoints" : lineChartRedDataPoints
    };

    return result;
}

const getMostIndicatorCountByCityState = (cityStateCountMap,properties) =>{
    
    //const filteredProperties = properties.filter(property => property.state == key && property.profileId == profileId);

    const lineChartLabels = [];
    const lineChartGreenDataPoints = [];
    const lineChartRedDataPoints =[];

    for (const [key, value] of cityStateCountMap) {

        const keyParts = key.split("_");
        const key1 = keyParts[0];
        const key2 = keyParts[1];
        
        const filteredProperties = properties.filter(property => property.city == key1 && property.state == key2);
        
        const [totalGreenIndicatorCounts,totalRedIndicatorCounts] = getPropertiesTotalIndicatorCount(filteredProperties);

        lineChartLabels.push(key1 +", " + key2);
        lineChartGreenDataPoints.push(totalGreenIndicatorCounts);
        lineChartRedDataPoints.push(totalRedIndicatorCounts);
    }

    const result = {
        "lineChartLabels" : lineChartLabels,
        "lineChartGreenDataPoints" : lineChartGreenDataPoints,
        "lineChartRedDataPoints" : lineChartRedDataPoints
    };

    return result;

}

const getMostIndicatorCountByZipcode = (zipCountMap,properties) =>{

    const lineChartLabels = [];
    const lineChartGreenDataPoints = [];
    const lineChartRedDataPoints =[];

    for (const [key, value] of zipCountMap) {
        
        const filteredProperties = properties.filter(property => property.zipcode == key);
        
        const [totalGreenIndicatorCounts,totalRedIndicatorCounts] = getPropertiesTotalIndicatorCount(filteredProperties);

        lineChartLabels.push(key);
        lineChartGreenDataPoints.push(totalGreenIndicatorCounts);
        lineChartRedDataPoints.push(totalRedIndicatorCounts);
    }

    const result = {
        "lineChartLabels" : lineChartLabels,
        "lineChartGreenDataPoints" : lineChartGreenDataPoints,
        "lineChartRedDataPoints" : lineChartRedDataPoints
    };

    return result;
}


const getPropertiesTotalIndicatorCount = (properties) => {

    let totalGreenIndicatorCounts = 0;
    let totalRedIndicatorCounts = 0;

    for (let property of properties) {
        
        let {greenIndicatorCount,redIndicatorCount} = getIndicatorCounts(property);

        totalGreenIndicatorCounts = totalGreenIndicatorCounts + greenIndicatorCount;

        totalRedIndicatorCounts = totalRedIndicatorCounts + redIndicatorCount;
    }

    return [totalGreenIndicatorCounts,totalRedIndicatorCounts];
}

const getKPartOfNumber = (num) => {
    
    let number = 0;

    try
    {
        if (num >= 1000) {
            number = parseInt( (num / 1000).toFixed(1).replace(/\.0$/, '') );
        }
    } catch(error)
    {

    }

    return number;
}

const getIndicatorCounts = (property) => {
    
    let greenIndicatorCount = 0;

    let redIndicatorCount = 0;

    try
    {
       if (property.indicators != null)
       {
            for (let indicator of property.indicators) {
                if (indicator.meetsBenchmark == true)
                {
                    greenIndicatorCount = greenIndicatorCount + 1;

                }else
                {
                    redIndicatorCount = redIndicatorCount + 1;
                }
            }
       }
        
    } catch(error)
    {

    }

    return {greenIndicatorCount,redIndicatorCount};
}

/* ----------------------------------------------------------------------------------------- */


//exports.aggregateMetrics = aggregateMetrics;

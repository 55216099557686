import React, { Component } from 'react';

import { Helmet } from "react-helmet";

import { OverlayTrigger,Tooltip,Dropdown, DropdownButton} from "react-bootstrap";

import { InfoCircleFill } from 'react-bootstrap-icons';

import CurrencyInput from '../types/CurrencyInput'

import PercentInput from '../types/PercentInput'

import CustomizedSlider from '../types/CustomizedSlider';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
});

class Settings extends Component {

    state = {
        closingCostsPercent:'5%',
        closingCostsPercentAsNumber:5,
        maintenanceRentPercent:'10%',
        maintenanceRentPercentAsNumber:10,
        propertyManagementPercent:'8%',
        propertyManagementPercentAsNumber:8,
        utilitiesMonthlyCost:'150',
        utilitiesMonthlyCostAsNumber:150,
        maintenanceFlippingCosts:'100',
        maintenanceFlippingCostsAsNumber:100,
        holdingMonths:'6',
        holdingMonthsAsNumber:6,
        bookkeepingMonthlyCost:'200',
        bookkeepingMonthlyCostAsNumber:200,
        taxFilingMonthlyCost:'100',
        taxFilingMonthlyCostAsNumber:100,
        appraisalFeeCost:'300',
        appraisalFeeCostAsNumber:300,
        homeInspectionCost:'400',
        homeInspectionCostAsNumber:400,
        tenantScreeningCost:'120',
        tenantScreeningCostAsNumber:120,
        marketingCost:'145',
        marketingCostAsNumber:145,
        annualHomeAppreciationPercent:'6%',
        annualHomeAppreciationPercentAsNumber:6,
        cosmeticRehabPerSqFt:'15',
        cosmeticRehabPerSqFtAsNumber:15,
        heavyRehabPerSqFt:'45',
        heavyRehabPerSqFtAsNumber:45,
        fullGutRehabPerSqFt:'70',
        fullGutRehabPerSqFtAsNumber:70,
        sellingCostPercent:'9%',
        sellingCostPercentAsNumber:9,

        backEndRatio:'36%',
        backEndRatioAsNumber:36,
        backEndRatioComparison:"Less Than Or Equal To",
        frontEndRatio:'28%',
        frontEndRatioAsNumber:28,
        frontEndRatioComparison:"Less Than Or Equal To",
        debtCoverageRatio:'1.2',
        debtCoverageRatioAsNumber:1.2,
        debtCoverageRatioComparison:"Greater Than Or Equal To",
        loanToValueRatio:'70%',
        loanToValueRatioAsNumber:70,
        loanToValueRatioComparison:"Less Than Or Equal To",
        costOfDebt:'85%',
        costOfDebtAsNumber:85,
        costOfDebtComparison:"Less Than Or Equal To",
        marketValuePercent:'1%',
        marketValuePercentAsNumber:1,
        marketValuePercentComparison:"Greater Than Or Equal To",
        priceToRentRatio:'15',
        priceToRentRatioAsNumber:15,
        priceToRentRatioComparison:"Less Than Or Equal To",
        creditScore:'620',
        creditScoreAsNumber:620,
        creditScoreComparison:"Greater Than Or Equal To",

        grossRentMultiplier:'10',
        grossRentMultiplierAsNumber:10,
        grossRentMultiplierComparison:"Less Than Or Equal To",
        capitalizationRate:'7%',
        capitalizationRateAsNumber:7,
        capitalizationRateComparison:"Greater Than Or Equal To",
        cashOnCashReturn:'10%',
        cashOnCashReturnAsNumber:10,
        cashOnCashReturnComparison:"Greater Than Or Equal To",
        profitabilityIndex:'1.0',
        profitabilityIndexAsNumber:1.0,
        profitabilityIndexComparison:"Greater Than Or Equal To",
        internalRateOfReturn:'10%',
        internalRateOfReturnAsNumber:10,
        internalRateOfReturnComparison:"Greater Than Or Equal To",
        breakEvenRatio:'70%',
        breakEvenRatioAsNumber:70,
        breakEvenRatioComparison:"Less Than Or Equal To",
        netCashFlow:'$0',
        netCashFlowAsNumber:0,
        netCashFlowComparison:"Greater Than Or Equal To",

        ruleOf70:'70%',
        ruleOf70AsNumber:70,
        ruleOf70Comparison:"Less Than Or Equal To",
        keyRiskIndicator:'15%',
        keyRiskIndicatorAsNumber:15,
        keyRiskIndicatorComparison:"Greater Than Or Equal To",
        roi:'10%',
        roiAsNumber:10,
        roiComparison:"Greater Than Or Equal To",
        netProfit:'$0',
        netProfitAsNumber:0,
        netProfitComparison:"Greater Than Or Equal To",

        settings:null,

        uiLoading: false
        
    };


    constructor(props) {
        super(props);

    }

    componentWillMount = () => {

     this.loadSettings();

    };

    reloadPage()
    {
        localStorage.setItem('SettingsStale',"Y");
        localStorage.setItem('CurrentPage', 'settings');
        window.location.reload(false);
    }


    loadSettings()
    {
        this.setState({
            uiLoading: true
          });

        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

        axios
        .get('/api/settings')
        .then((response) => {
  
            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
            {
                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            }

            //console.log(JSON.stringify(response.data));

            if (response.data != null &&  Object.keys(response.data).length > 0)
            {
                const settings = response.data;

                //console.log("DB:");
                //console.log(JSON.stringify(settings));

                this.setState({
                    uiLoading: false
                  });

                this.setState({
                    settings: settings,
                    closingCostsPercent:this.FormatPercent(settings.closingCostsPercent),
                    closingCostsPercentAsNumber:settings.closingCostsPercent,
                    maintenanceRentPercent:this.FormatPercent(settings.maintenanceRentPercent),
                    maintenanceRentPercentAsNumber:settings.maintenanceRentPercent,
                    propertyManagementPercent:this.FormatPercent(settings.propertyManagementPercent),
                    propertyManagementPercentAsNumber:settings.propertyManagementPercent,
                    utilitiesMonthlyCost:this.FormatMoney(settings.utilitiesMonthlyCost),
                    utilitiesMonthlyCostAsNumber:settings.utilitiesMonthlyCost,
                    maintenanceFlippingCosts:this.FormatMoney(settings.maintenanceFlippingCosts),
                    maintenanceFlippingCostsAsNumber:settings.maintenanceFlippingCosts,
                    holdingMonths:settings.holdingMonths,
                    holdingMonthsAsNumber:settings.holdingMonths,
                    bookkeepingMonthlyCost:this.FormatMoney(settings.bookkeepingMonthlyCost),
                    bookkeepingMonthlyCostAsNumber:settings.bookkeepingMonthlyCost,
                    taxFilingMonthlyCost:this.FormatMoney(settings.taxFilingMonthlyCost),
                    taxFilingMonthlyCostAsNumber:settings.taxFilingMonthlyCost,
                    appraisalFeeCost:this.FormatMoney(settings.appraisalFeeCost),
                    appraisalFeeCostAsNumber:settings.appraisalFeeCost,
                    homeInspectionCost:this.FormatMoney(settings.homeInspectionCost),
                    homeInspectionCostAsNumber:settings.homeInspectionCost,
                    tenantScreeningCost:this.FormatMoney(settings.tenantScreeningCost),
                    tenantScreeningCostAsNumber:settings.tenantScreeningCost,
                    marketingCost:this.FormatMoney(settings.marketingCost),
                    marketingCostAsNumber:settings.marketingCost,
                    annualHomeAppreciationPercent:this.FormatPercent(settings.annualHomeAppreciationPercent),
                    annualHomeAppreciationPercentAsNumber:settings.annualHomeAppreciationPercent,
                    cosmeticRehabPerSqFt:this.FormatMoney(settings.cosmeticRehabPerSqFt),
                    cosmeticRehabPerSqFtAsNumber:settings.cosmeticRehabPerSqFt,
                    heavyRehabPerSqFt:this.FormatMoney(settings.heavyRehabPerSqFt),
                    heavyRehabPerSqFtAsNumber:settings.heavyRehabPerSqFt,
                    fullGutRehabPerSqFt:this.FormatMoney(settings.fullGutRehabPerSqFt),
                    fullGutRehabPerSqFtAsNumber:settings.fullGutRehabPerSqFt,
                    sellingCostPercent:this.FormatPercent(settings.sellingCostPercent),
                    sellingCostPercentAsNumber:settings.sellingCostPercent,

                    backEndRatio:this.FormatPercent(settings.backEndRatio),
                    backEndRatioAsNumber:settings.backEndRatio,
                    backEndRatioComparison:settings.backEndRatioComparison,
                    frontEndRatio:this.FormatPercent(settings.frontEndRatio),
                    frontEndRatioAsNumber:settings.frontEndRatio,
                    frontEndRatioComparison:settings.frontEndRatioComparison,
                    debtCoverageRatio:this.FormatNumber(settings.debtCoverageRatio),
                    debtCoverageRatioAsNumber:settings.debtCoverageRatio,
                    debtCoverageRatioComparison:settings.debtCoverageRatioComparison,
                    loanToValueRatio:this.FormatPercent(settings.loanToValueRatio),
                    loanToValueRatioAsNumber:settings.loanToValueRatio,
                    loanToValueRatioComparison:settings.loanToValueRatioComparison,
                    costOfDebt:this.FormatPercent(settings.costOfDebt),
                    costOfDebtAsNumber:settings.costOfDebt,
                    costOfDebtComparison:settings.costOfDebtComparison,
                    marketValuePercent:this.FormatPercent(settings.marketValuePercent),
                    marketValuePercentAsNumber:settings.marketValuePercent,
                    marketValuePercentComparison:settings.marketValuePercentComparison,
                    priceToRentRatio:this.FormatNumber(settings.priceToRentRatio),
                    priceToRentRatioAsNumber:settings.priceToRentRatio,
                    priceToRentRatioComparison:settings.priceToRentRatioComparison,
                    creditScore:settings.creditScore.toString(),
                    creditScoreAsNumber:settings.creditScore,
                    creditScoreComparison:settings.creditScoreComparison,

                    grossRentMultiplier:this.FormatNumber(settings.grossRentMultiplier),
                    grossRentMultiplierAsNumber:settings.grossRentMultiplier,
                    grossRentMultiplierComparison:settings.grossRentMultiplierComparison,
                    capitalizationRate:this.FormatPercent(settings.capitalizationRate),
                    capitalizationRateAsNumber:settings.capitalizationRate,
                    capitalizationRateComparison:settings.capitalizationRateComparison,
                    cashOnCashReturn:this.FormatPercent(settings.cashOnCashReturn),
                    cashOnCashReturnAsNumber:settings.cashOnCashReturn,
                    cashOnCashReturnComparison:settings.cashOnCashReturnComparison,
                    profitabilityIndex:this.FormatNumber(settings.profitabilityIndex),
                    profitabilityIndexAsNumber:settings.profitabilityIndex,
                    profitabilityIndexComparison:settings.profitabilityIndexComparison,
                    internalRateOfReturn:this.FormatPercent(settings.internalRateOfReturn),
                    internalRateOfReturnAsNumber:settings.internalRateOfReturn,
                    internalRateOfReturnComparison:settings.internalRateOfReturnComparison,
                    breakEvenRatio:this.FormatPercent(settings.breakEvenRatio),
                    breakEvenRatioAsNumber:settings.breakEvenRatio,
                    breakEvenRatioComparison:settings.breakEvenRatioComparison,
                    netCashFlow:this.FormatMoney(settings.netCashFlow),
                    netCashFlowAsNumber:settings.netCashFlow,
                    netCashFlowComparison:settings.netCashFlowComparison,

                    ruleOf70:this.FormatMoney(settings.ruleOf70),
                    ruleOf70AsNumber:settings.ruleOf70,
                    ruleOf70Comparison:settings.ruleOf70Comparison,
                    keyRiskIndicator:this.FormatPercent(settings.keyRiskIndicator),
                    keyRiskIndicatorAsNumber:settings.keyRiskIndicator,
                    keyRiskIndicatorComparison:settings.keyRiskIndicatorComparison,
                    roi:this.FormatPercent(settings.roi),
                    roiAsNumber:settings.roi,
                    roiComparison:settings.roiComparison,
                    netProfit:this.FormatMoney(settings.netProfit),
                    netProfitAsNumber:settings.netProfit,
                    netProfitComparison:settings.netProfitComparison
                });

                if (this.props.parentRef != null)
                {
                    this.props.parentRef.setState({
                        settings: settings
                    });

                }
            }
            
        })
        .catch((error) => {
            console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
                } else 
                {
                    this.setState({
                        uiLoading: false
                      });
                }
        });

        
    };

    FormatNumber(value)
    {
        var number = "0";

        try 
        {
        number = Intl.NumberFormat().format(value)

        } catch (error) {
        }

        return number;
    }

    FormatMoney(value)
    {
        let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        });


        let formattedValue = dollarUS.format(value);

        if (formattedValue.endsWith(".00"))
        {
        formattedValue = formattedValue.substring(0, formattedValue.length-3);
        }
        //alert(formattedValue);

        return formattedValue;
    }

    FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }

  handleNumberInputChange = (event) => {
  
    var numberVar = event.target.value.replace(/[^\d.-]/g, '');
  
    numberVar = numberVar.replace('....','.').replace('...','..').replace('..','.');

    var formattedValue = Intl.NumberFormat().format(numberVar);
    
    this.setState({
      [event.target.name]: formattedValue
    });

    var key = event.target.name+"AsNumber"
    var val = Number(numberVar);  
    var obj  = {}
    obj[key] = val
    this.setState(obj, () => {
      // Do anything after the state has changed   


  });
  
  };

  HandleCurrencyEnterFocus(event) {

    //console.log(event.target.name);

    let key = event.target.name;

    if (key.endsWith("Column"))
    {
        key = key.replace("Column","");
    }

    if (this.state[key] =="0" || this.state[key] =="$0")
    {
  
      document.getElementById(event.target.name).value = "";
      
      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandleCurrencyExitFocus(event) {
   
    let key = event.target.name;

    if (key.endsWith("Column"))
    {
        key = key.replace("Column","");
    }

    if (this.state[key] =="" || this.state[key] ==" ")
    {
  
      document.getElementById(event.target.name).value = "$0";
      
      var obj  = {}
      obj[key] = 0
      this.setState(obj);
    }

  }

  HandlePercentEnterFocus(event) {

    if (this.state[event.target.name] =="0" || this.state[event.target.name] =="0%")
    {
      const key = event.target.name;
      const keyFormatted = key+"AsNumber";
      document.getElementById(key).value = "";

      var obj  = {}
      obj[key] = ""
      this.setState(obj);
    }
  }

  HandlePercentExitFocus(event) {
   
    if (this.state[event.target.name] =="" || this.state[event.target.name] ==" ")
    {
      const key = event.target.name;
      document.getElementById(key).value = "0%";

      var obj  = {}
      obj[key] = 0
      this.setState(obj);
    }

  }

  ChangeCustomCurrencySliderValue = (event, value) => {

    let name = event.target.id;

    if (name =="" || name == null)
    {
        const nodeMap = event.target.parentElement.attributes;

        for (let i = 0; i < nodeMap.length; i++) {

          if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
          {
            name = nodeMap[i].value;
          }
        }
  
    }

    if (name.endsWith("InputCashFlowColumnAsNumber"))
    {
      name = name.replace("Input","");
    }

    //console.log("HERE- NAME:");
    //console.log(name);

    //console.log("HERE- VALUE:");
    //console.log(value);

    event.target["name"] = name;
    event.target["value"] = value;

    this.ChangeCurrencySliderValue(event);

  };

    ChangeCurrencySliderValue(event) {
  
        let key = event.target.name;
    
        var keyFormatted = key.replace('AsNumber','');
        
        var value = event.target.value;
      
        var obj  = {}
        obj[key] = value
        this.setState(obj, () => {
          // Do anything after the state has changed   
       
        });
    
        var obj  = {}
        obj[keyFormatted] = value
        this.setState(obj)
        
    }
    
    ChangeCustomPercentSliderValue = (event, value) => {

        let name = event.target.id;
    
        if (name =="" || name == null)
        {
            const nodeMap = event.target.parentElement.attributes;
    
            for (let i = 0; i < nodeMap.length; i++) {
    
              if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
              {
                name = nodeMap[i].value;
              }
            }
      
        }
    
        //console.log("HERE- NAME:");
        //console.log(name);
    
        event.target["name"] = name;
        event.target["value"] = value;
    
        this.ChangePercentSliderValue(event);
      }
    
      ChangePercentSliderValue(event) {
  
        var key = event.target.name;
        var keyFormatted = key.replace('AsNumber','');
        
        var value = event.target.value;
    
        var obj  = {}
        obj[key] = value
        this.setState(obj, () => {
          // Do anything after the state has changed   
       
      });
    
        var obj  = {}
        obj[keyFormatted] = value+"%"
        this.setState(obj)
        
    }

    HandleSectionExpand(event,obj){
        
        let name = event.target.id;
        name = name.replace("Plus","");

        //alert(name);

        var value = document.querySelector('#'+name+'Plus').innerHTML;

        if (value =="+")
        {
          document.querySelector('#'+name+'Plus').innerHTML = "-";
    
          document.querySelector('#'+name+'Section').style.display = "block";
          
        } else
        {
          document.querySelector('#'+name+'Plus').innerHTML = "+";
    
          document.querySelector('#'+name+'Section').style.display = "none";
        }

    }

    ChangeCustomNumberSliderValue = (event, value) => {

        let name = event.target.id;
    
        if (name =="" || name == null)
        {
            const nodeMap = event.target.parentElement.attributes;
    
            for (let i = 0; i < nodeMap.length; i++) {
    
              if (nodeMap[i].name == "name" || nodeMap[i].name == "id")
              {
                name = nodeMap[i].value;
              }
            }
      
        }
    
        //console.log("HERE- NAME:");
        //console.log(name);
    
        event.target["name"] = name;
        event.target["value"] = value;
    
        this.ChangeNumberSliderValue(event);
      }

    ChangeNumberSliderValue(event) {
  
        var key = event.target.name;
        var keyFormatted = key.replace('AsNumber','');
        
        var value = event.target.value;
    
        var obj  = {}
        obj[key] = value
        this.setState(obj, () => {
          // Do anything after the state has changed   
         
      });
    
        var obj  = {}
        obj[keyFormatted] = Intl.NumberFormat().format(value);
        this.setState(obj, () => {
          // Do anything after the state has changed   
          if (event.target.name.startsWith("livingAreaSize"))
          {
              this.CalculateRehabCosts();
          }
        });
        
      }

    HandleInputChange = (event) => {

    var formattedValue = event.target.value;
    // if (formattedValue =="") // This is not needed and instruces a bug
    // {
    //   formattedValue = "$0"; // This is not needed and instruces a bug
    // }

    let propertyName = event.target.name;

    this.setState({
        [propertyName]: formattedValue
    }, () => {
    
        //If number currency only use number value
        if (formattedValue.startsWith("$"))
        {
            var key = propertyName+"AsNumber"
            var val = Number(formattedValue.replace(/[^0-9\.-]+/g,""));  
            var obj  = {}
            obj[key] = val
            this.setState(obj, () => {
                // Do anything after the state has changed   
                
            });
        
        } 

    });

    
    
    };
        
    HandlePercentInputChange = (event) => {
  
        var numberVar = event.target.value.replace(/[^\d.-]/g, '');
      
        numberVar = numberVar.replace('....','.').replace('...','..').replace('..','.');
    
        var formattedValue = numberVar
    
        if(event.target.value.endsWith("%"))
        {
          formattedValue = numberVar+"%";
        }
        
        this.setState({
          [event.target.name]: formattedValue
        });
    
        var key = event.target.name+"AsNumber"
        var val = Number(numberVar);  
        var obj  = {}
        obj[key] = val
        this.setState(obj, () => {
      
       
        });
        
        //Delay putting % if it was removed, this is to allow user to remove the % when typing it
        if(!formattedValue.endsWith("%"))
        {
              setTimeout(
                function() {
                    formattedValue = document.querySelector('#'+event.target.name).value
    
                    if(!formattedValue.endsWith("%"))
                    {
                      formattedValue = formattedValue+"%";
                      this.setState({
                        [event.target.name]: formattedValue
                      });
                    }
                }
                .bind(this),
                10000
            );
        }
    
      }
    

      HandleNumberInputChange = (event) => {
  
        var numberVar = event.target.value.replace(/[^\d.-]/g, '');
      
        numberVar = numberVar.replace('....','.').replace('...','..').replace('..','.');
    
        var formattedValue = numberVar
        
        this.setState({
          [event.target.name]: formattedValue
        });
    
        var key = event.target.name+"AsNumber"
        var val = Number(numberVar);  
        var obj  = {}
        obj[key] = val
        this.setState(obj, () => {
      
       
        });
      }

      HandleSelect(event) {

        var elements = event.split(",");
        var key = elements[0];
        var value = elements[1];
    
        var obj  = {}
        obj[key] = value
        this.setState(obj, () => {
          // Do anything after the state has changed   

        });
    
    
      }


    SaveSettings(event) {

        event.preventDefault();

        const settings = this.GetSettings();

        this.setState({
            uiLoading: true
          });

        if (this.state.settings == null)  //Insert
        {
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

          axios
            .post('/api/createSetting', settings)
            .then((response) => {
              
            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
            {
                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            }

              console.log("Completed creating setting");

              this.setState({
                uiLoading: false
              });

              this.loadSettings();  
            })
            .catch((error) => {

              alert(error);
              console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
                } else 
                {
                    this.setState({
                        uiLoading: false
                      });
                }
              
            });
    
          } else  //Update 
          {
            const id = this.state.settings.id;
    
            const payload = {
              id:id,
              setting:settings
            }
    
            //console.log(JSON.stringify(payload));

            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken }; 
            
            axios
            .post('/api/updateSetting', payload)
            .then((response) => {
              
                if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

              console.log("Completed updating setting");

              this.setState({
                uiLoading: false
              });

              this.loadSettings();

              this.reloadPage(); //This one will refresh the page, which needed for the settings to be popagated to the oter pages that need them.

            })
            .catch((error) => {
                console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                    localStorage.setItem('SessionExpired',"Y");
                    //window.location.reload();
                    window.location.href = '/login';
                } else 
                {
                    this.setState({
                        uiLoading: false
                      });
                }
              
            });
    
          }
    
    }

    GetSettings()
    {
        const settings = {
            "closingCostsPercent":this.state.closingCostsPercentAsNumber,
            "maintenanceRentPercent":this.state.maintenanceRentPercentAsNumber,
            "propertyManagementPercent":this.state.propertyManagementPercentAsNumber,
            "utilitiesMonthlyCost":this.state.utilitiesMonthlyCostAsNumber,
            "maintenanceFlippingCosts":this.state.maintenanceFlippingCostsAsNumber,
            "holdingMonths":parseInt(this.state.holdingMonthsAsNumber),
            "bookkeepingMonthlyCost":this.state.bookkeepingMonthlyCostAsNumber,
            "taxFilingMonthlyCost":this.state.taxFilingMonthlyCostAsNumber,
            "appraisalFeeCost":this.state.appraisalFeeCostAsNumber,
            "homeInspectionCost":this.state.homeInspectionCostAsNumber,
            "tenantScreeningCost":this.state.tenantScreeningCostAsNumber,
            "marketingCost":this.state.marketingCostAsNumber,
            "annualHomeAppreciationPercent":this.state.annualHomeAppreciationPercentAsNumber,
            "cosmeticRehabPerSqFt":this.state.cosmeticRehabPerSqFtAsNumber,
            "heavyRehabPerSqFt":this.state.heavyRehabPerSqFtAsNumber,
            "fullGutRehabPerSqFt":this.state.fullGutRehabPerSqFtAsNumber,
            "sellingCostPercent":this.state.sellingCostPercentAsNumber,
            "backEndRatio":this.state.backEndRatioAsNumber,
            "backEndRatioComparison":this.state.backEndRatioComparison,
            "frontEndRatio":this.state.frontEndRatioAsNumber,
            "frontEndRatioComparison":this.state.frontEndRatioComparison,
            "debtCoverageRatio":this.state.debtCoverageRatioAsNumber,
            "debtCoverageRatioComparison":this.state.debtCoverageRatioComparison,
            "loanToValueRatio":this.state.loanToValueRatioAsNumber,
            "loanToValueRatioComparison":this.state.loanToValueRatioComparison,
            "costOfDebt":this.state.costOfDebtAsNumber,
            "costOfDebtComparison":this.state.costOfDebtComparison,
            "marketValuePercent":this.state.marketValuePercentAsNumber,
            "marketValuePercentComparison":this.state.marketValuePercentComparison,
            "priceToRentRatio":this.state.priceToRentRatioAsNumber,
            "priceToRentRatioComparison":this.state.priceToRentRatioComparison,
            "creditScore":this.state.creditScoreAsNumber,
            "creditScoreComparison":this.state.creditScoreComparison,
            "grossRentMultiplier":this.state.grossRentMultiplierAsNumber,
            "grossRentMultiplierComparison":this.state.grossRentMultiplierComparison,
            "capitalizationRate":this.state.capitalizationRateAsNumber,
            "capitalizationRateComparison":this.state.capitalizationRateComparison,
            "cashOnCashReturn":this.state.cashOnCashReturnAsNumber,
            "cashOnCashReturnComparison":this.state.cashOnCashReturnComparison,
            "profitabilityIndex":this.state.profitabilityIndexAsNumber,
            "profitabilityIndexComparison":this.state.profitabilityIndexComparison,
            "internalRateOfReturn":this.state.internalRateOfReturnAsNumber,
            "internalRateOfReturnComparison":this.state.internalRateOfReturnComparison,
            "breakEvenRatio":this.state.breakEvenRatioAsNumber,
            "breakEvenRatioComparison":this.state.breakEvenRatioComparison,
            "netCashFlow":this.state.netCashFlowAsNumber,
            "netCashFlowComparison":this.state.netCashFlowComparison,
            "ruleOf70":this.state.ruleOf70AsNumber,
            "ruleOf70Comparison":this.state.ruleOf70Comparison,
            "keyRiskIndicator":this.state.keyRiskIndicatorAsNumber,
            "keyRiskIndicatorComparison":this.state.keyRiskIndicatorComparison,
            "roi":this.state.roiAsNumber,
            "roiComparison":this.state.roiComparison,
            "netProfit":this.state.netProfitAsNumber,
            "netProfitComparison":this.state.netProfitComparison
    
        };

        //console.log(JSON.stringify(settings));
      
        return settings;
    }
    


    render() {

        const { classes } = this.props;


        return (

            <div>

                <div class="my-property-heading">
                    <h1 class="dashboard-heading">Settings</h1>
                </div>


        {/* Default Values */}
        <div class="general-heading">
                    <h2 style={{fontSize:'22px'}}> <span class="circle"></span> Default Values</h2>
                </div>


                <div id="defaultGeneral" style={{paddingBottom:'10px',marginBottom:'0px'}} className="subsectionLabel" onClick={e => this.HandleSectionExpand(e, this)}>GENERAL<span id="defaultGeneralPlus" className="plusSign">+</span></div>
                               
                <section class="setting-section-one" id="defaultGeneralSection" style={{display:'none'}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                        
                                <div class="row">
                                            <div class="col-md-4">


                                                {/* Closing Costs % */}
                                                <div class="form-group setting-form">
                                                    
                                                        <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip  className="messagetooltipWhite">
                                                            
                                                                <div className='helpText'>Closing Costs %.</div>
                                                            </Tooltip>}
                                                            >
                                                        
                                                            <div>
                                                                    <label class="form-check-label" for="closingCostsPercent">Closing Costs %</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                            </div>
                                                        </OverlayTrigger>
                                                        <table>
                                                            <tr>
                                                                <td  class="compound-column-width">
                                                            <PercentInput type="text" class="form-select setting-edit-component" id="closingCostsPercent" placeholder="%" name="closingCostsPercent" defaultValue={this.state.closingCostsPercent} value={this.state.closingCostsPercent} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                            <span class="desktop-only-block">    
                                                                <input type="range" className="custom-range" id="closingCostsPercentAsNumber" name="closingCostsPercentAsNumber" min="0" max="50" step=".5" value={this.state.closingCostsPercentAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="closingCostsPercentAsNumber" name="closingCostsPercentAsNumber" min={0} max={50} step={.5} value={this.state.closingCostsPercentAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>
                                                            &nbsp;

                                                            </td>
                                                            </tr>
                                                            </table>

                                                </div> 
                                            </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <div id="defaultRental" style={{paddingBottom:'10px',marginBottom:'0px'}} className="subsectionLabel" onClick={e => this.HandleSectionExpand(e, this)}>RENTAL PROPERTIES<span id="defaultRentalPlus" className="plusSign">+</span></div>
                 
                <section class="setting-section-one" id="defaultRentalSection" style={{display:'none'}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                     
                                        <div class="row">
                                            <div class="col-md-4">


                                                {/* Annual Home Appreciation % */}
                                                <div class="form-group setting-form">
                                                    
                                                        <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip  className="messagetooltipWhite">
                                                            
                                                                <div className='helpText'>Annual Home Appreciation %.</div>
                                                            </Tooltip>}
                                                            >
                                                            <div>
                                                                    <label class="form-check-label" for="annualHomeAppreciationPercent">Annual Home Appreciation %</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                            </div>
                                                        </OverlayTrigger>
                                                        <table>
                                                            <tr>
                                                                <td  class="compound-column-width">
                                                            <PercentInput type="text" class="form-select setting-edit-component" id="annualHomeAppreciationPercent" placeholder="%" name="annualHomeAppreciationPercent" defaultValue={this.state.annualHomeAppreciationPercent} value={this.state.annualHomeAppreciationPercent} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                            <span class="desktop-only-block">    
                                                                <input type="range" className="custom-range" id="annualHomeAppreciationPercentAsNumber" name="annualHomeAppreciationPercentAsNumber" min="0" max="50" step=".5" value={this.state.annualHomeAppreciationPercentAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="annualHomeAppreciationPercentAsNumber" name="annualHomeAppreciationPercentAsNumber" min={0} max={50} step={.5} value={this.state.annualHomeAppreciationPercentAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>
                                                            
                                                            </td>
                                                            <td style={{width:'20%'}}>
                                                            &nbsp;

                                                            </td>
                                                            </tr>
                                                            </table>

                                                </div> 

                                                {/* Appraisal Fee Cost */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Appraisal Fee Cost.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="appraisalFeeCost">Appraisal Fee Cost</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="appraisalFeeCost" placeholder="$0" name="appraisalFeeCost" defaultValue={this.state.appraisalFeeCost} value={this.state.appraisalFeeCost} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="appraisalFeeCostAsNumber" name="appraisalFeeCostAsNumber" min="0" max="10000" step="100" value={this.state.appraisalFeeCostAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="appraisalFeeCostAsNumber" name="appraisalFeeCostAsNumber" min={0} max={10000} step={100} value={this.state.appraisalFeeCostAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>


                                                {/* Home Inspection Cost */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Home Inspection Cost.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="homeInspectionCost">Home Inspection Cost</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="homeInspectionCost" placeholder="$0" name="homeInspectionCost" defaultValue={this.state.homeInspectionCost} value={this.state.homeInspectionCost} onChange={this.HandleInputChange}/>
                                                            
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="homeInspectionCostAsNumber" name="homeInspectionCostAsNumber" min="0" max="10000" step="100" value={this.state.homeInspectionCostAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="homeInspectionCostAsNumber" name="homeInspectionCostAsNumber" min={0} max={10000} step={100} value={this.state.homeInspectionCostAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                            <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>


                                                {/* Maintenance Rent Percent */}
                                                <div class="form-group setting-form">
                                                    
                                                        <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip  className="messagetooltipWhite">
                                                            
                                                                <div className='helpText'>Maintenance Cost %.</div>
                                                            </Tooltip>}
                                                            >
                                                            <div>
                                                                    <label class="form-check-label" for="maintenanceRentPercent">Maintenance Cost %</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                            </div>
                                                        </OverlayTrigger>
                                                        <table>
                                                            <tr>
                                                                <td  class="compound-column-width">
                                                            <PercentInput type="text" class="form-select setting-edit-component" id="maintenanceRentPercent" placeholder="%" name="maintenanceRentPercent" defaultValue={this.state.maintenanceRentPercent} value={this.state.maintenanceRentPercent} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="maintenanceRentPercentAsNumber" name="maintenanceRentPercentAsNumber" min="0" max="50" step=".5" value={this.state.maintenanceRentPercentAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="maintenanceRentPercentAsNumber" name="maintenanceRentPercentAsNumber" min={0} max={50} step={.5} value={this.state.maintenanceRentPercentAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>
                                                            &nbsp;

                                                            </td>
                                                            </tr>
                                                            </table>

                                                </div>


                                                {/* Marketing Cost */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Marketing Cost.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="marketingCost">Marketing Cost</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="marketingCost" placeholder="$0" name="marketingCost" defaultValue={this.state.marketingCost} value={this.state.marketingCost} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="marketingCostAsNumber" name="marketingCostAsNumber" min="0" max="10000" step="100" value={this.state.marketingCostAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="marketingCostAsNumber" name="marketingCostAsNumber" min={0} max={10000} step={100} value={this.state.marketingCostAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                            </div>
                                            
                                            <div class="col-md-4">      
                                                                                                    
                                                {/* Monthly Bookkeeping Costs */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Monthly Bookkeeping Costs.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="bookkeepingMonthlyCost">Monthly Bookkeeping Costs</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="bookkeepingMonthlyCost" placeholder="$0" name="bookkeepingMonthlyCost" defaultValue={this.state.bookkeepingMonthlyCost} value={this.state.bookkeepingMonthlyCost} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="bookkeepingMonthlyCostAsNumber" name="bookkeepingMonthlyCostAsNumber" min="0" max="10000" step="100" value={this.state.bookkeepingMonthlyCostAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="bookkeepingMonthlyCostAsNumber" name="bookkeepingMonthlyCostAsNumber" min={0} max={10000} step={100} value={this.state.bookkeepingMonthlyCostAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                {/* Utilities Monthly Cost */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Monthly Utility Costs.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="utilitiesMonthlyCost">Monthly Utility Costs</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="utilitiesMonthlyCost" placeholder="$0" name="utilitiesMonthlyCost" defaultValue={this.state.utilitiesMonthlyCost} value={this.state.utilitiesMonthlyCost} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="utilitiesMonthlyCostAsNumber" name="utilitiesMonthlyCostAsNumber" min="0" max="10000" step="100" value={this.state.utilitiesMonthlyCostAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="utilitiesMonthlyCostAsNumber" name="utilitiesMonthlyCostAsNumber" min={0} max={10000} step={100} value={this.state.utilitiesMonthlyCostAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                {/* Property Management Percent */}
                                                <div class="form-group setting-form">
                                                    
                                                    <OverlayTrigger
                                                            placement="auto"
                                                            delay={{ show: 150, hide: 100 }} 
                                                            trigger={["hover","click"]}
                                                            overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Property Management %.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="propertyManagementPercent">Property Management %</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    <table>
                                                        <tr>
                                                            <td  class="compound-column-width">
                                                        <input type="text" class="form-select setting-edit-component" id="propertyManagementPercent" placeholder="%" name="propertyManagementPercent" defaultValue={this.state.propertyManagementPercent} value={this.state.propertyManagementPercent} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                        <span class="desktop-only-block">
                                                            <input type="range" className="custom-range" id="propertyManagementPercentAsNumber" name="propertyManagementPercentAsNumber" min="0" max="50" step=".5" value={this.state.propertyManagementPercentAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider id="propertyManagementPercentAsNumber" name="propertyManagementPercentAsNumber" min={0} max={50} step={.5} value={this.state.propertyManagementPercentAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>
                                                        &nbsp;

                                                        </td>
                                                        </tr>
                                                        </table>

                                                </div>

                                                {/* Tax Filing Monthly Cost */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Tax Filing Monthly Cost.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="taxFilingMonthlyCost">Tax Filing Monthly Cost</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="taxFilingMonthlyCost" placeholder="$0" name="taxFilingMonthlyCost" defaultValue={this.state.taxFilingMonthlyCost} value={this.state.taxFilingMonthlyCost} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="taxFilingMonthlyCostAsNumber" name="taxFilingMonthlyCostAsNumber" min="0" max="10000" step="100" value={this.state.taxFilingMonthlyCostAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="taxFilingMonthlyCostAsNumber" name="taxFilingMonthlyCostAsNumber" min={0} max={10000} step={100} value={this.state.taxFilingMonthlyCostAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                {/* Tenant Screening Cost */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Tenant Screening Cost.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="tenantScreeningCost">Tenant Screening Cost</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="tenantScreeningCost" placeholder="$0" name="tenantScreeningCost" defaultValue={this.state.tenantScreeningCost} value={this.state.tenantScreeningCost} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="tenantScreeningCostAsNumber" name="tenantScreeningCostAsNumber" min="0" max="10000" step="100" value={this.state.tenantScreeningCostAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="tenantScreeningCostAsNumber" name="tenantScreeningCostAsNumber" min={0} max={10000} step={100} value={this.state.tenantScreeningCostAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                </section>

                <div id="defaultFlipping" style={{paddingBottom:'10px',marginBottom:'0px'}} className="subsectionLabel" onClick={e => this.HandleSectionExpand(e, this)}>FLIPPING PROPERTIES<span id="defaultFlippingPlus" className="plusSign">+</span></div>
              
                <section class="setting-section-one" id="defaultFlippingSection" style={{display:'none'}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">

                <div class="preferred-heading">
                                            <h2>Flipping Default Values</h2>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">

                                    

                                                {/* Cosmetic Rehab Per SqFt */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Cosmetic Rehab Per SqFt.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="cosmeticRehabPerSqFt">Cosmetic Rehab Per SqFt</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="cosmeticRehabPerSqFt" placeholder="$0" name="cosmeticRehabPerSqFt" defaultValue={this.state.cosmeticRehabPerSqFt} value={this.state.cosmeticRehabPerSqFt} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="cosmeticRehabPerSqFtAsNumber" name="cosmeticRehabPerSqFtAsNumber" min="0" max="10000" step="100" value={this.state.cosmeticRehabPerSqFtAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>
                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="cosmeticRehabPerSqFtAsNumber" name="cosmeticRehabPerSqFtAsNumber" min={0} max={10000} step={100} value={this.state.cosmeticRehabPerSqFtAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                {/* Heavy Rehab Per SqFt */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Heavy Rehab Per SqFt.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="heavyRehabPerSqFt">Heavy Rehab Per SqFt</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="heavyRehabPerSqFt" placeholder="$0" name="heavyRehabPerSqFt" defaultValue={this.state.heavyRehabPerSqFt} value={this.state.heavyRehabPerSqFt} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="heavyRehabPerSqFtAsNumber" name="heavyRehabPerSqFtAsNumber" min="0" max="10000" step="100" value={this.state.heavyRehabPerSqFtAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="heavyRehabPerSqFtAsNumber" name="heavyRehabPerSqFtAsNumber" min={0} max={10000} step={100} value={this.state.heavyRehabPerSqFtAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>
                                                            
                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

        {/* Flipping Maintenace Cost */}
        <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Monthly Maintenace Cost.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="maintenanceFlippingCosts">Monthly Maintenace Cost</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="maintenanceFlippingCosts" placeholder="$0" name="maintenanceFlippingCosts" defaultValue={this.state.maintenanceFlippingCosts} value={this.state.maintenanceFlippingCosts} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="maintenanceFlippingCostsAsNumber" name="maintenanceFlippingCostsAsNumber" min="0" max="10000" step="100" value={this.state.maintenanceFlippingCostsAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="maintenanceFlippingCostsAsNumber" name="maintenanceFlippingCostsAsNumber" min={0} max={10000} step={100} value={this.state.maintenanceFlippingCostsAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>
                                                            
                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                    
                                            </div>
                                            
                                            <div class="col-md-4">
    
                                                {/* Full Gut Rehab Per SqFt*/}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                            <div className='helpText'>Full Gut Rehab Per SqFt.</div>
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="fullGutRehabPerSqFt">Full Gut Rehab Per SqFt</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <CurrencyInput type="text" class="form-select setting-edit-component" id="fullGutRehabPerSqFt" placeholder="$0" name="fullGutRehabPerSqFt" defaultValue={this.state.fullGutRehabPerSqFt} value={this.state.fullGutRehabPerSqFt} onChange={this.HandleInputChange}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="fullGutRehabPerSqFtAsNumber" name="fullGutRehabPerSqFtAsNumber" min="0" max="10000" step="100" value={this.state.fullGutRehabPerSqFtAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="fullGutRehabPerSqFtAsNumber" name="fullGutRehabPerSqFtAsNumber" min={0} max={10000} step={100} value={this.state.fullGutRehabPerSqFtAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>


                                                {/* Selling Cost % */}
                                                <div class="form-group setting-form">
                                                    
                                                        <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip  className="messagetooltipWhite">
                                                            
                                                                <div className='helpText'>Selling Cost %.</div>
                                                            </Tooltip>}
                                                            >
                                                            <div>
                                                                    <label class="form-check-label" for="sellingCostPercent">Selling Cost %</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                            </div>
                                                        </OverlayTrigger>
                                                        <table>
                                                            <tr>
                                                                <td  class="compound-column-width">
                                                            <PercentInput type="text" class="form-select setting-edit-component" id="sellingCostPercent" placeholder="%" name="sellingCostPercent" defaultValue={this.state.sellingCostPercent} value={this.state.sellingCostPercent} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="sellingCostPercentAsNumber" name="sellingCostPercentAsNumber" min="0" max="50" step=".5" value={this.state.sellingCostPercentAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="sellingCostPercentAsNumber" name="sellingCostPercentAsNumber" min={0} max={50} step={.5} value={this.state.sellingCostPercentAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>
                                                            &nbsp;

                                                            </td>
                                                            </tr>
                                                            </table>

                                                </div> 
                                                
                                                {/* Holding Months*/}
                                                <div class="form-group setting-form">
                                                                    <OverlayTrigger
                                                                            placement="auto"
                                                                            delay={{ show: 150, hide: 100 }} 
                                                                            trigger={["hover","click"]}
                                                                            overlay={<Tooltip className="messagetooltip">
                                                                        
                                                                            <div className='helpText'>
                                                                            <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                The number of months you plan to hold the property before reselling it.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                Note that most lenders won’t lend on properties owned for less than 90 days. Which means buyers might not be able to get a loan to buy your property.
                                                                                </div>

                                                                                <div style={{paddingBottom:'9px',maxWidth:'520px'}}>
                                                                                    The number of months you will hold property impacts many financial indicator calculations.
                                                                                </div>
                                                                                
                                                                                </div>
                                                                        </Tooltip>}
                                                                        >
                                                                        <div>
                                                                                <label class="dialog-form-label" for="holdingMonthsAsNumber">Holding Months</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                        </div>
                                                                        </OverlayTrigger>

                                                                        <table>
                                                                            <tr>
                                                                                <td  class="compound-column-width">
                                                                            <input type="text" inputmode="numeric" class="form-select setting-edit-component" id="holdingMonths" placeholder="" name="holdingMonths" defaultValue={this.state.holdingMonths} value={this.state.holdingMonths} onChange={this.handleNumberInputChange}/>
                                                                                    
                                                                            
                                                                            <span class="desktop-only-block">  
                                                                                        <input type="range" className="custom-range" id="holdingMonthsAsNumber" name="holdingMonthsAsNumber" min="0" max="24" step="1" value={this.state.holdingMonthsAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                                                        </span>

                                                                                        <span class="mobile-only-block">
                                                                                            <CustomizedSlider id="holdingMonthsAsNumber" name="holdingMonthsAsNumber" min={0} max={24} step={1} value={this.state.holdingMonthsAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                                                        <br></br>     
                                                                                        </span>
                

                                                                            </td>
                                                                            <td style={{width:'20%'}}>
                                                                            &nbsp;

                                                                            </td>
                                                                            </tr>
                                                                            </table>
                                                            
                                                                        
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                </section>

                <br></br>
                {/* Indicators */}
                <div class="general-heading">
                    <h2  style={{fontSize:'22px'}}> <span class="circle"></span> Indicator Benchmark Settings</h2>
                </div>


                <div id="benchmarkGeneral" style={{paddingBottom:'10px',marginBottom:'0px'}} className="subsectionLabel" onClick={e => this.HandleSectionExpand(e, this)}>GENERAL<span id="benchmarkGeneralPlus" className="plusSign">+</span></div>

                <section class="indicators-section" id="benchmarkGeneralSection" style={{display:'none'}}>
                    <div class="container">
                        <div class="row">
                                <div class="col-md-12">

                                    <div class="row">
                                        <div class="col-md-5">

                                            {/* Debt Coverage Ratio */}
                                            <div class="form-group setting-form">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 150, hide: 100 }} 
                                                    trigger={["hover","click"]}
                                                    overlay={<Tooltip  className="messagetooltipWhite">
                                                    
                                                                                                                <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The debt coverage ratio indicates whether the buyer has sufficient income to cover the debt for one year.
                                                            <br></br><br></br>
                                                            A debt coverage ratio of 1.00 means you have exactly enough money to cover the mortgage. A ratio greater than 1.00 means you have more than enough money to cover the mortgage and have some left over, while a ratio less than 1.00 indicates that you don't have enough money to pay the mortgage.
                                                            <br></br><br></br>
                                                            Most banks prefer the property to have a debt coverage ratio of 1.20 or higher before granting a mortgage.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                    </Tooltip>}
                                                    >
                                                    <div>
                                                            <label class="form-check-label" for="debtCoverageRatio">Debt Coverage Ratio</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                    </div>
                                                </OverlayTrigger>
                                                
                                                <table>
                                                    <tr>
                                                        <td class="setting-edit-field-td">
                                                    
                                                        <table>
                                                            <tr>
                                                            <td>
                                                                    <DropdownButton style={{minWidth:"250px"}} id="debtCoverageRatioComparisonDropdownSelect" name="debtCoverageRatioComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.debtCoverageRatioComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['debtCoverageRatioComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['debtCoverageRatioComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['debtCoverageRatioComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <input type="text" inputmode="decimal" style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="debtCoverageRatio" placeholder="%" name="debtCoverageRatio" defaultValue={this.state.debtCoverageRatio} value={this.state.debtCoverageRatio} onChange={this.HandleNumberInputChange}/>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                        <span class="desktop-only-block">
                                                            <input type="range" className="custom-range" id="debtCoverageRatioAsNumber" name="debtCoverageRatioAsNumber" min="0" max="20" step="0.1" value={this.state.debtCoverageRatioAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider id="debtCoverageRatioAsNumber" name="debtCoverageRatioAsNumber" min={0} max={20} step={0.1} value={this.state.debtCoverageRatioAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                        
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>

                                                        &nbsp;

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>

                                                                                        {/* Market Value Percent */}
                                                                                        <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The market value percentage indicator compares the current market value of a property to its purchase price. Ideally, you want to purchase properties below market value, and the lower the percentage below market value, the better.
                                                            <br></br><br></br>
                                                            Ideally, you should aim to purchase properties below market value, and the lower the percentage below market value, the better.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="marketValuePercent">Market Value Percent</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="marketValuePercentComparisonDropdownSelect" name="marketValuePercentComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.marketValuePercentComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['marketValuePercentComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['marketValuePercentComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['marketValuePercentComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="marketValuePercent" placeholder="%" name="marketValuePercent" defaultValue={this.state.marketValuePercent} value={this.state.marketValuePercent} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="marketValuePercentAsNumber" name="marketValuePercentAsNumber" min="0" max="100" step="1" value={this.state.marketValuePercentAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="marketValuePercentAsNumber" name="marketValuePercentAsNumber" min={0} max={100} step={1} value={this.state.marketValuePercentAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>
                                        </div>
                                        <div class="col-md-5">

                                            {/* Loan-to-Value Ratio */}
                                            <div class="form-group setting-form">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 150, hide: 100 }} 
                                                    trigger={["hover","click"]}
                                                    overlay={<Tooltip  className="messagetooltipWhite">
                                                    
                                                                                                                <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The loan-to-value (LTV) ratio is the ratio of the loan balance to the market value of a property, expressed as a percentage. The LTV can be used to estimate the amount of equity you have in a property.
                                                            <br></br><br></br>
                                                            The lower the loan-to-value (LTV) ratio, the better. Lenders usually want a LTV of 70% or lower, but check with the bank since the required ratio changes depending on the level of risk the bank is willing to take.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                    </Tooltip>}
                                                    >
                                                    <div>
                                                            <label class="form-check-label" for="loanToValueRatio">Loan-to-Value Ratio</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                    </div>
                                                </OverlayTrigger>
                                                
                                                <table>
                                                    <tr>
                                                        <td class="setting-edit-field-td">
                                                    
                                                        <table>
                                                            <tr>
                                                            <td>
                                                                    <DropdownButton style={{minWidth:"250px"}} id="loanToValueRatioComparisonDropdownSelect" name="loanToValueRatioComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.loanToValueRatioComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['loanToValueRatioComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['loanToValueRatioComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['loanToValueRatioComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="loanToValueRatio" placeholder="%" name="loanToValueRatio" defaultValue={this.state.loanToValueRatio} value={this.state.loanToValueRatio} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                        <span class="desktop-only-block">
                                                            <input type="range" className="custom-range" id="loanToValueRatioAsNumber" name="loanToValueRatioAsNumber" min="0" max="100" step="1" value={this.state.loanToValueRatioAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider id="loanToValueRatioAsNumber" name="loanToValueRatioAsNumber" min={0} max={100} step={1} value={this.state.loanToValueRatioAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                        
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>

                                                        &nbsp;

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>

                <div id="benchmarkPrimaryHomes" style={{paddingBottom:'10px',marginBottom:'0px'}} className="subsectionLabel" onClick={e => this.HandleSectionExpand(e, this)}>PRIMARY HOMES<span id="benchmarkPrimaryHomesPlus" className="plusSign">+</span></div>

                <section class="indicators-section" id="benchmarkPrimaryHomesSection" style={{display:'none'}}>
                    <div class="container">
                        <div class="row">
                                <div class="col-md-12">

                                    <div class="row">
                                        <div class="col-md-5">

                                            {/* Back-End Ratio */}
                                            <div class="form-group setting-form">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 150, hide: 100 }} 
                                                    trigger={["hover","click"]}
                                                    overlay={<Tooltip  className="messagetooltipWhite">
                                                    
                                                        <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            This ratio indicates what portion of a person's monthly income goes towards paying debts. Total monthly debt includes expenses such as mortgage payments, credit card payments, child support, and other loan payments.
                                                            <br></br><br></br>
                                                            Lenders use this ratio in conjunction with the front-end ratio to approve mortgages.
                                                            <br></br><br></br>
                                                            The lower the back-end ratio, the better. Generally. lenders like to see a back-end ratio that does not exceed 36%.
                                                            </div>


                                                        </div>
                                                    </Tooltip>}
                                                    >
                                                    <div>
                                                            <label class="form-check-label" for="cosmeticRehabPerSqFt">Back-End Ratio</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                    </div>
                                                </OverlayTrigger>
                                                
                                                <table>
                                                    <tr>
                                                        <td class="setting-edit-field-td">
                                                    
                                                        <table>
                                                            <tr>
                                                            <td>
                                                                    <DropdownButton style={{minWidth:"250px"}} id="backEndRatioComparisonDropdownSelect" name="backEndRatioComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.backEndRatioComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['backEndRatioComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['backEndRatioComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['backEndRatioComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="backEndRatio" placeholder="%" name="backEndRatio" defaultValue={this.state.backEndRatio} value={this.state.backEndRatio} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <span class="desktop-only-block">
                                                            <input type="range" className="custom-range" id="backEndRatioAsNumber" name="backEndRatioAsNumber" min="0" max="100" step="1" value={this.state.backEndRatioAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider id="backEndRatioAsNumber" name="backEndRatioAsNumber" min={0} max={100} step={1} value={this.state.backEndRatioAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                        
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>

                                                        &nbsp;

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>

                                            {/* Font-End Ratio */}
                                            <div class="form-group setting-form">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 150, hide: 100 }} 
                                                    trigger={["hover","click"]}
                                                    overlay={<Tooltip  className="messagetooltipWhite">
                                                    
                                                        <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            It is the percentage of your monthly income spent on your mortgage payment, and it is used by lenders to qualify borrowers for a mortgage.<br></br><br></br>
                                                            Typically a ratio of 28 percent is ideal from a lender's standpoint, and they calculate this ratio by dividing the mortgage payment on a property by your monthly income.<br></br><br></br>
                                                        The lower the front-end ratio the better. Generally, lenders like to see a front-end ratio that doesn't exceed 28%.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>

                                                    </Tooltip>}
                                                    >
                                                    <div>
                                                            <label class="form-check-label" for="cosmeticRehabPerSqFt">Font-End Ratio</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                    </div>
                                                </OverlayTrigger>
                                                
                                                <table>
                                                    <tr>
                                                        <td class="setting-edit-field-td">
                                                    
                                                        <table>
                                                            <tr>
                                                            <td>
                                                                    <DropdownButton style={{minWidth:"250px"}} id="frontEndRatioComparisonDropdownSelect" name="frontEndRatioComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.frontEndRatioComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['frontEndRatioComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['frontEndRatioComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['frontEndRatioComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="frontEndRatio" placeholder="%" name="frontEndRatio" defaultValue={this.state.frontEndRatio} value={this.state.frontEndRatio} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                        <span class="desktop-only-block">
                                                            <input type="range" className="custom-range" id="frontEndRatioAsNumber" name="frontEndRatioAsNumber" min="0" max="100" step="1" value={this.state.frontEndRatioAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider id="frontEndRatioAsNumber" name="frontEndRatioAsNumber" min={0} max={100} step={1} value={this.state.frontEndRatioAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                        
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>

                                                        &nbsp;

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            
                                                                                        {/* Price-To-Rent Ratio */}
                                                                                        <div class="form-group setting-form">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 150, hide: 100 }} 
                                                    trigger={["hover","click"]}
                                                    overlay={<Tooltip  className="messagetooltipWhite">
                                                    
                                                                                                                <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            You can arrive at the trending price-per-sqft of a property by adding the price-per-sqft of properties that have sold in a given area and dividing by the number of properties sold. <br></br><br></br>

                                                            The price-per-sqft can vary based on the property's location, condition, improvements, upgrades, as well as lot size and number of stories, among other factors.<br></br><br></br>

                                                            The main reason the price-per-sqft is important is to show property price trends. If you can compare the average price-per-sqft for the past 12 months, you can determine whether values are rising or falling.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                    </Tooltip>}
                                                    >
                                                    <div>
                                                            <label class="form-check-label" for="priceToRentRatio">Price-To-Rent Ratio</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                    </div>
                                                </OverlayTrigger>
                                                
                                                <table>
                                                    <tr>
                                                        <td class="setting-edit-field-td">
                                                    
                                                        <table>
                                                            <tr>
                                                            <td>
                                                                    <DropdownButton style={{minWidth:"250px"}} id="priceToRentRatioComparisonDropdownSelect" name="priceToRentRatioComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.priceToRentRatioComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['priceToRentRatioComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['priceToRentRatioComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['priceToRentRatioComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <input type="text" inputmode="decimal" style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="priceToRentRatio" placeholder="%" name="priceToRentRatio" defaultValue={this.state.priceToRentRatio} value={this.state.priceToRentRatio} onChange={this.HandleNumberInputChange}/>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                        <span class="desktop-only-block">
                                                            <input type="range" className="custom-range" id="priceToRentRatioAsNumber" name="priceToRentRatioAsNumber" min="0" max="100" step="1" value={this.state.priceToRentRatioAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider id="priceToRentRatioAsNumber" name="priceToRentRatioAsNumber" min={0} max={100} step={1} value={this.state.priceToRentRatioAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                        
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>

                                                        &nbsp;

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>
                                        
                                        <div class="col-md-5">
    
                                            {/* Cost of Debt */}
                                            <div class="form-group setting-form">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 150, hide: 100 }} 
                                                    trigger={["hover","click"]}
                                                    overlay={<Tooltip  className="messagetooltipWhite">
                                                    
                                                                                                                <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The cost-of-debt indicator compares the percentage of the cost of debt in relation to the loan amount, which shows how much money you are paying the lender in interest relative to the total loan amount.
                                                            <br></br><br></br>
                                                            The lower the cost-of-debt, the better. Basically, you want to pay the lender as little as possible in interest.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                    </Tooltip>}
                                                    >
                                                    <div>
                                                            <label class="form-check-label" for="costOfDebt">Cost of Debt</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                    </div>
                                                </OverlayTrigger>
                                                
                                                <table>
                                                    <tr>
                                                        <td class="setting-edit-field-td">
                                                    
                                                        <table>
                                                            <tr>
                                                            <td>
                                                                    <DropdownButton style={{minWidth:"250px"}} id="costOfDebtComparisonDropdownSelect" name="costOfDebtComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.costOfDebtComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['costOfDebtComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['costOfDebtComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['costOfDebtComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="costOfDebt" placeholder="%" name="costOfDebt" defaultValue={this.state.costOfDebt} value={this.state.costOfDebt} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                        <span class="desktop-only-block">
                                                            <input type="range" className="custom-range" id="costOfDebtAsNumber" name="costOfDebtAsNumber" min="0" max="100" step="1" value={this.state.costOfDebtAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider  id="costOfDebtAsNumber" name="costOfDebtAsNumber" min={0} max={100} step={1} value={this.state.costOfDebtAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                        
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>

                                                        &nbsp;

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>


                                            {/* Credit Score */}
                                            <div class="form-group setting-form">
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 150, hide: 100 }} 
                                                    trigger={["hover","click"]}
                                                    overlay={<Tooltip  className="messagetooltipWhite">
                                                    
                                                                                                                <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The credit score indicator compares the property buyer's credit score against the minimum required credit score specified in the benchmark settings.
                                                            <br></br><br></br>
                                                            The higher the credit score the better. Lenders look at a buyer's credit score to determine loan eligibility.
                                                                <br></br><br></br>
                                                                It's recommended you have a credit score of <b>620</b> or higher when you apply for a conventional loan. If your score is below <b>620</b>, lenders either won't be able to approve your loan or may be required to offer you a higher interest rate, which can result in higher monthly payments.                
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                    </Tooltip>}
                                                    >
                                                    <div>
                                                            <label class="form-check-label" for="creditScore">Credit Score</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                    </div>
                                                </OverlayTrigger>
                                                
                                                <table>
                                                    <tr>
                                                        <td class="setting-edit-field-td">
                                                    
                                                        <table>
                                                            <tr>
                                                            <td>
                                                                    <DropdownButton style={{minWidth:"250px"}} id="creditScoreComparisonDropdownSelect" name="creditScoreComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.creditScoreComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['creditScoreComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['creditScoreComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                            <div style={{paddingBottom:"8px"}}></div>
                                                                            <Dropdown.Item className="dropdownitem"  eventKey={['creditScoreComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <input type="text" inputmode="numeric" style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="creditScore" placeholder="%" name="creditScore" defaultValue={this.state.creditScore} value={this.state.creditScore} onChange={this.HandleNumberInputChange}/>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                        <span class="desktop-only-block">    
                                                            <input type="range" className="custom-range" id="creditScoreAsNumber" name="creditScoreAsNumber" min="300" max="850" step="10" value={this.state.creditScoreAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                        </span>

                                                        <span class="mobile-only-block">
                                                            <CustomizedSlider id="creditScoreAsNumber" name="creditScoreAsNumber" min={300} max={850} step={10} value={this.state.creditScoreAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                        
                                                            <br></br>     
                                                        </span>

                                                        </td>
                                                        <td style={{width:'20%'}}>

                                                        &nbsp;

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>


                                        </div>

                                    </div>


                                    </div>
                                </div>
                            </div>
                </section>



                <div id="benchmarkRental" style={{paddingBottom:'10px',marginBottom:'0px'}} className="subsectionLabel" onClick={e => this.HandleSectionExpand(e, this)}>RENTAL PROPERTIES<span id="benchmarkRentalPlus" className="plusSign">+</span></div>


                <section class="setting-section-one" id="benchmarkRentalSection" style={{display:'none'}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                               
                            {/*   Content Begin      */}
                       {/* RENTAL */}
                      
                                    <div class="row">
                                        <div class="col-md-5">

                                            {/* Gross Rent Multiplier */}
                                            <div class="form-group setting-form">
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{ show: 150, hide: 100 }} 
                                                trigger={["hover","click"]}
                                                overlay={<Tooltip  className="messagetooltipWhite">
                                                
                                                                                                            <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The gross rent multiplier (GRM) is a screening metric used by investors to compare rental property opportunities in a given market. The GRM functions as the ratio of the property’s market value over its annual gross rental income.
                                                            <br></br><br></br>
                                                            The lower the gross rent multiplier (GRM), the better.
                                                            <br></br><br></br>
                                                            For the investor, a higher gross rent multiplier (perhaps over 12) is a poorer opportunity, whereas a lower one (perhaps under 9) is better.
                                                            <br></br><br></br>
                                                            A gross rent multiplier (GRM) of zero would be perfect, but highly unlikely.
                                                            <br></br><br></br>                                            
                                                            The gross rent multiplier (GRM) typically ranges from 4 to 10. Most investors prefer properties with a gross rent multiplier (GRM) below 10.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                </Tooltip>}
                                                >
                                                <div>
                                                        <label class="form-check-label" for="grossRentMultiplier">Gross Rent Multiplier</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                </div>
                                            </OverlayTrigger>
                                            
                                            <table>
                                                <tr>
                                                    <td class="setting-edit-field-td">
                                                
                                                    <table>
                                                        <tr>
                                                        <td>
                                                                <DropdownButton style={{minWidth:"250px"}} id="grossRentMultiplierComparisonDropdownSelect" name="grossRentMultiplierComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.grossRentMultiplierComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['grossRentMultiplierComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['grossRentMultiplierComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                        <div style={{paddingBottom:"8px"}}></div>
                                                                        <Dropdown.Item className="dropdownitem"  eventKey={['grossRentMultiplierComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                </DropdownButton>
                                                            </td>
                                                            <td>&nbsp;</td>
                                                            <td>
                                                            <input type="text" inputmode="decimal" style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="grossRentMultiplier" placeholder="%" name="grossRentMultiplier" defaultValue={this.state.grossRentMultiplier} value={this.state.grossRentMultiplier} onChange={this.HandleNumberInputChange}/>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <span class="desktop-only-block">
                                                        <input type="range" className="custom-range" id="grossRentMultiplierAsNumber" name="grossRentMultiplierAsNumber" min="0" max="20" step="0.5" value={this.state.grossRentMultiplierAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                    </span>

                                                    <span class="mobile-only-block">
                                                        <CustomizedSlider id="grossRentMultiplierAsNumber" name="grossRentMultiplierAsNumber" min={0} max={20} step={0.5} value={this.state.grossRentMultiplierAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                    
                                                        <br></br>     
                                                    </span>

                                                    </td>
                                                    <td style={{width:'20%'}}>

                                                    &nbsp;

                                                    </td>
                                                </tr>
                                            </table>
                                            </div>  

                                        {/* Capitalization Rate */}
                                        <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The capitalization rate allows investors to measure the earning ability of a rental property. It is a ratio between a property's net operating income and it's purchase price.
                                                            <br></br><br></br>
                                                            The higher the capitalization rate the better, as this is the money that really flows into your pocket. Most investors would not go for a capitalization rate lower than 7%.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="capitalizationRate">Capitalization Rate</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="capitalizationRateComparisonDropdownSelect" name="capitalizationRateComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.capitalizationRateComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['capitalizationRateComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['capitalizationRateComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['capitalizationRateComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="capitalizationRate" placeholder="%" name="capitalizationRate" defaultValue={this.state.capitalizationRate} value={this.state.capitalizationRate} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="capitalizationRateAsNumber" name="capitalizationRateAsNumber" min="0" max="100" step="1" value={this.state.capitalizationRateAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="capitalizationRateAsNumber" name="capitalizationRateAsNumber" min={0} max={100} step={1} value={this.state.capitalizationRateAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                        </div>
                                            
                                            {/* Cash on Cash Return */}
                                            <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The cash on cash return is a percentage that measures the return on the cash invested in the first year of the investment.<br></br><br></br>
                                                            It differs from the internal rate of return and the capitalization rate in that the cash on cash return only focuses on the cash return in the first year, as opposed to the entire projected ownership life, therefore no compounding or discounting to the present is required.<br></br><br></br>
                                                            The higher the cash on cash return the better. However, it depends on the returns you would get on alternative investments, such as stocks or bonds.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="cashOnCashReturn">Cash on Cash Return</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="cashOnCashReturnComparisonDropdownSelect" name="cashOnCashReturnComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.cashOnCashReturnComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['cashOnCashReturnComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['cashOnCashReturnComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['cashOnCashReturnComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="cashOnCashReturn" placeholder="%" name="cashOnCashReturn" defaultValue={this.state.cashOnCashReturn} value={this.state.cashOnCashReturn} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="cashOnCashReturnAsNumber" name="cashOnCashReturnAsNumber" min="0" max="100" step="1" value={this.state.cashOnCashReturnAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="cashOnCashReturnAsNumber" name="cashOnCashReturnAsNumber" min={0} max={100} step={1} value={this.state.cashOnCashReturnAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>

                                            {/* Profitability Index */}
                                            <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The profitability index measures the cost-benefit of an rental property.<br></br><br></br>
                                                            The profitability index is an alternative way of stating the net present value (NPV), also known as benefit/cost ratio.<br></br><br></br>
                                                            The higher the profitablity index, the better. A profitability index of 1.0 means you achieved exactly your goal rate of return, greater than 1.0 means you have exceeded your goal rate of return, and below 1.0 means your failed to reach the goal.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="profitabilityIndex">Profitability Index</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="profitabilityIndexComparisonDropdownSelect" name="profitabilityIndexComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.profitabilityIndexComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['profitabilityIndexComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['profitabilityIndexComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['profitabilityIndexComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <input type="text" inputmode="decimal" style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="profitabilityIndex" placeholder="%" name="profitabilityIndex" defaultValue={this.state.profitabilityIndex} value={this.state.profitabilityIndex} onChange={this.HandleNumberInputChange}/>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="profitabilityIndexAsNumber" name="profitabilityIndexAsNumber" min="0" max="20" step="0.1" value={this.state.profitabilityIndexAsNumber} onChange=  {this.ChangeNumberSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="profitabilityIndexAsNumber" name="profitabilityIndexAsNumber" min={0} max={20} step={0.1} value={this.state.profitabilityIndexAsNumber} onChange=  {this.ChangeCustomNumberSliderValue.bind(this)}/>  
                                                        
                                                                <br></br>     
                                                            </span>
                                                            
                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>



                                        </div>
                                        
                                        <div class="col-md-5">
    
                                            {/* Break-Even Ratio */}
                                            <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The break-even ratio is an indicator used by lenders when underwriting investment properties; its purpose is to estimate how vulnerable a property is of defaulting on its debt should rental income decline.
                                                            <br></br><br></br>
                                                            The lower the break-even ratio the better. This ratio typically ranges between 70% and 100%. Most lenders look for a break-even ratio of 85% or less.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="breakEvenRatio">Break-Even Ratio</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="breakEvenRatioComparisonDropdownSelect" name="breakEvenRatioComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.breakEvenRatioComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['breakEvenRatioComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['breakEvenRatioComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['breakEvenRatioComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="breakEvenRatio" placeholder="%" name="breakEvenRatio" defaultValue={this.state.breakEvenRatio} value={this.state.breakEvenRatio} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="breakEvenRatioAsNumber" name="breakEvenRatioAsNumber" min="0" max="100" step="1" value={this.state.breakEvenRatioAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="breakEvenRatioAsNumber" name="breakEvenRatioAsNumber" min={0} max={100} step={1} value={this.state.breakEvenRatioAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>


                                            {/* Net Cash Flow */}
                                            <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                                The net cash flow is an indicator that calculates the net cash flow of the property.
                                                                <br></br><br></br>
                                                                It differs from the internal rate of return and the profitability index in that the net cash flow only focuses on the cash return in a single year, as opposed to the entire projected ownership life, therefore no compounding or discounting to the present is required.
                                                                <br></br><br></br>    
                                                                A positive cash flow is good and a negative cash flow is typically considered bad.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="netCashFlow">Net Cash Flow</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="netCashFlowComparisonDropdownSelect" name="netCashFlowComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.netCashFlowComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['netCashFlowComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['netCashFlowComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['netCashFlowComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <CurrencyInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="netCashFlow" placeholder="$0" name="netCashFlow" defaultValue={this.state.netCashFlow} value={this.state.netCashFlow} onChange={this.HandleInputChange}/>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="netCashFlowAsNumber" name="netCashFlowAsNumber" min="0" max="100000" step="100" value={this.state.netCashFlowAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="netCashFlowAsNumber" name="netCashFlowAsNumber" min={0} max={100000} step={100} value={this.state.netCashFlowAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>

                                            {/* Internal Rate of Return */}
                                            <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The internal rate of return (IRR) measures the profitability of a potential investment based on its predicted future cash flows.<br></br><br></br>
                                                            The higher the internal rate of return (IRR), the better. The higher the IRR for a project, the more attractive the project is to undertake.
                                                            <br></br><br></br>
                                                            Also if the internal rate of return is less than the cost of borrowing used to fund the property, the property will clearly be a money-loser.
                                                            <br></br><br></br>
                                                            That's why most investors will insist that in order to be acceptable, a property must be expected to earn an internal rate of return that is at least several percentage points higher than the mortgage interest rate.
                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="internalRateOfReturn">Internal Rate of Return</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="internalRateOfReturnComparisonDropdownSelect" name="internalRateOfReturnComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.internalRateOfReturnComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['internalRateOfReturnComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['internalRateOfReturnComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['internalRateOfReturnComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <input type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="internalRateOfReturn" placeholder="%" name="internalRateOfReturn" defaultValue={this.state.internalRateOfReturn} value={this.state.internalRateOfReturn} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="internalRateOfReturnAsNumber" name="internalRateOfReturnAsNumber" min="0" max="100" step="1" value={this.state.internalRateOfReturnAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="internalRateOfReturnAsNumber" name="internalRateOfReturnAsNumber" min={0} max={100} step={1} value={this.state.internalRateOfReturnAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>



                                        </div>

                                    </div>
                            {/*   Content End        */}

                            </div>
                        </div>
                    </div>
                </section>


                <div id="benchmarkFlipping" style={{paddingBottom:'10px',marginBottom:'0px'}} className="subsectionLabel" onClick={e => this.HandleSectionExpand(e, this)}>FLIPPING PROPERTIES<span id="benchmarkFlippingPlus" className="plusSign">+</span></div>


                <section class="setting-section-one" id="benchmarkFlippingSection" style={{display:'none'}}>

                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                               
                            {/* Content Begin */}

                             {/* FLIPPING */}

                                        <div class="row">
                                            <div class="col-md-5">

                                            {/* Rule of 70 */}
                                            <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                
                                                            <div style={{maxWidth:"300px"}}>
                                                                The rule of 70 states that an investor should pay no more than 70 percent of the AVR minus the repairs needed in order make a profit.
                                                                <br></br><br></br>
                                                                AVR=Expected price of property after repairs
                                                                <br></br><br></br>
                                                                The less you pay for the property below the maximum price indicated by the Rule of 70% the better.

                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="ruleOf70">Rule of 70</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="ruleOf70ComparisonDropdownSelect" name="ruleOf70ComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.ruleOf70Comparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['ruleOf70Comparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['ruleOf70Comparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['ruleOf70Comparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="ruleOf70" placeholder="%" name="ruleOf70" defaultValue={this.state.ruleOf70} value={this.state.ruleOf70} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="ruleOf70AsNumber" name="ruleOf70AsNumber" min="0" max="100" step="1" value={this.state.ruleOf70AsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="ruleOf70AsNumber" name="ruleOf70AsNumber" min={0} max={100} step={1} value={this.state.ruleOf70AsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>

                                            {/* Key Risk Indicator */}
                                            <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                                This key risk indicator calculates the maximum allowable percentage the property can drop in price while you hold the property before the investment starts losing money.
                                                                <br></br><br></br>    
                                                                The higher the market value drop you can absorb the better.
                                                                <br></br><br></br>
                                                                Therefore you should try to buy the property below market value and also hold the property for the least number of months to minimize your risk.

                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="keyRiskIndicator">Key Risk Indicator</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"260px"}} id="keyRiskIndicatorComparisonDropdownSelect" name="keyRiskIndicatorComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.keyRiskIndicatorComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['keyRiskIndicatorComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['keyRiskIndicatorComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['keyRiskIndicatorComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="keyRiskIndicator" placeholder="%" name="keyRiskIndicator" defaultValue={this.state.keyRiskIndicator} value={this.state.keyRiskIndicator} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="keyRiskIndicatorAsNumber" name="keyRiskIndicatorAsNumber" min="0" max="100" step="1" value={this.state.keyRiskIndicatorAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>
                                                                                                            
                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="keyRiskIndicatorAsNumber" name="keyRiskIndicatorAsNumber" min={0} max={100} step={1} value={this.state.keyRiskIndicatorAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                            </div>

                                            </div>
                                            
                                            <div class="col-md-5">
                                                        
                                                {/* Return on Investment (ROI) */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The ROI is an indicator that answers the question, "How much will I get back for each dollar I invest?"
                                                            <br></br><br></br>
                                                            The ROI measures the financial return on an investment relative to its cost.
                                                            <br></br><br></br>
                                                            ROI is calculated as the percentage difference between the current value (After Repair Value) of an investment and the original value (Purchase Price).
                                                            <br></br><br></br>
                                                            The higher the ROI the better.
                                                            <br></br><br></br>
                                                            The ROI on a property will be higher if you finance the property (get a mortgage) rather than buy it with your own cash. This is because the ROI calculates the return on your cash investment.

                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="roi">Return on Investment (ROI)</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"260px"}} id="roiComparisonDropdownSelect" name="roiComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.roiComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['roiComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['roiComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['roiComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <PercentInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="roi" placeholder="%" name="roi" defaultValue={this.state.roi} value={this.state.roi} onChange={this.HandlePercentInputChange} onFocus= {this.HandlePercentEnterFocus.bind(this)} onBlur= {this.HandlePercentExitFocus.bind(this)}/>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="roiAsNumber" name="roiAsNumber" min="0" max="100" step="1" value={this.state.roiAsNumber} onChange=  {this.ChangePercentSliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider name="roiAsNumber" min={0} max={100} step={1} value={this.state.roiAsNumber} onChange=  {this.ChangeCustomPercentSliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                {/* Net Profit */}
                                                <div class="form-group setting-form">
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{ show: 150, hide: 100 }} 
                                                        trigger={["hover","click"]}
                                                        overlay={<Tooltip  className="messagetooltipWhite">
                                                        
                                                                                                                    <div className='helpText'>
                                                            
                                                            <div style={{maxWidth:"300px"}}>
                                                            The net profit tells you exactly how much money you will make from this real estate investment.
                                                            <br></br><br></br>
                                                            To calculate the net profit, add up all the expenses, costs, and other out-of-pocket expenses and deduct the total from the expected gross profit. 
                                                            <br></br><br></br>
                                                            The gross profit is the expected selling price of the property minus the loan repayment amount (if financed).
                                                            <br></br><br></br>    
                                                            The higher your profit, the better.

                                                                <br></br><br></br>

                                                            </div>
                                                        </div>
                                                            
                                                        </Tooltip>}
                                                        >
                                                        <div>
                                                                <label class="form-check-label" for="netProfit">Net Profit</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                        </div>
                                                    </OverlayTrigger>
                                                    
                                                    <table>
                                                        <tr>
                                                            <td class="setting-edit-field-td">
                                                        
                                                            <table>
                                                                <tr>
                                                                <td>
                                                                        <DropdownButton style={{minWidth:"250px"}} id="netProfitComparisonDropdownSelect" name="netProfitComparisonDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.netProfitComparison} onSelect={(e) => this.HandleSelect(e)}>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['netProfitComparison', 'Less Than Or Equal To']}>Less Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['netProfitComparison', 'Greater Than Or Equal To']}>Greater Than Or Equal To</Dropdown.Item>
                                                                                <div style={{paddingBottom:"8px"}}></div>
                                                                                <Dropdown.Item className="dropdownitem"  eventKey={['netProfitComparison', 'Equal To']}>Equal To</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                    <td>
                                                                    <CurrencyInput type="text"  style={{textAlign:"center",backgroundColor:"#000000"}} class="form-select alert-edit-modal-component" id="netProfit" placeholder="$0" name="netProfit" defaultValue={this.state.netProfit} value={this.state.netProfit} onChange={this.HandleInputChange}/>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                            <span class="desktop-only-block">
                                                                <input type="range" className="custom-range" id="netProfitAsNumber" name="netProfitAsNumber" min="0" max="100000" step="100" value={this.state.netProfitAsNumber} onChange=  {this.ChangeCurrencySliderValue.bind(this)}/>  
                                                            </span>

                                                            <span class="mobile-only-block">
                                                                <CustomizedSlider id="netProfitAsNumber" name="netProfitAsNumber" min={0} max={100000} step={100} value={this.state.netProfitAsNumber} onChange=  {this.ChangeCustomCurrencySliderValue.bind(this)}/>  
                                                            
                                                                <br></br>     
                                                            </span>

                                                            </td>
                                                            <td style={{width:'20%'}}>

                                                            &nbsp;

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                            {/* Content End */}

                            </div>
                        </div>
                    </div>
                </section>




                {/* General
                <div class="general-heading">
                    <h2> <span class="circle"></span> General</h2>
                </div>

                <section class="setting-section-one">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="appearance-heading">
                                    <h3>Color Theme</h3>
                                </div>
                                <div class="search-by-sec">
                                    <ul class="search-by-list">
                                        <li><input type="radio" name="optradio" checked=""/><label class="radio-inline">Dark Mode</label>
                                            </li>
                                            <li><input type="radio" name="optradio" checked=""/><label class="radio-inline">Light Mode</label>
                                            </li>
                                        </ul>
                                    </div>
                    

                            </div>
                        </div>
                    </div>
                </section>

                */}

                    <div style={{width:'100%',textAlign:'center'}}>
                        <button type="button" class="btn btn-primary add-btn" onClick={(e) => this.SaveSettings(e)} style={{textAlign:'center'}}>
                            &nbsp;&nbsp;&nbsp;SAVE&nbsp;&nbsp;&nbsp;
                        </button>
                    </div>

                <Helmet>
                    <script src="js/grid-functions.js" type="text/javascript" />
                    <script src="vendor/jquery/jquery.min.js"></script>
                    <script src="js/sb-admin-2.min.js"></script>
                    <script src="vendor/bootstrap/bootstrap.bundle.min.js"></script>
                </Helmet>

                {this.state.uiLoading && 
              <main className={classes.content} style={{height:'1px'}}>
                  <div className={classes.toolbar} />
                  {this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
              </main>
            }  

            </div>
        );

        
    }
}

export default (withStyles(styles)(Settings));

const { calculateIndicators, getRedIndicatorRecommendation, getIndicatorDescription, isIndicatorDisabled } = require('./IndicatorUtility');

export const getReportData = (property,profile,settings) => {


   let indicators = getReportIndicators(property,profile,settings);
  
   let {greenCount,redCount} = getIndicatorCounts(indicators,property);

   const indicatorDescriptions = getIndicatorDescriptions(indicators,property);

   const reportData = {
        address: property.address,
        budget: profile.budget,
        city: property.city,
        closingCosts: property.closingCosts,
        country:property.country,
        creditScore: property.creditScore,
        downPayment: property.downPayment,
        downPaymentPercent: property.downPaymentPercent,
        financingType: property.financingType,
        greenIndicatorsFound: greenCount,
        redIndicatorsFound: redCount,
        indicators:indicators,
        interestRate: property.interestRate,
        investmentType: property.investmentType,
        livingAreaSize: property.livingAreaSize,
        loanType: property.loanType,
        lotSize: property.lotSize,
        marketValue: property.marketValue,
        mls: property.mls,
        monthlyBills: profile.monthlyBills,
        monthlyGrossIncome: profile.monthlyGrossIncome,
        monthlyPayment: property.monthlyPayment,
        numberBathrooms: property.numberBathrooms,
        numberBedrooms: property.numberBedrooms,
        price: property.price,
        pricePerSqft: property.pricePerSqft,
        principalAndInterest: property.pricePerSqft,
        propertyImage: property.propertyImage,
        propertyType: property.propertyType,
        repaymentType: property.repaymentType,
        state: property.state,
        term: property.term,
        trendingMonthlyRent: property.trendingMonthlyRent,
        trendingPricePerSqft: property.trendingPricePerSqft,
        yearBuilt: property.yearBuilt,
        zipcode: property.zipcode,
        glossary: indicatorDescriptions
    };

	return reportData;
};

const getReportIndicators = (property,profile,settings) => {

    const reportIndicators = [];
 
    const indicators = calculateIndicators(property,profile,settings);

    for(let indicator of indicators)
    {
        let result = indicator.helpVariableMap["VAR_RESULT_TEXT"];
        result = removeHTMLTags(result);

        let acceptCriteria = indicator.helpVariableMap["VAR_ACCEPT_RANGE"];
        acceptCriteria = getAcceptCriteria(acceptCriteria);

        reportIndicators.push(
            {
                indicatorName: indicator.indicatorName,
                indicatorAcronym: indicator.indicatorAcronym,
                indicatorValue: indicator.indicatorValue,
                meetsBenchmark: indicator.meetsBenchmark,
                disabled: isIndicatorDisabled(property,indicator),
                valueType: indicator.valueType,
                acceptCriteria: acceptCriteria,
                result:result,
                recommendation:getRecommendation(indicator,property)
            }
        );
    }

    return reportIndicators;
};

const getRecommendation = (indicator,property) => {

    let recommendations = "";

    if (!indicator.meetsBenchmark)
    {
        recommendations = getRedIndicatorRecommendation(indicator,property);
    }
    
    return recommendations;
}

const getIndicatorCounts = (indicators,property) => {

    let greenCount = 0;
    let redCount = 0;

    for(let indicator of indicators)
    {
        if (indicator.meetsBenchmark && !isIndicatorDisabled(property,indicator))
        {
            greenCount = greenCount + 1;
        }

        if (!indicator.meetsBenchmark && !isIndicatorDisabled(property,indicator))
        {
            redCount = redCount + 1;
        }

    }

    return {greenCount,redCount};
};

const getIndicatorDescriptions = (indicators,property) => {

    let descriptions = [];

    for(let indicator of indicators)
    {
        const description = getIndicatorDescription(indicator,property);
        console.log(JSON.stringify(description));
        console.log("-----------------------");

        if (Object.keys(description).length > 0)
        {
            descriptions.push(description);
        }
    }

    return descriptions;
};

const removeHTMLTags = (result) => {

    result = result.replace( /(<([^>]+)>)/ig, '');

    return result;
}

const getAcceptCriteria = (acceptCriteria) => {

    
    acceptCriteria = acceptCriteria.replace("Greater Than Or Equal To",">=");
    acceptCriteria = acceptCriteria.replace("Greater Than Or Equal",">=");
    acceptCriteria = acceptCriteria.replace("Less Than Or Equal To","<=");
    acceptCriteria = acceptCriteria.replace("Less Than Or Equal","<=");
    acceptCriteria = acceptCriteria.replace("Not Equal To","!=");
    acceptCriteria = acceptCriteria.replace("Greater Than",">");
    acceptCriteria = acceptCriteria.replace("Equal To","=");
    acceptCriteria = acceptCriteria.replace("Less Than","<");

    return acceptCriteria;    
};


//exports.getReportData = getReportData;


import React, { Component } from 'react';
import $ from 'jquery';

import { Helmet } from "react-helmet";

import '../pages/css/website-css/styles.css';


import {PricingPageHTML} from '../static/PricingPageHTML';


import twitterFooterWhite from '../pages/img/website-images/twitter-footer-white.png';

import loginIconBlue from '../pages/img/website-images/login-icon-blue.png';
import propertyAnalyticsLogoTransparent from '../pages/img/website-images/real-estate-property-analytics-logo-transparent.png';
import propertyAnalyticsLogo from '../pages/img/website-images/real-estate-property-analytics-logo.png';
import loginIconWhite from '../pages/img/website-images/login-icon-white.png';
import propertyAnalyticsFeatureBlue from '../pages/img/website-images/property-analytics-feature-blue.png';
import advancedSearchesBlue from '../pages/img/website-images/advanced-searches-blue.png';
import reportingAndAnalyticsGreen from '../pages/img/website-images/reporting-and-analytics-green.png';
import profilesFeatureBlue from '../pages/img/website-images/profiles-feature-blue.png';
import financialIndicatorsFeatureRed from '../pages/img/website-images/financial-indicators-feature-red.png';
import freeTrial from '../pages/img/website-images/free-trial.png';
import moneyBackGuarantee from '../pages/img/website-images/money-back-guarantee.png';
import cancelAnyTime from '../pages/img/website-images/cancel-any-time.png';
import satisfactionGurantee from '../pages/img/website-images/satisfaction-gurantee.png';
import securedSsl from '../pages/img/website-images/secured-ssl.png';
import instagramFooterWhite from '../pages/img/website-images/instagram-footer-white.png';
import linkedinFooterWhite from '../pages/img/website-images/linkedin-footer-white.png';
import facebookFooterWhite from '../pages/img/website-images/facebook-footer-white.png';


import axios from 'axios';
import { authMiddleWare } from '../utilities/Auth';

class main extends Component {

	constructor(props) {
		super(props);

		this.state = {
			user:null,
			errors: [],
			isUserLoggedIn: false,
			displayScreen:false,
			frequency:"monthly"
		};

		this.indexPageElement = null;

	}

	handleFrequencyChange = (event) => {
		//alert(event.target.checked);

		if (event.target.checked== true){

			this.setState({ 
                frequency: "annually"
            });	
	
		}else
		{
			this.setState({ 
                frequency: "monthly"
            });	
		}

	}

	upgradeSubscription = (type,plan,frequency) => {
	
		if (this.state.user.stripeSubscriptionId == null || this.state.user.stripeSubscriptionId ==='') // It is a trial
		{
			authMiddleWare(this?.props?.history);
			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
					.post('/api/user', {
						planName: plan,
						planType: type,
						billingFrequency: frequency
					})
					.then((response) => {
						if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
						{
							localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
							localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						}
		
						window.location.href = '/signup/terms-and-conditions';
					})
					.catch((error) => {
					console.log(error);
	
					//console.log(JSON.stringify(error));
			
					if (error && error.response && error.response.status && error.response.status === 403) {
							localStorage.setItem('SessionExpired',"Y");
							window.location.reload();
						}
					
					});

		}else //It is an actual uprade
		{
				const payload = {
					type:type,
					plan:plan,
					frequency:frequency,
					stripeSubscriptionId:this.state.user.stripeSubscriptionId
				}
			
				authMiddleWare(this?.props?.history);
				const authToken = localStorage.getItem('AuthToken');
				const refreshToken = localStorage.getItem('RefreshToken');
				axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			
				axios
				.post('/api/updateSubscription', payload)
				.then((response) => {

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					//this.props.history.push('/signup/terms-and-conditions');
					window.location.href = '/signup/terms-and-conditions';

				})
				.catch((error) => {
				console.log(error);

				//console.log(JSON.stringify(error));
		
				if (error && error.response && error.response.status && error.response.status === 403) {
						localStorage.setItem('SessionExpired',"Y");
						window.location.reload();
					}
				
				});
			}
	}


	componentDidUpdate(prevProps, prevState) {
		
	}

	componentDidMount = () => {

		let script = document.createElement("script");
		script.src = "../js/jquery-3.5.1.min.js";
		script.async = false;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.src = "../js/jquery.inview.min.js";
		script.async = false;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.src = "../js/jquery.colorbox-min.js";
		script.async = false;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.src = "../js/sites-scripts.js";
		script.async = false;
		document.body.appendChild(script);

		script = document.createElement("script");
		script.src = "../js/question-and-answers.js";
		script.async = false;
		document.body.appendChild(script);

	}

	componentWillMount = () => {

		try
		{
	
			this.parseQueryStringParameters();

			// 2. check user is logped in
			console.log("About to check logged in user.");

			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.get('/api/alreadyLoggedInCheck')
				.then((response) => {
					//console.log(JSON.stringify(response));

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					//If user is logged in
					if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
					{
						//Retrieve user
						const authToken = localStorage.getItem('AuthToken');
						const refreshToken = localStorage.getItem('RefreshToken');
						axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                        axios
                        .get('/api/user')
                        .then((response) => {
                            //console.log(response.data);
           
                            this.setState({ 
                                user: response.data,
								isUserLoggedIn: true,
								displayScreen: true
                            });	
                            
                        })
                        .catch((error) => {
                           
                            this.setState({ 
                                user: null,
								isUserLoggedIn: false,
								displayScreen: true
                            });	
                         
                          });
					} 

				})
				.catch((error) => {
				
					this.setState({ 
						user: null,
						isUserLoggedIn: false,
						displayScreen: true
					});	
					
				});

			
			} catch(err)
			{
				//alert(err);
				this.setState({ 
					user: null,
					isUserLoggedIn: false,
					displayScreen: true
				});	
			
			}
	}


	  parseQueryStringParameters = () => {

		// Query string example
		//https://www.sitepoint.com/get-url-parameters-with-javascript/

		const queryString = window.location.search;
	
		let frequency = "";

		if (queryString != null && queryString != '')
		{
			const urlParams = new URLSearchParams(queryString);

			frequency = urlParams.get('frequency');
		}

		if (frequency === '' || (frequency !== 'monthly' && frequency !== 'annually'))
		{
			frequency = 'monthly';
		}

		this.setState({
			frequency:frequency
		});

	};

	
	render() {
	
			const images = {
							"twitterFooterWhite":twitterFooterWhite,
							"loginIconBlue":loginIconBlue,
							"propertyAnalyticsLogoTransparent":propertyAnalyticsLogoTransparent,
							"propertyAnalyticsLogo":propertyAnalyticsLogo,
							"loginIconWhite":loginIconWhite,
							"propertyAnalyticsFeatureBlue":propertyAnalyticsFeatureBlue,
							"advancedSearchesBlue":advancedSearchesBlue,
							"reportingAndAnalyticsGreen":reportingAndAnalyticsGreen,
							"profilesFeatureBlue":profilesFeatureBlue,
							"financialIndicatorsFeatureRed":financialIndicatorsFeatureRed,
							"freeTrial":freeTrial,
							"moneyBackGuarantee":moneyBackGuarantee,
							"cancelAnyTime":cancelAnyTime,
							"satisfactionGurantee":satisfactionGurantee,
							"securedSsl":securedSsl,
							"instagramFooterWhite":instagramFooterWhite,
							"linkedinFooterWhite":linkedinFooterWhite,
							"facebookFooterWhite":facebookFooterWhite
						   }

			return (
			<div style={{backgroundColor:'red'}}>

				{this.state.displayScreen && 

                	<PricingPageHTML isUserLoggedIn={this.state.isUserLoggedIn} user={this.state.user} upgradeSubscription={this.upgradeSubscription} handleFrequencyChange={this.handleFrequencyChange} frequency={this.state.frequency} images={images}/>
				}

				
				<Helmet>
					{/*
					<script src="../js/jquery-3.5.1.min.js" type="text/javascript" /> 
                    <script src="../js/jquery.inview.min.js" type="text/javascript" /> 
                    <script src="../js/jquery.colorbox-min.js" type="text/javascript" /> 
					<script src="../js/sites-scripts.js" type="text/javascript" /> 
					<script src="../js/question-and-answers.js" type="text/javascript" />
					*/}

                </Helmet>

			</div>
			
	)};

}


export default main;

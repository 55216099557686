import React, { Component } from 'react';
import $ from 'jquery';

import {LandingPageHTML} from '../static/LandingPageHTML';


class main extends Component {

	constructor(props) {
		super(props);

		this.indexPageElement = null;

	}

	componentWillMount = () => {

		let queryString = window.location.search;

		try
		{
			if (queryString != null)
			{
				if (queryString === "?s=r")
				{
					queryString = queryString.replace("s=r","utm_source=reddit")
				
				}else if (queryString === "?s=q")
				{
					queryString = queryString.replace("s=q","utm_source=quora")
				
				}else if (queryString === "?s=f")
				{
					queryString = queryString.replace("s=f","utm_source=facebook")
				}
			}
		} catch(error)
		{
			console.log(error);
		}
	
		window.location.href = '/home.html'+queryString;

	}

	componentDidMount= () => {
	
        // Need this to run Javascript on load, because normal code on page doesn't work

        /*
		$(this.indexPageElement).find('[data-my-script]').each(function forEachScript() {
			const script = $(this).text();
			window.eval(script);
		  });
        */

	  };

	
	render() {

			return (
				
				{/*
                <LandingPageHTML/>
				*/}
			
	)};

}


export default main;

import React, { Component } from 'react';
import { Modal, Button, Dropdown, DropdownButton, OverlayTrigger,Tooltip} from "react-bootstrap";
import { InfoCircleFill } from 'react-bootstrap-icons';
import { Link45deg } from 'react-bootstrap-icons';

import { FileEarmarkText,  House } from 'react-bootstrap-icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import CurrencyInput from '../types/CurrencyInput'

import 'react-tabs/style/react-tabs.css';

import CustomizedSlider from '../types/CustomizedSlider';

import { parseOutFirstName } from '../utilities/AppUtility';

import { authMiddleWare } from '../utilities/Auth';

import axios from 'axios';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '40%',
		top: '35%'
	}
});

class LoginEditDialog extends Component { 


  /* Actual one for application */

    state = {
        isOpen: false,
        name: '',
        email: '',
        role:'_',
        login:null,
        loading: false,
        uiSaving: false,
        user:null,
        isUpdate:false
        
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.mainModalInfoDialogRef = React.createRef();

       // alert(JSON.stringify(props.user));

       /*
        if (props.user != null)
        {
          alert("Inside");
          this.setState({
            user:props.user
          }, () => {
		   
           alert(JSON.stringify(this.state.user));

          });
        }
        */

     }

     componentDidUpdate(prevProps) {

      //alert(JSON.stringify(prevProps.user));

      if (this.state.user == null) {
       // alert("Inside");
        this.setState({
          user:this.props.user
        }, () => {
     
        // alert(JSON.stringify(this.state.user));
        // console.log(JSON.stringify(this.state.user));

        });
      }


    }
/*
     componentDidUpdate() 
     {

     }
*/
    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

 
    PopulateDialog(login,parentReference)
    {
        
        this.ClearFields();

        if (login != null)
        {
            this.setState({
              name: login.name,
              email: login.email,
              role:login.role,
              login:login,
              isUpdate:true
            });
        } else
        {
          this.setState({
            name: '',
            email: '',
            role:'_',
            login:null,
            isUpdate:false
          });
        }

        this.setState({
          parentReference: parentReference
        });
       
        this.openModal();
    }

 
    ClearFields()
    {
      this.setState({
          name: '',
          email: '',
          role:'_',
          login: null
        });

    }

    HandleSelect(event) {

      var elements = event.split(",");
      var key = elements[0];
      var value = elements[1];
  
      var obj  = {}
      obj[key] = value
      this.setState(obj, () => {
        // Do anything after the state has changed   
       
      });
  
  
    }
  
  handleInputChange = (event) => {
  
  //  console.log("STOP 1");
    var formattedValue = event.target.value;
   // if (formattedValue =="") // This is not needed and instruces a bug
   // {
   //   formattedValue = "$0"; // This is not needed and instruces a bug
   // }

   let propertyName = event.target.name;

   //console.log("STOP 2:");

   //console.log("propertyName:");
   //console.log(propertyName);

   //console.log("formattedValue:");
   //console.log(formattedValue);

    this.setState({
      [propertyName]: formattedValue
    }, () => {
    
      //If number currency only use number value
      if (formattedValue.startsWith("$"))
      {
        var key = propertyName+"AsNumber"
        var val = Number(formattedValue.replace(/[^0-9\.-]+/g,""));  
        var obj  = {}
        obj[key] = val
        this.setState(obj, () => {
            // Do anything after the state has changed   
            
        });
      
      } 

    });

    
    
	};


  validate = () => {
  
    let isValid = true;

    if (this.state.name == null || this.state.name ==='')
    {
      alert("Please enter name");
      isValid = false;

    } else if (this.state.email == null || this.state.email ==='')
    {
      alert("Please enter email");
      isValid = false;

    } if (this.state.role == null || this.state.role ==='' || this.state.role ==='_')
    {
      alert("Please select role");
      isValid = false;
    }

    return isValid;
  }

  submitLogin = (event) => {
    
    event.preventDefault();
   	 
    if (this.validate())
    {
        const login = {
          name: this.state.name,
          firstName:parseOutFirstName(this.state.name),
          email: this.state.email,
          role:this.state.role,
          parentUser:this.state.user
        }

        //alert(JSON.stringify(this.state.user));

        //alert(JSON.stringify(login));

        this.setState({
          uiSaving: true
        });

        if (this.state.login == null)  //Insert
        {
          authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
          
          axios
            .post('/api/createAccountLogin', login)
            .then((response) => {
              
              if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
              {
                  localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                  localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              }

              console.log("Completed creating login");

              this.setState({
                uiSaving: false
              });

              this.closeModal();
              this.state.parentReference.reloadPage(); //We only need to reload page because we want the changes reflected in the top login dropdown
              
            })
            .catch((error) => {
              alert("An error occured while creating login. Try again later.");
              console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                } else 
                {
                  this.setState({
                    uiSaving: false
                });
      
                }
              
            });

          } else  //Update 
          {
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

            axios
            .post('/api/updateAccountLogin', login)
            .then((response) => {
              
              if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
              {
                  localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                  localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
              }

              console.log("Completed updating login");

              this.setState({
                uiSaving: false
              });

              this.closeModal();
              this.state.parentReference.reloadPage(); //We only need to reload page because we want the changes reflected in the top login dropdown
              
            })
            .catch((error) => {
              console.log(error);

                if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                } else 
                {
                  this.setState({
                    uiSaving: false
                });
      
                }
              
            });

          }

        } // end validate

	};

  render() {

    const { classes } = this.props;


      //Note: backdrop="static"  prevents the modal from closing when you click outside
      return (
      
          <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} backdrop="static" dialogClassName="property-edit-modal-width"> 

            <Modal.Header closeButton>
              <Modal.Title>

              {this.state.login == null  &&
                  <span>Add Login</span>
                }

                {this.state.login != null  &&
                  <span>Edit Login</span>
                }

              </Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal" ref={this.mainModalInfoDialogRef}>
            
              <div class="modal-body" style={{padding:"0px",margin:"0px"}}>
                    <div classx="container" style={{padding:"0px",margin:"0px"}}>

                    <div class="card2">
                                
                                <div class="card-body alert-edit-modal">

                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                            <form action="/action_page.php">
                                      
                                                {/* form row begins */}


                                                {/* Display Email */} 
                                                {this.state.isUpdate && 
                                                <div class="row">

                                                  <div class="col-md-7">
                                                      {this.state.email}
                                                      <br></br>
                                                  </div>

                                                </div>    
                                                }

                                                {/* ----- End Row ----*/}

                                                {/* Name */} 
                                                <div class="row">

                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                        <OverlayTrigger
                                                                  placement="auto"
                                                                  delay={{ show: 150, hide: 100 }} 
                                                                  trigger={["hover","click"]}
                                                                  overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                                
                                                                    <div className='helpText'>This is the name of person the account is for.</div>
                                                                </Tooltip>}
                                                                >
                                                                <div>
                                                                        <label class="dialog-form-label" for="name">Name</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                </div>
                                                            </OverlayTrigger>
                                                      
                                                            <input type="text" class="form-select alert-edit-modal-component" id="name" placeholder="i.e. John Doe" name="name" defaultValue={this.state.name} onChange={this.handleInputChange}/>
                                                            
                                                        </div>
                                                    </div>


                                                </div>    {/* ----- End Row ----*/}


                                                {/* Email */} 
                                                {!this.state.isUpdate && 
                                                <div class="row">

                                                  <div class="col-md-7">
                                                      <div class="form-group">
                                                      <OverlayTrigger
                                                                placement="auto"
                                                                delay={{ show: 150, hide: 100 }} 
                                                                trigger={["hover","click"]}
                                                                overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">
                                                              
                                                                  <div className='helpText'>This is the email the person will use to login.</div>
                                                              </Tooltip>}
                                                              >
                                                              <div>
                                                                      <label class="dialog-form-label" for="email">Email</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                              </div>
                                                          </OverlayTrigger>
                                                    
                                                          <input type="text" class="form-select alert-edit-modal-component" id="email" placeholder="i.e. johndoe@domain.com" name="email" defaultValue={this.state.email} onChange={this.handleInputChange}/>
                                                          
                                                      </div>
                                                  </div>


                                                </div>    
                                                }
                                                {/* ----- End Row ----*/}

                                                {/* Role */} 
                                                <div class="row">
                                                      <div class="col-md-7">
                                                                
                                                                <div class="form-group">
                                                                          <OverlayTrigger
                                                                                placement="auto"
                                                                                delay={{ show: 150, hide: 100 }} 
                                                                                trigger={["hover","click"]}
                                                                                overlay={<Tooltip  className="messagetooltip">
                                                                              
                                                                                  <div className='helpText'>The role you want the user to have.</div>
                                                                              </Tooltip>}
                                                                              >
                                                                              <div>
                                                                                      <label class="dialog-form-label" for="role">Role</label><InfoCircleFill className="ml-2 orangeInfo desktop-only-block" /><InfoCircleFill className="ml-2 orangeInfo mobile-only-block" size={30} /> 
                                                                              </div>
                                                                          </OverlayTrigger>
                                                                    
                                                                          <DropdownButton id="roleDropdownSelect" name="roleDropdownSelect" className="customDropdownSelectWrapper"  title={this.state.role} onSelect={(e) => this.HandleSelect(e)}>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['role', 'user']}>User</Dropdown.Item>
                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                              <Dropdown.Item className="dropdownitem"  eventKey={['role', 'admin']}>Admin</Dropdown.Item>

                                                                              <div style={{paddingBottom:"8px"}}></div>
                                                                             
                                                                          </DropdownButton>

                                                                      </div>
                                                                      
                                                                  </div>
                                                                
                                                </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>


                        
                                </div>
                    </div> 

        
                    <div class="modal-footer">
                                            <div class="container">
                                            <center>
                                                <div class="row">
                                                    <div class="col-md-5">&nbsp;</div>
                                                    <div class="col-md-2">
                                                      <button id="SaveButton" type="button" class="btn btn-primary btn-block footer-buttons" style={{marginTop:"5px"}} onClick={this.submitLogin}  >Save</button>
                                                    </div>
                                                
                                                    <div class="col-md-6">&nbsp;</div>
                                                  </div>
                                              </center>

                                            </div>




                    </div>

            </div>
                                                    
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>

            {this.state.uiSaving && 
              <main className={classes.content} style={{height:'1px'}}>
                  <div className={classes.toolbar} />
                  {this.state.uiSaving && <CircularProgress size={150} className={classes.uiProgess} />}
              </main>
            }    

          </Modal>
   
      );
      
    }
}

export default (withStyles(styles)(LoginEditDialog));
import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger ,Tooltip } from "react-bootstrap";


class PhotoDialog extends Component { 
    state = {
        isOpen: false, 
        property: {}
      };
   
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });

    constructor(props) {
        super(props);

        this.state = { 
            imageUrl : null
          };

     }

     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    PopulateDialog(property)
    {
        this.setState({          
          property:property
        });

        let imageUrl = property.propertyImage;

        //Zillow images
        //Use zillow high resolution image if it exists
        if (!property.hasOwnProperty('propertyImageHighResolution') || property.propertyImageHighResolution == null || property.propertyImageHighResolution === "")
        {
          if (imageUrl.endsWith("_192.jpg"))
          {
                const me = this;
                const highresImageUrl = imageUrl.replace("_192.jpg","_960.webp");
                this.ifUrlExist(highresImageUrl, function(exists) {
                  if (exists)
                  {
                    me.setState({          
                      imageUrl:highresImageUrl
                    });
                  }
                });
    
          }
        }

        //Use high resoltion image if it exists
        if (property.hasOwnProperty('propertyImageHighResolution') && property.propertyImageHighResolution != null && property.propertyImageHighResolution !== "")
        {
            const me = this;
            const url = property.propertyImageHighResolution;
            this.ifUrlExist(url, function(exists) {
              if (exists)
              {
                me.setState({          
                  imageUrl:url
                });
              }
            });

            this.setState({          
              imageUrl:imageUrl
          }, () => {
              this.openModal();
            });

        } else //If not high resolution image found 
        {
          this.setState({          
            imageUrl:imageUrl
        }, () => {
            this.openModal();
          });
        }

        
    }

    ifUrlExist(url, callback) {

      try
      {
          let request = new XMLHttpRequest;
          request.open('GET', url, true);
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          request.setRequestHeader('Accept', '*/*');
          request.onprogress = function(event) {

              //console.log("CAME BACK ******");
              let status = event.target.status;
              let statusFirstNumber = (status).toString()[0];

              //console.log("STATUS:"+status);
              //console.log("statusFirstNumber:"+statusFirstNumber);

              switch (statusFirstNumber) {
                  case '2':
                      request.abort();
                      return callback(true);
                  default:
                      request.abort();
                      return callback(false);
              };
          };

          request.onerror = function(event) {
              request.abort();
              return callback(false);
          };

          request.send('');
      } catch(error)
      {
          console.log("ERROR CHECKING IMAGE URL");
          console.log(error);
      }
  }


    render() {

        return (
            <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal} dialogClassName="detail-modal-width"> 

            <Modal.Header closeButton>
              <Modal.Title>Property Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body class="alert-edit-modal">
            
               <div class="modal-body" style={{padding:"0px",margin:"0px"}}>
                    <div classx="container" style={{padding:"0px",margin:"0px"}}>
                    
                        <img src={this.state?.imageUrl} style={{width:'100%',height:'%100'}}/>

                      </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
              <div style={{float:'left',width:'80%',color:'#000000'}}>
                {this.state.property?.address}<br></br>
                {this.state.property?.city}, {this.state.property?.state}, {this.state.property?.zipcode}
              </div>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
            </Modal.Footer>
          </Modal>

        );
    }
}

export default (PhotoDialog);

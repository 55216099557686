import React from 'react';
import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import IndicatorValue from './IndicatorValue';

const PropertyDetailPage = ({
	address,
	city,
	stateCode,
	zip,
	propertyImage,
	numberBedrooms,
	numberBathrooms,
	price,
	livingAreaSize,
	lotSize,
	yearBuilt,
	propertyType,
	financingType,
	term,
	loanType,
	closingCosts,
	downPayment,
	interestRate,
	repaymentType,
	monthlyPayment,
	monthlyGrossIncome,
	creditScore,
	monthlyBills,
	trendingPricePerSqft,
	trendingMonthlyRent,
}) => {
	return (
		<View style={styles.wrapper} break={true}>
			<Text style={[styles.address, styles.fwBold]}>
				{address}, {city}, {stateCode} {zip}
			</Text>
			<View style={styles.container}>
				<View style={[styles.elevatedCard, styles.propertyInfo]}>
					{propertyImage !=null && propertyImage !='' && 
					<Image style={styles.propertyImage} src={propertyImage} />
					}
					<View style={styles.propertyInfoList}>
						<View style={styles.propertyInfoItem}>
							<Text style={[[styles.fwBold, styles.mb4], styles.mb4]}>
								Beds
							</Text>
							<Text>{numberBedrooms}</Text>
						</View>
						<View style={styles.propertyInfoItem}>
							<Text style={[styles.fwBold, styles.mb4]}>Baths</Text>
							<Text>{numberBathrooms}</Text>
						</View>
						<View style={styles.propertyInfoItem}>
							<Text style={[styles.fwBold, styles.mb4]}>Sales Price</Text>
							<Text>
								<IndicatorValue value={price} valueType='DOLLAR_AMOUNT' />
							</Text>
						</View>
						<View style={styles.propertyInfoItem}>
							<Text style={[styles.fwBold, styles.mb4]}>Bldg Sq Ft</Text>
							<Text>
								{new Intl.NumberFormat('en-US').format(livingAreaSize)}
							</Text>
						</View>
						<View style={styles.propertyInfoItem}>
							<Text style={[styles.fwBold, styles.mb4]}>Lot Sq Ft</Text>
							<Text>{new Intl.NumberFormat('en-US').format(lotSize)}</Text>
						</View>
						<View style={styles.propertyInfoItem}>
							<Text style={[styles.fwBold, styles.mb4]}>Year Built</Text>
							<Text>{yearBuilt}</Text>
						</View>
						<View style={styles.propertyInfoItem}>
							<Text style={[styles.fwBold, styles.mb4]}>Type</Text>
							<Text>{propertyType}</Text>
						</View>
					</View>
				</View>

				<View style={[styles.elevatedCard]}>
					<View style={styles.cardHeader}>
						<Text style={styles.fwBold}>Financing</Text>
					</View>
					<View style={styles.cardBody}>
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>
								Financing Type
							</Text>
							<Text style={styles.field}>{financingType}</Text>
						</View>
						{financingType === 'Loan' && 
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>Down Payment</Text>
							<Text style={styles.field}>
								<IndicatorValue value={downPayment} valueType='DOLLAR_AMOUNT' />
							</Text>
						</View>
						}
						{financingType === 'Loan' &&
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>Loan Term</Text>
							<Text style={styles.field}>{term}</Text>
						</View>
						}
						{financingType === 'Loan' &&
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>Interest Rate</Text>
							<Text style={styles.field}>
								<IndicatorValue value={interestRate} valueType='PERCENT' />
							</Text>
						</View>
						}
						{financingType === 'Loan' &&
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>Loan Type</Text>
							<Text style={styles.field}>{loanType}</Text>
						</View>
						}
						{financingType === 'Loan' &&
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>
								Repayment Type
							</Text>
							<Text style={styles.field}>{repaymentType}</Text>
						</View>
						}
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>Closing Costs</Text>
							<Text style={styles.field}>
								<IndicatorValue
									value={closingCosts}
									valueType='DOLLAR_AMOUNT'
								/>
							</Text>
						</View>
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>
								Monthly Payment
							</Text>
							<Text style={styles.field}>
								<IndicatorValue
									value={monthlyPayment}
									valueType='DOLLAR_AMOUNT'
								/>
							</Text>
						</View>
					</View>
				</View>

				<View style={[styles.elevatedCard]}>
					<View style={styles.cardHeader}>
						<Text style={styles.fwBold}>Buyer's Financial Status</Text>
					</View>
					<View style={styles.cardBody}>
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>
								Monthly Gross Income
							</Text>
							<Text style={styles.field}>
								<IndicatorValue
									value={monthlyGrossIncome}
									valueType='DOLLAR_AMOUNT'
								/>
							</Text>
						</View>
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>Monthly Bills</Text>
							<Text style={styles.field}>
								<IndicatorValue
									value={monthlyBills}
									valueType='DOLLAR_AMOUNT'
								/>
							</Text>
						</View>
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>Credit Score</Text>
							<Text style={styles.field}>
								<IndicatorValue value={creditScore} />
							</Text>
						</View>
					</View>
				</View>

				<View style={[styles.elevatedCard]}>
					<View style={styles.cardHeader}>
						<Text style={styles.fwBold}>Area Current Trends</Text>
					</View>
					<View style={styles.cardBody}>
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>
								Trending Price Per Square Foot
							</Text>
							<Text style={styles.field}>
								<IndicatorValue
									value={trendingPricePerSqft}
									valueType='DOLLAR_AMOUNT'
								/>
							</Text>
						</View>
						<View style={styles.fieldRow}>
							<Text style={[styles.field, styles.fieldKey]}>
								Trending Monthly Rent
							</Text>
							<Text style={styles.field}>
								<IndicatorValue
									value={trendingMonthlyRent}
									valueType='DOLLAR_AMOUNT'
								/>
							</Text>
						</View>
					</View>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	wrapper: {
		marginTop: -24,
		marginBottom: -48,
		marginHorizontal: -24,
		flex: 1,
	},
	address: {
		padding: 12,
		fontSize: 12,
	},
	container: {
		backgroundColor: '#F3F3F3',
		paddingTop: 12,
		paddingBottom: 48,
		paddingHorizontal: 24,
		flexGrow: 1,
		fontSize: 11,
	},
	propertyInfo: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		fontSize: 10,
	},
	propertyInfoList: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		flex: 1,
		marginLeft: 4,
	},
	propertyInfoItem: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'flex-start',
		paddingHorizontal: 7,
	},
	propertyImage: {
		height: 60,
		width: 'auto',
	},
	elevatedCard: {
		backgroundColor: '#FFF',
		marginVertical: 10,
	},
	cardHeader: {
		borderBottom: '1px solid #ccc',
		textTransform: 'uppercase',
		paddingVertical: 7,
		paddingHorizontal: 14,
		fontWeight: 700,
	},
	cardBody: {
		padding: 14,
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	fieldRow: {
		display: 'flex',
		flexDirection: 'row',
		marginRight: '5%',
		width: '45%',
	},
	field: {
		flex: 1,
		borderBottom: '1px dotted #ccc',
		paddingVertical: 5,
		paddingHorizontal: 10,
	},
	fieldKey: {
		backgroundColor: '#f5f5f5',
		borderRight: '1px solid #ccc',
	},
	fwBold: {
		fontFamily: 'Helvetica-Bold',
	},
	mb4: {
		marginBottom: 4,
	},
});

export default PropertyDetailPage;

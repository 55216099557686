
const { plans } = require('../utilities/Constants');


function getPlanPriceIntegerPart(planName,frequency) {
	   
    let formattedPrice = "";

    if (planName != null)
    {
          const price = plans[planName].price[frequency];
       
          formattedPrice = "$"+Math.trunc(price);
    }

    return formattedPrice;
 }

 function getPlanPriceDecimalPart(planName,frequency) {
	   
    let formattedPrice = "";

    if (planName != null)
    {
          const price = plans[planName].price[frequency];
       
          if (Number.isInteger(price)) {
            formattedPrice = "00";
          }
        
          const decimalStr = price.toString().split('.')[1];
          formattedPrice = Number(decimalStr);
    }

    return formattedPrice;
 }

 const handleFrequencyChange = (event) => { 
    
    //alert(event.target.checked);
/*
    if (event.target.checked== true){

        props.frequency="annually";

    }else
    {
        props.frequency="monthly";
    }
*/

  }; 


const logOutUser = (event) => 
{
    localStorage.removeItem('AuthToken');
    localStorage.removeItem('RefreshToken');
    window.location.href = '/pricing';
}

function PricingPageHTML(props) {
    return (

<html lang="en">
    <head>
        <meta charset="UTF-8"/>
        <title>Pricing | GoRepa</title>
        <meta name="description" content="Compare GoRepa plans and choose the best for you. We have plans for everyone." />
        <meta name="keywords" content="real estate analysis pricing" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
     
        <meta property="og:url" content="https://www.gorepa.com/pricing.html" />
        <meta property="og:type" content="" />
        <meta property="og:title" content="Pricing | GoRepa" />
        <meta property="og:description" content="Compare GoRepa plans and choose the best for you. We have plans for everyone." />
        <meta property="og:image" content="" />
        <meta name="twitter:image" content="" />
        <link rel="canonical" href="https://www.gorepa.com/pricing.html" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        {/* <link rel="stylesheet" type="text/css" href="css/style.css" /> */}

        <script src="js/jquery-3.5.1.min.js"></script>
        <script type="text/javascript" src="js/sites-scripts.js"></script>
      
        {/*
      <link rel="stylesheet" type="text/css" href="css/page-specific/pricing.css" />
    */}
 
      </head>

      <body class="bodyStyle">

        <div class="pricePagecontent ">
        
            <div class="pricePagegr-encloser-cookie " id="cookie-policy-banner">
        
                <div class="pricePagegr-cookie-container ">
                    <div class="pricePagegr-cookie-enclosure ">
                        <div class="pricePagegr-cookie-head pricePagegr-space-fix ">
                            <div class="pricePagegr-cookie-title ">
                                This Site Uses Cookies
                            </div>
                            <div class="pricePagegr-cookie-permission " id="cookieAllow" onclick="cookieAccept();">
                                Allow
                            </div>
                        </div>
                        <div class="pricePagegr-cookie-content ">
                            <div>
                                We use cookies to enhance site navigation, analyze site usage, and assist us to improve your website experience as set out in our <a href="/cookie-policy.html">Cookie Policy</a>.
                            </div>
                                                                        </div>
                                                                                </div>
                </div>
            </div>
           
            {/* START Header */}

            <header class="pricePageheader " id="theHeader">

            <div class="pricePagerow pricePagegr-mobile-top-menu-encloser ">
            <div id="mobilePrimaryMenu">
                <div class="pricePagenavbar-header ">
                    <div class="pricePagegr-mobile-menu-wrap pricePagegr-space-fix ">
                        <div class="pricePagegr-mobile-menu-left-side pricePagegr-space-fix ">
                            <div class="pricePagegr-hamburger-button ">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div class="pricePagegr-mobile-menu-logo-container ">
                                <a href="/" title="">
                                    <img class="pricePagelogo pricePagescrollView " src={props.images.propertyAnalyticsLogo} alt="real estate property analytics" />
                                </a>
                            </div>
                        </div>
                        <div class="pricePagegr-mobile-menu-right-side pricePagegr-space-fix ">
                            <div class="pricePagegr-tablet-tick-contact-container ">
                                <a class="pricePagebtn pricePagenormal pricePagegr-gtitem-blue " href="/login">
                                                                             LOGIN
                                </a>
                            </div>
                            
                            {!props.isUserLoggedIn && 
                            <div class="pricePagegr-mobile-menu-signup-container ">
                                <a class="pricePagebtn pricePagenormal pricePagegreen " href="/pricing">
                                    SIGN UP for FREE
                                </a>
                            </div>
                            }

                            {props.isUserLoggedIn && 
                            <div class="pricePagegr-mobile-menu-signup-container ">
                                <div class="pricePagebtn pricePagenormal pricePagegreen " onClick={logOutUser}>
                                    LOGOUT
                                </div>
                            </div>
                            }
                            
                            <div class="pricePagegr-mobile-menu-login-container ">
                                <a href="/login">
                                    <img class="pricePagegr-menu-login-icon " src="images/login-icon-blue.png" alt="Login" border="0" />
                                </a>
                            </div>
                            <div class="pricePagegr-tablet-tick-button-container ">
                                <a href="/signup">
                                    <img class="pricePagegr-menu-login-icon " src="images/search-icon-blue.png" alt="Login" border="0" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="pricePagegr-tablet-special-search " id="tabletChannelSearch">
                        <div class="pricePagegr-tablet-tick-field-enclosure " id="channel-search">

                        </div>
                    </div>
                </div>
                <div class="pricePagenavbar-collapse pricePageignore-additional-spaces pricePagegr-mobile-secondary-menu ">
                    <ul class="pricePagenav pricePagenavbar-nav ">
        
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="SOLUTIONS">
                            <a data-path="SOLUTIONS" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                SOLUTION
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li class="pricePagemobileSubtitle ">
                                    <a href="/homebuyers.html" class="">
                                        BY INVESTOR TYPE
                                    </a>
                                </li>
                                <li>
                                    <a href="/homebuyers.html" class="">
                                        For Home Buyers
                                    </a>
                                </li>
                                <li>
                                    <a href="/property-flipping-investors.html" class="">
                                        For House Flipping Investors
                                    </a>
                                </li>
                                <li>
                                    <a href="/rental-property-investors.html" class="">
                                        For Rental Property Investors
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="COMPANY">
                            <a data-path="COMPANY" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                COMPANY         
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li>
                                    <a href="/about.html" class="">
                                        About Us
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        {/*
                        <li class="">
                            <a href="/pricing" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                PRICING
                            </a>
                        </li>
                        */}
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="Resources">
                            <a data-path="Resources" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                Resources
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li>
                                    <a href="/blog" >
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a href="/faq.html" class="">
                                        FAQ
                                    </a>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                        <li class="pricePagedropDown " id="Support">
                            <a data-path="Support" class="pricePagemenuNoLink pricePagedropdown-toggle ">
                                Support
                            </a>
                            <ul class="pricePagedropDownMobile ">
                                <li>
                                    <a href="/faq.html" >
                                        FAQ
                                    </a>
                                </li>
                       
                                <li>
                                    <a href="/contactus" >
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a href="/login" >
                                        Login
                                    </a>
                                </li>
                                
                            </ul>
                        </li>

                        <li>
                            <span class="pricePageseparator "></span>
                        </li>
                
                    </ul>
                    <div class="pricePagegr-mobile-point-buttonsWrap ">
                        <div class="pricePagegr-mobile-point-buttonsBox ">
                            <div class="pricePagegr-mobile-point-buttons ">
                                <a class="pricePagebtn pricePagenormal pricePagegr-gtitem-blue " href="/login">
                                                                                LOGIN
                                                                        </a>
                                                                    <a class="pricePagebtn pricePagenormal pricePagegreen " href="/signup">
                                    SIGN UP for FREE
                                </a>
                                                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pricePagerow pricePagegr-desktop-main-menu-wrap ">
            <div class="pricePagecontainer pricePageignore-additional-spaces pricePagegr-desktop-main-menu-container pricePagegr-space-fix ">
                <div class="pricePagegr-desktop-menu pricePageignore-additional-spaces ">
                    <div id="primaryMenu" class="pricePagenavbar " style={{minWidth:'100%',padding:'0px',margin:'0px'}}>
                        <ul class="pricePagenav pricePagenavbar-nav pricePagenav-pills pricePagegr-space-fix " style={{minWidth:'100%'}}>
                            <table style={{width:'100%'}}>
                                <tr>
                                    <td style={{float:'left'}}>
                                        <ul class="pricePagegr-main-menu-wrap pricePagegr-space-fix ">
                                        <li class="pricePagedesktopLogo pricePageignore-additional-spaces ">
                                            <a href="/" title="">
                                                <img class="pricePagelogo pricePagegr-normal-view " src={props.images.propertyAnalyticsLogoTransparent} alt="real estate property analytics" />
                                                <img class="pricePagelogo pricePagegr-scroll-view " src={props.images.propertyAnalyticsLogo} alt="real estate property analytics" />
                                            </a>
                                        </li>
                                        <li class="pricePagedropDown pricePagesolutions ">
                                            <a class="pricePagemenuNoLink pricePagedropdown-toggleX " data-hover="dropDown" data-toggle="dropDown">
                                                SOLUTION
                                            </a>
                                            <ul class="pricePagemenu-dropdown pricePagecol3 ">
                                                <li class="pricePagegr-space-fix pricePagegr-menu-nav-enclosure ">
                                                    <ul class="pricePagecol1 pricePagecolAll ">
                                                        <li class="pricePagegr-top-list ">
                                                            <a href="/homebuyers.html" class="">
                                                                BY INVESTOR TYPE
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                            <ul class="pricePagegr-menu-left-side ">
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/homebuyers.html" class="">
                                                                        For Home Buyers
                                                                    </a>
                                                                </li>
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/property-flipping-investors.html" class="">
                                                                        For House Flipping Investors
                                                                    </a>
                                                                </li>
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/rental-property-investors.html" class="">
                                                                        For Rental Property Investors
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="pricePagedropDown pricePagecompany ">
                                            
                                            <a class="menuNoLink pricePagedropdown-toggleX " data-hover="dropDown" data-toggle="dropDown">
                                                COMPANY
                                            </a>
                                         
                                            <ul class="pricePagemenu-dropdown pricePagecol2 ">
                                                <li class="pricePagegr-space-fix pricePagegr-menu-nav-enclosure ">
                                                    <ul class="pricePagecol1 pricePagecolAll ">
                                                        <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                            <ul class="pricePagegr-menu-left-side ">
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/about.html" class="">
                                                                        About Us
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        {/*
                                        <li class="pricePagegr-single-link pricePagepricing ">
                                            <a href="/pricing/" class="">
                                                PRICING
                                            </a>
                                        </li>
                                        */}
                                        </ul>
                                    </td>

                                    <td style={{float:'right'}}>
                                        <ul class="pricePagegr-main-menu-wrap pricePagegr-space-fix ">
                                        <li class="pricePagedropDown pricePageresources pricePagerightRefferer ">
                                            <a class="pricePagemenuNoLink pricePagedropdown-toggleX " data-hover="dropDown" data-toggle="dropDown">
                                                Resources
                                            </a>
                                            <ul class="pricePagemenu-dropdown pricePagegr-wrap-col2 pricePagegr-space-fix ">
                                                <li class="pricePagegr-wrap-col-left ">
                                                    <ul>
                                                        <li>
                                                            <ul class="pricePagecol1 pricePagecolAll ">
                                                                <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                                    <ul class="pricePagemenugr-left-side ">
                                                                        <li class="pricePagelevel2 ">
                                                                            <a href="/blog" >
                                                                                Blog
                                                                            </a>
                                                                        </li>
                                                                        <li class="pricePagelevel2 ">
                                                                            <a href="/faq.html" class="">
                                                                                FAQ
                                                                            </a>
                                                                        </li>
                                                               
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="pricePagedropDown pricePagesupport pricePagerightRefferer ">
                                            <a class="pricePagemenuNoLink ">
                                                Support
                                            </a>
                                            <ul class="pricePagemenu-dropdown pricePagecol2 ">
                                                <li class="pricePagegr-space-fix pricePagegr-menu-nav-enclosure ">
                                                    <ul class="pricePagecol1 pricePagecolAll ">
                                                        <li class="pricePagegr-middle-list pricePagegr-space-fix ">
                                                            <ul class="pricePagegr-menu-left-side ">
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/faq.html" >
                                                                        FAQ
                                                                    </a>
                                                                </li>
                                                     
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/contactus" >
                                                                        Contact Us
                                                                    </a>
                                                                </li>
                                                                <li class="pricePagelevel2 ">
                                                                    <a href="/login" >
                                                                        Login
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        
                                        <li class="pricePagegr-desktop-login pricePagegr-desktop-login-blue ">
                                            <a href="/login">
                                                <img class="pricePagegr-menu-login-icon " src={props.images.loginIconBlue} alt="Login" border="0"/>
                                                <span class="pricePagegr-menu-login-text ">
                                                                Login
                                                            </span>
                                            </a>
                                        </li>
                                        <li class="pricePagegr-desktop-login pricePagedesktopLoginWhite ">
                                            <a href="/login">
                                                <img class="pricePagegr-menu-login-icon " src={props.images.loginIconWhite} alt="Login" border="0"/>
                                                <span class="pricePagegr-menu-login-text ">
                                                                Login
                                                            </span>
                                            </a>
                                        </li>

                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-blue ">
                                            
                                            <a class="pricePagebtn pricePagegr-gtitem-blue " href="/login">
                                                LOGIN
                                            </a>
                                            
                                        </li>
                                        {props.isUserLoggedIn && 
                                        
                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-blue ">
                                            
                                            <a class="pricePagebtn pricePagegr-gtitem-blue "  onClick={logOutUser}>
                                                LOGOUT
                                            </a>
                                            
                                        </li>
                                        }

                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-white ">
                                                <a class="pricePagebtn pricePagegr-gtitem-white " href="/login">
                                                LOGIN
                                                </a>
                                        </li>

                                        {props.isUserLoggedIn && 
                                        <li class="pricePagegr-contact-more-info pricePagegr-contact-more-info-white ">
                                                <a class="pricePagebtn pricePagegr-gtitem-white " onClick={logOutUser}>
                                                LOGOUT
                                                </a>
                                        </li>
                                        }

                                        <li class="pricePagegr-signup-nomoney-menu ">
                                            <a class="pricePagebtn pricePagegreen " href="/signup">
                                                SIGN UP for FREE
                                            </a>
                                        </li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
    
                        </ul>
                    
                    </div>
                    
                    <div class="pricePagegr-desktop-search " id="desktopChannelSearch">
                        <div class="pricePagegr-desktop-search-item-enclosure " id="channel-search">

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </header>
            <div class="pricePagegr-space-fix "></div>
            {/* End Header */}
            {/*  Start Content  */}
            <div class="pricePagegr-enclose-all ">
                <div class="pricePagepricing ">

                <div class="pricePagegr-entry-page-rectangle-container pricePagegr-the-low-menu ">
        <div class="pricePagegr-entry-page-rectangle-wrap ">
        <div class="pricePagecontainer pricePageignore-additional-spaces ">
            <div class="pricePagegr-first-info-special-wrapper pricePagegr-space-fix ">
            <h1 class="pricePageintroPageBannerTitle pricePagegr-color-white pricePagetext-center ">
            Plans and Prices
            </h1>
            <h4 class="pricePagegr-section-title2 pricePagetext-center pricePagewhite ">
                Compare Plans and Choose the Best Solution for You.<br/>
                No Contracts. No Commitment. Cancel anytime.
            </h4>

            <div style={{paddingTop:'30px'}}>
                <center>
                <table>
                    <tr>
                    <td>

                        <input
                            className="react-switch-checkbox"
                            id={`react-switch-new`}
                            type="checkbox" 
                            onChange={props.handleFrequencyChange}
                        />
                        <label
                            className="react-switch-label"
                            htmlFor={`react-switch-new`}
                        >
                            <span className={`react-switch-button`} />
                        </label>

                    </td>
                    <td style={{color:'#ffffff',paddingLeft:'10px'}}><div style={{marginTop:'15px'}}>Pay annually and save up to 17%</div></td>
                    </tr> 
                </table>
                </center>
            </div>

            </div>
        </div>
        </div>

        </div>

        {/* START Section1  */}
        <div class="pricePagegr-encloser-gray pricePagegr-encloser-container pricePagesection1 ">
        <div class="pricePagecontainerPrice pricePageignore-additional-spaces ">
        <div class="pricePagerow pricePageignore-additional-spaces ">
            <div class="pricePagegr-enclose-pricing-container ">
            <div class="pricePagegr-pricing-sub-menu pricePagegr-space-fix ">
                <div class="pricePagegr-most-popular-wrap ">

                {/* First Plan  */}
                <div class="pricePagegr-pricing-sub-menu-item1 pricePagegr-pricing-sub-menuItemAll pricePagegr-tile-price-top-space ">
                    <div class="pricePagegr-most-popular-wrap pricePagegr-encloser-white pricePagegr-bottom-border-blue ">
                    <h5 class="pricePagegr-most-popular pricePagegr-encloser-blue pricePagewhite pricePagegr-spaces-0 pricePagefont400 ">
                        Lite
                    </h5>
                    <div class="pricePagegr-enclose-top-item ">
                        <div class="pricePagetext-center pricePagegr-desktop-min-height ">
                        Ideal for home buyers, real estate investors and real estate agents searching 
                        for a small number of properties. 
                        </div>
                        <h4 class="pricePagecolorBlueX pricePagefont500 pricePagetext-center pricePagegr-bottom-spaces-0 pricePagegr-price-amount-top-space ">
                        
                        <center>
                            <table>
                                <tr>
                                <td style={{fontSize:'45px',fontWeight:'bold'}}>{getPlanPriceIntegerPart('lite',props.frequency)}</td>
                                <td style={{padding:'0px',margin:'0px',paddingLeft:'5px'}}>
                                <div style={{paddingBottom:'-5px',marginBottom:'-5px',fontSize:'16px',fontWeight:'bold'}}>.{getPlanPriceDecimalPart('lite',props.frequency)}</div> <div style={{paddingBottom:'-15px',marginBottom:'-15px',fontSize:'16px'}}>monthly</div>
                                </td>
                                </tr>
                            </table>
                           
                        </center>

                        </h4>
                        <div class="pricePagegr-substansive-value pricePagetext-center " style={{paddingTop:'6px'}}>
                        Cancel Anytime
                        {props.frequency != "monthly" &&  
                              <div style={{fontWeight:'plain',fontSize:'14px',color:'blue'}}>
                                Billed {props.frequency}
                                </div>
                            }
                        </div>
                        
                        {!props.isUserLoggedIn && 
                                    
                        <div class="pricePagegr-signup-button pricePagetext-center ">
                            <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " href={"/signup?type=trial&plan=lite&frequency="+props.frequency}><span>Try it for FREE</span></a>
                        </div>
                        
                        }


                        {props.isUserLoggedIn && 
                            <span>
                            {(props.user.planType === "trial" || (props.user.planType !== "trial" && props.user.planName !== "lite")) && 
                                <div>
                                 
                                    <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " onClick={() => props.upgradeSubscription('subscription','lite',props.frequency)}><span>Upgrade</span></a>

                                </div>
                            }
                            {props.user.planType !== "trial" && props.user.planName === "lite" && 
                                <div style={{fontWeight:'bold', color:'blue',fontSize:'20px'}}>
                                    Your Current Plan
                                </div>
                            }

                            </span>
                        }

                        
                        <div class="pricePagegr-no-credit-card pricePagetext-center ">
                        or <a href={"/signup?type=subscription&plan=lite&frequency="+props.frequency} style={{fontSize:"17px",fontWeight:"bold"}}><u>Subscribe</u></a>
                        <br/>
                        <div style={{paddingTop:'5px'}}>
                        7 day free trial<br></br>    
                        No credit card required.
                        </div>

                        {/*
                        <br />
                        You'll only pay when you start a subscription.
                        */}
                        
                        </div>
                    </div>
                    <div class="pricePagepartnerTestWrap ">
                        <h6 class="pricePagefont300 pricePagegr-bottom-spaces-0 pricePagegr-top-spaces20 ">
                        What You Get
                        </h6>
                        <ul class="pricePagegr-spaces-0 ">
                        <li class="pricePageblueCheck ">{plans['lite'].logins} login{plans['lite'].logins > 1 && <span>s</span>}
                        </li>
                        <li class="pricePageblueCheck ">{plans['lite'].profiles} profiles
                        </li>
                        <li class="pricePageblueCheck ">{plans['lite'].advancedSeaches} advanced searches
                        </li>
                        <li class="pricePageblueCheck ">{plans['lite'].properties} properties
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>

                {/* Second Plan  */}
                <div class="pricePagegr-pricing-sub-menu-item2 pricePagegr-pricing-sub-menuItemAll ">
                    <div class="pricePagegr-most-popular-wrap pricePagegr-encloser-white pricePagegr-bottom-border-green ">
                    <h5 class="pricePagegr-most-popular pricePagegr-encloser-green pricePagewhite pricePagegr-spaces-0 pricePagefont400 ">
                        Standard
                    </h5>
                    <div class="pricePagegr-enclose-top-item ">
                        <h5 class="pricePagefont500 pricePagetext-center ">
                        <b>Most Popular</b>
                        </h5>
                        <div class="pricePagetext-center ">
                        Perfect for real estate investors with medium size portfolios and real estate agents with multiple clients.
                        </div>
                        <h4 class="pricePagecolorGreenX pricePagefont500 pricePagetext-center pricePagegr-bottom-spaces-0 pricePagegr-price-amount-top-space ">
 
                        <center>
                            <table>
                                <tr>
                                <td style={{fontSize:'45px',fontWeight:'bold'}}>{getPlanPriceIntegerPart('standard',props.frequency)}</td>
                                <td style={{padding:'0px',margin:'0px',paddingLeft:'5px'}}>
                                <div style={{paddingBottom:'-5px',marginBottom:'-5px',fontSize:'16px',fontWeight:'bold'}}>.{getPlanPriceDecimalPart('standard',props.frequency)}</div> <div style={{paddingBottom:'-15px',marginBottom:'-15px',fontSize:'16px'}}>monthly</div>
                                </td>
                                </tr>
                            </table>
                        </center>
                        </h4>
                        <div class="pricePagegr-substansive-value pricePagetext-center " style={{paddingTop:'6px'}}>
                        Cancel Anytime
                        {props.frequency != "monthly" &&  
                              <div style={{fontWeight:'plain',fontSize:'14px',color:'blue'}}>
                                Billed {props.frequency}
                                </div>
                            }
                        </div>
                        {!props.isUserLoggedIn && 
                                    
                                    <div class="pricePagegr-signup-button pricePagetext-center ">
                                        <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " href={"/signup?type=trial&plan=standard&frequency="+props.frequency}><span>Try it for FREE</span></a>
                                    </div>
                                    
                                    }
            
            
                                    {props.isUserLoggedIn && 
                                        <span>
                                        {(props.user.planType === "trial" || (props.user.planType !== "trial" && props.user.planName !== "standard")) && 
                                            <div>
                                             
                                                <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " onClick={() => props.upgradeSubscription('subscription','standard',props.frequency)}><span>Upgrade</span></a>
            
                                            </div>
                                        }
                                        {props.user.planType !== "trial" && props.user.planName === "standard" && 
                                            <div style={{fontWeight:'bold', color:'blue',fontSize:'20px'}}>
                                                Your Current Plan
                                            </div>
                                        }
            
                                        </span>
                                    }
                        <div class="pricePagegr-no-credit-card pricePagetext-center ">
                        or <a href={"/signup?type=subscription&plan=standard&frequency="+props.frequency} style={{fontSize:"17px",fontWeight:"bold"}}><u>Subscribe</u></a>
                        <br/>
                        <div style={{paddingTop:'5px'}}>
                        7 day free trial<br></br>    
                        No credit card required.
                        </div>
                        
                        
                        </div>
                    </div>
                    <div class="pricePagepartnerTestWrap ">
                        <h6 class="pricePagefont300 pricePagegr-bottom-spaces-0 pricePagegr-top-spaces20 ">
                        What You Get
                        </h6>
                        <ul class="pricePagegr-spaces-0 ">
                        <li class="pricePagegreenCheck ">{plans['standard'].logins} login{plans['standard'].logins > 1 && <span>s</span>}
                        </li>
                        <li class="pricePagegreenCheck ">{plans['standard'].profiles} profiles
                        </li>
                        <li class="pricePagegreenCheck ">{plans['standard'].advancedSeaches} advanced searches
                        </li>
                        <li class="pricePagegreenCheck ">{plans['standard'].properties} properties 
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                
                {/* Third Plan  */}
                <div class="pricePagegr-pricing-sub-menu-item3 pricePagegr-pricing-sub-menuItemAll pricePagegr-tile-price-top-space ">
                    <div class="pricePagegr-most-popular-wrap pricePagegr-encloser-white pricePagegr-bottom-border-purple ">
                    <h5 class="pricePagegr-most-popular pricePagegr-encloser-purple pricePagewhite pricePagegr-spaces-0 pricePagefont400 ">
                        Advanced
                    </h5>
                    <div class="pricePagegr-enclose-top-item ">

                        <div class="pricePagetext-center pricePagegr-desktop-min-height ">
                        Ideal for bankers, real estate agents with a large client base, and real estate investors investing nation-wide.
                        </div>
                        <h4 class="pricePagefont500 pricePagetext-center pricePagegr-bottom-spaces-0 pricePagegr-price-amount-top-space ">
                        <center>
                            <table>
                                <tr>
                                <td style={{fontSize:'45px',fontWeight:'bold'}}>{getPlanPriceIntegerPart('advanced',props.frequency)}</td>
                                <td style={{padding:'0px',margin:'0px',paddingLeft:'5px'}}>
                                <div style={{paddingBottom:'-5px',marginBottom:'-5px',fontSize:'16px',fontWeight:'bold'}}>.{getPlanPriceDecimalPart('advanced',props.frequency)}</div> <div style={{paddingBottom:'-15px',marginBottom:'-15px',fontSize:'16px'}}>monthly</div>
                                </td>
                                </tr>
                            </table>
                        </center>
                        </h4>
                        <div class="pricePagegr-substansive-value pricePagetext-center " style={{paddingTop:'6px'}}>
                        Cancel Anytime
                        {props.frequency != "monthly" &&  
                              <div style={{fontWeight:'plain',fontSize:'14px',color:'blue'}}>
                                Billed {props.frequency}
                                </div>
                            }
                        </div>
                        {!props.isUserLoggedIn && 
                                    
                                    <div class="pricePagegr-signup-button pricePagetext-center ">
                                        <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " href={"/signup?type=trial&plan=enterprise&frequency="+props.frequency}><span>Try it for FREE</span></a>
                                    </div>
                                    
                                    }
            
            
                                    {props.isUserLoggedIn && 
                                        <span>
                                        {(props.user.planType === "trial" || (props.user.planType !== "trial" && props.user.planName !== "enterprise")) && 
                                            <div>
                                             
                                                <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " onClick={() => props.upgradeSubscription('subscription','enterprise',props.frequency)}><span>Upgrade</span></a>
            
                                            </div>
                                        }
                                        {props.user.planType !== "trial" && props.user.planName === "enterprise" && 
                                            <div style={{fontWeight:'bold', color:'blue',fontSize:'20px'}}>
                                                Your Current Plan
                                            </div>
                                        }
            
                                        </span>
                                    }
            
            
                        <div class="pricePagegr-no-credit-card pricePagetext-center ">
                        or <a href={"/signup?type=subscription&plan=advanced&frequency="+props.frequency} style={{fontSize:"17px",fontWeight:"bold"}}><u>Subscribe</u></a>
                        <br/>
                        <div style={{paddingTop:'5px'}}>
                        7 day free trial<br></br>    
                        No credit card required.
                        </div>
                        
                        
                        </div>
                    </div>
                    <div class="pricePagepartnerTestWrap ">
                        <h6 class="pricePagefont300 pricePagegr-bottom-spaces-0 pricePagegr-top-spaces20 ">
                        What You Get
                        </h6>
                        <ul class="pricePagegr-spaces-0 ">
                        <li class="pricePagepurpleCheck ">{plans['advanced'].logins} login{plans['advanced'].logins > 1 && <span>s</span>}
                        </li>
                        <li class="pricePagepurpleCheck ">{plans['advanced'].profiles} profiles
                        </li>
                        <li class="pricePagepurpleCheck ">{plans['advanced'].advancedSeaches} advanced searches
                        </li>
                        <li class="pricePagepurpleCheck ">{plans['advanced'].properties} properties
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>

                {/* Fourth Plan  */}
                <div class="pricePagegr-pricing-sub-menu-item4 pricePagegr-pricing-sub-menuItemAll pricePagegr-tile-price-top-space ">
                    <div class="pricePagegr-most-popular-wrap pricePagegr-encloser-white pricePagegr-bottom-border-dark-purple pricePagegr-left-border ">
                    <h5 class="pricePagegr-most-popular pricePagegr-encloser-dark-purple pricePagewhite pricePagegr-spaces-0 pricePagefont400 ">
                        Enterprise
                    </h5>
                    <div class="pricePagegr-enclose-top-item ">
                        
                        <div class="pricePagetext-center pricePagegr-desktop-min-height ">
                        Ideal for investment companies, large real estate agencies, and banks.
                        </div>
                        <h4 class="pricePagecolorDarkPurpleX pricePagefont500 pricePagetext-center pricePagegr-bottom-spaces-0 pricePagegr-price-amount-top-space ">
                        <center>
                            <table>
                                <tr>
                                <td style={{fontSize:'45px',fontWeight:'bold'}}>{getPlanPriceIntegerPart('enterprise',props.frequency)}</td>
                                <td style={{padding:'0px',margin:'0px',paddingLeft:'5px'}}>
                                <div style={{paddingBottom:'-5px',marginBottom:'-5px',fontSize:'16px',fontWeight:'bold'}}>.{getPlanPriceDecimalPart('enterprise',props.frequency)}</div> <div style={{paddingBottom:'-15px',marginBottom:'-15px',fontSize:'16px'}}>monthly</div>
                                </td>
                                </tr>
                            </table>
                        </center>
                        </h4>
                        <div class="pricePagegr-substansive-value pricePagetext-center " style={{paddingTop:'6px'}}>
                        Cancel Anytime
                        {props.frequency != "monthly" &&  
                              <div style={{fontWeight:'plain',fontSize:'14px',color:'blue'}}>
                                Billed {props.frequency}
                                </div>
                            }
                        </div>
                        {!props.isUserLoggedIn && 
                                    
                                    <div class="pricePagegr-signup-button pricePagetext-center ">
                                        <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " href={"/signup?type=trial&plan=advanced&frequency="+props.frequency}><span>Try it for FREE</span></a>
                                    </div>
                                    
                                    }
            
            
                                    {props.isUserLoggedIn && 
                                        <span>
                                        {(props.user.planType === "trial" || (props.user.planType !== "trial" && props.user.planName !== "advanced")) && 
                                            <div>
                                             
                                                <a class="pricePagebtn pricePagenormal pricePageblue pricePagegr-spaces-a " onClick={() => props.upgradeSubscription('subscription','advanced',props.frequency)}><span>Upgrade</span></a>
            
                                            </div>
                                        }
                                        {props.user.planType !== "trial" && props.user.planName === "advanced" && 
                                            <div style={{fontWeight:'bold', color:'blue',fontSize:'20px'}}>
                                                Your Current Plan
                                            </div>
                                        }
            
                                        </span>
                                    }
                        <div class="pricePagegr-no-credit-card pricePagetext-center ">
                        or <a href={"/signup?type=subscription&plan=enterprise&frequency="+props.frequency} style={{fontSize:"17px",fontWeight:"bold"}}><u>Subscribe</u></a>
                        <br/>
                        <div style={{paddingTop:'5px'}}>
                        7 day free trial<br></br>    
                        No credit card required.
                        </div>
                 
                        
                        </div>
                    </div>
                    <div class="pricePagepartnerTestWrap ">
                        <h6 class="pricePagefont300 pricePagegr-bottom-spaces-0 pricePagegr-top-spaces20 ">
                        What You Get
                        </h6>
                        <ul class="pricePagegr-spaces-0 ">
                        <li class="pricePagegr-dark-purple-check ">{plans['enterprise'].logins} login{plans['enterprise'].logins > 1 && <span>s</span>}
                        </li>
                        <li class="pricePagegr-dark-purple-check ">{plans['enterprise'].profiles} profiles
                        </li>
                        <li class="pricePagegr-dark-purple-check ">{plans['enterprise'].advancedSeaches} advanced searches
                        </li>
                        <li class="pricePagegr-dark-purple-check ">{plans['enterprise'].properties} properties
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>

                </div>
            </div>
            </div> 
            <div class="pricePagegr-special-pricing-submenu-enclosureBox pricePagegr-top-spaces40 " style={{backgroundColor:'#583bec',width:'100%'}}>
            <div class="pricePagegr-special-pricing-submenu-enclosure ">
                <div class="pricePagegr-enclose-top-item pricePagegr-space-fix ">
                <div class="pricePagecolItemAll pricePagecolItem1 ">
                    <h5 class="pricePagecolorBlueLight pricePagefont500 pricePagewhite pricePagegr-top-spaces0 pricePagegr-bottom-spaces-a " style={{color:'#ffffff'}}>
                    Custom Pricing
                    </h5>
                    <div class="pricePagewhite ">
                    We provide customized plans to fit your specific needs specifically for bigger companies that require more than 10 logins.
                    </div>
                </div>
        
                <div class="pricePagecolItemAll pricePagecolItem5 ">
                    <div class="pricePagegr-signup-button pricePagetext-center ">
                    <a class="pricePagebtn pricePagenormal pricePagegr-gtitem-light-blue pricePagegr-spaces-a " href="/contactus" style={{backgroundColor:'#ffffff',fontWeight:'bold'}}><span>CONTACT SALES</span></a>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pricePagegr-top-spaces40 pricePagedetailsDecide " id="openPrincigDetails" style={{width:'100%'}}>
            <h4 class="pricePagegr-top-spaces0 pricePagetext-center ">
                Can't decide which plan is right for you?
            </h4>
            <div class="pricePagecomparationBox pricePagegr-space-fix ">



                <div class="pricePagegr-comparing-plans pricePagegr-future-left-side ">

                <div class="pricePagegr-future-title " style={{textAlign:'center'}}>
                    No worries, you can change your plan any time without skipping a beat.
                </div>
                
                </div>

            </div>
            </div>
        </div>
        </div>
        </div>
        {/* END Section1  */}

        {/* START Section2  */}
        <div class="pricePagesection3 pricePagegr-encloser-gray pricePagegr-container1 ">
        <div class="pricePagecontainer pricePageignore-additional-spaces ">
        <div class="pricePagerow pricePageignore-additional-spaces ">
            <div class="pricePagegr-informational-client-whiteWrap " style={{width:'100%'}}>
            <div class="pricePagegr-informational-client-white-enclosure pricePagegr-space-fix ">
                <div class="pricePagecol-md-2 pricePagegr-spaces-a pricePagegr-informational-client-whiteTitle ">
                Included in All Plans:
                </div>
                <div class="pricePagecol-md-2 pricePagegr-spaces-a pricePagegr-informational-client-white ">
                <span class="pricePagegr-our-care-image "><img alt="Property Analysis" border="0" class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.propertyAnalyticsFeatureBlue} /></span> <span class="pricePagegr-our-care-text ">Property
                <br />
                Analytics </span>
                </div>
                <div class="pricePagecol-md-2 pricePagegr-spaces-a pricePagegr-informational-client-white ">
                <span class="pricePagegr-our-care-image "><img alt="Advanced Searches" border="0" class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.advancedSearchesBlue} /></span> <span class="pricePagegr-our-care-text ">Advanced
                <br />
                Searches</span>
                </div>
                <div class="pricePagecol-md-2 pricePagegr-spaces-a pricePagegr-informational-client-white ">
                <span class="pricePagegr-our-care-image "><img alt="Reporting and Analytics" border="0" class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.reportingAndAnalyticsGreen} /></span> <span class="pricePagegr-our-care-text ">Reporting &amp;
                <br />
                Metrics</span>
                </div>
                <div class="pricePagecol-md-2 pricePagegr-spaces-a pricePagegr-informational-client-white ">
                <span class="pricePagegr-our-care-image "><img alt="Multiple Profiles" border="0" class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.profilesFeatureBlue} /></span> <span class="pricePagegr-our-care-text ">Multiple
                <br />
                Profiles</span>
                </div>
                <div class="pricePagecol-md-2 pricePagegr-spaces-a pricePagegr-informational-client-white ">
                <span class="pricePagegr-our-care-image "><img alt="Financial Indicators" border="0" class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.financialIndicatorsFeatureRed} /></span> <span class="pricePagegr-our-care-text ">Financial
                <br />
                Indicators</span>
                </div>
            </div>
            </div>
        </div>
        </div>
        </div>
        
        {/* END Section3  */}
        
        {/* START Section4  */}
        <div class="pricePagegr-space-fix pricePagesection4 pricePagegr-encloser-blue ">
        <div class="pricePagecontainer pricePageignore-additional-spaces ">
        <div class="pricePagerow pricePageignore-additional-spaces ">
            <h4 class="pricePagewhite pricePagetext-center pricePagegr-spaces-b " style={{textAlign:'center',width:'100%'}}>
            <div style= {{fontSize:'25px',fontWeight:'bold'}}>100% RISK FREE</div>
            This is why so many people and companies trust us
            </h4>
                <div class="pricePagegr-informational-client-whiteWrap ">
                    <div class="pricePagegr-informational-client-white-enclosure pricePagegr-space-fix pricePagegr-multiple-sections-5-enclosure ">
                        <div class="pricePagecol-md-2 pricePagegr-spaces-a pricePagewhite pricePagegr-informational-client-whiteTitle ">
                            Trusted by home buyers, real estate investors, realtors and bankers
                        </div>
            
                        <div class="pricePagecol-md-2 pricePagegr-spaces-b pricePagegr-informational-client-white ">
                            <a class="pricePagegr-icon-effect " href="/pricing">
                                <img class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.freeTrial} alt="Free trial" border="0" style={{width:'135px',height:'119px'}}/>
                            </a>
                        </div>
                    
                        <div class="pricePagecol-md-2 pricePagegr-spaces-b pricePagegr-informational-client-white ">
                            
                                <img class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.moneyBackGuarantee} alt="Money back guarantee" border="0"/>
                           
                        </div>
                    
                        <div class="pricePagecol-md-2 pricePagegr-spaces-b pricePagegr-informational-client-white ">
                           
                                <img class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.cancelAnyTime} alt="Cancel anytime" border="0" style={{width:'200px',height:'131px'}}/>
                           
                        </div>
                    
                        <div class="pricePagecol-md-2 pricePagegr-spaces-b pricePagegr-informational-client-white ">
                          
                                <img class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.satisfactionGurantee} alt="satisfaction gurantee" border="0" style={{width:'125px',height:'108px'}}/>
                           
                        </div>
                    
                        <div class="pricePagecol-md-2 pricePagegr-spaces-b pricePagegr-informational-client-white ">
                            
                                <img class="pricePagegr-image-responsive pricePagecenter-block " src={props.images.securedSsl} alt="Secured SSL" border="0" />
                            
                        </div>
                    
                    </div>
                </div>
        </div>
        </div>
        </div>
        
        {/* END Section4  */}
        
        {/* START Section6  */}
        <div class="pricePagewrapper pricePagesection6 pricePagegr-encloser-white pricePagegr-encloser-container ">
        <div class="pricePagecontainer pricePagenopadd ">
        <div class="pricePagerow ">
            <h2 class="pricePagegr-top-spaces0 pricePagetext-center pricePagegr-bottom-spaces-c " style={{width:'100%'}}>
            Frequently Asked Questions
            </h2>
            <div class="pricePagegr-enclose-team-enclosure ">
            <div class="pricePagegr-space-fix ">
                <div class="pricePagecol-sm-6 ">
                <div class="pricePageitemAll pricePageitem1 ">
                    <div>
                    <h5 class="pricePagequestion ">
                        1. Do I need a credit card to try it?
                    </h5>
                    <p class="pricePageanswer pStyle">
                    No, you don't need a credit card to try GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>. All you need to do is create a free account and you are ready to go. At the end of the trial period you decide whether you want to enroll in a paid subscription plan or not. 
                    </p>
                    </div>
                </div>
                <div class="pricePageitemAll pricePageitem2 ">
                    <div>
                    <h5 class="pricePagequestion ">
                        2. What happens after the free trial period ends?
                    </h5>
                    <p class="pricePageanswer pStyle">
                        At the end your free trial you will have the option to upgrade to a paid subscription plan. This doesn't happen automatically, we will never enroll your in a paid plan automatically. You have to initiate the upgrade yourself at your discretion.
                    </p>
                    </div>
                </div>
                <div class="pricePageitemAll pricePageitem3 ">
                    <div>
                    <h5 class="pricePagequestion ">
                        3. Are there any long-term contracts or hidden fees?
                    </h5>
                    <p class="pricePageanswer pStyle">
                        There are no contracts or long-term commitments, you can cancel anytime. There are no hidden fees and no extra charges. You only pay exactly the amount of your subscription plan. Your subscription plan will be either charged monthly or annually based your choice.
                    </p>
                    </div>
                </div>
                </div>
                <div class="pricePagecol-sm-6 ">
                <div class="pricePageitemAll pricePageitem4 ">
                    <div>
                    <h5 class="pricePagequestion ">
                        4. Can I cancel my subscription at any time?
                    </h5>
                    <p class="pricePageanswer pStyle">
                        Yes, you can cancel your paid subscription at anytime. There are no contracts or long-term commitments. 
                    </p>
                    </div>
                </div>
                <div class="pricePageitemAll pricePageitem5 ">
                    <div>
                    <h5 class="pricePagequestion ">
                        5. I have more than 10 employees, what plan is right for me?
                    </h5>
                    <p class="pricePageanswer pStyle">
                        For companies with more than 10 employee simply contact our <a href="mailto:sales@gorepa.com">Sales Team</a> and we will create a custom plan for your specific needs.
                    </p>
                    </div>
                </div>
                <div class="pricePageitemAll pricePageitem6 ">
                    <div>
                    <h5 class="pricePagequestion ">
                        6. Is my personal data or credit card stored anywhere?
                    </h5>
                    <p class="pricePageanswer pStyle">
                        No, we don't store your personal information or credit card in our system. Your payment is handled directly my our payment processor. We use <b>Stripe</b> as our payment processor, which adheres to the standards set by <b>PCI-DSS</b> as managed by the PCI Security Standards Council. You can read about Stripe's PCI-DSS compliance <a href="https://stripe.com/guides/pci-compliance" >here</a>.
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </div>
        
        {/* END Section6  */}
        </div>
                {/* Stop Loading Content  */}

                {/* Start Loading Bottom Blocks  */}
                <div class="pricePagegr-enclose-g-container pricePagegr-enclosed-rotate-wrap ">
                <div class="pricePagegr-enclosed-rotate ">
                    <div class="pricePagecontainer pricePageignore-additional-spaces ">
                        <div class="pricePagerow pricePagegr-space-fix ">
                            <div class="pricePagecol-md-8 pricePagegr-footer-left pricePagetextX pricePageignore-additional-spaces ">
                                <div>
                                    <div class="pricePagegr-start-text-enclosure pricePagetext-center ">
                                        <h5 class="pricePagewhite pricePagegr-spaces-0 ">
                                            Simplify your property search and analysis efforts. Try GoRepa<sup style={{fontsize:'12px'}}>&#174;</sup>.
                                        </h5>
                                        <div class="pricePagewhite ">
                                            The only real estate analytics platform that allows you to pick properties like you pick stocks with green and red signals.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pricePagecol-md-4 pricePagegr-footer-right pricePageignore-additional-spaces ">
                                <div>
                                    <div class="pricePagegr-start-button-enclosure pricePagetext-center ">
                                        <a class="pricePagebtn pricePagenormal pricePagegr-gtitem-white " href="/login">LOGIN</a>
                                        <a class="pricePagebtn pricePagenormal pricePagegreen " href="/signup">SIGN UP for FREE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
                {/* Stop Loading Bottom Blocks  */}
            </div>
            {/* Stop Content  */}

                    {/* Start Template Footer  */}
                    <footer class="pricePagev2 ">
                    <div class="pricePagefooter ">
                        <div class="">
                            <div class="pricePagerow ">
                                <div class="pricePagecontainer pricePageignore-additional-spaces ">
                                    <div class="pricePagegr-footer-wrap " style={{width:'100%'}}>
                                        <div class="pricePagegr-footer-top-wrap pricePagegr-space-fix ">
                                            <div class="pricePagegr-footer-top pricePagegr-space-fix ">
                                                <div class="pricePagecolAll pricePagecol1 ">
                                                    <ul class="pricePagegr-footer-enclose-wrap pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-logo-wrap ">
                                                            <a class="pricePagegr-footer-enclose-logo " href="/">
                                                                <img class="pricePagelogo "style={{width:'55px'}} src={props.images.propertyAnalyticsLogoTransparent} alt="real estate property analytics" border="0"/>
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-title pricePagegr-footer-enclose-1 ">
                                                            <h5 class="pricePagegr-top-spaces10 pricePagegr-bottom-spaces-0 ">
                                                                Real Estate Property Analytics. Simplified.
                                                            </h5>
                                                        </li>
                                        
                                                        <li class="pricePagegr-social-box pricePagegr-space-fix pricePagegr-bottom-spaces-b ">
                                                            <ul class="pricePagesocial pricePagegr-space-fix ">
                                                                <li>
                                                                    <a class="pricePagegr-footer-flickr " href="https://www.instagram.com/gorepa.analytics/"  target="_blank">
                                                                        <img alt="instagram" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={props.images.instagramFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="pricePagegr-footer-linkedin " href="https://www.linkedin.com/company/gorepa/"  target="_blank">
                                                                        <img alt="linkedin" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={props.images.linkedinFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="pricePagegr-footer-twitter " href="https://www.twitter.com/GoRepaAnalytics"  target="_blank">
                                                                        <img alt="twitter" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={props.images.twitterFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="pricePagegr-footer-facebook " href="https://www.facebook.com/GoRepaAnalytics"  target="_blank">
                                                                        <img alt="facebook" border="0" class="pricePagegr-social-image pricePagegr-social-imageDark " src={props.images.facebookFooterWhite}/>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                        
                                                <div class="pricePagecolAll pricePagecol3 ">
                                                    <ul class="pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                SOLUTION
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/homebuyers.html">
                                                                For Homebuyers
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/property-flipping-investors.html">
                                                                For Property Flipping Investors
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/rental-property-investors.html">
                                                                For Rental Property Investors
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="pricePagecolAll pricePagecol4 ">
                                                    <ul class="pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                COMPANY
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/about.html">
                                                                About Us
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="pricePagecolAll pricePagecol5 ">
                                                    <ul class="pricePagefooterNav pricePagegr-second-level ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                RESOURCES
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/blog" >
                                                                Blog
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/faq.html">
                                                                FAQ
                                                            </a>
                                                        </li>
                                               
                                                    </ul>
                                                </div>
                                                <div class="pricePagecolAll pricePagecol6 ">
                                                    <ul class="pricePagefooterNav ">
                                                        <li class="pricePagegr-footer-enclose-1 ">
                                                            <a class="pricePagemenuNoLink ">
                                                                CONTACT US
                                                            </a>
                                                        </li>
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/login">
                                                                Login
                                                            </a>
                                                        </li>
                                                  
                                                        <li class="pricePagegr-footer-enclose-2 ">
                                                            <a href="/contactus">
                                                                Contact Us
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pricePagegr-footer-bottom-wrap ">
                                            <ul class="pricePagegr-space-fix ">
                                                <li class="pricePagegr-footer-enclose-policy pricePagegr-space-fix ">
                                                    <a href="/privacy.html">
                                                        Privacy Policy
                                                    </a>
                                                    <a href="/terms-of-service.html" >
                                                        Terms and Conditions
                                                    </a>
                                                </li>
                                                <li class="pricePagegr-footer-copyright ">
                                                    &#169; 2023 Copyright GoRepa<sup style={{fontsize:'12px'}}>&#174;</sup>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            


                </div>



        <script type="text/javascript" src="js/jquery.colorbox-min.js"></script>
        
</body>

</html>

);
};

const _PricingPageHTML = PricingPageHTML;
export { _PricingPageHTML as PricingPageHTML };
import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';

//import appLogo from '../images/logo-nocircle.png';

import Dialog from 'react-bootstrap-dialog'


import appLogo from './img/website-images/real-estate-property-analytics-logo.png';

import axios from 'axios';


const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#e37627'
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	}

});

class login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			errors: [],
			alertOpen: false,
			alertMessage: '',
			alertTitle:'',
			loading: false
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.UI.errors) {
			this.setState({
				errors: nextProps.UI.errors
			});
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};


	setAlertOpen = (flag) => {

		this.setState({ alertOpen: flag });
	}

	handleSubmit = (event) => {
		event.preventDefault();

		console.log("About to submit password reset");

		localStorage.removeItem('menuItem')

		this.setState({ loading: true });
		const userData = {
			email: this.state.email
		};
		axios
			.post('/api/checkUserExists', userData)
			.then((response) => {
				this.setState({ 
					loading: false,
				});		
		
				console.log("Came back from password reset:"+JSON.stringify(response.data));

				if (response.data.exists)
				{
					//Reset password
					axios
					.post('/api/resetPassword', userData)
					.then((response) => {
					
						//alert(JSON.stringify(response));
						
						if (response.data.result && response.data.result ==='success')
						{
							this.dialog.show({
								title: "Password Reset",
								body: "An email has been sent to you to set your new password.",
								actions: [
								  Dialog.OKAction(() => {
									//console.log('OK was clicked!')
									window.location.href ="/login";
								  })
								],
								bsSize: 'small',
								onHide: (dialog) => {
								  dialog.hide()
								  window.location.href ="/login";
								}
							  });

						}else
						{
							this.displayAlert("Password Reset Error","An error occurred while reseting your password, please try again later.");
						}
		
					})
					.catch((error) => {	
					
					
						console.log(error);		

						this.displayAlert("Password Reset Error","An error occurred while reseting your password, please try again later.");

							
					});

				}else
				{
					this.displayAlert("Email Not Found","Please enter the email address you used when you created your account.");
				}

			})
			.catch((error) => {		
				//alert(error);	
				console.log(error);
				if (error && error.response && error.response.data)
				{	
					this.setState({
						errors: error.response.data,
						loading: false
					});
				}
			});
	};

	goHome = () => {
		window.location.href ="/";
	};

	displayAlert = (title,message) => {

		this.dialog.show({
			title: title,
			body: message,
			actions: [
			  Dialog.OKAction(() => {
				//console.log('OK was clicked!')
				
			  })
			],
			bsSize: 'small',
			onHide: (dialog) => {
			  dialog.hide()
			  
			}
		  });
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#e37627' //your color
			  }
			}
		  });

		const { classes } = this.props;
		const { errors, loading } = this.state;
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>

				<img src={appLogo} style={{height:'85px',cursor:'pointer',paddingBottom:"10px"}} onClick={this.goHome}/> 
				
				{/*<img src={appLogo} style={{height:'45px',cursor:'pointer'}} onClick={this.goHome}/> 
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#e37627',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
					Real Estate Analysis Application
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>

					*/}

					<Typography component="h1" variant="h5">
					Reset Your Password
					</Typography>

					<br></br>
					<div style={{textAlign:'jutify'}}>
						Enter the email address you used when you created your TrafficInfuser account.
					</div>

					<form className={classes.form} noValidate>
						<ThemeProvider theme={theme}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							type="regular"
							autoComplete="email"
							autoFocus
							helperText={errors.email}
							error={errors.email ? true : false}
							onChange={this.handleChange}
						/>
				
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary" 
							style={{color:'#FFFFFF'}}
							className={classes.submit}
							onClick={this.handleSubmit}
							disabled={loading || !this.state.email}
						>
							Reset Password
							{loading && <CircularProgress size={30} className={classes.progess} />}
						</Button>
						<Grid container>
							<Grid item>
									<Link href="login" variant="body2">
										<strong>Back to Log In</strong>
									</Link>
							</Grid>
						</Grid>
						{errors.general && (
							<Typography variant="body2" className={classes.customError}>
								{errors.general}
							</Typography>
						)}
						</ThemeProvider>
					</form>


					<Dialog ref={(component) => { this.dialog = component }}/> 
				</div>
			</Container>
		);
	}
}

export default withStyles(styles)(login);

import React, { Component } from 'react';

import thumbsUpIcon from '../pages/img/icons/green-thumbs-up-circle.png';
import thumbsDownIcon from '../pages/img/icons/red-thumbs-down-cicle.png';

import {Line} from 'react-chartjs-2';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import { authMiddleWare } from '../utilities/Auth';

import { Helmet } from "react-helmet";

//import  Metrics  from '../utilities/Metrics';
import * as Metrics from '../utilities/Metrics';

//import BLO from '../utilities/BLO';
import * as BLO from '../utilities/BLO';

import axios from 'axios';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
});

class Charts extends Component {

    state = {
        properties:[],
        uiLoading: true       
    };

    lineChartLabels = [];
    lineChartGreenDataPoints = [];
    lineChartRedDataPoints =[];

    constructor(props) {
        super(props);

        this.state = {
            lowestPrice:0,
            lowestPriceAddress:{},
            highestPrice:0,
            highestPriceAddress:{},
            budget:0,
            mostGreenIndicatorProperties:[],
            bestPerformingIndicators:[],
        };    

    }

    componentDidUpdate = (prevProps) => {
        
        if (prevProps.investmentType !== this.props.investmentType  ||  prevProps.profileId !== this.props.profileId) {

            const metrics = Metrics.aggregateMetrics(this.state.properties, this.props.investmentType, this.props.profileId, this.props.profile);

            this.lineChartLabels            = metrics?.mostGreenIndicatorsByLocation?.lineChartLabels;
            this.lineChartGreenDataPoints   = metrics?.mostGreenIndicatorsByLocation?.lineChartGreenDataPoints;
            this.lineChartRedDataPoints     = metrics?.mostGreenIndicatorsByLocation?.lineChartRedDataPoints;

            this.setState({
                metrics: metrics,
                lowestPrice:metrics?.lowestPriceProperty?.priceK,
                lowestPriceAddress:metrics?.lowestPriceProperty?.address,
                highestPrice:metrics?.highestPriceProperty?.priceK,
                highestPriceAddress:metrics?.highestPriceProperty?.address,
                mostGreenIndicatorProperties: metrics.mostGreenIndicatorProperties,
                bestPerformingIndicators:metrics.bestPerformingIndicators,
                budget:metrics?.budgetK
            });       

        }

    }

    componentWillMount = () => {

        this.setState({
            uiLoading: true
        });

        this.loadProperties();


        if (this.props.settings == null || (this.props.settings != null && this.props.settings.benchmarkSettings == null))
        {
            this.loadSettings();
        }
    };

    loadProperties()
    {

        authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };

        axios
        .get('/api/properties')
        .then((response) => {

            if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
            {
                localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
            }

            const metrics = Metrics.aggregateMetrics(response.data, this.props.investmentType, this.props.profileId, this.props.profile);

            this.lineChartLabels            = metrics?.mostGreenIndicatorsByLocation?.lineChartLabels;
            this.lineChartGreenDataPoints   = metrics?.mostGreenIndicatorsByLocation?.lineChartGreenDataPoints;
            this.lineChartRedDataPoints     = metrics?.mostGreenIndicatorsByLocation?.lineChartRedDataPoints;

            this.setState({
                properties: response.data,
                metrics: metrics,
                lowestPrice:metrics?.lowestPriceProperty?.priceK,
                lowestPriceAddress:metrics?.lowestPriceProperty?.address,
                highestPrice:metrics?.highestPriceProperty?.priceK,
                highestPriceAddress:metrics?.highestPriceProperty?.address,
                mostGreenIndicatorProperties: metrics.mostGreenIndicatorProperties,
                bestPerformingIndicators:metrics.bestPerformingIndicators,
                budget:metrics?.budgetK,
                uiLoading: false
            });

        })
        .catch((error) => {
            console.log(error);
            if (error && error.response && error.response.status && error.response.status === 403) {
                localStorage.setItem('SessionExpired',"Y");
                //window.location.reload();
                window.location.href = '/login';
            } else
            {
                this.setState({
                    uiLoading: false
                });
            }
            
        });
    }

    loadSettings()
    {
        try
        {
            authMiddleWare(this?.props?.history);
            const authToken = localStorage.getItem('AuthToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
            
            axios
                .get('/api/settings')
                .then((response) => {

                    if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                    {
                        localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                        localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    }
    
                    const settings = response.data;
                    const benchmarkSettings = BLO.getIndicatorBenchmarkSettings(settings);
                    settings.benchmarkSettings = benchmarkSettings;

                    //console.log("benchmarkSettings:");
                    //console.log(JSON.stringify(benchmarkSettings));

                    //console.log("Account Settings:");
                    //console.log(JSON.stringify(settings));

                    this.props.settings = settings;

                    this.setState({
                        settings: settings
                    });
                    
                })
                .catch((error) => {
                    console.log(error);
                    if (error && error.response && error.response.status && error.response.status === 403) {
                        localStorage.setItem('SessionExpired',"Y");
                        //window.location.reload();
                        window.location.href = '/login';
                    }
                });
            } catch(error)
            {
              console.log(error);   
            }

    }

    render() {
  
        const labels =  this.lineChartLabels;
  
        const lineChartData = {
            labels,
            fill: false,
            datasets: [
            {
                label: 'Green Indicator Count',
                data: this.lineChartGreenDataPoints,
                borderColor: '#1ab215',
                backgroundColorX: '#1a8315',
                borderWidth: 3,
            },
            {
                label: 'Red Indicator Count',
                data: this.lineChartRedDataPoints,
                borderColor: '#ff0019',
                backgroundColorX: '#970019',
                borderWidth: 3,
            },
            ]
        };

         const { classes } = this.props;

         if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
            return (

                <div style={{paddingLeft:'15px',paddingRight:'15px'}}>
                    <h1 class="dashboard-heading" style={{paddingBottom:'2px',marginBottom:'2px'}}>Metrics</h1>

                    <div class="page-short-description">
                    Find out which properties have the most green indicators, the best performing properties, and best locations.
                    </div>

                    <div class="row" style={{paddingTop:'5px'}}>

                        <div class="col-md-6" style={{padding:'0px'}}>
                            <div class="metric-chart-section">
                                
                                    <div class="row" style={{paddingTop:'0px',paddingBottom:'7px'}}>

                                        {/* -------------- LOWEST PRICE PROPERTY -------------- */}
                                        <div class="col-md-6" style={{padding:'5px'}}>
                                            <div class="metric-border metric-chart-item">
                                                <b>Lowest Priced Property</b>
                                                {/* Bar */}
                                                <div class="row">
                                                    <div class="col-md-11" style={{backgroundColor:'#4e4e4e',marginLeft:'12px',marginRight:'12px',marginTop:'10px',height:'40px',padding:'0px'}}>

                                                        <table style={{width:'100%',height:'100%'}}>
                                                            <tr>
                                                                <td style={{minWidth:'80%',width:'80%',height:'100%',margin:'0px',padding:'0px'}}>
                                                                    
                                                                {(this.state.lowestPrice*100)/this.state.budget <=100 &&    
                                                                    <div style={{height:'100%',width:((parseInt(this.state.lowestPrice*100)/this.state.budget))+'%',backgroundColor:'#3984b5',textAlign:'right',paddingTop:'10px'}}>
                                                                        {this.state.lowestPrice}K
                                                                    </div>
                                                                }

                                                                {(this.state.lowestPrice*100)/this.state.budget >100 &&    
                                                                    <div style={{height:'100%',width:'100%',backgroundColor:'#3984b5',textAlign:'right',paddingTop:'10px'}}>
                                                                        &nbsp;
                                                                    </div>
                                                                }
                                                                    
                                                                </td>
                                                                <td style={{maxWidth:'2px',width:'2px',backgroundColor:'white'}}></td>
                                                                <td style={{minWidth:'19%',width:'19%',height:'100%',margin:'0px', padding:'0px'}}>
                                                                    {((this.state.lowestPrice*100)/this.state.budget >100  && (this.state.lowestPrice*100)/this.state.budget <=120) && 
                                                                        <div style={{height:'100%',width:((   (parseInt(((this.state.lowestPrice*100)/this.state.budget)-100))                *100)/20)+'%',backgroundColor:'#3984b5',textAlign:'right',paddingTop:'10px'}}>
                                                                            {this.state.lowestPrice}K
                                                                        </div>
                                                                    }

                                                                    {(this.state.lowestPrice*100)/this.state.budget >120 &&   
                                                                        <div style={{height:'100%',width:'100%',backgroundColor:'#3984b5',textAlign:'right',paddingTop:'10px'}}>
                                                                            {this.state.lowestPrice}K
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                            
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-11">
                                                        <table style={{width:'100%',height:'100%'}}>
                                                            <tr>
                                                                <td style={{minWidth:'75%',width:'75%',height:'100%',margin:'0px',padding:'0px'}}> 

                                                                    {this.state?.lowestPriceAddress?.hasOwnProperty('street') && this.state?.lowestPriceAddress?.street !=='' &&
                                                                        <div style={{paddingTop:'8px'}}>
                                                                            {this.state?.lowestPriceAddress?.street},<br></br>
                                                                            {this.state?.lowestPriceAddress?.city}, {this.state?.lowestPriceAddress?.state} {this.state?.lowestPriceAddress?.zip}
                                                                        </div>
                                                                    }

                                                                </td>
                                                                <td style={{maxWidth:'25px',width:'25px'}}><div class="metric-budget">Budget<br></br>{this.state?.budget}K</div></td>
                                                                <td style={{minWidth:'0%',width:'0%',height:'100%',margin:'0px', padding:'0px'}}>&nbsp;</td>
                                                            </tr>
                                                        </table>  
                                                    </div>
                                                </div>

                                            

                                                {/* End Bar */}
                                            </div>
                                        </div>
                                        {/* -------------- END LOWEST PRICE PROPERTY ---------- */}

                                        
                                        {/* -------------- HIGHEST PRICE PROPERTY -------------- */}
                                        <div class="col-md-6" style={{padding:'5px'}}>
                                            <div class="metric-border metric-chart-item">
                                                <b>Highest Priced Property</b>
                                                {/* Bar */}
                                                <div class="row">
                                                    <div class="col-md-11" style={{backgroundColor:'#4e4e4e',marginLeft:'12px',marginRight:'12px',marginTop:'10px',height:'40px',padding:'0px'}}>

                                                        <table style={{width:'100%',height:'100%'}}>
                                                            <tr>
                                                                <td style={{minWidth:'80%',width:'80%',height:'100%',margin:'0px',padding:'0px'}}>
                                                                    
                                                                {(this.state.highestPrice*100)/this.state.budget <=100 &&    
                                                                    <div style={{height:'100%',width:((parseInt(this.state.highestPrice*100)/this.state.budget))+'%',backgroundColor:'#b50099',textAlign:'right',paddingTop:'10px'}}>
                                                                        {this.state.highestPrice}K
                                                                    </div>
                                                                }

                                                                {(this.state.highestPrice*100)/this.state.budget >100 &&    
                                                                    <div style={{height:'100%',width:'100%',backgroundColor:'#b50099',textAlign:'right',paddingTop:'10px'}}>
                                                                        &nbsp;
                                                                    </div>
                                                                }
                                                                    
                                                                </td>
                                                                <td style={{maxWidth:'2px',width:'2px',backgroundColor:'white'}}></td>
                                                                <td style={{minWidth:'19%',width:'19%',height:'100%',margin:'0px', padding:'0px'}}>
                                                                    {((this.state.highestPrice*100)/this.state.budget >100  && (this.state.highestPrice*100)/this.state.budget <=120) && 
                                                                        <div style={{height:'100%',width:((   (parseInt(((this.state.highestPrice*100)/this.state.budget)-100))                *100)/20)+'%',backgroundColor:'#b50099',textAlign:'right',paddingTop:'10px'}}>
                                                                            {this.state.highestPrice}K
                                                                        </div>
                                                                    }

                                                                    {(this.state.highestPrice*100)/this.state.budget >120 &&   
                                                                        <div style={{height:'100%',width:'100%',backgroundColor:'#b50099',textAlign:'right',paddingTop:'10px'}}>
                                                                            {this.state.highestPrice}K
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                            
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-11">
                                                        <table style={{width:'100%',height:'100%'}}>
                                                            <tr>
                                                                <td style={{minWidth:'75%',width:'75%',height:'100%',margin:'0px',padding:'0px'}}> 
                                                                {this.state?.highestPriceAddress?.hasOwnProperty('street') && this.state?.highestPriceAddress?.street !=='' &&
                                                                    
                                                                    <div style={{paddingTop:'8px'}}>
                                                                        {this.state?.highestPriceAddress?.street},<br></br>
                                                                        {this.state?.highestPriceAddress?.city}, {this.state?.highestPriceAddress?.state} {this.state?.highestPriceAddress?.zip}
                                                                    </div>
                                                                }

                                                                </td>
                                                                <td style={{maxWidth:'25px',width:'25px'}}><div class="metric-budget">Budget<br></br>{this.state?.budget}K</div></td>
                                                                <td style={{minWidth:'0%',width:'0%',height:'100%',margin:'0px', padding:'0px'}}>&nbsp;</td>
                                                            </tr>
                                                        </table>  
                                                    </div>
                                                </div>

                                            

                                                {/* End Bar */}
                                            </div>
                                        </div>
                                        {/* -------------- END HIGHEST PRICE PROPERTY ---------- */}


                                    </div>

                                    {/* ------------------ MOST GREEN INDICATORS CHART ------------------ */}
                                    <div class="row" style={{paddingTop:'0px',paddingLeft:'12px',paddingRight:'12px'}}>

                                        <div class="col-md-12" style={{padding:'0px'}}>

                                            <div class="metric-border" style={{minHeight:'320px'}}>

                                                <div style={{textAlign:'left',fontSize:'13px'}}>
                                                    <b>Highest Green Indicator Count</b><br></br>
                                                    These are the properties that have the most green indicators for the selected profile and investment type.

                                                </div>


                                                {/*  LINE */}
                                                <div class="row" style={{paddingTop:'10px',paddingBottom:'20px'}}>
                                                    <div class="col-md-12" style={{borderBottom:'1px solid #777777',marginLeft:'5px',marginRight:'5px',maxWidth:'98%',width:'98%'}}></div>
                                                </div>

                                                {this.state.mostGreenIndicatorProperties?.map((property, i) => (
                                                    <div> {/* Wrapper*/}
                                                         {/* ------------------------- CHART ROW ---------------------- */}
                                                         <div class="row">
                                                            
                                                            <div class="col-md-4" style={{textAlign:'left',color:'#d4d4d4',fontSize:'14px'}}>
                                                            {property?.address?.street},<br></br>
                                                            {property?.address?.city}, {property?.address?.state} {property?.address?.zip}
                                                            </div>

                                                            <div class="col-md-8">
                                                                    {/* GRAPH BAR */}
                                                                    <div style={{paddingTop:'6px',margin:'0px'}}>

                                                                            {/* Green bar */}
                                                                            <div style={{width:((property?.greenIndicatorCount/9)*100)+'%'}} class="green-chart-bar">
                                                                                {property?.greenIndicatorCount}   
                                                                            </div>
                                                                    

                                                                        {/* Red bar */}    
                                                                            <div style={{width:((property?.redIndicatorCount/9)*100)+'%'}} class="red-chart-bar">
                                                                                {property?.redIndicatorCount} 
                                                                            </div>
                                                                
                                                                    </div>
                                                                    {/* END GRAPH BAR */}
                                                            </div>        
                                                        </div>
                                                        {/* ------------------------- CHART ROW END-------------------- */}
                                                        {/*  LINE */}
                                                        <div class="row">
                                                            <div class="col-md-12" style={{borderBottom:'1px solid #4e4e4e',marginLeft:'5px',marginRight:'10px',paddingTop:'10px'}}></div>
                                                        </div>
                                                    </div> 
                                                ))}
                                                
        
                                                

                                            </div>

                                        </div>

                                    </div>


                            </div>
                        </div>

                        {/* ------------------ RIGHT COLUMN - LINE CHART ------------------ */}
                        <div class="col-md-6" style={{padding:'0px'}}>
                            <div class="metric-chart-section metric-border metric-graph-section">

                            {/*
                            NOTE: Had to harcde defaultFontColor in node_modules\chart.js\dist\Chart.js

                                defaults._set('global', {
                                defaultColor: 'rgba(0,0,0,0.1)',
                                //defaultFontColor: '#666',
                                defaultFontColor: '#FFFFFF',
                                defaultFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                                defaultFontSize: 12, 
                                defaultFontStyle: 'normal',
                                defaultLineHeight: 1.2,
                                showLines: true
                                });

                            */}
                                <Line
                                    data={lineChartData} 
                                    height={215}
                                    options={{ 
                                        title:{
                                        display:true,
                                        text:'Locations With Most Green Indicators',
                                        fontSize:18
                                        },
                                        legend:{
                                        display:true,
                                        position:'top',
                                        }
                                    }}
                                    />
                            

                            </div>
                        </div>
            
                    </div> 


                    <div class="row">

                        {this.state.bestPerformingIndicators?.map((indicatorProperty, i) => (

                            <div class="col-md-3" style={{padding:'0px'}}>
                                <div class="metric-border metric-tile">
                                    Best<br></br>
                                    {indicatorProperty?.indicatorName}
                                    <div class="metric-indicator-value">

                                    {indicatorProperty?.indicatorValue} {indicatorProperty?.passed && <img src={thumbsUpIcon} style={{height:'30px'}}/> } {!indicatorProperty?.passed && <img src={thumbsDownIcon} style={{height:'30px'}}/> } 
                                        
                                    </div>
                                    <div style={{color:'#d4d4d4'}}>{indicatorProperty?.address?.street}, {indicatorProperty?.address?.city} {indicatorProperty?.address?.state} {indicatorProperty?.address?.zip}</div>
                                </div>
                            </div>

                        ))}

                    </div>
                
                    <Helmet>
                        <script src="js/grid-functions.js" type="text/javascript" />
                        <script src="vendor/jquery/jquery.min.js"></script>
                        <script src="js/sb-admin-2.min.js"></script>
                        <script src="vendor/bootstrap/bootstrap.bundle.min.js"></script>
                    </Helmet>
            </div>

            );
        }
    }
}

export default (withStyles(styles)(Charts));

import React from 'react';
import { StyleSheet, Image, View } from '@react-pdf/renderer';
import IndicatorTiles from './IndicatorTiles';
import IndicatorInfo from './IndicatorInfo';
import IndicatorsTable from './IndicatorsTable';

const tableColumns = [
	{ name: 'Indicator Name', width: '12%' },
	{ name: '', width: '5%' },
	{ name: 'Status', width: '10%' },
	{ name: 'Accept Criteria', width: '11%' },
	{ name: 'Indicator Value', width: '12%' },
	{ name: 'Result', width: '22%' },
	{ name: 'Recommendations', width: '28%' },
];

const FirstPage = ({
	logoUrl,
	passedCount,
	failedCount,
	indicators,
	categoryName,
	address,
	city,
	stateCode,
	zip,
	price,
}) => {
	return (
		<View style={styles.container}>
			{/* Page Header */}
			<View style={styles.pageHeader}>
				<Image style={styles.image} src={logoUrl} />
				<View style={styles.indicatorsWrapper}>
					{Array.from(Array(passedCount)).map((_, index) => (
						<Image
							key={index}
							style={styles.thumbIcon}
							src={'/images/thumbs-up.png'}
						/>
					))}
					{Array.from(Array(failedCount)).map((_, index) => (
						<Image
							key={index}
							style={styles.thumbIcon}
							src={'/images/thumbs-down.png'}
						/>
					))}
				</View>
			</View>
			<IndicatorTiles indicators={indicators} />
			<IndicatorInfo
				categoryName={categoryName}
				address={address}
				city={city}
				state={stateCode}
				zip={zip}
				price={price}
				passedCount={passedCount}
				failedCount={failedCount}
			/>
			<IndicatorsTable columns={tableColumns} indicators={indicators} />
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
	},
	pageHeader: {
		paddingBottom: 4,
		borderBottom: '2px solid #ccc',
	},
	image: {
		width: 117,
		height: 'auto',
	},
	indicatorsWrapper: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 4,
	},
	thumbIcon: {
		width: 20,
		height: 20,
		margin: 1,
	},
});

export default FirstPage;

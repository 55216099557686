import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>loan-to-value (LTV)</b> ratio is the ratio of the loan balance to the market value of a property, expressed as a percentage. The LTV can be used to estimate the amount of equity you have in a property.
                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        Financial institutions and other lenders examine the <b>LTV</b> ratio before approving a mortgage. Typically, assessments with high LTV ratios are seen as higher risk, and therefore, if the mortgage is approved, the loan will generally cost the borrower more, or the lending institution will require the borrower to purchase mortgage insurance.
                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                            Banks calculate the loan-to-value (LTV) ratio using the lesser of the purchase price or the appraised value of the property. If the appraised value of the property is lower than the purchase price when you're buying a home, <b>your LTV is based on the appraised value instead</b>.
                                        </div>
                             
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The lower the <b>LTV</b> ratio, the better. Lenders usually want an LTV of <b>70%</b> or lower, but check with the bank since the required ratio may vary depending on the level of risk the bank is willing to take.

                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>loan-to-value (LTV)</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Loan Amount
                                                            {(this.state.indicator.property.price > 0 || 
                                                             this.state.indicator.property.downPayment > 0) &&        

                                                                <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                                    <table class="calculation-info-table">
                                                                        {this.state.indicator.property.price > 0 && 
                                                                            <tr>
                                                                                <td>Purchase Price</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td></td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.price)}</td>
                                                                            </tr>
                                                                        }

                                                                        {this.state.indicator.property.downPayment > 0 && 
                                                                            <tr>
                                                                                <td>Down Payment</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td style={{fontSize:'14px'}}>-</td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.downPayment)}</td>
                                                                            </tr>
                                                                        }
                                                                       
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}</b></td>
                                                                            </tr>

                                                                        </table>

                                                                    
                                                                    </div>
                                                                }
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}
                                                        </div>
                                                    
                                                </div>


                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                   
                                                        <span>Purchase Price</span>
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            {/*
                                                            {this.state.indicator.property.investmentType === "Primary Home" && 
                                                                <span>Market Value</span>
                                                            }

                                                            {this.state.indicator.property.investmentType === "Rental Property" && 
                                                                <span>Market Value</span>
                                                            }

                                                            {this.state.indicator.property.investmentType === "Flipping Property" && 
                                                                <span>After Repair Value</span>
                                                            }
                                                        */}
                                                        <span>{this.state.indicator.helpVariableMap.VAR_APPRAISED_LABEL}</span>
                                                        <div style={{fontSize:"11px",fontWeight:"plain"}}>(Appraised Value)</div>
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_BANKS_APPRAISAL}
                                                        </div>
                                                    
                                                </div>
                                            
                                            </div>
                                        </div>
                                                             
                                    </div>
                                    <div style={{ontSize:"16px",fontWeight:"plain",padding:"15px",color:"#000000"}}>
                                    Since banks use the lesser of the purchase price or the appraised value we will be using the <b>{this.state.indicator.helpVariableMap.VAR_BANKS_APPRAISAL_LABEL}</b>.

                                </div>
                             
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                             
                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">

                                   
                                        {/* ---------------------  STEP 1 ------------------ */}
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Loan-to-Value Ratio</b>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px", paddingLeft:"15%"}}>

                                                    <table>
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Loan-to-Value Ratio = </td>
                                                            <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>Loan Amount</b></div>
                                                                 
                                                                <b>        
                                                                    {/*
                                                                    {this.state.indicator.property.investmentType === "Primary Home" && 
                                                                        <span>Market Value</span>
                                                                    }
                                                                    {this.state.indicator.property.investmentType === "Rental Property" && 
                                                                        <span>Market Value</span>
                                                                    }

                                                                    {this.state.indicator.property.investmentType === "Flipping Property" && 
                                                                        <span>After Repair Value</span>
                                                                    }
                                                                */}
                                                                <span>{this.state.indicator.helpVariableMap.VAR_BANKS_APPRAISAL_LABEL}</span>
                                                                </b>

                                                            </td>
                                                        </tr>

                                                    </table>

                                                    <div style={{paddingTop:'25px'}}>
                                                        <table>
                                                            <tr>
                                                                <td style={{paddingTop:'5px'}}>Loan-to-Value Ratio = </td>
                                                                <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                    <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>{this.state.indicator.helpVariableMap.VAR_LOAN_AMOUNT}</b></div>
                                                                    
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_SMALLER_PURCHASE_BANKS_APPRAISAL}</b>

                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                   
                                          
                                            


                                                </div>
                                            </div>
                                        </div>

                                               {/* ---------------------  STEP 3 ------------------ */}


                                               <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 2:</span> <b>Results</b>
                                            </div>
                                            </div>

                                                    <div class="row">
                                                        <div class="col-md-12 indicator-info-formula-table">
                                                            <div class="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:"15px"}}>
                                                                <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                                Loan-to-Value Ratio = 
                                                                </div>
                                                                <div class="col-md-4 indicator-info-formula-table-col1">
                                                                    <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                            </div>
                                                                      

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Loan-to-Value Ratio benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                PURCHASE PRICE<br></br>
                                                DOWN PAYMENT<br></br>

                                                {this.state.indicator.property.investmentType === "Primary Home" && 
                                                    <span>MARKET VALUE</span>
                                                }

                                                {this.state.indicator.property.investmentType === "Flipping Property" && 
                                                    <span>AFTER REPAIR VALUE</span>
                                                }
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                 {/* TIP */}
                 {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Put down a larger <span style={{fontWeight:'600'}}>down payment</span><br></br>
                            <b>2)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>                  
                        </div>

                    </div>        
                }             

                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

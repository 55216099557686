import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">
                                    
                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>gross rent multiplier (GRM)</b> is a metric used by investors to quickly compare rental property opportunities in a given market. The GRM is the ratio of the property's market value to its annual gross rental income. 

                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        It is an excellent first assessment tool for getting a rough estimate of the value of rental properties, before considering operating expenses.
                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        For example, let's say one property collects <b>$2,000</b> in rent, and another collects <b>$1,200</b> in rent, and you want to determine how much rent you will collect relative to the property cost.

                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        If both properties cost <b>$200,000</b>, the property that rents for <b>$2,000</b> will provide a higher return on investment. However, this changes when the property costs are different. You can use GRM to determine the best option.
                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        A gross rent multiplier (GRM) of <b>5.0</b>, for instance, indicates that the rental property price is five times the gross scheduled income. Therefore, the property would have to collect rent for five years to equal the price.

                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        It's important to note that GRM is not equivalent to the length of time it takes for the investment to pay off because it does not consider net operating income. GRM should not be the only calculation used to evaluate a profitable investment.
                                        </div>

                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The lower the <b>gross rent multiplier (GRM)</b>, the better. <br></br><br></br>
                                    
                                    For the investor, a higher gross rent multiplier (perhaps over 12) is a poorer opportunity, whereas a lower one (perhaps under 9) is better. <br></br><br></br>
                                    
                                    A <b>gross rent multiplier (GRM)</b> of zero would be perfect, but highly unlikely.<br></br><br></br> 
                                    
                                    The <b>gross rent multiplier (GRM)</b> typically ranges from <b>4</b> to <b>10</b>. Most investors prefer properties with a gross rent multiplier (GRM) below <b>10</b>.
                                  
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>gross rent multiplier (GRM)</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                    <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                    
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Purchase Price       
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}
                                                    </div>

                                                </div>

                                          
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Monthly Rent         
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT}
                                                    </div>

                                                </div>

                                         
                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                    Gross Annual Income
                                                            <br></br>
                                                            <span style={{fontWeight:"400",fontSize:"14px"}}>(Monthly Rent * 12)</span>   
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                    {this.state.indicator.helpVariableMap.VAR_ANNUAL_INCOME}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>
                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 1:</span> <b>Formula</b>
                                            </div>
                                 </div>

                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1">
                                                        Gross Rent Multiplier = 
                                                    </div>
                                                    <div class="col-md-4 indicator-info-formula-table-col2">
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center",borderBottom: "2px solid  #1c7cfb"}}>
                                                                    <b>Purchase Price</b>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center"}}>
                                                                    <b>Gross Annual Income</b>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                  </div>
                                   

                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 2:</span> <b>Plug in values</b>
                                            </div>
                              </div>

                                        <div class="row">
                                            <div class="col-md-12  indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1">
                                                        Gross Rent Multiplier = 
                                                    </div>
                                                    <div class="col-md-4 indicator-info-formula-table-col2">
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center",borderBottom: "2px solid  #1c7cfb"}}>
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}</b>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center"}}>
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_INCOME}</b>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   

                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 3:</span> <b>Results</b>
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1">
                                                        Gross Rent Multiplier = 
                                                    </div>
                                                    <div class="col-md-4 indicator-info-formula-table-col1">
                                                        <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                    </div>
                                                </div>
                                            </div>
                                  </div>
                                   

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Gross Rent Multiplier benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"center",fontWeight:"bold"}}>
                                                PURCHASE PRICE<br></br>
                                                MONTHLY RENT
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                   {/* TIP */}
                   {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>2)</b> Increase the <span style={{fontWeight:'600'}}>monthly rent</span><br></br>                     
                        </div>

                    </div>        
                }         

                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

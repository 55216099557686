	
	const PERCENT = "PERCENT"; //1
	const DOLLAR_AMOUNT = "DOLLAR_AMOUNT"; //2
	const DECIMAL = "DECIMAL"; //4
	const GROSS_RENT_MULTIPLIER_INDICATOR_NAME 	="Gross Rent Multiplier";
	const GROSS_RENT_MULTIPLIER_INDICATOR_ACRONYM 	="GRM";
	const CAPITALIZATION_RATE_INDICATOR_NAME 		="Capitalization Rate";
	const CAPITALIZATION_RATE_INDICATOR_ACRONYM 	="CAP";
	const CASH_ON_CASH_RATE_INDICATOR_NAME 		="Cash on Cash Return";
	const CASH_ON_CASH_RATE_INDICATOR_ACRONYM 		="COC";
	const IRR_INDICATOR_NAME 						="Internal Rate of Return";
	const IRR_INDICATOR_ACRONYM 					="IRR";
	const PI_INDICATOR_NAME 						="Profitability Index";
	const PI_INDICATOR_ACRONYM 					="PI";	
	const DEBT_CONVERAGE_RATIO_INDICATOR_NAME 		="Debt Coverage Ratio";
	const DEBT_CONVERAGE_RATIO_INDICATOR_ACRONYM 	="DCR";
	const BREAK_EVEN_RATIO_INDICATOR_NAME 			="Break-Even Ratio";
	const BREAK_EVEN_RATIO_INDICATOR_ACRONYM 		="BER";
	const LOAN_TO_VALUE_RATIO_INDICATOR_NAME 		="Loan-to-Value Ratio";
	const LOAN_TO_VALUE_RATIO_INDICATOR_ACRONYM 	="LVR";
	const NET_CASH_FLOW_INDICATOR_NAME 			="Net Cash Flow";
	const NET_CASH_FLOW_INDICATOR_ACRONYM 			="NCF";
	const RULE_70_INDICATOR_NAME 					="Rule of 70";
	const RULE_70_INDICATOR_ACRONYM 				="R70";
	const BUDGET_INDICATOR_NAME 					="Budget Usage";
	const BUDGET_INDICATOR_ACRONYM 				="BUDGET";
	const ROI_INDICATOR_NAME 						="Return on Investment (ROI)";
	const ROI_INDICATOR_ACRONYM 					="ROI";
	const KRI_INDICATOR_NAME 						="Key Risk Indicator";
	const KRI_INDICATOR_ACRONYM 					="KRI";
	const NET_PROFIT_INDICATOR_NAME 				="Net Profit";
	const NET_PROFIT_INDICATOR_ACRONYM 			="NP";
	const MARKET_VALUE_PERCENT_INDICATOR_NAME 		="Market Value Percent";
	const MARKET_VALUE_PERCENT_INDICATOR_ACRONYM 	="MVP";
	const BACK_END_RATIO_INDICATOR_NAME 			="Back-End Ratio";
	const BACK_END_RATIO_INDICATOR_ACRONYM 		="BE";
	const FRONT_END_RATIO_INDICATOR_NAME 			="Front-End Ratio";
	const FRONT_END_RATIO_INDICATOR_ACRONYM 		="FE";
	const PRICE_TO_RENT_RATIO_INDICATOR_NAME 		="Price-to-Rent Ratio";
	const PRICE_TO_RENT_RATIO_INDICATOR_ACRONYM 	="PTR";
	const CREDIT_SCORE_INDICATOR_NAME 				="Credit Score";
	const CREDIT_SCORE_INDICATOR_ACRONYM 			="CS";
	const PRICE_PER_SIZE_INDICATOR_NAME 			="Price per SqFt";
	const PRICE_PER_SIZE_INDICATOR_ACRONYM 		="PPQ";
	const  COST_OF_DEBT_INDICATOR_NAME 			="Cost of Debt";
	const COST_OF_DEBT_INDICATOR_ACRONYM 			="COD";
	const UNDEFINED_IRR_VALUE = -10;
	

   exports.PERCENT=PERCENT; 
   exports.DOLLAR_AMOUNT=DOLLAR_AMOUNT;
   exports.DECIMAL=DECIMAL;
   exports.GROSS_RENT_MULTIPLIER_INDICATOR_NAME=GROSS_RENT_MULTIPLIER_INDICATOR_NAME; 	
   exports.GROSS_RENT_MULTIPLIER_INDICATOR_ACRONYM=GROSS_RENT_MULTIPLIER_INDICATOR_ACRONYM;
   exports.CAPITALIZATION_RATE_INDICATOR_NAME=CAPITALIZATION_RATE_INDICATOR_NAME;
   exports.CAPITALIZATION_RATE_INDICATOR_ACRONYM=CAPITALIZATION_RATE_INDICATOR_ACRONYM;
   exports.CASH_ON_CASH_RATE_INDICATOR_NAME=CASH_ON_CASH_RATE_INDICATOR_NAME;
   exports.CASH_ON_CASH_RATE_INDICATOR_ACRONYM=CASH_ON_CASH_RATE_INDICATOR_ACRONYM;
   exports.IRR_INDICATOR_NAME=IRR_INDICATOR_NAME;				
   exports.IRR_INDICATOR_ACRONYM=IRR_INDICATOR_ACRONYM;	
   exports.PI_INDICATOR_NAME=PI_INDICATOR_NAME;
   exports.PI_INDICATOR_ACRONYM=PI_INDICATOR_ACRONYM;	
   exports.DEBT_CONVERAGE_RATIO_INDICATOR_NAME=DEBT_CONVERAGE_RATIO_INDICATOR_NAME;
   exports.DEBT_CONVERAGE_RATIO_INDICATOR_ACRONYM=DEBT_CONVERAGE_RATIO_INDICATOR_ACRONYM;
   exports.BREAK_EVEN_RATIO_INDICATOR_NAME=BREAK_EVEN_RATIO_INDICATOR_NAME;
   exports.BREAK_EVEN_RATIO_INDICATOR_ACRONYM=BREAK_EVEN_RATIO_INDICATOR_ACRONYM;
   exports.LOAN_TO_VALUE_RATIO_INDICATOR_NAME=LOAN_TO_VALUE_RATIO_INDICATOR_NAME;
   exports.LOAN_TO_VALUE_RATIO_INDICATOR_ACRONYM=LOAN_TO_VALUE_RATIO_INDICATOR_ACRONYM;
   exports.NET_CASH_FLOW_INDICATOR_NAME=NET_CASH_FLOW_INDICATOR_NAME;
   exports.NET_CASH_FLOW_INDICATOR_ACRONYM=NET_CASH_FLOW_INDICATOR_ACRONYM;
   exports.RULE_70_INDICATOR_NAME=RULE_70_INDICATOR_NAME;
   exports.RULE_70_INDICATOR_ACRONYM=RULE_70_INDICATOR_ACRONYM;
   exports.BUDGET_INDICATOR_NAME=BUDGET_INDICATOR_NAME;
   exports.BUDGET_INDICATOR_ACRONYM=BUDGET_INDICATOR_ACRONYM;
   exports.ROI_INDICATOR_NAME=ROI_INDICATOR_NAME;
   exports.ROI_INDICATOR_ACRONYM=ROI_INDICATOR_ACRONYM;	
   exports.KRI_INDICATOR_NAME=KRI_INDICATOR_NAME;
   exports.KRI_INDICATOR_ACRONYM=KRI_INDICATOR_ACRONYM;	
   exports.NET_PROFIT_INDICATOR_NAME=NET_PROFIT_INDICATOR_NAME;
   exports.NET_PROFIT_INDICATOR_ACRONYM=NET_PROFIT_INDICATOR_ACRONYM;
   exports.MARKET_VALUE_PERCENT_INDICATOR_NAME=MARKET_VALUE_PERCENT_INDICATOR_NAME;
   exports.MARKET_VALUE_PERCENT_INDICATOR_ACRONYM=MARKET_VALUE_PERCENT_INDICATOR_ACRONYM;
   exports.BACK_END_RATIO_INDICATOR_NAME=BACK_END_RATIO_INDICATOR_NAME;
   exports.BACK_END_RATIO_INDICATOR_ACRONYM=BACK_END_RATIO_INDICATOR_ACRONYM;
   exports.FRONT_END_RATIO_INDICATOR_NAME=FRONT_END_RATIO_INDICATOR_NAME;
   exports.FRONT_END_RATIO_INDICATOR_ACRONYM=FRONT_END_RATIO_INDICATOR_ACRONYM;
   exports.PRICE_TO_RENT_RATIO_INDICATOR_NAME=PRICE_TO_RENT_RATIO_INDICATOR_NAME;
   exports.PRICE_TO_RENT_RATIO_INDICATOR_ACRONYM=PRICE_TO_RENT_RATIO_INDICATOR_ACRONYM;
   exports.CREDIT_SCORE_INDICATOR_NAME=CREDIT_SCORE_INDICATOR_NAME;
   exports.CREDIT_SCORE_INDICATOR_ACRONYM=CREDIT_SCORE_INDICATOR_ACRONYM;
   exports.PRICE_PER_SIZE_INDICATOR_NAME=PRICE_PER_SIZE_INDICATOR_NAME;
   exports.PRICE_PER_SIZE_INDICATOR_ACRONYM=PRICE_PER_SIZE_INDICATOR_ACRONYM;
   exports.COST_OF_DEBT_INDICATOR_NAME=COST_OF_DEBT_INDICATOR_NAME;
   exports.COST_OF_DEBT_INDICATOR_ACRONYM=COST_OF_DEBT_INDICATOR_ACRONYM;	
   exports.UNDEFINED_IRR_VALUE=UNDEFINED_IRR_VALUE;


	
import React from 'react';
import { Text } from '@react-pdf/renderer';

const IndicatorValue = ({ value, valueType, style }) => {
	return (
		<Text style={style}>
			{valueType === 'DOLLAR_AMOUNT'
				? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
						.format(value)
						.replace(/(\.|,)00$/g, '')
				: `${value}${valueType === 'PERCENT' ? '%' : ''}`}
		</Text>
	);
};

export default IndicatorValue;

import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import SubscriptionPayment from './SubscriptionPayment';
import SubscriptionDetails from './SubscriptionDetails';

import { Helmet } from "react-helmet";

const styles = (theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    toolbar: theme.mixins.toolbar,
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    locationText: {
        paddingLeft: '15px'
    },
    buttonProperty: {
        position: 'absolute',
        top: '50%'
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    progess: {
        position: 'absolute'
    },
    uploadButton: {
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },
    submitButton: {
        marginTop: '10px'
    }
});
class Account extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
			name: '',
			email: '',
			phoneNumber: '',
			country: '',
			blogUrl: '',
			type: '',
			hasSubscription: false,
			stripeCustomerId:'',
			stripeSubscriptionId:'',
			stripePaymentMethodId:'',
			uiLoading: true,
			buttonLoading: false,
			imageError: ''
		};

    }

    componentWillMount = () => {
        
        this.displayComponents();
    }

    componentWillReceiveProps(nextProps) {
        
        this.displayComponents();

    }

    displayComponents() {

        if (this.props != null && this.props.user != null && this.props.user.stripeSubscriptionId != null)
        {
            let hasSubscription = false;

            if (this.props.user.stripeSubscriptionId != null && this.props.user.stripeSubscriptionId !='')
            {
                hasSubscription = true;
            }

            this.setState({
                uiLoading: false,
                stripeSubscriptionId: this.props?.user?.stripeSubscriptionId,
                stripeCustomerId: this.props?.user?.stripeCustomerId,
                hasSubscription:hasSubscription,
                user:this.props.user
            });
        }
    }


    render() {

        const { classes, ...rest } = this.props;
		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
        return (
            <div>
                <div class="my-property-heading">
                    <h1 class="dashboard-heading">Account</h1>
                </div>
             
                    <section class="property-detail">
                        <div class="row properties-row">
                            <main className={classes.content}>

                                { !this.state.hasSubscription && <SubscriptionPayment parentState={this.state}/>}
                                { this.state.hasSubscription && <SubscriptionDetails parentState={this.state}/>}
                            </main>

                        </div>
                    </section>

                    <Helmet>
                        <script src="js/grid-functions.js" type="text/javascript" />
                        <script src="vendor/jquery/jquery.min.js"></script>
                        <script src="js/sb-admin-2.min.js"></script>
                        <script src="vendor/bootstrap/bootstrap.bundle.min.js"></script>
                    </Helmet>

            </div>

        );
        }
    }
}

export default withStyles(styles)(Account);

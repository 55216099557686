
const  BLO  = require('./BLO');


export const isIndicatorDisabled = (property,indicator) => { 
    
        let isDisabled = false;

        //console.log(indicator.indicatorAcronym);
        if (
            (property.financingType !== "Cash") || 
            (property.financingType === "Cash" && indicator.indicatorAcronym !== "DCR" && indicator.indicatorAcronym !== "BER" && indicator.indicatorAcronym !== "LVR" && indicator.indicatorAcronym !== "FE" && indicator.indicatorAcronym !== "COD") 
        )
        {
            isDisabled = false;
        } else
        {
            isDisabled = true;
        }                                         

        return isDisabled;
    };

export const calculateIndicators = (property,profile,settings) => {

    let indicators = [];

    try
    {

        if (isNaN(property.term))
        {            
          property.term = property.term.replace("-"," ").replace("year", "Years").replace("Years", "").trim();
        }

        if (property.propertyCashFlowMaps == null || Object.keys(property.propertyCashFlowMaps).length == 0)
        {
            const projectedYearOfSale = parseInt(property.projectedYearOfSale.toLowerCase().replace("year","").trim());

            property.propertyCashFlowMaps = BLO.calculateFutureCashFlows(property,settings.benchmarkSettings);

           // console.log(property.propertyCashFlowMaps.size);
        }
        
        if (property.investmentType == "Rental Property")
        {
            indicators = PopulateRentalIndicators(property,settings,profile);          
        
        } else if (property.investmentType == "Flipping Property")
        {
            indicators = PopulateFlippingIndicators(property,settings,profile);          
        
        } else if (property.investmentType == "Primary Home")
        {
            indicators = PopulatePrimaryHomeIndicators(property,settings,profile);          
        }

    }catch(error)
    {
        console.log(error);
    }

	return indicators;
};

export const getIndicatorDescription = (indicator,property) => {

    let description = {};

    let key = indicator.indicatorAcronym;
    const keySuffix = getKeySuffix(property);

    if (INDICATOR_DESCRIPTIONS.hasOwnProperty(key+keySuffix)) // for special cases like DCR and BUDGET
    {
        key = key+keySuffix;
    }

    if (INDICATOR_DESCRIPTIONS.hasOwnProperty(key))
    {
        description = INDICATOR_DESCRIPTIONS[key];
    }
    
    return description;
};


export const getRedIndicatorRecommendation = (indicator,property) => {

    let indicatorRecommendation = "Consider making any of the following changes:\n\n";

    let key = indicator.indicatorAcronym;
    const keySuffix = getKeySuffix(property);

    if (RECOMMENDATIONS.hasOwnProperty(key+keySuffix)) // for special cases like DCR and BUDGET
    {
        key = key+keySuffix;
    }

    if (RECOMMENDATIONS.hasOwnProperty(key))
    {
        const recommendations = RECOMMENDATIONS[key];

        let counter = 0;
        for (let recommendation of recommendations)
        {
            counter = counter + 1;
            indicatorRecommendation = indicatorRecommendation + counter +") "+recommendation +"\n\n";
        }
    }
    
    return indicatorRecommendation;
};

const getKeySuffix = (property) => {

    let suffix = "";

    if (property.investmentType === "Rental Property")
    {
        suffix = "_RENTAL";

    } else if (property.investmentType === "Primary Home")
    {
        suffix = "_PRIMARY";

    } else if (property.investmentType === "Flipping Property")
    {
        suffix = "_FLIPPING";
    }

    return suffix;
}


const PopulateRentalIndicators = (property,account_settings,profile) => {

  //const settings = Settings.GetProfileBenchmarkingSettings();
  const settings =JSON.parse(JSON.stringify(account_settings.benchmarkSettings)); // clone it since need budget based on the profile value and trending price per sqft
  
  //Needs to come from profile
  settings.budgetAccepted=profile?.budget;   
  settings.budgetRejected=profile?.budget;   
  settings.budgetAcceptedCompareId=5;
  settings.budgetRejectedCompareId=1;

  //Needs to come from property
  settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
  settings.pricePerSizeRejected = property.trendingPricePerSqft;
  settings.pricePerSizeAcceptedCompareId =  5;
  settings.pricePerSizeRejectedCompareId =  1;

  //console.log("SETTINGS:");
  //console.log(JSON.stringify(settings));

  const grmIndicator = BLO.getGRMIndicator(property,settings);
  const capIndicator = BLO.getCapRateIndicator(property,settings);
  const cocIndicator = BLO.getCashOnCashIndicator(property,settings);

  const piIndicator = BLO.getProfitabilityIndexIndicator(property,settings);
  const irrIndicator = BLO.getInternalRateOfReturnIndicator(property,settings);
  const dcrIndicator = BLO.getDebtCoverageRatioRentalIndicator(property,settings);

  const berIndicator = BLO.getBreakEvenRatioIndicator(property,settings);
  const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
  const ncfIndicator = BLO.getNetCashFlowIndicator(property,settings);

  //console.log(JSON.stringify(dcrIndicator));

  const indicators = [];
  indicators.push(grmIndicator);
  indicators.push(capIndicator);
  indicators.push(cocIndicator);

  indicators.push(piIndicator);
  indicators.push(irrIndicator);
  indicators.push(dcrIndicator);

  indicators.push(berIndicator);
  indicators.push(ltvIndicator);
  indicators.push(ncfIndicator);

  //console.log(JSON.stringify(indicators));

  return indicators;
};


const PopulateFlippingIndicators = (property,account_settings,profile) => {

  //const settings = Settings.GetProfileBenchmarkingSettings();
  const settings =JSON.parse(JSON.stringify(account_settings.benchmarkSettings)); // clone it since need budget based on the profile value and trending price per sqft
  
  //Needs to come from profile
  settings.budgetAccepted=profile?.budget;   
  settings.budgetRejected=profile?.budget;   
  settings.budgetAcceptedCompareId=5;
  settings.budgetRejectedCompareId=1;

  //Needs to come from property
  settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
  settings.pricePerSizeRejected = property.trendingPricePerSqft;
  settings.pricePerSizeAcceptedCompareId =  5;
  settings.pricePerSizeRejectedCompareId =  1;

  const seventyPercentIndicator = BLO.get70PercentRuleIndicator(property,settings);
  const keyRiskIndicator = BLO.getKeyRiskIndicator(property,settings);    
  const dcrIndicator = BLO.getDebtCoverageRatioFlipIndicator(property,settings);

  const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
  const roiIndicator = BLO.getROIIndicator(property,settings);
  const netProfitIndicator = BLO.getNetProfitIndicator(property,settings);

  const marketValueIndicator = BLO.getMarketValuePercentIndicator(property,settings);
  const pricePerSqFtIndicator = BLO.getPricePerSizeIndicator(property,settings);
  const budgetIndicator = BLO.getBudgetIndicator(property,settings);

  //console.log(JSON.stringify(roiIndicator));

    const indicators = [];
    indicators.push(seventyPercentIndicator);
    indicators.push(keyRiskIndicator);
    indicators.push(dcrIndicator);
  
    indicators.push(ltvIndicator);
    indicators.push(roiIndicator);
    indicators.push(netProfitIndicator);
  
    indicators.push(marketValueIndicator);
    indicators.push(pricePerSqFtIndicator);
    indicators.push(budgetIndicator);
  
    //console.log(JSON.stringify(indicators));
  
    return indicators;
  };


const PopulatePrimaryHomeIndicators = (property,account_settings,profile) => {

  //const settings = Settings.GetProfileBenchmarkingSettings();
  const settings =JSON.parse(JSON.stringify(account_settings.benchmarkSettings)); // clone it since need budget based on the profile value and trending price per sqft
  
  //Needs to come from profile
  settings.budgetAccepted=profile?.budget;   
  settings.budgetRejected=profile?.budget;   
  settings.budgetAcceptedCompareId=5;
  settings.budgetRejectedCompareId=1;

  //Needs to come from property
  settings.pricePerSizeAccepted =property.trendingPricePerSqft ;
  settings.pricePerSizeRejected = property.trendingPricePerSqft;
  settings.pricePerSizeAcceptedCompareId =  5;
  settings.pricePerSizeRejectedCompareId =  1;

  const backEndRatioIndicator = BLO.getBackEndRatioIndicator(property,settings);
  const frontEndRatioIndicator = BLO.getFrontEndRatioIndicator(property,settings);    
  const dcrIndicator = BLO.getDebtCoverageRatioHomeIndicator(property,settings);

  const ltvIndicator = BLO.getLoanToValueRatioIndicator(property,settings);
  const costOfDebtIndicator = BLO.getCostOfDebtIndicator(property,settings);
  const marketValueIndicator = BLO.getMarketValuePercentIndicator(property,settings);
      
  const pricePerSqFtIndicator = BLO.getPricePerSizeIndicator(property,settings);
  const priceToRentIndicator = BLO.getPriceToRentRatioIndicator(property,settings);
  const budgetIndicator = BLO.getBudgetHomeIndicator(property,settings);

  //const creditScoreIndicator = BLO.getCreditScoreIndicator(property,settings);

  //console.log(JSON.stringify(priceToRentIndicator));

    const indicators = [];
    indicators.push(backEndRatioIndicator);
    indicators.push(frontEndRatioIndicator);
    indicators.push(dcrIndicator);
  
    indicators.push(ltvIndicator);
    indicators.push(costOfDebtIndicator);
    indicators.push(marketValueIndicator);
  
    indicators.push(pricePerSqFtIndicator);
    indicators.push(priceToRentIndicator);
    indicators.push(budgetIndicator);
  
   // console.log(JSON.stringify(indicators));
  
    return indicators;
  };

  /*
exports.calculateIndicators = calculateIndicators;
exports.getRedIndicatorRecommendation = getRedIndicatorRecommendation;
exports.getIndicatorDescription = getIndicatorDescription;
exports.isIndicatorDisabled = isIndicatorDisabled;
*/

const RECOMMENDATIONS = {
    "GRM":  ["Negotiate a lower purchase price",
             "Increase the monthly rent"
            ],

    "CAP":  ["Negotiate a lower purchase price",
             "Increase the monthly rent",
             "Lower your expenses"
            ],

   "COC":   ["Put down a larger down payment",
             "Negotiate a lower purchase price",
             "Increase the monthly rent",
             "Lower your monthly expenses"
            ],

    "PI":  ["Negotiate a lower purchase price",
             "Increase the monthly rent",
             "Reduce your monthly expenses",
             "Increase your sale proceeds"
            ],

    "IRR":  ["Increase the monthly rent",
             "Reduce your monthly expenses",
             "Increase your sale proceeds"
            ],

    "DCR_RENTAL":  ["Negotiate a lower purchase price",
             "Increase the monthly rent",
             "Lower your expenses"
            ],
    
    "DCR_PRIMARY":  ["Put down a larger down payment",
                    "Negotiate a lower purchase price",
                    "Increase your monthly income",
                    "Lower your monthly bills"
            ],
    
    "DCR_FLIPPING":  [  "Put down a larger down payment",
                        "Negotiate a lower purchase price",
                        "Lower your closing costs",
                        "Lower your rehab costs",
                        "Lower your holding costs",
                        "Lower your selling costs",
                        "Increase your budget"
           ],

    "BER":  ["Put down a larger down payment",
             "Negotiate a lower purchase price",
             "Increase the monthly rent",
             "Lower your expenses"
            ],

    "LVR":  ["Put down a larger down payment",
             "Negotiate a lower purchase price"
            ],

    "NCF":  ["Negotiate a lower purchase price",
             "Increase the monthly rent",
             "Lower your expenses",
             "Put down a larger down payment"
            ],

    "R70":  ["Negotiate a lower purchase price",
             "Lower your rehab costs"
            ],

    "BUDGET_FLIPPING":["Negotiate a lower purchase price",
             "Lower your closing costs",
             "Lower your rehab costs",
             "Lower your holding costs",
             "Lower your selling costs",
             "Increase your budget",
             "Put down a larger down payment"
            ],

    "BUDGET_PRIMARY":["Negotiate a lower purchase price",
            "Lower your closing costs",
            "Lower your rehab costs",
            "Lower your holding costs",
            "Lower your selling costs",
            "Increase your budget",
            "Put down a larger down payment"
           ],

    "ROI":  ["Negotiate a lower purchase price",
             "Lower your closing costs",
             "Lower your rehab costs",
             "Lower your holding costs",
             "Lower your selling costs",
             "Put down a larger down payment"
            ],

    "KRI":  ["Negotiate a lower purchase price",
             "Hold the property for less time. Lower the holding months"
            ],

    "NP":   ["Negotiate a lower purchase price",
             "Lower your closing costs",
             "Lower your rehab costs",
             "Lower your holding costs",
             "Lower your selling costs",
             "Put down a larger down payment"
            ],

    "MVP":  ["Negotiate a lower purchase price"
            ],

    "BE":   ["Negotiate a lower purchase price",
             "Increase your monthly income",
             "Lower your monthly bills",
             "Put down a larger down payment"
            ],

    "FE":   ["Put down a larger down payment",
             "Negotiate a lower purchase price",
             "Increase your monthly income"
            ],

    "PTR":  ["Negotiate a lower purchase price",
             ],

    "PPQ":  ["Negotiate a lower purchase price"
            ],

    "COD":   ["Negotiate a lower interest rate",
             "Choose a lower loan term"
            ]
};

const INDICATOR_DESCRIPTIONS = {
    "GRM":{term:"Gross Rent Multiplier",
           definition:"The gross rent multiplier (GRM) is a screening metric used by investors to compare rental property opportunities in a given market. The GRM functions as the ratio of the property’s market value over its annual gross rental income.\n\nThe gross rent multiplier (GRM) typically ranges from 4 to 10. Most investors prefer properties with a gross rent multiplier (GRM) below 10."
          },
    "CAP":{term:"Capitalization Rate",
           definition:"The capitalization rate is a metric that allows investors to measure the earning ability of a rental property. It is the ratio between a property's net operating income and the purchase price.\n\nThe higher the capitalization rate, the better, as this is the money that really flows into your pocket. Most investors would not go for a capitalization rate lower than 7%."
          },
    "COC":{term:"Cash on Cash Return",
                definition:"The cash on cash return is a percentage that measures the return on the cash invested in the first year of the investment.\n\nThe higher the cash on cash return, the better. However, it depends on the returns you would get on alternative investments, such as stocks or bonds."
            },
    "IRR":{term:"Internal Rate of Return",
                definition:"The internal rate of return (IRR) measures the profitability of a potential investment based on its predicted future cash flows.\n\nThe higher the internal rate of return (IRR), the better. The higher the IRR for a project, the more attractive the project is to undertake."
            },
    "PI":{term:"Profitability Index",
                definition:"The profitability index measures the cost-benefit of an rental property. It is an alternative way of stating the net present value (NPV), also known as benefit/cost ratio.\n\nThe higher the profitablity index, the better. A profitability index of 1.0 means you achieved your goal rate of return while an index greater than 1.0 means you have exceeded your goal rate of return. Lastly, an index lower than 1.0 means you failed to reach your goal rate of return."
            },

    "DCR_PRIMARY":{term:"Debt Coverage Ratio",
    definition:"The debt coverage ratio indicates whether the buyer has sufficient income to cover the debt for one year.\n\nA debt coverage ratio of 1.00 means you have exactly enough money to cover the mortgage. A ratio greater than 1.00 means you have more than enough money to cover the mortgage and have some left over, while a ratio less than 1.00 indicates that you don't have enough money to pay the mortgage."
        },
    "DCR_RENTAL":{term:"Debt Coverage Ratio",
                definition:"The debt coverage ratio indicates whether the buyer has sufficient income to cover the debt for one year.\n\nA debt coverage ratio of 1.00 means you have exactly enough money to cover the mortgage. A ratio greater than 1.00 means you have more than enough money to cover the mortgage and have some left over, while a ratio less than 1.00 indicates that you don't have enough money to pay the mortgage."
            },
    "DCR_FLIPPING":{term:"Debt Coverage Ratio",
                    definition:"The debt coverage ratio indicates whether the investor has sufficient income to to flip the property.\n\nA debt coverage ratio of 1.00 means you have exactly enough money to cover the investment. A ratio greater than 1.00 means you have enough money to cover the investment and some left over, while a ratio less than 1.00 means you don't have enough money to cover the investment."
                },
    "BER":{term:"Break-Even Ratio",
                definition:"The break-even ratio is an indicator used by lenders when underwriting investment properties; its purpose is to estimate how vulnerable a property is of defaulting on its debt should rental income decline.\n\nThe lower the break-even ratio, the better. This ratio typically ranges between 70% and 100%. Most lenders look for a break-even ratio of 85% or less."
            },
    "LVR":{term:"Loan-to-Value Ratio",
                definition:"The loan-to-value (LTV) ratio is the ratio of the loan balance to the market value of a property, expressed as a percentage. The LTV can be used to estimate the amount of equity you have in a property.\n\nThe lower the LTV ratio, the better. Lenders usually want an LTV of 70% or lower, but check with the bank since the required ratio may vary depending on the level of risk the bank is willing to take."
            },
    "NCF":{term:"Net Cash Flow",
                definition:"The net cash flow is an indicator that calculates the net cash flow of the property."
            },
    "R70":{term:"Rule of 70",
                definition:"The Rule of 70 states that an investor should pay no more than 70% of the after-repair value minus repairs in order make a profit.\n\nThe lower the value for the Rule of 70, the better. Anything below 70% is considered good."
            },
    "BUDGET":{term:"Budget Usage",
                definition:"The budget is an indicator that tells you whether you can finance the investment property with the money you have allocated for the project.\n\nThe lower the budget percentage, the better. This means that you will have more money left over."
            },
    "ROI":{term:"Return on Investment (ROI)",
                definition:"The ROI is an indicator that answers the question How much will I get back per each dollar I invest? The ROI measures the financial return on an investment relative to its cost.\n\nThe higher the ROI, the better."
            },
    "KRI":{term:"Key Risk Indicator",
                definition:"The key risk indicator calculates the maximum allowable percentage that the property can drop in price while you hold the property before the investment starts losing money.\n\nThe higher the market value drop you can absorb, the better."
            },
    "NP":{term:"Net Profit",
            definition:"The net profit tells you exactly how much money you will make from this real estate investment."
        },
    "MVP":{term:"Market Value Percent",
                definition:"The market value percentage indicator compares the current market value of a property to its purchase price. Ideally, you want to purchase properties below market value, and the lower the percentage below market value, the better."
            },
    "BE":{term:"Back-End Ratio",
            definition:"This ratio indicates what portion of a person's monthly income goes towards paying debts. Total monthly debt includes expenses such as mortgage payments, credit card payments, child support, and other loan payments.\n\nThe lower the back-end ratio, the better. Generally. lenders like to see a back-end ratio that does not exceed 36%."
        },
    "FE":{term:"Front-End Ratio",
                definition:"It is the percentage of your monthly income spent on your mortgage payment, and it is used by lenders to qualify borrowers for a mortgage.\n\nThe lower the front-end ratio, the better. Generally, lenders like to see a front-end ratio that doesn't exceed 28%."
            },
    "PTR":{term:"Price-to-Rent Ratio",
                definition:"The price-to-rent ratio measures the value of a property compared to the income it could generate through rent. It is an important indicator because when a property price significantly deviates from the income it can earn through rent, there is a higher probability that the property price will decline or not rise as much over time.\n\nThe lower the price-to-rent ratio, the better. As a general rule of thumb, consumers should consider buying when the ratio is under 15 and renting when it is above 20."
            },
    
    "PPQ":{term:"Price per SqFt",
            definition:"You can arrive at the trending price-per-sqft of a property by adding the price-per-sqft of properties that have sold in a given area and dividing by the number of properties sold.\n\nThe lower the price-per-sqft, the better. If your price-per-sqft exceeds the trending price-per-sqft of similar properties in the area, you might be paying too much for the property."

        },
    "COD":{term:"Cost of Debt",
            definition:"The cost-of-debt indicator compares the percentage of the cost of debt in relation to the loan amount, which shows how much money you are paying the lender in interest relative to the total loan amount.\n\nThe less the cost-of-debt, the better. Basically you want to pay the lender as little as possible in interest."
        },
}
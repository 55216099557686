import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';

class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>price-to-rent</b> ratio measures the value of a property compared to the income it could generate through rent. It is an important indicator because when a property price significantly deviates from the income it can earn through rent, there is a higher probability that the property price will decline or not rise as much over time.
                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        If a property has a low <b>price-to-rent</b> ratio, it is more likely to be a good investment opportunity. Conversely, a property with a high price-to-rent ratio is usually not as good an investment opportunity.

                                        </div>

                                        <div style={{paddingBottom:'12px'}}>
                                        The <b>price-to-rent</b> ratio can also indicate whether owning or renting makes better financial sense. As a general rule of thumb, consumers should consider buying when the ratio is under <b>15</b> and renting when it is above <b>20</b>.

                                        </div>
                             
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The lower the <b>price-to-rent</b> ratio, the better. As a general rule of thumb, consumers should consider buying when the ratio is under <b>15</b> and renting when it is above <b>20</b>.

                                    <br></br><br></br>
                                    Investors should generally buy investment properties with a <b>price-to-rent</b> ratio that is equal to or under <b>15</b>, since a high <b>price-to-rent</b> ratio is indicative of future property price decline.

                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>price-to-rent</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Purchase Price
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row" style={{paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Trending Monthly Rent in Area
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT}
                                                        </div>
                                                    
                                                </div>


                                            </div>
                                        </div>
                                                             
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">


                                        {/* ---------------------  STEP 1 ------------------ */}

                                        <div class="row">
    
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 1:</span> <b>Calculate Annual Rent</b>
                                           </div>
                                       </div>
                                       
                                       <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table" style={{paddingLeft:'15%'}}>

                                                <div style={{paddingTop:"12px"}}>
                                                    Annual Rent = <b>Trending Monthly Rent x 12</b>
                                                </div>

                                                <div style={{paddingTop:"12px"}}>
                                                    Annual Rent = <b>{this.state.indicator.helpVariableMap.VAR_MONTHLY_RENT} x 12</b>
                                                </div>

                                                <div style={{paddingTop:"12px"}}>
                                                    Annual Rent = <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_RENT}</b>
                                                </div>

                                                <br></br>
                                            </div>
                                        </div>

                                   
                                        {/* ---------------------  STEP 2 ------------------ */}
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 2:</span> <b>Calculate Price-to-Rent Ratio</b>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px", paddingLeft:"15%"}}>

                                                    <table>
                                                        <tr>
                                                            <td style={{paddingTop:'5px'}}>Price-to-Rent Ratio = </td>
                                                            <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>Purchase Price</b></div>
                                                                 
                                                                <b>Annual Rent</b>

                                                            </td>
                                                        </tr>

                                                    </table>

                                                    <div style={{paddingTop:'25px'}}>
                                                        <table>
                                                            <tr>
                                                                <td style={{paddingTop:'5px'}}>Price-to-Rent Ratio = </td>
                                                                <td style={{textAlign:"center",paddingLeft:'5px',paddingRight:'5px'}}>
                                                                    <div style={{borderBottom: "2px solid  #1c7cfb", textAlign:'center',paddingLeft:'10px',paddingRight:'10px'}}><b>{this.state.indicator.helpVariableMap.VAR_PURCHASE_PRICE}</b></div>
                                                                    
                                                                    <b>{this.state.indicator.helpVariableMap.VAR_ANNUAL_RENT}</b>

                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                   
                                          
                                            


                                                </div>
                                            </div>
                                        </div>

                                               {/* ---------------------  STEP 3 ------------------ */}


                                               <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">STEP 3:</span> <b>Results</b>
                                            </div>
                                            </div>

                                                    <div class="row">
                                                        <div class="col-md-12 indicator-info-formula-table">
                                                            <div class="row" style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:"15px"}}>
                                                                <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                                Price-to-Rent Ratio = 
                                                                </div>
                                                                <div class="col-md-4 indicator-info-formula-table-col1">
                                                                    <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                            </div>
                                                                      

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                            This outcome can be changed by changing the Price-to-Rent ratio benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                PURCHASE PRICE<br></br>
                                                TRENDING RENT IN AREA
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      


                  {/* TIP */}
                  {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                        </div>

                    </div>        
                }                 
                
                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

function LandingPageHTML(props) {
    return (

        <html lang="en-us">
            <head>
            </head>

            <body>
            <h1>Real Estate Analysis Application</h1>
            
            <div style={{paddingLeft:'20px',fontSize:'18px'}}>
                <a href="/Login">Login</a><br></br><br></br>
                <a href="/pricing">Pricing</a><br></br><br></br>
                <a href="/signup">Try it Free</a><br></br><br></br>
                
            </div>

            </body>

        </html>

);
};

const _LandingPageHTML = LandingPageHTML;
export { _LandingPageHTML as LandingPageHTML };
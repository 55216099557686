import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import Slider from '@material-ui/core/Slider';

export const styles = (theme) => ({
// Your styles here...
});

export class MortagageCalculator extends Component {
constructor(props) {
	super(props);
	this.state = {
	purchasePrice: '425,000',
	downPayment: '85,000',
	downPaymentPercent: '',
	interestRate: '7',
	loanTerm: '30',
	monthlyPayment: 0,
	taxesInsuranceCollapsed: true,
	propertyTax: '280',
	homeownersInsurance: '65',
	PMI: '98',
	homeownersAssociation: '50',
	propertyTaxSlider: 280, // added propertyTaxSlider field
	chartData: {
		labels: ['Monthly Payment', 'Property Tax', 'Homeowner\'s Insurance', 'PMI', 'Homeowners Association'],
		datasets: [
		  {
			data: [0, 0, 0, 0, 0],
			backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D'],
			hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D']
		  }
		]
	  }
	};
}

componentWillMount = () => {
	this.calculateMonthlyPayment();
}

calculateMonthlyPayment = () => {
	//const { purchasePrice, downPayment, interestRate, loanTerm } = this.state;
	const { purchasePrice, downPayment, interestRate, loanTerm, monthlyPayment, propertyTax, homeownersInsurance, PMI, homeownersAssociation } = this.state; // added propertyTax, homeownersInsurance and PMI to the destructure

	const principal = parseFloat(purchasePrice.replace(/,/g, '')) - parseFloat(downPayment.replace(/,/g, ''));
	const interest = parseFloat(interestRate) / 100 / 12;
	const periods = parseFloat(loanTerm) * 12;

	const x = Math.pow(1 + interest, periods);
	const monthly = (principal * x * interest) / (x - 1);

	this.setState({ monthlyPayment: monthly.toFixed(2) });

	const downPaymentPercent = ((parseFloat(downPayment.replace(/,/g, '')) / parseFloat(purchasePrice.replace(/,/g, ''))) * 100).toFixed(2);
	this.setState({ downPaymentPercent });

	
	const chartData = {
		labels: ['Monthly Payment', 'Property Tax', 'Homeowner\'s Insurance', 'PMI', 'Homeowners Association'],
		datasets: [
		  {
			data: [monthlyPayment, propertyTax, homeownersInsurance, PMI, homeownersAssociation],
			backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D'],
			hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8F8DFF', '#6C757D']
		  }
		]
	  };
	
	  this.setState({ chartData });

};

handlePurchasePriceChange = (e) => {
	this.setState({ purchasePrice: e.target.value }, this.calculateMonthlyPayment);
};

/*
handlePurchasePriceSliderChange = (e) => {
	const value = Math.min(Math.round(e.target.value / 1000) * 1000, 10000000);
	const formattedValue = Number(value).toLocaleString();
	this.setState({ purchasePrice: formattedValue }, this.calculateMonthlyPayment);
};
*/

handlePurchasePriceSliderChange = (e, value) => {
    const formattedValue = Number(value).toLocaleString();
    this.setState({ purchasePrice: formattedValue }, this.calculateMonthlyPayment);
  };


handlePropertyTaxSliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ propertyTax: formattedValue }, this.calculateMonthlyPayment);
};

handleHomeownersInsuranceSliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ homeownersInsurance: formattedValue }, this.calculateMonthlyPayment);
};

handlePMISliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ PMI: formattedValue }, this.calculateMonthlyPayment);
};

handleHOASliderChange = (e, value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ homeownersAssociation: formattedValue }, this.calculateMonthlyPayment);
};

handleDownPaymentChange = (e) => {
	const value = e.target.value.replace(/\D/g, '');
	const formattedValue = Number(value).toLocaleString();
	this.setState({ downPayment: formattedValue }, this.calculateMonthlyPayment);
};

/*
handleDownPaymentSliderChange = (e) => {
	const value = Math.min(Math.round(e.target.value / 1000) * 1000, 800000);
	const formattedValue = Number(value).toLocaleString();
	this.setState({ downPayment: formattedValue }, this.calculateMonthlyPayment);
};
*/

handleDownPaymentSliderChange = (e,value) => {
	const formattedValue = Number(value).toLocaleString();
	this.setState({ downPayment: formattedValue }, this.calculateMonthlyPayment);
};

handleInterestRateChange = (e) => {
	this.setState({ interestRate: e.target.value }, this.calculateMonthlyPayment);
};

/*
handleInterestRateSliderChange = (e) => {
	const value = Math.min(Math.round(e.target.value / 0.25) * 0.25, 12);
	this.setState({ interestRate: value }, this.calculateMonthlyPayment);
};
*/

handleInterestRateSliderChange = (e,value) => {
	//const value = Math.min(Math.round(e.target.value / 0.25) * 0.25, 12);
	
	//const value2 = Math.min(Math.round(value / 0.25) * 0.25, 12);

	this.setState({ interestRate: value }, this.calculateMonthlyPayment);
};


handleLoanTermChange = (e) => {
	this.setState({ loanTerm: e.target.value }, this.calculateMonthlyPayment);
};

/*
handleLoanTermSliderChange = (e) => {
	const value = e.target.value;
	this.setState({ loanTerm: value }, this.calculateMonthlyPayment);
};
*/
handleLoanTermSliderChange = (e,value) => {
	this.setState({ loanTerm: value }, this.calculateMonthlyPayment);
};


formatNumberWithCommas(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

handleCollapseSection = (event) => {

	event.preventDefault();


	if (this.state.taxesInsuranceCollapsed == true)
	{
		this.setState({ taxesInsuranceCollapsed: false });

	}else
	{
		this.setState({ taxesInsuranceCollapsed: true });
	}
}

render() {
	const { classes } = this.props;
	const { purchasePrice, downPayment, downPaymentPercent, interestRate, loanTerm, monthlyPayment, propertyTax, homeownersInsurance, PMI, homeownersAssociation } = this.state; // added propertyTax, homeownersInsurance and PMI to the destructure

	return (
	<div className="container mt-4">
		<h2 style={{fontWeight:'bold',color:'#000000'}}>Mortgage Calculator</h2>

		<div classname="row">
		<div classname="col-6" style={{minWidth:'400px'}}>
		<form>
	
		<div className="form-group">
			<label style={{fontWeight:'bold',color:'#000000'}}>Purchase Price ($)</label>
			<input style={{color:'#000000'}} 
			type="text"
			className="form-control"
			value={purchasePrice}
			onChange={this.handlePurchasePriceChange}
			/>
			{/*
			<input
			type="range"
			min="0"
			max="10000000"
			step="1000"
			value={purchasePrice.replace(/,/g, '')}
			onChange={this.handlePurchasePriceSliderChange}
			/>
			*/}
			<Slider
              min={0}
              max={10000000}
              step={1000}
              value={parseFloat(purchasePrice.replace(/,/g, ''))}
              onChange={this.handlePurchasePriceSliderChange}
              valueLabelDisplay="auto"
              marks={[{value: 300000, label: '300,000'}, {value: 5000000, label: '5,000,000'}, {value: 10000000, label: '10,000,000'}]}
            />
		</div>
		<div className="form-group">
			<label style={{fontWeight:'bold',color:'#000000'}}>Down Payment ($)</label>
			<input style={{color:'#000000'}}
			type="text"
			className="form-control"
			value={downPayment}
			onChange={this.handleDownPaymentChange}
			/>
			{/*
			<input
			type="range"
			min="0"
			max="800000"
			step="1000"
			value={downPayment.replace(/,/g, '')}
			onChange={this.handleDownPaymentSliderChange}
			/>
			*/}

			<Slider
              min={0}
              max={1000000}
              step={1000}
              value={parseFloat(downPayment.replace(/,/g, ''))}
              onChange={this.handleDownPaymentSliderChange}
              valueLabelDisplay="auto"
              marks={[ {value: 100000, label: '100,000'}, {value: 300000, label: '300,000'},{value: 500000, label: '500,000'}, {value: 1000000, label: '1,000,000'}]}
            />

		</div>
		<div className="form-group">
			<label style={{fontWeight:'bold',color:'#000000'}}>Down Payment Percent (%)</label>
			<input style={{color:'#000000'}}
			type="text"
			className="form-control"
			value={downPaymentPercent}
			readOnly
			/>
		</div>
		<div className="form-group">
			<label style={{fontWeight:'bold',color:'#000000'}}>Interest Rate (%)</label>
			<input style={{color:'#000000'}}
			type="number"
			step="0.01"
			className="form-control"
			value={interestRate}
			onChange={this.handleInterestRateChange}
			/>
			{/*
			<input
			type="range"
			min="0"
			max="12"
			step="0.25"
			value={interestRate}
			onChange={this.handleInterestRateSliderChange}
			/>
		*/}
		<Slider
              min={0}
              max={15}
              step={.25}
              value={interestRate}
              onChange={this.handleInterestRateSliderChange}
              valueLabelDisplay="auto"
              marks={[{value: 4, label: '4'}, {value: 7, label: '7'}, {value: 10, label: '10'}, {value: 15, label: '15'}]}
            />

		</div>
		<div className="form-group">
			<label style={{fontWeight:'bold',color:'#000000'}}>Loan Term (Years)</label>
			<input style={{color:'#000000'}}
			type="number"
			className="form-control"
			value={loanTerm}
			onChange={this.handleLoanTermChange}
			/>
			{/*
			<input
			type="range"
			min="10"
			max="50"
			step="5"
			list="tickmarks"
			value={loanTerm}
			onChange={this.handleLoanTermSliderChange}
			/>
			*/}
			<Slider
              min={10}
              max={50}
              step={5}
              value={loanTerm}
              onChange={this.handleLoanTermSliderChange}
              valueLabelDisplay="auto"
              marks={[{value: 10, label: '10'}, {value: 15, label: '15'}, {value: 20, label: '20'}, {value: 25, label: '25'}, {value: 30, label: '30'}, {value: 40, label: '40'}, {value: 50, label: '50'}]}
            />


			<datalist id="tickmarks">
			<option value="10" label="10 Years" />
			<option value="15" label="15 Years" />
			<option value="20" label="20 Years" />
			<option value="30" label="30 Years" />
			<option value="40" label="40 Years" />
			<option value="50" label="50 Years" />
			</datalist>
		</div>

		{/* Collapsible section for Taxes, insurance, HOA fees */}
		<div className="form-group">
			<button
			className="btn btn-link"
			onClick={this.handleCollapseSection}
			aria-expanded={!this.state.taxesInsuranceCollapsed}
			>
			Taxes, insurance, HOA fees (Monthly)
			</button>
			{!this.state.taxesInsuranceCollapsed && (
			<div>
				<div className="form-group">
				<label style={{fontWeight:'bold',color:'#000000'}}>Property Tax ($)</label>
				<input style={{color:'#000000'}}
					type="text"
					className="form-control"
					value={propertyTax}
					onChange={(e) => this.setState({ propertyTax: e.target.value })}
				/>
				{/*
				<input
					type="range"
					min="0"
					max="5000"
					step="25"
					value={propertyTax}
					onChange={(e) => this.setState({ propertyTax: e.target.value })}
				/>
				*/}
				<Slider
					min={0}
					max={5000}
					step={25}
					value={parseFloat(propertyTax.replace(/,/g, ''))}
					onChange={this.handlePropertyTaxSliderChange}
					valueLabelDisplay="auto"
					marks={[{value: 50, label: '50'}, {value: 500, label: '500'}, {value: 5000, label: '5,000'}]}
					/>
				</div>
				<div className="form-group">
				<label style={{fontWeight:'bold',color:'#000000'}}>Homeowner's Insurance ($)</label>
				<input style={{color:'#000000'}}
					type="text"
					className="form-control"
					value={homeownersInsurance}
					onChange={(e) => this.setState({ homeownersInsurance: e.target.value })}
				/>
				{/*
        				<input
		                  type="range"
		                  min="5"
		                  max="1000"
		                  step="5"
		                  value={homeownersInsurance}
		                  onChange={(e) => this.setState({ homeownersInsurance: e.target.value })}
		              />
				*/}
					<Slider
					min={5}
					max={1000}
					step={5}
					value={parseFloat(homeownersInsurance.replace(/,/g, ''))}
					onChange={this.handleHomeownersInsuranceSliderChange}
					valueLabelDisplay="auto"
					marks={[{value: 50, label: '50'}, {value: 500, label: '500'}, {value: 1000, label: '1,000'}]}
					/>
				</div>
				<div className="form-group">
				<label style={{fontWeight:'bold',color:'#000000'}}>PMI ($)</label>
				<input style={{color:'#000000'}}
					type="text"
					className="form-control"
					value={this.state.PMI}
					onChange={(e) => this.setState({ PMI: e.target.value })}
				/>
				{/*
				<input
					type="range"
					min="0"
					max="1000"
					step="2"
					value={this.state.PMI}
					onChange={(e) => this.setState({ PMI: e.target.value })}
				/>
			*/}
			
			<Slider
              min={0}
              max={1000}
              step={2}
              value={parseFloat(this.state.PMI.replace(/,/g, ''))}
              onChange={this.handlePMISliderChange}
              valueLabelDisplay="auto"
              marks={[{value: 50, label: '50'}, {value: 500, label: '500'}, {value: 1000, label: '1,000'}]}
            />

				</div>
				<div className="form-group">
				<label style={{fontWeight:'bold',color:'#000000'}}>Homeowners Association ($)</label>
				<input style={{color:'#000000'}}
					type="text"
					className="form-control"
					value={this.state.homeownersAssociation}
					onChange={(e) => this.setState({ homeownersAssociation: e.target.value })}
				/>
				{/*
					<input
					type="range"
					min="0"
					max="1000"
					step="2"
					value={this.state.homeownersAssociation}
					onChange={(e) => this.setState({ homeownersAssociation: e.target.value })}
				/>
				*/}
				<Slider
					min={0}
					max={1000}
					step={2}
					value={parseFloat(this.state.homeownersAssociation.replace(/,/g, ''))}
					onChange={this.handleHOASliderChange}
					valueLabelDisplay="auto"
					marks={[{value: 50, label: '50'}, {value: 500, label: '500'}, {value: 1000, label: '1,000'}]}
				/>


				</div>
			</div>
			)}
		</div>

		{/*
		<button
			type="button"
			className="btn btn-primary"
			onClick={this.calculateMonthlyPayment}
		>
			Calculate
		</button>
			*/}

		</form>

		</div> {/* column */}
		<div classname="col-6" style={{paddingLeft:'50px',minWidth:'400px'}}>
		{monthlyPayment > 0 && (
		<div className="mt-3">
			<h3 style={{fontWeight:'bold',color:'#000000'}}>Monthly Payment: ${this.formatNumberWithCommas(monthlyPayment)}</h3>
		</div>
		)}

		{monthlyPayment > 0 && (
		<div className="mt-3" style={{backgroundColor:'#4e73df'}}>
			{/* <h4>Monthly Payment: ${this.formatNumberWithCommas(monthlyPayment)}</h4> */}

			<Pie data={this.state.chartData} />
		</div>
		)}
		</div> {/* column */}
		</div> {/* row */}	

	</div>
	);
}
}



import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

//import appLogo from '../images/logo-nocircle.png';

import appLogo from './img/website-images/real-estate-property-analytics-logo.png';

import { OverlayTrigger,Tooltip} from "react-bootstrap";

import closeIcon from './img/icons/x2.png';

import axios from 'axios';
import { authMiddleWare } from '../utilities/Auth';

import CheckoutForm from "../components/CheckoutFormSignUp";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const { stripePublishablecKey, plans} = require('../utilities/Constants');

const stripePromise = loadStripe(stripePublishablecKey);


const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#e37627'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progess: {
		position: 'absolute'
	}
});

class paymentInfo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			firstName: '',
			name: '',
		  	blogUrl: '',
			type: '',
          	email: '',
			password: '',
			user:null,
			errors: [],
			loading: false,
			displayScreen:false
		};

		this.indexPageElement = null;

	}


	componentWillMount = () => {

		try
		{
			
			// 2. check user is logped in
			console.log("About to check logged in user.");

			authMiddleWare(this?.props?.history);
			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.get('/api/alreadyLoggedInCheck')
				.then((response) => {

					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
					//console.log(JSON.stringify(response));

					//If user is logged in
					if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
					{
						//Retrieve user
						authMiddleWare(this?.props?.history);
						const authToken = localStorage.getItem('AuthToken');
						const refreshToken = localStorage.getItem('RefreshToken');
						axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
                        axios
                        .get('/api/user')
                        .then((response) => {
                            //console.log(response.data);
							if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
							{
								localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
								localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
							}
			
							const user = response.data;

                            this.setState({ 
                                user: response.data,
								email: response.data.email,
								displayScreen:true
                            });	

							localStorage.setItem('SessionExpired','N')

							//We already have payment information
							if (user.termsAndConditionsAccepted ==="Y" && user.stripeSubscriptionId != null && user.stripeSubscriptionId !="")
							{
								this.props.history.push('/app');

							}else if (user.termsAndConditionsAccepted === null || user.termsAndConditionsAccepted ==="N")
							{
								this.props.history.push('/signup/terms-and-conditions');
							}
                            
                        })
                        .catch((error) => {
                            console.log('Error in getting user record:'+error);
        
                            this.setState({ 
                                user: null,
								displayScreen:true
                            });	
                            //If user is not logged in redirect to signup
							this.props.history.push('/signup');

                    
                          });
					} 

				})
				.catch((error) => {
					//console.log(error);
					if(error.response.status === 403) {
						
						//If user is not logged in redirect to signup
						this.props.history.push('/signup');
					}
					
				});

			
			} catch(err)
			{
				console.log("Error checking logged in user:"+err);

				this.setState({ 
					user: null
				});	
			
			}
	}

	componentDidMount= () => {
	
	  };

	upperCaseFirstLetter= (value) => {
	   
	   let newValue = "";

	   if (value != null)
	   {
			newValue = value.charAt(0).toUpperCase() + value.slice(1);
	   }

	   return newValue;
	};

	getPlanPrice= (planName, frequency) => {
	   
		let formattedPrice = "";
		
		if (planName != null)
		{
			let dollarUS = Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",	
			  });
			  
			  const price = plans[planName].price[frequency];
			  formattedPrice = dollarUS.format(price);
		}
 
		return formattedPrice;
	 };

	 getPlanName= (planName) => {
	   
		let name = "";

		if (planName != null)
		{
			name = plans[planName].name;
		}

		return name;
	 };

	render() {
		const theme = createTheme({
			palette: {
			  primary: {
				main: '#e37627' //your color
			  }
			}
		  });
		const { classes } = this.props;
		const { errors, loading } = this.state;
			return (
				<div>
					{this.state.displayScreen && 
					<div>

					<div style={{float:'right'}}><a href="/pricing"><img src={closeIcon} style={{height:'30px'}}/></a> &nbsp;&nbsp;&nbsp;&nbsp;</div>

				<Container component="main" maxWidth="xs"> 
				<CssBaseline />
				<div className={classes.paper}>
				
				{/* <img src={appLogo} style={{height:'45px',cursor:'pointer'}} onClick={this.goHome}/> */}

					{/*
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#e37627',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
					GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>
					</div>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					*/}

					<img src={appLogo} style={{height:'85px',cursor:'pointer',paddingBottom:"10px"}} onClick={this.goHome}/> 

					<Typography component="h1" variant="h5">
						Billing Information
					</Typography>
					
					{(this.state.user != null && this.state.user.planType != "trial") && 

						<div style={{paddingTop:'10px',paddingBottom:'1px'}}>
							<span class="dot"></span>
							<span class="dot"></span>
							<span class="dot-circle"></span>
						</div>
					
					}

					<OverlayTrigger
                                                          placement="auto"
                                                          delay={{ show: 150, hide: 100 }} 
                                                          trigger={["hover","click"]}
                                                          overlay={<Tooltip id="indicatorInfoTooltip" className="messagetooltip">

									Perfect for Investors <br></br>
									3 Advanced Searches <br></br>
									4 Profiles <br></br>

								</Tooltip>}
                                                        >
                    <div style={{width:'100%'}}>
						<center>                                    										
							<br></br>
							<table style={{width:'67%',fontSize:'18px',paddingTop:'5px',paddingBottom:'5px'}}>
								<tr>
									<td style={{fontWeight:'bold',float:'left'}}>{this.getPlanName(this.state?.user?.planName)} - {this.upperCaseFirstLetter(this.state?.user?.billingFrequency)}</td> 		
									<td style={{fontWeight:'bold',float:'right'}}> {this.getPlanPrice(this.state?.user?.planName,this.state?.user?.billingFrequency)} </td>
								</tr>
								<tr>
									<td colSpan={2} style={{width:'100%',textAlign:'center'}}><a href="/pricing" style={{fontSize:"14px",paddingTop:'4px'}}>Change Plan</a></td>
								</tr>
							</table>
							<br></br>
						</center>
					</div>

					</OverlayTrigger>

					<ThemeProvider theme={theme}>
						<Grid container spacing={2}>
						
							<Grid item xs={12}>
								<Elements stripe={stripePromise}>

								<CheckoutForm targetAction="Subscribe" user={this.state.user} plan={this.state?.user?.planName} billingFrequency={this.state?.user?.billingFrequency}/>

								</Elements>
							</Grid>
					
					
						</Grid>
					
					<br/>
					
						</ThemeProvider>
					
					<br></br><br></br><br></br>
				</div>
				</Container>
			   </div>
				}
			 </div>
				  
	)};

}


export default withStyles(styles)(paymentInfo);

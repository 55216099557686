import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

import stopicon from '../../pages/img/icons/stop2-sm.png';
import goicon from '../../pages/img/icons/go2-sm.png';

import greenTrafficLight from '../../pages/img/indicators/traffic-lights-green.png';
import redTrafficLight from '../../pages/img/indicators/traffic-lights-red.png';

import quicktipIcon from '../../pages/img/icons/quicktips.jpg';

import IndicatorHeader from './IndicatorHeader';


class GRMIndicator extends Component { 
    
    constructor(props) {
        super(props);

        //alert(JSON.stringify(this.props.indicator));

        this.state = {
            isOpen: false,
			indicator: this.props.indicator
		};
        
     }


     expandCollapseOverview(event) {

        event.preventDefault();
  
        var overviewDiv = document.querySelector('#overviewSection'); 
  
        var overviewSign = document.querySelector('#overviewSign'); 
     
        if (overviewSign.innerHTML === "+")
        {
          overviewSign.innerHTML = "-";
          overviewDiv.style.display= "block";
        }else
        {
          overviewSign.innerHTML = "+";
          overviewDiv.style.display= "none";
        }
        
     }

  
     expandCollapseCalculations(event) {

        event.preventDefault();
  
        var calculationsDiv = document.querySelector('#calculationsSection'); 
  
        var calculationsSign = document.querySelector('#calculationsSign'); 
     
        if (calculationsSign.innerHTML === "+")
        {
            calculationsSign.innerHTML = "-";
            calculationsDiv.style.display= "block";
        }else
        {
            calculationsSign.innerHTML = "+";
            calculationsDiv.style.display= "none";
        }
        
     }


     componentDidUpdate() {
        
    }

    componentDidMount() {
        //alert(window.innerWidth);
    }

    componentWillUnmount() {
    }

    FormatIndicatorValue(valueType,value)
    {
        if (valueType == "PERCENT")
        {
            value = this.FormatPercent(value);

        } else if (valueType == "DOLLAR_AMOUNT")
        {
            value = this.FormatMoney(value);
        }
  

    return value;
   }

    
    FormatMoney(value)
  {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });


    let formattedValue = dollarUS.format(value);

    if (formattedValue.endsWith(".00"))
    {
      formattedValue = formattedValue.substring(0, formattedValue.length-3);
    }
    //alert(formattedValue);

    return formattedValue;
  }



  FormatPercent(value)
  {
    value = value.toString() +"%";

    return value;
  }



   
    render() {

        return (

            <main>
            
               <div class="modal-body indicator-info-margins" style={{padding:"7px",margin:"0px"}}>
                   
                    <div style={{marginLeft:"5px",marginRight:"5px"}}>
         
                        <IndicatorHeader indicator={this.state.indicator}/>        

                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                            {this.state.indicator?.indicatorName} Overview  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseOverview(e)}><span id="overviewSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                        <div class="row" id="overviewSection" style={{display:"none"}}>
                            <div class="col-md-12 indicator-info-indicator-result-text" style={{paddingTop:"10px"}}>
                                <div class="row">
                                    <div class="col-md-12">
                                    <div style={{paddingBottom:'12px'}}>
                                    This ratio indicates what portion of a person's monthly income goes towards paying debts. Total monthly debt includes expenses such as mortgage payments, credit card payments, child support, and other loan payments.   
                                    </div>
                                    Lenders use this ratio in conjunction with the front-end ratio to approve mortgages.

                                    <br></br><br></br>
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-sub-title">
                                    Benchmark
                                    </div>
                               </div>
                               <div class="row">
                                    <div class="col-md-12 indicator-info-indicator-result-text">
                                    The lower the <b>back-end ratio</b>, the better.
                                     Generally. lenders like to see a back-end ratio that does not exceed <b>36%</b>.
                                    <br></br><br></br>
                                    <span style={{fontWeight:"bold",color:"#777777"}}>
                                        You can adjust your <b>back-end ratio</b> acceptance criteria in the settings based on your own investing goals and risk tolerance.
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 indicator-info-indicator-expandable-title">
                                Calculations  (<a href="#!" style={{textDecoration:"none"}}  onClick={(e) => this.expandCollapseCalculations(e)}><span id="calculationsSign" style={{fontSize:"23px"}}>+</span></a>)
                            </div>
                        </div>
                    </div>   

                    <div class="row" id="calculationsSection" style={{marginTop:"15px",display:"none"}}>
                        
                        <div class="col-md-12">
                            <div class="row"> 
                            
                                    <div class="col-md-11 indicator-info-indicator-settings indicator-info-calculations-table">
                                        
                                        <div class="row">
                                            <div class="col-md-12" >
                                                {this.state.indicator.property.financingType == "Loan" &&  
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Monthly Mortgage Payment
                                                            {this.state.indicator.property.principalAndInterest == 0 && 
                                                             this.state.indicator.property.propertyTaxes == 0 && 
                                                             this.state.indicator.property.homeownersInsurance == 0 && 
                                                             this.state.indicator.property.hoa == 0 &&        

                                                                <div style={{fontSize:"11px",fontWeight:"plain"}}>Includes Principal & Interest, Taxes, Insurance and HOA.</div>
                                                            }

                                                            {(this.state.indicator.property.principalAndInterest > 0 || 
                                                             this.state.indicator.property.propertyTaxes > 0 ||
                                                             this.state.indicator.property.homeownersInsurance > 0 ||
                                                             this.state.indicator.property.hoa > 0) &&        

                                                                <div style={{fontSize:"11px",fontWeight:"plain"}}>

                                                                <table class="calculation-info-table">
                                                                        {this.state.indicator.property.principalAndInterest > 0 && 
                                                                            <tr>
                                                                                <td>Principal & Interest</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td></td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.principalAndInterest)}</td>
                                                                            </tr>
                                                                        }

                                                                        {this.state.indicator.property.propertyTaxes > 0 && 
                                                                            <tr>
                                                                                <td>Property Taxes</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td>+</td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.propertyTaxes)}</td>
                                                                            </tr>
                                                                        }
                                                                        {this.state.indicator.property.homeownersInsurance > 0 && 
                                                                            <tr>
                                                                                <td>Homeowners Insurance</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td>+</td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.homeownersInsurance)}</td>
                                                                            </tr>
                                                                        }
                                                                        {this.state.indicator.property.hoa > 0 && 
                                                                            <tr>
                                                                                <td>HOA</td>
                                                                                <td style={{paddingLeft:'5px',paddingRight:'5px'}}>:</td>
                                                                                <td>+</td>
                                                                                <td style={{textAlign:'right'}}>{this.FormatMoney(this.state.indicator.property.hoa)}</td>
                                                                            </tr>
                                                                        }

                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td style={{borderTop:"2px solid  #1c7cfb",textAlign:'right',fontSize:'12px'}}><b>{this.state.indicator.helpVariableMap.VAR_MORTGAGE_PAYMENT}</b></td>
                                                                            </tr>

                                                                    </table>

                                                                    
                                                                </div>
                                                            }


                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_MORTGAGE_PAYMENT}
                                                        </div>
                                                    
                                                </div>
                                                }
                                                <div class="row" style={{borderBottom:"2px solid  #1c7cfb",paddingBottom:"2px"}}>

                                                        <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                            Monthly Bills
                                                            <div style={{fontSize:"11px",fontWeight:"plain"}}>Utilities, car insurance, car payments, credit cards ...</div>
                                                        </div>
                                                        <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                            =>
                                                        </div>
                                                        <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                            {this.state.indicator.helpVariableMap.VAR_MONTHLY_BILLS}
                                                        </div>
                                                    
                                                </div>

                                                <div class="row">

                                                    <div class="col-md-7 indicator-info-indicator-accept indicator-info-calculations-table-col1">
                                                        Gross Monthly Income
                                                    </div>
                                                    <div class="col-md-2 indicator-info-indicator-accept indicator-info-calculations-table-col2">
                                                        =>
                                                    </div>
                                                    <div class="col-md-3 indicator-info-indicator-settings-creteria indicator-info-calculations-table-col3">
                                                        {this.state.indicator.helpVariableMap.VAR_GROSS_INCOME}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                         
                                    
                                    </div>
                            
                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>
                                      
                             </div>

                            <div class="row">
                                    <div class="col-md-11" class="indicator-info-indicator-steps">
                                        
                                        {/* ---------------------  STEP 1 ------------------ */}
                                        {this.state.indicator.property.financingType == "Loan" &&  
                                        <span>
                                        <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">STEP 1:</span> <b>Add monthly expenses</b>
                                           </div>
                                       </div>

                                       <div style={{paddingTop:'10px',paddingBottom:'10px',paddingLeft:'40%'}}>
                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"left",maxWidth:'250px'}}>
                                                            Monthly Mortgage Payment
                                                        </div>
                                            </div>
                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            +
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"left",maxWidth:'250px'}}>
                                                            Monthly Bills
                                                        </div>
                                            </div>

                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{borderTop:"2px solid  #1c7cfb",textAlign:"left",maxWidth:'250px'}}>
                                                            <b>Total Monthly Expenses</b>
                                                        </div>
                                            </div>

                                            
                                        <br></br>
                                       
                                        <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"right",maxWidth:'100px'}}>
                                                            {this.state.indicator.helpVariableMap.VAR_MORTGAGE_PAYMENT}
                                                        </div>
                                            </div>
                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            +
                                                        </div>
                                                        <div class="col-md-10" style={{textAlign:"right",maxWidth:'100px'}}>
                                                            {this.state.indicator.helpVariableMap.VAR_MONTHLY_BILLS}
                                                        </div>
                                            </div>

                                            <div class="row">
                                                        <div class="col-md-2" style={{paddingLeft:'0px',paddingRight:'0px',marginLeft:'0px',marginRight:'0px',width:'5px',maxWidth:'5px',textAlign:'right'}}>
                                                            
                                                        </div>
                                                        <div class="col-md-10" style={{borderTop:"2px solid  #1c7cfb",textAlign:"right",maxWidth:'100px'}}>
                                                            <b>{this.state.indicator.helpVariableMap.VAR_TOTAL_MONTHLY_EXPENSES}</b>
                                                        </div>
                                            </div>                                   
                                        
                                        </div>
                                        </span>
                                        }
                                   
                                        {/* ---------------------  STEP 2 ------------------ */}
                                        
                                        <div class="row">
                                           
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 2' : 'STEP 1'}:</span> <b>Formula</b>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                        Back-End Ratio = 
                                                    </div>
                                                    <div class="col-md-5 indicator-info-formula-table-col2">
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center",borderBottom: "2px solid  #1c7cfb"}}>
                                                                    <b>Total Monthly Expenses</b>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12" style={{textAlign:"center"}}>
                                                                    <b>Gross Monthly Income</b>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   

                                        {/* ---------------------  STEP 3 ------------------ */}
                                        <div class="row">
                                           
                                           <div class="col-md-12">
                                               <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 3' : 'STEP 2'}:</span> <b>Substitute values</b>
                                           </div>
                                       </div>
                                       
                                       
                                       <div class="row">
                                           <div class="col-md-12 indicator-info-formula-table">
                                               <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                   <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                       Back-End Ratio = 
                                                   </div>
                                                   <div class="col-md-5 indicator-info-formula-table-col2">
                                                           <div class="row">
                                                               <div class="col-md-12" style={{textAlign:"center",borderBottom: "2px solid  #1c7cfb"}}>
                                                                   <b>{this.state.indicator.helpVariableMap.VAR_TOTAL_MONTHLY_EXPENSES}</b>
                                                               </div>
                                                           </div>
                                                           <div class="row">
                                                               <div class="col-md-12" style={{textAlign:"center"}}>
                                                                   <b>{this.state.indicator.helpVariableMap.VAR_GROSS_INCOME}</b>
                                                               </div>
                                                           </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                  
                                        {/* ---------------------  STEP 4 ------------------ */}


                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="indicator-info-indicator-step">{this.state.indicator.property.financingType == "Loan" ? 'STEP 4' : 'STEP 3'}:</span> <b>Results</b>
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-12 indicator-info-formula-table">
                                                <div class="row" style={{paddingTop:"10px",paddingBottom:"10px"}}>
                                                    <div class="col-md-5 indicator-info-formula-table-col1" style={{textAlign:'right'}}>
                                                    Back-End Ratio = 
                                                    </div>
                                                    <div class="col-md-4 indicator-info-formula-table-col1">
                                                           <b>{this.FormatIndicatorValue(this.state.indicator?.valueType,this.state.indicator?.indicatorValue)}</b>
                                                    </div>
                                                </div>
                                            </div>
                                  </div>
                                   

                                        <div class="row">
                                            <div class="col-md-10" style={{paddingTop:"15px",marginRight:"5px"}}>
                                                    This outcome can be changed by changing the Back-End ratio benchmark settings or by changing the following property values:
                                            </div>
                                  </div>

                                        <div class="row">
                                            <div class="col-md-10" style={{textAlign:"left",fontWeight:"bold",paddingLeft:'25%',paddingTop:'10px'}}>
                                                {this.state.indicator.property.financingType == "Loan" &&   
                                                <span>
                                                MONTHLY MORTGAGE PAYMENT<br></br>
                                                </span>
                                                }
                                                MONTHLY BILLS<br></br>
                                                MONTHLY GROSS INCOME
                                            </div>
                                  </div>

                                   
                                    </div>

                                    <div class="col-md-1">
                                        &nbsp;
                                    </div>  
                            </div>


                        </div>


                        
                    </div>      

                 {/* TIP */}
                 {this.state.indicator?.meetsBenchmark == false &&
                    
                    <div style={{paddingLeft:'5px',paddingTop:'10px'}}>   
                        
                        <img src={quicktipIcon} style={{height:'50px'}}/>  
                        <br></br>

                        If you want to turn this indicator green, consider making any of the following changes:

                        <div style={{paddingTop:'10px',color:'#000000'}}>
                            <b>1)</b> Negotiate a lower <span style={{fontWeight:'600'}}>purchase price</span><br></br>
                            <b>2)</b> Increase your <span style={{fontWeight:'600'}}>monthly income</span><br></br>          
                            <b>3)</b> Lower your <span style={{fontWeight:'600'}}>monthly bills</span><br></br>  
                            {this.state.indicator.property.financingType  == "Loan" && 
                            <span>
                            <b>4)</b> Put down a larger <span style={{fontWeight:'600'}}>down payment</span><br></br>      
                            </span>     
                            }
                        </div>

                    </div>        
                }                       

                <br></br><br></br><br></br><br></br>
                </div>
                                                    
          
          </main> 
        );
    }
}

export default (GRMIndicator);

import React, { useMemo } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import dayjs from 'dayjs';

const PdfReportFooter = () => {
	const currentDateTime = useMemo(() => {
		const currentDateTime = dayjs();
		return currentDateTime.format('MM/DD/YYYY hh:mm A');
	}, []);

	return (
		<View style={styles.footer} fixed>
			<View style={styles.container}>
				<Text style={styles.footerText} render={() => currentDateTime} />
				<Text
					style={styles.footerText}
					render={({ pageNumber, totalPages }) =>
						`Page ${pageNumber} of ${totalPages}`
					}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	footer: {
		position: 'absolute',
		bottom: 24,
		left: 24,
		right: 24,
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	footerText: {
		fontSize: 11,
		fontFamily: 'Helvetica-Bold',
	},
});

export default PdfReportFooter;

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import {createTheme,ThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';

import closeIcon from '../pages/img/icons/x2.png';

import appLogo from '../pages/img/website-images/real-estate-property-analytics-logo.png';


function LegalDisclaimerPageHTML(props) {

    const theme = createTheme({
        palette: {
          primary: {
            main: '#e37627' //your color
          }
        }
      });

    const classes = props.classes;

    return (

        <html lang="en-us">
            <head>
            </head>

            <body>
            <center>
            <br></br><br></br>

            <img src={appLogo} style={{height:'85px',cursor:'pointer',paddingBottom:"10px"}} onClick={props.goHome}/> 

            {/*
                <div style={{float:'right'}}><a href="/pricing"><img src={closeIcon} style={{height:'30px'}}/></a> &nbsp;&nbsp;&nbsp;&nbsp;</div>
            */} 


          {/*  
            <div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#2451b3',fontSize:'20px',cursor:'pointer',paddingTop:'15px'}}  onClick={props.goHome}>
            GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>
            </div>
            <Avatar style={{margin: 'theme.spacing(1)',backgroundColor: '#2451b3'}}>
                <LockOutlinedIcon />
            </Avatar>
            */}

            <Typography component="h1" variant="h5">
                <div style={{color:'black',paddingTop:'10px'}}>Terms and Conditions</div>
			</Typography>
            


            {(props.user != null && props.user.planType != "trial") && 

                <div style={{paddingTop:'10px',paddingBottom:'10px'}}>
                    <span class="dot"></span>
                    <span class="dot-circle"></span>
                    <span class="dot"></span>
                </div>
            
            }

            {(props.user == null || props.user.planType === "trial") && 

            <div style={{paddingTop:'5px',paddingBottom:'0px'}}>
                &nbsp;
            </div>

            }

            </center>    
        
            <div style={{paddingLeft:'450px',paddingRight:'450px', fontSize:'17px',color:'#000000'}}>

                <p>
                Thanks for using our products and services (“Service”). The Service is provided by SoftWorks Global LLC. (“we”, “us” or “our”), located at 599 Trelago Way, Suite 202, Maitland, FL 32751. By using our Service, you are agreeing to these terms. Please read them carefully.
                </p>

                <h4 style={{fontWeight:'bold'}}>Using our Service</h4>

                <p>
                Using our Service does not give you ownership of any intellectual property rights in our Service or the content you access.
                </p>
                <p>
                Our Service displays some content that is not GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>'s. This content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don't assume that we do.
                </p>

                <p>
                    Our service is intended for the purpose of obtaining insights and analysis of real estate properties. We no dot make any recommendations or suggestions to make any purchases, it is your resposibility to use the information of the analysis simply to inform your decision.
                </p>

                <p>
                Our service doesn't offer or provide any information related to mortgages, mortgage consulting, lending or counseling services. Lending, loans and mortgage information is out of scope of this service and not part of the benefits you will get from using our service.
                </p>

                <p>
                Our service doesn't offer any investment opportunities, it simply provides analysis of existing properties in the market using publicly available data to help inform in your during you research process. It is your resposibility to do your due diligence before making any investment decision.
                </p>

                <p>
                Our service doesn't provide a way to execute the purchase of real estate investments, nor it puts in contact with any buyer or seller, it simply provides analysis of existing properties in the market using publicly available data to help inform in your during you research process. Any transaction or communication with any parties needs to be execute through traditional means.
                </p>
                <h4 style={{fontWeight:'bold'}}>Description of Service</h4>

                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> provides you detailed analysis of real estate properties to give you a first glance at the potential of the investment. We no dot make any recommendations or suggestions to make any purchases, it is your resposibility to use the information of the analysis simply to inform your decision.
                </p>
                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> doesn't promote any product or services on behalf of any company, it simply performs a detailed analysis of existing properties in the market with information that is publicly available to everyone.
                </p>

                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> doesn't offer or provide any information related to mortgages, mortgage consulting, lending or counseling services. Lending, loans and mortgage information is out of scope of this service and not part of the benefits you will get from using our service.
                </p>

                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> doesn't offer any investment opportunities, it simply provides analysis of existing properties in the market using publicly available data to help inform in your during you research process. It is your resposibility to do your due diligence before making any investment decision.
                </p>

                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> doesn't provide a way to execute the purchase of real estate investments, nor it puts in contact with any buyer or seller, it simply provides analysis of existing properties in the market using publicly available data to help inform in your during you research process. Any transaction or communication with any parties needs to be execute through traditional means.
                </p>

                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> provides you with access to a rich collection of resources, including but not limited to, real estate advanced searches, detailed analysis or properties, detailed metrics and reports. You also understand that the Service may include certain communications from GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>, such as service announcements and administrative messages, that are related to the provision of the Service. Unless explicitly stated otherwise, any new features or tools released by us in relation to the Service shall be subject to this Agreement.
                </p>
                <p>
                The Service is an online product that requires access via an internet or other network connection. You are responsible for obtaining the necessary internet or network connection to access GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>’ Service, and you are responsible for the associated third-party fees (such as internet service provider or airtime charges) for the connectivity to the Service. Additionally, you are responsible for procuring and maintaining all equipment necessary to connect to the Service. The quality of the Service that you experience may depend on your connectivity and equipment. GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> is not responsible for any claims or losses that arise as a result of your connectivity and equipment.

                </p>
                <h4 style={{fontWeight:'bold'}}>Your Registration Obligations</h4>

                <p>
                As a registered user of GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>’ Service, you represent that you are of legal age to form a binding contract. You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service's registration form (the "Registration Data") and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).
                </p>

                <h4 style={{fontWeight:'bold'}}>No Resale or Redistribution of Service</h4>


                <p>
                Except as expressly authorized by GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>, you agree not to reproduce, duplicate, copy, sell, trade, resell, permit access, transfer, assign, modify, create derivative works, or exploit for any commercial purposes, any portion of the Service, use of the Service, or access to the Service or computer code that powers the Service.
                </p>
                <p>
                You are responsible for all use made of the Service using your account. You are responsible for maintaining the confidentiality of your online account credentials.

                </p>

                <h4 style={{fontWeight:'bold'}}>Intellectual Property</h4>

                <p>
                All materials and all intellectual property rights in respect of the Services provided therein (including all underlying technology, modifications and work products created in or arising from the same) shall remain the property of GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> and nothing shall operate as a transfer of such rights. For the avoidance of doubt, using the Services does not give you ownership of any intellectual property rights in the Services or the content accessed by you, and all rights not expressly granted by GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> are reserved. You shall not use the Services to build a similar or competitive work.
                </p>
                <p>
                You represent and warrant that you have all the relevant rights and permissions (including all intellectual property rights) to the information provided by you in the course of your use of the Services, and that all the relevant rights (including all intellectual property rights) and permissions are granted to GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> for the purposes of providing the Services to you. You shall assume sole and full liability on such information and the provision of the same to GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>.
                </p>
                <p>
                Any comments or feedback provided by you to GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> shall be non-confidential in nature and you agree that GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> shall own all rights to use and incorporate such comment or feedback into the Services, without any payment or attribution to you.

                </p>

                <h4 style={{fontWeight:'bold'}}>Attribution</h4>

                <p>
                You acknowledge that all GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> proprietary metrics obtained through the Service remains the intellectual property of GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>, and agree to attribute metrics such as, but not limited to property Rating and GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> Rank in any publication or distribution of said proprietary metrics.

                </p>

                <h4 style={{fontWeight:'bold'}}>Account Access</h4>

                <p>
                You must provide your valid email address, and any other information requested, in order to complete the registration process and become a registrant. Your login may only be used by a single user. A single login shared by multiple users is not permitted. We reserve the right to cancel or suspend your access to the Service if we see any suspicious activity in your account that reasonably points out you willingly and/ or knowingly shared your credentials with another user. You are responsible for maintaining the security of your account and password. GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> cannot and will not be liable for any loss or damage from your failure to comply with this obligation.

                </p>

                <h4 style={{fontWeight:'bold'}}>Service and Marketing Messages</h4>

                <p>
                By registering an account at GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> (free or paid) you're giving us your permission to send email notifications to the email address that you have specified upon registration.
                </p>

                <p>
                These email notifications may include: updates to GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>' toolset, tips and tutorials on using GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> tools, and system messages.

                </p>
                <p>
                You're also given an option to subscribe to promotional offers and weekly updates from GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> blog, which is not obligatory for completing the registration process. Please be advised that you can opt-out from any of these optional emails any time by using the ‘Unsubscribe’ link that is included at the bottom of each email that we send you.
                </p>
                <p>
                We may send messages or emails via our own mailing service or via a third party service like MailChimp, Sendgrid or Intercom.io. More information on this can be found in GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>’ privacy policy, which forms part of these terms.

                </p>

                <h4 style={{fontWeight:'bold'}}>Unauthorized Users</h4>

                <p>
                Account registration must be completed by a human. Accounts registered by "bots" or other automated methods are not permitted.

                </p>

                <h4 style={{fontWeight:'bold'}}>Unauthorized Uses</h4>

                <p>
                You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service or GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>. You may not reverse engineer object code or reuse source code provided in relation to the Service. This includes any and all JavaScript. The code is GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>' copyright. You shall not transmit any worms or viruses or any code of a destructive or malicious nature. You agree not to modify any code provided in relation to the Service in any manner or form, nor to use modified versions of such code, including (without limitation) for the purpose of obtaining unauthorized access to the Service. You agree not to access the Service by any means other than through the interface that is provided by GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> for use in accessing the Service, and shall not interfere or disrupt the integrity, security, functionality or performance of the Service or its components. All unnatural means or unlawful attempts to access our Service, website, server or database including by means of any automatic tools such as scrapers or bots is prohibited. We may terminate, suspend and/or block your access if we determine that your use or access of our Service violates our terms. Subject to the other restrictions stated herein, you may only use automated tools to access our Service through the Application Program Interfaces (API) that we provide.
                </p>

                <p>
                You may not attempt to bypass any functionality or access features on the website directly by using custom HTTP (or other) calls, or attempt to manipulate the Service, or access it in a manner other than in a way in which a reasonable person would consider normal permitted use.
                </p>
                <p>
                You should not attempt to take advantage of any feature that a reasonable person would consider an obvious omission or error on the Service and website.
                </p>
                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> may issue instructions, rules, guidelines and directions from time to time in respect of its provision of the Service, which shall be complied with by you.

                </p>

                <h4 style={{fontWeight:'bold'}}>Blocking of IP Addresses</h4>

                <p>
                In order to protect the integrity of the Service, GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> reserves the right at any time in its sole discretion to block registrants and subscribers from certain IP addresses from accessing the Service.
                </p>


                <h4 style={{fontWeight:'bold'}}>Registration, Subscription, Cancellation by You and Additional Usage (Pay-As-You-Go)</h4>

                <p>
                You may become a registrant of the Service at no cost. As a registrant, you will have the ability to participate in some, but not all, of the features and tools available within the Service.
                </p>
                <p>
                In order to access additional features, including the ability to use many of our tools, you must become a subscriber to the Service.
                </p>
                <p>
                We offer paid monthly subscription plans and annual subscription plans. These subscriptions are paid upfront monthly or annually depending on your subscription plan and according to your billing period, with automatic renewals every month or year respectively. You therefore acknowledge and agree that there will be recurring payments by you for the same and that we are authorised to charge the same credit card that you have used for the initial subscription and/or any other payment method used. This will continue until you cancel your subscription plan with us. Such cancellation must be made by you before your subscription renews in order to avoid being charged for the following month’s or year’s subscription fee. When you cancel, you will still have access to our Service based on your subscription plan until the end of your subscription period. You may also opt to downgrade your subscription plan at any time. Any downgrade will only take effect after the end of your current subscription period and the provisions in this section will continue to apply to such downgraded subscription plans.
                </p>
                <p>
                Depending on the subscription plan you select, you will be entitled to varying number of features and/or different limits. Any additional usage on top of those provided under the respective subscription plans is subject to additional charges as set forth in our pricing page. We will periodically charge you for such usage on a pay-as-you-go basis. This will continue for any accrued pay-as-you-go usage even after the cessation of your subscription plan for any reason and any unused portion will expire by the stipulated timeframe without being transferred to any subsequent usage period. In the event that such charges are not paid by you, we shall have the right to remove your access to our Service at any time and apply the value of your remaining subscription plan towards the fulfilment of such charges.
                </p>


                <h4 style={{fontWeight:'bold'}}>Refund policy</h4>

                <p>
                Unless required by law, GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> is not obligated to provide you a refund at any time or for any reason. For your monthly subscription, you may request for a refund after payment has been made for non-use, but please be advised that we reserve the right to decline your refund request should we see any material activity in your account.
                </p>


                <h4 style={{fontWeight:'bold'}}>Cancellation of Service by GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup></h4>

                <p>
                In addition to the above, GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> reserves the right of unilateral service cancellation under the following circumstances:
                </p>

                <p>

                    <ul>

                        <li>You are in breach of any of these terms.</li>

                        <li>We detect unusual usage patterns or unauthorized uses in relation to the Service.</li>

                        <li>You attempt to inflict damage or harm our reputation, work or Service.</li>

                        <li>In response to requests from law enforcement or other public agencies.</li>

                        <li>You become a subject of a petition in a bankruptcy or any other proceeding relating to insolvency, cessation of business, liquidation or assignment for the benefit of creditors.</li>
                        
                    </ul>

                </p>


                <h4 style={{fontWeight:'bold'}}>Modifications to Service</h4>

                <p>
                We are constantly changing and improving our Service. We may add or remove functionalities or features, and we may suspend or stop the Service altogether. You can stop using our Service at any time, although we'll be sorry to see you go. GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> may also stop providing the Service to you, or add or create new limits to our Service at any time.
                </p>

                <p>
                You agree that GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> shall not be liable, to you or to any third party, for any modification, suspension or discontinuance of the Service.
                </p>
                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> reserves the right to amend and change any subscription plan and/or adjust the pricing of the Service or any components thereof in any manner and at any time as GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> may determine in its sole and absolute discretion.

                </p>

                <h4 style={{fontWeight:'bold'}}>Links</h4>

                <p>
                The Service may provide links to other World Wide Web sites or resources. Since GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> has no control over such sites or resources, you acknowledge and agree that GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> is not responsible for the availability of such external sites or resources, does not endorse said sites/resources, and is not responsible, nor liable for any content, advertising, or other materials on or available from such sites or resources. You further acknowledge and agree that GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or related in any way to use of or reliance on any such content, goods or services available on or through any such site or resource.
                </p>

                <h4 style={{fontWeight:'bold'}}>Indemnity</h4>

                <p>
                You shall indemnify and keep GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> and its respective directors, officers, employees and agents indemnified against any and all claims, demands, actions, judgments, obligations, liabilities, costs, expenses, damages and losses incurred as a result of (a) your breach of any of these terms; (b) any infringement or alleged infringement of any intellectual property right arising directly or indirectly from your actions or omissions; and/or (c) your violation of any applicable laws.
                </p>


                <h4 style={{fontWeight:'bold'}}>Liability for our Service</h4>

                <p>
                The Service and all information provided therein are on an "as is" and "as available" basis without any warranty.

                </p>

                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> does not guarantee or warrant that the Service (including any products or data) will meet your requirements, be secure, timely, accurate, error free, bug free, virus free, uninterrupted, comply with applicable law, or that GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> will correct all errors.
                </p>
                <p>
                TO THE EXTENT PERMITTED BY LAW, GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, PAST OR PRESENT, STATUTORY OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE, AND GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND/OR NON-INFRINGEMENT.
                </p>
                <p>
                TO THE EXTENT PERMITTED BY LAW, GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>, WILL NOT BE RESPONSIBLE FOR LOSS OF PROFITS OR REVENUES, LOSS OF USE OF PRODUCTS OR SERVICES, LOSS OF BUSINESS OPPORTUNITY, LOSS OF REPUTATION AND/OR CONTRACT, DOWNTIME COSTS, INCREASED EXPENSE OF OPERATION, LOSS OF OR CORRUPTION OF DATA, FINANCIAL LOSSES, OR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR DAMAGES OF ANY KIND WHATSOEVER.
                </p>
                <p>
                TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>, FOR ANY CLAIM UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICE IN THE 12 MONTHS PRECEDING THE CLAIM (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICE AGAIN).
                </p>
                <p>
                TO THE EXTENT PERMITTED BY LAW, GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
                </p>
                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> shall not be responsible for any delay in performance or non-performance due to any cause which is beyond the reasonable control of GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>.

                </p>

                <h4 style={{fontWeight:'bold'}}>Regulatory Compliance</h4>
                <p>
                You represent and warrant that you are at all times in compliance with all applicable laws (including any and all applicable sanctions and export control regulations) in respect of all activities conducted herein as well as your usage of the Services.
                </p>

                <h4 style={{fontWeight:'bold'}}>Contacting GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup></h4>
                <p>
                GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> may be contacted by email at support@appdomain.com
                </p>
                <p>
                By contacting GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>, you grant GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> a worldwide, non-exclusive perpetual right to any ideas, information, designs or other material contained in any communication, except when you have obtained a duly authorized prior written agreement.
                </p>
                <p>
                You agree not to pass to GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> any commercially sensitive information and data about your own operations.
                </p>
                <p>
                You accept that information sent to GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> will not under any circumstance be considered subject to any form of non-disclosure agreement, except when you have obtained a prior written consent from GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>.
                </p>
                <p>
                All parties take into consideration that email is not a secure, guaranteed medium of communication. Emails should only be regarded received by GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> if you receive a manually created response.
                </p>
                <p>
                All discussions over email are deemed to be of informal nature, and should only be taken as binding when a duly authorized written agreement is entered into.
                </p>
                <p>
                Any suggestions, mentions or references of any alteration to past, present or future contractual terms by GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> should be taken purely as speculative until such time that you are in possession of a duly authorized written confirmation from GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>.

                </p>

                <h4 style={{fontWeight:'bold'}}>About these Terms</h4>

                <p>
                We may modify these terms or any additional terms that apply to the Service, for example, reflect changes to the law or changes to our Service. You should look at the online version of our terms regularly. We’ll post notice of modifications to these terms on this page. Changes will not apply retroactively and will become effective no sooner than seven days after they are posted. However, changes addressing new functions for the Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms of the Service, you should discontinue the use of our Service.
                </p>
                <p>
                These terms control the relationship between GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup> and you. They do not create any third party beneficiary rights.
                </p>
                <p>
                If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have, such as taking action in the future.
                </p>
                <p>
                If any of these terms are rendered invalid, unenforceable or void, the other terms will remain valid, enforceable and not be affected.
                </p>
                <p>
                The section titles in the terms herein are for convenience only and have no legal or contractual effect.
                </p>
                <p>    
                These terms and all matters based upon, arising out of, or relating in any way to these terms as well as the interpretation, construction, performance and enforcement of these terms, shall be governed by the laws of Singapore and you submit to the jurisdiction of the courts of Singapore for the purposes of any suit, action or other proceedings.
                </p>

            </div>     
             {/*
                <div style={{paddingLeft:'450px',paddingRight:'450px', fontSize:'17px',color:'#000000'}}>

                    <p>
                    Thanks for using our products and services (“Service”). The Service is provided by Ahrefs Pte. Ltd. (“Ahrefs”, “we”, “us” or “our”), located at 16 Raffles Quay #33-03, Hong Leong Building, Singapore. By using our Service, you are agreeing to these terms. Please read them carefully.
                    </p>

                    <h4 style={{fontWeight:'bold'}}>Using our Service</h4>

                    <p>
                    Using our Service does not give you ownership of any intellectual property rights in our Service or the content you access.
                    </p>
                    <p>
                    Our Service displays some content that is not Ahrefs'. This content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don't assume that we do.
                    </p>

                    <h4 style={{fontWeight:'bold'}}>Description of Service</h4>
                    
                    <p>
                    Ahrefs provides you with access to a rich collection of resources, including but not limited to, search engine optimization tools, link analysis tools, application program interfaces (API). You also understand that the Service may include certain communications from Ahrefs, such as service announcements and administrative messages, that are related to the provision of the Service. Unless explicitly stated otherwise, any new features or tools released by us in relation to the Service shall be subject to this Agreement.
                    </p>
                    <p>
                    The Service is an online product that requires access via an internet or other network connection. You are responsible for obtaining the necessary internet or network connection to access Ahrefs’ Service, and you are responsible for the associated third-party fees (such as internet service provider or airtime charges) for the connectivity to the Service. Additionally, you are responsible for procuring and maintaining all equipment necessary to connect to the Service. The quality of the Service that you experience may depend on your connectivity and equipment. Ahrefs is not responsible for any claims or losses that arise as a result of your connectivity and equipment.

                    </p>
                    <h4 style={{fontWeight:'bold'}}>Your Registration Obligations</h4>

                    <p>
                    As a registered user of Ahrefs’ Service, you represent that you are of legal age to form a binding contract. You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service's registration form (the "Registration Data") and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Ahrefs has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Ahrefs has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).
                    </p>

                    <h4 style={{fontWeight:'bold'}}>No Resale or Redistribution of Service</h4>

                    <p>
                    You are permitted to use the Service internally within the company for the purpose of obtaining insights and analysis to optimize your website(s) and related campaigns.
                    </p>

                    <p>
                    Except as expressly authorized by Ahrefs, you agree not to reproduce, duplicate, copy, sell, trade, resell, permit access, transfer, assign, modify, create derivative works, or exploit for any commercial purposes, any portion of the Service, use of the Service, or access to the Service or computer code that powers the Service.
                    </p>
                    <p>
                    You are responsible for all use made of the Service using your account. You are responsible for maintaining the confidentiality of your online account credentials.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Intellectual Property</h4>

                    <p>
                    All materials and all intellectual property rights in respect of the Services provided therein (including all underlying technology, modifications and work products created in or arising from the same) shall remain the property of Ahrefs and nothing shall operate as a transfer of such rights. For the avoidance of doubt, using the Services does not give you ownership of any intellectual property rights in the Services or the content accessed by you, and all rights not expressly granted by Ahrefs are reserved. You shall not use the Services to build a similar or competitive work.
                    </p>
                    <p>
                    You represent and warrant that you have all the relevant rights and permissions (including all intellectual property rights) to the information provided by you in the course of your use of the Services, and that all the relevant rights (including all intellectual property rights) and permissions are granted to Ahrefs for the purposes of providing the Services to you. You shall assume sole and full liability on such information and the provision of the same to Ahrefs.
                    </p>
                    <p>
                    Any comments or feedback provided by you to Ahrefs shall be non-confidential in nature and you agree that Ahrefs shall own all rights to use and incorporate such comment or feedback into the Services, without any payment or attribution to you.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Attribution</h4>

                    <p>
                    You acknowledge that all Ahrefs proprietary metrics obtained through the Service remains the intellectual property of Ahrefs, and agree to attribute metrics such as, but not limited to URL Rating, Domain Rating and Ahrefs Rank in any publication or distribution of said proprietary metrics.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Account Access</h4>

                    <p>
                    You must provide your valid email address, and any other information requested, in order to complete the registration process and become a registrant. Your login may only be used by a single user. A single login shared by multiple users is not permitted. We reserve the right to cancel or suspend your access to the Service if we see any suspicious activity in your account that reasonably points out you willingly and/ or knowingly shared your credentials with another user. You are responsible for maintaining the security of your account and password. Ahrefs cannot and will not be liable for any loss or damage from your failure to comply with this obligation.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Service and Marketing Messages</h4>

                    <p>
                    By registering an account at Ahrefs (free or paid) you're giving us your permission to send email notifications to the email address that you have specified upon registration.
                    </p>

                    <p>
                    These email notifications may include: updates to Ahrefs' toolset, tips and tutorials on using Ahrefs tools, and system messages.

                    </p>
                    <p>
                    You're also given an option to subscribe to promotional offers and weekly updates from Ahrefs blog, which is not obligatory for completing the registration process. Please be advised that you can opt-out from any of these optional emails any time by using the ‘Unsubscribe’ link that is included at the bottom of each email that we send you.
                    </p>
                    <p>
                    We may send messages or emails via our own mailing service or via a third party service like MailChimp, Sendgrid or Intercom.io. More information on this can be found in Ahrefs’ privacy policy, which forms part of these terms.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Unauthorized Users</h4>

                    <p>
                    Account registration must be completed by a human. Accounts registered by "bots" or other automated methods are not permitted.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Unauthorized Uses</h4>

                    <p>
                    You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service or Ahrefs. You may not reverse engineer object code or reuse source code provided in relation to the Service. This includes any and all JavaScript. The code is Ahrefs' copyright. You shall not transmit any worms or viruses or any code of a destructive or malicious nature. You agree not to modify any code provided in relation to the Service in any manner or form, nor to use modified versions of such code, including (without limitation) for the purpose of obtaining unauthorized access to the Service. You agree not to access the Service by any means other than through the interface that is provided by Ahrefs for use in accessing the Service, and shall not interfere or disrupt the integrity, security, functionality or performance of the Service or its components. All unnatural means or unlawful attempts to access our Service, website, server or database including by means of any automatic tools such as scrapers or bots is prohibited. We may terminate, suspend and/or block your access if we determine that your use or access of our Service violates our terms. Subject to the other restrictions stated herein, you may only use automated tools to access our Service through the Application Program Interfaces (API) that we provide.
                    </p>

                    <p>
                    You may not attempt to bypass any functionality or access features on the website directly by using custom HTTP (or other) calls, or attempt to manipulate the Service, or access it in a manner other than in a way in which a reasonable person would consider normal permitted use.
                    </p>
                    <p>
                    You should not attempt to take advantage of any feature that a reasonable person would consider an obvious omission or error on the Service and website.
                    </p>
                    <p>
                    Ahrefs may issue instructions, rules, guidelines and directions from time to time in respect of its provision of the Service, which shall be complied with by you.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Blocking of IP Addresses</h4>

                    <p>
                    In order to protect the integrity of the Service, Ahrefs reserves the right at any time in its sole discretion to block registrants and subscribers from certain IP addresses from accessing the Service.
                    </p>

                    
                    <h4 style={{fontWeight:'bold'}}>Registration, Subscription, Cancellation by You and Additional Usage (Pay-As-You-Go)</h4>
                    
                    <p>
                    You may become a registrant of the Service at no cost. As a registrant, you will have the ability to participate in some, but not all, of the features and tools available within the Service.
                    </p>
                    <p>
                    In order to access additional features, including the ability to use many of our tools, you must become a subscriber to the Service.
                    </p>
                    <p>
                    We offer paid monthly subscription plans and annual subscription plans. These subscriptions are paid upfront monthly or annually depending on your subscription plan and according to your billing period, with automatic renewals every month or year respectively. You therefore acknowledge and agree that there will be recurring payments by you for the same and that we are authorised to charge the same credit card that you have used for the initial subscription and/or any other payment method used. This will continue until you cancel your subscription plan with us. Such cancellation must be made by you before your subscription renews in order to avoid being charged for the following month’s or year’s subscription fee. When you cancel, you will still have access to our Service based on your subscription plan until the end of your subscription period. You may also opt to downgrade your subscription plan at any time. Any downgrade will only take effect after the end of your current subscription period and the provisions in this section will continue to apply to such downgraded subscription plans.
                    </p>
                    <p>
                    Depending on the subscription plan you select, you will be entitled to varying number of features and/or different limits. Any additional usage on top of those provided under the respective subscription plans is subject to additional charges as set forth in our pricing page. We will periodically charge you for such usage on a pay-as-you-go basis. This will continue for any accrued pay-as-you-go usage even after the cessation of your subscription plan for any reason and any unused portion will expire by the stipulated timeframe without being transferred to any subsequent usage period. In the event that such charges are not paid by you, we shall have the right to remove your access to our Service at any time and apply the value of your remaining subscription plan towards the fulfilment of such charges.
                    </p>


                    <h4 style={{fontWeight:'bold'}}>Refund policy</h4>

                    <p>
                    Unless required by law, Ahrefs is not obligated to provide you a refund at any time or for any reason. For your monthly subscription, you may request for a refund after payment has been made for non-use, but please be advised that we reserve the right to decline your refund request should we see any material activity in your account.
                    </p>


                    <h4 style={{fontWeight:'bold'}}>Cancellation of Service by Ahrefs</h4>

                    <p>
                    In addition to the above, Ahrefs reserves the right of unilateral service cancellation under the following circumstances:
                    </p>

                    <p>

                        <ul>
 
                            <li>You are in breach of any of these terms.</li>

                            <li>We detect unusual usage patterns or unauthorized uses in relation to the Service.</li>

                            <li>You attempt to inflict damage or harm our reputation, work or Service.</li>

                            <li>In response to requests from law enforcement or other public agencies.</li>

                            <li>You become a subject of a petition in a bankruptcy or any other proceeding relating to insolvency, cessation of business, liquidation or assignment for the benefit of creditors.</li>
                            
                        </ul>
                    
                    </p>

                    <h4 style={{fontWeight:'bold'}}>Data Collection and Use</h4>

                    <p>
                    You understand and agree that Ahrefs may anonymize and aggregate any data you share with the Service, including data associated with your Google Analytics and Google Search Console accounts, and use it without identifying you for purposes of improving the Service.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Modifications to Service</h4>

                    <p>
                    We are constantly changing and improving our Service. We may add or remove functionalities or features, and we may suspend or stop the Service altogether. You can stop using our Service at any time, although we'll be sorry to see you go. Ahrefs may also stop providing the Service to you, or add or create new limits to our Service at any time.
                    </p>

                    <p>
                    You agree that Ahrefs shall not be liable, to you or to any third party, for any modification, suspension or discontinuance of the Service.
                    </p>
                    <p>
                    Ahrefs reserves the right to amend and change any subscription plan and/or adjust the pricing of the Service or any components thereof in any manner and at any time as Ahrefs may determine in its sole and absolute discretion.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Links</h4>

                    <p>
                    The Service may provide links to other World Wide Web sites or resources. Since Ahrefs has no control over such sites or resources, you acknowledge and agree that Ahrefs is not responsible for the availability of such external sites or resources, does not endorse said sites/resources, and is not responsible, nor liable for any content, advertising, or other materials on or available from such sites or resources. You further acknowledge and agree that Ahrefs shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or related in any way to use of or reliance on any such content, goods or services available on or through any such site or resource.
                    </p>

                    <h4 style={{fontWeight:'bold'}}>Indemnity</h4>

                    <p>
                    You shall indemnify and keep Ahrefs and its respective directors, officers, employees and agents indemnified against any and all claims, demands, actions, judgments, obligations, liabilities, costs, expenses, damages and losses incurred as a result of (a) your breach of any of these terms; (b) any infringement or alleged infringement of any intellectual property right arising directly or indirectly from your actions or omissions; and/or (c) your violation of any applicable laws.
                    </p>


                    <h4 style={{fontWeight:'bold'}}>Liability for our Service</h4>

                    <p>
                    The Service and all information provided therein are on an "as is" and "as available" basis without any warranty.

                    </p>

                    <p>
                    Ahrefs does not guarantee or warrant that the Service (including any products or data) will meet your requirements, be secure, timely, accurate, error free, bug free, virus free, uninterrupted, comply with applicable law, or that Ahrefs will correct all errors.
                    </p>
                    <p>
                    TO THE EXTENT PERMITTED BY LAW, AHREFS EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, PAST OR PRESENT, STATUTORY OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE, AND AHREFS SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND/OR NON-INFRINGEMENT.
                    </p>
                    <p>
                    TO THE EXTENT PERMITTED BY LAW, AHREFS, WILL NOT BE RESPONSIBLE FOR LOSS OF PROFITS OR REVENUES, LOSS OF USE OF PRODUCTS OR SERVICES, LOSS OF BUSINESS OPPORTUNITY, LOSS OF REPUTATION AND/OR CONTRACT, DOWNTIME COSTS, INCREASED EXPENSE OF OPERATION, LOSS OF OR CORRUPTION OF DATA, FINANCIAL LOSSES, OR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR DAMAGES OF ANY KIND WHATSOEVER.
                    </p>
                    <p>
                    TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF AHREFS, FOR ANY CLAIM UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICE IN THE 12 MONTHS PRECEDING THE CLAIM (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICE AGAIN).
                    </p>
                    <p>
                    TO THE EXTENT PERMITTED BY LAW, AHREFS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
                    </p>
                    <p>
                    Ahrefs shall not be responsible for any delay in performance or non-performance due to any cause which is beyond the reasonable control of Ahrefs.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>Regulatory Compliance</h4>
                    <p>
                    You represent and warrant that you are at all times in compliance with all applicable laws (including any and all applicable sanctions and export control regulations) in respect of all activities conducted herein as well as your usage of the Services.
                    </p>

                    <h4 style={{fontWeight:'bold'}}>Contacting Ahrefs</h4>
                    <p>
                    Ahrefs may be contacted by email at support@ahrefs.com
                    </p>
                    <p>
                    By contacting Ahrefs, you grant Ahrefs a worldwide, non-exclusive perpetual right to any ideas, information, designs or other material contained in any communication, except when you have obtained a duly authorized prior written agreement.
                    </p>
                    <p>
                    You agree not to pass to Ahrefs any commercially sensitive information and data about your own operations.
                    </p>
                    <p>
                    You accept that information sent to Ahrefs will not under any circumstance be considered subject to any form of non-disclosure agreement, except when you have obtained a prior written consent from Ahrefs.
                    </p>
                    <p>
                    All parties take into consideration that email is not a secure, guaranteed medium of communication. Emails should only be regarded received by Ahrefs if you receive a manually created response.
                    </p>
                    <p>
                    All discussions over email are deemed to be of informal nature, and should only be taken as binding when a duly authorized written agreement is entered into.
                    </p>
                    <p>
                    Any suggestions, mentions or references of any alteration to past, present or future contractual terms by Ahrefs should be taken purely as speculative until such time that you are in possession of a duly authorized written confirmation from Ahrefs.

                    </p>

                    <h4 style={{fontWeight:'bold'}}>About these Terms</h4>

                    <p>
                    We may modify these terms or any additional terms that apply to the Service, for example, reflect changes to the law or changes to our Service. You should look at the online version of our terms regularly. We’ll post notice of modifications to these terms on this page. Changes will not apply retroactively and will become effective no sooner than seven days after they are posted. However, changes addressing new functions for the Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms of the Service, you should discontinue the use of our Service.
                    </p>
                    <p>
                    These terms control the relationship between Ahrefs and you. They do not create any third party beneficiary rights.
                    </p>
                    <p>
                    If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have, such as taking action in the future.
                    </p>
                    <p>
                    If any of these terms are rendered invalid, unenforceable or void, the other terms will remain valid, enforceable and not be affected.
                    </p>
                    <p>
                    The section titles in the terms herein are for convenience only and have no legal or contractual effect.
                    </p>
                    <p>    
                    These terms and all matters based upon, arising out of, or relating in any way to these terms as well as the interpretation, construction, performance and enforcement of these terms, shall be governed by the laws of Singapore and you submit to the jurisdiction of the courts of Singapore for the purposes of any suit, action or other proceedings.
                    </p>

                </div>       
            */}         
                <br></br>
                {props.showButton == true && 
                <div>
                    <center>
                
                        <ThemeProvider theme={theme}>
                            <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained" 
                                    style={{color:'#FFFFFF',fontSize:'20px',fontWeight:'bold',maxWidth:'300px'}}
                                    color="primary"
                                    onClick={props.handleClick}
                                >
                                    Accept and Continue

                                    {props.loading && <CircularProgress size={30} className={classes.progess} />}
                                </Button>
                        </ThemeProvider>  

                    </center>
                </div>
                }
         
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </body>

        </html>

);
};

const _LegalDisclaimerPageHTML = LegalDisclaimerPageHTML;
export { _LegalDisclaimerPageHTML as LegalDisclaimerPageHTML };
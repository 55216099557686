import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createTheme,ThemeProvider} from '@material-ui/core/styles';

import Dialog from 'react-bootstrap-dialog'

import appLogo from './img/website-images/real-estate-property-analytics-logo.png';

import { authMiddleWare } from '../utilities/Auth';

//import appLogo from '../images/logo-nocircle.png';

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#e37627'
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	},


	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {
		minWidth: 300
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '40%',
		top: '35%'
	}
	
});

class login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			errors: [],
			uiLoading: true,
			loading: false
		};
	}

	componentWillMount = () => {

	try
	{
		console.log("About to check logged in user.");

		authMiddleWare(this?.props?.history);
        const authToken = localStorage.getItem('AuthToken');
        const refreshToken = localStorage.getItem('RefreshToken');
        axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
		axios
			.get('/api/alreadyLoggedInCheck')
			.then((response) => {

				if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
                {
                    localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                    localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
                }

				//console.log(JSON.stringify(response));

				if (response && ( (response.result && response.result ==='success') || (response.data && response.data.result && response.data.result ==='success') ) )
				{
					
					//localStorage.setItem('SessionExpired',"N");
					//this.props.history.push('/app');
					//Retrieve user
					authMiddleWare(this?.props?.history);
					const authToken = localStorage.getItem('AuthToken');
					const refreshToken = localStorage.getItem('RefreshToken');
					axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
					axios
					.get('/api/user')
					.then((response) => {

						if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
						{
							localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
							localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						}
		
						console.log(response.data);
	   
						const user =  response.data;

						this.setState({ 
							user: response.data,
							email: response.data.email
						});	

						localStorage.setItem('SessionExpired','N');

						const nextPage = this.getNextPage(response.data);

						localStorage.setItem('SubscriptionStatus','active');
						if (nextPage === '/app' && user.planType !== 'trial')
						{
							//CHECK SUBSCRIPTION STATUS
							authMiddleWare(this?.props?.history);
							const authToken = localStorage.getItem('AuthToken');
							const refreshToken = localStorage.getItem('RefreshToken');
							axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
							axios
								.post('/api/subscription', {
									subscriptionId: user.stripeSubscriptionId
								})
								.then((response) => {
									//alert(response.data);
									
									if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
									{
										localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
										localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
									}
					
									const subscriptionStatus = response.data.status;

									localStorage.setItem('SubscriptionStatus',subscriptionStatus);

									this.updateAccountStatus(user.accountNumber,subscriptionStatus);

									this.setState({
										uiLoading: false
									});
									this.props.history.push(nextPage);
								})
								.catch((error) => {
									
									console.log(error);

									this.dialogMessage.show({
										title: 'No Subscription Found',
										body: "It looks like you don't have a valid subscription. Please sign up to one of our subscription plans.",
										actions: [
										  Dialog.OKAction(() => {
											//console.log('OK was clicked!')
											this.props.history.push("/pricing");               
										  })
										],
										bsSize: 'small',
										onHide: (dialog) => {
										  dialog.hide()
										  this.props.history.push("/pricing");  
										}
									  });
						
								});

							//--------------------------------------

						} else
						{
							this.setState({
								uiLoading: false
							});
							this.props.history.push(nextPage);
						}
						
					})
					.catch((error) => {
						console.log('Error in getting user record:'+error);
	
						this.setState({ 
							user: null,
							uiLoading: false
						});	
						//Do nothing, let user login
				
					  });
				} else
				{
					alert('Error logging in. Please try again later...');
					this.setState({
						uiLoading: false
					});
				}

			})
			.catch((error) => {
				//console.log(error);
				this.setState({
					uiLoading: false
				});
				if(error.response.status === 403) {
					//Do nothing, let this page render to allow user to log in
					localStorage.setItem('SessionExpired',"Y");
				}
				
			});

		} catch(err)
		{
			console.log("Error checking logged in user:"+err);
			this.setState({
				uiLoading: false
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		try
		{
			if (nextProps && nextProps.UI && nextProps.UI.errors) {
				this.setState({
					errors: nextProps.UI.errors
				});
			}
		} catch(err)
		{
			console.log("Error:"+err);
		}
	}

	updateAccountStatus(accountNumber,accountStatus) {

		try {

			authMiddleWare(this?.props?.history);
			const authToken = localStorage.getItem('AuthToken');
			const refreshToken = localStorage.getItem('RefreshToken');
			axios.defaults.headers.common = { Authorization: `${authToken}`,RefreshToken:refreshToken };
			axios
				.post('/api/updateUserAccountStatus', {
					accountNumber:accountNumber,
					accountStatus:accountStatus
				})
				.then((response) => {
					//alert(response.data);
					if (response.headers["authtoken"] != null && response.headers["authtoken"] != "" && response.headers["refreshtoken"] != null && response.headers["refreshtoken"] !="")
					{
						localStorage.setItem('AuthToken', `Bearer ${response.headers["authtoken"]}`); //We need to set them again on each request in case they got refresh to keep user logged in all the time
						localStorage.setItem('RefreshToken', response.headers["refreshtoken"]); //We need to set them again on each request in case they got refresh to keep user logged in all the time
					}
	
				})
				.catch((error) => {
					
					console.log(error);
				});
				
		} catch(error)
		{
			console.log(error);
		}

	}

	getNextPage = (user) => {
		
		let nextPage = "";

		if (user.emailHasBeenConfirmed ==='N')
		{
			nextPage = "/signup/email-confirmation";
		} else
		{
			if (user.planType !="trial")
			{
				if (user.termsAndConditionsAccepted ==="N")
				{
					nextPage = "/signup/terms-and-conditions";

				} else if (user.stripeSubscriptionId ==="")
				{
					nextPage = "/signup/billing-information";
				} else
				{
					nextPage = "/app";
				}

			} else
			{
				nextPage = "/app";
			}
			
		}

		return nextPage;
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();

		localStorage.removeItem('menuItem')

		this.setState({ loading: true });
		const userData = {
			email: this.state.email,
			password: this.state.password
		};
		axios
			.post('/api/login', userData)
			.then((response) => {

				localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
				localStorage.setItem('RefreshToken', response.data.refreshToken);
					
				//------- Get user info
				const authToken = localStorage.getItem('AuthToken');
                axios.defaults.headers.common = { Authorization: `${authToken}` };
                axios
				  .get('/api/user')
                  .then((response) => {

						console.log(response.data);
						
						localStorage.setItem('SessionUserName', response.data.name);

						const user = response.data;
		
					//------ Update last login date	
					axios
					.post('/api/user', {
						lastLoginDate: new Date().toISOString(),
						previousLoginDate: user.lastLoginDate
					})
					.then((response) => {
						

						localStorage.setItem('SessionExpired',"N");
						localStorage.setItem('CurrentPage','');
						
						const nextPage = this.getNextPage(user);

						localStorage.setItem('SubscriptionStatus','active');
						if (nextPage === '/app' && user.planType !== 'trial')
						{
							//CHECK SUBSCRIPTION STATUS
							authMiddleWare(this?.props?.history);
							const authToken = localStorage.getItem('AuthToken');
							axios.defaults.headers.common = { Authorization: `${authToken}` };
							axios
								.post('/api/subscription', {
									subscriptionId: user.stripeSubscriptionId
								})
								.then((response) => {
									//alert(response.data);

									const subscriptionStatus = response.data.status;
					
									this.setState({ 
										loading: false,
									});	

									localStorage.setItem('SubscriptionStatus',subscriptionStatus);

									this.updateAccountStatus(user.accountNumber,subscriptionStatus);

									this.props.history.push(nextPage);
								})
								.catch((error) => {
									console.log(error);

									this.dialogMessage.show({
										title: 'No Subscription Found',
										body: "It looks like you don't have a valid subscription. Please sign up to one of our subscription plans.",
										actions: [
										  Dialog.OKAction(() => {
											//console.log('OK was clicked!')
											this.props.history.push("/pricing");               
										  })
										],
										bsSize: 'small',
										onHide: (dialog) => {
										  dialog.hide()
										  this.props.history.push("/pricing");  
										}
									  });
								});

							//--------------------------------------

						} else
						{

							this.props.history.push(nextPage);
						}

					})
					.catch((error) => {
						console.log('Error in getting customer record:'+error);
	
						this.setState({ 
							loading: false,
						});	
						this.props.history.push('/');  //I still want to redirect the page
				
					  });


                  })
                  .catch((error) => {
                    console.log('Error in updating customer record:'+error);

					this.setState({ 
						loading: false,
					});	
					this.props.history.push('/');  //I still want to redirect the page, it is not important to update the last logged in date
            
                  });
				//------
			})
			.catch((error) => {		
				//alert(error);		
				this.setState({
					errors: error,
					loading: false
				});
			});
	};

	goHome = () => {
		window.location.href ="/";
	};

	render() {

		const theme = createTheme({
			palette: {
			  primary: {
				main: '#e37627' //your color
			  }
			}
		  });

		const { classes } = this.props;
		const { errors, loading } = this.state;

		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}

					<Dialog ref={(component) => { this.dialogMessage = component }}/>    
				</main>
			);
		} else {
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img src={appLogo} style={{height:'85px',cursor:'pointer',paddingBottom:"10px"}} onClick={this.goHome}/> 
					
					{/*
					
					<div style={{fontWeight: '600',fontFamily: 'Tiempos Headline, Georgia, Times, Times New Roman, serif',color:'#e37627',fontSize:'20px',cursor:'pointer'}}  onClick={this.goHome}>
					GoRepa<sup style={{fontSize:'12px'}}>&#174;</sup>
					</div>
					
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					*/}

					<Typography component="h1" variant="h5">
						<div style={{fontWeight:"500",paddingBottom:'10px'}}>Log in</div>
					</Typography>


					<div  style={{border:"1px solid #a9a29d",padding:"30px",paddingBottom:'90px',backgroundColor:"#FFFFFF"}}>
						<form className={classes.form} noValidate>
							<ThemeProvider theme={theme}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								type="regular"
								name="email"
								autoComplete="email"
								autoFocus
								helperText={errors.email}
								error={errors.email ? true : false}
								onChange={this.handleChange}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								helperText={errors.password}
								error={errors.password ? true : false}
								onChange={this.handleChange}
							/>

							<div style={{textAlign: 'right'}}>
							
								<Link href="reset" variant="body2">
									Forgot Password?
								</Link>
							
							</div>
				
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								style={{color:'#FFFFFF'}}
								className={classes.submit}
								onClick={this.handleSubmit}
								disabled={loading || !this.state.email || !this.state.password}
							>
								Log In
								{loading && <CircularProgress size={30} className={classes.progess} />}
							</Button>
							<Grid container>
								<Grid item>
										<Link href="signup" variant="body2">
											Don't have an account? <strong>Sign Up</strong>
										</Link>
								</Grid>
							</Grid>
							{errors.general && (
								<Typography variant="body2" className={classes.customError}>
									{errors.general}
								</Typography>
							)}
							</ThemeProvider>
						</form>

					</div>

					<Dialog ref={(component) => { this.dialogMessage = component }}/>    
				</div>
			</Container>
		);
		}
	}
}

export default withStyles(styles)(login);

import React from 'react';
import { StyleSheet, View, Image, Text } from '@react-pdf/renderer';
import IndicatorValue from './IndicatorValue';

const IndicatorsTable = ({ columns, indicators }) => {
	return (
		<View style={styles.table}>
			<View style={styles.tableRow}>
				{columns.map((column, index) => (
					<View
						key={index}
						style={[styles.tableColHeader, { width: column.width }]}
					>
						<Text style={styles.tableCellHeader}>{column.name}</Text>
					</View>
				))}
			</View>
			{indicators.map((row, index) => (
				<div>
				{row.disabled == false &&	
				<View key={index} style={styles.tableRow}>
					<View style={[styles.tableCol, { width: columns[0].width }]}>
						<Text style={styles.tableCell}>{row.indicatorName}</Text>
					</View>
					<View style={[styles.tableCol, { width: columns[1].width }]}>
						<Image
							style={[
								styles.tableCell,
								styles.statusDot,
								row.meetsBenchmark ? styles.greenBorder : styles.redBorder,
							]}
							src={
								row.meetsBenchmark
									? '/images/passed-dot.png'
									: '/images/failed-dot.png'
							}
						/>
					</View>
					<View style={[styles.tableCol, { width: columns[2].width }]}>
						<Text style={styles.tableCell}>
							{row.meetsBenchmark ? 'PASSED' : 'FAILED'}
						</Text>
					</View>
					<View style={[styles.tableCol, { width: columns[3].width }]}>
						<Text style={[styles.tableCell]}>{row.acceptCriteria}</Text>
					</View>
					<View style={[styles.tableCol, { width: columns[4].width }]}>
						<IndicatorValue
							value={row.indicatorValue}
							valueType={row.valueType}
							style={[styles.tableCell]}
						/>
					</View>
					<View style={[styles.tableCol, { width: columns[5].width }]}>
						<Text style={[styles.tableCell, styles.textLeft]}>
							{row.result}
						</Text>
					</View>
					<View style={[styles.tableCol, { width: columns[6].width }]}>
						<Text style={[styles.tableCell, styles.textLeft]}>
							{row.recommendation}
						</Text>
					</View>
				</View>
				}
				</div>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	table: {
		display: 'table',
		width: '100%',
		marginTop: 5,
	},
	tableRow: {
		margin: 'auto',
		flexDirection: 'row',
		borderLeft: '1px solid #bfbfbf',
	},
	tableColHeader: {
		border: '1px solid #bfbfbf',
		borderLeftWidth: 0,
		backgroundColor: '#e9e9e9',
	},
	tableCol: {
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		width: '100%',
	},
	tableCellHeader: {
		padding: 3,
		fontSize: 10,
		textAlign: 'center',
		fontFamily: 'Helvetica-Bold',
		marginTop: 'auto',
	},
	tableCell: {
		padding: 3,
		fontSize: 9,
		margin: 'auto',
	},
	textLeft: {
		margin: 0,
	},
	statusDot: {
		width: 16,
		height: 16,
		margin: 'auto',
		borderWidth: 2,
		borderStyle: 'solid',
		borderRadius: '50%',
		padding: 1,
	},
	greenBorder: {
		borderColor: 'green',
	},
	redBorder: {
		borderColor: 'red',
	},
});
export default IndicatorsTable;
